import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import "./about.css";
import { UserOutlined } from "@ant-design/icons";

import { Typography, Card, Col, Row, Space } from "antd";
// import professor_1 from "../../constants/img/professor-img-1.png";
// import professor_2 from "../../constants/img/professor-img-2.png";
// import professor_3 from "../../constants/img/professor-img-3.png";
const { Title, Paragraph } = Typography;

function About(store) {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className="about-body">
      <div className="about-connect-text-and-map"></div>
      <div className="about-connect-body">
        <Typography>
          <Title level={2} className="about-contact-title">
            Information
          </Title>
          <Paragraph>
            <b>DeepDR</b> is developed and maintained by BioAI team from School of Computer Science and Technology of Wuhan University of Science and Technology, the DMCI Lab team from Department of Computer Science and Technology of Xiamen University, the DrugAI Group from College of Computer Science and Electronic Engineering of Hunan University, the Intelligence Bioinformatics Group from Research Center of Software and Data Engineering of Shandong University, the CBDD Team of Central South University, and the AIDD team of Shanghai Yuyao Biotechnology Co., Ltd..
            <br />
          </Paragraph>
          {/* <br/> */}
          <Title level={2} className="about-contact-title">
            People involved in the DeepDR project:
          </Title>
          <Paragraph>
            <UserOutlined className="user-out-lined-icon" />
            <Space />
            Shuting Jin, School of Computer Science and Technology, Wuhan
            University of Science and Technology, Wuhan, Hubei, P.R. China
            430081
          </Paragraph>
          <Paragraph>
            <UserOutlined className="user-out-lined-icon" />
            <Space />
            Zheyong Zhu, School of Software, Joint SDU-NTU Centre for Artificial Intelligence Research (C-FAIR), Shandong University, Jinan, 250101, Shandong, P. R. China
          </Paragraph>
          <Paragraph>
            <UserOutlined className="user-out-lined-icon" />
            <Space />
            Yi Jiang, School of Software, Joint SDU-NTU Centre for Artificial
            Intelligence Research (C-FAIR), Shandong University, Jinan, 250101,
            Shandong, P. R. China
          </Paragraph>
          <Paragraph>
            <UserOutlined className="user-out-lined-icon" />
            Tengfei Ma, School of Information Science and Engineering, Hunan
            University, Changsha, 410082, Hunan, P.R. China
          </Paragraph>
          <Paragraph>
            <UserOutlined className="user-out-lined-icon" />
            DongSheng Cao, Xiangya School of Pharmaceutical Sciences, Central
            South University, Changsha, 410013, Hunan, P. R. China{" "}
          </Paragraph>
          <Paragraph>
            <UserOutlined className="user-out-lined-icon" />
            Leyi Wei, School of Software, Joint SDU-NTU Centre for Artificial
            Intelligence Research (C-FAIR), Shandong University, Jinan, 250101,
            Shandong, P. R. China
          </Paragraph>
          <Paragraph>
            <UserOutlined className="user-out-lined-icon" />
            Xiangrong Liu, School of Informatics Xiamen University, National
            Institute for Data Science in Health and Medicine, Xiamen University
            , Xiamen, 361005, Fujian, P. R. China
          </Paragraph>
          <Paragraph>
            <UserOutlined className="user-out-lined-icon" />
            Xiangxiang Zeng, School of Information Science and Engineering,
            Hunan University, Changsha, 410082, Hunan, P.R. China
          </Paragraph>
          <Title level={3} className="about-contact-title">
            Correspondence
          </Title>
          <div>
            <div className="site-card-wrapper">
              <Row>
                <Col span={8}>
                  <Card
                    // title={}
                    bordered={false}
                  >
                    <div className="professor-graph-right">
                      <div className="professor-details">
                        Dr. Xiangxiang Zeng
                      </div>
                      <div>
                        <a>xzeng@hnu.edu.cn</a>
                      </div>{" "}
                      <br />
                      <div>
                        <text>Professor</text>
                      </div>
                      {/* <br /> */}
                      <div>
                        <text>Hunan University, Hunan, China</text>
                      </div>
                    </div>

                    {/* <br /> */}
                    <div className="professor-graph-left">
                      <div className="professor-graph">
                        <img
                          alt="Dr. Xiangxiang Zeng"
                          src={
                            "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/professor-img-1.png"
                          }
                          className="professor-graph-con"
                        />
                      </div>
                    </div>

                    <br />
                  </Card>
                </Col>

                <Col span={8}>
                  <Card bordered={false}>
                    <div className="professor-graph-right">
                      <div className="professor-details">Dr. Leyi Wei</div>
                      <div>
                        <a>weileyi@sdu.edu.cn </a>
                      </div>{" "}
                      <br />
                      <div>
                        <text>Professor</text>
                      </div>
                      {/* <br /> */}
                      <div>
                        <text>Shandong University, Shandong, China</text>
                      </div>
                    </div>

                    {/* <br /> */}
                    <div className="professor-graph-left">
                      <div className="professor-graph">
                        <img
                          alt="Dr. Leyi Wei"
                          src={
                            "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/professor-img-2.png"
                          }
                          className="professor-graph-con"
                        />
                      </div>
                    </div>

                    <br />
                  </Card>
                </Col>

                <Col span={8}>
                  <Card
                    // title={}
                    bordered={false}
                  >
                    <div className="professor-graph-right">
                      <div className="professor-details">Dr. Xiangrong Liu</div>
                      <div>
                        <a>xrliu@xmu.edu.cn</a>
                      </div>{" "}
                      <br />
                      <div>
                        <text>Professor</text>
                      </div>
                      {/* <br /> */}
                      <div>
                        <text>Xiamen University, Xiamen, China</text>
                      </div>
                    </div>

                    {/* <br /> */}
                    <div className="professor-graph-left-add">
                      <div className="professor-graph">
                        <img
                          alt="Dr. Xiangrong Liu"
                          src={
                            "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/professor-img-3.png"
                          }
                          className="professor-graph-con"
                        />
                      </div>
                    </div>

                    <br />
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Typography>

        <div className="about-connect-text-and-map"></div>
      </div>
    </div>
  );
}

export default inject("store")(observer(About));
