import { observable, action, makeAutoObservable } from "mobx";
import { FAILURE, REQUEST, SUCCESS, UNSET } from "../../constants/status";
class DDRresult {
  constructor() {
    makeAutoObservable(this);
  }

  @observable status = UNSET;
  @observable data = [];
  @observable type = "";
  @observable RequestType = "default";
  @observable DataType = "default";
  @action request = () => {
    this.status = REQUEST;
  };
  @action request_success = (dataInject) => {
    this.data = dataInject.data.data;
    this.type = dataInject.type;
    this.status = SUCCESS;
    this.RequestType = dataInject.data.RequestType;
    this.DataType = dataInject.data.DataType;
  };
  @action request_fail = () => {
    this.status = FAILURE;
  };
}
export default DDRresult;
