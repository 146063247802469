import { inject, observer } from "mobx-react";
import { useEffect } from "react";

import "./publications.css";
function Publications(store) {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className="Publications-body">
      <div className="Publications-outline">
        <div>
          <text className="Publications-title">
            [1] deepDR: a network-based deep learning approach to in silico drug
            repositioning
          </text>
          <br />
          Xiangxiang Zeng, Siyi Zhu, Xiangrong Liu, Yadi Zhou, Ruth
          Nussinov, Feixiong Cheng*
          <br />
          Bioinformatics, Volume 35, Issue 24, 15 December 2019, Pages
          5191–5198.
          <br />
          DOI:{" "}
          <a
            onClick={() => {
              try {
                window.open(
                  "https://doi.org/10.1093/bioinformatics/btz418",
                  "_blank"
                );
              } catch (e) {}
            }}
          >
            https://doi.org/10.1093/bioinformatics/btz418
          </a>
        </div>

        <div>
          <text className="Publications-title">
            [2] Target identification among known drugs by deep learning from
            heterogeneous networks
          </text>
          <br />
          Xiangxiang Zeng, Siyi Zhu, Weiqiang Lu, Zehui Liu, Jin Huang, Yadi
          Zhou, Jiansong Fang, Yin Huang, Huimin Guo, Lag Li, Bruce D. Trapp,
          Ruth Nussinov, Charis Eng, Joseph Loscalzo, Feixiong Cheng *
          <br />
          Chemical Science, 2020,11, 1775-1797
          <br />
          DOI:{" "}
          <a
            onClick={() => {
              try {
                window.open("https://doi.org/10.1039/C9SC04336E", "_blank");
              } catch (e) {}
            }}
          >
            https://doi.org/10.1039/C9SC04336E
          </a>
        </div>

        <div>
          <text className="Publications-title">
            [3] HeTDR: Drug repositioning based on heterogeneous networks and
            text mining
          </text>
          <br />
          Shuting Jin, Zhangming Niu, Changzhi Jiang, Wei Huang, Feng Xia, Xurui
          Jin, Xiangrong Liu *, Xiangxiang Zeng
          <br />
          Patterns Volume 2, Issue 8, 13 August 2021, 100307
          <br />
          DOI:{" "}
          <a
            onClick={() => {
              try {
                window.open(
                  "https://doi.org/10.1016/j.patter.2021.100307",
                  "_blank"
                );
              } catch (e) {}
            }}
          >
            https://doi.org/10.1016/j.patter.2021.100307
          </a>
        </div>

        <div>
          <text className="Publications-title">
            [4] Network-based prediction of drug-target interactions using an
            arbitrary-order proximity embedded deep forest
          </text>
          <br />
          Xiangxiang Zeng, Siyi Zhu, Yuan Hou, Pengyue Zhang, Lang Li, Jing
          Li, L Frank Huang, Stephen J Lewis, Ruth Nussinov, Feixiong Cheng*
          <br />
          Bioinformatics, Volume 36, Issue 9, 1 May 2020, Pages 2805–2812,
          <br />
          DOI:{" "}
          <a
            onClick={() => {
              try {
                window.open(
                  "https://doi.org/10.1093/bioinformatics/btaa010",
                  "_blank"
                );
              } catch (e) {}
            }}
          >
            https://doi.org/10.1093/bioinformatics/btaa010
          </a>
        </div>

        <div>
          <text className="Publications-title">
            [5] Repurpose Open Data to Discover Therapeutics for COVID-19 Using
            Deep Learning
          </text>
          <br />
          Xiangxiang Zeng, Xiang Song, Tengfei Ma, Xiaoqin Pan, Yadi Zhou, Yuan
          Hou, Zheng Zhang, Kenli Li,  George Karypis,*  Feixiong Cheng*
          <br />
          J Proteome Res. 2020 Jul 12 : acs.jproteome.0c00316.
          <br />
          DOI:{" "}
          <a
            onClick={() => {
              try {
                window.open(
                  "https://doi.org/10.1021/acs.jproteome.0c00316",
                  "_blank"
                );
              } catch (e) {}
            }}
          >
            https://doi.org/10.1021/acs.jproteome.0c00316
          </a>
        </div>

        <div>
          <text className="Publications-title">
            [6] KG-MTL: Knowledge Graph Enhanced Multi-Task Learning for
            Molecular Interaction.
          </text>
          <br />
          Tengfei Ma, Xuan Lin, Bosheng Song, Philip S. Yu, and Xiangxiang Zeng.
          <br />
          IEEE Transactions on Knowledge and Data Engineering, 2022.
          <br />
          DOI:{" "}
          <a
            onClick={() => {
              try {
                window.open(
                  "https://doi.org/10.1109/TKDE.2022.3188154",
                  "_blank"
                );
              } catch (e) {}
            }}
          >
            https://doi.org/10.1109/TKDE.2022.3188154
          </a>
        </div>
        <div>
          <text className="Publications-title">
            [7] Deep learning for drug repurposing: methods, databases, and
            applications.
          </text>
          <br />
          Xiaoqin Pan, Xuan Lin, Dongsheng Cao, Xiangxiang Zeng, Philip S. Yu,
          Lifang He, Ruth Nussinov and Feixiong Cheng.
          <br />
          WIREs Comput Mol Sci. 2022;e1597
          <br />
          DOI:{" "}
          <a
            onClick={() => {
              try {
                window.open("https://doi.org/10.1002/wcms.1597", "_blank");
              } catch (e) {}
            }}
          >
            https://doi.org/10.1002/wcms.1597
          </a>
        </div>
      </div>
    </div>
  );
}

export default inject("store")(observer(Publications));
