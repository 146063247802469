import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import "./serverHomeModel.css";
import { Button } from "antd";
// import DeepDR from "../../../constants/img/Tutorial/2_Disease_Centric_DeepDR(1).png";
// import HetDR from "../../../constants/img/Tutorial/2_Disease_Centric_DeepDR(2).png"
// import DisKGE from "../../../constants/img/Tutorial/2_DisKGE.png"
// import DeepDTnet from "../../../constants/img/Tutorial/2_Target_Centric_DeepDR(1).png"
import KGMTL from "../../../constants/img/KG-MTL.png";

function ServerHomeModel(store) {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  if (store.type === "Disease") {
    return (
      <div className="serverhome-body-outer-up-down">
        <div className="serverhome-body">
          <div className="function-select-up-down">
            <div className="function-select-up-down-title">
              Heterogeneous networks-based model
            </div>
            <div className="server-home-model-function-select-con-model-outside">
              <div className="server-home-model-function-select-con-model">
                <div className="server-home-model-function-select-title">
                  DeepDR
                </div>

                <div className="function-select-img-outer">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Disease_Centric_DeepDR(1).png"
                    }
                    className="server-home-model-function-select-img-outer"
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(8);
                    }}
                  />
                </div>
                {/* <div className="server-home-model-position"></div> */}

                <div className="function-select-context">
                  This model is a network-based deep learning method for drug
                  repositioning that integrates 10 drug-related networks,
                  consisting of one each for drug-disease, drug-side-effect, and
                  drug-target, alongside seven types of drug-drug similarity
                  networks.
                </div>
                <div className="function-select-button-out">
                  <Button
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(8);
                    }}
                    className="function-select-button"
                  >
                    Start
                  </Button>
                </div>
              </div>
              <div className="server-home-model-function-select-con-model">
                <div className="server-home-model-function-select-title">
                  HetDR
                </div>
                <div className="function-select-img-outer">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Disease_Centric_DeepDR(2).png"
                    }
                    className="server-home-model-function-select-img-outer"
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(9);
                    }}
                  />
                </div>
                {/* <div className="server-home-model-position"></div> */}

                <div className="function-select-context">
                  This model is a drug repositioning method based on
                  heterogeneous networks and text mining, which combines drug
                  features from multiple networks and disease features from
                  biomedical corpora to predict the correlation scores between
                  drugs and diseases.
                </div>
                <div className="function-select-button-out">
                  <Button
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(9);
                    }}
                    className="function-select-button"
                  >
                    Start
                  </Button>
                </div>
              </div>
            </div>
            <div className="function-select-up-down-title">
              Knowledge graph-based model
            </div>{" "}
            <div className="server-home-model-function-select-con-model-outside">
              <div className="server-home-model-function-select-con-model">
                <div className="server-home-model-function-select-title">
                  DisKGE
                </div>

                <div className="function-select-img-outer">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_DisKGE.png"
                    }
                    className="server-home-model-function-select-img-outer"
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(10);
                    }}
                  />
                </div>

                {/* <div className="server-home-model-position"></div> */}
                <div className="function-select-context">
                  The model is a variant of CovKGE, which uses a comprehensive
                  biological knowledge graph called DRKG relating genes,
                  compounds, diseases, biological processes, side effects, and
                  symptoms for predicting candidate drugs with potential
                  therapeutic effects for a certain disease.
                </div>
                <div className="function-select-button-out">
                  <Button
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(10);
                    }}
                    className="function-select-button"
                  >
                    Start
                  </Button>
                </div>
              </div>
              <div className="server-home-model-function-select-con-model-block"></div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="serverhome-body-outer-up-down">
        <div className="serverhome-body">
          <div className="function-select-up-down">
            <div className="function-select-up-down-title">
              Heterogeneous networks-based model
            </div>
            <div className="server-home-model-function-select-con-model-outside">
              <div className="server-home-model-function-select-con-model">
                <div className="server-home-model-function-select-title">
                  DeepDTnet
                </div>
                <div className="function-select-img-outer">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Target_Centric_DeepDR(1).png"
                    }
                    className="server-home-model-function-select-img-outer"
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(20);
                    }}
                  />
                </div>
                {/* <div className="server-home-model-position"></div> */}

                <div className="function-select-context">
                  The model is a network-based deep learning method for new
                  target identification and drug repositioning in a
                  heterogeneous drug–gene–disease network embedding 15 types of
                  chemical, genomic, phenotypic, and cellular network profiles.
                </div>
                <div className="function-select-button-out">
                  <Button
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(20);
                    }}
                    className="function-select-button"
                  >
                    Start
                  </Button>
                </div>
              </div>
              {/* <div className="function-select-img-outer">
                <img
                  src={
                    "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Target_Centric_DeepDR(2).png"
                  }
                  className="server-home-model-function-select-img-outer"
                  onClick={() => {
                    //gotoPage();
                    store.store.servers.changeHomeStatue(22);
                  }}
                />
              </div> */}
              {/* <div className="server-home-model-position"></div> */}

              <div className="server-home-model-function-select-con-model">
                <div className="server-home-model-function-select-title">
                  AOPEDF
                </div>
                <div className="function-select-img-outer">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Target_Centric_DeepDR(2).png"
                    }
                    className="server-home-model-function-select-img-outer"
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(22);
                    }}
                  />
                </div>
                {/* <div className="server-home-model-position"></div> */}

                <div className="function-select-context">
                  This model is a network-based computational framework,
                  employing an arbitrary-order proximity embedded deep forest
                  approach for drug-target interaction predictions, utilizing
                  the same data as the DeepDTnet model.
                </div>
                <div className="function-select-button-out">
                  <Button
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(22);
                    }}
                    className="function-select-button"
                  >
                    Start
                  </Button>
                </div>
              </div>
            </div>
            <div className="function-select-up-down-title">
              Knowledge graph-based model
            </div>{" "}
            <div className="server-home-model-function-select-con-model-outside">
              <div className="server-home-model-function-select-con-model">
                <div className="server-home-model-function-select-title">
                  TarKGE
                </div>
                <div className="function-select-img-outer">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_DisKGE.png"
                    }
                    className="server-home-model-function-select-img-outer"
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(21);
                    }}
                  />
                </div>
                {/* <div className="server-home-model-position"></div> */}

                <div className="function-select-context">
                  The model is also a variant of CovKGE, which uses a
                  comprehensive biological knowledge graph called DRKG relating
                  genes, compounds, diseases, biological processes, side
                  effects, and symptoms for predicting candidate drugs with the
                  potential to target a specific protein.
                </div>
                <div className="function-select-button-out">
                  <Button
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(21);
                    }}
                    className="function-select-button"
                  >
                    Start
                  </Button>
                </div>
              </div>
              <div className="server-home-model-function-select-con-model">
                <div className="server-home-model-function-select-title">
                  KG-MTL
                </div>
                <div className="function-select-img-outer">
                  <img
                    src={KGMTL}
                    className="server-home-model-function-select-img-outer"
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(90);
                    }}
                  />
                </div>
                {/* <div className="server-home-model-position"></div> */}

                <div className="function-select-context">
                  The model is a large-scale knowledge graph enhanced multi-task
                  Learning model, which extracts the features from both
                  knowledge graph and molecular graph in a synergistic way for
                  molecular interaction prediction tasks.
                </div>
                <div className="function-select-button-out">
                  <Button
                    onClick={() => {
                      //gotoPage();
                      store.store.servers.changeHomeStatue(90);
                    }}
                    className="function-select-button"
                  >
                    Start
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("store")(observer(ServerHomeModel));
