export const list = {
  
  DeepDR_HeTDR_disease: [
    {
      id: "C0342826",
      name: "3-Beta-hydroxy-delta-5-C27-steroid dehydrogenase deficiency",
    },
    { id: "C0243001", name: "Abdominal Abscess" },
    { id: "C0001263", name: "Abdominal actinomycosis" },
    { id: "C0014553", name: "Absence Epilepsy" },
    {
      id: "C0261486",
      name: "Accidental poisoning by lead and its compounds and fumes",
    },
    { id: "C0261439", name: "Accidental poisoning by methyl alcohol" },
    { id: "C0001144", name: "Acne Vulgaris" },
    { id: "C0175167", name: "Acneiform Eruptions" },
    { id: "C0271693", name: "Acquired generalized lipodystrophy" },
    { id: "C0220989", name: "Acquired partial lipodystrophy" },
    { id: "C0154301", name: "Acquired thrombocytopenia" },
    { id: "C0001206", name: "Acromegaly" },
    { id: "C0022602", name: "Actinic keratosis" },
    { id: "C0001261", name: "Actinomycosis" },
    { id: "C0152499", name: "Acute amebiasis" },
    { id: "C0339933", name: "Acute bacterial bronchitis" },
    { id: "C0275555", name: "Acute bacterial epiglottitis" },
    { id: "C0275550", name: "Acute bacterial peritonitis" },
    { id: "C0275556", name: "Acute bacterial sinusitis" },
    { id: "C0948089", name: "Acute Coronary Syndrome" },
    { id: "C0155814", name: "Acute epiglottitis" },
    { id: "C0023440", name: "Acute Erythroblastic Leukemia" },
    { id: "C0856695", name: "Acute exacerbation of chronic bronchitis" },
    { id: "C0153195", name: "Acute gonococcal cervicitis" },
    { id: "C0153196", name: "Acute gonococcal endometritis" },
    { id: "C0153193", name: "Acute gonococcal epididymo-orchitis" },
    { id: "C0275652", name: "Acute gonococcal urethritis" },
    { id: "C0162565", name: "Acute intermittent porphyria" },
    { id: "C0023449", name: "Acute lymphocytic leukemia" },
    { id: "C0155804", name: "Acute maxillary sinusitis" },
    { id: "C0023465", name: "Acute monocytic leukemia" },
    { id: "C0339932", name: "Acute Moraxella catarrhalis bronchitis" },
    { id: "C0023479", name: "Acute myelomonocytic leukemia" },
    { id: "C0155626", name: "Acute myocardial infarction" },
    { id: "C0184567", name: "Acute onset pain" },
    { id: "C0158371", name: "Acute osteomyelitis" },
    { id: "C0149948", name: "Acute otitis externa" },
    { id: "C0271429", name: "Acute otitis media" },
    { id: "C2215257", name: "Acute postoperative pain" },
    { id: "C0023487", name: "Acute Promyelocytic Leukemia" },
    { id: "C0155919", name: "Acute pulmonary edema" },
    {
      id: "C1303258",
      name: "Acute ST segment elevation myocardial infarction (disorder)",
    },
    { id: "C0275959", name: "Acute tuberculosis" },
    { id: "C0001403", name: "Addison Disease" },
    { id: "C0151467", name: "Addisonian crisis" },
    { id: "C0281361", name: "Adenocarcinoma of pancreas" },
    { id: "C0405580", name: "Adrenal cortical hypofunction" },
    { id: "C0001622", name: "Adrenal Gland Hyperfunction" },
    { id: "C0701163", name: "Adrenogenital disorder" },
    { id: "C0041228", name: "African Trypanosomiasis" },
    { id: "C0242383", name: "Age related macular degeneration" },
    { id: "C0085631", name: "Agitation" },
    { id: "C0276535", name: "AIDS with Kaposi's sarcoma" },
    { id: "C0001957", name: "Alcohol Withdrawal Delirium" },
    { id: "C0236663", name: "Alcohol withdrawal syndrome" },
    { id: "C0001973", name: "Alcoholic Intoxication, Chronic" },
    { id: "C0155877", name: "Allergic asthma" },
    { id: "C0009766", name: "Allergic Conjunctivitis" },
    { id: "C1112631", name: "Allergic keratoconjunctivitis" },
    { id: "C1320547", name: "Allergic otitis externa" },
    { id: "C2607914", name: "Allergic rhinitis (disorder)" },
    { id: "C0149526", name: "Allergic urticaria" },
    { id: "C0002170", name: "Alopecia" },
    { id: "C0002171", name: "Alopecia Areata" },
    { id: "C4083212", name: "Alopecia, Male Pattern" },
    { id: "C0002351", name: "Altitude Sickness" },
    { id: "C0002395", name: "Alzheimer's Disease" },
    { id: "C0002438", name: "Amebiasis" },
    { id: "C0002453", name: "Amenorrhea" },
    { id: "C0002726", name: "Amyloidosis" },
    { id: "C0002736", name: "Amyotrophic Lateral Sclerosis" },
    { id: "C0002792", name: "anaphylaxis" },
    { id: "C0334579", name: "Anaplastic astrocytoma" },
    {
      id: "C1275317",
      name: "Anaplastic large T-cell systemic malignant lymphoma",
    },
    { id: "C1998329", name: "Anemia due to chemotherapy" },
    { id: "C1561828", name: "Anemia in chronic kidney disease" },
    { id: "C0158996", name: "Anemia of prematurity" },
    { id: "C1260899", name: "Anemia, Diamond-Blackfan" },
    { id: "C0002888", name: "Anemia, Megaloblastic" },
    { id: "C0002892", name: "Anemia, Pernicious" },
    { id: "C0002895", name: "Anemia, Sickle Cell" },
    { id: "C0002962", name: "Angina Pectoris" },
    { id: "C0002963", name: "Angina Pectoris, Variant" },
    { id: "C0002994", name: "Angioedema" },
    { id: "C0019243", name: "Angioedemas, Hereditary" },
    { id: "C0017531", name: "Angiolymphoid hyperplasia" },
    { id: "C0241961", name: "Angiomyolipoma of kidney" },
    { id: "C0017605", name: "Angle Closure Glaucoma" },
    { id: "C0038013", name: "Ankylosing spondylitis" },
    { id: "C0042165", name: "Anterior uveitis" },
    { id: "C0003175", name: "Anthrax disease" },
    { id: "C0152946", name: "Anthrax sepsis" },
    { id: "C0237996", name: "anticholinergic toxicity" },
    { id: "C0572583", name: "Antimetabolite overdose" },
    { id: "C0003467", name: "Anxiety" },
    { id: "C0003469", name: "Anxiety Disorders" },
    { id: "C0003492", name: "Aortic coarctation" },
    { id: "C0038363", name: "Aphthous Stomatitis" },
    { id: "C0002874", name: "Aplastic Anemia" },
    { id: "C0232058", name: "Apnea in the newborn" },
    { id: "C0003615", name: "Appendicitis" },
    { id: "C0233485", name: "apprehension" },
    { id: "C0311375", name: "Arsenic Poisoning" },
    { id: "C0155749", name: "Arterial embolus and thrombosis" },
    { id: "C0003850", name: "Arteriosclerosis" },
    { id: "C0003862", name: "Arthralgia" },
    { id: "C0857177", name: "Arthritic pains" },
    { id: "C0003864", name: "Arthritis" },
    { id: "C1692886", name: "Arthritis, Bacterial" },
    { id: "C0003868", name: "Arthritis, Gouty" },
    { id: "C0003872", name: "Arthritis, Psoriatic" },
    { id: "C0157749", name: "Arthropathy associated with infection" },
    { id: "C1444173", name: "Arthropod bite wound" },
    { id: "C0003950", name: "Ascariasis" },
    { id: "C0003962", name: "Ascites" },
    { id: "C0004030", name: "Aspergillosis" },
    { id: "C0004031", name: "Aspergillosis, Allergic Bronchopulmonary" },
    { id: "C0032290", name: "Aspiration Pneumonia" },
    { id: "C1761609", name: "Aspiration pneumonitis" },
    { id: "C0004096", name: "Asthma" },
    { id: "C0347950", name: "Asthma attack" },
    { id: "C1273489", name: "Asthma night-time symptoms" },
    {
      id: "C4038730",
      name: "Asthma-chronic obstructive pulmonary disease overlap syndrome",
    },
    {
      id: "C3698411",
      name: "Asymptomatic left ventricular systolic dysfunction",
    },
    { id: "C0004153", name: "Atherosclerosis" },
    { id: "C0004238", name: "Atrial Fibrillation" },
    { id: "C0004245", name: "Atrioventricular Block" },
    { id: "C0221392", name: "Atrophic Vaginitis" },
    { id: "C0156393", name: "Atrophy of vulva" },
    { id: "C1263846", name: "Attention deficit hyperactivity disorder" },
    { id: "C0004352", name: "Autistic Disorder" },
    { id: "C0004364", name: "Autoimmune Diseases" },
    { id: "C0002880", name: "Autoimmune hemolytic anemia" },
    { id: "C0004604", name: "Back Pain" },
    { id: "C0009768", name: "Bacterial conjunctivitis" },
    { id: "C0014121", name: "Bacterial Endocarditis" },
    { id: "C0152516", name: "Bacterial enteritis" },
    {
      id: "C0343402",
      name: "Bacterial infection due to Klebsiella pneumoniae",
    },
    { id: "C0854211", name: "Bacterial keratitis" },
    {
      id: "C0854215",
      name: "Bacterial meningitis due to Gram-negative bacteria",
    },
    { id: "C0341503", name: "Bacterial peritonitis" },
    { id: "C0684256", name: "Bacterial sepsis" },
    { id: "C0729524", name: "Bacterial urinary infection" },
    { id: "C0085166", name: "Bacterial Vaginosis" },
    { id: "C2062454", name: "Bacteroides empyema" },
    { id: "C0004771", name: "Bartonella Infections" },
    { id: "C0007117", name: "Basal cell carcinoma" },
    { id: "C0270327", name: "Bedwetting" },
    { id: "C0004945", name: "Bejel" },
    { id: "C0005138", name: "Berylliosis" },
    { id: "C0005283", name: "beta Thalassemia" },
    { id: "C0005424", name: "Biliary Tract Diseases" },
    { id: "C0596170", name: "Binge eating disorder" },
    { id: "C2937225", name: "Biotin deficiency disease" },
    {
      id: "C0338873",
      name: "Bipolar affective disorder, current episode manic",
    },
    { id: "C0005586", name: "Bipolar Disorder" },
    { id: "C0270425", name: "Bipolar disorder in remission" },
    { id: "C0403643", name: "Bladder muscle dysfunction - overactive" },
    { id: "C0232849", name: "Bladder pain" },
    { id: "C0005699", name: "Blast Phase" },
    { id: "C0005716", name: "Blastomycosis" },
    { id: "C0005743", name: "Blepharoconjunctivitis" },
    { id: "C0030758", name: "Body louse infestation" },
    { id: "C0006057", name: "Botulism" },
    { id: "C0006060", name: "Boutonneuse Fever" },
    { id: "C0006105", name: "Brain Abscess" },
    { id: "C0006118", name: "Brain Neoplasms" },
    {
      id: "C0339507",
      name: "Branch retinal vein occlusion with macular edema",
    },
    { id: "C0678222", name: "Breast Carcinoma" },
    { id: "C0016034", name: "Breast Fibrocystic Disease" },
    { id: "C1623040", name: "Breastfeeding (mother)" },
    { id: "C0006181", name: "Brill-Zinsser Disease" },
    { id: "C0006266", name: "Bronchial Spasm" },
    { id: "C0006267", name: "Bronchiectasis" },
    { id: "C0006277", name: "Bronchitis" },
    { id: "C0008677", name: "Bronchitis, Chronic" },
    { id: "C0006309", name: "Brucellosis" },
    { id: "C2267227", name: "Bulimia Nervosa" },
    { id: "C0006413", name: "Burkitt Lymphoma" },
    { id: "C0006434", name: "Burn injury" },
    { id: "C0006444", name: "Bursitis" },
    { id: "C1959799", name: "Calcium renal calculus" },
    { id: "C0278996", name: "Cancer of Head and Neck" },
    { id: "C0153254", name: "Candidal endocarditis" },
    { id: "C0153256", name: "Candidal meningitis" },
    { id: "C2062982", name: "candidal peritonitis" },
    { id: "C0877445", name: "Candidemia" },
    { id: "C0006840", name: "Candidiasis" },
    { id: "C0239295", name: "Candidiasis of the esophagus" },
    { id: "C0700345", name: "Candidiasis, Vulvovaginal" },
    {
      id: "C0751753",
      name: "Carbamoyl-Phosphate Synthase I Deficiency Disease",
    },
    { id: "C0278488", name: "Carcinoma breast stage IV" },
    { id: "C0686510", name: "Carcinoma in situ of adrenal cortex" },
    { id: "C0007138", name: "Carcinoma, Transitional Cell" },
    { id: "C0018790", name: "Cardiac Arrest" },
    { id: "C1142132", name: "Carnitine deficiency" },
    {
      id: "C0339498",
      name: "Central retinal vein occlusion with macular edema",
    },
    { id: "C0006114", name: "Cerebral Edema" },
    { id: "C0007780", name: "Cerebral Embolism" },
    { id: "C0917798", name: "Cerebral Ischemia" },
    { id: "C0038454", name: "Cerebrovascular accident" },
    { id: "C0007820", name: "Cerebrovascular Disorders" },
    { id: "C0596298", name: "Cerebrovascular Occlusion" },
    { id: "C0001264", name: "Cervicofacial actinomycosis" },
    { id: "C0302592", name: "Cervix carcinoma" },
    { id: "C0007947", name: "Chancroids" },
    { id: "C0401160", name: "Chemotherapy-induced nausea and vomiting" },
    { id: "C1827687", name: "Chemotherapy-induced neutropenia" },
    { id: "C0008049", name: "Chickenpox" },
    { id: "C0008149", name: "Chlamydia Infections" },
    {
      id: "C1997322",
      name: "Chlamydia trachomatis infection of genital structure",
    },
    { id: "C0341834", name: "Chlamydial cervicitis" },
    { id: "C0520777", name: "Chlamydial pelvic inflammatory disease" },
    { id: "C0339959", name: "Chlamydial pneumonia" },
    { id: "C1278807", name: "Chlamydial urethritis" },
    { id: "C0025218", name: "Chloasma" },
    { id: "C0008311", name: "Cholangitis" },
    { id: "C0008325", name: "Cholecystitis" },
    { id: "C0008350", name: "Cholelithiasis" },
    { id: "C0008354", name: "Cholera" },
    { id: "C0008513", name: "Chorioretinitis" },
    { id: "C0600518", name: "Choroidal Neovascularization" },
    { id: "C0271066", name: "Choroidal retinal neovascularization" },
    { id: "C0008526", name: "Choroiditis" },
    { id: "C0008582", name: "Chromoblastomycosis" },
    { id: "C1720797", name: "Chronic Bacterial Prostatitis" },
    { id: "C0346421", name: "Chronic eosinophilic leukemia" },
    { id: "C0268108", name: "Chronic gouty arthritis" },
    { id: "C0264716", name: "Chronic heart failure" },
    { id: "C0267509", name: "Chronic idiopathic constipation" },
    { id: "C2267231", name: "Chronic idiopathic neutropenia" },
    { id: "C0272293", name: "Chronic idiopathic thrombocytopenic purpura" },
    { id: "C0578870", name: "Chronic idiopathic urticaria" },
    {
      id: "C0409818",
      name: "Chronic Infantile Neurological, Cutaneous, and Articular Syndrome",
    },
    { id: "C0002440", name: "Chronic intestinal amebiasis" },
    { id: "C1739382", name: "Chronic iron overload" },
    { id: "C0023434", name: "Chronic Lymphocytic Leukemia" },
    { id: "C0024117", name: "Chronic Obstructive Airway Disease" },
    { id: "C0150055", name: "Chronic pain" },
    { id: "C0221765", name: "Chronic schizophrenia" },
    { id: "C0406317", name: "Chronic small plaque psoriasis" },
    { id: "C2363973", name: "Chronic thromboembolic pulmonary hypertension" },
    { id: "C0155440", name: "Chronic tubotympanic suppurative otitis media" },
    { id: "C2937222", name: "Chronic ulcerative proctitis" },
    { id: "C0267390", name: "Chronic ulcerative rectosigmoiditis" },
    { id: "C0162810", name: "Cicatrix, Hypertrophic" },
    { id: "C1096254", name: "Citrobacter sepsis" },
    { id: "C0175683", name: "Citrullinemia" },
    { id: "C0751434", name: "Classical phenylketonuria" },
    { id: "C0343386", name: "Clostridium difficile infection" },
    { id: "C0009062", name: "Clostridium Infections" },
    { id: "C0009088", name: "Cluster Headache" },
    { id: "C0009186", name: "Coccidioidomycosis" },
    { id: "C0009443", name: "Common Cold" },
    { id: "C0043037", name: "Common wart" },
    { id: "C3875286", name: "Complex dyslipidemia" },
    { id: "C1632842", name: "Complicated appendicitis" },
    { id: "C0009663", name: "Condylomata Acuminata" },
    { id: "C0001627", name: "Congenital adrenal hyperplasia" },
    { id: "C0242855", name: "Congenital atresia of pulmonary valve" },
    { id: "C2919522", name: "Congenital hemolytic uremic syndrome" },
    { id: "C0010308", name: "Congenital Hypothyroidism" },
    { id: "C0340970", name: "Congenital neutropenia" },
    { id: "C0018802", name: "Congestive heart failure" },
    { id: "C0009769", name: "Conjunctivitis, Giant Papillary" },
    { id: "C0009773", name: "Conjunctivitis, Vernal" },
    { id: "C0009806", name: "Constipation" },
    { id: "C0011616", name: "Contact Dermatitis" },
    { id: "C0263283", name: "Contact dermatitis due to Rhus diversiloba" },
    { id: "C0002875", name: "Cooley's anemia" },
    { id: "C0010032", name: "Corneal abrasion" },
    { id: "C0339293", name: "Corneal Perforation" },
    { id: "C0010043", name: "Corneal Ulcer" },
    { id: "C0157726", name: "Corns and callus" },
    { id: "C0010200", name: "Coughing" },
    { id: "C0010346", name: "Crohn Disease" },
    { id: "C1960764", name: "Crohn's disease in remission" },
    { id: "C2316212", name: "Cryopyrin-Associated Periodic Syndromes" },
    { id: "C0010417", name: "Cryptorchidism" },
    { id: "C0010418", name: "Cryptosporidiosis" },
    { id: "C0003177", name: "Cutaneous anthrax" },
    { id: "C0006846", name: "Cutaneous Candidiasis" },
    { id: "C0221023", name: "Cyclic neutropenia" },
    { id: "C0010543", name: "Cyclitis" },
    { id: "C0235238", name: "Cycloplegia" },
    { id: "C0010674", name: "Cystic Fibrosis" },
    { id: "C0010691", name: "Cystinuria" },
    { id: "C0010692", name: "Cystitis" },
    { id: "C0853852", name: "Cystitis escherichia" },
    { id: "C0206178", name: "Cytomegalovirus Retinitis" },
    { id: "C0010930", name: "Dacryocystitis" },
    { id: "C1563054", name: "Dapsone resistant mycobacterium leprae disease" },
    { id: "C0581377", name: "Decompensated cardiac failure" },
    { id: "C1827266", name: "Decompensated chronic heart failure" },
    { id: "C0149871", name: "Deep Vein Thrombosis" },
    { id: "C0342731", name: "Deficiency of mevalonate kinase" },
    { id: "C0342527", name: "Deficiency of testosterone biosynthesis" },
    { id: "C0029408", name: "Degenerative polyarthritis" },
    { id: "C0034012", name: "Delayed Puberty" },
    {
      id: "C0342827",
      name: "Delta-4-3-oxosteroid-5-beta-reductase deficiency",
    },
    { id: "C0497327", name: "Dementia" },
    { id: "C1828079", name: "Dementia associated with Parkinson's Disease" },
    { id: "C0005587", name: "Depression, Bipolar" },
    { id: "C0011581", name: "Depressive disorder" },
    { id: "C0011608", name: "Dermatitis Herpetiformis" },
    { id: "C0011615", name: "Dermatitis, Atopic" },
    { id: "C0392784", name: "Dermatofibrosarcoma Protuberans" },
    { id: "C0037274", name: "Dermatologic disorders" },
    { id: "C0011633", name: "Dermatomyositis" },
    { id: "C0011849", name: "Diabetes Mellitus" },
    { id: "C0011854", name: "Diabetes Mellitus, Insulin-Dependent" },
    { id: "C0011860", name: "Diabetes Mellitus, Non-Insulin-Dependent" },
    { id: "C0744130", name: "Diabetic foot infection" },
    { id: "C0730285", name: "Diabetic macular edema" },
    { id: "C0011881", name: "Diabetic Nephropathy" },
    { id: "C0740447", name: "Diabetic peripheral neuropathy" },
    { id: "C0011884", name: "Diabetic Retinopathy" },
    { id: "C0011974", name: "Diaper Rash" },
    { id: "C0011991", name: "Diarrhea" },
    { id: "C1262211", name: "Diarrhoea predominant irritable bowel syndrome" },
    { id: "C0079744", name: "Diffuse Large B-Cell Lymphoma" },
    { id: "C0012546", name: "Diphtheria" },
    { id: "C0012561", name: "Diphyllobothriasis" },
    { id: "C0151907", name: "Discoloration of skin" },
    { id: "C1704431", name: "Disorder of electrolytes" },
    { id: "C0015397", name: "Disorder of eye" },
    { id: "C0475811", name: "Disorder of keratinization" },
    { id: "C0012734", name: "Disruptive Behavior Disorder" },
    { id: "C0012739", name: "Disseminated Intravascular Coagulation" },
    { id: "C0276725", name: "Disseminated sporotrichosis" },
    { id: "C0349774", name: "Diverticulitis of gastrointestinal tract" },
    { id: "C0272373", name: "Drug-induced coagulation inhibitor disorder" },
    { id: "C0267511", name: "Drug-induced constipation" },
    { id: "C1274988", name: "Drug-induced mucositis" },
    { id: "C0264115", name: "Drug-induced osteoporosis" },
    { id: "C0013238", name: "Dry Eye Syndromes" },
    { id: "C0151908", name: "Dry skin" },
    { id: "C0013295", name: "Duodenal Ulcer" },
    { id: "C0410065", name: "Dupuytren's disease of finger, with contracture" },
    { id: "C0013369", name: "Dysentery" },
    { id: "C0013390", name: "Dysmenorrhea" },
    { id: "C1384606", name: "Dyspareunia" },
    { id: "C0013395", name: "Dyspepsia" },
    { id: "C0013404", name: "Dyspnea" },
    { id: "C0013428", name: "Dysuria" },
    {
      id: "C0275842",
      name: "Early latent syphilis, positive serology, negative cerebrospinal fluid, less than 2 years after infection",
    },
    { id: "C0152068", name: "Echinococcus granulosus infection" },
    { id: "C0153289", name: "Echinococcus granulosus infection of liver" },
    { id: "C0153290", name: "Echinococcus granulosus infection of lung" },
    { id: "C0156677", name: "Eclampsia in pregnancy" },
    { id: "C0276085", name: "Ecthyma gangrenosum" },
    { id: "C0013595", name: "Eczema" },
    { id: "C0013604", name: "Edema" },
    { id: "C0013882", name: "Elephantiasis" },
    { id: "C0013922", name: "Embolism" },
    { id: "C0014013", name: "Empyema, Pleural" },
    { id: "C0041472", name: "Endemic Flea-Borne Typhus" },
    { id: "C0018022", name: "Endemic goiter" },
    { id: "C0854528", name: "Endocarditis haemophilus" },
    { id: "C0476089", name: "Endometrial Carcinoma" },
    { id: "C0014175", name: "Endometriosis" },
    { id: "C0014179", name: "Endometritis" },
    { id: "C0269050", name: "Endomyometritis" },
    { id: "C0275982", name: "Enteric campylobacteriosis" },
    { id: "C1096258", name: "Enterobacter pneumonia" },
    { id: "C0014347", name: "Enterobacteriaceae Infections" },
    { id: "C0086227", name: "Enterobiasis" },
    { id: "C0014356", name: "Enterocolitis" },
    { id: "C0014358", name: "Enterocolitis, Pseudomembranous" },
    { id: "C1527407", name: "Eosinophilic Pneumonia" },
    { id: "C0014488", name: "Epicondylitis" },
    {
      id: "C1960925",
      name: "Epidermal growth factor receptor positive non-small cell lung cancer",
    },
    { id: "C0014550", name: "Epilepsies, Myoclonic" },
    { id: "C0014547", name: "Epilepsies, Partial" },
    { id: "C0014544", name: "Epilepsy" },
    {
      id: "C2711653",
      name: "Epilepsy characterized by intractable complex partial seizures",
    },
    { id: "C1849508", name: "EPILEPSY, PYRIDOXINE-DEPENDENT" },
    { id: "C0270846", name: "Epileptic drop attack" },
    { id: "C0341823", name: "Epithelial tumor of ovary" },
    { id: "C0242350", name: "Erectile dysfunction" },
    { id: "C0267055", name: "Erosive esophagitis" },
    { id: "C0014733", name: "Erysipelas" },
    { id: "C0014742", name: "Erythema Multiforme" },
    { id: "C0343467", name: "Erythema nodosum leprosum" },
    { id: "C0014752", name: "Erythrasma" },
    { id: "C0162568", name: "Erythropoietic Protoporphyria" },
    { id: "C0014836", name: "Escherichia coli Infections" },
    { id: "C0276088", name: "Escherichia coli septicemia" },
    { id: "C0577708", name: "Escherichia coli urinary tract infection" },
    { id: "C0014877", name: "Esotropia" },
    { id: "C0413194", name: "Ethylene glycol poisoning (disorder)" },
    { id: "C0553580", name: "Ewings sarcoma" },
    { id: "C0349790", name: "Exacerbation of asthma" },
    { id: "C0581392", name: "Exacerbation of multiple sclerosis" },
    { id: "C0015230", name: "Exanthema" },
    { id: "C0424620", name: "Excess subcutaneous fat" },
    { id: "C0011606", name: "Exfoliative dermatitis" },
    { id: "C0267963", name: "Exocrine pancreatic insufficiency" },
    { id: "C0015371", name: "Extrapyramidal Disorders" },
    { id: "C0002390", name: "Extrinsic allergic alveolitis" },
    { id: "C0271084", name: "Exudative age-related macular degeneration" },
    { id: "C0002986", name: "Fabry Disease" },
    { id: "C2677857", name: "Facial hyperpigmentation" },
    { id: "C0272325", name: "Factor 8 deficiency, acquired" },
    { id: "C3203356", name: "Factor II deficiency" },
    { id: "C0015503", name: "Factor VII Deficiency" },
    { id: "C0221032", name: "Familial generalized lipodystrophy" },
    { id: "C0342882", name: "Familial hypercholesterolemia - heterozygous" },
    { id: "C0342881", name: "Familial hypercholesterolemia - homozygous" },
    { id: "C0031069", name: "Familial Mediterranean Fever" },
    { id: "C1263758", name: "Female genital tract infection" },
    { id: "C0271578", name: "Female hypogonadism syndrome" },
    { id: "C0021361", name: "Female infertility" },
    { id: "C0349734", name: "Female pelvic cellulitis" },
    { id: "C0038437", name: "Female stress incontinence" },
    { id: "C0015967", name: "Fever" },
    { id: "C0016053", name: "Fibromyalgia" },
    { id: "C1272826", name: "FIGO stage finding for vulvar carcinoma" },
    { id: "C0016412", name: "Folic Acid Deficiency" },
    { id: "C0206682", name: "Follicular thyroid carcinoma" },
    { id: "C0016436", name: "Folliculitis" },
    { id: "C0339170", name: "Fungal conjunctivitis" },
    { id: "C0151874", name: "Fungal infection of lung" },
    { id: "C1262117", name: "Fungal keratitis" },
    { id: "C2960008", name: "Fungal septicemia" },
    { id: "C0016867", name: "Furunculosis" },
    { id: "C0276758", name: "Fusariosis" },
    { id: "C1318559", name: "Fusospirochetal pharyngitis" },
    { id: "C0343500", name: "Gas gangrene caused by clostridium perfringens" },
    { id: "C0278701", name: "Gastric Adenocarcinoma" },
    { id: "C0581358", name: "Gastric spasm (disorder)" },
    { id: "C0038358", name: "Gastric ulcer" },
    { id: "C0017168", name: "Gastroesophageal reflux disease" },
    { id: "C0152945", name: "Gastrointestinal anthrax" },
    { id: "C0343886", name: "Gastrointestinal candidiasis" },
    { id: "C0238198", name: "Gastrointestinal Stromal Tumors" },
    { id: "C0152020", name: "Gastroparesis" },
    { id: "C0267176", name: "Gastroparesis due to diabetes mellitus" },
    { id: "C0017205", name: "Gaucher Disease" },
    { id: "C1961835", name: "Gaucher Disease, Type 1" },
    { id: "C0270549", name: "Generalized Anxiety Disorder" },
    { id: "C0342751", name: "Generalized glycogen storage disease of infants" },
    { id: "C2936781", name: "Generalized Myotonia of Thomsen" },
    { id: "C0019342", name: "Genital Herpes" },
    { id: "C1279247", name: "Genitourinary tract infection" },
    { id: "C1135868", name: "Gestational Trophoblastic Neoplasms" },
    { id: "C0039483", name: "Giant Cell Arteritis" },
    { id: "C0017536", name: "Giardiasis" },
    { id: "C0040517", name: "Gilles de la Tourette syndrome" },
    { id: "C0017563", name: "Gingival Diseases" },
    { id: "C0017574", name: "Gingivitis" },
    { id: "C0149704", name: "Gingivostomatitis" },
    { id: "C0017601", name: "Glaucoma" },
    { id: "C0017612", name: "Glaucoma, Open-Angle" },
    { id: "C1621958", name: "Glioblastoma Multiforme" },
    { id: "C0349543", name: "Glioblastoma multiforme of brain" },
    { id: "C0154731", name: "Glossopharyngeal Neuralgia" },
    { id: "C0018021", name: "Goiter" },
    { id: "C0153227", name: "Gonococcal endocarditis" },
    { id: "C0744451", name: "GONOCOCCAL INFECTION DISSEMINATED" },
    { id: "C0153216", name: "Gonococcal joint infection" },
    { id: "C0153225", name: "Gonococcal meningitis" },
    { id: "C0018081", name: "Gonorrhea" },
    { id: "C0149966", name: "Gonorrhea of pharynx" },
    { id: "C0275665", name: "Gonorrhea of rectum" },
    { id: "C0018099", name: "Gout" },
    { id: "C0085074", name: "Granuloma Annulare" },
    { id: "C0018190", name: "Granuloma Inguinale" },
    { id: "C1535478", name: "Granulomatosis infantiseptica" },
    { id: "C0018213", name: "Graves Disease" },
    { id: "C0234925", name: "Greasy skin" },
    { id: "C0151686", name: "Growth retardation" },
    { id: "C0276026", name: "Haemophilus influenzae pneumonia" },
    { id: "C2028293", name: "Haemophilus influenzae type b infection" },
    { id: "C0877633", name: "Haemophilus parainfluenzae pneumonia" },
    { id: "C0854324", name: "Haemophilus sepsis" },
    { id: "C0023443", name: "Hairy Cell Leukemia" },
    { id: "C0085786", name: "Hamman-Rich syndrome" },
    { id: "C0677607", name: "Hashimoto Disease" },
    { id: "C0018621", name: "Hay fever" },
    { id: "C0393735", name: "Headache Disorders" },
    { id: "C1961112", name: "Heart Decompensation" },
    { id: "C0018834", name: "Heartburn" },
    { id: "C0343378", name: "Helicobacter-associated gastritis" },
    { id: "C0018916", name: "Hemangioma" },
    { id: "C0238425", name: "Hemoglobin SS disease with crisis" },
    { id: "C0019069", name: "Hemophilia A" },
    { id: "C0008533", name: "Hemophilia B" },
    { id: "C0019080", name: "Hemorrhage" },
    { id: "C0019112", name: "Hemorrhoids" },
    { id: "C0573789", name: "Heparin overdose" },
    {
      id: "C0272275",
      name: "Heparin-induced thrombocytopenia with thrombosis",
    },
    { id: "C0019147", name: "Hepatic Coma" },
    { id: "C0019151", name: "Hepatic Encephalopathy" },
    { id: "C0019156", name: "Hepatic Veno-Occlusive Disease" },
    { id: "C0524909", name: "Hepatitis B, Chronic" },
    { id: "C0524910", name: "Hepatitis C, Chronic" },
    { id: "C0019202", name: "Hepatolenticular Degeneration" },
    { id: "C1960398", name: "HER2-positive carcinoma of breast" },
    {
      id: "C0398775",
      name: "Hereditary C1 esterase inhibitor deficiency - deficient factor",
    },
    { id: "C2584877", name: "Hereditary factor XIII A subunit deficiency" },
    { id: "C0220987", name: "Hereditary orotic aciduria" },
    { id: "C0276226", name: "Herpes encephalitis" },
    { id: "C0019345", name: "Herpes Labialis" },
    { id: "C0349719", name: "Herpes simplex infection of skin" },
    { id: "C0019348", name: "Herpes Simplex Infections" },
    { id: "C0276228", name: "Herpes simplex keratoconjunctivitis" },
    { id: "C0019360", name: "Herpes zoster disease" },
    { id: "C1275687", name: "Herpes zoster keratitis" },
    { id: "C0153027", name: "Herpes zoster keratoconjunctivitis" },
    { id: "C0029396", name: "Heterotopic Ossification" },
    { id: "C0162836", name: "Hidradenitis Suppurativa" },
    { id: "C0019655", name: "Histoplasmosis" },
    { id: "C0019693", name: "HIV Infections" },
    { id: "C0019829", name: "Hodgkin Disease" },
    { id: "C0019880", name: "Homocystinuria" },
    {
      id: "C1562029",
      name: "Hormone receptor positive malignant neoplasm of breast",
    },
    { id: "C0600142", name: "Hot flushes" },
    {
      id: "C2316304",
      name: "Human epidermal growth factor 2 negative carcinoma of breast",
    },
    { id: "C0149911", name: "Humoral hypercalcemia of malignancy (disorder)" },
    { id: "C0549315", name: "Hydatidiform mole, benign" },
    { id: "C0020428", name: "Hyperaldosteronism" },
    { id: "C0220994", name: "Hyperammonemia" },
    { id: "C0020437", name: "Hypercalcemia" },
    { id: "C0020443", name: "Hypercholesterolemia" },
    { id: "C0020459", name: "Hyperinsulinism" },
    { id: "C0020461", name: "Hyperkalemia" },
    { id: "C0870082", name: "Hyperkeratosis" },
    { id: "C0020473", name: "Hyperlipidemia" },
    { id: "C0020474", name: "Hyperlipidemia, Familial Combined" },
    { id: "C0020479", name: "Hyperlipoproteinemia Type III" },
    { id: "C0151714", name: "Hypermagnesemia" },
    { id: "C0020502", name: "Hyperparathyroidism" },
    { id: "C0085681", name: "Hyperphosphatemia (disorder)" },
    { id: "C0020514", name: "Hyperprolactinemia" },
    { id: "C0001207", name: "Hypersomatotropic gigantism" },
    { id: "C0020538", name: "Hypertensive disease" },
    { id: "C0745136", name: "Hypertensive emergency" },
    { id: "C0745138", name: "Hypertensive urgency" },
    { id: "C0020550", name: "Hyperthyroidism" },
    { id: "C0155213", name: "Hypertrichosis of eyelid" },
    { id: "C0020557", name: "Hypertriglyceridemia" },
    { id: "C0007194", name: "Hypertrophic Cardiomyopathy" },
    { id: "C0740394", name: "Hyperuricemia" },
    { id: "C0020594", name: "Hypoactive Sexual Desire Disorder" },
    { id: "C0473527", name: "Hypoalphalipoproteinemias" },
    { id: "C0020598", name: "Hypocalcemia" },
    { id: "C0151940", name: "Hypocalcemic tetany" },
    { id: "C0020615", name: "Hypoglycemia" },
    { id: "C0271623", name: "Hypogonadotropic hypogonadism" },
    { id: "C0020621", name: "Hypokalemia" },
    { id: "C0151723", name: "Hypomagnesemia" },
    { id: "C0020625", name: "Hyponatremia" },
    { id: "C0020626", name: "Hypoparathyroidism" },
    { id: "C0020630", name: "Hypophosphatasia" },
    { id: "C0085682", name: "Hypophosphatemia" },
    { id: "C0020635", name: "Hypopituitarism" },
    { id: "C0178416", name: "Hypoplastic anemia" },
    { id: "C0020649", name: "Hypotension" },
    { id: "C0020651", name: "Hypotension, Orthostatic" },
    { id: "C0020676", name: "Hypothyroidism" },
    { id: "C0020683", name: "Hypovolemic Shock" },
    { id: "C0206141", name: "Idiopathic Hypereosinophilic Syndrome" },
    { id: "C0700053", name: "Idiopathic hypertrophic subaortic stenosis" },
    { id: "C0021092", name: "Impacted cerumen" },
    { id: "C0021099", name: "Impetigo" },
    { id: "C0021141", name: "Inappropriate ADH Syndrome" },
    { id: "C0009770", name: "Inclusion conjunctivitis" },
    { id: "C0426639", name: "Incomplete passage of stool" },
    { id: "C0000810", name: "Incomplete spontaneous abortion" },
    { id: "C0042023", name: "Increased frequency of micturition" },
    { id: "C0751122", name: "Infantile Severe Myoclonic Epilepsy" },
    { id: "C0275979", name: "Infection by Campylobacter fetus" },
    { id: "C0010414", name: "Infection by Cryptococcus neoformans" },
    { id: "C1260911", name: "Infection by Pasteurella multocida" },
    { id: "C0040954", name: "Infection by Trichuris trichiura" },
    { id: "C0041648", name: "Infection by Uncinaria" },
    { id: "C0948205", name: "Infection caused by Enterobacter" },
    { id: "C0854328", name: "Infection due to anaerobic bacteria" },
    {
      id: "C1276801",
      name: "Infection due to Erysipelothrix rhusiopathiae (disorder)",
    },
    { id: "C0276075", name: "Infection due to Pseudomonas aeruginosa" },
    { id: "C2242472", name: "Infection of bone" },
    { id: "C0178299", name: "Infection of skin AND/OR subcutaneous tissue" },
    { id: "C0729587", name: "Infective blepharitis" },
    { id: "C0600041", name: "Infective cystitis" },
    { id: "C0021355", name: "Infective otitis externa" },
    { id: "C0729586", name: "Infective otitis media" },
    { id: "C0030759", name: "Infestation by Phthirus pubis" },
    { id: "C0021390", name: "Inflammatory Bowel Diseases" },
    { id: "C3875321", name: "Inflammatory dermatosis" },
    { id: "C0021400", name: "Influenza" },
    { id: "C2711180", name: "Influenza due to Influenza A virus" },
    { id: "C0392171", name: "Influenza-like symptoms" },
    { id: "C0155866", name: "Inhalational anthrax" },
    { id: "C0393760", name: "Initial insomnia" },
    { id: "C0043250", name: "Injury wounds" },
    { id: "C0021670", name: "insulinoma" },
    { id: "C0021775", name: "Intermittent Claudication" },
    { id: "C0282488", name: "Interstitial Cystitis" },
    { id: "C1112209", name: "Intraabdominal Infections" },
    { id: "C0744896", name: "Intractable hiccups" },
    { id: "C0155880", name: "Intrinsic asthma" },
    { id: "C1134719", name: "Invasive Ductal Breast Carcinoma" },
    { id: "C0276653", name: "Invasive Pulmonary Aspergillosis" },
    { id: "C0022073", name: "Iridocyclitis" },
    { id: "C0022081", name: "Iritis" },
    { id: "C0162316", name: "Iron deficiency anemia" },
    { id: "C0282193", name: "Iron Overload" },
    { id: "C0022104", name: "Irritable Bowel Syndrome" },
    {
      id: "C1868889",
      name: "Irritable bowel syndrome characterized by constipation",
    },
    { id: "C0348898", name: "Irritable bowel syndrome with diarrhea" },
    { id: "C2711256", name: "Ischemic priapism" },
    { id: "C0948008", name: "Ischemic stroke" },
    { id: "C0849907", name: "Itching of ear" },
    { id: "C0022281", name: "Itching of eye" },
    { id: "C0022354", name: "Jaundice, Obstructive" },
    { id: "C3495559", name: "Juvenile arthritis" },
    { id: "C0036220", name: "Kaposi Sarcoma" },
    { id: "C0022548", name: "Keloid" },
    { id: "C0022568", name: "Keratitis" },
    { id: "C0022570", name: "Keratitis, Dendritic" },
    { id: "C0019357", name: "Keratitis, Herpetic" },
    { id: "C0022573", name: "Keratoconjunctivitis" },
    { id: "C0022575", name: "Keratoconjunctivitis Sicca" },
    { id: "C0022577", name: "Keratoconjunctivitis, Vernal" },
    { id: "C0520775", name: "Klebsiella cystitis" },
    { id: "C0022729", name: "Klebsiella Infections" },
    { id: "C0745528", name: "Klebsiella sepsis" },
    { id: "C0022735", name: "Klinefelter Syndrome" },
    { id: "C0474368", name: "Labor Pain" },
    { id: "C0022951", name: "Lactose Intolerance" },
    { id: "C0022972", name: "Lambert-Eaton Myasthenic Syndrome" },
    { id: "C0271568", name: "Laron Syndrome" },
    { id: "C0546999", name: "Larva Migrans, Cutaneous" },
    { id: "C0023052", name: "Laryngeal Edema" },
    { id: "C0280324", name: "Laryngeal Squamous Cell Carcinoma" },
    { id: "C1260915", name: "Late latent syphilis" },
    { id: "C0153240", name: "Latent yaws" },
    { id: "C0857846", name: "Legionella pneumophila pneumonia" },
    { id: "C0023241", name: "Legionnaires' Disease" },
    { id: "C0023269", name: "leiomyosarcoma" },
    { id: "C0023281", name: "Leishmaniasis" },
    { id: "C0023283", name: "Leishmaniasis, Cutaneous" },
    { id: "C0023290", name: "Leishmaniasis, Visceral" },
    { id: "C0238111", name: "Lennox-Gastaut syndrome" },
    { id: "C0023343", name: "Leprosy" },
    { id: "C1562585", name: "Leprosy, Multibacillary" },
    { id: "C0023381", name: "Letterer-Siwe Disease" },
    { id: "C0023467", name: "Leukemia, Myelocytic, Acute" },
    { id: "C0023472", name: "Leukemia, Myeloid, Accelerated Phase" },
    { id: "C0023474", name: "Leukemia, Myeloid, Chronic-Phase" },
    { id: "C0023646", name: "Lichen Planus" },
    { id: "C0149922", name: "Lichen Simplex Chronicus" },
    {
      id: "C3854116",
      name: "Lipodystrophy due to Human immunodeficiency virus infection and antiretroviral therapy",
    },
    { id: "C0023827", name: "liposarcoma" },
    { id: "C0348868", name: "Listerial endocarditis" },
    { id: "C0023860", name: "Listeriosis" },
    { id: "C0023885", name: "Liver Abscess" },
    { id: "C0023886", name: "Liver Abscess, Amebic" },
    { id: "C2239176", name: "Liver carcinoma" },
    { id: "C0400968", name: "Liver transplant rejection" },
    { id: "C0023968", name: "Loiasis" },
    { id: "C0600177", name: "Low Cardiac Output Syndrome" },
    { id: "C0149725", name: "Lower respiratory tract infection" },
    { id: "C0024110", name: "Lung Abscess" },
    { id: "C0024115", name: "Lung diseases" },
    { id: "C0024138", name: "Lupus Erythematosus, Discoid" },
    { id: "C0024141", name: "Lupus Erythematosus, Systemic" },
    { id: "C0024198", name: "Lyme Disease" },
    { id: "C0024286", name: "Lymphogranuloma Venereum" },
    { id: "C0024299", name: "Lymphoma" },
    { id: "C0024301", name: "Lymphoma, Follicular" },
    { id: "C0024305", name: "Lymphoma, Non-Hodgkin" },
    { id: "C0079773", name: "Lymphoma, T-Cell, Cutaneous" },
    { id: "C1269683", name: "Major Depressive Disorder" },
    { id: "C0024530", name: "Malaria" },
    { id: "C0024535", name: "Malaria, Falciparum" },
    { id: "C0024537", name: "Malaria, Vivax" },
    { id: "C0220656", name: "Malignant ascites" },
    { id: "C0024586", name: "Malignant Carcinoid Syndrome" },
    { id: "C3163805", name: "Malignant epithelial neoplasm of female breast" },
    { id: "C3163939", name: "Malignant epithelial neoplasm of thyroid" },
    { id: "C0346161", name: "Malignant epithelial tumor of ovary" },
    { id: "C0024588", name: "Malignant essential hypertension" },
    { id: "C0271152", name: "Malignant glaucoma" },
    { id: "C0555198", name: "Malignant Glioma" },
    { id: "C0024591", name: "Malignant hyperpyrexia due to anesthesia" },
    {
      id: "C0334634",
      name: "Malignant lymphoma, lymphocytic, intermediate differentiation, diffuse",
    },
    { id: "C0860594", name: "Malignant melanoma, metastatic" },
    { id: "C0153633", name: "Malignant neoplasm of brain" },
    { id: "C0546837", name: "Malignant neoplasm of esophagus" },
    { id: "C0153579", name: "Malignant neoplasm of fallopian tube" },
    { id: "C0345904", name: "Malignant neoplasm of liver" },
    { id: "C0242379", name: "Malignant neoplasm of lung" },
    { id: "C1140680", name: "Malignant neoplasm of ovary" },
    { id: "C0153601", name: "Malignant neoplasm of penis" },
    { id: "C0376358", name: "Malignant neoplasm of prostate" },
    { id: "C0024623", name: "Malignant neoplasm of stomach" },
    { id: "C0278498", name: "Malignant neoplasm of stomach stage IV" },
    { id: "C0153594", name: "Malignant neoplasm of testis" },
    { id: "C0007115", name: "Malignant neoplasm of thyroid" },
    { id: "C0005684", name: "Malignant neoplasm of urinary bladder" },
    {
      id: "C0395818",
      name: "Malignant otitis externa due to Pseudomonas aeruginosa",
    },
    { id: "C0220655", name: "Malignant pericardial effusion" },
    { id: "C0812413", name: "Malignant Pleural Mesothelioma" },
    { id: "C0007847", name: "Malignant tumor of cervix" },
    { id: "C0007102", name: "Malignant tumor of colon" },
    { id: "C0153467", name: "Malignant tumor of peritoneum" },
    { id: "C0221013", name: "Mastocytosis, Systemic" },
    { id: "C0238462", name: "Medullary carcinoma of thyroid" },
    { id: "C0151482", name: "Megaloblastic anemia due to folate deficiency" },
    { id: "C1275684", name: "Meibomian gland dysfunction" },
    { id: "C0549398", name: "Meibomianitis" },
    { id: "C0025202", name: "melanoma" },
    { id: "C0025281", name: "Meniere Disease" },
    { id: "C0948840", name: "Meningeal Leukemia" },
    { id: "C0025289", name: "Meningitis" },
    { id: "C0865436", name: "Meningitis due to Bacteroides" },
    { id: "C1719906", name: "Meningitis due to Klebsiella mobilis" },
    { id: "C0085437", name: "Meningitis, Bacterial" },
    { id: "C0085436", name: "Meningitis, Cryptococcal" },
    { id: "C0338395", name: "Meningitis, Escherichia coli" },
    { id: "C0085438", name: "Meningitis, Fungal" },
    { id: "C0025293", name: "Meningitis, Listeria" },
    { id: "C0025295", name: "Meningitis, Pneumococcal" },
    { id: "C0025294", name: "Meningococcal meningitis" },
    { id: "C0025306", name: "Meningococcemia" },
    { id: "C0421162", name: "Meningococcus carrier" },
    { id: "C0025323", name: "Menorrhagia" },
    { id: "C1282500", name: "Metastasis from malignant tumor of colon" },
    {
      id: "C1704231",
      name: "Metastatic Malignant Neoplasm to the Leptomeninges",
    },
    { id: "C0936223", name: "Metastatic Prostate Carcinoma" },
    { id: "C0278678", name: "Metastatic Renal Cell Cancer" },
    { id: "C0568062", name: "Methotrexate poisoning" },
    { id: "C0025874", name: "Metrorrhagia" },
    { id: "C0149931", name: "Migraine Disorders" },
    { id: "C0269658", name: "Mild pre-eclampsia" },
    { id: "C0687148", name: "Mineral deficiency" },
    { id: "C0269398", name: "Miscarriage with sepsis" },
    { id: "C0338908", name: "Mixed anxiety and depressive disorder" },
    { id: "C0236780", name: "Mixed bipolar I disorder" },
    { id: "C1398376", name: "mixed; epileptic" },
    { id: "C0857831", name: "Moraxella catarrhalis pneumonia" },
    { id: "C1527383", name: "Morphea" },
    { id: "C0026603", name: "Motion Sickness" },
    { id: "C0234978", name: "Motor cortex epilepsy" },
    { id: "C0235339", name: "Mouth irritation" },
    { id: "C0006848", name: "Mucocutaneous candidiasis" },
    { id: "C1328252", name: "Mucocutaneous leishmaniasis" },
    { id: "C0023786", name: "Mucopolysaccharidosis I" },
    { id: "C0026705", name: "Mucopolysaccharidosis II" },
    { id: "C0026709", name: "Mucopolysaccharidosis VI" },
    { id: "C0086651", name: "Mucopolysaccharidosis, MPS-IV-A" },
    { id: "C0026718", name: "Mucormycosis" },
    { id: "C0392190", name: "Mucositis following radiation therapy" },
    { id: "C0027662", name: "Multiple Endocrine Neoplasia" },
    { id: "C0026764", name: "Multiple Myeloma" },
    { id: "C0026769", name: "Multiple Sclerosis" },
    { id: "C0751967", name: "Multiple Sclerosis, Relapsing-Remitting" },
    { id: "C0026838", name: "Muscle Spasticity" },
    { id: "C1562430", name: "Muscle spasticity of spinal origin" },
    { id: "C0013264", name: "Muscular Dystrophy, Duchenne" },
    { id: "C0026857", name: "Musculoskeletal Diseases" },
    { id: "C0746683", name: "MUSCULOSKELETAL PAIN CHRONIC" },
    { id: "C0231528", name: "Myalgia" },
    { id: "C0026896", name: "Myasthenia Gravis" },
    { id: "C0024449", name: "Mycetoma" },
    { id: "C0032302", name: "Mycoplasma pneumonia" },
    { id: "C0026948", name: "Mycosis Fungoides" },
    { id: "C0276648", name: "Mycotic endocarditis" },
    { id: "C0026961", name: "Mydriasis" },
    { id: "C0026987", name: "Myelofibrosis" },
    { id: "C0023473", name: "Myeloid Leukemia, Chronic" },
    { id: "C0027022", name: "Myeloproliferative disease" },
    { id: "C0027051", name: "Myocardial Infarction" },
    { id: "C0027145", name: "Myxedema" },
    { id: "C0238298", name: "Myxedema coma" },
    { id: "C0027404", name: "Narcolepsy" },
    { id: "C0751362", name: "Narcolepsy-Cataplexy Syndrome" },
    { id: "C0027424", name: "Nasal congestion (finding)" },
    { id: "C0027430", name: "Nasal Polyps" },
    { id: "C0027497", name: "Nausea" },
    { id: "C0027498", name: "Nausea and vomiting" },
    { id: "C0392445", name: "Necrobiosis Lipoidica Diabeticorum" },
    { id: "C0017575", name: "Necrotizing Ulcerative Gingivitis" },
    { id: "C0342634", name: "Neonatal hypocalcemia" },
    { id: "C0456107", name: "Neonatal meningitis" },
    { id: "C0339968", name: "Neonatal pneumonia" },
    { id: "C0027708", name: "Nephroblastoma" },
    { id: "C2931187", name: "Nephropathic cystinosis" },
    { id: "C0027726", name: "Nephrotic Syndrome" },
    { id: "C0027773", name: "Nesidioblastosis" },
    { id: "C0027796", name: "Neuralgia" },
    { id: "C0027819", name: "Neuroblastoma" },
    { id: "C0338437", name: "Neurocysticercosis" },
    { id: "C0005697", name: "Neurogenic Urinary Bladder" },
    { id: "C0406526", name: "Neuropathic diabetic ulcer - foot" },
    { id: "C0027927", name: "Neurosyphilis" },
    { id: "C0028043", name: "Nicotine Dependence" },
    { id: "C0220756", name: "Niemann-Pick Disease, Type C" },
    { id: "C0028242", name: "Nocardia Infections" },
    { id: "C0028734", name: "Nocturia" },
    { id: "C0393772", name: "Non-24 hour sleep-wake cycle" },
    { id: "C1112709", name: "non-gonococcal urethritis (NGU)" },
    { id: "C0267436", name: "Non-infective diarrhea" },
    { id: "C0542269", name: "Non-Q wave myocardial infarction" },
    { id: "C0007131", name: "Non-Small Cell Lung Carcinoma" },
    { id: "C2585890", name: "Nonsquamous nonsmall cell neoplasm of lung" },
    { id: "C0028326", name: "Noonan Syndrome" },
    { id: "C0949083", name: "Nosocomial pneumonia" },
    { id: "C0085656", name: "Nummular eczema" },
    { id: "C0276587", name: "Nutritional deficiency associated with AIDS" },
    { id: "C0028754", name: "Obesity" },
    { id: "C0028768", name: "Obsessive-Compulsive Disorder" },
    { id: "C1562543", name: "Ocular histoplasmosis syndrome" },
    { id: "C0028840", name: "Ocular Hypertension" },
    { id: "C0406486", name: "Ocular Rosacea" },
    { id: "C0029001", name: "Onchocerciasis" },
    { id: "C0040261", name: "Onychomycosis" },
    {
      id: "C0276755",
      name: "Onychomycosis due to Trichophyton mentagrophytes",
    },
    { id: "C0276754", name: "Onychomycosis due to Trichophyton rubrum" },
    { id: "C1274469", name: "Onychomycosis of fingernails" },
    { id: "C1274470", name: "Onychomycosis of toenails" },
    { id: "C0029076", name: "Ophthalmia Neonatorum" },
    { id: "C0029077", name: "Ophthalmia, Sympathetic" },
    { id: "C0524662", name: "Opiate Addiction" },
    { id: "C0029104", name: "Opioid withdrawal" },
    { id: "C0029106", name: "Opisthorchiasis" },
    { id: "C0917796", name: "Optic Atrophy, Hereditary, Leber" },
    { id: "C0006849", name: "Oral candidiasis" },
    { id: "C0700359", name: "Organophosphate poisoning" },
    { id: "C0919659", name: "Oropharyngeal candidiasis" },
    { id: "C0029401", name: "Osteitis Deformans" },
    { id: "C0409959", name: "Osteoarthritis, Knee" },
    { id: "C3898069", name: "Osteolytic Lesions of Multiple Myeloma" },
    { id: "C0029442", name: "Osteomalacia" },
    { id: "C0029443", name: "Osteomyelitis" },
    { id: "C1997728", name: "Osteomyelitis due to Staphylococcus aureus" },
    { id: "C0029456", name: "Osteoporosis" },
    { id: "C0029458", name: "Osteoporosis, Postmenopausal" },
    { id: "C0585442", name: "Osteosarcoma of bone" },
    { id: "C0029878", name: "Otitis Externa" },
    { id: "C0152072", name: "Ovale malaria" },
    { id: "C0030193", name: "Pain" },
    { id: "C0423673", name: "Pain in spine" },
    { id: "C0030201", name: "Pain, Postoperative" },
    { id: "C0030319", name: "Panic Disorder" },
    { id: "C0238463", name: "Papillary thyroid carcinoma" },
    { id: "C0030409", name: "Paracoccidioidomycosis" },
    { id: "C0030446", name: "Paralytic Ileus" },
    { id: "C0030528", name: "Paratyphoid Fever" },
    { id: "C0030567", name: "Parkinson Disease" },
    { id: "C0030568", name: "Parkinson Disease, Postencephalitic" },
    { id: "C0242422", name: "Parkinsonian Disorders" },
    { id: "C0030578", name: "Paronychia Inflammation" },
    { id: "C0235480", name: "Paroxysmal atrial fibrillation" },
    { id: "C0741292", name: "Paroxysmal atrial flutter" },
    { id: "C0221154", name: "Paroxysmal hypertension" },
    { id: "C0024790", name: "Paroxysmal nocturnal hemoglobinuria" },
    { id: "C0030590", name: "Paroxysmal supraventricular tachycardia" },
    { id: "C0030636", name: "Pasteurella Infections" },
    { id: "C0013274", name: "Patent ductus arteriosus" },
    { id: "C0030757", name: "Pediculus capitis infestation" },
    { id: "C0030783", name: "Pellagra" },
    { id: "C0030785", name: "Pelvic abscess" },
    { id: "C0242172", name: "Pelvic Inflammatory Disease" },
    {
      id: "C1261256",
      name: "Pelvic inflammatory disease due to Mycoplasma hominis",
    },
    { id: "C0030807", name: "Pemphigus" },
    { id: "C0014869", name: "Peptic Esophagitis" },
    { id: "C0030920", name: "Peptic Ulcer" },
    { id: "C1096562", name: "Peptostreptococcus infection" },
    { id: "C0343730", name: "Perianal warts" },
    { id: "C0031099", name: "Periodontitis" },
    { id: "C3661921", name: "Perioperative hypertension" },
    { id: "C0564750", name: "Peripheral arterial embolism" },
    { id: "C0085649", name: "Peripheral edema" },
    { id: "C0079774", name: "Peripheral T-Cell Lymphoma" },
    { id: "C0085096", name: "Peripheral Vascular Diseases" },
    { id: "C0031154", name: "Peritonitis" },
    { id: "C0031190", name: "Persistent Fetal Circulation Syndrome" },
    { id: "C0043167", name: "Pertussis" },
    { id: "C0030848", name: "Peyronie Disease" },
    { id: "C0031350", name: "Pharyngitis" },
    { id: "C2062475", name: "pharyngitis due to Haemophilus influenzae" },
    { id: "C0031511", name: "Pheochromocytoma" },
    {
      id: "C0279543",
      name: "Philadelphia chromosome positive chronic myelogenous leukemia",
    },
    {
      id: "C1960397",
      name: "Philadelphia chromosome-positive acute lymphoblastic leukemia",
    },
    { id: "C0031572", name: "Phobia, Social" },
    { id: "C0349506", name: "Photosensitivity of skin" },
    { id: "C0031946", name: "Pinta" },
    { id: "C0013338", name: "Pituitary dwarfism" },
    { id: "C0032064", name: "Plague" },
    { id: "C0152936", name: "Plague, Septicemic" },
    { id: "C0042548", name: "Plantar wart" },
    { id: "C0919737", name: "Platelet aggregation inhibition" },
    { id: "C0080032", name: "Pleural Effusion, Malignant" },
    { id: "C0032269", name: "Pneumococcal Infections" },
    { id: "C0275761", name: "Pneumococcal pharyngitis" },
    { id: "C0032285", name: "Pneumonia" },
    { id: "C0276089", name: "Pneumonia due to Escherichia coli" },
    { id: "C0854248", name: "Pneumonia due to Gram negative bacteria" },
    { id: "C0519030", name: "Pneumonia due to Klebsiella pneumoniae" },
    { id: "C0155860", name: "Pneumonia due to Pseudomonas" },
    { id: "C2349530", name: "Pneumonia due to Staphylococcus aureus" },
    { id: "C0004626", name: "Pneumonia, Bacterial" },
    { id: "C0264515", name: "Pneumonia, Necrotizing" },
    { id: "C0524688", name: "Pneumonic Plague" },
    { id: "C0237963", name: "Poisoning by acetaminophen" },
    { id: "C0274726", name: "Poisoning by digitalis glycoside" },
    { id: "C0412842", name: "Poisoning by iron AND/OR its compounds" },
    { id: "C0412850", name: "Poisoning by opiate analgesic drug" },
    { id: "C0274478", name: "Poisoning by pyrimethamine" },
    { id: "C0274462", name: "Poisoning by sulfadiazine" },
    { id: "C0085413", name: "Polycystic Kidney, Autosomal Dominant" },
    { id: "C0032463", name: "Polycythemia Vera" },
    { id: "C0032533", name: "Polymyalgia Rheumatica" },
    { id: "C2894027", name: "Post traumatic osteoarthritis" },
    { id: "C0152253", name: "Posterior synechiae" },
    { id: "C0032768", name: "Postherpetic neuralgia" },
    { id: "C1278797", name: "Postnatal infection" },
    { id: "C0400877", name: "Postoperative ileus" },
    { id: "C0392618", name: "Postoperative infection" },
    { id: "C0032797", name: "Postpartum Hemorrhage" },
    { id: "C0038436", name: "Post-Traumatic Stress Disorder" },
    { id: "C0032897", name: "Prader-Willi Syndrome" },
    { id: "C0349636", name: "Pre B-cell acute lymphoblastic leukemia" },
    { id: "C0034013", name: "Precocious Puberty" },
    {
      id: "C1961099",
      name: "Precursor T-Cell Lymphoblastic Leukemia-Lymphoma",
    },
    { id: "C1292758", name: "Precursor T-cell lymphoblastic lymphoma" },
    { id: "C0033038", name: "Premature Ejaculation" },
    { id: "C0022876", name: "Premature Obstetric Labor" },
    { id: "C0151636", name: "Premature ventricular contractions" },
    { id: "C0520676", name: "Premenstrual Dysphoric Disorder" },
    { id: "C0008312", name: "Primary biliary cirrhosis" },
    { id: "C0017418", name: "Primary genital syphilis" },
    { id: "C0948896", name: "Primary hypogonadism" },
    {
      id: "C1306632",
      name: "Primary malignant neoplasm of gastrointestinal tract",
    },
    { id: "C0033246", name: "Proctitis" },
    { id: "C1739363", name: "Prostatic Hypertrophy" },
    { id: "C0033578", name: "Prostatic Neoplasms" },
    { id: "C0033581", name: "prostatitis" },
    { id: "C0338396", name: "Proteus meningitis" },
    { id: "C1260917", name: "Proteus pneumonia" },
    { id: "C0577690", name: "Proteus septicemia" },
    { id: "C0577709", name: "Proteus urinary tract infection" },
    { id: "C0033774", name: "Pruritus" },
    { id: "C0033775", name: "Pruritus Ani" },
    { id: "C0033777", name: "Pruritus of genital organs" },
    { id: "C2316460", name: "Pseudobulbar affect" },
    { id: "C0854135", name: "Pseudomonas aeruginosa infection" },
    { id: "C3665869", name: "Pseudomonas aeruginosa meningitis" },
    { id: "C0033845", name: "Pseudotumor Cerebri" },
    { id: "C0029291", name: "Psittacosis" },
    { id: "C0033860", name: "Psoriasis" },
    { id: "C0033975", name: "Psychotic Disorders" },
    { id: "C0275566", name: "Pulmonary actinomycosis" },
    { id: "C2973725", name: "Pulmonary arterial hypertension" },
    { id: "C0276688", name: "Pulmonary cryptococcosis" },
    { id: "C0392164", name: "Pulmonary Cystic Fibrosis" },
    { id: "C0034063", name: "Pulmonary Edema" },
    { id: "C0034065", name: "Pulmonary Embolism" },
    { id: "C0034067", name: "Pulmonary Emphysema" },
    { id: "C0034069", name: "Pulmonary Fibrosis" },
    { id: "C0020542", name: "Pulmonary Hypertension" },
    { id: "C0349649", name: "Pulmonary lymphangioleiomyomatosis" },
    { id: "C2733595", name: "Pulmonary Mycobacterium avium complex infection" },
    { id: "C0524702", name: "Pulmonary Thromboembolisms" },
    { id: "C0259799", name: "Punctate keratitis" },
    { id: "C0423234", name: "Punctate keratopathy" },
    { id: "C0034186", name: "Pyelonephritis" },
    { id: "C0748196", name: "PYELONEPHRITIS E COLI" },
    { id: "C0085652", name: "Pyoderma Gangrenosum" },
    { id: "C0034362", name: "Q Fever" },
    { id: "C0340354", name: "Q fever endocarditis" },
    { id: "C0024536", name: "Quartan malaria" },
    { id: "C0034686", name: "Rat-Bite Fever" },
    { id: "C0034734", name: "Raynaud Disease" },
    { id: "C0034886", name: "Rectal pain" },
    { id: "C1274323", name: "Recurrent genital herpes simplex" },
    { id: "C1274321", name: "Recurrent herpes simplex labialis" },
    { id: "C0235267", name: "Redness of eye" },
    { id: "C0035021", name: "Relapsing Fever" },
    { id: "C0748267", name: "RENAL CALCULUS CALCIUM OXALATE" },
    { id: "C0007134", name: "Renal Cell Carcinoma" },
    { id: "C0866125", name: "Renal disease with edema NOS" },
    { id: "C0035086", name: "Renal Osteodystrophy" },
    { id: "C0238217", name: "Renal transplant rejection" },
    { id: "C0001126", name: "Renal tubular acidosis" },
    { id: "C0235063", name: "Respiratory Depression" },
    { id: "C0035220", name: "Respiratory Distress Syndrome, Newborn" },
    { id: "C0035235", name: "Respiratory Syncytial Virus Infections" },
    { id: "C0035243", name: "Respiratory Tract Infections" },
    { id: "C0035258", name: "Restless Legs Syndrome" },
    { id: "C0035335", name: "Retinoblastoma" },
    { id: "C0035412", name: "Rhabdomyosarcoma" },
    { id: "C0035439", name: "Rheumatic Heart Disease" },
    { id: "C0035435", name: "Rheumatism" },
    { id: "C0003873", name: "Rheumatoid Arthritis" },
    { id: "C0035455", name: "Rhinitis" },
    { id: "C0035457", name: "Rhinitis, Allergic, Perennial" },
    { id: "C0035460", name: "Rhinitis, Vasomotor" },
    { id: "C1636205", name: "Rhinocerebral phycomycosis" },
    { id: "C1260880", name: "Rhinorrhea" },
    { id: "C0035468", name: "Rhinoscleroma" },
    { id: "C0035579", name: "Rickets" },
    { id: "C0035585", name: "Rickettsia Infections" },
    { id: "C0035597", name: "Rickettsialpox" },
    { id: "C1960924", name: "Right-sided Staphylococcus aureus endocarditis" },
    { id: "C0035793", name: "Rocky Mountain Spotted Fever" },
    { id: "C0035854", name: "Rosacea" },
    { id: "C0036117", name: "Salmonella infections" },
    { id: "C0152486", name: "Salmonella sepsis" },
    { id: "C0036130", name: "Salpingitis" },
    { id: "C0036202", name: "Sarcoidosis" },
    { id: "C1261473", name: "Sarcoma" },
    { id: "C0036262", name: "Scabies <infestation>" },
    { id: "C0036271", name: "Scalp Dermatoses" },
    { id: "C0406326", name: "Scalp psoriasis" },
    { id: "C0036285", name: "Scarlet Fever" },
    { id: "C0036323", name: "Schistosomiasis" },
    { id: "C0036337", name: "Schizoaffective Disorder" },
    { id: "C0036341", name: "Schizophrenia" },
    { id: "C0011644", name: "Scleroderma" },
    { id: "C0036472", name: "Scrub Typhus" },
    { id: "C0423775", name: "Scurfiness of scalp" },
    { id: "C0085159", name: "Seasonal Affective Disorder" },
    { id: "C0036508", name: "Seborrheic dermatitis" },
    { id: "C0149893", name: "Secondary glaucoma" },
    { id: "C0346974", name: "Secondary malignant neoplasm of colon" },
    { id: "C0346993", name: "Secondary malignant neoplasm of female breast" },
    { id: "C0153685", name: "Secondary malignant neoplasm of kidney" },
    { id: "C0346976", name: "Secondary malignant neoplasm of pancreas" },
    { id: "C0686068", name: "Secondary malignant neoplasm of stomach" },
    { id: "C0232940", name: "Secondary physiologic amenorrhea" },
    { id: "C0036651", name: "Senile lentigo" },
    { id: "C3163829", name: "Sepsis due to Gram negative bacteria" },
    { id: "C2887096", name: "Sepsis due to Pseudomonas" },
    { id: "C2887088", name: "Sepsis due to Staphylococcus aureus" },
    { id: "C0456103", name: "Sepsis of the newborn" },
    { id: "C0860048", name: "Septic arthritis haemophilus" },
    { id: "C0349009", name: "Septicemia candida" },
    { id: "C0152967", name: "Septicemia due to anaerobes" },
    { id: "C0276064", name: "Septicemia due to Bacteroides" },
    { id: "C0588233", name: "Septicemia due to enterococcus" },
    { id: "C0085394", name: "Serratia Infections" },
    { id: "C0152973", name: "Serratia sepsis" },
    { id: "C0036830", name: "Serum Sickness" },
    { id: "C0231403", name: "Severe anxiety (panic)" },
    { id: "C0730607", name: "Severe chronic obstructive pulmonary disease" },
    { id: "C0270458", name: "Severe major depression with psychotic features" },
    { id: "C0278140", name: "Severe pain" },
    { id: "C0341950", name: "Severe pre-eclampsia" },
    { id: "C0013371", name: "Shigella Infections" },
    { id: "C0036980", name: "Shock, Cardiogenic" },
    { id: "C0036992", name: "Short Bowel Syndrome" },
    { id: "C0262399", name: "shoulder bursitis" },
    { id: "C0262633", name: "Shoulder tendinitis" },
    { id: "C0037036", name: "Sialorrhea" },
    { id: "C0234974", name: "Simple Partial Seizures" },
    { id: "C0242459", name: "Simple Pulmonary Eosinophilia" },
    { id: "C0085610", name: "Sinus bradycardia" },
    { id: "C0037195", name: "Sinus headache" },
    { id: "C0037199", name: "Sinusitis" },
    { id: "C0342907", name: "Sitosterolemia" },
    { id: "C1527336", name: "Sjogren's Syndrome" },
    { id: "C0162627", name: "Skin Diseases, Bacterial" },
    { id: "C0152030", name: "Skin irritation" },
    { id: "C0037299", name: "Skin Ulcer" },
    { id: "C0021603", name: "Sleep Initiation and Maintenance Disorders" },
    { id: "C0917801", name: "Sleeplessness" },
    { id: "C0149925", name: "Small cell carcinoma of lung" },
    { id: "C0855095", name: "Small Lymphocytic Lymphoma" },
    { id: "C0037383", name: "Sneezing" },
    { id: "C0242429", name: "Sore Throat" },
    { id: "C0038048", name: "Sprains and Strains" },
    { id: "C0007137", name: "Squamous cell carcinoma" },
    { id: "C0149782", name: "Squamous cell carcinoma of lung" },
    { id: "C0585362", name: "Squamous cell carcinoma of mouth" },
    { id: "C3163899", name: "Squamous cell carcinoma of nose" },
    { id: "C1319317", name: "Squamous cell carcinoma of pharynx" },
    { id: "C0152965", name: "Staphylococcal bacteraemia" },
    { id: "C0520767", name: "Staphylococcal endocarditis" },
    { id: "C0038157", name: "Staphylococcal enterocolitis" },
    { id: "C0038160", name: "Staphylococcal Infections" },
    { id: "C0154640", name: "Staphylococcal meningitis" },
    { id: "C0275794", name: "Staphylococcal pharyngitis" },
    { id: "C0032308", name: "Staphylococcal Pneumonia" },
    { id: "C0038166", name: "Staphylococcal Skin Infections" },
    { id: "C0275793", name: "Staphylococcal tonsillitis" },
    { id: "C1318973", name: "Staphylococcus aureus infection" },
    { id: "C0271093", name: "Stargardt's disease" },
    { id: "C0038218", name: "Status Asthmaticus" },
    { id: "C0038220", name: "Status Epilepticus" },
    { id: "C0038325", name: "Stevens-Johnson Syndrome" },
    { id: "C3665800", name: "Streptococcal endocarditis" },
    { id: "C1274355", name: "Streptococcal infection of skin" },
    { id: "C0038395", name: "Streptococcal Infections" },
    { id: "C0154639", name: "Streptococcal meningitis" },
    { id: "C0155862", name: "Streptococcal pneumonia" },
    { id: "C0152964", name: "Streptococcal sepsis" },
    { id: "C0275804", name: "Streptococcal tonsillitis" },
    { id: "C0343532", name: "Streptococcal toxic shock syndrome" },
    { id: "C0554628", name: "Streptococcus pyogenes infection" },
    { id: "C0038463", name: "Strongyloidiasis" },
    { id: "C1290159", name: "Subacute bursitis" },
    { id: "C0038525", name: "Subarachnoid Hemorrhage" },
    { id: "C1283620", name: "Sucrase-isomaltase deficiency, congenital" },
    { id: "C0342985", name: "Superficial bacterial infection of skin" },
    { id: "C0862889", name: "Superficial basal cell carcinoma" },
    { id: "C0241310", name: "Suprapubic pain" },
    { id: "C0428974", name: "Supraventricular arrhythmia" },
    { id: "C0039240", name: "Supraventricular tachycardia" },
    { id: "C0343065", name: "Symptomatic dermographism" },
    { id: "C0039103", name: "Synovitis" },
    { id: "C0039128", name: "Syphilis" },
    { id: "C0039131", name: "Syphilis, Congenital" },
    { id: "C0039133", name: "Syphilis, Latent" },
    { id: "C0149985", name: "Syphilis, secondary" },
    { id: "C0153188", name: "Syphilis, tertiary" },
    { id: "C0153252", name: "Systemic candidiasis" },
    { id: "C1384600", name: "Systemic onset juvenile chronic arthritis" },
    { id: "C0039503", name: "Tendinitis" },
    { id: "C0039254", name: "Teniasis" },
    { id: "C0039520", name: "Tenosynovitis" },
    { id: "C0233494", name: "Tension" },
    { id: "C0033893", name: "Tension Headache" },
    { id: "C0343833", name: "Tertiary lesion of yaws" },
    { id: "C1336708", name: "Testicular Germ Cell Tumor" },
    { id: "C0151721", name: "Testicular hypogonadism" },
    { id: "C0039614", name: "Tetanus" },
    { id: "C0039621", name: "Tetany" },
    { id: "C0039685", name: "Tetralogy of Fallot" },
    { id: "C0238452", name: "Thallium poisoning" },
    { id: "C0039841", name: "Thiamine Deficiency" },
    { id: "C0040015", name: "Thrombasthenia" },
    { id: "C0040028", name: "Thrombocythemia, Essential" },
    { id: "C0040034", name: "Thrombocytopenia" },
    { id: "C0040038", name: "Thromboembolism" },
    { id: "C0040053", name: "Thrombosis" },
    { id: "C0040127", name: "Thyroid Crisis" },
    { id: "C0040147", name: "Thyroiditis" },
    { id: "C0040247", name: "Tinea" },
    { id: "C2349994", name: "Tinea barbae" },
    { id: "C0040250", name: "Tinea Capitis" },
    { id: "C0040252", name: "Tinea corporis (disorder)" },
    { id: "C1384589", name: "Tinea cruris" },
    { id: "C0153246", name: "Tinea manus" },
    { id: "C0040259", name: "Tinea Pedis" },
    { id: "C0040262", name: "Tinea Versicolor" },
    {
      id: "C1275126",
      name: "TNF receptor-associated periodic fever syndrome (TRAPS)",
    },
    { id: "C0494475", name: "Tonic - clonic seizures" },
    { id: "C0014549", name: "Tonic-Clonic Epilepsy" },
    { id: "C0040425", name: "Tonsillitis" },
    { id: "C0040460", name: "Toothache" },
    { id: "C0238080", name: "Toxic effect of cyanide" },
    { id: "C0040553", name: "Toxocariasis" },
    { id: "C0040558", name: "Toxoplasmosis" },
    { id: "C1720498", name: "Toxoplasmosis associated with AIDS" },
    { id: "C0040560", name: "Toxoplasmosis, Congenital" },
    { id: "C0040592", name: "Trachoma" },
    { id: "C0345468", name: "Transplanted organ rejection" },
    { id: "C0277528", name: "Traveler's diarrhea" },
    { id: "C3544321", name: "Treatment-resistant schizophrenia" },
    { id: "C0040896", name: "Trichinellosis" },
    { id: "C0040921", name: "Trichomonas Infections" },
    { id: "C0243002", name: "Tricuspid Atresia" },
    { id: "C0040997", name: "Trigeminal Neuralgia" },
    { id: "C0206525", name: "Tuberculosis, Drug-Resistant" },
    { id: "C0041318", name: "Tuberculosis, Meningeal" },
    { id: "C0041321", name: "Tuberculosis, Miliary" },
    { id: "C0041327", name: "Tuberculosis, Pulmonary" },
    { id: "C0041341", name: "Tuberous Sclerosis" },
    { id: "C0041343", name: "Tubo-ovarian abscess" },
    { id: "C0041351", name: "Tularemia" },
    { id: "C0041408", name: "Turner Syndrome" },
    { id: "C0041466", name: "Typhoid Fever" },
    { id: "C0041471", name: "TYPHUS" },
    { id: "C0041473", name: "Typhus, Epidemic Louse-Borne" },
    { id: "C0268490", name: "Tyrosinemia, Type I" },
    { id: "C0009324", name: "Ulcerative Colitis" },
    { id: "C3697010", name: "Ulcerative colitis in remission" },
    { id: "C0156543", name: "Unspecified Abortion" },
    { id: "C0041909", name: "Upper gastrointestinal hemorrhage" },
    { id: "C0041912", name: "Upper Respiratory Infections" },
    { id: "C0154246", name: "Urea Cycle Disorders, Inborn" },
    { id: "C0520577", name: "Urethral atrophy" },
    { id: "C0041976", name: "Urethritis" },
    { id: "C0150045", name: "Urge Incontinence" },
    { id: "C0085606", name: "Urgency of micturition" },
    { id: "C0558595", name: "Uric acid renal calculus" },
    { id: "C0042024", name: "Urinary Incontinence" },
    { id: "C0080274", name: "Urinary Retention" },
    { id: "C0042029", name: "Urinary tract infection" },
    { id: "C0749955", name: "URINARY TRACT INFECTION CITROBACTER" },
    { id: "C0749958", name: "URINARY TRACT INFECTION ENTEROCOCCUS" },
    { id: "C0877131", name: "Urinary tract infection fungal" },
    { id: "C0042109", name: "Urticaria" },
    { id: "C0042133", name: "Uterine Fibroids" },
    { id: "C0042135", name: "Uterine Inertia" },
    { id: "C0042164", name: "Uveitis" },
    { id: "C0042345", name: "Varicosity" },
    { id: "C0042373", name: "Vascular Diseases" },
    { id: "C0042376", name: "Vascular Headaches" },
    { id: "C0085612", name: "Ventricular arrhythmia" },
    { id: "C0042510", name: "Ventricular Fibrillation" },
    { id: "C0042571", name: "Vertigo" },
    { id: "C0042842", name: "Vitamin A Deficiency" },
    { id: "C0042847", name: "Vitamin B 12 Deficiency" },
    { id: "C0936215", name: "Vitamin B 6 Deficiency" },
    { id: "C0042870", name: "Vitamin D Deficiency" },
    { id: "C0221468", name: "Vitamin D-dependent rickets" },
    { id: "C1510471", name: "Vitamin Deficiency" },
    { id: "C3536984", name: "Vitamin D-Resistant Rickets, X-Linked" },
    { id: "C0042875", name: "Vitamin E Deficiency" },
    { id: "C0042900", name: "Vitiligo" },
    { id: "C0730274", name: "Vitreomacular traction syndrome" },
    { id: "C0042963", name: "Vomiting" },
    { id: "C0042974", name: "von Willebrand Disease" },
    { id: "C0042998", name: "Vulvovaginitis" },
    { id: "C0024419", name: "Waldenstrom Macroglobulinemia" },
    { id: "C0349464", name: "Wernicke-Korsakoff Syndrome" },
    { id: "C0037769", name: "West Syndrome" },
    { id: "C0043144", name: "Wheezing" },
    { id: "C0043208", name: "Wolman Disease" },
    { id: "C0238052", name: "Xanthomatosis, Cerebrotendinous" },
    { id: "C0043352", name: "Xerostomia" },
    { id: "C0043388", name: "Yaws" },
    { id: "C0235950", name: "Zinc deficiency" },
    { id: "C0043515", name: "Zollinger-Ellison syndrome" },
  ],
  DeepDTnet_AOPEDF_target: [
    { id: "2", name: "A2M" },
    { id: "51166", name: "AADAT" },
    { id: "22848", name: "AAK1" },
    { id: "16", name: "AARS" },
    { id: "57505", name: "AARS2" },
    { id: "10157", name: "AASS" },
    { id: "18", name: "ABAT" },
    { id: "19", name: "ABCA1" },
    { id: "5243", name: "ABCB1" },
    { id: "8647", name: "ABCB11" },
    { id: "4363", name: "ABCC1" },
    { id: "1244", name: "ABCC2" },
    { id: "8714", name: "ABCC3" },
    { id: "10257", name: "ABCC4" },
    { id: "6833", name: "ABCC8" },
    { id: "10060", name: "ABCC9" },
    { id: "215", name: "ABCD1" },
    { id: "9619", name: "ABCG1" },
    { id: "9429", name: "ABCG2" },
    { id: "26090", name: "ABHD12" },
    { id: "25", name: "ABL1" },
    { id: "27", name: "ABL2" },
    { id: "30", name: "ACAA1" },
    { id: "31", name: "ACACA" },
    { id: "32", name: "ACACB" },
    { id: "27034", name: "ACAD8" },
    { id: "34", name: "ACADM" },
    { id: "35", name: "ACADS" },
    { id: "36", name: "ACADSB" },
    { id: "176", name: "ACAN" },
    { id: "38", name: "ACAT1" },
    { id: "39", name: "ACAT2" },
    { id: "1636", name: "ACE" },
    { id: "59272", name: "ACE2" },
    { id: "43", name: "ACHE" },
    { id: "51", name: "ACOX1" },
    { id: "55", name: "ACPP" },
    { id: "2180", name: "ACSL1" },
    { id: "2181", name: "ACSL3" },
    { id: "2182", name: "ACSL4" },
    { id: "84532", name: "ACSS1" },
    { id: "55902", name: "ACSS2" },
    { id: "58", name: "ACTA1" },
    { id: "10097", name: "ACTR2" },
    { id: "10096", name: "ACTR3" },
    { id: "90", name: "ACVR1" },
    { id: "91", name: "ACVR1B" },
    { id: "92", name: "ACVR2A" },
    { id: "93", name: "ACVR2B" },
    { id: "94", name: "ACVRL1" },
    { id: "91703", name: "ACY3" },
    { id: "100", name: "ADA" },
    { id: "102", name: "ADAM10" },
    { id: "8038", name: "ADAM12" },
    { id: "6868", name: "ADAM17" },
    { id: "80332", name: "ADAM33" },
    { id: "8754", name: "ADAM9" },
    { id: "9507", name: "ADAMTS4" },
    { id: "11096", name: "ADAMTS5" },
    { id: "113451", name: "ADC" },
    { id: "56997", name: "ADCK3" },
    { id: "107", name: "ADCY1" },
    { id: "108", name: "ADCY2" },
    { id: "111", name: "ADCY5" },
    { id: "124", name: "ADH1A" },
    { id: "125", name: "ADH1B" },
    { id: "126", name: "ADH1C" },
    { id: "127", name: "ADH4" },
    { id: "128", name: "ADH5" },
    { id: "131", name: "ADH7" },
    { id: "132", name: "ADK" },
    { id: "134", name: "ADORA1" },
    { id: "135", name: "ADORA2A" },
    { id: "148", name: "ADRA1A" },
    { id: "147", name: "ADRA1B" },
    { id: "150", name: "ADRA2A" },
    { id: "151", name: "ADRA2B" },
    { id: "152", name: "ADRA2C" },
    { id: "153", name: "ADRB1" },
    { id: "154", name: "ADRB2" },
    { id: "155", name: "ADRB3" },
    { id: "156", name: "ADRBK1" },
    { id: "159", name: "ADSS" },
    { id: "122622", name: "ADSSL1" },
    { id: "178", name: "AGL" },
    { id: "185", name: "AGTR1" },
    { id: "186", name: "AGTR2" },
    { id: "189", name: "AGXT" },
    { id: "64902", name: "AGXT2" },
    { id: "191", name: "AHCY" },
    { id: "196", name: "AHR" },
    { id: "9131", name: "AIFM1" },
    { id: "10327", name: "AKR1A1" },
    { id: "231", name: "AKR1B1" },
    { id: "57016", name: "AKR1B10" },
    { id: "1645", name: "AKR1C1" },
    { id: "8644", name: "AKR1C3" },
    { id: "1109", name: "AKR1C4" },
    { id: "6718", name: "AKR1D1" },
    { id: "207", name: "AKT1" },
    { id: "208", name: "AKT2" },
    { id: "10000", name: "AKT3" },
    { id: "210", name: "ALAD" },
    { id: "211", name: "ALAS1" },
    { id: "212", name: "ALAS2" },
    { id: "213", name: "ALB" },
    { id: "5832", name: "ALDH18A1" },
    { id: "216", name: "ALDH1A1" },
    { id: "8854", name: "ALDH1A2" },
    { id: "220", name: "ALDH1A3" },
    { id: "219", name: "ALDH1B1" },
    { id: "217", name: "ALDH2" },
    { id: "218", name: "ALDH3A1" },
    { id: "224", name: "ALDH3A2" },
    { id: "221", name: "ALDH3B1" },
    { id: "8659", name: "ALDH4A1" },
    { id: "7915", name: "ALDH5A1" },
    { id: "4329", name: "ALDH6A1" },
    { id: "501", name: "ALDH7A1" },
    { id: "223", name: "ALDH9A1" },
    { id: "226", name: "ALDOA" },
    { id: "238", name: "ALK" },
    { id: "121642", name: "ALKBH2" },
    { id: "221120", name: "ALKBH3" },
    { id: "239", name: "ALOX12" },
    { id: "246", name: "ALOX15" },
    { id: "240", name: "ALOX5" },
    { id: "241", name: "ALOX5AP" },
    { id: "250", name: "ALPP" },
    { id: "259", name: "AMBP" },
    { id: "262", name: "AMD1" },
    { id: "81693", name: "AMN" },
    { id: "276", name: "AMY1A" },
    { id: "279", name: "AMY2A" },
    { id: "283", name: "ANG" },
    { id: "290", name: "ANPEP" },
    { id: "301", name: "ANXA1" },
    { id: "302", name: "ANXA2" },
    { id: "26", name: "AOC1" },
    { id: "8639", name: "AOC3" },
    { id: "316", name: "AOX1" },
    { id: "317", name: "APAF1" },
    { id: "328", name: "APEX1" },
    { id: "348", name: "APOE" },
    { id: "350", name: "APOH" },
    { id: "351", name: "APP" },
    { id: "353", name: "APRT" },
    { id: "358", name: "AQP1" },
    { id: "367", name: "AR" },
    { id: "369", name: "ARAF" },
    { id: "375", name: "ARF1" },
    { id: "378", name: "ARF4" },
    { id: "382", name: "ARF6" },
    { id: "383", name: "ARG1" },
    { id: "384", name: "ARG2" },
    { id: "10095", name: "ARPC1B" },
    { id: "10109", name: "ARPC2" },
    { id: "10094", name: "ARPC3" },
    { id: "10093", name: "ARPC4" },
    { id: "10092", name: "ARPC5" },
    { id: "414", name: "ARSD" },
    { id: "41", name: "ASIC1" },
    { id: "40", name: "ASIC2" },
    { id: "435", name: "ASL" },
    { id: "438", name: "ASMT" },
    { id: "439", name: "ASNA1" },
    { id: "440", name: "ASNS" },
    { id: "443", name: "ASPA" },
    { id: "444", name: "ASPH" },
    { id: "80150", name: "ASRGL1" },
    { id: "445", name: "ASS1" },
    { id: "471", name: "ATIC" },
    { id: "475", name: "ATOX1" },
    { id: "476", name: "ATP1A1" },
    { id: "487", name: "ATP2A1" },
    { id: "27032", name: "ATP2C1" },
    { id: "498", name: "ATP5A1" },
    { id: "506", name: "ATP5B" },
    { id: "509", name: "ATP5C1" },
    { id: "513", name: "ATP5D" },
    { id: "526", name: "ATP6V1B2" },
    { id: "51382", name: "ATP6V1D" },
    { id: "10396", name: "ATP8A1" },
    { id: "545", name: "ATR" },
    { id: "6790", name: "AURKA" },
    { id: "9212", name: "AURKB" },
    { id: "6795", name: "AURKC" },
    { id: "551", name: "AVP" },
    { id: "552", name: "AVPR1A" },
    { id: "553", name: "AVPR1B" },
    { id: "554", name: "AVPR2" },
    { id: "8312", name: "AXIN1" },
    { id: "558", name: "AXL" },
    { id: "563", name: "AZGP1" },
    { id: "566", name: "AZU1" },
    { id: "567", name: "B2M" },
    { id: "27087", name: "B3GAT1" },
    { id: "26229", name: "B3GAT3" },
    { id: "2683", name: "B4GALT1" },
    { id: "8704", name: "B4GALT2" },
    { id: "8703", name: "B4GALT3" },
    { id: "8702", name: "B4GALT4" },
    { id: "570", name: "BAAT" },
    { id: "23621", name: "BACE1" },
    { id: "573", name: "BAG1" },
    { id: "8424", name: "BBOX1" },
    { id: "586", name: "BCAT1" },
    { id: "587", name: "BCAT2" },
    { id: "590", name: "BCHE" },
    { id: "596", name: "BCL2" },
    { id: "598", name: "BCL2L1" },
    { id: "622", name: "BDH1" },
    { id: "624", name: "BDKRB2" },
    { id: "632", name: "BGLAP" },
    { id: "635", name: "BHMT" },
    { id: "23743", name: "BHMT2" },
    { id: "332", name: "BIRC5" },
    { id: "640", name: "BLK" },
    { id: "641", name: "BLM" },
    { id: "644", name: "BLVRA" },
    { id: "645", name: "BLVRB" },
    { id: "649", name: "BMP1" },
    { id: "652", name: "BMP4" },
    { id: "657", name: "BMPR1A" },
    { id: "658", name: "BMPR1B" },
    { id: "659", name: "BMPR2" },
    { id: "660", name: "BMX" },
    { id: "670", name: "BPHL" },
    { id: "673", name: "BRAF" },
    { id: "23476", name: "BRD4" },
    { id: "84446", name: "BRSK1" },
    { id: "9024", name: "BRSK2" },
    { id: "683", name: "BST1" },
    { id: "695", name: "BTK" },
    { id: "712", name: "C1QA" },
    { id: "713", name: "C1QB" },
    { id: "715", name: "C1R" },
    { id: "716", name: "C1S" },
    { id: "759", name: "CA1" },
    { id: "56934", name: "CA10" },
    { id: "760", name: "CA2" },
    { id: "762", name: "CA4" },
    { id: "765", name: "CA6" },
    { id: "773", name: "CACNA1A" },
    { id: "774", name: "CACNA1B" },
    { id: "775", name: "CACNA1C" },
    { id: "776", name: "CACNA1D" },
    { id: "778", name: "CACNA1F" },
    { id: "8913", name: "CACNA1G" },
    { id: "8912", name: "CACNA1H" },
    { id: "8911", name: "CACNA1I" },
    { id: "779", name: "CACNA1S" },
    { id: "781", name: "CACNA2D1" },
    { id: "9254", name: "CACNA2D2" },
    { id: "55799", name: "CACNA2D3" },
    { id: "783", name: "CACNB2" },
    { id: "784", name: "CACNB3" },
    { id: "785", name: "CACNB4" },
    { id: "790", name: "CAD" },
    { id: "799", name: "CALCR" },
    { id: "801", name: "CALM1" },
    { id: "811", name: "CALR" },
    { id: "50632", name: "CALY" },
    { id: "815", name: "CAMK2A" },
    { id: "816", name: "CAMK2B" },
    { id: "817", name: "CAMK2D" },
    { id: "818", name: "CAMK2G" },
    { id: "814", name: "CAMK4" },
    { id: "84254", name: "CAMKK1" },
    { id: "10645", name: "CAMKK2" },
    { id: "819", name: "CAMLG" },
    { id: "124583", name: "CANT1" },
    { id: "821", name: "CANX" },
    { id: "826", name: "CAPNS1" },
    { id: "833", name: "CARS" },
    { id: "79587", name: "CARS2" },
    { id: "9607", name: "CARTPT" },
    { id: "8573", name: "CASK" },
    { id: "834", name: "CASP1" },
    { id: "835", name: "CASP2" },
    { id: "836", name: "CASP3" },
    { id: "840", name: "CASP7" },
    { id: "846", name: "CASR" },
    { id: "831", name: "CAST" },
    { id: "847", name: "CAT" },
    { id: "117144", name: "CATSPER1" },
    { id: "117155", name: "CATSPER2" },
    { id: "347732", name: "CATSPER3" },
    { id: "378807", name: "CATSPER4" },
    { id: "873", name: "CBR1" },
    { id: "875", name: "CBS" },
    { id: "883", name: "CCBL1" },
    { id: "6347", name: "CCL2" },
    { id: "6355", name: "CCL8" },
    { id: "890", name: "CCNA2" },
    { id: "891", name: "CCNB1" },
    { id: "595", name: "CCND1" },
    { id: "1230", name: "CCR1" },
    { id: "729230", name: "CCR2" },
    { id: "1233", name: "CCR4" },
    { id: "1234", name: "CCR5" },
    { id: "1237", name: "CCR8" },
    { id: "909", name: "CD1A" },
    { id: "914", name: "CD2" },
    { id: "30835", name: "CD209" },
    { id: "919", name: "CD247" },
    { id: "945", name: "CD33" },
    { id: "915", name: "CD3D" },
    { id: "916", name: "CD3E" },
    { id: "917", name: "CD3G" },
    { id: "920", name: "CD4" },
    { id: "960", name: "CD44" },
    { id: "4179", name: "CD46" },
    { id: "941", name: "CD80" },
    { id: "942", name: "CD86" },
    { id: "994", name: "CDC25B" },
    { id: "8476", name: "CDC42BPA" },
    { id: "9578", name: "CDC42BPB" },
    { id: "55561", name: "CDC42BPG" },
    { id: "8317", name: "CDC7" },
    { id: "1003", name: "CDH5" },
    { id: "10423", name: "CDIPT" },
    { id: "983", name: "CDK1" },
    { id: "728642", name: "CDK11A" },
    { id: "984", name: "CDK11B" },
    { id: "8621", name: "CDK13" },
    { id: "5218", name: "CDK14" },
    { id: "5127", name: "CDK16" },
    { id: "1017", name: "CDK2" },
    { id: "1019", name: "CDK4" },
    { id: "1020", name: "CDK5" },
    { id: "8851", name: "CDK5R1" },
    { id: "1021", name: "CDK6" },
    { id: "1022", name: "CDK7" },
    { id: "1024", name: "CDK8" },
    { id: "1025", name: "CDK9" },
    { id: "8999", name: "CDKL2" },
    { id: "6792", name: "CDKL5" },
    { id: "1036", name: "CDO1" },
    { id: "634", name: "CEACAM1" },
    { id: "1048", name: "CEACAM5" },
    { id: "1990", name: "CELA1" },
    { id: "1066", name: "CES1" },
    { id: "8824", name: "CES2" },
    { id: "629", name: "CFB" },
    { id: "1072", name: "CFL1" },
    { id: "1080", name: "CFTR" },
    { id: "1081", name: "CGA" },
    { id: "1105", name: "CHD1" },
    { id: "1111", name: "CHEK1" },
    { id: "11200", name: "CHEK2" },
    { id: "27159", name: "CHIA" },
    { id: "1118", name: "CHIT1" },
    { id: "1121", name: "CHM" },
    { id: "89832", name: "CHRFAM7A" },
    { id: "1128", name: "CHRM1" },
    { id: "1129", name: "CHRM2" },
    { id: "1131", name: "CHRM3" },
    { id: "1133", name: "CHRM5" },
    { id: "57053", name: "CHRNA10" },
    { id: "1135", name: "CHRNA2" },
    { id: "1136", name: "CHRNA3" },
    { id: "1137", name: "CHRNA4" },
    { id: "1138", name: "CHRNA5" },
    { id: "8973", name: "CHRNA6" },
    { id: "1139", name: "CHRNA7" },
    { id: "55584", name: "CHRNA9" },
    { id: "1141", name: "CHRNB2" },
    { id: "1142", name: "CHRNB3" },
    { id: "1143", name: "CHRNB4" },
    { id: "1145", name: "CHRNE" },
    { id: "1147", name: "CHUK" },
    { id: "11113", name: "CIT" },
    { id: "1152", name: "CKB" },
    { id: "1158", name: "CKM" },
    { id: "1178", name: "CLC" },
    { id: "7123", name: "CLEC3B" },
    { id: "1196", name: "CLK2" },
    { id: "1198", name: "CLK3" },
    { id: "81570", name: "CLPB" },
    { id: "8192", name: "CLPP" },
    { id: "10845", name: "CLPX" },
    { id: "1215", name: "CMA1" },
    { id: "55907", name: "CMAS" },
    { id: "51727", name: "CMPK1" },
    { id: "1268", name: "CNR1" },
    { id: "1277", name: "COL1A1" },
    { id: "1278", name: "COL1A2" },
    { id: "1280", name: "COL2A1" },
    { id: "1281", name: "COL3A1" },
    { id: "1311", name: "COMP" },
    { id: "1312", name: "COMT" },
    { id: "22820", name: "COPG1" },
    { id: "1327", name: "COX4I1" },
    { id: "9377", name: "COX5A" },
    { id: "1340", name: "COX6B1" },
    { id: "1351", name: "COX8A" },
    { id: "1356", name: "CP" },
    { id: "1360", name: "CPB1" },
    { id: "1363", name: "CPE" },
    { id: "1371", name: "CPOX" },
    { id: "1374", name: "CPT1A" },
    { id: "1376", name: "CPT2" },
    { id: "1384", name: "CRAT" },
    { id: "286204", name: "CRB2" },
    { id: "1385", name: "CREB1" },
    { id: "1392", name: "CRH" },
    { id: "54677", name: "CROT" },
    { id: "1421", name: "CRYGD" },
    { id: "1429", name: "CRYZ" },
    { id: "1436", name: "CSF1R" },
    { id: "1438", name: "CSF2RA" },
    { id: "1439", name: "CSF2RB" },
    { id: "1441", name: "CSF3R" },
    { id: "1445", name: "CSK" },
    { id: "1452", name: "CSNK1A1" },
    { id: "122011", name: "CSNK1A1L" },
    { id: "1453", name: "CSNK1D" },
    { id: "1454", name: "CSNK1E" },
    { id: "53944", name: "CSNK1G1" },
    { id: "1455", name: "CSNK1G2" },
    { id: "1456", name: "CSNK1G3" },
    { id: "1457", name: "CSNK2A1" },
    { id: "1459", name: "CSNK2A2" },
    { id: "1487", name: "CTBP1" },
    { id: "1491", name: "CTH" },
    { id: "1493", name: "CTLA4" },
    { id: "1499", name: "CTNNB1" },
    { id: "1503", name: "CTPS1" },
    { id: "1504", name: "CTRB1" },
    { id: "5476", name: "CTSA" },
    { id: "1508", name: "CTSB" },
    { id: "1509", name: "CTSD" },
    { id: "8722", name: "CTSF" },
    { id: "1511", name: "CTSG" },
    { id: "1512", name: "CTSH" },
    { id: "1513", name: "CTSK" },
    { id: "1514", name: "CTSL1" },
    { id: "1515", name: "CTSL2" },
    { id: "1520", name: "CTSS" },
    { id: "8029", name: "CUBN" },
    { id: "23113", name: "CUL9" },
    { id: "51596", name: "CUTA" },
    { id: "2919", name: "CXCL1" },
    { id: "6387", name: "CXCL12" },
    { id: "4283", name: "CXCL9" },
    { id: "3577", name: "CXCR1" },
    { id: "7852", name: "CXCR4" },
    { id: "1528", name: "CYB5A" },
    { id: "1727", name: "CYB5R3" },
    { id: "1537", name: "CYC1" },
    { id: "54205", name: "CYCS" },
    { id: "114757", name: "CYGB" },
    { id: "1584", name: "CYP11B1" },
    { id: "1585", name: "CYP11B2" },
    { id: "1586", name: "CYP17A1" },
    { id: "1588", name: "CYP19A1" },
    { id: "1543", name: "CYP1A1" },
    { id: "1544", name: "CYP1A2" },
    { id: "1545", name: "CYP1B1" },
    { id: "1548", name: "CYP2A6" },
    { id: "1555", name: "CYP2B6" },
    { id: "1557", name: "CYP2C19" },
    { id: "1558", name: "CYP2C8" },
    { id: "1559", name: "CYP2C9" },
    { id: "1565", name: "CYP2D6" },
    { id: "1571", name: "CYP2E1" },
    { id: "1573", name: "CYP2J2" },
    { id: "1576", name: "CYP3A4" },
    { id: "1577", name: "CYP3A5" },
    { id: "1595", name: "CYP51A1" },
    { id: "10800", name: "CYSLTR1" },
    { id: "57105", name: "CYSLTR2" },
    { id: "9266", name: "CYTH2" },
    { id: "747", name: "DAGLA" },
    { id: "221955", name: "DAGLB" },
    { id: "1610", name: "DAO" },
    { id: "1612", name: "DAPK1" },
    { id: "23604", name: "DAPK2" },
    { id: "1613", name: "DAPK3" },
    { id: "27071", name: "DAPP1" },
    { id: "1615", name: "DARS" },
    { id: "55157", name: "DARS2" },
    { id: "1621", name: "DBH" },
    { id: "28988", name: "DBNL" },
    { id: "1633", name: "DCK" },
    { id: "9201", name: "DCLK1" },
    { id: "28960", name: "DCPS" },
    { id: "1635", name: "DCTD" },
    { id: "51181", name: "DCXR" },
    { id: "23576", name: "DDAH1" },
    { id: "23564", name: "DDAH2" },
    { id: "1644", name: "DDC" },
    { id: "780", name: "DDR1" },
    { id: "4921", name: "DDR2" },
    { id: "1656", name: "DDX6" },
    { id: "1666", name: "DECR1" },
    { id: "8527", name: "DGKD" },
    { id: "1717", name: "DHCR7" },
    { id: "1719", name: "DHFR" },
    { id: "200895", name: "DHFRL1" },
    { id: "1723", name: "DHODH" },
    { id: "1725", name: "DHPS" },
    { id: "9249", name: "DHRS3" },
    { id: "10901", name: "DHRS4" },
    { id: "27042", name: "DIEXF" },
    { id: "1737", name: "DLAT" },
    { id: "1738", name: "DLD" },
    { id: "8788", name: "DLK1" },
    { id: "1760", name: "DMPK" },
    { id: "1786", name: "DNMT1" },
    { id: "23549", name: "DNPEP" },
    { id: "1803", name: "DPP4" },
    { id: "1804", name: "DPP6" },
    { id: "54878", name: "DPP8" },
    { id: "1806", name: "DPYD" },
    { id: "1812", name: "DRD1" },
    { id: "1813", name: "DRD2" },
    { id: "1814", name: "DRD3" },
    { id: "1815", name: "DRD4" },
    { id: "1816", name: "DRD5" },
    { id: "11034", name: "DSTN" },
    { id: "54935", name: "DUSP23" },
    { id: "1854", name: "DUT" },
    { id: "1859", name: "DYRK1A" },
    { id: "8445", name: "DYRK2" },
    { id: "8444", name: "DYRK3" },
    { id: "124454", name: "EARS2" },
    { id: "10682", name: "EBP" },
    { id: "1889", name: "ECE1" },
    { id: "9718", name: "ECE2" },
    { id: "1632", name: "ECI1" },
    { id: "10455", name: "ECI2" },
    { id: "1896", name: "EDA" },
    { id: "1909", name: "EDNRA" },
    { id: "1910", name: "EDNRB" },
    { id: "1938", name: "EEF2" },
    { id: "79631", name: "EFTUD1" },
    { id: "1950", name: "EGF" },
    { id: "1956", name: "EGFR" },
    { id: "54583", name: "EGLN1" },
    { id: "112398", name: "EGLN2" },
    { id: "112399", name: "EGLN3" },
    { id: "1962", name: "EHHADH" },
    { id: "5610", name: "EIF2AK2" },
    { id: "440275", name: "EIF2AK4" },
    { id: "1977", name: "EIF4E" },
    { id: "1991", name: "ELANE" },
    { id: "2006", name: "ELN" },
    { id: "6785", name: "ELOVL4" },
    { id: "2023", name: "ENO1" },
    { id: "2028", name: "ENPEP" },
    { id: "5167", name: "ENPP1" },
    { id: "2034", name: "EPAS1" },
    { id: "2041", name: "EPHA1" },
    { id: "1969", name: "EPHA2" },
    { id: "2042", name: "EPHA3" },
    { id: "2043", name: "EPHA4" },
    { id: "2044", name: "EPHA5" },
    { id: "285220", name: "EPHA6" },
    { id: "2045", name: "EPHA7" },
    { id: "2046", name: "EPHA8" },
    { id: "2047", name: "EPHB1" },
    { id: "2048", name: "EPHB2" },
    { id: "2049", name: "EPHB3" },
    { id: "2050", name: "EPHB4" },
    { id: "2051", name: "EPHB6" },
    { id: "2053", name: "EPHX2" },
    { id: "2058", name: "EPRS" },
    { id: "2064", name: "ERBB2" },
    { id: "2065", name: "ERBB3" },
    { id: "2066", name: "ERBB4" },
    { id: "2081", name: "ERN1" },
    { id: "2098", name: "ESD" },
    { id: "2099", name: "ESR1" },
    { id: "2100", name: "ESR2" },
    { id: "2101", name: "ESRRA" },
    { id: "2103", name: "ESRRB" },
    { id: "2104", name: "ESRRG" },
    { id: "2110", name: "ETFDH" },
    { id: "2159", name: "F10" },
    { id: "2160", name: "F11" },
    { id: "2162", name: "F13A1" },
    { id: "2147", name: "F2" },
    { id: "2149", name: "F2R" },
    { id: "2152", name: "F3" },
    { id: "2153", name: "F5" },
    { id: "2155", name: "F7" },
    { id: "2157", name: "F8" },
    { id: "2158", name: "F9" },
    { id: "2166", name: "FAAH" },
    { id: "2168", name: "FABP1" },
    { id: "2169", name: "FABP2" },
    { id: "2170", name: "FABP3" },
    { id: "2167", name: "FABP4" },
    { id: "2171", name: "FABP5" },
    { id: "2172", name: "FABP6" },
    { id: "3992", name: "FADS1" },
    { id: "2191", name: "FAP" },
    { id: "10667", name: "FARS2" },
    { id: "2193", name: "FARSA" },
    { id: "2194", name: "FASN" },
    { id: "2201", name: "FBN2" },
    { id: "2203", name: "FBP1" },
    { id: "8789", name: "FBP2" },
    { id: "2207", name: "FCER1G" },
    { id: "2208", name: "FCER2" },
    { id: "2209", name: "FCGR1A" },
    { id: "2212", name: "FCGR2A" },
    { id: "2213", name: "FCGR2B" },
    { id: "9103", name: "FCGR2C" },
    { id: "2222", name: "FDFT1" },
    { id: "2224", name: "FDPS" },
    { id: "2235", name: "FECH" },
    { id: "2241", name: "FER" },
    { id: "2242", name: "FES" },
    { id: "2864", name: "FFAR1" },
    { id: "338557", name: "FFAR4" },
    { id: "2243", name: "FGA" },
    { id: "2244", name: "FGB" },
    { id: "2246", name: "FGF1" },
    { id: "2247", name: "FGF2" },
    { id: "2260", name: "FGFR1" },
    { id: "2263", name: "FGFR2" },
    { id: "2261", name: "FGFR3" },
    { id: "2264", name: "FGFR4" },
    { id: "2266", name: "FGG" },
    { id: "2268", name: "FGR" },
    { id: "2280", name: "FKBP1A" },
    { id: "2321", name: "FLT1" },
    { id: "2322", name: "FLT3" },
    { id: "2324", name: "FLT4" },
    { id: "2339", name: "FNTA" },
    { id: "2342", name: "FNTB" },
    { id: "2346", name: "FOLH1" },
    { id: "2350", name: "FOLR2" },
    { id: "2353", name: "FOS" },
    { id: "2356", name: "FPGS" },
    { id: "2357", name: "FPR1" },
    { id: "2444", name: "FRK" },
    { id: "2492", name: "FSHR" },
    { id: "10841", name: "FTCD" },
    { id: "2495", name: "FTH1" },
    { id: "2512", name: "FTL" },
    { id: "79068", name: "FTO" },
    { id: "2517", name: "FUCA1" },
    { id: "5045", name: "FURIN" },
    { id: "486", name: "FXYD2" },
    { id: "2534", name: "FYN" },
    { id: "2539", name: "G6PD" },
    { id: "2548", name: "GAA" },
    { id: "2550", name: "GABBR1" },
    { id: "9568", name: "GABBR2" },
    { id: "2554", name: "GABRA1" },
    { id: "2555", name: "GABRA2" },
    { id: "2556", name: "GABRA3" },
    { id: "2557", name: "GABRA4" },
    { id: "2558", name: "GABRA5" },
    { id: "2559", name: "GABRA6" },
    { id: "2560", name: "GABRB1" },
    { id: "2561", name: "GABRB2" },
    { id: "2562", name: "GABRB3" },
    { id: "2563", name: "GABRD" },
    { id: "2564", name: "GABRE" },
    { id: "2565", name: "GABRG1" },
    { id: "2566", name: "GABRG2" },
    { id: "2567", name: "GABRG3" },
    { id: "2568", name: "GABRP" },
    { id: "55879", name: "GABRQ" },
    { id: "2569", name: "GABRR1" },
    { id: "2570", name: "GABRR2" },
    { id: "200959", name: "GABRR3" },
    { id: "2571", name: "GAD1" },
    { id: "2572", name: "GAD2" },
    { id: "2582", name: "GALE" },
    { id: "2584", name: "GALK1" },
    { id: "130589", name: "GALM" },
    { id: "2592", name: "GALT" },
    { id: "2593", name: "GAMT" },
    { id: "2597", name: "GAPDH" },
    { id: "26330", name: "GAPDHS" },
    { id: "2617", name: "GARS" },
    { id: "2628", name: "GATM" },
    { id: "2629", name: "GBA" },
    { id: "57704", name: "GBA2" },
    { id: "2638", name: "GC" },
    { id: "25801", name: "GCA" },
    { id: "23464", name: "GCAT" },
    { id: "2639", name: "GCDH" },
    { id: "2642", name: "GCGR" },
    { id: "2643", name: "GCH1" },
    { id: "2645", name: "GCK" },
    { id: "2729", name: "GCLC" },
    { id: "2730", name: "GCLM" },
    { id: "2671", name: "GFER" },
    { id: "9453", name: "GGPS1" },
    { id: "2678", name: "GGT1" },
    { id: "2690", name: "GHR" },
    { id: "2697", name: "GJA1" },
    { id: "2717", name: "GLA" },
    { id: "2731", name: "GLDC" },
    { id: "2735", name: "GLI1" },
    { id: "2736", name: "GLI2" },
    { id: "152007", name: "GLIPR2" },
    { id: "2739", name: "GLO1" },
    { id: "2740", name: "GLP1R" },
    { id: "9340", name: "GLP2R" },
    { id: "2741", name: "GLRA1" },
    { id: "2742", name: "GLRA2" },
    { id: "8001", name: "GLRA3" },
    { id: "2743", name: "GLRB" },
    { id: "2745", name: "GLRX" },
    { id: "51022", name: "GLRX2" },
    { id: "2744", name: "GLS" },
    { id: "27165", name: "GLS2" },
    { id: "51228", name: "GLTP" },
    { id: "2746", name: "GLUD1" },
    { id: "2747", name: "GLUD2" },
    { id: "2752", name: "GLUL" },
    { id: "10249", name: "GLYAT" },
    { id: "2762", name: "GMDS" },
    { id: "8833", name: "GMPS" },
    { id: "2778", name: "GNAS" },
    { id: "2779", name: "GNAT1" },
    { id: "2782", name: "GNB1" },
    { id: "54331", name: "GNG2" },
    { id: "2792", name: "GNGT1" },
    { id: "27232", name: "GNMT" },
    { id: "10007", name: "GNPDA1" },
    { id: "79158", name: "GNPTAB" },
    { id: "2798", name: "GNRHR" },
    { id: "2805", name: "GOT1" },
    { id: "2806", name: "GOT2" },
    { id: "2811", name: "GP1BA" },
    { id: "2815", name: "GP9" },
    { id: "151306", name: "GPBAR1" },
    { id: "2852", name: "GPER" },
    { id: "10243", name: "GPHN" },
    { id: "2821", name: "GPI" },
    { id: "29933", name: "GPR132" },
    { id: "2840", name: "GPR17" },
    { id: "2841", name: "GPR18" },
    { id: "1880", name: "GPR183" },
    { id: "2859", name: "GPR35" },
    { id: "9290", name: "GPR55" },
    { id: "9052", name: "GPRC5A" },
    { id: "2875", name: "GPT" },
    { id: "84706", name: "GPT2" },
    { id: "2876", name: "GPX1" },
    { id: "2877", name: "GPX2" },
    { id: "2878", name: "GPX3" },
    { id: "2879", name: "GPX4" },
    { id: "2882", name: "GPX7" },
    { id: "493869", name: "GPX8" },
    { id: "2890", name: "GRIA1" },
    { id: "2891", name: "GRIA2" },
    { id: "2892", name: "GRIA3" },
    { id: "2893", name: "GRIA4" },
    { id: "2894", name: "GRID1" },
    { id: "2895", name: "GRID2" },
    { id: "2897", name: "GRIK1" },
    { id: "2898", name: "GRIK2" },
    { id: "2899", name: "GRIK3" },
    { id: "2900", name: "GRIK4" },
    { id: "2901", name: "GRIK5" },
    { id: "2902", name: "GRIN1" },
    { id: "2903", name: "GRIN2A" },
    { id: "2904", name: "GRIN2B" },
    { id: "2905", name: "GRIN2C" },
    { id: "2906", name: "GRIN2D" },
    { id: "116443", name: "GRIN3A" },
    { id: "116444", name: "GRIN3B" },
    { id: "6011", name: "GRK1" },
    { id: "2911", name: "GRM1" },
    { id: "2912", name: "GRM2" },
    { id: "2913", name: "GRM3" },
    { id: "2914", name: "GRM4" },
    { id: "2915", name: "GRM5" },
    { id: "2916", name: "GRM6" },
    { id: "2917", name: "GRM7" },
    { id: "2918", name: "GRM8" },
    { id: "83903", name: "GSG2" },
    { id: "2931", name: "GSK3A" },
    { id: "2932", name: "GSK3B" },
    { id: "2934", name: "GSN" },
    { id: "2936", name: "GSR" },
    { id: "2937", name: "GSS" },
    { id: "2938", name: "GSTA1" },
    { id: "2939", name: "GSTA2" },
    { id: "2940", name: "GSTA3" },
    { id: "2941", name: "GSTA4" },
    { id: "221357", name: "GSTA5" },
    { id: "373156", name: "GSTK1" },
    { id: "2944", name: "GSTM1" },
    { id: "2946", name: "GSTM2" },
    { id: "2947", name: "GSTM3" },
    { id: "2948", name: "GSTM4" },
    { id: "2949", name: "GSTM5" },
    { id: "9446", name: "GSTO1" },
    { id: "119391", name: "GSTO2" },
    { id: "2950", name: "GSTP1" },
    { id: "2952", name: "GSTT1" },
    { id: "2954", name: "GSTZ1" },
    { id: "2987", name: "GUK1" },
    { id: "2990", name: "GUSB" },
    { id: "3002", name: "GZMB" },
    { id: "3033", name: "HADH" },
    { id: "3030", name: "HADHA" },
    { id: "3029", name: "HAGH" },
    { id: "54363", name: "HAO1" },
    { id: "3035", name: "HARS" },
    { id: "3039", name: "HBA1" },
    { id: "3043", name: "HBB" },
    { id: "338442", name: "HCAR2" },
    { id: "3055", name: "HCK" },
    { id: "3065", name: "HDAC1" },
    { id: "83933", name: "HDAC10" },
    { id: "79885", name: "HDAC11" },
    { id: "3066", name: "HDAC2" },
    { id: "8841", name: "HDAC3" },
    { id: "9759", name: "HDAC4" },
    { id: "10014", name: "HDAC5" },
    { id: "10013", name: "HDAC6" },
    { id: "51564", name: "HDAC7" },
    { id: "55869", name: "HDAC8" },
    { id: "9734", name: "HDAC9" },
    { id: "3067", name: "HDC" },
    { id: "3074", name: "HEXB" },
    { id: "3082", name: "HGF" },
    { id: "3087", name: "HHEX" },
    { id: "11112", name: "HIBADH" },
    { id: "26275", name: "HIBCH" },
    { id: "3091", name: "HIF1A" },
    { id: "55662", name: "HIF1AN" },
    { id: "3094", name: "HINT1" },
    { id: "204851", name: "HIPK1" },
    { id: "28996", name: "HIPK2" },
    { id: "10114", name: "HIPK3" },
    { id: "3098", name: "HK1" },
    { id: "3105", name: "HLA-A" },
    { id: "3106", name: "HLA-B" },
    { id: "3118", name: "HLA-DQA2" },
    { id: "3141", name: "HLCS" },
    { id: "3156", name: "HMGCR" },
    { id: "3162", name: "HMOX1" },
    { id: "3163", name: "HMOX2" },
    { id: "3172", name: "HNF4A" },
    { id: "3174", name: "HNF4G" },
    { id: "3176", name: "HNMT" },
    { id: "3178", name: "HNRNPA1" },
    { id: "3248", name: "HPGD" },
    { id: "27306", name: "HPGDS" },
    { id: "3249", name: "HPN" },
    { id: "3251", name: "HPRT1" },
    { id: "3265", name: "HRAS" },
    { id: "3269", name: "HRH1" },
    { id: "11255", name: "HRH3" },
    { id: "59340", name: "HRH4" },
    { id: "10247", name: "HRSP12" },
    { id: "9955", name: "HS3ST3A1" },
    { id: "3292", name: "HSD17B1" },
    { id: "3028", name: "HSD17B10" },
    { id: "51170", name: "HSD17B11" },
    { id: "3294", name: "HSD17B2" },
    { id: "3295", name: "HSD17B4" },
    { id: "8630", name: "HSD17B6" },
    { id: "7923", name: "HSD17B8" },
    { id: "3283", name: "HSD3B1" },
    { id: "3284", name: "HSD3B2" },
    { id: "3320", name: "HSP90AA1" },
    { id: "3326", name: "HSP90AB1" },
    { id: "7184", name: "HSP90B1" },
    { id: "3303", name: "HSPA1A" },
    { id: "3309", name: "HSPA5" },
    { id: "3312", name: "HSPA8" },
    { id: "3315", name: "HSPB1" },
    { id: "3339", name: "HSPG2" },
    { id: "3350", name: "HTR1A" },
    { id: "3351", name: "HTR1B" },
    { id: "3352", name: "HTR1D" },
    { id: "3354", name: "HTR1E" },
    { id: "3355", name: "HTR1F" },
    { id: "3356", name: "HTR2A" },
    { id: "3357", name: "HTR2B" },
    { id: "3358", name: "HTR2C" },
    { id: "3359", name: "HTR3A" },
    { id: "9177", name: "HTR3B" },
    { id: "3360", name: "HTR4" },
    { id: "3361", name: "HTR5A" },
    { id: "3362", name: "HTR6" },
    { id: "3376", name: "IARS" },
    { id: "3383", name: "ICAM1" },
    { id: "22858", name: "ICK" },
    { id: "3416", name: "IDE" },
    { id: "3417", name: "IDH1" },
    { id: "3420", name: "IDH3B" },
    { id: "3421", name: "IDH3G" },
    { id: "3422", name: "IDI1" },
    { id: "3620", name: "IDO1" },
    { id: "3454", name: "IFNAR1" },
    { id: "3455", name: "IFNAR2" },
    { id: "3456", name: "IFNB1" },
    { id: "3458", name: "IFNG" },
    { id: "3459", name: "IFNGR1" },
    { id: "3460", name: "IFNGR2" },
    { id: "3479", name: "IGF1" },
    { id: "3480", name: "IGF1R" },
    { id: "3482", name: "IGF2R" },
    { id: "3486", name: "IGFBP3" },
    { id: "3490", name: "IGFBP7" },
    { id: "3551", name: "IKBKB" },
    { id: "9641", name: "IKBKE" },
    { id: "3593", name: "IL12B" },
    { id: "3553", name: "IL1B" },
    { id: "3558", name: "IL2" },
    { id: "3559", name: "IL2RA" },
    { id: "3560", name: "IL2RB" },
    { id: "3561", name: "IL2RG" },
    { id: "3562", name: "IL3" },
    { id: "3563", name: "IL3RA" },
    { id: "3567", name: "IL5" },
    { id: "3569", name: "IL6" },
    { id: "3570", name: "IL6R" },
    { id: "3576", name: "IL8" },
    { id: "10989", name: "IMMT" },
    { id: "3612", name: "IMPA1" },
    { id: "3613", name: "IMPA2" },
    { id: "3614", name: "IMPDH1" },
    { id: "3615", name: "IMPDH2" },
    { id: "3619", name: "INCENP" },
    { id: "3623", name: "INHA" },
    { id: "11185", name: "INMT" },
    { id: "3628", name: "INPP1" },
    { id: "3630", name: "INS" },
    { id: "3643", name: "INSR" },
    { id: "3645", name: "INSRR" },
    { id: "3654", name: "IRAK1" },
    { id: "11213", name: "IRAK3" },
    { id: "51135", name: "IRAK4" },
    { id: "3667", name: "IRS1" },
    { id: "3669", name: "ISG20" },
    { id: "51477", name: "ISYNA1" },
    { id: "3674", name: "ITGA2B" },
    { id: "3676", name: "ITGA4" },
    { id: "3683", name: "ITGAL" },
    { id: "3685", name: "ITGAV" },
    { id: "3688", name: "ITGB1" },
    { id: "3689", name: "ITGB2" },
    { id: "3690", name: "ITGB3" },
    { id: "3702", name: "ITK" },
    { id: "3712", name: "IVD" },
    { id: "389434", name: "IYD" },
    { id: "3716", name: "JAK1" },
    { id: "3717", name: "JAK2" },
    { id: "3718", name: "JAK3" },
    { id: "3725", name: "JUN" },
    { id: "3735", name: "KARS" },
    { id: "8850", name: "KAT2B" },
    { id: "3736", name: "KCNA1" },
    { id: "3738", name: "KCNA3" },
    { id: "3741", name: "KCNA5" },
    { id: "3749", name: "KCNC4" },
    { id: "3751", name: "KCND2" },
    { id: "3752", name: "KCND3" },
    { id: "3753", name: "KCNE1" },
    { id: "3757", name: "KCNH2" },
    { id: "81033", name: "KCNH6" },
    { id: "90134", name: "KCNH7" },
    { id: "3758", name: "KCNJ1" },
    { id: "3767", name: "KCNJ11" },
    { id: "3768", name: "KCNJ12" },
    { id: "3760", name: "KCNJ3" },
    { id: "3762", name: "KCNJ5" },
    { id: "3763", name: "KCNJ6" },
    { id: "3764", name: "KCNJ8" },
    { id: "3775", name: "KCNK1" },
    { id: "3776", name: "KCNK2" },
    { id: "3777", name: "KCNK3" },
    { id: "9424", name: "KCNK6" },
    { id: "51305", name: "KCNK9" },
    { id: "3778", name: "KCNMA1" },
    { id: "10242", name: "KCNMB2" },
    { id: "27094", name: "KCNMB3" },
    { id: "27345", name: "KCNMB4" },
    { id: "3780", name: "KCNN1" },
    { id: "3781", name: "KCNN2" },
    { id: "3782", name: "KCNN3" },
    { id: "3783", name: "KCNN4" },
    { id: "3784", name: "KCNQ1" },
    { id: "3785", name: "KCNQ2" },
    { id: "3786", name: "KCNQ3" },
    { id: "9132", name: "KCNQ4" },
    { id: "56479", name: "KCNQ5" },
    { id: "23028", name: "KDM1A" },
    { id: "8284", name: "KDM5D" },
    { id: "3791", name: "KDR" },
    { id: "3832", name: "KIF11" },
    { id: "547", name: "KIF1A" },
    { id: "3799", name: "KIF5B" },
    { id: "3815", name: "KIT" },
    { id: "5653", name: "KLK6" },
    { id: "3818", name: "KLKB1" },
    { id: "3845", name: "KRAS" },
    { id: "8942", name: "KYNU" },
    { id: "26013", name: "L3MBTL1" },
    { id: "51056", name: "LAP3" },
    { id: "51520", name: "LARS" },
    { id: "23395", name: "LARS2" },
    { id: "9113", name: "LATS1" },
    { id: "26524", name: "LATS2" },
    { id: "3932", name: "LCK" },
    { id: "51451", name: "LCMT1" },
    { id: "9836", name: "LCMT2" },
    { id: "3934", name: "LCN2" },
    { id: "3938", name: "LCT" },
    { id: "3939", name: "LDHA" },
    { id: "3945", name: "LDHB" },
    { id: "3949", name: "LDLR" },
    { id: "64175", name: "LEPRE1" },
    { id: "55214", name: "LEPREL1" },
    { id: "3973", name: "LHCGR" },
    { id: "11019", name: "LIAS" },
    { id: "3978", name: "LIG1" },
    { id: "3980", name: "LIG3" },
    { id: "3984", name: "LIMK1" },
    { id: "3985", name: "LIMK2" },
    { id: "8513", name: "LIPF" },
    { id: "3998", name: "LMAN1" },
    { id: "9227", name: "LRAT" },
    { id: "4035", name: "LRP1" },
    { id: "4036", name: "LRP2" },
    { id: "120892", name: "LRRK2" },
    { id: "4049", name: "LTA" },
    { id: "4048", name: "LTA4H" },
    { id: "1241", name: "LTB4R" },
    { id: "4056", name: "LTC4S" },
    { id: "4057", name: "LTF" },
    { id: "4058", name: "LTK" },
    { id: "23643", name: "LY96" },
    { id: "4067", name: "LYN" },
    { id: "10434", name: "LYPLA1" },
    { id: "11313", name: "LYPLA2" },
    { id: "4094", name: "MAF" },
    { id: "4117", name: "MAK" },
    { id: "11253", name: "MAN1B1" },
    { id: "4123", name: "MAN2C1" },
    { id: "4128", name: "MAOA" },
    { id: "4129", name: "MAOB" },
    { id: "4130", name: "MAP1A" },
    { id: "4133", name: "MAP2" },
    { id: "5604", name: "MAP2K1" },
    { id: "5605", name: "MAP2K2" },
    { id: "5606", name: "MAP2K3" },
    { id: "6416", name: "MAP2K4" },
    { id: "5607", name: "MAP2K5" },
    { id: "5608", name: "MAP2K6" },
    { id: "5609", name: "MAP2K7" },
    { id: "4214", name: "MAP3K1" },
    { id: "4294", name: "MAP3K10" },
    { id: "4296", name: "MAP3K11" },
    { id: "7786", name: "MAP3K12" },
    { id: "9175", name: "MAP3K13" },
    { id: "389840", name: "MAP3K15" },
    { id: "80122", name: "MAP3K19" },
    { id: "10746", name: "MAP3K2" },
    { id: "4215", name: "MAP3K3" },
    { id: "4216", name: "MAP3K4" },
    { id: "6885", name: "MAP3K7" },
    { id: "1326", name: "MAP3K8" },
    { id: "4293", name: "MAP3K9" },
    { id: "11184", name: "MAP4K1" },
    { id: "5871", name: "MAP4K2" },
    { id: "8491", name: "MAP4K3" },
    { id: "9448", name: "MAP4K4" },
    { id: "11183", name: "MAP4K5" },
    { id: "5594", name: "MAPK1" },
    { id: "5602", name: "MAPK10" },
    { id: "5600", name: "MAPK11" },
    { id: "6300", name: "MAPK12" },
    { id: "5603", name: "MAPK13" },
    { id: "1432", name: "MAPK14" },
    { id: "225689", name: "MAPK15" },
    { id: "5595", name: "MAPK3" },
    { id: "5596", name: "MAPK4" },
    { id: "5597", name: "MAPK6" },
    { id: "5598", name: "MAPK7" },
    { id: "5599", name: "MAPK8" },
    { id: "9479", name: "MAPK8IP1" },
    { id: "5601", name: "MAPK9" },
    { id: "9261", name: "MAPKAPK2" },
    { id: "7867", name: "MAPKAPK3" },
    { id: "8550", name: "MAPKAPK5" },
    { id: "4137", name: "MAPT" },
    { id: "4139", name: "MARK1" },
    { id: "2011", name: "MARK2" },
    { id: "4140", name: "MARK3" },
    { id: "57787", name: "MARK4" },
    { id: "22983", name: "MAST1" },
    { id: "4144", name: "MAT2A" },
    { id: "4145", name: "MATK" },
    { id: "4146", name: "MATN1" },
    { id: "4158", name: "MC2R" },
    { id: "56922", name: "MCCC1" },
    { id: "64087", name: "MCCC2" },
    { id: "4190", name: "MDH1" },
    { id: "4191", name: "MDH2" },
    { id: "4199", name: "ME1" },
    { id: "4200", name: "ME2" },
    { id: "10873", name: "ME3" },
    { id: "5469", name: "MED1" },
    { id: "9833", name: "MELK" },
    { id: "10461", name: "MERTK" },
    { id: "4233", name: "MET" },
    { id: "23173", name: "METAP1" },
    { id: "10988", name: "METAP2" },
    { id: "8972", name: "MGAM" },
    { id: "11343", name: "MGLL" },
    { id: "4255", name: "MGMT" },
    { id: "4257", name: "MGST1" },
    { id: "4258", name: "MGST2" },
    { id: "4259", name: "MGST3" },
    { id: "4282", name: "MIF" },
    { id: "50488", name: "MINK1" },
    { id: "57591", name: "MKL1" },
    { id: "8569", name: "MKNK1" },
    { id: "2872", name: "MKNK2" },
    { id: "326625", name: "MMAB" },
    { id: "25974", name: "MMACHC" },
    { id: "4311", name: "MME" },
    { id: "4312", name: "MMP1" },
    { id: "4321", name: "MMP12" },
    { id: "4322", name: "MMP13" },
    { id: "4323", name: "MMP14" },
    { id: "4324", name: "MMP15" },
    { id: "4325", name: "MMP16" },
    { id: "4326", name: "MMP17" },
    { id: "4313", name: "MMP2" },
    { id: "9313", name: "MMP20" },
    { id: "10893", name: "MMP24" },
    { id: "4314", name: "MMP3" },
    { id: "4317", name: "MMP8" },
    { id: "4318", name: "MMP9" },
    { id: "55034", name: "MOCOS" },
    { id: "4353", name: "MPO" },
    { id: "3140", name: "MR1" },
    { id: "57380", name: "MRS2" },
    { id: "931", name: "MS4A1" },
    { id: "4486", name: "MST1R" },
    { id: "51765", name: "MST4" },
    { id: "4504", name: "MT3" },
    { id: "4507", name: "MTAP" },
    { id: "123263", name: "MTFMT" },
    { id: "4522", name: "MTHFD1" },
    { id: "10797", name: "MTHFD2" },
    { id: "4524", name: "MTHFR" },
    { id: "4543", name: "MTNR1A" },
    { id: "4544", name: "MTNR1B" },
    { id: "2475", name: "MTOR" },
    { id: "4548", name: "MTR" },
    { id: "4552", name: "MTRR" },
    { id: "4547", name: "MTTP" },
    { id: "4583", name: "MUC2" },
    { id: "4593", name: "MUSK" },
    { id: "4594", name: "MUT" },
    { id: "4598", name: "MVK" },
    { id: "79784", name: "MYH14" },
    { id: "4625", name: "MYH7" },
    { id: "10627", name: "MYL12A" },
    { id: "140465", name: "MYL6B" },
    { id: "4638", name: "MYLK" },
    { id: "85366", name: "MYLK2" },
    { id: "91807", name: "MYLK3" },
    { id: "53904", name: "MYO3A" },
    { id: "4661", name: "MYT1" },
    { id: "55191", name: "NADSYN1" },
    { id: "8883", name: "NAE1" },
    { id: "55577", name: "NAGK" },
    { id: "162417", name: "NAGS" },
    { id: "79731", name: "NARS2" },
    { id: "8648", name: "NCOA1" },
    { id: "10499", name: "NCOA2" },
    { id: "9611", name: "NCOR1" },
    { id: "54820", name: "NDE1" },
    { id: "4694", name: "NDUFA1" },
    { id: "4705", name: "NDUFA10" },
    { id: "55967", name: "NDUFA12" },
    { id: "51079", name: "NDUFA13" },
    { id: "4695", name: "NDUFA2" },
    { id: "4696", name: "NDUFA3" },
    { id: "4697", name: "NDUFA4" },
    { id: "4698", name: "NDUFA5" },
    { id: "4700", name: "NDUFA6" },
    { id: "4701", name: "NDUFA7" },
    { id: "4702", name: "NDUFA8" },
    { id: "4704", name: "NDUFA9" },
    { id: "4706", name: "NDUFAB1" },
    { id: "4707", name: "NDUFB1" },
    { id: "4716", name: "NDUFB10" },
    { id: "4708", name: "NDUFB2" },
    { id: "4709", name: "NDUFB3" },
    { id: "4710", name: "NDUFB4" },
    { id: "4711", name: "NDUFB5" },
    { id: "4712", name: "NDUFB6" },
    { id: "4713", name: "NDUFB7" },
    { id: "4714", name: "NDUFB8" },
    { id: "4715", name: "NDUFB9" },
    { id: "4717", name: "NDUFC1" },
    { id: "4718", name: "NDUFC2" },
    { id: "4719", name: "NDUFS1" },
    { id: "4720", name: "NDUFS2" },
    { id: "4722", name: "NDUFS3" },
    { id: "4724", name: "NDUFS4" },
    { id: "4725", name: "NDUFS5" },
    { id: "4726", name: "NDUFS6" },
    { id: "374291", name: "NDUFS7" },
    { id: "4728", name: "NDUFS8" },
    { id: "4723", name: "NDUFV1" },
    { id: "4729", name: "NDUFV2" },
    { id: "4731", name: "NDUFV3" },
    { id: "79661", name: "NEIL1" },
    { id: "4750", name: "NEK1" },
    { id: "79858", name: "NEK11" },
    { id: "4751", name: "NEK2" },
    { id: "4752", name: "NEK3" },
    { id: "6787", name: "NEK4" },
    { id: "10783", name: "NEK6" },
    { id: "140609", name: "NEK7" },
    { id: "91754", name: "NEK9" },
    { id: "4758", name: "NEU1" },
    { id: "4759", name: "NEU2" },
    { id: "10825", name: "NEU3" },
    { id: "4779", name: "NFE2L1" },
    { id: "4790", name: "NFKB1" },
    { id: "4791", name: "NFKB2" },
    { id: "9054", name: "NFS1" },
    { id: "4803", name: "NGF" },
    { id: "27018", name: "NGFRAP1" },
    { id: "4809", name: "NHP2L1" },
    { id: "4811", name: "NID1" },
    { id: "51701", name: "NLK" },
    { id: "4830", name: "NME1" },
    { id: "4831", name: "NME2" },
    { id: "64802", name: "NMNAT1" },
    { id: "349565", name: "NMNAT3" },
    { id: "4836", name: "NMT1" },
    { id: "9397", name: "NMT2" },
    { id: "23530", name: "NNT" },
    { id: "26155", name: "NOC2L" },
    { id: "4842", name: "NOS1" },
    { id: "4843", name: "NOS2" },
    { id: "4846", name: "NOS3" },
    { id: "4856", name: "NOV" },
    { id: "50507", name: "NOX4" },
    { id: "2831", name: "NPBWR1" },
    { id: "29881", name: "NPC1L1" },
    { id: "4869", name: "NPM1" },
    { id: "4879", name: "NPPB" },
    { id: "4883", name: "NPR3" },
    { id: "387129", name: "NPSR1" },
    { id: "4852", name: "NPY" },
    { id: "4886", name: "NPY1R" },
    { id: "4887", name: "NPY2R" },
    { id: "1728", name: "NQO1" },
    { id: "190", name: "NR0B1" },
    { id: "8431", name: "NR0B2" },
    { id: "7376", name: "NR1H2" },
    { id: "10062", name: "NR1H3" },
    { id: "9971", name: "NR1H4" },
    { id: "8856", name: "NR1I2" },
    { id: "9970", name: "NR1I3" },
    { id: "2908", name: "NR3C1" },
    { id: "4306", name: "NR3C2" },
    { id: "8204", name: "NRIP1" },
    { id: "8829", name: "NRP1" },
    { id: "22978", name: "NT5C2" },
    { id: "4907", name: "NT5E" },
    { id: "4914", name: "NTRK1" },
    { id: "4915", name: "NTRK2" },
    { id: "4916", name: "NTRK3" },
    { id: "23620", name: "NTSR2" },
    { id: "9891", name: "NUAK1" },
    { id: "81788", name: "NUAK2" },
    { id: "53343", name: "NUDT9" },
    { id: "4942", name: "OAT" },
    { id: "4946", name: "OAZ1" },
    { id: "4947", name: "OAZ2" },
    { id: "51686", name: "OAZ3" },
    { id: "4953", name: "ODC1" },
    { id: "4967", name: "OGDH" },
    { id: "55239", name: "OGFOD1" },
    { id: "26873", name: "OPLAH" },
    { id: "4985", name: "OPRD1" },
    { id: "4986", name: "OPRK1" },
    { id: "4987", name: "OPRL1" },
    { id: "4988", name: "OPRM1" },
    { id: "5009", name: "OTC" },
    { id: "5019", name: "OXCT1" },
    { id: "165140", name: "OXER1" },
    { id: "9943", name: "OXSR1" },
    { id: "5021", name: "OXTR" },
    { id: "5023", name: "P2RX1" },
    { id: "5024", name: "P2RX3" },
    { id: "5025", name: "P2RX4" },
    { id: "5028", name: "P2RY1" },
    { id: "5032", name: "P2RY11" },
    { id: "64805", name: "P2RY12" },
    { id: "5029", name: "P2RY2" },
    { id: "5030", name: "P2RY4" },
    { id: "11240", name: "PADI2" },
    { id: "23569", name: "PADI4" },
    { id: "5050", name: "PAFAH1B3" },
    { id: "5053", name: "PAH" },
    { id: "10606", name: "PAICS" },
    { id: "5058", name: "PAK1" },
    { id: "5062", name: "PAK2" },
    { id: "5063", name: "PAK3" },
    { id: "10298", name: "PAK4" },
    { id: "56924", name: "PAK6" },
    { id: "57144", name: "PAK7" },
    { id: "5066", name: "PAM" },
    { id: "24145", name: "PANX1" },
    { id: "9061", name: "PAPSS1" },
    { id: "11315", name: "PARK7" },
    { id: "142", name: "PARP1" },
    { id: "10038", name: "PARP2" },
    { id: "10039", name: "PARP3" },
    { id: "55193", name: "PBRM1" },
    { id: "5091", name: "PC" },
    { id: "5095", name: "PCCA" },
    { id: "5105", name: "PCK1" },
    { id: "5110", name: "PCMT1" },
    { id: "5122", name: "PCSK1" },
    { id: "5126", name: "PCSK2" },
    { id: "9159", name: "PCSK7" },
    { id: "5130", name: "PCYT1A" },
    { id: "10846", name: "PDE10A" },
    { id: "50940", name: "PDE11A" },
    { id: "5136", name: "PDE1A" },
    { id: "5153", name: "PDE1B" },
    { id: "5138", name: "PDE2A" },
    { id: "5140", name: "PDE3B" },
    { id: "5141", name: "PDE4A" },
    { id: "5142", name: "PDE4B" },
    { id: "5144", name: "PDE4D" },
    { id: "8654", name: "PDE5A" },
    { id: "5147", name: "PDE6D" },
    { id: "5148", name: "PDE6G" },
    { id: "5151", name: "PDE8A" },
    { id: "8622", name: "PDE8B" },
    { id: "5152", name: "PDE9A" },
    { id: "64146", name: "PDF" },
    { id: "5156", name: "PDGFRA" },
    { id: "5159", name: "PDGFRB" },
    { id: "5160", name: "PDHA1" },
    { id: "5161", name: "PDHA2" },
    { id: "5162", name: "PDHB" },
    { id: "5163", name: "PDK1" },
    { id: "5164", name: "PDK2" },
    { id: "5165", name: "PDK3" },
    { id: "5166", name: "PDK4" },
    { id: "54704", name: "PDP1" },
    { id: "5170", name: "PDPK1" },
    { id: "23590", name: "PDSS1" },
    { id: "8566", name: "PDXK" },
    { id: "57026", name: "PDXP" },
    { id: "55825", name: "PECR" },
    { id: "5184", name: "PEPD" },
    { id: "5188", name: "PET112" },
    { id: "5196", name: "PF4" },
    { id: "5198", name: "PFAS" },
    { id: "5207", name: "PFKFB1" },
    { id: "5210", name: "PFKFB4" },
    { id: "5213", name: "PFKM" },
    { id: "5217", name: "PFN2" },
    { id: "643834", name: "PGA3" },
    { id: "5226", name: "PGD" },
    { id: "5229", name: "PGGT1B" },
    { id: "5241", name: "PGR" },
    { id: "26227", name: "PHGDH" },
    { id: "5261", name: "PHKG2" },
    { id: "162466", name: "PHOSPHO1" },
    { id: "5264", name: "PHYH" },
    { id: "5298", name: "PI4KB" },
    { id: "5277", name: "PIGA" },
    { id: "5287", name: "PIK3C2B" },
    { id: "5288", name: "PIK3C2G" },
    { id: "5289", name: "PIK3C3" },
    { id: "5290", name: "PIK3CA" },
    { id: "5291", name: "PIK3CB" },
    { id: "5293", name: "PIK3CD" },
    { id: "5294", name: "PIK3CG" },
    { id: "5295", name: "PIK3R1" },
    { id: "5296", name: "PIK3R2" },
    { id: "8503", name: "PIK3R3" },
    { id: "5292", name: "PIM1" },
    { id: "11040", name: "PIM2" },
    { id: "415116", name: "PIM3" },
    { id: "5300", name: "PIN1" },
    { id: "8396", name: "PIP4K2B" },
    { id: "79837", name: "PIP4K2C" },
    { id: "8394", name: "PIP5K1A" },
    { id: "23396", name: "PIP5K1C" },
    { id: "51268", name: "PIPOX" },
    { id: "5569", name: "PKIA" },
    { id: "5313", name: "PKLR" },
    { id: "5315", name: "PKM" },
    { id: "9088", name: "PKMYT1" },
    { id: "5585", name: "PKN1" },
    { id: "5586", name: "PKN2" },
    { id: "23659", name: "PLA2G15" },
    { id: "5319", name: "PLA2G1B" },
    { id: "5320", name: "PLA2G2A" },
    { id: "5321", name: "PLA2G4A" },
    { id: "7941", name: "PLA2G7" },
    { id: "5327", name: "PLAT" },
    { id: "5328", name: "PLAU" },
    { id: "5329", name: "PLAUR" },
    { id: "5337", name: "PLD1" },
    { id: "5338", name: "PLD2" },
    { id: "59338", name: "PLEKHA1" },
    { id: "57664", name: "PLEKHA4" },
    { id: "5340", name: "PLG" },
    { id: "5347", name: "PLK1" },
    { id: "10769", name: "PLK2" },
    { id: "1263", name: "PLK3" },
    { id: "10733", name: "PLK4" },
    { id: "5351", name: "PLOD1" },
    { id: "8985", name: "PLOD3" },
    { id: "5366", name: "PMAIP1" },
    { id: "5395", name: "PMS2" },
    { id: "5408", name: "PNLIPRP2" },
    { id: "4860", name: "PNP" },
    { id: "55163", name: "PNPO" },
    { id: "5422", name: "POLA1" },
    { id: "23649", name: "POLA2" },
    { id: "5426", name: "POLE" },
    { id: "5427", name: "POLE2" },
    { id: "54107", name: "POLE3" },
    { id: "56655", name: "POLE4" },
    { id: "5443", name: "POMC" },
    { id: "5444", name: "PON1" },
    { id: "5447", name: "POR" },
    { id: "5465", name: "PPARA" },
    { id: "5467", name: "PPARD" },
    { id: "5468", name: "PPARG" },
    { id: "60490", name: "PPCDC" },
    { id: "5478", name: "PPIA" },
    { id: "5479", name: "PPIB" },
    { id: "5480", name: "PPIC" },
    { id: "10105", name: "PPIF" },
    { id: "9360", name: "PPIG" },
    { id: "10465", name: "PPIH" },
    { id: "5499", name: "PPP1CA" },
    { id: "5515", name: "PPP2CA" },
    { id: "5516", name: "PPP2CB" },
    { id: "5518", name: "PPP2R1A" },
    { id: "5520", name: "PPP2R2A" },
    { id: "5527", name: "PPP2R5C" },
    { id: "5530", name: "PPP3CA" },
    { id: "5534", name: "PPP3R1" },
    { id: "5531", name: "PPP4C" },
    { id: "5547", name: "PRCP" },
    { id: "7001", name: "PRDX2" },
    { id: "25824", name: "PRDX5" },
    { id: "9588", name: "PRDX6" },
    { id: "5562", name: "PRKAA1" },
    { id: "5563", name: "PRKAA2" },
    { id: "5564", name: "PRKAB1" },
    { id: "5565", name: "PRKAB2" },
    { id: "5566", name: "PRKACA" },
    { id: "5567", name: "PRKACB" },
    { id: "5573", name: "PRKAR1A" },
    { id: "5578", name: "PRKCA" },
    { id: "5579", name: "PRKCB" },
    { id: "5580", name: "PRKCD" },
    { id: "5581", name: "PRKCE" },
    { id: "5582", name: "PRKCG" },
    { id: "5583", name: "PRKCH" },
    { id: "5584", name: "PRKCI" },
    { id: "5588", name: "PRKCQ" },
    { id: "5590", name: "PRKCZ" },
    { id: "5587", name: "PRKD1" },
    { id: "23683", name: "PRKD3" },
    { id: "5591", name: "PRKDC" },
    { id: "5592", name: "PRKG1" },
    { id: "5593", name: "PRKG2" },
    { id: "5613", name: "PRKX" },
    { id: "5618", name: "PRLR" },
    { id: "5621", name: "PRNP" },
    { id: "5624", name: "PROC" },
    { id: "10544", name: "PROCR" },
    { id: "5625", name: "PRODH" },
    { id: "5627", name: "PROS1" },
    { id: "11212", name: "PROSC" },
    { id: "9128", name: "PRPF4" },
    { id: "8899", name: "PRPF4B" },
    { id: "5644", name: "PRSS1" },
    { id: "5646", name: "PRSS3" },
    { id: "58497", name: "PRUNE" },
    { id: "29968", name: "PSAT1" },
    { id: "5682", name: "PSMA1" },
    { id: "5683", name: "PSMA2" },
    { id: "5684", name: "PSMA3" },
    { id: "5685", name: "PSMA4" },
    { id: "5686", name: "PSMA5" },
    { id: "5687", name: "PSMA6" },
    { id: "5688", name: "PSMA7" },
    { id: "5689", name: "PSMB1" },
    { id: "5690", name: "PSMB2" },
    { id: "5691", name: "PSMB3" },
    { id: "5692", name: "PSMB4" },
    { id: "5693", name: "PSMB5" },
    { id: "5694", name: "PSMB6" },
    { id: "5695", name: "PSMB7" },
    { id: "5696", name: "PSMB8" },
    { id: "5707", name: "PSMD1" },
    { id: "10213", name: "PSMD14" },
    { id: "5708", name: "PSMD2" },
    { id: "5724", name: "PTAFR" },
    { id: "5729", name: "PTGDR" },
    { id: "11251", name: "PTGDR2" },
    { id: "5733", name: "PTGER3" },
    { id: "5734", name: "PTGER4" },
    { id: "9536", name: "PTGES" },
    { id: "5737", name: "PTGFR" },
    { id: "5739", name: "PTGIR" },
    { id: "5740", name: "PTGIS" },
    { id: "145482", name: "PTGR2" },
    { id: "5742", name: "PTGS1" },
    { id: "5743", name: "PTGS2" },
    { id: "5741", name: "PTH" },
    { id: "5747", name: "PTK2" },
    { id: "2185", name: "PTK2B" },
    { id: "5753", name: "PTK6" },
    { id: "11156", name: "PTP4A3" },
    { id: "5770", name: "PTPN1" },
    { id: "5771", name: "PTPN2" },
    { id: "5775", name: "PTPN4" },
    { id: "5777", name: "PTPN6" },
    { id: "5786", name: "PTPRA" },
    { id: "5787", name: "PTPRB" },
    { id: "5791", name: "PTPRE" },
    { id: "5802", name: "PTPRS" },
    { id: "5817", name: "PVR" },
    { id: "5831", name: "PYCR1" },
    { id: "5834", name: "PYGB" },
    { id: "5836", name: "PYGL" },
    { id: "5837", name: "PYGM" },
    { id: "5860", name: "QDPR" },
    { id: "25797", name: "QPCT" },
    { id: "23475", name: "QPRT" },
    { id: "5870", name: "RAB6A" },
    { id: "9367", name: "RAB9A" },
    { id: "5875", name: "RABGGTA" },
    { id: "5876", name: "RABGGTB" },
    { id: "5879", name: "RAC1" },
    { id: "5888", name: "RAD51" },
    { id: "5894", name: "RAF1" },
    { id: "10267", name: "RAMP1" },
    { id: "10266", name: "RAMP2" },
    { id: "10268", name: "RAMP3" },
    { id: "5911", name: "RAP2A" },
    { id: "5914", name: "RARA" },
    { id: "5915", name: "RARB" },
    { id: "5916", name: "RARG" },
    { id: "5925", name: "RB1" },
    { id: "10432", name: "RBM14" },
    { id: "5947", name: "RBP1" },
    { id: "5949", name: "RBP3" },
    { id: "5950", name: "RBP4" },
    { id: "51109", name: "RDH11" },
    { id: "145226", name: "RDH12" },
    { id: "5959", name: "RDH5" },
    { id: "50700", name: "RDH8" },
    { id: "5967", name: "REG1A" },
    { id: "5970", name: "RELA" },
    { id: "5972", name: "REN" },
    { id: "5973", name: "RENBP" },
    { id: "5979", name: "RET" },
    { id: "54884", name: "RETSAT" },
    { id: "55312", name: "RFK" },
    { id: "8625", name: "RFXANK" },
    { id: "6010", name: "RHO" },
    { id: "387", name: "RHOA" },
    { id: "388", name: "RHOB" },
    { id: "8737", name: "RIPK1" },
    { id: "8767", name: "RIPK2" },
    { id: "54101", name: "RIPK4" },
    { id: "6035", name: "RNASE1" },
    { id: "6036", name: "RNASE2" },
    { id: "6037", name: "RNASE3" },
    { id: "6038", name: "RNASE4" },
    { id: "6093", name: "ROCK1" },
    { id: "9475", name: "ROCK2" },
    { id: "6095", name: "RORA" },
    { id: "6096", name: "RORB" },
    { id: "6097", name: "RORC" },
    { id: "6098", name: "ROS1" },
    { id: "22934", name: "RPIA" },
    { id: "140801", name: "RPL10L" },
    { id: "6135", name: "RPL11" },
    { id: "23521", name: "RPL13A" },
    { id: "6138", name: "RPL15" },
    { id: "6143", name: "RPL19" },
    { id: "9349", name: "RPL23" },
    { id: "6147", name: "RPL23A" },
    { id: "51121", name: "RPL26L1" },
    { id: "6122", name: "RPL3" },
    { id: "6167", name: "RPL37" },
    { id: "6125", name: "RPL5" },
    { id: "6132", name: "RPL8" },
    { id: "6181", name: "RPLP2" },
    { id: "6195", name: "RPS6KA1" },
    { id: "6196", name: "RPS6KA2" },
    { id: "6197", name: "RPS6KA3" },
    { id: "8986", name: "RPS6KA4" },
    { id: "9252", name: "RPS6KA5" },
    { id: "27330", name: "RPS6KA6" },
    { id: "6198", name: "RPS6KB1" },
    { id: "6240", name: "RRM1" },
    { id: "6241", name: "RRM2" },
    { id: "50484", name: "RRM2B" },
    { id: "51187", name: "RSL24D1" },
    { id: "65078", name: "RTN4R" },
    { id: "6256", name: "RXRA" },
    { id: "6257", name: "RXRB" },
    { id: "6258", name: "RXRG" },
    { id: "6261", name: "RYR1" },
    { id: "6271", name: "S100A1" },
    { id: "6282", name: "S100A11" },
    { id: "6283", name: "S100A12" },
    { id: "6284", name: "S100A13" },
    { id: "6273", name: "S100A2" },
    { id: "6275", name: "S100A4" },
    { id: "6278", name: "S100A7" },
    { id: "6285", name: "S100B" },
    { id: "6286", name: "S100P" },
    { id: "1901", name: "S1PR1" },
    { id: "1903", name: "S1PR3" },
    { id: "8698", name: "S1PR4" },
    { id: "6288", name: "SAA1" },
    { id: "6301", name: "SARS" },
    { id: "6303", name: "SAT1" },
    { id: "949", name: "SCARB1" },
    { id: "6336", name: "SCN10A" },
    { id: "6323", name: "SCN1A" },
    { id: "6324", name: "SCN1B" },
    { id: "6326", name: "SCN2A" },
    { id: "6327", name: "SCN2B" },
    { id: "6328", name: "SCN3A" },
    { id: "55800", name: "SCN3B" },
    { id: "6330", name: "SCN4B" },
    { id: "6331", name: "SCN5A" },
    { id: "6337", name: "SCNN1A" },
    { id: "6338", name: "SCNN1B" },
    { id: "6339", name: "SCNN1D" },
    { id: "6340", name: "SCNN1G" },
    { id: "59342", name: "SCPEP1" },
    { id: "6383", name: "SDC2" },
    { id: "6389", name: "SDHA" },
    { id: "6390", name: "SDHB" },
    { id: "6391", name: "SDHC" },
    { id: "6392", name: "SDHD" },
    { id: "10993", name: "SDS" },
    { id: "113675", name: "SDSL" },
    { id: "23541", name: "SEC14L2" },
    { id: "6401", name: "SELE" },
    { id: "6403", name: "SELP" },
    { id: "51091", name: "SEPSECS" },
    { id: "5265", name: "SERPINA1" },
    { id: "5104", name: "SERPINA5" },
    { id: "5055", name: "SERPINB2" },
    { id: "5268", name: "SERPINB5" },
    { id: "5269", name: "SERPINB6" },
    { id: "462", name: "SERPINC1" },
    { id: "3053", name: "SERPIND1" },
    { id: "5054", name: "SERPINE1" },
    { id: "80854", name: "SETD7" },
    { id: "23450", name: "SF3B3" },
    { id: "6422", name: "SFRP1" },
    { id: "6441", name: "SFTPD" },
    { id: "6446", name: "SGK1" },
    { id: "23678", name: "SGK3" },
    { id: "8879", name: "SGPL1" },
    { id: "6464", name: "SHC1" },
    { id: "6469", name: "SHH" },
    { id: "6470", name: "SHMT1" },
    { id: "6472", name: "SHMT2" },
    { id: "6476", name: "SI" },
    { id: "27036", name: "SIGLEC7" },
    { id: "10280", name: "SIGMAR1" },
    { id: "150094", name: "SIK1" },
    { id: "23235", name: "SIK2" },
    { id: "22933", name: "SIRT2" },
    { id: "23408", name: "SIRT5" },
    { id: "6500", name: "SKP1" },
    { id: "6554", name: "SLC10A1" },
    { id: "6565", name: "SLC15A2" },
    { id: "6573", name: "SLC19A1" },
    { id: "6505", name: "SLC1A1" },
    { id: "6580", name: "SLC22A1" },
    { id: "55867", name: "SLC22A11" },
    { id: "6582", name: "SLC22A2" },
    { id: "6581", name: "SLC22A3" },
    { id: "9356", name: "SLC22A6" },
    { id: "9376", name: "SLC22A8" },
    { id: "6513", name: "SLC2A1" },
    { id: "55244", name: "SLC47A1" },
    { id: "146802", name: "SLC47A2" },
    { id: "6523", name: "SLC5A1" },
    { id: "115584", name: "SLC5A11" },
    { id: "6524", name: "SLC5A2" },
    { id: "6529", name: "SLC6A1" },
    { id: "6538", name: "SLC6A11" },
    { id: "55117", name: "SLC6A15" },
    { id: "6530", name: "SLC6A2" },
    { id: "6531", name: "SLC6A3" },
    { id: "6532", name: "SLC6A4" },
    { id: "23657", name: "SLC7A11" },
    { id: "10599", name: "SLCO1B1" },
    { id: "28234", name: "SLCO1B3" },
    { id: "6578", name: "SLCO2A1" },
    { id: "11309", name: "SLCO2B1" },
    { id: "9748", name: "SLK" },
    { id: "6608", name: "SMO" },
    { id: "54498", name: "SMOX" },
    { id: "6609", name: "SMPD1" },
    { id: "55627", name: "SMPD4" },
    { id: "6611", name: "SMS" },
    { id: "6616", name: "SNAP25" },
    { id: "54861", name: "SNRK" },
    { id: "6626", name: "SNRPA" },
    { id: "6646", name: "SOAT1" },
    { id: "6647", name: "SOD1" },
    { id: "6652", name: "SORD" },
    { id: "6697", name: "SPR" },
    { id: "10558", name: "SPTLC1" },
    { id: "9517", name: "SPTLC2" },
    { id: "55304", name: "SPTLC3" },
    { id: "6714", name: "SRC" },
    { id: "6715", name: "SRD5A1" },
    { id: "6716", name: "SRD5A2" },
    { id: "6721", name: "SREBF2" },
    { id: "6725", name: "SRMS" },
    { id: "6732", name: "SRPK1" },
    { id: "6733", name: "SRPK2" },
    { id: "26576", name: "SRPK3" },
    { id: "63826", name: "SRR" },
    { id: "6750", name: "SST" },
    { id: "6751", name: "SSTR1" },
    { id: "6752", name: "SSTR2" },
    { id: "6755", name: "SSTR5" },
    { id: "6768", name: "ST14" },
    { id: "6777", name: "STAT5B" },
    { id: "6793", name: "STK10" },
    { id: "6794", name: "STK11" },
    { id: "8576", name: "STK16" },
    { id: "9263", name: "STK17A" },
    { id: "9262", name: "STK17B" },
    { id: "8428", name: "STK24" },
    { id: "10494", name: "STK25" },
    { id: "6788", name: "STK3" },
    { id: "202374", name: "STK32A" },
    { id: "55351", name: "STK32B" },
    { id: "27148", name: "STK36" },
    { id: "11329", name: "STK38" },
    { id: "23012", name: "STK38L" },
    { id: "27347", name: "STK39" },
    { id: "6789", name: "STK4" },
    { id: "81551", name: "STMN4" },
    { id: "412", name: "STS" },
    { id: "8803", name: "SUCLA2" },
    { id: "8802", name: "SUCLG1" },
    { id: "8801", name: "SUCLG2" },
    { id: "6817", name: "SULT1A1" },
    { id: "27284", name: "SULT1B1" },
    { id: "6783", name: "SULT1E1" },
    { id: "6822", name: "SULT2A1" },
    { id: "6820", name: "SULT2B1" },
    { id: "6821", name: "SUOX" },
    { id: "6850", name: "SYK" },
    { id: "127833", name: "SYT2" },
    { id: "94121", name: "SYTL4" },
    { id: "134864", name: "TAAR1" },
    { id: "319100", name: "TAAR6" },
    { id: "6869", name: "TACR1" },
    { id: "6870", name: "TACR3" },
    { id: "57551", name: "TAOK1" },
    { id: "9344", name: "TAOK2" },
    { id: "51347", name: "TAOK3" },
    { id: "6890", name: "TAP1" },
    { id: "6897", name: "TARS" },
    { id: "80222", name: "TARS2" },
    { id: "80834", name: "TAS1R2" },
    { id: "6898", name: "TAT" },
    { id: "29110", name: "TBK1" },
    { id: "50945", name: "TBX22" },
    { id: "6915", name: "TBXA2R" },
    { id: "7006", name: "TEC" },
    { id: "7010", name: "TEK" },
    { id: "7016", name: "TESK1" },
    { id: "7018", name: "TF" },
    { id: "7035", name: "TFPI" },
    { id: "7046", name: "TGFBR1" },
    { id: "7048", name: "TGFBR2" },
    { id: "7052", name: "TGM2" },
    { id: "7053", name: "TGM3" },
    { id: "7054", name: "TH" },
    { id: "7056", name: "THBD" },
    { id: "79896", name: "THNSL1" },
    { id: "7067", name: "THRA" },
    { id: "7068", name: "THRB" },
    { id: "7075", name: "TIE1" },
    { id: "7083", name: "TK1" },
    { id: "9874", name: "TLK1" },
    { id: "11011", name: "TLK2" },
    { id: "7097", name: "TLR2" },
    { id: "7099", name: "TLR4" },
    { id: "51284", name: "TLR7" },
    { id: "51311", name: "TLR8" },
    { id: "54106", name: "TLR9" },
    { id: "55217", name: "TMLHE" },
    { id: "7124", name: "TNF" },
    { id: "7133", name: "TNFRSF1B" },
    { id: "8600", name: "TNFSF11" },
    { id: "10673", name: "TNFSF13B" },
    { id: "23043", name: "TNIK" },
    { id: "8711", name: "TNK1" },
    { id: "10188", name: "TNK2" },
    { id: "8658", name: "TNKS" },
    { id: "80351", name: "TNKS2" },
    { id: "7134", name: "TNNC1" },
    { id: "7125", name: "TNNC2" },
    { id: "7137", name: "TNNI3" },
    { id: "51086", name: "TNNI3K" },
    { id: "7150", name: "TOP1" },
    { id: "116447", name: "TOP1MT" },
    { id: "7153", name: "TOP2A" },
    { id: "7155", name: "TOP2B" },
    { id: "219931", name: "TPCN2" },
    { id: "7167", name: "TPI1" },
    { id: "7173", name: "TPO" },
    { id: "7175", name: "TPR" },
    { id: "58485", name: "TRAPPC1" },
    { id: "6399", name: "TRAPPC2" },
    { id: "51399", name: "TRAPPC4" },
    { id: "1787", name: "TRDMT1" },
    { id: "54210", name: "TREM1" },
    { id: "10206", name: "TRIM13" },
    { id: "8989", name: "TRPA1" },
    { id: "54795", name: "TRPM4" },
    { id: "79054", name: "TRPM8" },
    { id: "7442", name: "TRPV1" },
    { id: "706", name: "TSPO" },
    { id: "83942", name: "TSSK1B" },
    { id: "7263", name: "TST" },
    { id: "7264", name: "TSTA3" },
    { id: "7272", name: "TTK" },
    { id: "7276", name: "TTR" },
    { id: "7846", name: "TUBA1A" },
    { id: "10376", name: "TUBA1B" },
    { id: "84790", name: "TUBA1C" },
    { id: "7278", name: "TUBA3C" },
    { id: "7277", name: "TUBA4A" },
    { id: "203068", name: "TUBB" },
    { id: "347733", name: "TUBB2B" },
    { id: "10381", name: "TUBB3" },
    { id: "10382", name: "TUBB4A" },
    { id: "10383", name: "TUBB4B" },
    { id: "51175", name: "TUBE1" },
    { id: "7283", name: "TUBG1" },
    { id: "7294", name: "TXK" },
    { id: "51060", name: "TXNDC12" },
    { id: "7296", name: "TXNRD1" },
    { id: "7297", name: "TYK2" },
    { id: "7298", name: "TYMS" },
    { id: "7299", name: "TYR" },
    { id: "7301", name: "TYRO3" },
    { id: "6675", name: "UAP1" },
    { id: "7371", name: "UCK2" },
    { id: "7358", name: "UGDH" },
    { id: "8408", name: "ULK1" },
    { id: "9706", name: "ULK2" },
    { id: "25989", name: "ULK3" },
    { id: "139596", name: "UPRT" },
    { id: "29796", name: "UQCR10" },
    { id: "10975", name: "UQCR11" },
    { id: "7381", name: "UQCRB" },
    { id: "7384", name: "UQCRC1" },
    { id: "7385", name: "UQCRC2" },
    { id: "7388", name: "UQCRH" },
    { id: "6843", name: "VAMP1" },
    { id: "6844", name: "VAMP2" },
    { id: "7407", name: "VARS" },
    { id: "7412", name: "VCAM1" },
    { id: "7416", name: "VDAC1" },
    { id: "7417", name: "VDAC2" },
    { id: "7419", name: "VDAC3" },
    { id: "7421", name: "VDR" },
    { id: "7422", name: "VEGFA" },
    { id: "79001", name: "VKORC1" },
    { id: "154807", name: "VKORC1L1" },
    { id: "7444", name: "VRK2" },
    { id: "7448", name: "VTN" },
    { id: "7450", name: "VWF" },
    { id: "7453", name: "WARS" },
    { id: "7465", name: "WEE1" },
    { id: "7498", name: "XDH" },
    { id: "7518", name: "XRCC4" },
    { id: "9942", name: "XYLB" },
    { id: "8565", name: "YARS" },
    { id: "51067", name: "YARS2" },
    { id: "7525", name: "YES1" },
    { id: "51776", name: "ZAK" },
    { id: "7535", name: "ZAP70" },
  ],
  DisKGE_disease: [{
    "id": "Disease::MESH:D000182",
    "name": "ACTH Syndrome"
},
{
    "id": "Disease::MESH:D049913",
    "name": "ACTH-Secreting Pituitary Adenoma"
},
{
    "id": "Disease::MESH:D020943",
    "name": "AIDS Arteritis"
},
{
    "id": "Disease::MESH:D015526",
    "name": "AIDS Dementia Complex"
},
{
    "id": "Disease::MESH:D000386",
    "name": "AIDS-Related Complex"
},
{
    "id": "Disease::MESH:D017088",
    "name": "AIDS-Related Opportunistic Infections"
},
{
    "id": "Disease::MESH:D018784",
    "name": "Abdominal Abscess"
},
{
    "id": "Disease::MESH:D000007",
    "name": "Abdominal Injuries"
},
{
    "id": "Disease::MESH:D000008",
    "name": "Abdominal Neoplasms"
},
{
    "id": "Disease::MESH:D015746",
    "name": "Abdominal Pain"
},
{
    "id": "Disease::MESH:D020434",
    "name": "Abducens Nerve Diseases"
},
{
    "id": "Disease::MESH:D058739",
    "name": "Aberrant Crypt Foci"
},
{
    "id": "Disease::MESH:D000012",
    "name": "Abetalipoproteinemia"
},
{
    "id": "Disease::MESH:D000015",
    "name": "Abnormalities"
},
{
    "id": "Disease::MESH:D000014",
    "name": "Abnormalities"
},
{
    "id": "Disease::MESH:D000026",
    "name": "Abortion"
},
{
    "id": "Disease::MESH:D000031",
    "name": "Abortion"
},
{
    "id": "Disease::MESH:D000033",
    "name": "Abortion"
},
{
    "id": "Disease::MESH:D000022",
    "name": "Abortion"
},
{
    "id": "Disease::MESH:D000027",
    "name": "Abortion"
},
{
    "id": "Disease::MESH:D000030",
    "name": "Abortion"
},
{
    "id": "Disease::MESH:D000037",
    "name": "Abruptio Placentae"
},
{
    "id": "Disease::MESH:D000038",
    "name": "Abscess"
},
{
    "id": "Disease::MESH:D042101",
    "name": "Acalculous Cholecystitis"
},
{
    "id": "Disease::MESH:D015823",
    "name": "Acanthamoeba Keratitis"
},
{
    "id": "Disease::MESH:D000052",
    "name": "Acanthosis Nigricans"
},
{
    "id": "Disease::MESH:D020642",
    "name": "Acatalasia"
},
{
    "id": "Disease::MESH:D016170",
    "name": "Accelerated Idioventricular Rhythm"
},
{
    "id": "Disease::MESH:D058606",
    "name": "Accessory Atrioventricular Bundle"
},
{
    "id": "Disease::MESH:D000069",
    "name": "Acculturation"
},
{
    "id": "Disease::MESH:D000072",
    "name": "Acedapsone"
},
{
    "id": "Disease::MESH:D000073",
    "name": "Acenaphthenes"
},
{
    "id": "Disease::MESH:D000075",
    "name": "Acepromazine"
},
{
    "id": "Disease::MESH:D000077",
    "name": "Acetabulum"
},
{
    "id": "Disease::MESH:D000079",
    "name": "Acetaldehyde"
},
{
    "id": "Disease::MESH:D000080",
    "name": "Acetals"
},
{
    "id": "Disease::MESH:D000081",
    "name": "Acetamides"
},
{
    "id": "Disease::MESH:D000126",
    "name": "Achlorhydria"
},
{
    "id": "Disease::MESH:D000130",
    "name": "Achondroplasia"
},
{
    "id": "Disease::MESH:D000137",
    "name": "Acid-Base Imbalance"
},
{
    "id": "Disease::MESH:D000138",
    "name": "Acidosis"
},
{
    "id": "Disease::MESH:D000142",
    "name": "Acidosis"
},
{
    "id": "Disease::MESH:D000140",
    "name": "Acidosis"
},
{
    "id": "Disease::MESH:D000141",
    "name": "Acidosis"
},
{
    "id": "Disease::MESH:D000151",
    "name": "Acinetobacter Infections"
},
{
    "id": "Disease::MESH:D000152",
    "name": "Acne Vulgaris"
},
{
    "id": "Disease::MESH:D017486",
    "name": "Acneiform Eruptions"
},
{
    "id": "Disease::MESH:D020083",
    "name": "Acquired Hyperostosis Syndrome"
},
{
    "id": "Disease::MESH:D000163",
    "name": "Acquired Immunodeficiency Syndrome"
},
{
    "id": "Disease::MESH:D030981",
    "name": "Acro-Osteolysis"
},
{
    "id": "Disease::MESH:D055673",
    "name": "Acrocallosal Syndrome"
},
{
    "id": "Disease::MESH:D000168",
    "name": "Acrocephalosyndactylia"
},
{
    "id": "Disease::MESH:D000169",
    "name": "Acrodermatitis"
},
{
    "id": "Disease::MESH:D000170",
    "name": "Acrodynia"
},
{
    "id": "Disease::MESH:D000172",
    "name": "Acromegaly"
},
{
    "id": "Disease::MESH:D000196",
    "name": "Actinomycosis"
},
{
    "id": "Disease::MESH:D020016",
    "name": "Activated Protein C Resistance"
},
{
    "id": "Disease::MESH:D056586",
    "name": "Acute Chest Syndrome"
},
{
    "id": "Disease::MESH:D054058",
    "name": "Acute Coronary Syndrome"
},
{
    "id": "Disease::MESH:D000208",
    "name": "Acute Disease"
},
{
    "id": "Disease::MESH:D056150",
    "name": "Acute Generalized Exanthematous Pustulosis"
},
{
    "id": "Disease::MESH:D058186",
    "name": "Acute Kidney Injury"
},
{
    "id": "Disease::MESH:D055371",
    "name": "Acute Lung Injury"
},
{
    "id": "Disease::MESH:D059787",
    "name": "Acute Pain"
},
{
    "id": "Disease::MESH:D054508",
    "name": "Acute Radiation Syndrome"
},
{
    "id": "Disease::MESH:D065290",
    "name": "Acute-On-Chronic Liver Failure"
},
{
    "id": "Disease::MESH:D000210",
    "name": "Acute-Phase Reaction"
},
{
    "id": "Disease::MESH:D050398",
    "name": "Adamantinoma"
},
{
    "id": "Disease::MESH:D000219",
    "name": "Adams-Stokes Syndrome"
},
{
    "id": "Disease::MESH:D000224",
    "name": "Addison Disease"
},
{
    "id": "Disease::MESH:D000230",
    "name": "Adenocarcinoma"
},
{
    "id": "Disease::MESH:D000231",
    "name": "Adenocarcinoma"
},
{
    "id": "Disease::MESH:D018266",
    "name": "Adenocarcinoma"
},
{
    "id": "Disease::MESH:D002293",
    "name": "Adenocarcinoma"
},
{
    "id": "Disease::MESH:D002282",
    "name": "Adenocarcinoma"
},
{
    "id": "Disease::MESH:D002288",
    "name": "Adenocarcinoma"
},
{
    "id": "Disease::MESH:D018262",
    "name": "Adenocarcinoma"
},
{
    "id": "Disease::MESH:D065311",
    "name": "Adenocarcinoma in Situ"
},
{
    "id": "Disease::MESH:D000232",
    "name": "Adenofibroma"
},
{
    "id": "Disease::MESH:D018248",
    "name": "Adenoma"
},
{
    "id": "Disease::MESH:D008949",
    "name": "Adenoma"
},
{
    "id": "Disease::MESH:D002759",
    "name": "Adenoma"
},
{
    "id": "Disease::MESH:D018253",
    "name": "Adenoma"
},
{
    "id": "Disease::MESH:D018249",
    "name": "Adenoma"
},
{
    "id": "Disease::MESH:D000236",
    "name": "Adenoma"
},
{
    "id": "Disease::MESH:D007516",
    "name": "Adenoma"
},
{
    "id": "Disease::MESH:D011125",
    "name": "Adenomatous Polyposis Coli"
},
{
    "id": "Disease::MESH:D018256",
    "name": "Adenomatous Polyps"
},
{
    "id": "Disease::MESH:D062788",
    "name": "Adenomyosis"
},
{
    "id": "Disease::MESH:D018195",
    "name": "Adenosarcoma"
},
{
    "id": "Disease::MESH:D000257",
    "name": "Adenoviridae Infections"
},
{
    "id": "Disease::MESH:D000275",
    "name": "Adjustment Disorders"
},
{
    "id": "Disease::MESH:D000303",
    "name": "Adrenal Cortex Diseases"
},
{
    "id": "Disease::MESH:D000305",
    "name": "Adrenal Cortex Hormones"
},
{
    "id": "Disease::MESH:D000306",
    "name": "Adrenal Cortex Neoplasms"
},
{
    "id": "Disease::MESH:D000310",
    "name": "Adrenal Gland Neoplasms"
},
{
    "id": "Disease::MESH:D000312",
    "name": "Adrenal Hyperplasia"
},
{
    "id": "Disease::MESH:D000309",
    "name": "Adrenal Insufficiency"
},
{
    "id": "Disease::MESH:D018246",
    "name": "Adrenocortical Adenoma"
},
{
    "id": "Disease::MESH:D018268",
    "name": "Adrenocortical Carcinoma"
},
{
    "id": "Disease::MESH:D000308",
    "name": "Adrenocortical Hyperfunction"
},
{
    "id": "Disease::MESH:D047808",
    "name": "Adrenogenital Syndrome"
},
{
    "id": "Disease::MESH:D000326",
    "name": "Adrenoleukodystrophy"
},
{
    "id": "Disease::MESH:D000341",
    "name": "Affective Disorders"
},
{
    "id": "Disease::MESH:D000343",
    "name": "Afferent Loop Syndrome"
},
{
    "id": "Disease::MESH:D000077329",
    "name": "Agammaglobulinaemia Tyrosine Kinase"
},
{
    "id": "Disease::MESH:D000361",
    "name": "Agammaglobulinemia"
},
{
    "id": "Disease::MESH:D061085",
    "name": "Agenesis of Corpus Callosum"
},
{
    "id": "Disease::MESH:D000370",
    "name": "Ageusia"
},
{
    "id": "Disease::MESH:D000374",
    "name": "Aggression"
},
{
    "id": "Disease::MESH:D010520",
    "name": "Aggressive Periodontitis"
},
{
    "id": "Disease::MESH:D019588",
    "name": "Aging"
},
{
    "id": "Disease::MESH:D000377",
    "name": "Agnosia"
},
{
    "id": "Disease::MESH:D000379",
    "name": "Agoraphobia"
},
{
    "id": "Disease::MESH:D000380",
    "name": "Agranulocytosis"
},
{
    "id": "Disease::MESH:D000382",
    "name": "Agricultural Workers' Diseases"
},
{
    "id": "Disease::MESH:D058540",
    "name": "Aicardi Syndrome"
},
{
    "id": "Disease::MESH:D000402",
    "name": "Airway Obstruction"
},
{
    "id": "Disease::MESH:D056151",
    "name": "Airway Remodeling"
},
{
    "id": "Disease::MESH:D017109",
    "name": "Akathisia"
},
{
    "id": "Disease::MESH:D016738",
    "name": "Alagille Syndrome"
},
{
    "id": "Disease::MESH:D000417",
    "name": "Albinism"
},
{
    "id": "Disease::MESH:D016115",
    "name": "Albinism"
},
{
    "id": "Disease::MESH:D000419",
    "name": "Albuminuria"
},
{
    "id": "Disease::MESH:D000425",
    "name": "Alcohol Amnestic Disorder"
},
{
    "id": "Disease::MESH:D000430",
    "name": "Alcohol Withdrawal Delirium"
},
{
    "id": "Disease::MESH:D020270",
    "name": "Alcohol Withdrawal Seizures"
},
{
    "id": "Disease::MESH:D019973",
    "name": "Alcohol-Related Disorders"
},
{
    "id": "Disease::MESH:D000435",
    "name": "Alcoholic Intoxication"
},
{
    "id": "Disease::MESH:D000437",
    "name": "Alcoholism"
},
{
    "id": "Disease::MESH:D038261",
    "name": "Alexander Disease"
},
{
    "id": "Disease::MESH:D000471",
    "name": "Alkalosis"
},
{
    "id": "Disease::MESH:D000472",
    "name": "Alkalosis"
},
{
    "id": "Disease::MESH:D000474",
    "name": "Alkaptonuria"
},
{
    "id": "Disease::MESH:D000505",
    "name": "Alopecia"
},
{
    "id": "Disease::MESH:D000506",
    "name": "Alopecia Areata"
},
{
    "id": "Disease::MESH:D056769",
    "name": "Alstrom Syndrome"
},
{
    "id": "Disease::MESH:D000532",
    "name": "Altitude Sickness"
},
{
    "id": "Disease::MESH:D016301",
    "name": "Alveolar Bone Loss"
},
{
    "id": "Disease::MESH:D000542",
    "name": "Alveolitis"
},
{
    "id": "Disease::MESH:D000544",
    "name": "Alzheimer Disease"
},
{
    "id": "Disease::MESH:D000550",
    "name": "Amblyopia"
},
{
    "id": "Disease::MESH:D000562",
    "name": "Amebiasis"
},
{
    "id": "Disease::MESH:D000564",
    "name": "Ameloblastoma"
},
{
    "id": "Disease::MESH:D000567",
    "name": "Amelogenesis Imperfecta"
},
{
    "id": "Disease::MESH:D000568",
    "name": "Amenorrhea"
},
{
    "id": "Disease::MESH:D000592",
    "name": "Amino Acid Metabolism"
},
{
    "id": "Disease::MESH:D000647",
    "name": "Amnesia"
},
{
    "id": "Disease::MESH:D020236",
    "name": "Amnesia"
},
{
    "id": "Disease::MESH:D000648",
    "name": "Amnesia"
},
{
    "id": "Disease::MESH:D000652",
    "name": "Amniotic Band Syndrome"
},
{
    "id": "Disease::MESH:D019969",
    "name": "Amphetamine-Related Disorders"
},
{
    "id": "Disease::MESH:D017772",
    "name": "Amyloid Neuropathies"
},
{
    "id": "Disease::MESH:D028227",
    "name": "Amyloid Neuropathies"
},
{
    "id": "Disease::MESH:D028226",
    "name": "Amyloidosis"
},
{
    "id": "Disease::MESH:D000686",
    "name": "Amyloidosis"
},
{
    "id": "Disease::MESH:D000690",
    "name": "Amyotrophic Lateral Sclerosis"
},
{
    "id": "Disease::MESH:D000707",
    "name": "Anaphylaxis"
},
{
    "id": "Disease::MESH:D000708",
    "name": "Anaplasia"
},
{
    "id": "Disease::MESH:D000712",
    "name": "Anaplasmosis"
},
{
    "id": "Disease::MESH:D057868",
    "name": "Anastomotic Leak"
},
{
    "id": "Disease::MESH:D000724",
    "name": "Ancylostomiasis"
},
{
    "id": "Disease::MESH:D050030",
    "name": "Andersen Syndrome"
},
{
    "id": "Disease::MESH:D013734",
    "name": "Androgen-Insensitivity Syndrome"
},
{
    "id": "Disease::MESH:D000746",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000748",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000747",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000754",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000744",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000751",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D029503",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000741",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000753",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000743",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000742",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000745",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000755",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D029502",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000740",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000752",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D018798",
    "name": "Anemia"
},
{
    "id": "Disease::MESH:D000757",
    "name": "Anencephaly"
},
{
    "id": "Disease::MESH:D000782",
    "name": "Aneuploidy"
},
{
    "id": "Disease::MESH:D017541",
    "name": "Aneurysm"
},
{
    "id": "Disease::MESH:D000784",
    "name": "Aneurysm"
},
{
    "id": "Disease::MESH:D017542",
    "name": "Aneurysm"
},
{
    "id": "Disease::MESH:D000785",
    "name": "Aneurysm"
},
{
    "id": "Disease::MESH:D000783",
    "name": "Aneurysm"
},
{
    "id": "Disease::MESH:D017204",
    "name": "Angelman Syndrome"
},
{
    "id": "Disease::MESH:D060050",
    "name": "Angina"
},
{
    "id": "Disease::MESH:D000789",
    "name": "Angina"
},
{
    "id": "Disease::MESH:D000788",
    "name": "Angina Pectoris"
},
{
    "id": "Disease::MESH:D000787",
    "name": "Angina Pectoris"
},
{
    "id": "Disease::MESH:D016888",
    "name": "Angiodysplasia"
},
{
    "id": "Disease::MESH:D000799",
    "name": "Angioedema"
},
{
    "id": "Disease::MESH:D054179",
    "name": "Angioedemas"
},
{
    "id": "Disease::MESH:D018322",
    "name": "Angiofibroma"
},
{
    "id": "Disease::MESH:D000793",
    "name": "Angioid Streaks"
},
{
    "id": "Disease::MESH:D000796",
    "name": "Angiolymphoid Hyperplasia with Eosinophilia"
},
{
    "id": "Disease::MESH:D016917",
    "name": "Angiomatosis"
},
{
    "id": "Disease::MESH:D018207",
    "name": "Angiomyolipoma"
},
{
    "id": "Disease::MESH:D018229",
    "name": "Angiomyoma"
},
{
    "id": "Disease::MESH:D059445",
    "name": "Anhedonia"
},
{
    "id": "Disease::MESH:D000820",
    "name": "Animal Diseases"
},
{
    "id": "Disease::MESH:D015783",
    "name": "Aniridia"
},
{
    "id": "Disease::MESH:D017129",
    "name": "Anisakiasis"
},
{
    "id": "Disease::MESH:D015858",
    "name": "Anisometropia"
},
{
    "id": "Disease::MESH:D016512",
    "name": "Ankle Injuries"
},
{
    "id": "Disease::MESH:D000844",
    "name": "Ankylosis"
},
{
    "id": "Disease::MESH:D000848",
    "name": "Anodontia"
},
{
    "id": "Disease::MESH:D000853",
    "name": "Anophthalmos"
},
{
    "id": "Disease::MESH:D000855",
    "name": "Anorexia"
},
{
    "id": "Disease::MESH:D000856",
    "name": "Anorexia Nervosa"
},
{
    "id": "Disease::MESH:D000858",
    "name": "Anovulation"
},
{
    "id": "Disease::MESH:D057971",
    "name": "Anterior Capsular Rupture"
},
{
    "id": "Disease::MESH:D056988",
    "name": "Anterior Wall Myocardial Infarction"
},
{
    "id": "Disease::MESH:D055008",
    "name": "Anthracosis"
},
{
    "id": "Disease::MESH:D000881",
    "name": "Anthrax"
},
{
    "id": "Disease::MESH:D019867",
    "name": "Anti-Glomerular Basement Membrane Disease"
},
{
    "id": "Disease::MESH:D060426",
    "name": "Anti-N-Methyl-D-Aspartate Receptor Encephalitis"
},
{
    "id": "Disease::MESH:D056648",
    "name": "Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis"
},
{
    "id": "Disease::MESH:D064807",
    "name": "Anticholinergic Syndrome"
},
{
    "id": "Disease::MESH:D000952",
    "name": "Antigens"
},
{
    "id": "Disease::MESH:D016736",
    "name": "Antiphospholipid Syndrome"
},
{
    "id": "Disease::MESH:D000987",
    "name": "Antisocial Personality Disorder"
},
{
    "id": "Disease::MESH:D020152",
    "name": "Antithrombin III Deficiency"
},
{
    "id": "Disease::MESH:D054882",
    "name": "Antley-Bixler Syndrome Phenotype"
},
{
    "id": "Disease::MESH:D001002",
    "name": "Anuria"
},
{
    "id": "Disease::MESH:D001005",
    "name": "Anus Neoplasms"
},
{
    "id": "Disease::MESH:D001007",
    "name": "Anxiety"
},
{
    "id": "Disease::MESH:D001010",
    "name": "Anxiety"
},
{
    "id": "Disease::MESH:D001008",
    "name": "Anxiety Disorders"
},
{
    "id": "Disease::MESH:D001014",
    "name": "Aortic Aneurysm"
},
{
    "id": "Disease::MESH:D017544",
    "name": "Aortic Aneurysm"
},
{
    "id": "Disease::MESH:D017545",
    "name": "Aortic Aneurysm"
},
{
    "id": "Disease::MESH:D001017",
    "name": "Aortic Coarctation"
},
{
    "id": "Disease::MESH:D001018",
    "name": "Aortic Diseases"
},
{
    "id": "Disease::MESH:D001019",
    "name": "Aortic Rupture"
},
{
    "id": "Disease::MESH:D021921",
    "name": "Aortic Stenosis"
},
{
    "id": "Disease::MESH:D001022",
    "name": "Aortic Valve Insufficiency"
},
{
    "id": "Disease::MESH:D001024",
    "name": "Aortic Valve Stenosis"
},
{
    "id": "Disease::MESH:D001025",
    "name": "Aortitis"
},
{
    "id": "Disease::MESH:D018420",
    "name": "Ape Diseases"
},
{
    "id": "Disease::MESH:D001035",
    "name": "Aphakia"
},
{
    "id": "Disease::MESH:D001037",
    "name": "Aphasia"
},
{
    "id": "Disease::MESH:D001039",
    "name": "Aphasia"
},
{
    "id": "Disease::MESH:D018886",
    "name": "Aphasia"
},
{
    "id": "Disease::MESH:D001049",
    "name": "Apnea"
},
{
    "id": "Disease::MESH:D001063",
    "name": "Appendiceal Neoplasms"
},
{
    "id": "Disease::MESH:D001064",
    "name": "Appendicitis"
},
{
    "id": "Disease::MESH:D001072",
    "name": "Apraxias"
},
{
    "id": "Disease::MESH:D001079",
    "name": "Apudoma"
},
{
    "id": "Disease::MESH:D001100",
    "name": "Arachnoiditis"
},
{
    "id": "Disease::MESH:D056807",
    "name": "Argininosuccinic Aciduria"
},
{
    "id": "Disease::MESH:D001139",
    "name": "Arnold-Chiari Malformation"
},
{
    "id": "Disease::MESH:D001145",
    "name": "Arrhythmias"
},
{
    "id": "Disease::MESH:D019571",
    "name": "Arrhythmogenic Right Ventricular Dysplasia"
},
{
    "id": "Disease::MESH:D020261",
    "name": "Arsenic Poisoning"
},
{
    "id": "Disease::MESH:D001157",
    "name": "Arterial Occlusive Diseases"
},
{
    "id": "Disease::MESH:D050379",
    "name": "Arteriolosclerosis"
},
{
    "id": "Disease::MESH:D001161",
    "name": "Arteriosclerosis"
},
{
    "id": "Disease::MESH:D001162",
    "name": "Arteriosclerosis Obliterans"
},
{
    "id": "Disease::MESH:D001164",
    "name": "Arteriovenous Fistula"
},
{
    "id": "Disease::MESH:D001165",
    "name": "Arteriovenous Malformations"
},
{
    "id": "Disease::MESH:D001167",
    "name": "Arteritis"
},
{
    "id": "Disease::MESH:D018771",
    "name": "Arthralgia"
},
{
    "id": "Disease::MESH:D001169",
    "name": "Arthritis"
},
{
    "id": "Disease::MESH:D015535",
    "name": "Arthritis"
},
{
    "id": "Disease::MESH:D001171",
    "name": "Arthritis"
},
{
    "id": "Disease::MESH:D001172",
    "name": "Arthritis"
},
{
    "id": "Disease::MESH:D015210",
    "name": "Arthritis"
},
{
    "id": "Disease::MESH:D001168",
    "name": "Arthritis"
},
{
    "id": "Disease::MESH:D001170",
    "name": "Arthritis"
},
{
    "id": "Disease::MESH:D001176",
    "name": "Arthrogryposis"
},
{
    "id": "Disease::MESH:D001183",
    "name": "Arthus Reaction"
},
{
    "id": "Disease::MESH:D060437",
    "name": "Artificial Lens Implant Migration"
},
{
    "id": "Disease::MESH:D001195",
    "name": "Asbestosis"
},
{
    "id": "Disease::MESH:D001196",
    "name": "Ascariasis"
},
{
    "id": "Disease::MESH:D001201",
    "name": "Ascites"
},
{
    "id": "Disease::MESH:D001206",
    "name": "Ascorbic Acid Deficiency"
},
{
    "id": "Disease::MESH:D054880",
    "name": "Aspartylglucosaminuria"
},
{
    "id": "Disease::MESH:D020817",
    "name": "Asperger Syndrome"
},
{
    "id": "Disease::MESH:D001228",
    "name": "Aspergillosis"
},
{
    "id": "Disease::MESH:D001229",
    "name": "Aspergillosis"
},
{
    "id": "Disease::MESH:D001237",
    "name": "Asphyxia"
},
{
    "id": "Disease::MESH:D001247",
    "name": "Asthenia"
},
{
    "id": "Disease::MESH:D053627",
    "name": "Asthenozoospermia"
},
{
    "id": "Disease::MESH:D055963",
    "name": "Asthma"
},
{
    "id": "Disease::MESH:D001249",
    "name": "Asthma"
},
{
    "id": "Disease::MESH:D001250",
    "name": "Asthma"
},
{
    "id": "Disease::MESH:D059366",
    "name": "Asthma"
},
{
    "id": "Disease::MESH:D001251",
    "name": "Astigmatism"
},
{
    "id": "Disease::MESH:D001254",
    "name": "Astrocytoma"
},
{
    "id": "Disease::MESH:D058345",
    "name": "Asymptomatic Infections"
},
{
    "id": "Disease::MESH:D001259",
    "name": "Ataxia"
},
{
    "id": "Disease::MESH:D001260",
    "name": "Ataxia Telangiectasia"
},
{
    "id": "Disease::MESH:D050197",
    "name": "Atherosclerosis"
},
{
    "id": "Disease::MESH:D001264",
    "name": "Athetosis"
},
{
    "id": "Disease::MESH:D001265",
    "name": "Athletic Injuries"
},
{
    "id": "Disease::MESH:D001281",
    "name": "Atrial Fibrillation"
},
{
    "id": "Disease::MESH:D001282",
    "name": "Atrial Flutter"
},
{
    "id": "Disease::MESH:D018880",
    "name": "Atrial Premature Complexes"
},
{
    "id": "Disease::MESH:D064752",
    "name": "Atrial Remodeling"
},
{
    "id": "Disease::MESH:D054537",
    "name": "Atrioventricular Block"
},
{
    "id": "Disease::MESH:D059268",
    "name": "Atrophic Vaginitis"
},
{
    "id": "Disease::MESH:D001284",
    "name": "Atrophy"
},
{
    "id": "Disease::MESH:D001289",
    "name": "Attention Deficit Disorder with Hyperactivity"
},
{
    "id": "Disease::MESH:D019958",
    "name": "Attention Deficit and Disruptive Behavior Disorders"
},
{
    "id": "Disease::MESH:D065766",
    "name": "Atypical Hemolytic Uremic Syndrome"
},
{
    "id": "Disease::MESH:D065309",
    "name": "Atypical Squamous Cells of the Cervix"
},
{
    "id": "Disease::MESH:D001304",
    "name": "Auditory Diseases"
},
{
    "id": "Disease::MESH:D001308",
    "name": "Auditory Perceptual Disorders"
},
{
    "id": "Disease::MESH:D001321",
    "name": "Autistic Disorder"
},
{
    "id": "Disease::MESH:D001327",
    "name": "Autoimmune Diseases"
},
{
    "id": "Disease::MESH:D020274",
    "name": "Autoimmune Diseases of the Nervous System"
},
{
    "id": "Disease::MESH:D056735",
    "name": "Autoimmune Lymphoproliferative Syndrome"
},
{
    "id": "Disease::MESH:D020211",
    "name": "Autonomic Dysreflexia"
},
{
    "id": "Disease::MESH:D001342",
    "name": "Autonomic Nervous System Diseases"
},
{
    "id": "Disease::MESH:D001353",
    "name": "Avian Leukosis"
},
{
    "id": "Disease::MESH:D001361",
    "name": "Avitaminosis"
},
{
    "id": "Disease::MESH:D053713",
    "name": "Azoospermia"
},
{
    "id": "Disease::MESH:D053099",
    "name": "Azotemia"
},
{
    "id": "Disease::MESH:D001404",
    "name": "Babesiosis"
},
{
    "id": "Disease::MESH:D001416",
    "name": "Back Pain"
},
{
    "id": "Disease::MESH:D016470",
    "name": "Bacteremia"
},
{
    "id": "Disease::MESH:D001424",
    "name": "Bacterial Infections"
},
{
    "id": "Disease::MESH:D001437",
    "name": "Bacteriuria"
},
{
    "id": "Disease::MESH:D001442",
    "name": "Bacteroides Infections"
},
{
    "id": "Disease::MESH:D001446",
    "name": "Balanitis"
},
{
    "id": "Disease::MESH:D052798",
    "name": "Balanitis Xerotica Obliterans"
},
{
    "id": "Disease::MESH:D001447",
    "name": "Balantidiasis"
},
{
    "id": "Disease::MESH:D020788",
    "name": "Bardet-Biedl Syndrome"
},
{
    "id": "Disease::MESH:D001471",
    "name": "Barrett Esophagus"
},
{
    "id": "Disease::MESH:D056889",
    "name": "Barth Syndrome"
},
{
    "id": "Disease::MESH:D001474",
    "name": "Bartonella Infections"
},
{
    "id": "Disease::MESH:D001477",
    "name": "Bartter Syndrome"
},
{
    "id": "Disease::MESH:D001478",
    "name": "Basal Cell Nevus Syndrome"
},
{
    "id": "Disease::MESH:D020144",
    "name": "Basal Ganglia Cerebrovascular Disease"
},
{
    "id": "Disease::MESH:D001480",
    "name": "Basal Ganglia Diseases"
},
{
    "id": "Disease::MESH:D001506",
    "name": "Beckwith-Wiedemann Syndrome"
},
{
    "id": "Disease::MESH:D001528",
    "name": "Behcet Syndrome"
},
{
    "id": "Disease::MESH:D020330",
    "name": "Bell Palsy"
},
{
    "id": "Disease::MESH:D065635",
    "name": "Benign Paroxysmal Positional Vertigo"
},
{
    "id": "Disease::MESH:D001602",
    "name": "Beriberi"
},
{
    "id": "Disease::MESH:D001606",
    "name": "Bernard-Soulier Syndrome"
},
{
    "id": "Disease::MESH:D001607",
    "name": "Berylliosis"
},
{
    "id": "Disease::MESH:D001630",
    "name": "Bezoars"
},
{
    "id": "Disease::MESH:D001649",
    "name": "Bile Duct Diseases"
},
{
    "id": "Disease::MESH:D001650",
    "name": "Bile Duct Neoplasms"
},
{
    "id": "Disease::MESH:D001655",
    "name": "Bile Reflux"
},
{
    "id": "Disease::MESH:D001656",
    "name": "Biliary Atresia"
},
{
    "id": "Disease::MESH:D001660",
    "name": "Biliary Tract Diseases"
},
{
    "id": "Disease::MESH:D001661",
    "name": "Biliary Tract Neoplasms"
},
{
    "id": "Disease::MESH:D063425",
    "name": "Binge Drinking"
},
{
    "id": "Disease::MESH:D056912",
    "name": "Binge-Eating Disorder"
},
{
    "id": "Disease::MESH:D028921",
    "name": "Biotinidase Deficiency"
},
{
    "id": "Disease::MESH:D001714",
    "name": "Bipolar Disorder"
},
{
    "id": "Disease::MESH:D058249",
    "name": "Birt-Hogg-Dube Syndrome"
},
{
    "id": "Disease::MESH:D059266",
    "name": "Bisphosphonate-Associated Osteonecrosis of the Jaw"
},
{
    "id": "Disease::MESH:D001734",
    "name": "Bites"
},
{
    "id": "Disease::MESH:D001733",
    "name": "Bites and Stings"
},
{
    "id": "Disease::MESH:D001752",
    "name": "Blast Crisis"
},
{
    "id": "Disease::MESH:D001753",
    "name": "Blast Injuries"
},
{
    "id": "Disease::MESH:D016776",
    "name": "Blastocystis Infections"
},
{
    "id": "Disease::MESH:D001759",
    "name": "Blastomycosis"
},
{
    "id": "Disease::MESH:D001762",
    "name": "Blepharitis"
},
{
    "id": "Disease::MESH:D016569",
    "name": "Blepharophimosis"
},
{
    "id": "Disease::MESH:D001763",
    "name": "Blepharoptosis"
},
{
    "id": "Disease::MESH:D001764",
    "name": "Blepharospasm"
},
{
    "id": "Disease::MESH:D001765",
    "name": "Blind Loop Syndrome"
},
{
    "id": "Disease::MESH:D019575",
    "name": "Blindness"
},
{
    "id": "Disease::MESH:D001766",
    "name": "Blindness"
},
{
    "id": "Disease::MESH:D001768",
    "name": "Blister"
},
{
    "id": "Disease::MESH:D025861",
    "name": "Blood Coagulation Disorders"
},
{
    "id": "Disease::MESH:D001778",
    "name": "Blood Coagulation Disorders"
},
{
    "id": "Disease::MESH:D016063",
    "name": "Blood Loss"
},
{
    "id": "Disease::MESH:D001791",
    "name": "Blood Platelet Disorders"
},
{
    "id": "Disease::MESH:D001796",
    "name": "Blood Protein Disorders"
},
{
    "id": "Disease::MESH:D001816",
    "name": "Bloom Syndrome"
},
{
    "id": "Disease::MESH:D057215",
    "name": "Body Dysmorphic Disorders"
},
{
    "id": "Disease::MESH:D017824",
    "name": "Bone Cysts"
},
{
    "id": "Disease::MESH:D001845",
    "name": "Bone Cysts"
},
{
    "id": "Disease::MESH:D001847",
    "name": "Bone Diseases"
},
{
    "id": "Disease::MESH:D001849",
    "name": "Bone Diseases"
},
{
    "id": "Disease::MESH:D001848",
    "name": "Bone Diseases"
},
{
    "id": "Disease::MESH:D001851",
    "name": "Bone Diseases"
},
{
    "id": "Disease::MESH:D001855",
    "name": "Bone Marrow Diseases"
},
{
    "id": "Disease::MESH:D001859",
    "name": "Bone Neoplasms"
},
{
    "id": "Disease::MESH:D001862",
    "name": "Bone Resorption"
},
{
    "id": "Disease::MESH:D001882",
    "name": "Border Disease"
},
{
    "id": "Disease::MESH:D001883",
    "name": "Borderline Personality Disorder"
},
{
    "id": "Disease::MESH:D001890",
    "name": "Borna Disease"
},
{
    "id": "Disease::MESH:D001899",
    "name": "Borrelia Infections"
},
{
    "id": "Disease::MESH:D001906",
    "name": "Botulism"
},
{
    "id": "Disease::MESH:D048090",
    "name": "Bovine Respiratory Disease Complex"
},
{
    "id": "Disease::MESH:D001913",
    "name": "Bowen's Disease"
},
{
    "id": "Disease::MESH:D020968",
    "name": "Brachial Plexus Neuritis"
},
{
    "id": "Disease::MESH:D020516",
    "name": "Brachial Plexus Neuropathies"
},
{
    "id": "Disease::MESH:D059327",
    "name": "Brachydactyly"
},
{
    "id": "Disease::MESH:D001919",
    "name": "Bradycardia"
},
{
    "id": "Disease::MESH:D001922",
    "name": "Brain Abscess"
},
{
    "id": "Disease::MESH:D001924",
    "name": "Brain Concussion"
},
{
    "id": "Disease::MESH:D001926",
    "name": "Brain Death"
},
{
    "id": "Disease::MESH:D020739",
    "name": "Brain Diseases"
},
{
    "id": "Disease::MESH:D001927",
    "name": "Brain Diseases"
},
{
    "id": "Disease::MESH:D001928",
    "name": "Brain Diseases"
},
{
    "id": "Disease::MESH:D001929",
    "name": "Brain Edema"
},
{
    "id": "Disease::MESH:D020520",
    "name": "Brain Infarction"
},
{
    "id": "Disease::MESH:D001930",
    "name": "Brain Injuries"
},
{
    "id": "Disease::MESH:D020208",
    "name": "Brain Injury"
},
{
    "id": "Disease::MESH:D002545",
    "name": "Brain Ischemia"
},
{
    "id": "Disease::MESH:D001932",
    "name": "Brain Neoplasms"
},
{
    "id": "Disease::MESH:D020295",
    "name": "Brain Stem Neoplasms"
},
{
    "id": "Disease::MESH:D019280",
    "name": "Branchio-Oto-Renal Syndrome"
},
{
    "id": "Disease::MESH:D001935",
    "name": "Branchioma"
},
{
    "id": "Disease::MESH:D059390",
    "name": "Breakthrough Pain"
},
{
    "id": "Disease::MESH:D001941",
    "name": "Breast Diseases"
},
{
    "id": "Disease::MESH:D001943",
    "name": "Breast Neoplasms"
},
{
    "id": "Disease::MESH:D018567",
    "name": "Breast Neoplasms"
},
{
    "id": "Disease::MESH:D001946",
    "name": "Breech Presentation"
},
{
    "id": "Disease::MESH:D057768",
    "name": "Brief"
},
{
    "id": "Disease::MESH:D001982",
    "name": "Bronchial Diseases"
},
{
    "id": "Disease::MESH:D001983",
    "name": "Bronchial Fistula"
},
{
    "id": "Disease::MESH:D016535",
    "name": "Bronchial Hyperreactivity"
},
{
    "id": "Disease::MESH:D001986",
    "name": "Bronchial Spasm"
},
{
    "id": "Disease::MESH:D001987",
    "name": "Bronchiectasis"
},
{
    "id": "Disease::MESH:D001988",
    "name": "Bronchiolitis"
},
{
    "id": "Disease::MESH:D001990",
    "name": "Bronchiolitis"
},
{
    "id": "Disease::MESH:D001989",
    "name": "Bronchiolitis Obliterans"
},
{
    "id": "Disease::MESH:D029481",
    "name": "Bronchitis"
},
{
    "id": "Disease::MESH:D001991",
    "name": "Bronchitis"
},
{
    "id": "Disease::MESH:D016084",
    "name": "Bronchoconstriction"
},
{
    "id": "Disease::MESH:D001996",
    "name": "Bronchopneumonia"
},
{
    "id": "Disease::MESH:D001997",
    "name": "Bronchopulmonary Dysplasia"
},
{
    "id": "Disease::MESH:D001998",
    "name": "Bronchopulmonary Sequestration"
},
{
    "id": "Disease::MESH:D002006",
    "name": "Brucellosis"
},
{
    "id": "Disease::MESH:D053840",
    "name": "Brugada Syndrome"
},
{
    "id": "Disease::MESH:D002012",
    "name": "Bruxism"
},
{
    "id": "Disease::MESH:D006502",
    "name": "Budd-Chiari Syndrome"
},
{
    "id": "Disease::MESH:D055534",
    "name": "Bulbo-Spinal Atrophy"
},
{
    "id": "Disease::MESH:D002032",
    "name": "Bulimia"
},
{
    "id": "Disease::MESH:D052018",
    "name": "Bulimia Nervosa"
},
{
    "id": "Disease::MESH:D002037",
    "name": "Bundle-Branch Block"
},
{
    "id": "Disease::MESH:D002051",
    "name": "Burkitt Lymphoma"
},
{
    "id": "Disease::MESH:D002054",
    "name": "Burning Mouth Syndrome"
},
{
    "id": "Disease::MESH:D002057",
    "name": "Burns"
},
{
    "id": "Disease::MESH:D002056",
    "name": "Burns"
},
{
    "id": "Disease::MESH:D002062",
    "name": "Bursitis"
},
{
    "id": "Disease::MESH:D054312",
    "name": "Buruli Ulcer"
},
{
    "id": "Disease::MESH:D062688",
    "name": "Buschke-Lowenstein Tumor"
},
{
    "id": "Disease::MESH:D002095",
    "name": "Byssinosis"
},
{
    "id": "Disease::MESH:D046589",
    "name": "CADASIL"
},
{
    "id": "Disease::MESH:D058747",
    "name": "CHARGE Syndrome"
},
{
    "id": "Disease::MESH:D002100",
    "name": "Cachexia"
},
{
    "id": "Disease::MESH:D002102",
    "name": "Cadaver"
},
{
    "id": "Disease::MESH:D002114",
    "name": "Calcinosis"
},
{
    "id": "Disease::MESH:D002115",
    "name": "Calciphylaxis"
},
{
    "id": "Disease::MESH:D002128",
    "name": "Calcium Metabolism Disorders"
},
{
    "id": "Disease::MESH:D002137",
    "name": "Calculi"
},
{
    "id": "Disease::MESH:D002145",
    "name": "Callosities"
},
{
    "id": "Disease::MESH:D002169",
    "name": "Campylobacter Infections"
},
{
    "id": "Disease::MESH:D002168",
    "name": "Campylobacter fetus"
},
{
    "id": "Disease::MESH:D003966",
    "name": "Camurati-Engelmann Syndrome"
},
{
    "id": "Disease::MESH:D017825",
    "name": "Canavan Disease"
},
{
    "id": "Disease::MESH:D058387",
    "name": "Candidemia"
},
{
    "id": "Disease::MESH:D058365",
    "name": "Candidiasis"
},
{
    "id": "Disease::MESH:D002177",
    "name": "Candidiasis"
},
{
    "id": "Disease::MESH:D002181",
    "name": "Candidiasis"
},
{
    "id": "Disease::MESH:D002180",
    "name": "Candidiasis"
},
{
    "id": "Disease::MESH:D002178",
    "name": "Candidiasis"
},
{
    "id": "Disease::MESH:D002179",
    "name": "Candidiasis"
},
{
    "id": "Disease::MESH:D019559",
    "name": "Capillary Leak Syndrome"
},
{
    "id": "Disease::MESH:D058442",
    "name": "Capsule Opacification"
},
{
    "id": "Disease::MESH:D020165",
    "name": "Carbamoyl-Phosphate Synthase I Deficiency Disease"
},
{
    "id": "Disease::MESH:D002249",
    "name": "Carbon Monoxide Poisoning"
},
{
    "id": "Disease::MESH:D002252",
    "name": "Carbon Tetrachloride Poisoning"
},
{
    "id": "Disease::MESH:D063646",
    "name": "Carcinogenesis"
},
{
    "id": "Disease::MESH:D002276",
    "name": "Carcinoid Tumor"
},
{
    "id": "Disease::MESH:D002283",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018287",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018269",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018273",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D044584",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002280",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002277",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018267",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018288",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018270",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018277",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002291",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002292",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002289",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018278",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002295",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018196",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002285",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018286",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D003528",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D021441",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018275",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D018279",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D015266",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002294",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002286",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D006528",
    "name": "Carcinoma"
},
{
    "id": "Disease::MESH:D002279",
    "name": "Carcinoma 256"
},
{
    "id": "Disease::MESH:D002278",
    "name": "Carcinoma in Situ"
},
{
    "id": "Disease::MESH:D002296",
    "name": "Carcinosarcoma"
},
{
    "id": "Disease::MESH:D005117",
    "name": "Cardiac Complexes"
},
{
    "id": "Disease::MESH:D016534",
    "name": "Cardiac Output"
},
{
    "id": "Disease::MESH:D002303",
    "name": "Cardiac Output"
},
{
    "id": "Disease::MESH:D002305",
    "name": "Cardiac Tamponade"
},
{
    "id": "Disease::MESH:D059347",
    "name": "Cardio-Renal Syndrome"
},
{
    "id": "Disease::MESH:D006332",
    "name": "Cardiomegaly"
},
{
    "id": "Disease::MESH:D009202",
    "name": "Cardiomyopathies"
},
{
    "id": "Disease::MESH:D002310",
    "name": "Cardiomyopathy"
},
{
    "id": "Disease::MESH:D002311",
    "name": "Cardiomyopathy"
},
{
    "id": "Disease::MESH:D002313",
    "name": "Cardiomyopathy"
},
{
    "id": "Disease::MESH:D002312",
    "name": "Cardiomyopathy"
},
{
    "id": "Disease::MESH:D024741",
    "name": "Cardiomyopathy"
},
{
    "id": "Disease::MESH:D066126",
    "name": "Cardiotoxicity"
},
{
    "id": "Disease::MESH:D018376",
    "name": "Cardiovascular Abnormalities"
},
{
    "id": "Disease::MESH:D002318",
    "name": "Cardiovascular Diseases"
},
{
    "id": "Disease::MESH:D064646",
    "name": "Cardiovascular Nursing"
},
{
    "id": "Disease::MESH:D056733",
    "name": "Carney Complex"
},
{
    "id": "Disease::MESH:D002340",
    "name": "Carotid Artery Diseases"
},
{
    "id": "Disease::MESH:D020212",
    "name": "Carotid Artery Injuries"
},
{
    "id": "Disease::MESH:D002341",
    "name": "Carotid Artery Thrombosis"
},
{
    "id": "Disease::MESH:D016893",
    "name": "Carotid Stenosis"
},
{
    "id": "Disease::MESH:D002349",
    "name": "Carpal Tunnel Syndrome"
},
{
    "id": "Disease::MESH:D002357",
    "name": "Cartilage Diseases"
},
{
    "id": "Disease::MESH:D005871",
    "name": "Castleman Disease"
},
{
    "id": "Disease::MESH:D002372",
    "name": "Cat-Scratch Disease"
},
{
    "id": "Disease::MESH:D002375",
    "name": "Catalepsy"
},
{
    "id": "Disease::MESH:D002385",
    "name": "Cataplexy"
},
{
    "id": "Disease::MESH:D002386",
    "name": "Cataract"
},
{
    "id": "Disease::MESH:D002388",
    "name": "Catastrophic Illness"
},
{
    "id": "Disease::MESH:D002389",
    "name": "Catatonia"
},
{
    "id": "Disease::MESH:D055499",
    "name": "Catheter-Related Infections"
},
{
    "id": "Disease::MESH:D002418",
    "name": "Cattle Diseases"
},
{
    "id": "Disease::MESH:D002422",
    "name": "Causalgia"
},
{
    "id": "Disease::MESH:D002429",
    "name": "Cecal Diseases"
},
{
    "id": "Disease::MESH:D002446",
    "name": "Celiac Disease"
},
{
    "id": "Disease::MESH:D002471",
    "name": "Cell Transformation"
},
{
    "id": "Disease::MESH:D002481",
    "name": "Cellulitis"
},
{
    "id": "Disease::MESH:D020863",
    "name": "Central Nervous System Cysts"
},
{
    "id": "Disease::MESH:D002493",
    "name": "Central Nervous System Diseases"
},
{
    "id": "Disease::MESH:D002494",
    "name": "Central Nervous System Infections"
},
{
    "id": "Disease::MESH:D016543",
    "name": "Central Nervous System Neoplasms"
},
{
    "id": "Disease::MESH:D020808",
    "name": "Central Nervous System Protozoal Infections"
},
{
    "id": "Disease::MESH:D020785",
    "name": "Central Nervous System Vascular Malformations"
},
{
    "id": "Disease::MESH:D020805",
    "name": "Central Nervous System Viral Diseases"
},
{
    "id": "Disease::MESH:D002524",
    "name": "Cerebellar Ataxia"
},
{
    "id": "Disease::MESH:D002526",
    "name": "Cerebellar Diseases"
},
{
    "id": "Disease::MESH:D028243",
    "name": "Cerebral Amyloid Angiopathy"
},
{
    "id": "Disease::MESH:D016657",
    "name": "Cerebral Amyloid Angiopathy"
},
{
    "id": "Disease::MESH:D002539",
    "name": "Cerebral Arterial Diseases"
},
{
    "id": "Disease::MESH:D002543",
    "name": "Cerebral Hemorrhage"
},
{
    "id": "Disease::MESH:D002544",
    "name": "Cerebral Infarction"
},
{
    "id": "Disease::MESH:D002547",
    "name": "Cerebral Palsy"
},
{
    "id": "Disease::MESH:D059345",
    "name": "Cerebral Small Vessel Diseases"
},
{
    "id": "Disease::MESH:D058565",
    "name": "Cerebral Ventriculitis"
},
{
    "id": "Disease::MESH:D065634",
    "name": "Cerebrospinal Fluid Leak"
},
{
    "id": "Disease::MESH:D002558",
    "name": "Cerebrospinal Fluid Otorrhea"
},
{
    "id": "Disease::MESH:D002559",
    "name": "Cerebrospinal Fluid Rhinorrhea"
},
{
    "id": "Disease::MESH:D002561",
    "name": "Cerebrovascular Disorders"
},
{
    "id": "Disease::MESH:D020214",
    "name": "Cerebrovascular Trauma"
},
{
    "id": "Disease::MESH:D002571",
    "name": "Cerumen"
},
{
    "id": "Disease::MESH:D018290",
    "name": "Cervical Intraepithelial Neoplasia"
},
{
    "id": "Disease::MESH:D002590",
    "name": "Cestode Infections"
},
{
    "id": "Disease::MESH:D014355",
    "name": "Chagas Disease"
},
{
    "id": "Disease::MESH:D017043",
    "name": "Chalazion"
},
{
    "id": "Disease::MESH:D002601",
    "name": "Chancre"
},
{
    "id": "Disease::MESH:D002602",
    "name": "Chancroid"
},
{
    "id": "Disease::MESH:D053447",
    "name": "Channelopathies"
},
{
    "id": "Disease::MESH:D002607",
    "name": "Charcot-Marie-Tooth Disease"
},
{
    "id": "Disease::MESH:D002609",
    "name": "Chediak-Higashi Syndrome"
},
{
    "id": "Disease::MESH:D002613",
    "name": "Cheilitis"
},
{
    "id": "Disease::MESH:D056486",
    "name": "Chemical and Drug Induced Liver Injury"
},
{
    "id": "Disease::MESH:D056487",
    "name": "Chemical and Drug Induced Liver Injury"
},
{
    "id": "Disease::MESH:D064419",
    "name": "Chemically-Induced Disorders"
},
{
    "id": "Disease::MESH:D064146",
    "name": "Chemotherapy-Induced Febrile Neutropenia"
},
{
    "id": "Disease::MESH:D002636",
    "name": "Cherubism"
},
{
    "id": "Disease::MESH:D002637",
    "name": "Chest Pain"
},
{
    "id": "Disease::MESH:D002644",
    "name": "Chickenpox"
},
{
    "id": "Disease::MESH:D065632",
    "name": "Chikungunya Fever"
},
{
    "id": "Disease::MESH:D002647",
    "name": "Chilblains"
},
{
    "id": "Disease::MESH:D002659",
    "name": "Child Development Disorders"
},
{
    "id": "Disease::MESH:D002690",
    "name": "Chlamydia Infections"
},
{
    "id": "Disease::MESH:D002692",
    "name": "Chlamydia trachomatis"
},
{
    "id": "Disease::MESH:D061387",
    "name": "Chlamydial Pneumonia"
},
{
    "id": "Disease::MESH:D002707",
    "name": "Chlordiazepoxide"
},
{
    "id": "Disease::MESH:D002754",
    "name": "Choanal Atresia"
},
{
    "id": "Disease::MESH:D018281",
    "name": "Cholangiocarcinoma"
},
{
    "id": "Disease::MESH:D002761",
    "name": "Cholangitis"
},
{
    "id": "Disease::MESH:D015209",
    "name": "Cholangitis"
},
{
    "id": "Disease::MESH:D041881",
    "name": "Cholecystitis"
},
{
    "id": "Disease::MESH:D002764",
    "name": "Cholecystitis"
},
{
    "id": "Disease::MESH:D041761",
    "name": "Cholecystolithiasis"
},
{
    "id": "Disease::MESH:D015529",
    "name": "Choledochal Cyst"
},
{
    "id": "Disease::MESH:D042883",
    "name": "Choledocholithiasis"
},
{
    "id": "Disease::MESH:D002769",
    "name": "Cholelithiasis"
},
{
    "id": "Disease::MESH:D002771",
    "name": "Cholera"
},
{
    "id": "Disease::MESH:D001651",
    "name": "Cholestasis"
},
{
    "id": "Disease::MESH:D002780",
    "name": "Cholestasis"
},
{
    "id": "Disease::MESH:D002779",
    "name": "Cholestasis"
},
{
    "id": "Disease::MESH:D018424",
    "name": "Cholesteatoma"
},
{
    "id": "Disease::MESH:D002781",
    "name": "Cholesteatoma"
},
{
    "id": "Disease::MESH:D015217",
    "name": "Cholesterol Ester Storage Disease"
},
{
    "id": "Disease::MESH:D002796",
    "name": "Choline Deficiency"
},
{
    "id": "Disease::MESH:D002804",
    "name": "Chondroblastoma"
},
{
    "id": "Disease::MESH:D002805",
    "name": "Chondrocalcinosis"
},
{
    "id": "Disease::MESH:D002806",
    "name": "Chondrodysplasia Punctata"
},
{
    "id": "Disease::MESH:D018902",
    "name": "Chondrodysplasia Punctata"
},
{
    "id": "Disease::MESH:D002812",
    "name": "Chondroma"
},
{
    "id": "Disease::MESH:D002813",
    "name": "Chondrosarcoma"
},
{
    "id": "Disease::MESH:D002817",
    "name": "Chordoma"
},
{
    "id": "Disease::MESH:D002819",
    "name": "Chorea"
},
{
    "id": "Disease::MESH:D002821",
    "name": "Chorioamnionitis"
},
{
    "id": "Disease::MESH:D031954",
    "name": "Choriocarcinoma"
},
{
    "id": "Disease::MESH:D002822",
    "name": "Choriocarcinoma"
},
{
    "id": "Disease::MESH:D002825",
    "name": "Chorioretinitis"
},
{
    "id": "Disease::MESH:D015862",
    "name": "Choroid Diseases"
},
{
    "id": "Disease::MESH:D016545",
    "name": "Choroid Plexus Neoplasms"
},
{
    "id": "Disease::MESH:D020256",
    "name": "Choroidal Neovascularization"
},
{
    "id": "Disease::MESH:D015794",
    "name": "Choroideremia"
},
{
    "id": "Disease::MESH:D002833",
    "name": "Choroiditis"
},
{
    "id": "Disease::MESH:D002862",
    "name": "Chromoblastomycosis"
},
{
    "id": "Disease::MESH:D043171",
    "name": "Chromosomal Instability"
},
{
    "id": "Disease::MESH:D002869",
    "name": "Chromosome Aberrations"
},
{
    "id": "Disease::MESH:D019457",
    "name": "Chromosome Breakage"
},
{
    "id": "Disease::MESH:D025063",
    "name": "Chromosome Disorders"
},
{
    "id": "Disease::MESH:D058674",
    "name": "Chromosome Duplication"
},
{
    "id": "Disease::MESH:D002908",
    "name": "Chronic Disease"
},
{
    "id": "Disease::MESH:D012080",
    "name": "Chronic Kidney Disease-Mineral and Bone Disorder"
},
{
    "id": "Disease::MESH:D059350",
    "name": "Chronic Pain"
},
{
    "id": "Disease::MESH:D055113",
    "name": "Chronic Periodontitis"
},
{
    "id": "Disease::MESH:D021081",
    "name": "Chronobiology Disorders"
},
{
    "id": "Disease::MESH:D015267",
    "name": "Churg-Strauss Syndrome"
},
{
    "id": "Disease::MESH:D002916",
    "name": "Chylothorax"
},
{
    "id": "Disease::MESH:D002915",
    "name": "Chylous Ascites"
},
{
    "id": "Disease::MESH:D002921",
    "name": "Cicatrix"
},
{
    "id": "Disease::MESH:D017439",
    "name": "Cicatrix"
},
{
    "id": "Disease::MESH:D036841",
    "name": "Ciguatera Poisoning"
},
{
    "id": "Disease::MESH:D020159",
    "name": "Citrullinemia"
},
{
    "id": "Disease::MESH:D054221",
    "name": "Classical Lissencephalies and Subcortical Band Heterotopias"
},
{
    "id": "Disease::MESH:D006691",
    "name": "Classical Swine Fever"
},
{
    "id": "Disease::MESH:D002971",
    "name": "Cleft Lip"
},
{
    "id": "Disease::MESH:D002972",
    "name": "Cleft Palate"
},
{
    "id": "Disease::MESH:D003003",
    "name": "Clonorchiasis"
},
{
    "id": "Disease::MESH:D003015",
    "name": "Clostridium Infections"
},
{
    "id": "Disease::MESH:D003027",
    "name": "Cluster Headache"
},
{
    "id": "Disease::MESH:D019970",
    "name": "Cocaine-Related Disorders"
},
{
    "id": "Disease::MESH:D003047",
    "name": "Coccidioidomycosis"
},
{
    "id": "Disease::MESH:D003048",
    "name": "Coccidiosis"
},
{
    "id": "Disease::MESH:D015834",
    "name": "Cochlear Diseases"
},
{
    "id": "Disease::MESH:D003057",
    "name": "Cockayne Syndrome"
},
{
    "id": "Disease::MESH:D038921",
    "name": "Coffin-Lowry Syndrome"
},
{
    "id": "Disease::MESH:D055952",
    "name": "Cogan Syndrome"
},
{
    "id": "Disease::MESH:D003072",
    "name": "Cognition Disorders"
},
{
    "id": "Disease::MESH:D060825",
    "name": "Cognitive Dysfunction"
},
{
    "id": "Disease::MESH:D060085",
    "name": "Coinfection"
},
{
    "id": "Disease::MESH:D003085",
    "name": "Colic"
},
{
    "id": "Disease::MESH:D003093",
    "name": "Colitis"
},
{
    "id": "Disease::MESH:D046728",
    "name": "Colitis"
},
{
    "id": "Disease::MESH:D003092",
    "name": "Colitis"
},
{
    "id": "Disease::MESH:D046729",
    "name": "Colitis"
},
{
    "id": "Disease::MESH:D046730",
    "name": "Colitis"
},
{
    "id": "Disease::MESH:D003095",
    "name": "Collagen Diseases"
},
{
    "id": "Disease::MESH:D003103",
    "name": "Coloboma"
},
{
    "id": "Disease::MESH:D044682",
    "name": "Colon"
},
{
    "id": "Disease::MESH:D003108",
    "name": "Colonic Diseases"
},
{
    "id": "Disease::MESH:D003110",
    "name": "Colonic Neoplasms"
},
{
    "id": "Disease::MESH:D003111",
    "name": "Colonic Polyps"
},
{
    "id": "Disease::MESH:D003112",
    "name": "Colonic Pseudo-Obstruction"
},
{
    "id": "Disease::MESH:D003117",
    "name": "Color Vision Defects"
},
{
    "id": "Disease::MESH:D015179",
    "name": "Colorectal Neoplasms"
},
{
    "id": "Disease::MESH:D003123",
    "name": "Colorectal Neoplasms"
},
{
    "id": "Disease::MESH:D003128",
    "name": "Coma"
},
{
    "id": "Disease::MESH:D003139",
    "name": "Common Cold"
},
{
    "id": "Disease::MESH:D017074",
    "name": "Common Variable Immunodeficiency"
},
{
    "id": "Disease::MESH:D003141",
    "name": "Communicable Diseases"
},
{
    "id": "Disease::MESH:D003147",
    "name": "Communication Disorders"
},
{
    "id": "Disease::MESH:D017714",
    "name": "Community-Acquired Infections"
},
{
    "id": "Disease::MESH:D003161",
    "name": "Compartment Syndromes"
},
{
    "id": "Disease::MESH:D019955",
    "name": "Conduct Disorder"
},
{
    "id": "Disease::MESH:D003218",
    "name": "Condylomata Acuminata"
},
{
    "id": "Disease::MESH:D003221",
    "name": "Confusion"
},
{
    "id": "Disease::MESH:D009358",
    "name": "Congenital"
},
{
    "id": "Disease::MESH:D000013",
    "name": "Congenital Abnormalities"
},
{
    "id": "Disease::MESH:D018981",
    "name": "Congenital Disorders of Glycosylation"
},
{
    "id": "Disease::MESH:D044903",
    "name": "Congenital Hyperinsulinism"
},
{
    "id": "Disease::MESH:D003409",
    "name": "Congenital Hypothyroidism"
},
{
    "id": "Disease::MESH:D065817",
    "name": "Congenital Microtia"
},
{
    "id": "Disease::MESH:D003229",
    "name": "Conjunctival Diseases"
},
{
    "id": "Disease::MESH:D003234",
    "name": "Conjunctivitis"
},
{
    "id": "Disease::MESH:D003235",
    "name": "Conjunctivitis"
},
{
    "id": "Disease::MESH:D003233",
    "name": "Conjunctivitis"
},
{
    "id": "Disease::MESH:D003231",
    "name": "Conjunctivitis"
},
{
    "id": "Disease::MESH:D003240",
    "name": "Connective Tissue Diseases"
},
{
    "id": "Disease::MESH:D003244",
    "name": "Consciousness Disorders"
},
{
    "id": "Disease::MESH:D003248",
    "name": "Constipation"
},
{
    "id": "Disease::MESH:D003251",
    "name": "Constriction"
},
{
    "id": "Disease::MESH:D003286",
    "name": "Contracture"
},
{
    "id": "Disease::MESH:D003288",
    "name": "Contusions"
},
{
    "id": "Disease::MESH:D046349",
    "name": "Coproporphyria"
},
{
    "id": "Disease::MESH:D003316",
    "name": "Corneal Diseases"
},
{
    "id": "Disease::MESH:D003317",
    "name": "Corneal Dystrophies"
},
{
    "id": "Disease::MESH:D053559",
    "name": "Corneal Dystrophy"
},
{
    "id": "Disease::MESH:D015715",
    "name": "Corneal Edema"
},
{
    "id": "Disease::MESH:D065306",
    "name": "Corneal Injuries"
},
{
    "id": "Disease::MESH:D016510",
    "name": "Corneal Neovascularization"
},
{
    "id": "Disease::MESH:D003320",
    "name": "Corneal Ulcer"
},
{
    "id": "Disease::MESH:D003323",
    "name": "Coronary Aneurysm"
},
{
    "id": "Disease::MESH:D003324",
    "name": "Coronary Artery Disease"
},
{
    "id": "Disease::MESH:D003327",
    "name": "Coronary Disease"
},
{
    "id": "Disease::MESH:D054059",
    "name": "Coronary Occlusion"
},
{
    "id": "Disease::MESH:D023903",
    "name": "Coronary Restenosis"
},
{
    "id": "Disease::MESH:D023921",
    "name": "Coronary Stenosis"
},
{
    "id": "Disease::MESH:D003328",
    "name": "Coronary Thrombosis"
},
{
    "id": "Disease::MESH:D003329",
    "name": "Coronary Vasospasm"
},
{
    "id": "Disease::MESH:D003333",
    "name": "Coronaviridae Infections"
},
{
    "id": "Disease::MESH:D028941",
    "name": "Coronavirus 229E"
},
{
    "id": "Disease::MESH:D018352",
    "name": "Coronavirus Infections"
},
{
    "id": "Disease::MESH:D058957",
    "name": "Coronavirus NL63"
},
{
    "id": "Disease::MESH:D056685",
    "name": "Costello Syndrome"
},
{
    "id": "Disease::MESH:D003371",
    "name": "Cough"
},
{
    "id": "Disease::MESH:D015605",
    "name": "Cowpox"
},
{
    "id": "Disease::MESH:D003389",
    "name": "Cranial Nerve Diseases"
},
{
    "id": "Disease::MESH:D020209",
    "name": "Cranial Nerve Injuries"
},
{
    "id": "Disease::MESH:D006259",
    "name": "Craniocerebral Trauma"
},
{
    "id": "Disease::MESH:D019465",
    "name": "Craniofacial Abnormalities"
},
{
    "id": "Disease::MESH:D003394",
    "name": "Craniofacial Dysostosis"
},
{
    "id": "Disease::MESH:D003397",
    "name": "Craniopharyngioma"
},
{
    "id": "Disease::MESH:D003398",
    "name": "Craniosynostoses"
},
{
    "id": "Disease::MESH:D007562",
    "name": "Creutzfeldt-Jakob Syndrome"
},
{
    "id": "Disease::MESH:D003414",
    "name": "Crigler-Najjar Syndrome"
},
{
    "id": "Disease::MESH:D016638",
    "name": "Critical Illness"
},
{
    "id": "Disease::MESH:D003424",
    "name": "Crohn Disease"
},
{
    "id": "Disease::MESH:D003428",
    "name": "Cross Infection"
},
{
    "id": "Disease::MESH:D003440",
    "name": "Croup"
},
{
    "id": "Disease::MESH:D003444",
    "name": "Crush Syndrome"
},
{
    "id": "Disease::MESH:D003448",
    "name": "Crying"
},
{
    "id": "Disease::MESH:D003449",
    "name": "Cryoglobulinemia"
},
{
    "id": "Disease::MESH:D056587",
    "name": "Cryopyrin-Associated Periodic Syndromes"
},
{
    "id": "Disease::MESH:D003453",
    "name": "Cryptococcosis"
},
{
    "id": "Disease::MESH:D018549",
    "name": "Cryptogenic Organizing Pneumonia"
},
{
    "id": "Disease::MESH:D003456",
    "name": "Cryptorchidism"
},
{
    "id": "Disease::MESH:D003457",
    "name": "Cryptosporidiosis"
},
{
    "id": "Disease::MESH:D012090",
    "name": "Cumulative Trauma Disorders"
},
{
    "id": "Disease::MESH:D003480",
    "name": "Cushing Syndrome"
},
{
    "id": "Disease::MESH:D003490",
    "name": "Cyanosis"
},
{
    "id": "Disease::MESH:D018284",
    "name": "Cystadenocarcinoma"
},
{
    "id": "Disease::MESH:D003536",
    "name": "Cystadenocarcinoma"
},
{
    "id": "Disease::MESH:D018282",
    "name": "Cystadenocarcinoma"
},
{
    "id": "Disease::MESH:D003537",
    "name": "Cystadenoma"
},
{
    "id": "Disease::MESH:D018292",
    "name": "Cystadenoma"
},
{
    "id": "Disease::MESH:D015615",
    "name": "Cystic Adenomatoid Malformation of Lung"
},
{
    "id": "Disease::MESH:D003550",
    "name": "Cystic Fibrosis"
},
{
    "id": "Disease::MESH:D003551",
    "name": "Cysticercosis"
},
{
    "id": "Disease::MESH:D003554",
    "name": "Cystinosis"
},
{
    "id": "Disease::MESH:D003555",
    "name": "Cystinuria"
},
{
    "id": "Disease::MESH:D018856",
    "name": "Cystitis"
},
{
    "id": "Disease::MESH:D003556",
    "name": "Cystitis"
},
{
    "id": "Disease::MESH:D030401",
    "name": "Cytochrome-c Oxidase Deficiency"
},
{
    "id": "Disease::MESH:D003586",
    "name": "Cytomegalovirus Infections"
},
{
    "id": "Disease::MESH:D017726",
    "name": "Cytomegalovirus Retinitis"
},
{
    "id": "Disease::MESH:D049914",
    "name": "DNA Repair-Deficiency Disorders"
},
{
    "id": "Disease::MESH:D004266",
    "name": "DNA Virus Infections"
},
{
    "id": "Disease::MESH:D003607",
    "name": "Dacryocystitis"
},
{
    "id": "Disease::MESH:D063807",
    "name": "Dandruff"
},
{
    "id": "Disease::MESH:D003616",
    "name": "Dandy-Walker Syndrome"
},
{
    "id": "Disease::MESH:D007644",
    "name": "Darier Disease"
},
{
    "id": "Disease::MESH:D003635",
    "name": "De Lange Syndrome"
},
{
    "id": "Disease::MESH:D053684",
    "name": "De Quervain Disease"
},
{
    "id": "Disease::MESH:D054062",
    "name": "Deaf-Blind Disorders"
},
{
    "id": "Disease::MESH:D003638",
    "name": "Deafness"
},
{
    "id": "Disease::MESH:D003643",
    "name": "Death"
},
{
    "id": "Disease::MESH:D016757",
    "name": "Death"
},
{
    "id": "Disease::MESH:D003645",
    "name": "Death"
},
{
    "id": "Disease::MESH:D003649",
    "name": "Decalcification"
},
{
    "id": "Disease::MESH:D003665",
    "name": "Decompression Sickness"
},
{
    "id": "Disease::MESH:D003677",
    "name": "Deficiency Diseases"
},
{
    "id": "Disease::MESH:D000069836",
    "name": "Degloving Injuries"
},
{
    "id": "Disease::MESH:D003680",
    "name": "Deglutition Disorders"
},
{
    "id": "Disease::MESH:D003681",
    "name": "Dehydration"
},
{
    "id": "Disease::MESH:D055191",
    "name": "Delayed Emergence from Anesthesia"
},
{
    "id": "Disease::MESH:D051799",
    "name": "Delayed Graft Function"
},
{
    "id": "Disease::MESH:D003693",
    "name": "Delirium"
},
{
    "id": "Disease::MESH:D063726",
    "name": "Delusional Parasitosis"
},
{
    "id": "Disease::MESH:D015140",
    "name": "Dementia"
},
{
    "id": "Disease::MESH:D015161",
    "name": "Dementia"
},
{
    "id": "Disease::MESH:D003704",
    "name": "Dementia"
},
{
    "id": "Disease::MESH:D020278",
    "name": "Demyelinating Autoimmune Diseases"
},
{
    "id": "Disease::MESH:D003711",
    "name": "Demyelinating Diseases"
},
{
    "id": "Disease::MESH:D054740",
    "name": "Dendritic Cell Sarcoma"
},
{
    "id": "Disease::MESH:D003715",
    "name": "Dengue"
},
{
    "id": "Disease::MESH:D003719",
    "name": "Dens in Dente"
},
{
    "id": "Disease::MESH:D057973",
    "name": "Dent Disease"
},
{
    "id": "Disease::MESH:D003728",
    "name": "Dental Calculus"
},
{
    "id": "Disease::MESH:D003731",
    "name": "Dental Caries"
},
{
    "id": "Disease::MESH:D003744",
    "name": "Dental Enamel Hypoplasia"
},
{
    "id": "Disease::MESH:D003763",
    "name": "Dental Leakage"
},
{
    "id": "Disease::MESH:D003773",
    "name": "Dental Plaque"
},
{
    "id": "Disease::MESH:D003784",
    "name": "Dental Pulp Calcification"
},
{
    "id": "Disease::MESH:D003788",
    "name": "Dental Pulp Diseases"
},
{
    "id": "Disease::MESH:D003805",
    "name": "Dentin Dysplasia"
},
{
    "id": "Disease::MESH:D003811",
    "name": "Dentinogenesis Imperfecta"
},
{
    "id": "Disease::MESH:D030321",
    "name": "Denys-Drash Syndrome"
},
{
    "id": "Disease::MESH:D003859",
    "name": "Dependent Personality Disorder"
},
{
    "id": "Disease::MESH:D003863",
    "name": "Depression"
},
{
    "id": "Disease::MESH:D019052",
    "name": "Depression"
},
{
    "id": "Disease::MESH:D003866",
    "name": "Depressive Disorder"
},
{
    "id": "Disease::MESH:D003865",
    "name": "Depressive Disorder"
},
{
    "id": "Disease::MESH:D061218",
    "name": "Depressive Disorder"
},
{
    "id": "Disease::MESH:D003869",
    "name": "Dermabrasion"
},
{
    "id": "Disease::MESH:D003873",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D012628",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D009783",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D003877",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D017449",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D019557",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D017454",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D003872",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D017453",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D017484",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D003876",
    "name": "Dermatitis"
},
{
    "id": "Disease::MESH:D003874",
    "name": "Dermatitis Herpetiformis"
},
{
    "id": "Disease::MESH:D018223",
    "name": "Dermatofibrosarcoma"
},
{
    "id": "Disease::MESH:D003881",
    "name": "Dermatomycoses"
},
{
    "id": "Disease::MESH:D003882",
    "name": "Dermatomyositis"
},
{
    "id": "Disease::MESH:D058405",
    "name": "Desmoplastic Small Round Cell Tumor"
},
{
    "id": "Disease::MESH:D002658",
    "name": "Developmental Disabilities"
},
{
    "id": "Disease::MESH:D004062",
    "name": "DiGeorge Syndrome"
},
{
    "id": "Disease::MESH:D016640",
    "name": "Diabetes"
},
{
    "id": "Disease::MESH:D048909",
    "name": "Diabetes Complications"
},
{
    "id": "Disease::MESH:D020790",
    "name": "Diabetes Insipidus"
},
{
    "id": "Disease::MESH:D018500",
    "name": "Diabetes Insipidus"
},
{
    "id": "Disease::MESH:D003919",
    "name": "Diabetes Insipidus"
},
{
    "id": "Disease::MESH:D003922",
    "name": "Diabetes Mellitus"
},
{
    "id": "Disease::MESH:D003924",
    "name": "Diabetes Mellitus"
},
{
    "id": "Disease::MESH:D003923",
    "name": "Diabetes Mellitus"
},
{
    "id": "Disease::MESH:D003920",
    "name": "Diabetes Mellitus"
},
{
    "id": "Disease::MESH:D003925",
    "name": "Diabetic Angiopathies"
},
{
    "id": "Disease::MESH:D058065",
    "name": "Diabetic Cardiomyopathies"
},
{
    "id": "Disease::MESH:D003926",
    "name": "Diabetic Coma"
},
{
    "id": "Disease::MESH:D017719",
    "name": "Diabetic Foot"
},
{
    "id": "Disease::MESH:D016883",
    "name": "Diabetic Ketoacidosis"
},
{
    "id": "Disease::MESH:D003928",
    "name": "Diabetic Nephropathies"
},
{
    "id": "Disease::MESH:D003929",
    "name": "Diabetic Neuropathies"
},
{
    "id": "Disease::MESH:D003930",
    "name": "Diabetic Retinopathy"
},
{
    "id": "Disease::MESH:D003963",
    "name": "Diaper Rash"
},
{
    "id": "Disease::MESH:D003967",
    "name": "Diarrhea"
},
{
    "id": "Disease::MESH:D004030",
    "name": "Dientamoebiasis"
},
{
    "id": "Disease::MESH:D002549",
    "name": "Diffuse Cerebral Sclerosis of Schilder"
},
{
    "id": "Disease::MESH:D055956",
    "name": "Diffuse Neurofibrillary Tangles with Calcification"
},
{
    "id": "Disease::MESH:D004063",
    "name": "Digestion"
},
{
    "id": "Disease::MESH:D004066",
    "name": "Digestive System Diseases"
},
{
    "id": "Disease::MESH:D058066",
    "name": "Digital Dermatitis"
},
{
    "id": "Disease::MESH:D054067",
    "name": "Dihydropyrimidine Dehydrogenase Deficiency"
},
{
    "id": "Disease::MESH:D004108",
    "name": "Dilatation"
},
{
    "id": "Disease::MESH:D004165",
    "name": "Diphtheria"
},
{
    "id": "Disease::MESH:D004172",
    "name": "Diplopia"
},
{
    "id": "Disease::MESH:D004184",
    "name": "Dirofilariasis"
},
{
    "id": "Disease::MESH:D015299",
    "name": "Discitis"
},
{
    "id": "Disease::MESH:D004194",
    "name": "Disease"
},
{
    "id": "Disease::MESH:D004195",
    "name": "Disease Models"
},
{
    "id": "Disease::MESH:D018450",
    "name": "Disease Progression"
},
{
    "id": "Disease::MESH:D060467",
    "name": "Disease Resistance"
},
{
    "id": "Disease::MESH:D004198",
    "name": "Disease Susceptibility"
},
{
    "id": "Disease::MESH:D018562",
    "name": "Disease Transmission"
},
{
    "id": "Disease::MESH:D058490",
    "name": "Disorder of Sex Development"
},
{
    "id": "Disease::MESH:D006970",
    "name": "Disorders of Excessive Somnolence"
},
{
    "id": "Disease::MESH:D012734",
    "name": "Disorders of Sex Development"
},
{
    "id": "Disease::MESH:D007174",
    "name": "Disruptive"
},
{
    "id": "Disease::MESH:D004211",
    "name": "Disseminated Intravascular Coagulation"
},
{
    "id": "Disease::MESH:D004213",
    "name": "Dissociative Disorders"
},
{
    "id": "Disease::MESH:D009105",
    "name": "Dissociative Identity Disorder"
},
{
    "id": "Disease::MESH:D049310",
    "name": "Distal Myopathies"
},
{
    "id": "Disease::MESH:D053207",
    "name": "Diurnal Enuresis"
},
{
    "id": "Disease::MESH:D004238",
    "name": "Diverticulitis"
},
{
    "id": "Disease::MESH:D004240",
    "name": "Diverticulum"
},
{
    "id": "Disease::MESH:D004244",
    "name": "Dizziness"
},
{
    "id": "Disease::MESH:D004283",
    "name": "Dog Diseases"
},
{
    "id": "Disease::MESH:D056731",
    "name": "Donohue Syndrome"
},
{
    "id": "Disease::MESH:D004314",
    "name": "Down Syndrome"
},
{
    "id": "Disease::MESH:D004320",
    "name": "Dracunculiasis"
},
{
    "id": "Disease::MESH:D003875",
    "name": "Drug Eruptions"
},
{
    "id": "Disease::MESH:D004342",
    "name": "Drug Hypersensitivity"
},
{
    "id": "Disease::MESH:D063926",
    "name": "Drug Hypersensitivity Syndrome"
},
{
    "id": "Disease::MESH:D062787",
    "name": "Drug Overdose"
},
{
    "id": "Disease::MESH:D064420",
    "name": "Drug-Related Side Effects and Adverse Reactions"
},
{
    "id": "Disease::MESH:D015352",
    "name": "Dry Eye Syndromes"
},
{
    "id": "Disease::MESH:D004368",
    "name": "Dry Socket"
},
{
    "id": "Disease::MESH:D004370",
    "name": "Duane Retraction Syndrome"
},
{
    "id": "Disease::MESH:D004374",
    "name": "Ductus Arteriosus"
},
{
    "id": "Disease::MESH:D004377",
    "name": "Dumping Syndrome"
},
{
    "id": "Disease::MESH:D004379",
    "name": "Duodenal Neoplasms"
},
{
    "id": "Disease::MESH:D004381",
    "name": "Duodenal Ulcer"
},
{
    "id": "Disease::MESH:D004382",
    "name": "Duodenitis"
},
{
    "id": "Disease::MESH:D004383",
    "name": "Duodenogastric Reflux"
},
{
    "id": "Disease::MESH:D004387",
    "name": "Dupuytren Contracture"
},
{
    "id": "Disease::MESH:D004392",
    "name": "Dwarfism"
},
{
    "id": "Disease::MESH:D004393",
    "name": "Dwarfism"
},
{
    "id": "Disease::MESH:D004401",
    "name": "Dysarthria"
},
{
    "id": "Disease::MESH:D004402",
    "name": "Dysautonomia"
},
{
    "id": "Disease::MESH:D064806",
    "name": "Dysbiosis"
},
{
    "id": "Disease::MESH:D004403",
    "name": "Dysentery"
},
{
    "id": "Disease::MESH:D004404",
    "name": "Dysentery"
},
{
    "id": "Disease::MESH:D004405",
    "name": "Dysentery"
},
{
    "id": "Disease::MESH:D004407",
    "name": "Dysgerminoma"
},
{
    "id": "Disease::MESH:D004408",
    "name": "Dysgeusia"
},
{
    "id": "Disease::MESH:D019871",
    "name": "Dyskeratosis Congenita"
},
{
    "id": "Disease::MESH:D004409",
    "name": "Dyskinesia"
},
{
    "id": "Disease::MESH:D020820",
    "name": "Dyskinesias"
},
{
    "id": "Disease::MESH:D004410",
    "name": "Dyslexia"
},
{
    "id": "Disease::MESH:D050171",
    "name": "Dyslipidemias"
},
{
    "id": "Disease::MESH:D004412",
    "name": "Dysmenorrhea"
},
{
    "id": "Disease::MESH:D004414",
    "name": "Dyspareunia"
},
{
    "id": "Disease::MESH:D004415",
    "name": "Dyspepsia"
},
{
    "id": "Disease::MESH:D055154",
    "name": "Dysphonia"
},
{
    "id": "Disease::MESH:D004416",
    "name": "Dysplastic Nevus Syndrome"
},
{
    "id": "Disease::MESH:D004417",
    "name": "Dyspnea"
},
{
    "id": "Disease::MESH:D020920",
    "name": "Dyssomnias"
},
{
    "id": "Disease::MESH:D019263",
    "name": "Dysthymic Disorder"
},
{
    "id": "Disease::MESH:D004420",
    "name": "Dystocia"
},
{
    "id": "Disease::MESH:D004421",
    "name": "Dystonia"
},
{
    "id": "Disease::MESH:D004422",
    "name": "Dystonia Musculorum Deformans"
},
{
    "id": "Disease::MESH:D020821",
    "name": "Dystonic Disorders"
},
{
    "id": "Disease::MESH:D053159",
    "name": "Dysuria"
},
{
    "id": "Disease::MESH:D004427",
    "name": "Ear Diseases"
},
{
    "id": "Disease::MESH:D004428",
    "name": "Ear Neoplasms"
},
{
    "id": "Disease::MESH:D057090",
    "name": "Eccrine Porocarcinoma"
},
{
    "id": "Disease::MESH:D004443",
    "name": "Echinococcosis"
},
{
    "id": "Disease::MESH:D004445",
    "name": "Echinococcosis"
},
{
    "id": "Disease::MESH:D058535",
    "name": "Echogenic Bowel"
},
{
    "id": "Disease::MESH:D004461",
    "name": "Eclampsia"
},
{
    "id": "Disease::MESH:D004473",
    "name": "Ecthyma"
},
{
    "id": "Disease::MESH:D053360",
    "name": "Ectodermal Dysplasia"
},
{
    "id": "Disease::MESH:D004476",
    "name": "Ectodermal Dysplasia"
},
{
    "id": "Disease::MESH:D053358",
    "name": "Ectodermal Dysplasia 1"
},
{
    "id": "Disease::MESH:D053359",
    "name": "Ectodermal Dysplasia 3"
},
{
    "id": "Disease::MESH:D004479",
    "name": "Ectopia Lentis"
},
{
    "id": "Disease::MESH:D004482",
    "name": "Ectromelia"
},
{
    "id": "Disease::MESH:D004483",
    "name": "Ectropion"
},
{
    "id": "Disease::MESH:D011146",
    "name": "Eczema"
},
{
    "id": "Disease::MESH:D004485",
    "name": "Eczema"
},
{
    "id": "Disease::MESH:D004487",
    "name": "Edema"
},
{
    "id": "Disease::MESH:D004489",
    "name": "Edema"
},
{
    "id": "Disease::MESH:D021181",
    "name": "Egg Hypersensitivity"
},
{
    "id": "Disease::MESH:D004535",
    "name": "Ehlers-Danlos Syndrome"
},
{
    "id": "Disease::MESH:D016873",
    "name": "Ehrlichiosis"
},
{
    "id": "Disease::MESH:D004541",
    "name": "Eisenmenger Complex"
},
{
    "id": "Disease::MESH:D004556",
    "name": "Electric Injuries"
},
{
    "id": "Disease::MESH:D004605",
    "name": "Elephantiasis"
},
{
    "id": "Disease::MESH:D004604",
    "name": "Elephantiasis"
},
{
    "id": "Disease::MESH:D004612",
    "name": "Elliptocytosis"
},
{
    "id": "Disease::MESH:D004613",
    "name": "Ellis-Van Creveld Syndrome"
},
{
    "id": "Disease::MESH:D004620",
    "name": "Embolism"
},
{
    "id": "Disease::MESH:D019320",
    "name": "Embolism"
},
{
    "id": "Disease::MESH:D004619",
    "name": "Embolism"
},
{
    "id": "Disease::MESH:D004617",
    "name": "Embolism"
},
{
    "id": "Disease::MESH:D004618",
    "name": "Embolism"
},
{
    "id": "Disease::MESH:D017700",
    "name": "Embolism"
},
{
    "id": "Disease::MESH:D004621",
    "name": "Embolization"
},
{
    "id": "Disease::MESH:D020964",
    "name": "Embryo Loss"
},
{
    "id": "Disease::MESH:D004630",
    "name": "Emergencies"
},
{
    "id": "Disease::MESH:D004646",
    "name": "Emphysema"
},
{
    "id": "Disease::MESH:D041882",
    "name": "Emphysematous Cholecystitis"
},
{
    "id": "Disease::MESH:D004652",
    "name": "Empty Sella Syndrome"
},
{
    "id": "Disease::MESH:D016724",
    "name": "Empyema"
},
{
    "id": "Disease::MESH:D004654",
    "name": "Empyema"
},
{
    "id": "Disease::MESH:D004653",
    "name": "Empyema"
},
{
    "id": "Disease::MESH:D004660",
    "name": "Encephalitis"
},
{
    "id": "Disease::MESH:D004675",
    "name": "Encephalitis"
},
{
    "id": "Disease::MESH:D020803",
    "name": "Encephalitis"
},
{
    "id": "Disease::MESH:D004670",
    "name": "Encephalitis"
},
{
    "id": "Disease::MESH:D020804",
    "name": "Encephalitis"
},
{
    "id": "Disease::MESH:D004671",
    "name": "Encephalitis"
},
{
    "id": "Disease::MESH:D018792",
    "name": "Encephalitis"
},
{
    "id": "Disease::MESH:D004672",
    "name": "Encephalitis"
},
{
    "id": "Disease::MESH:D016890",
    "name": "Encephalitozoonosis"
},
{
    "id": "Disease::MESH:D004677",
    "name": "Encephalocele"
},
{
    "id": "Disease::MESH:D004678",
    "name": "Encephalomalacia"
},
{
    "id": "Disease::MESH:D004679",
    "name": "Encephalomyelitis"
},
{
    "id": "Disease::MESH:D004673",
    "name": "Encephalomyelitis"
},
{
    "id": "Disease::MESH:D004681",
    "name": "Encephalomyelitis"
},
{
    "id": "Disease::MESH:D020241",
    "name": "Encephalomyelitis"
},
{
    "id": "Disease::MESH:D016643",
    "name": "Encephalopathy"
},
{
    "id": "Disease::MESH:D004687",
    "name": "Enchondromatosis"
},
{
    "id": "Disease::MESH:D004688",
    "name": "Encopresis"
},
{
    "id": "Disease::MESH:D058625",
    "name": "End Stage Liver Disease"
},
{
    "id": "Disease::MESH:D004692",
    "name": "Endarteritis"
},
{
    "id": "Disease::MESH:D004694",
    "name": "Endocardial Cushion Defects"
},
{
    "id": "Disease::MESH:D004697",
    "name": "Endocarditis"
},
{
    "id": "Disease::MESH:D004696",
    "name": "Endocarditis"
},
{
    "id": "Disease::MESH:D004698",
    "name": "Endocarditis"
},
{
    "id": "Disease::MESH:D004701",
    "name": "Endocrine Gland Neoplasms"
},
{
    "id": "Disease::MESH:D004700",
    "name": "Endocrine System Diseases"
},
{
    "id": "Disease::MESH:D018240",
    "name": "Endodermal Sinus Tumor"
},
{
    "id": "Disease::MESH:D057867",
    "name": "Endoleak"
},
{
    "id": "Disease::MESH:D018159",
    "name": "Endolymphatic Hydrops"
},
{
    "id": "Disease::MESH:D004714",
    "name": "Endometrial Hyperplasia"
},
{
    "id": "Disease::MESH:D016889",
    "name": "Endometrial Neoplasms"
},
{
    "id": "Disease::MESH:D036821",
    "name": "Endometrial Stromal Tumors"
},
{
    "id": "Disease::MESH:D004715",
    "name": "Endometriosis"
},
{
    "id": "Disease::MESH:D004716",
    "name": "Endometritis"
},
{
    "id": "Disease::MESH:D009877",
    "name": "Endophthalmitis"
},
{
    "id": "Disease::MESH:D019446",
    "name": "Endotoxemia"
},
{
    "id": "Disease::MESH:D002149",
    "name": "Energy Intake"
},
{
    "id": "Disease::MESH:D017189",
    "name": "Enoplida Infections"
},
{
    "id": "Disease::MESH:D004748",
    "name": "Entamoeba histolytica"
},
{
    "id": "Disease::MESH:D004751",
    "name": "Enteritis"
},
{
    "id": "Disease::MESH:D004756",
    "name": "Enterobacteriaceae Infections"
},
{
    "id": "Disease::MESH:D017229",
    "name": "Enterobiasis"
},
{
    "id": "Disease::MESH:D004760",
    "name": "Enterocolitis"
},
{
    "id": "Disease::MESH:D004761",
    "name": "Enterocolitis"
},
{
    "id": "Disease::MESH:D044504",
    "name": "Enterocolitis"
},
{
    "id": "Disease::MESH:D020345",
    "name": "Enterocolitis"
},
{
    "id": "Disease::MESH:D054307",
    "name": "Enterotoxigenic Escherichia coli"
},
{
    "id": "Disease::MESH:D004769",
    "name": "Enterovirus Infections"
},
{
    "id": "Disease::MESH:D004775",
    "name": "Enuresis"
},
{
    "id": "Disease::MESH:D018876",
    "name": "Environmental Illness"
},
{
    "id": "Disease::MESH:D016583",
    "name": "Enzootic Bovine Leukosis"
},
{
    "id": "Disease::MESH:D004802",
    "name": "Eosinophilia"
},
{
    "id": "Disease::MESH:D016603",
    "name": "Eosinophilia-Myalgia Syndrome"
},
{
    "id": "Disease::MESH:D057765",
    "name": "Eosinophilic Esophagitis"
},
{
    "id": "Disease::MESH:D004806",
    "name": "Ependymoma"
},
{
    "id": "Disease::MESH:D004814",
    "name": "Epidermal Cyst"
},
{
    "id": "Disease::MESH:D004819",
    "name": "Epidermodysplasia Verruciformis"
},
{
    "id": "Disease::MESH:D016109",
    "name": "Epidermolysis Bullosa"
},
{
    "id": "Disease::MESH:D004820",
    "name": "Epidermolysis Bullosa"
},
{
    "id": "Disease::MESH:D016107",
    "name": "Epidermolysis Bullosa Acquisita"
},
{
    "id": "Disease::MESH:D016108",
    "name": "Epidermolysis Bullosa Dystrophica"
},
{
    "id": "Disease::MESH:D004823",
    "name": "Epididymitis"
},
{
    "id": "Disease::MESH:D020802",
    "name": "Epidural Abscess"
},
{
    "id": "Disease::MESH:D015174",
    "name": "Epidural Neoplasms"
},
{
    "id": "Disease::MESH:D004826",
    "name": "Epiglottitis"
},
{
    "id": "Disease::MESH:D017036",
    "name": "Epilepsia Partialis Continua"
},
{
    "id": "Disease::MESH:D004831",
    "name": "Epilepsies"
},
{
    "id": "Disease::MESH:D004828",
    "name": "Epilepsies"
},
{
    "id": "Disease::MESH:D017034",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D020936",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D004827",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D004830",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D004833",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D020938",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D019305",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D017029",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D020195",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D004832",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D004829",
    "name": "Epilepsy"
},
{
    "id": "Disease::MESH:D004844",
    "name": "Epistaxis"
},
{
    "id": "Disease::MESH:D020031",
    "name": "Epstein-Barr Virus Infections"
},
{
    "id": "Disease::MESH:D004859",
    "name": "Equine Infectious Anemia"
},
{
    "id": "Disease::MESH:D031249",
    "name": "Erdheim-Chester Disease"
},
{
    "id": "Disease::MESH:D007172",
    "name": "Erectile Dysfunction"
},
{
    "id": "Disease::MESH:D004881",
    "name": "Ergotism"
},
{
    "id": "Disease::MESH:D004886",
    "name": "Erysipelas"
},
{
    "id": "Disease::MESH:D004887",
    "name": "Erysipeloid"
},
{
    "id": "Disease::MESH:D004890",
    "name": "Erythema"
},
{
    "id": "Disease::MESH:D015787",
    "name": "Erythema Chronicum Migrans"
},
{
    "id": "Disease::MESH:D016731",
    "name": "Erythema Infectiosum"
},
{
    "id": "Disease::MESH:D004892",
    "name": "Erythema Multiforme"
},
{
    "id": "Disease::MESH:D004893",
    "name": "Erythema Nodosum"
},
{
    "id": "Disease::MESH:D004894",
    "name": "Erythrasma"
},
{
    "id": "Disease::MESH:D004899",
    "name": "Erythroblastosis"
},
{
    "id": "Disease::MESH:D056266",
    "name": "Erythrokeratodermia Variabilis"
},
{
    "id": "Disease::MESH:D004916",
    "name": "Erythromelalgia"
},
{
    "id": "Disease::MESH:D004919",
    "name": "Erythroplasia"
},
{
    "id": "Disease::MESH:D004927",
    "name": "Escherichia coli Infections"
},
{
    "id": "Disease::MESH:D004931",
    "name": "Esophageal Achalasia"
},
{
    "id": "Disease::MESH:D004933",
    "name": "Esophageal Atresia"
},
{
    "id": "Disease::MESH:D004935",
    "name": "Esophageal Diseases"
},
{
    "id": "Disease::MESH:D015154",
    "name": "Esophageal Motility Disorders"
},
{
    "id": "Disease::MESH:D004938",
    "name": "Esophageal Neoplasms"
},
{
    "id": "Disease::MESH:D015155",
    "name": "Esophageal Spasm"
},
{
    "id": "Disease::MESH:D004940",
    "name": "Esophageal Stenosis"
},
{
    "id": "Disease::MESH:D004932",
    "name": "Esophageal and Gastric Varices"
},
{
    "id": "Disease::MESH:D004942",
    "name": "Esophagitis"
},
{
    "id": "Disease::MESH:D004941",
    "name": "Esophagitis"
},
{
    "id": "Disease::MESH:D004948",
    "name": "Esotropia"
},
{
    "id": "Disease::MESH:D020329",
    "name": "Essential Tremor"
},
{
    "id": "Disease::MESH:D018304",
    "name": "Esthesioneuroblastoma"
},
{
    "id": "Disease::MESH:D015521",
    "name": "Ethmoid Sinusitis"
},
{
    "id": "Disease::MESH:D005058",
    "name": "Eunuchism"
},
{
    "id": "Disease::MESH:D005067",
    "name": "Euthyroid Sick Syndromes"
},
{
    "id": "Disease::MESH:D005076",
    "name": "Exanthema"
},
{
    "id": "Disease::MESH:D005077",
    "name": "Exanthema Subitum"
},
{
    "id": "Disease::MESH:D017889",
    "name": "Exfoliation Syndrome"
},
{
    "id": "Disease::MESH:D010188",
    "name": "Exocrine Pancreatic Insufficiency"
},
{
    "id": "Disease::MESH:D005097",
    "name": "Exostoses"
},
{
    "id": "Disease::MESH:D054908",
    "name": "Extensively Drug-Resistant Tuberculosis"
},
{
    "id": "Disease::MESH:D005119",
    "name": "Extravasation of Diagnostic and Therapeutic Materials"
},
{
    "id": "Disease::MESH:D005124",
    "name": "Eye Abnormalities"
},
{
    "id": "Disease::MESH:D005126",
    "name": "Eye Burns"
},
{
    "id": "Disease::MESH:D005128",
    "name": "Eye Diseases"
},
{
    "id": "Disease::MESH:D015785",
    "name": "Eye Diseases"
},
{
    "id": "Disease::MESH:D015828",
    "name": "Eye Infections"
},
{
    "id": "Disease::MESH:D015818",
    "name": "Eye Infections"
},
{
    "id": "Disease::MESH:D015817",
    "name": "Eye Infections"
},
{
    "id": "Disease::MESH:D015821",
    "name": "Eye Infections"
},
{
    "id": "Disease::MESH:D005131",
    "name": "Eye Injuries"
},
{
    "id": "Disease::MESH:D005134",
    "name": "Eye Neoplasms"
},
{
    "id": "Disease::MESH:D058447",
    "name": "Eye Pain"
},
{
    "id": "Disease::MESH:D005141",
    "name": "Eyelid Diseases"
},
{
    "id": "Disease::MESH:D000795",
    "name": "Fabry Disease"
},
{
    "id": "Disease::MESH:D005146",
    "name": "Facial Asymmetry"
},
{
    "id": "Disease::MESH:D005148",
    "name": "Facial Dermatoses"
},
{
    "id": "Disease::MESH:D005155",
    "name": "Facial Nerve Diseases"
},
{
    "id": "Disease::MESH:D020220",
    "name": "Facial Nerve Injuries"
},
{
    "id": "Disease::MESH:D005157",
    "name": "Facial Pain"
},
{
    "id": "Disease::MESH:D005158",
    "name": "Facial Paralysis"
},
{
    "id": "Disease::MESH:D005166",
    "name": "Factor V Deficiency"
},
{
    "id": "Disease::MESH:D055111",
    "name": "Failed Back Surgery Syndrome"
},
{
    "id": "Disease::MESH:D005183",
    "name": "Failure to Thrive"
},
{
    "id": "Disease::MESH:D005184",
    "name": "Fallopian Tube Diseases"
},
{
    "id": "Disease::MESH:D005185",
    "name": "Fallopian Tube Neoplasms"
},
{
    "id": "Disease::MESH:D053098",
    "name": "Familial Hypophosphatemic Rickets"
},
{
    "id": "Disease::MESH:D010505",
    "name": "Familial Mediterranean Fever"
},
{
    "id": "Disease::MESH:D065627",
    "name": "Familial Primary Pulmonary Hypertension"
},
{
    "id": "Disease::MESH:D005199",
    "name": "Fanconi Anemia"
},
{
    "id": "Disease::MESH:D005198",
    "name": "Fanconi Syndrome"
},
{
    "id": "Disease::MESH:D055577",
    "name": "Farber Lipogranulomatosis"
},
{
    "id": "Disease::MESH:D005207",
    "name": "Fasciculation"
},
{
    "id": "Disease::MESH:D036981",
    "name": "Fasciitis"
},
{
    "id": "Disease::MESH:D005211",
    "name": "Fascioliasis"
},
{
    "id": "Disease::MESH:D005221",
    "name": "Fatigue"
},
{
    "id": "Disease::MESH:D015673",
    "name": "Fatigue Syndrome"
},
{
    "id": "Disease::MESH:D005235",
    "name": "Fatty Liver"
},
{
    "id": "Disease::MESH:D005234",
    "name": "Fatty Liver"
},
{
    "id": "Disease::MESH:D005236",
    "name": "Favism"
},
{
    "id": "Disease::MESH:D064147",
    "name": "Febrile Neutropenia"
},
{
    "id": "Disease::MESH:D005244",
    "name": "Fecal Impaction"
},
{
    "id": "Disease::MESH:D005242",
    "name": "Fecal Incontinence"
},
{
    "id": "Disease::MESH:D001068",
    "name": "Feeding and Eating Disorders"
},
{
    "id": "Disease::MESH:D005258",
    "name": "Felty Syndrome"
},
{
    "id": "Disease::MESH:D052776",
    "name": "Female Urogenital Diseases"
},
{
    "id": "Disease::MESH:D057925",
    "name": "Femoracetabular Impingement"
},
{
    "id": "Disease::MESH:D005264",
    "name": "Femoral Fractures"
},
{
    "id": "Disease::MESH:D005265",
    "name": "Femoral Neck Fractures"
},
{
    "id": "Disease::MESH:D005271",
    "name": "Femur Head Necrosis"
},
{
    "id": "Disease::MESH:D063647",
    "name": "Fetal Alcohol Spectrum Disorders"
},
{
    "id": "Disease::MESH:D005313",
    "name": "Fetal Death"
},
{
    "id": "Disease::MESH:D005315",
    "name": "Fetal Diseases"
},
{
    "id": "Disease::MESH:D005316",
    "name": "Fetal Distress"
},
{
    "id": "Disease::MESH:D005317",
    "name": "Fetal Growth Retardation"
},
{
    "id": "Disease::MESH:D005311",
    "name": "Fetal Hypoxia"
},
{
    "id": "Disease::MESH:D005320",
    "name": "Fetal Macrosomia"
},
{
    "id": "Disease::MESH:D005322",
    "name": "Fetal Membranes"
},
{
    "id": "Disease::MESH:D005330",
    "name": "Fetofetal Transfusion"
},
{
    "id": "Disease::MESH:D005334",
    "name": "Fever"
},
{
    "id": "Disease::MESH:D018226",
    "name": "Fibroadenoma"
},
{
    "id": "Disease::MESH:D005348",
    "name": "Fibrocystic Breast Disease"
},
{
    "id": "Disease::MESH:D005350",
    "name": "Fibroma"
},
{
    "id": "Disease::MESH:D018220",
    "name": "Fibroma"
},
{
    "id": "Disease::MESH:D018222",
    "name": "Fibromatosis"
},
{
    "id": "Disease::MESH:D005352",
    "name": "Fibromuscular Dysplasia"
},
{
    "id": "Disease::MESH:D005356",
    "name": "Fibromyalgia"
},
{
    "id": "Disease::MESH:D005354",
    "name": "Fibrosarcoma"
},
{
    "id": "Disease::MESH:D005355",
    "name": "Fibrosis"
},
{
    "id": "Disease::MESH:D005359",
    "name": "Fibrous Dysplasia"
},
{
    "id": "Disease::MESH:D005357",
    "name": "Fibrous Dysplasia of Bone"
},
{
    "id": "Disease::MESH:D005368",
    "name": "Filariasis"
},
{
    "id": "Disease::MESH:D005393",
    "name": "Fish Diseases"
},
{
    "id": "Disease::MESH:D005401",
    "name": "Fissure in Ano"
},
{
    "id": "Disease::MESH:D005402",
    "name": "Fistula"
},
{
    "id": "Disease::MESH:D005409",
    "name": "Flail Chest"
},
{
    "id": "Disease::MESH:D005414",
    "name": "Flatulence"
},
{
    "id": "Disease::MESH:D018177",
    "name": "Flavivirus Infections"
},
{
    "id": "Disease::MESH:D058267",
    "name": "Flea Infestations"
},
{
    "id": "Disease::MESH:D005458",
    "name": "Fluoride Poisoning"
},
{
    "id": "Disease::MESH:D009050",
    "name": "Fluorosis"
},
{
    "id": "Disease::MESH:D005483",
    "name": "Flushing"
},
{
    "id": "Disease::MESH:D005489",
    "name": "Focal Dermal Hypoplasia"
},
{
    "id": "Disease::MESH:D017573",
    "name": "Focal Epithelial Hyperplasia"
},
{
    "id": "Disease::MESH:D020518",
    "name": "Focal Nodular Hyperplasia"
},
{
    "id": "Disease::MESH:D005494",
    "name": "Folic Acid Deficiency"
},
{
    "id": "Disease::MESH:D005497",
    "name": "Follicular Cyst"
},
{
    "id": "Disease::MESH:D005499",
    "name": "Folliculitis"
},
{
    "id": "Disease::MESH:D005512",
    "name": "Food Hypersensitivity"
},
{
    "id": "Disease::MESH:D005517",
    "name": "Foodborne Diseases"
},
{
    "id": "Disease::MESH:D005532",
    "name": "Foot Deformities"
},
{
    "id": "Disease::MESH:D005534",
    "name": "Foot Diseases"
},
{
    "id": "Disease::MESH:D005536",
    "name": "Foot-and-Mouth Disease"
},
{
    "id": "Disease::MESH:D054092",
    "name": "Foramen Ovale"
},
{
    "id": "Disease::MESH:D005547",
    "name": "Foreign Bodies"
},
{
    "id": "Disease::MESH:D005549",
    "name": "Foreign-Body Reaction"
},
{
    "id": "Disease::MESH:D005588",
    "name": "Fox-Fordyce Disease"
},
{
    "id": "Disease::MESH:D015775",
    "name": "Fractures"
},
{
    "id": "Disease::MESH:D050723",
    "name": "Fractures"
},
{
    "id": "Disease::MESH:D005597",
    "name": "Fractures"
},
{
    "id": "Disease::MESH:D050815",
    "name": "Fractures"
},
{
    "id": "Disease::MESH:D005600",
    "name": "Fragile X Syndrome"
},
{
    "id": "Disease::MESH:D058497",
    "name": "Fraser Syndrome"
},
{
    "id": "Disease::MESH:D005621",
    "name": "Friedreich Ataxia"
},
{
    "id": "Disease::MESH:D057180",
    "name": "Frontotemporal Dementia"
},
{
    "id": "Disease::MESH:D057174",
    "name": "Frontotemporal Lobar Degeneration"
},
{
    "id": "Disease::MESH:D005627",
    "name": "Frostbite"
},
{
    "id": "Disease::MESH:D005633",
    "name": "Fructose Intolerance"
},
{
    "id": "Disease::MESH:D005642",
    "name": "Fuchs' Endothelial Dystrophy"
},
{
    "id": "Disease::MESH:D005645",
    "name": "Fucosidosis"
},
{
    "id": "Disease::MESH:D016469",
    "name": "Fungemia"
},
{
    "id": "Disease::MESH:D005660",
    "name": "Funnel Chest"
},
{
    "id": "Disease::MESH:D017823",
    "name": "Furcation Defects"
},
{
    "id": "Disease::MESH:D005667",
    "name": "Furunculosis"
},
{
    "id": "Disease::MESH:D060585",
    "name": "Fusariosis"
},
{
    "id": "Disease::MESH:D005671",
    "name": "Fused Teeth"
},
{
    "id": "Disease::MESH:D020233",
    "name": "Gait Disorders"
},
{
    "id": "Disease::MESH:D005687",
    "name": "Galactorrhea"
},
{
    "id": "Disease::MESH:D005693",
    "name": "Galactosemias"
},
{
    "id": "Disease::MESH:D005705",
    "name": "Gallbladder Diseases"
},
{
    "id": "Disease::MESH:D005706",
    "name": "Gallbladder Neoplasms"
},
{
    "id": "Disease::MESH:D042882",
    "name": "Gallstones"
},
{
    "id": "Disease::MESH:D005715",
    "name": "Gambling"
},
{
    "id": "Disease::MESH:D018303",
    "name": "Ganglioglioma"
},
{
    "id": "Disease::MESH:D045888",
    "name": "Ganglion Cysts"
},
{
    "id": "Disease::MESH:D020143",
    "name": "Gangliosidoses"
},
{
    "id": "Disease::MESH:D005733",
    "name": "Gangliosidoses"
},
{
    "id": "Disease::MESH:D016537",
    "name": "Gangliosidosis"
},
{
    "id": "Disease::MESH:D005734",
    "name": "Gangrene"
},
{
    "id": "Disease::MESH:D005736",
    "name": "Gardner Syndrome"
},
{
    "id": "Disease::MESH:D005738",
    "name": "Gas Gangrene"
},
{
    "id": "Disease::MESH:D020252",
    "name": "Gastric Antral Vascular Ectasia"
},
{
    "id": "Disease::MESH:D005747",
    "name": "Gastric Fistula"
},
{
    "id": "Disease::MESH:D005750",
    "name": "Gastric Juice"
},
{
    "id": "Disease::MESH:D017219",
    "name": "Gastric Outlet Obstruction"
},
{
    "id": "Disease::MESH:D015408",
    "name": "Gastrinoma"
},
{
    "id": "Disease::MESH:D005757",
    "name": "Gastritis"
},
{
    "id": "Disease::MESH:D005756",
    "name": "Gastritis"
},
{
    "id": "Disease::MESH:D005759",
    "name": "Gastroenteritis"
},
{
    "id": "Disease::MESH:D005764",
    "name": "Gastroesophageal Reflux"
},
{
    "id": "Disease::MESH:D005767",
    "name": "Gastrointestinal Diseases"
},
{
    "id": "Disease::MESH:D006471",
    "name": "Gastrointestinal Hemorrhage"
},
{
    "id": "Disease::MESH:D005770",
    "name": "Gastrointestinal Neoplasms"
},
{
    "id": "Disease::MESH:D046152",
    "name": "Gastrointestinal Stromal Tumors"
},
{
    "id": "Disease::MESH:D018589",
    "name": "Gastroparesis"
},
{
    "id": "Disease::MESH:D020139",
    "name": "Gastroschisis"
},
{
    "id": "Disease::MESH:D005776",
    "name": "Gaucher Disease"
},
{
    "id": "Disease::MESH:D030342",
    "name": "Genetic Diseases"
},
{
    "id": "Disease::MESH:D040181",
    "name": "Genetic Diseases"
},
{
    "id": "Disease::MESH:D005831",
    "name": "Genital Diseases"
},
{
    "id": "Disease::MESH:D005832",
    "name": "Genital Diseases"
},
{
    "id": "Disease::MESH:D005833",
    "name": "Genital Neoplasms"
},
{
    "id": "Disease::MESH:D005834",
    "name": "Genital Neoplasms"
},
{
    "id": "Disease::MESH:D042822",
    "name": "Genomic Instability"
},
{
    "id": "Disease::MESH:D057092",
    "name": "Geographic Atrophy"
},
{
    "id": "Disease::MESH:D018237",
    "name": "Germinoma"
},
{
    "id": "Disease::MESH:D005862",
    "name": "Gerstmann Syndrome"
},
{
    "id": "Disease::MESH:D016098",
    "name": "Gerstmann-Straussler-Scheinker Disease"
},
{
    "id": "Disease::MESH:D031901",
    "name": "Gestational Trophoblastic Disease"
},
{
    "id": "Disease::MESH:D013700",
    "name": "Giant Cell Arteritis"
},
{
    "id": "Disease::MESH:D018212",
    "name": "Giant Cell Tumor of Bone"
},
{
    "id": "Disease::MESH:D005870",
    "name": "Giant Cell Tumors"
},
{
    "id": "Disease::MESH:D005873",
    "name": "Giardiasis"
},
{
    "id": "Disease::MESH:D005877",
    "name": "Gigantism"
},
{
    "id": "Disease::MESH:D005878",
    "name": "Gilbert Disease"
},
{
    "id": "Disease::MESH:D005882",
    "name": "Gingival Diseases"
},
{
    "id": "Disease::MESH:D005885",
    "name": "Gingival Hyperplasia"
},
{
    "id": "Disease::MESH:D005886",
    "name": "Gingival Hypertrophy"
},
{
    "id": "Disease::MESH:D019214",
    "name": "Gingival Overgrowth"
},
{
    "id": "Disease::MESH:D005888",
    "name": "Gingival Pocket"
},
{
    "id": "Disease::MESH:D005889",
    "name": "Gingival Recession"
},
{
    "id": "Disease::MESH:D005891",
    "name": "Gingivitis"
},
{
    "id": "Disease::MESH:D005892",
    "name": "Gingivitis"
},
{
    "id": "Disease::MESH:D053579",
    "name": "Gitelman Syndrome"
},
{
    "id": "Disease::MESH:D015812",
    "name": "Glaucoma"
},
{
    "id": "Disease::MESH:D005901",
    "name": "Glaucoma"
},
{
    "id": "Disease::MESH:D005902",
    "name": "Glaucoma"
},
{
    "id": "Disease::MESH:D015355",
    "name": "Glaucoma"
},
{
    "id": "Disease::MESH:D005909",
    "name": "Glioblastoma"
},
{
    "id": "Disease::MESH:D005910",
    "name": "Glioma"
},
{
    "id": "Disease::MESH:D018316",
    "name": "Gliosarcoma"
},
{
    "id": "Disease::MESH:D005911",
    "name": "Gliosis"
},
{
    "id": "Disease::MESH:D005922",
    "name": "Glomerulonephritis"
},
{
    "id": "Disease::MESH:D015432",
    "name": "Glomerulonephritis"
},
{
    "id": "Disease::MESH:D005921",
    "name": "Glomerulonephritis"
},
{
    "id": "Disease::MESH:D015433",
    "name": "Glomerulonephritis"
},
{
    "id": "Disease::MESH:D005923",
    "name": "Glomerulosclerosis"
},
{
    "id": "Disease::MESH:D005929",
    "name": "Glossitis"
},
{
    "id": "Disease::MESH:D005928",
    "name": "Glossitis"
},
{
    "id": "Disease::MESH:D020435",
    "name": "Glossopharyngeal Nerve Diseases"
},
{
    "id": "Disease::MESH:D005935",
    "name": "Glucagonoma"
},
{
    "id": "Disease::MESH:D018149",
    "name": "Glucose Intolerance"
},
{
    "id": "Disease::MESH:D044882",
    "name": "Glucose Metabolism Disorders"
},
{
    "id": "Disease::MESH:D005955",
    "name": "Glucosephosphate Dehydrogenase Deficiency"
},
{
    "id": "Disease::MESH:D006008",
    "name": "Glycogen Storage Disease"
},
{
    "id": "Disease::MESH:D005953",
    "name": "Glycogen Storage Disease Type I"
},
{
    "id": "Disease::MESH:D006009",
    "name": "Glycogen Storage Disease Type II"
},
{
    "id": "Disease::MESH:D006010",
    "name": "Glycogen Storage Disease Type III"
},
{
    "id": "Disease::MESH:D052120",
    "name": "Glycogen Storage Disease Type IIb"
},
{
    "id": "Disease::MESH:D006011",
    "name": "Glycogen Storage Disease Type IV"
},
{
    "id": "Disease::MESH:D006012",
    "name": "Glycogen Storage Disease Type V"
},
{
    "id": "Disease::MESH:D006013",
    "name": "Glycogen Storage Disease Type VI"
},
{
    "id": "Disease::MESH:D006030",
    "name": "Glycosuria"
},
{
    "id": "Disease::MESH:D006029",
    "name": "Glycosuria"
},
{
    "id": "Disease::MESH:D058429",
    "name": "Gnathostomiasis"
},
{
    "id": "Disease::MESH:D015511",
    "name": "Goat Diseases"
},
{
    "id": "Disease::MESH:D006044",
    "name": "Goiter"
},
{
    "id": "Disease::MESH:D006043",
    "name": "Goiter"
},
{
    "id": "Disease::MESH:D006042",
    "name": "Goiter"
},
{
    "id": "Disease::MESH:D006053",
    "name": "Goldenhar Syndrome"
},
{
    "id": "Disease::MESH:D006058",
    "name": "Gonadal Disorders"
},
{
    "id": "Disease::MESH:D006060",
    "name": "Gonadal Dysgenesis"
},
{
    "id": "Disease::MESH:D006059",
    "name": "Gonadal Dysgenesis"
},
{
    "id": "Disease::MESH:D006069",
    "name": "Gonorrhea"
},
{
    "id": "Disease::MESH:D006073",
    "name": "Gout"
},
{
    "id": "Disease::MESH:D006084",
    "name": "Graft Rejection"
},
{
    "id": "Disease::MESH:D006086",
    "name": "Graft vs Host Disease"
},
{
    "id": "Disease::MESH:D016905",
    "name": "Gram-Negative Bacterial Infections"
},
{
    "id": "Disease::MESH:D016908",
    "name": "Gram-Positive Bacterial Infections"
},
{
    "id": "Disease::MESH:D016586",
    "name": "Granular Cell Tumor"
},
{
    "id": "Disease::MESH:D017789",
    "name": "Granuloma"
},
{
    "id": "Disease::MESH:D006099",
    "name": "Granuloma"
},
{
    "id": "Disease::MESH:D006101",
    "name": "Granuloma"
},
{
    "id": "Disease::MESH:D006104",
    "name": "Granuloma"
},
{
    "id": "Disease::MESH:D016460",
    "name": "Granuloma Annulare"
},
{
    "id": "Disease::MESH:D006100",
    "name": "Granuloma Inguinale"
},
{
    "id": "Disease::MESH:D051261",
    "name": "Granulomatosis"
},
{
    "id": "Disease::MESH:D014890",
    "name": "Granulomatosis with Polyangiitis"
},
{
    "id": "Disease::MESH:D006105",
    "name": "Granulomatous Disease"
},
{
    "id": "Disease::MESH:D058890",
    "name": "Granulomatous Mastitis"
},
{
    "id": "Disease::MESH:D006106",
    "name": "Granulosa Cell Tumor"
},
{
    "id": "Disease::MESH:D006111",
    "name": "Graves Disease"
},
{
    "id": "Disease::MESH:D049970",
    "name": "Graves Ophthalmopathy"
},
{
    "id": "Disease::MESH:D055652",
    "name": "Gray Platelet Syndrome"
},
{
    "id": "Disease::MESH:D006130",
    "name": "Growth Disorders"
},
{
    "id": "Disease::MESH:D049912",
    "name": "Growth Hormone-Secreting Pituitary Adenoma"
},
{
    "id": "Disease::MESH:D020275",
    "name": "Guillain-Barre Syndrome"
},
{
    "id": "Disease::MESH:D006175",
    "name": "Gynatresia"
},
{
    "id": "Disease::MESH:D006177",
    "name": "Gynecomastia"
},
{
    "id": "Disease::MESH:D015799",
    "name": "Gyrate Atrophy"
},
{
    "id": "Disease::MESH:D017359",
    "name": "HELLP Syndrome"
},
{
    "id": "Disease::MESH:D015658",
    "name": "HIV Infections"
},
{
    "id": "Disease::MESH:D020276",
    "name": "HIV Long-Term Survivors"
},
{
    "id": "Disease::MESH:D006679",
    "name": "HIV Seropositivity"
},
{
    "id": "Disease::MESH:D019247",
    "name": "HIV Wasting Syndrome"
},
{
    "id": "Disease::MESH:D015497",
    "name": "HIV-1"
},
{
    "id": "Disease::MESH:D039682",
    "name": "HIV-Associated Lipodystrophy Syndrome"
},
{
    "id": "Disease::MESH:D015490",
    "name": "HTLV-I Infections"
},
{
    "id": "Disease::MESH:D006192",
    "name": "Haemophilus Infections"
},
{
    "id": "Disease::MESH:D006201",
    "name": "Hair Diseases"
},
{
    "id": "Disease::MESH:D031845",
    "name": "Hajdu-Cheney Syndrome"
},
{
    "id": "Disease::MESH:D006209",
    "name": "Halitosis"
},
{
    "id": "Disease::MESH:D006212",
    "name": "Hallucinations"
},
{
    "id": "Disease::MESH:D006215",
    "name": "Hallux Valgus"
},
{
    "id": "Disease::MESH:D006222",
    "name": "Hamartoma"
},
{
    "id": "Disease::MESH:D006223",
    "name": "Hamartoma Syndrome"
},
{
    "id": "Disease::MESH:D006232",
    "name": "Hand"
},
{
    "id": "Disease::MESH:D053421",
    "name": "Hand-Arm Vibration Syndrome"
},
{
    "id": "Disease::MESH:D060831",
    "name": "Hand-Foot Syndrome"
},
{
    "id": "Disease::MESH:D018804",
    "name": "Hantavirus Pulmonary Syndrome"
},
{
    "id": "Disease::MESH:D006250",
    "name": "Hartnup Disease"
},
{
    "id": "Disease::MESH:D050031",
    "name": "Hashimoto Disease"
},
{
    "id": "Disease::MESH:D016489",
    "name": "Head Injuries"
},
{
    "id": "Disease::MESH:D006258",
    "name": "Head and Neck Neoplasms"
},
{
    "id": "Disease::MESH:D006261",
    "name": "Headache"
},
{
    "id": "Disease::MESH:D051270",
    "name": "Headache Disorders"
},
{
    "id": "Disease::MESH:D051271",
    "name": "Headache Disorders"
},
{
    "id": "Disease::MESH:D020773",
    "name": "Headache Disorders"
},
{
    "id": "Disease::MESH:D006311",
    "name": "Hearing Disorders"
},
{
    "id": "Disease::MESH:D006316",
    "name": "Hearing Loss"
},
{
    "id": "Disease::MESH:D006317",
    "name": "Hearing Loss"
},
{
    "id": "Disease::MESH:D034381",
    "name": "Hearing Loss"
},
{
    "id": "Disease::MESH:D003639",
    "name": "Hearing Loss"
},
{
    "id": "Disease::MESH:D006319",
    "name": "Hearing Loss"
},
{
    "id": "Disease::MESH:D006323",
    "name": "Heart Arrest"
},
{
    "id": "Disease::MESH:D006327",
    "name": "Heart Block"
},
{
    "id": "Disease::MESH:D006330",
    "name": "Heart Defects"
},
{
    "id": "Disease::MESH:D006331",
    "name": "Heart Diseases"
},
{
    "id": "Disease::MESH:D054143",
    "name": "Heart Failure"
},
{
    "id": "Disease::MESH:D054144",
    "name": "Heart Failure"
},
{
    "id": "Disease::MESH:D006333",
    "name": "Heart Failure"
},
{
    "id": "Disease::MESH:D006335",
    "name": "Heart Injuries"
},
{
    "id": "Disease::MESH:D006337",
    "name": "Heart Murmurs"
},
{
    "id": "Disease::MESH:D006338",
    "name": "Heart Neoplasms"
},
{
    "id": "Disease::MESH:D006339",
    "name": "Heart Rate"
},
{
    "id": "Disease::MESH:D006341",
    "name": "Heart Rupture"
},
{
    "id": "Disease::MESH:D006344",
    "name": "Heart Septal Defects"
},
{
    "id": "Disease::MESH:D006343",
    "name": "Heart Septal Defects"
},
{
    "id": "Disease::MESH:D006345",
    "name": "Heart Septal Defects"
},
{
    "id": "Disease::MESH:D006349",
    "name": "Heart Valve Diseases"
},
{
    "id": "Disease::MESH:D006356",
    "name": "Heartburn"
},
{
    "id": "Disease::MESH:D018882",
    "name": "Heat Stress Disorders"
},
{
    "id": "Disease::MESH:D018883",
    "name": "Heat Stroke"
},
{
    "id": "Disease::MESH:D006362",
    "name": "Heavy Chain Disease"
},
{
    "id": "Disease::MESH:D016481",
    "name": "Helicobacter Infections"
},
{
    "id": "Disease::MESH:D006373",
    "name": "Helminthiasis"
},
{
    "id": "Disease::MESH:D018325",
    "name": "Hemangioblastoma"
},
{
    "id": "Disease::MESH:D006390",
    "name": "Hemangioendothelioma"
},
{
    "id": "Disease::MESH:D018323",
    "name": "Hemangioendothelioma"
},
{
    "id": "Disease::MESH:D018324",
    "name": "Hemangioma"
},
{
    "id": "Disease::MESH:D006392",
    "name": "Hemangioma"
},
{
    "id": "Disease::MESH:D006391",
    "name": "Hemangioma"
},
{
    "id": "Disease::MESH:D020786",
    "name": "Hemangioma"
},
{
    "id": "Disease::MESH:D006393",
    "name": "Hemangiopericytoma"
},
{
    "id": "Disease::MESH:D006394",
    "name": "Hemangiosarcoma"
},
{
    "id": "Disease::MESH:D006395",
    "name": "Hemarthrosis"
},
{
    "id": "Disease::MESH:D006402",
    "name": "Hematologic Diseases"
},
{
    "id": "Disease::MESH:D019337",
    "name": "Hematologic Neoplasms"
},
{
    "id": "Disease::MESH:D020200",
    "name": "Hematoma"
},
{
    "id": "Disease::MESH:D046748",
    "name": "Hematoma"
},
{
    "id": "Disease::MESH:D006406",
    "name": "Hematoma"
},
{
    "id": "Disease::MESH:D006408",
    "name": "Hematoma"
},
{
    "id": "Disease::MESH:D020199",
    "name": "Hematoma"
},
{
    "id": "Disease::MESH:D006417",
    "name": "Hematuria"
},
{
    "id": "Disease::MESH:D065705",
    "name": "Hemimegalencephaly"
},
{
    "id": "Disease::MESH:D006429",
    "name": "Hemiplegia"
},
{
    "id": "Disease::MESH:D006432",
    "name": "Hemochromatosis"
},
{
    "id": "Disease::MESH:D006450",
    "name": "Hemoglobin SC Disease"
},
{
    "id": "Disease::MESH:D006453",
    "name": "Hemoglobinopathies"
},
{
    "id": "Disease::MESH:D006457",
    "name": "Hemoglobinuria"
},
{
    "id": "Disease::MESH:D006456",
    "name": "Hemoglobinuria"
},
{
    "id": "Disease::MESH:D006461",
    "name": "Hemolysis"
},
{
    "id": "Disease::MESH:D006463",
    "name": "Hemolytic-Uremic Syndrome"
},
{
    "id": "Disease::MESH:D006467",
    "name": "Hemophilia A"
},
{
    "id": "Disease::MESH:D002836",
    "name": "Hemophilia B"
},
{
    "id": "Disease::MESH:D006469",
    "name": "Hemoptysis"
},
{
    "id": "Disease::MESH:D006470",
    "name": "Hemorrhage"
},
{
    "id": "Disease::MESH:D006474",
    "name": "Hemorrhagic Disorders"
},
{
    "id": "Disease::MESH:D019142",
    "name": "Hemorrhagic Fever"
},
{
    "id": "Disease::MESH:D006478",
    "name": "Hemorrhagic Fever"
},
{
    "id": "Disease::MESH:D006479",
    "name": "Hemorrhagic Fever"
},
{
    "id": "Disease::MESH:D006480",
    "name": "Hemorrhagic Fever with Renal Syndrome"
},
{
    "id": "Disease::MESH:D006482",
    "name": "Hemorrhagic Fevers"
},
{
    "id": "Disease::MESH:D006483",
    "name": "Hemorrhagic Septicemia"
},
{
    "id": "Disease::MESH:D006484",
    "name": "Hemorrhoids"
},
{
    "id": "Disease::MESH:D006486",
    "name": "Hemosiderosis"
},
{
    "id": "Disease::MESH:D051516",
    "name": "Hemospermia"
},
{
    "id": "Disease::MESH:D020141",
    "name": "Hemostatic Disorders"
},
{
    "id": "Disease::MESH:D006501",
    "name": "Hepatic Encephalopathy"
},
{
    "id": "Disease::MESH:D048550",
    "name": "Hepatic Insufficiency"
},
{
    "id": "Disease::MESH:D006504",
    "name": "Hepatic Veno-Occlusive Disease"
},
{
    "id": "Disease::MESH:D006521",
    "name": "Hepatitis"
},
{
    "id": "Disease::MESH:D006519",
    "name": "Hepatitis"
},
{
    "id": "Disease::MESH:D019693",
    "name": "Hepatitis"
},
{
    "id": "Disease::MESH:D006525",
    "name": "Hepatitis"
},
{
    "id": "Disease::MESH:D006506",
    "name": "Hepatitis A"
},
{
    "id": "Disease::MESH:D006509",
    "name": "Hepatitis B"
},
{
    "id": "Disease::MESH:D019694",
    "name": "Hepatitis B"
},
{
    "id": "Disease::MESH:D006526",
    "name": "Hepatitis C"
},
{
    "id": "Disease::MESH:D019698",
    "name": "Hepatitis C"
},
{
    "id": "Disease::MESH:D003699",
    "name": "Hepatitis D"
},
{
    "id": "Disease::MESH:D019701",
    "name": "Hepatitis D"
},
{
    "id": "Disease::MESH:D016751",
    "name": "Hepatitis E"
},
{
    "id": "Disease::MESH:D018197",
    "name": "Hepatoblastoma"
},
{
    "id": "Disease::MESH:D006527",
    "name": "Hepatolenticular Degeneration"
},
{
    "id": "Disease::MESH:D006529",
    "name": "Hepatomegaly"
},
{
    "id": "Disease::MESH:D020065",
    "name": "Hepatopulmonary Syndrome"
},
{
    "id": "Disease::MESH:D006530",
    "name": "Hepatorenal Syndrome"
},
{
    "id": "Disease::MESH:D056828",
    "name": "Hereditary Angioedema Type III"
},
{
    "id": "Disease::MESH:D056829",
    "name": "Hereditary Angioedema Types I and II"
},
{
    "id": "Disease::MESH:D056660",
    "name": "Hereditary Autoinflammatory Diseases"
},
{
    "id": "Disease::MESH:D061325",
    "name": "Hereditary Breast and Ovarian Cancer Syndrome"
},
{
    "id": "Disease::MESH:D020279",
    "name": "Hereditary Central Nervous System Demyelinating Diseases"
},
{
    "id": "Disease::MESH:D009477",
    "name": "Hereditary Sensory and Autonomic Neuropathies"
},
{
    "id": "Disease::MESH:D015417",
    "name": "Hereditary Sensory and Motor Neuropathy"
},
{
    "id": "Disease::MESH:D020271",
    "name": "Heredodegenerative Disorders"
},
{
    "id": "Disease::MESH:D022861",
    "name": "Hermanski-Pudlak Syndrome"
},
{
    "id": "Disease::MESH:D006551",
    "name": "Hernia"
},
{
    "id": "Disease::MESH:D006555",
    "name": "Hernia"
},
{
    "id": "Disease::MESH:D006552",
    "name": "Hernia"
},
{
    "id": "Disease::MESH:D006547",
    "name": "Hernia"
},
{
    "id": "Disease::MESH:D065630",
    "name": "Hernias"
},
{
    "id": "Disease::MESH:D006556",
    "name": "Heroin Dependence"
},
{
    "id": "Disease::MESH:D006558",
    "name": "Herpes Genitalis"
},
{
    "id": "Disease::MESH:D006560",
    "name": "Herpes Labialis"
},
{
    "id": "Disease::MESH:D006561",
    "name": "Herpes Simplex"
},
{
    "id": "Disease::MESH:D006562",
    "name": "Herpes Zoster"
},
{
    "id": "Disease::MESH:D006563",
    "name": "Herpes Zoster Ophthalmicus"
},
{
    "id": "Disease::MESH:D016697",
    "name": "Herpes Zoster Oticus"
},
{
    "id": "Disease::MESH:D006566",
    "name": "Herpesviridae Infections"
},
{
    "id": "Disease::MESH:D019288",
    "name": "Herpesvirus 8"
},
{
    "id": "Disease::MESH:D059446",
    "name": "Heterotaxy Syndrome"
},
{
    "id": "Disease::MESH:D006606",
    "name": "Hiccup"
},
{
    "id": "Disease::MESH:D016575",
    "name": "Hidradenitis"
},
{
    "id": "Disease::MESH:D017497",
    "name": "Hidradenitis Suppurativa"
},
{
    "id": "Disease::MESH:D018251",
    "name": "Hidrocystoma"
},
{
    "id": "Disease::MESH:D006618",
    "name": "Hip Dislocation"
},
{
    "id": "Disease::MESH:D006617",
    "name": "Hip Dislocation"
},
{
    "id": "Disease::MESH:D006620",
    "name": "Hip Fractures"
},
{
    "id": "Disease::MESH:D006627",
    "name": "Hirschsprung Disease"
},
{
    "id": "Disease::MESH:D006628",
    "name": "Hirsutism"
},
{
    "id": "Disease::MESH:D020042",
    "name": "Histiocytic Necrotizing Lymphadenitis"
},
{
    "id": "Disease::MESH:D054747",
    "name": "Histiocytic Sarcoma"
},
{
    "id": "Disease::MESH:D051642",
    "name": "Histiocytoma"
},
{
    "id": "Disease::MESH:D051677",
    "name": "Histiocytoma"
},
{
    "id": "Disease::MESH:D018219",
    "name": "Histiocytoma"
},
{
    "id": "Disease::MESH:D015618",
    "name": "Histiocytosis"
},
{
    "id": "Disease::MESH:D006646",
    "name": "Histiocytosis"
},
{
    "id": "Disease::MESH:D015616",
    "name": "Histiocytosis"
},
{
    "id": "Disease::MESH:D006660",
    "name": "Histoplasmosis"
},
{
    "id": "Disease::MESH:D006689",
    "name": "Hodgkin Disease"
},
{
    "id": "Disease::MESH:D028922",
    "name": "Holocarboxylase Synthetase Deficiency"
},
{
    "id": "Disease::MESH:D016142",
    "name": "Holoprosencephaly"
},
{
    "id": "Disease::MESH:D006712",
    "name": "Homocystinuria"
},
{
    "id": "Disease::MESH:D006725",
    "name": "Hookworm Infections"
},
{
    "id": "Disease::MESH:D006726",
    "name": "Hordeolum"
},
{
    "id": "Disease::MESH:D006760",
    "name": "Hospitalization"
},
{
    "id": "Disease::MESH:D019584",
    "name": "Hot Flashes"
},
{
    "id": "Disease::MESH:D006816",
    "name": "Huntington Disease"
},
{
    "id": "Disease::MESH:D018327",
    "name": "Hutchinson's Melanotic Freckle"
},
{
    "id": "Disease::MESH:D006819",
    "name": "Hyaline Membrane Disease"
},
{
    "id": "Disease::MESH:D057770",
    "name": "Hyalinosis"
},
{
    "id": "Disease::MESH:D006828",
    "name": "Hydatidiform Mole"
},
{
    "id": "Disease::MESH:D006832",
    "name": "Hydranencephaly"
},
{
    "id": "Disease::MESH:D006849",
    "name": "Hydrocephalus"
},
{
    "id": "Disease::MESH:D006850",
    "name": "Hydrocephalus"
},
{
    "id": "Disease::MESH:D006869",
    "name": "Hydronephrosis"
},
{
    "id": "Disease::MESH:D015160",
    "name": "Hydrops Fetalis"
},
{
    "id": "Disease::MESH:D006876",
    "name": "Hydrothorax"
},
{
    "id": "Disease::MESH:D006925",
    "name": "Hymenolepiasis"
},
{
    "id": "Disease::MESH:D053307",
    "name": "Hyper-IgM Immunodeficiency Syndrome"
},
{
    "id": "Disease::MESH:D053306",
    "name": "Hyper-IgM Immunodeficiency Syndrome"
},
{
    "id": "Disease::MESH:D006929",
    "name": "Hyperaldosteronism"
},
{
    "id": "Disease::MESH:D006930",
    "name": "Hyperalgesia"
},
{
    "id": "Disease::MESH:D022124",
    "name": "Hyperammonemia"
},
{
    "id": "Disease::MESH:D034321",
    "name": "Hyperamylasemia"
},
{
    "id": "Disease::MESH:D017588",
    "name": "Hyperandrogenism"
},
{
    "id": "Disease::MESH:D006933",
    "name": "Hyperbilirubinemia"
},
{
    "id": "Disease::MESH:D051556",
    "name": "Hyperbilirubinemia"
},
{
    "id": "Disease::MESH:D006932",
    "name": "Hyperbilirubinemia"
},
{
    "id": "Disease::MESH:D006934",
    "name": "Hypercalcemia"
},
{
    "id": "Disease::MESH:D053565",
    "name": "Hypercalciuria"
},
{
    "id": "Disease::MESH:D006935",
    "name": "Hypercapnia"
},
{
    "id": "Disease::MESH:D006937",
    "name": "Hypercholesterolemia"
},
{
    "id": "Disease::MESH:D006939",
    "name": "Hyperemesis Gravidarum"
},
{
    "id": "Disease::MESH:D006940",
    "name": "Hyperemia"
},
{
    "id": "Disease::MESH:D017681",
    "name": "Hypereosinophilic Syndrome"
},
{
    "id": "Disease::MESH:D006941",
    "name": "Hyperesthesia"
},
{
    "id": "Disease::MESH:D006942",
    "name": "Hypergammaglobulinemia"
},
{
    "id": "Disease::MESH:D006943",
    "name": "Hyperglycemia"
},
{
    "id": "Disease::MESH:D006944",
    "name": "Hyperglycemic Hyperosmolar Nonketotic Coma"
},
{
    "id": "Disease::MESH:D020158",
    "name": "Hyperglycinemia"
},
{
    "id": "Disease::MESH:D006945",
    "name": "Hyperhidrosis"
},
{
    "id": "Disease::MESH:D020138",
    "name": "Hyperhomocysteinemia"
},
{
    "id": "Disease::MESH:D006946",
    "name": "Hyperinsulinism"
},
{
    "id": "Disease::MESH:D006947",
    "name": "Hyperkalemia"
},
{
    "id": "Disease::MESH:D017488",
    "name": "Hyperkeratosis"
},
{
    "id": "Disease::MESH:D006948",
    "name": "Hyperkinesis"
},
{
    "id": "Disease::MESH:D065906",
    "name": "Hyperlactatemia"
},
{
    "id": "Disease::MESH:D006950",
    "name": "Hyperlipidemia"
},
{
    "id": "Disease::MESH:D006949",
    "name": "Hyperlipidemias"
},
{
    "id": "Disease::MESH:D008072",
    "name": "Hyperlipoproteinemia Type I"
},
{
    "id": "Disease::MESH:D006938",
    "name": "Hyperlipoproteinemia Type II"
},
{
    "id": "Disease::MESH:D006952",
    "name": "Hyperlipoproteinemia Type III"
},
{
    "id": "Disease::MESH:D006953",
    "name": "Hyperlipoproteinemia Type IV"
},
{
    "id": "Disease::MESH:D006954",
    "name": "Hyperlipoproteinemia Type V"
},
{
    "id": "Disease::MESH:D006951",
    "name": "Hyperlipoproteinemias"
},
{
    "id": "Disease::MESH:D020167",
    "name": "Hyperlysinemias"
},
{
    "id": "Disease::MESH:D006955",
    "name": "Hypernatremia"
},
{
    "id": "Disease::MESH:D006956",
    "name": "Hyperopia"
},
{
    "id": "Disease::MESH:D004057",
    "name": "Hyperostosis"
},
{
    "id": "Disease::MESH:D015576",
    "name": "Hyperostosis"
},
{
    "id": "Disease::MESH:D006959",
    "name": "Hyperoxaluria"
},
{
    "id": "Disease::MESH:D006960",
    "name": "Hyperoxaluria"
},
{
    "id": "Disease::MESH:D018496",
    "name": "Hyperoxia"
},
{
    "id": "Disease::MESH:D049950",
    "name": "Hyperparathyroidism"
},
{
    "id": "Disease::MESH:D006962",
    "name": "Hyperparathyroidism"
},
{
    "id": "Disease::MESH:D006961",
    "name": "Hyperparathyroidism"
},
{
    "id": "Disease::MESH:D006963",
    "name": "Hyperphagia"
},
{
    "id": "Disease::MESH:D054559",
    "name": "Hyperphosphatemia"
},
{
    "id": "Disease::MESH:D017495",
    "name": "Hyperpigmentation"
},
{
    "id": "Disease::MESH:D006964",
    "name": "Hyperpituitarism"
},
{
    "id": "Disease::MESH:D006965",
    "name": "Hyperplasia"
},
{
    "id": "Disease::MESH:D006966",
    "name": "Hyperprolactinemia"
},
{
    "id": "Disease::MESH:D006967",
    "name": "Hypersensitivity"
},
{
    "id": "Disease::MESH:D006969",
    "name": "Hypersensitivity"
},
{
    "id": "Disease::MESH:D006971",
    "name": "Hypersplenism"
},
{
    "id": "Disease::MESH:D006973",
    "name": "Hypertension"
},
{
    "id": "Disease::MESH:D006977",
    "name": "Hypertension"
},
{
    "id": "Disease::MESH:D006978",
    "name": "Hypertension"
},
{
    "id": "Disease::MESH:D006976",
    "name": "Hypertension"
},
{
    "id": "Disease::MESH:D006974",
    "name": "Hypertension"
},
{
    "id": "Disease::MESH:D006980",
    "name": "Hyperthyroidism"
},
{
    "id": "Disease::MESH:D050010",
    "name": "Hyperthyroxinemia"
},
{
    "id": "Disease::MESH:D006981",
    "name": "Hyperthyroxinemia"
},
{
    "id": "Disease::MESH:D006983",
    "name": "Hypertrichosis"
},
{
    "id": "Disease::MESH:D015228",
    "name": "Hypertriglyceridemia"
},
{
    "id": "Disease::MESH:D064250",
    "name": "Hypertriglyceridemic Waist"
},
{
    "id": "Disease::MESH:D017379",
    "name": "Hypertrophy"
},
{
    "id": "Disease::MESH:D017380",
    "name": "Hypertrophy"
},
{
    "id": "Disease::MESH:D006984",
    "name": "Hypertrophy"
},
{
    "id": "Disease::MESH:D033461",
    "name": "Hyperuricemia"
},
{
    "id": "Disease::MESH:D006985",
    "name": "Hyperventilation"
},
{
    "id": "Disease::MESH:D006986",
    "name": "Hypervitaminosis A"
},
{
    "id": "Disease::MESH:D006987",
    "name": "Hypesthesia"
},
{
    "id": "Disease::MESH:D006988",
    "name": "Hyphema"
},
{
    "id": "Disease::MESH:D034141",
    "name": "Hypoalbuminemia"
},
{
    "id": "Disease::MESH:D006994",
    "name": "Hypoaldosteronism"
},
{
    "id": "Disease::MESH:D052456",
    "name": "Hypoalphalipoproteinemias"
},
{
    "id": "Disease::MESH:D052476",
    "name": "Hypobetalipoproteinemia"
},
{
    "id": "Disease::MESH:D006995",
    "name": "Hypobetalipoproteinemias"
},
{
    "id": "Disease::MESH:D006996",
    "name": "Hypocalcemia"
},
{
    "id": "Disease::MESH:D016857",
    "name": "Hypocapnia"
},
{
    "id": "Disease::MESH:D006998",
    "name": "Hypochondriasis"
},
{
    "id": "Disease::MESH:D020437",
    "name": "Hypoglossal Nerve Diseases"
},
{
    "id": "Disease::MESH:D007003",
    "name": "Hypoglycemia"
},
{
    "id": "Disease::MESH:D007006",
    "name": "Hypogonadism"
},
{
    "id": "Disease::MESH:D007007",
    "name": "Hypohidrosis"
},
{
    "id": "Disease::MESH:D007008",
    "name": "Hypokalemia"
},
{
    "id": "Disease::MESH:D020514",
    "name": "Hypokalemic Periodic Paralysis"
},
{
    "id": "Disease::MESH:D018476",
    "name": "Hypokinesia"
},
{
    "id": "Disease::MESH:D007010",
    "name": "Hyponatremia"
},
{
    "id": "Disease::MESH:D007011",
    "name": "Hypoparathyroidism"
},
{
    "id": "Disease::MESH:D007014",
    "name": "Hypophosphatasia"
},
{
    "id": "Disease::MESH:D017674",
    "name": "Hypophosphatemia"
},
{
    "id": "Disease::MESH:D007015",
    "name": "Hypophosphatemia"
},
{
    "id": "Disease::MESH:D007016",
    "name": "Hypophysectomy"
},
{
    "id": "Disease::MESH:D017496",
    "name": "Hypopigmentation"
},
{
    "id": "Disease::MESH:D007018",
    "name": "Hypopituitarism"
},
{
    "id": "Disease::MESH:D018636",
    "name": "Hypoplastic Left Heart Syndrome"
},
{
    "id": "Disease::MESH:D007019",
    "name": "Hypoproteinemia"
},
{
    "id": "Disease::MESH:D007020",
    "name": "Hypoprothrombinemias"
},
{
    "id": "Disease::MESH:D007021",
    "name": "Hypospadias"
},
{
    "id": "Disease::MESH:D007022",
    "name": "Hypotension"
},
{
    "id": "Disease::MESH:D007024",
    "name": "Hypotension"
},
{
    "id": "Disease::MESH:D007027",
    "name": "Hypothalamic Diseases"
},
{
    "id": "Disease::MESH:D007029",
    "name": "Hypothalamic Neoplasms"
},
{
    "id": "Disease::MESH:D007035",
    "name": "Hypothermia"
},
{
    "id": "Disease::MESH:D007037",
    "name": "Hypothyroidism"
},
{
    "id": "Disease::MESH:D007039",
    "name": "Hypotrichosis"
},
{
    "id": "Disease::MESH:D007040",
    "name": "Hypoventilation"
},
{
    "id": "Disease::MESH:D020896",
    "name": "Hypovolemia"
},
{
    "id": "Disease::MESH:D002534",
    "name": "Hypoxia"
},
{
    "id": "Disease::MESH:D000860",
    "name": "Hypoxia"
},
{
    "id": "Disease::MESH:D020925",
    "name": "Hypoxia-Ischemia"
},
{
    "id": "Disease::MESH:D016113",
    "name": "Ichthyosiform Erythroderma"
},
{
    "id": "Disease::MESH:D017490",
    "name": "Ichthyosis"
},
{
    "id": "Disease::MESH:D007057",
    "name": "Ichthyosis"
},
{
    "id": "Disease::MESH:D016114",
    "name": "Ichthyosis"
},
{
    "id": "Disease::MESH:D053560",
    "name": "Ichthyosis Bullosa of Siemens"
},
{
    "id": "Disease::MESH:D016112",
    "name": "Ichthyosis Vulgaris"
},
{
    "id": "Disease::MESH:D020177",
    "name": "Idiopathic Hypersomnia"
},
{
    "id": "Disease::MESH:D054988",
    "name": "Idiopathic Interstitial Pneumonias"
},
{
    "id": "Disease::MESH:D054990",
    "name": "Idiopathic Pulmonary Fibrosis"
},
{
    "id": "Disease::MESH:D017098",
    "name": "IgA Deficiency"
},
{
    "id": "Disease::MESH:D011695",
    "name": "IgA Vasculitis"
},
{
    "id": "Disease::MESH:D017099",
    "name": "IgG Deficiency"
},
{
    "id": "Disease::MESH:D007077",
    "name": "Ileal Diseases"
},
{
    "id": "Disease::MESH:D007078",
    "name": "Ileal Neoplasms"
},
{
    "id": "Disease::MESH:D007079",
    "name": "Ileitis"
},
{
    "id": "Disease::MESH:D045823",
    "name": "Ileus"
},
{
    "id": "Disease::MESH:D017543",
    "name": "Iliac Aneurysm"
},
{
    "id": "Disease::MESH:D007105",
    "name": "Immune Complex Diseases"
},
{
    "id": "Disease::MESH:D054019",
    "name": "Immune Reconstitution Inflammatory Syndrome"
},
{
    "id": "Disease::MESH:D007154",
    "name": "Immune System Diseases"
},
{
    "id": "Disease::MESH:D007119",
    "name": "Immunoblastic Lymphadenopathy"
},
{
    "id": "Disease::MESH:D007153",
    "name": "Immunologic Deficiency Syndromes"
},
{
    "id": "Disease::MESH:D007169",
    "name": "Impetigo"
},
{
    "id": "Disease::MESH:D018783",
    "name": "Impotence"
},
{
    "id": "Disease::MESH:D007177",
    "name": "Inappropriate ADH Syndrome"
},
{
    "id": "Disease::MESH:D007184",
    "name": "Incontinentia Pigmenti"
},
{
    "id": "Disease::MESH:D007222",
    "name": "Inert Gas Narcosis"
},
{
    "id": "Disease::MESH:D007235",
    "name": "Infant"
},
{
    "id": "Disease::MESH:D007232",
    "name": "Infant"
},
{
    "id": "Disease::MESH:D020243",
    "name": "Infarction"
},
{
    "id": "Disease::MESH:D020244",
    "name": "Infarction"
},
{
    "id": "Disease::MESH:D020762",
    "name": "Infarction"
},
{
    "id": "Disease::MESH:D007238",
    "name": "Infarction"
},
{
    "id": "Disease::MESH:D007239",
    "name": "Infections"
},
{
    "id": "Disease::MESH:D007244",
    "name": "Infectious Mononucleosis"
},
{
    "id": "Disease::MESH:D001351",
    "name": "Infectious bronchitis virus"
},
{
    "id": "Disease::MESH:D007246",
    "name": "Infertility"
},
{
    "id": "Disease::MESH:D007248",
    "name": "Infertility"
},
{
    "id": "Disease::MESH:D007247",
    "name": "Infertility"
},
{
    "id": "Disease::MESH:D007249",
    "name": "Inflammation"
},
{
    "id": "Disease::MESH:D015212",
    "name": "Inflammatory Bowel Diseases"
},
{
    "id": "Disease::MESH:D058922",
    "name": "Inflammatory Breast Neoplasms"
},
{
    "id": "Disease::MESH:D007251",
    "name": "Influenza"
},
{
    "id": "Disease::MESH:D009981",
    "name": "Influenza B virus"
},
{
    "id": "Disease::MESH:D005585",
    "name": "Influenza in Birds"
},
{
    "id": "Disease::MESH:D058545",
    "name": "Inhalant Abuse"
},
{
    "id": "Disease::MESH:D007299",
    "name": "Insect Bites and Stings"
},
{
    "id": "Disease::MESH:D034062",
    "name": "Insomnia"
},
{
    "id": "Disease::MESH:D007333",
    "name": "Insulin Resistance"
},
{
    "id": "Disease::MESH:D007340",
    "name": "Insulinoma"
},
{
    "id": "Disease::MESH:D008607",
    "name": "Intellectual Disability"
},
{
    "id": "Disease::MESH:D007383",
    "name": "Intermittent Claudication"
},
{
    "id": "Disease::MESH:D007402",
    "name": "Intertrigo"
},
{
    "id": "Disease::MESH:D055959",
    "name": "Intervertebral Disc Degeneration"
},
{
    "id": "Disease::MESH:D007405",
    "name": "Intervertebral Disc Displacement"
},
{
    "id": "Disease::MESH:D007409",
    "name": "Intestinal Atresia"
},
{
    "id": "Disease::MESH:D007410",
    "name": "Intestinal Diseases"
},
{
    "id": "Disease::MESH:D007412",
    "name": "Intestinal Fistula"
},
{
    "id": "Disease::MESH:D007414",
    "name": "Intestinal Neoplasms"
},
{
    "id": "Disease::MESH:D007415",
    "name": "Intestinal Obstruction"
},
{
    "id": "Disease::MESH:D044483",
    "name": "Intestinal Polyposis"
},
{
    "id": "Disease::MESH:D007417",
    "name": "Intestinal Polyps"
},
{
    "id": "Disease::MESH:D007418",
    "name": "Intestinal Pseudo-Obstruction"
},
{
    "id": "Disease::MESH:D045822",
    "name": "Intestinal Volvulus"
},
{
    "id": "Disease::MESH:D059325",
    "name": "Intra-Abdominal Hypertension"
},
{
    "id": "Disease::MESH:D059413",
    "name": "Intraabdominal Infections"
},
{
    "id": "Disease::MESH:D002532",
    "name": "Intracranial Aneurysm"
},
{
    "id": "Disease::MESH:D020765",
    "name": "Intracranial Arterial Diseases"
},
{
    "id": "Disease::MESH:D002537",
    "name": "Intracranial Arteriosclerosis"
},
{
    "id": "Disease::MESH:D002538",
    "name": "Intracranial Arteriovenous Malformations"
},
{
    "id": "Disease::MESH:D020766",
    "name": "Intracranial Embolism"
},
{
    "id": "Disease::MESH:D020299",
    "name": "Intracranial Hemorrhage"
},
{
    "id": "Disease::MESH:D020300",
    "name": "Intracranial Hemorrhages"
},
{
    "id": "Disease::MESH:D019586",
    "name": "Intracranial Hypertension"
},
{
    "id": "Disease::MESH:D020767",
    "name": "Intracranial Thrombosis"
},
{
    "id": "Disease::MESH:D064090",
    "name": "Intraocular Lymphoma"
},
{
    "id": "Disease::MESH:D007429",
    "name": "Intraocular Pressure"
},
{
    "id": "Disease::MESH:D058926",
    "name": "Intraoperative Awareness"
},
{
    "id": "Disease::MESH:D007443",
    "name": "Intussusception"
},
{
    "id": "Disease::MESH:D055744",
    "name": "Invasive Pulmonary Aspergillosis"
},
{
    "id": "Disease::MESH:D057129",
    "name": "Iridocorneal Endothelial Syndrome"
},
{
    "id": "Disease::MESH:D015863",
    "name": "Iridocyclitis"
},
{
    "id": "Disease::MESH:D007499",
    "name": "Iris Diseases"
},
{
    "id": "Disease::MESH:D007500",
    "name": "Iritis"
},
{
    "id": "Disease::MESH:D019189",
    "name": "Iron Metabolism Disorders"
},
{
    "id": "Disease::MESH:D019190",
    "name": "Iron Overload"
},
{
    "id": "Disease::MESH:D043183",
    "name": "Irritable Bowel Syndrome"
},
{
    "id": "Disease::MESH:D007508",
    "name": "Irritable Mood"
},
{
    "id": "Disease::MESH:D020386",
    "name": "Isaacs Syndrome"
},
{
    "id": "Disease::MESH:D007511",
    "name": "Ischemia"
},
{
    "id": "Disease::MESH:D002546",
    "name": "Ischemic Attack"
},
{
    "id": "Disease::MESH:D056830",
    "name": "Isolated Noncompaction of the Ventricular Myocardium"
},
{
    "id": "Disease::MESH:D021865",
    "name": "Isosporiasis"
},
{
    "id": "Disease::MESH:D054868",
    "name": "Jacobsen Distal 11q Deletion Syndrome"
},
{
    "id": "Disease::MESH:D007567",
    "name": "Jaundice"
},
{
    "id": "Disease::MESH:D041781",
    "name": "Jaundice"
},
{
    "id": "Disease::MESH:D007565",
    "name": "Jaundice"
},
{
    "id": "Disease::MESH:D007566",
    "name": "Jaundice"
},
{
    "id": "Disease::MESH:D007570",
    "name": "Jaw Cysts"
},
{
    "id": "Disease::MESH:D007571",
    "name": "Jaw Diseases"
},
{
    "id": "Disease::MESH:D007579",
    "name": "Jejunal Diseases"
},
{
    "id": "Disease::MESH:D007580",
    "name": "Jejunal Neoplasms"
},
{
    "id": "Disease::MESH:D020179",
    "name": "Jet Lag Syndrome"
},
{
    "id": "Disease::MESH:D007589",
    "name": "Job Syndrome"
},
{
    "id": "Disease::MESH:D007592",
    "name": "Joint Diseases"
},
{
    "id": "Disease::MESH:D004204",
    "name": "Joint Dislocations"
},
{
    "id": "Disease::MESH:D007593",
    "name": "Joint Instability"
},
{
    "id": "Disease::MESH:D017436",
    "name": "Kallmann Syndrome"
},
{
    "id": "Disease::MESH:D007617",
    "name": "Kaposi Varicelliform Eruption"
},
{
    "id": "Disease::MESH:D007619",
    "name": "Kartagener Syndrome"
},
{
    "id": "Disease::MESH:D059885",
    "name": "Kasabach-Merritt Syndrome"
},
{
    "id": "Disease::MESH:D057767",
    "name": "Kashin-Beck Disease"
},
{
    "id": "Disease::MESH:D007625",
    "name": "Kearns-Sayre Syndrome"
},
{
    "id": "Disease::MESH:D007627",
    "name": "Keloid"
},
{
    "id": "Disease::MESH:D016849",
    "name": "Keratitis"
},
{
    "id": "Disease::MESH:D007635",
    "name": "Keratitis"
},
{
    "id": "Disease::MESH:D007634",
    "name": "Keratitis"
},
{
    "id": "Disease::MESH:D007636",
    "name": "Keratoacanthoma"
},
{
    "id": "Disease::MESH:D007637",
    "name": "Keratoconjunctivitis"
},
{
    "id": "Disease::MESH:D007639",
    "name": "Keratoconjunctivitis"
},
{
    "id": "Disease::MESH:D007638",
    "name": "Keratoconjunctivitis Sicca"
},
{
    "id": "Disease::MESH:D007640",
    "name": "Keratoconus"
},
{
    "id": "Disease::MESH:D053546",
    "name": "Keratoderma"
},
{
    "id": "Disease::MESH:D007645",
    "name": "Keratoderma"
},
{
    "id": "Disease::MESH:D055623",
    "name": "Keratosis"
},
{
    "id": "Disease::MESH:D007642",
    "name": "Keratosis"
},
{
    "id": "Disease::MESH:D017492",
    "name": "Keratosis"
},
{
    "id": "Disease::MESH:D007647",
    "name": "Kernicterus"
},
{
    "id": "Disease::MESH:D007662",
    "name": "Ketosis"
},
{
    "id": "Disease::MESH:D007669",
    "name": "Kidney Calculi"
},
{
    "id": "Disease::MESH:D007673",
    "name": "Kidney Cortex Necrosis"
},
{
    "id": "Disease::MESH:D007674",
    "name": "Kidney Diseases"
},
{
    "id": "Disease::MESH:D052177",
    "name": "Kidney Diseases"
},
{
    "id": "Disease::MESH:D007676",
    "name": "Kidney Failure"
},
{
    "id": "Disease::MESH:D007680",
    "name": "Kidney Neoplasms"
},
{
    "id": "Disease::MESH:D007681",
    "name": "Kidney Papillary Necrosis"
},
{
    "id": "Disease::MESH:D007683",
    "name": "Kidney Tubular Necrosis"
},
{
    "id": "Disease::MESH:D007710",
    "name": "Klebsiella Infections"
},
{
    "id": "Disease::MESH:D017593",
    "name": "Kleine-Levin Syndrome"
},
{
    "id": "Disease::MESH:D007713",
    "name": "Klinefelter Syndrome"
},
{
    "id": "Disease::MESH:D007714",
    "name": "Klippel-Feil Syndrome"
},
{
    "id": "Disease::MESH:D007715",
    "name": "Klippel-Trenaunay-Weber Syndrome"
},
{
    "id": "Disease::MESH:D007718",
    "name": "Knee Injuries"
},
{
    "id": "Disease::MESH:D020915",
    "name": "Korsakoff Syndrome"
},
{
    "id": "Disease::MESH:D007732",
    "name": "Kwashiorkor"
},
{
    "id": "Disease::MESH:D007738",
    "name": "Kyphosis"
},
{
    "id": "Disease::MESH:D044542",
    "name": "LEOPARD Syndrome"
},
{
    "id": "Disease::MESH:D048949",
    "name": "Labor Pain"
},
{
    "id": "Disease::MESH:D007757",
    "name": "Laboratory Infection"
},
{
    "id": "Disease::MESH:D007759",
    "name": "Labyrinth Diseases"
},
{
    "id": "Disease::MESH:D022125",
    "name": "Lacerations"
},
{
    "id": "Disease::MESH:D007766",
    "name": "Lacrimal Apparatus Diseases"
},
{
    "id": "Disease::MESH:D007775",
    "name": "Lactation Disorders"
},
{
    "id": "Disease::MESH:D007787",
    "name": "Lactose Intolerance"
},
{
    "id": "Disease::MESH:D020192",
    "name": "Lafora Disease"
},
{
    "id": "Disease::MESH:D015624",
    "name": "Lambert-Eaton Myasthenic Syndrome"
},
{
    "id": "Disease::MESH:D007794",
    "name": "Lameness"
},
{
    "id": "Disease::MESH:D018887",
    "name": "Landau-Kleffner Syndrome"
},
{
    "id": "Disease::MESH:D015826",
    "name": "Langer-Giedion Syndrome"
},
{
    "id": "Disease::MESH:D054752",
    "name": "Langerhans Cell Sarcoma"
},
{
    "id": "Disease::MESH:D007805",
    "name": "Language Development Disorders"
},
{
    "id": "Disease::MESH:D007806",
    "name": "Language Disorders"
},
{
    "id": "Disease::MESH:D046150",
    "name": "Laron Syndrome"
},
{
    "id": "Disease::MESH:D007815",
    "name": "Larva Migrans"
},
{
    "id": "Disease::MESH:D007818",
    "name": "Laryngeal Diseases"
},
{
    "id": "Disease::MESH:D007822",
    "name": "Laryngeal Neoplasms"
},
{
    "id": "Disease::MESH:D007826",
    "name": "Laryngismus"
},
{
    "id": "Disease::MESH:D007827",
    "name": "Laryngitis"
},
{
    "id": "Disease::MESH:D057045",
    "name": "Laryngopharyngeal Reflux"
},
{
    "id": "Disease::MESH:D007829",
    "name": "Laryngostenosis"
},
{
    "id": "Disease::MESH:D007835",
    "name": "Lassa Fever"
},
{
    "id": "Disease::MESH:D055985",
    "name": "Latent Tuberculosis"
},
{
    "id": "Disease::MESH:D007842",
    "name": "Lathyrism"
},
{
    "id": "Disease::MESH:D007845",
    "name": "Laughter"
},
{
    "id": "Disease::MESH:D007855",
    "name": "Lead Poisoning"
},
{
    "id": "Disease::MESH:D007859",
    "name": "Learning Disabilities"
},
{
    "id": "Disease::MESH:D057130",
    "name": "Leber Congenital Amaurosis"
},
{
    "id": "Disease::MESH:D007863",
    "name": "Lecithin Cholesterol Acyltransferase Deficiency"
},
{
    "id": "Disease::MESH:D007869",
    "name": "Leg Injuries"
},
{
    "id": "Disease::MESH:D007870",
    "name": "Leg Length Inequality"
},
{
    "id": "Disease::MESH:D007871",
    "name": "Leg Ulcer"
},
{
    "id": "Disease::MESH:D007873",
    "name": "Legg-Calve-Perthes Disease"
},
{
    "id": "Disease::MESH:D007876",
    "name": "Legionellosis"
},
{
    "id": "Disease::MESH:D007877",
    "name": "Legionnaires' Disease"
},
{
    "id": "Disease::MESH:D007888",
    "name": "Leigh Disease"
},
{
    "id": "Disease::MESH:D007889",
    "name": "Leiomyoma"
},
{
    "id": "Disease::MESH:D018231",
    "name": "Leiomyomatosis"
},
{
    "id": "Disease::MESH:D007890",
    "name": "Leiomyosarcoma"
},
{
    "id": "Disease::MESH:D016773",
    "name": "Leishmaniasis"
},
{
    "id": "Disease::MESH:D007898",
    "name": "Leishmaniasis"
},
{
    "id": "Disease::MESH:D007897",
    "name": "Leishmaniasis"
},
{
    "id": "Disease::MESH:D007896",
    "name": "Leishmaniasis"
},
{
    "id": "Disease::MESH:D016774",
    "name": "Leishmaniasis"
},
{
    "id": "Disease::MESH:D065768",
    "name": "Lennox Gastaut Syndrome"
},
{
    "id": "Disease::MESH:D007905",
    "name": "Lens Diseases"
},
{
    "id": "Disease::MESH:D007911",
    "name": "Lentigo"
},
{
    "id": "Disease::MESH:D056006",
    "name": "Leprosy"
},
{
    "id": "Disease::MESH:D015441",
    "name": "Leprosy"
},
{
    "id": "Disease::MESH:D007918",
    "name": "Leprosy"
},
{
    "id": "Disease::MESH:D015440",
    "name": "Leprosy"
},
{
    "id": "Disease::MESH:D056005",
    "name": "Leprosy"
},
{
    "id": "Disease::MESH:D007922",
    "name": "Leptospirosis"
},
{
    "id": "Disease::MESH:D007925",
    "name": "Leriche Syndrome"
},
{
    "id": "Disease::MESH:D007926",
    "name": "Lesch-Nyhan Syndrome"
},
{
    "id": "Disease::MESH:D054066",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D016582",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015466",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D054403",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015456",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015463",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015458",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D004915",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007945",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007938",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015472",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007951",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007948",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007946",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015461",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015477",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015467",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015464",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015465",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007947",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015451",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015471",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015448",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015479",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007952",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D054438",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015470",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D015473",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007943",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D054429",
    "name": "Leukemia"
},
{
    "id": "Disease::MESH:D007939",
    "name": "Leukemia L1210"
},
{
    "id": "Disease::MESH:D007941",
    "name": "Leukemia P388"
},
{
    "id": "Disease::MESH:D015459",
    "name": "Leukemia-Lymphoma"
},
{
    "id": "Disease::MESH:D017254",
    "name": "Leukemic Infiltration"
},
{
    "id": "Disease::MESH:D049292",
    "name": "Leukoaraiosis"
},
{
    "id": "Disease::MESH:D007960",
    "name": "Leukocyte Disorders"
},
{
    "id": "Disease::MESH:D018370",
    "name": "Leukocyte-Adhesion Deficiency Syndrome"
},
{
    "id": "Disease::MESH:D007964",
    "name": "Leukocytosis"
},
{
    "id": "Disease::MESH:D007966",
    "name": "Leukodystrophy"
},
{
    "id": "Disease::MESH:D007965",
    "name": "Leukodystrophy"
},
{
    "id": "Disease::MESH:D007967",
    "name": "Leukoedema"
},
{
    "id": "Disease::MESH:D056784",
    "name": "Leukoencephalopathies"
},
{
    "id": "Disease::MESH:D007968",
    "name": "Leukoencephalopathy"
},
{
    "id": "Disease::MESH:D053529",
    "name": "Leukokeratosis"
},
{
    "id": "Disease::MESH:D007969",
    "name": "Leukomalacia"
},
{
    "id": "Disease::MESH:D007970",
    "name": "Leukopenia"
},
{
    "id": "Disease::MESH:D017733",
    "name": "Leukoplakia"
},
{
    "id": "Disease::MESH:D007972",
    "name": "Leukoplakia"
},
{
    "id": "Disease::MESH:D007971",
    "name": "Leukoplakia"
},
{
    "id": "Disease::MESH:D007973",
    "name": "Leukorrhea"
},
{
    "id": "Disease::MESH:D018921",
    "name": "Leukostasis"
},
{
    "id": "Disease::MESH:D020961",
    "name": "Lewy Body Disease"
},
{
    "id": "Disease::MESH:D007984",
    "name": "Leydig Cell Tumor"
},
{
    "id": "Disease::MESH:D016864",
    "name": "Li-Fraumeni Syndrome"
},
{
    "id": "Disease::MESH:D010373",
    "name": "Lice Infestations"
},
{
    "id": "Disease::MESH:D017676",
    "name": "Lichen Planus"
},
{
    "id": "Disease::MESH:D008010",
    "name": "Lichen Planus"
},
{
    "id": "Disease::MESH:D018459",
    "name": "Lichen Sclerosus et Atrophicus"
},
{
    "id": "Disease::MESH:D017512",
    "name": "Lichenoid Eruptions"
},
{
    "id": "Disease::MESH:D056929",
    "name": "Liddle Syndrome"
},
{
    "id": "Disease::MESH:D017880",
    "name": "Limb Deformities"
},
{
    "id": "Disease::MESH:D020363",
    "name": "Limbic Encephalitis"
},
{
    "id": "Disease::MESH:D062027",
    "name": "Linear IgA Bullous Dermatosis"
},
{
    "id": "Disease::MESH:D061222",
    "name": "Lingual Nerve Injuries"
},
{
    "id": "Disease::MESH:D052439",
    "name": "Lipid Metabolism Disorders"
},
{
    "id": "Disease::MESH:D008064",
    "name": "Lipidoses"
},
{
    "id": "Disease::MESH:D052497",
    "name": "Lipodystrophy"
},
{
    "id": "Disease::MESH:D052496",
    "name": "Lipodystrophy"
},
{
    "id": "Disease::MESH:D008060",
    "name": "Lipodystrophy"
},
{
    "id": "Disease::MESH:D008065",
    "name": "Lipoid Proteinosis of Urbach and Wiethe"
},
{
    "id": "Disease::MESH:D008067",
    "name": "Lipoma"
},
{
    "id": "Disease::MESH:D008068",
    "name": "Lipomatosis"
},
{
    "id": "Disease::MESH:D008080",
    "name": "Liposarcoma"
},
{
    "id": "Disease::MESH:D018208",
    "name": "Liposarcoma"
},
{
    "id": "Disease::MESH:D054082",
    "name": "Lissencephaly"
},
{
    "id": "Disease::MESH:D008088",
    "name": "Listeriosis"
},
{
    "id": "Disease::MESH:D020347",
    "name": "Lithiasis"
},
{
    "id": "Disease::MESH:D054068",
    "name": "Livedo Reticularis"
},
{
    "id": "Disease::MESH:D008101",
    "name": "Liver Abscess"
},
{
    "id": "Disease::MESH:D008100",
    "name": "Liver Abscess"
},
{
    "id": "Disease::MESH:D046290",
    "name": "Liver Abscess"
},
{
    "id": "Disease::MESH:D008105",
    "name": "Liver Cirrhosis"
},
{
    "id": "Disease::MESH:D008104",
    "name": "Liver Cirrhosis"
},
{
    "id": "Disease::MESH:D008103",
    "name": "Liver Cirrhosis"
},
{
    "id": "Disease::MESH:D008108",
    "name": "Liver Diseases"
},
{
    "id": "Disease::MESH:D008107",
    "name": "Liver Diseases"
},
{
    "id": "Disease::MESH:D017093",
    "name": "Liver Failure"
},
{
    "id": "Disease::MESH:D017114",
    "name": "Liver Failure"
},
{
    "id": "Disease::MESH:D008113",
    "name": "Liver Neoplasms"
},
{
    "id": "Disease::MESH:D008114",
    "name": "Liver Neoplasms"
},
{
    "id": "Disease::MESH:D055947",
    "name": "Loeys-Dietz Syndrome"
},
{
    "id": "Disease::MESH:D008118",
    "name": "Loiasis"
},
{
    "id": "Disease::MESH:D008133",
    "name": "Long QT Syndrome"
},
{
    "id": "Disease::MESH:D008141",
    "name": "Lordosis"
},
{
    "id": "Disease::MESH:D017116",
    "name": "Low Back Pain"
},
{
    "id": "Disease::MESH:D057066",
    "name": "Low Tension Glaucoma"
},
{
    "id": "Disease::MESH:D038061",
    "name": "Lower Extremity Deformities"
},
{
    "id": "Disease::MESH:D059411",
    "name": "Lower Urinary Tract Symptoms"
},
{
    "id": "Disease::MESH:D008169",
    "name": "Lung Abscess"
},
{
    "id": "Disease::MESH:D008173",
    "name": "Lung Diseases"
},
{
    "id": "Disease::MESH:D017563",
    "name": "Lung Diseases"
},
{
    "id": "Disease::MESH:D008171",
    "name": "Lung Diseases"
},
{
    "id": "Disease::MESH:D008172",
    "name": "Lung Diseases"
},
{
    "id": "Disease::MESH:D055370",
    "name": "Lung Injury"
},
{
    "id": "Disease::MESH:D008175",
    "name": "Lung Neoplasms"
},
{
    "id": "Disease::MESH:D008178",
    "name": "Lupus Erythematosus"
},
{
    "id": "Disease::MESH:D008179",
    "name": "Lupus Erythematosus"
},
{
    "id": "Disease::MESH:D008180",
    "name": "Lupus Erythematosus"
},
{
    "id": "Disease::MESH:D008181",
    "name": "Lupus Nephritis"
},
{
    "id": "Disease::MESH:D020945",
    "name": "Lupus Vasculitis"
},
{
    "id": "Disease::MESH:D008177",
    "name": "Lupus Vulgaris"
},
{
    "id": "Disease::MESH:D018311",
    "name": "Luteoma"
},
{
    "id": "Disease::MESH:D008193",
    "name": "Lyme Disease"
},
{
    "id": "Disease::MESH:D020852",
    "name": "Lyme Neuroborreliosis"
},
{
    "id": "Disease::MESH:D008199",
    "name": "Lymphadenitis"
},
{
    "id": "Disease::MESH:D008201",
    "name": "Lymphangiectasis"
},
{
    "id": "Disease::MESH:D018192",
    "name": "Lymphangioleiomyomatosis"
},
{
    "id": "Disease::MESH:D008202",
    "name": "Lymphangioma"
},
{
    "id": "Disease::MESH:D018191",
    "name": "Lymphangioma"
},
{
    "id": "Disease::MESH:D008204",
    "name": "Lymphangiosarcoma"
},
{
    "id": "Disease::MESH:D044148",
    "name": "Lymphatic Abnormalities"
},
{
    "id": "Disease::MESH:D008206",
    "name": "Lymphatic Diseases"
},
{
    "id": "Disease::MESH:D018190",
    "name": "Lymphatic Vessel Tumors"
},
{
    "id": "Disease::MESH:D008209",
    "name": "Lymphedema"
},
{
    "id": "Disease::MESH:D008216",
    "name": "Lymphocytic Choriomeningitis"
},
{
    "id": "Disease::MESH:D008218",
    "name": "Lymphocytosis"
},
{
    "id": "Disease::MESH:D008219",
    "name": "Lymphogranuloma Venereum"
},
{
    "id": "Disease::MESH:D051359",
    "name": "Lymphohistiocytosis"
},
{
    "id": "Disease::MESH:D016393",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D008228",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D016411",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D016483",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D016410",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D016403",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D017728",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D008224",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D054391",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D020522",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D054685",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D016399",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D008223",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D018442",
    "name": "Lymphoma"
},
{
    "id": "Disease::MESH:D008230",
    "name": "Lymphomatoid Granulomatosis"
},
{
    "id": "Disease::MESH:D017731",
    "name": "Lymphomatoid Papulosis"
},
{
    "id": "Disease::MESH:D008231",
    "name": "Lymphopenia"
},
{
    "id": "Disease::MESH:D008232",
    "name": "Lymphoproliferative Disorders"
},
{
    "id": "Disease::MESH:D016464",
    "name": "Lysosomal Storage Diseases"
},
{
    "id": "Disease::MESH:D017241",
    "name": "MELAS Syndrome"
},
{
    "id": "Disease::MESH:D020267",
    "name": "MPTP Poisoning"
},
{
    "id": "Disease::MESH:D017827",
    "name": "Machado-Joseph Disease"
},
{
    "id": "Disease::MESH:D055501",
    "name": "Macrophage Activation Syndrome"
},
{
    "id": "Disease::MESH:D008268",
    "name": "Macular Degeneration"
},
{
    "id": "Disease::MESH:D008269",
    "name": "Macular Edema"
},
{
    "id": "Disease::MESH:D008275",
    "name": "Magnesium Deficiency"
},
{
    "id": "Disease::MESH:D008286",
    "name": "Malabsorption Syndromes"
},
{
    "id": "Disease::MESH:D008287",
    "name": "Malacoplakia"
},
{
    "id": "Disease::MESH:D016778",
    "name": "Malaria"
},
{
    "id": "Disease::MESH:D016780",
    "name": "Malaria"
},
{
    "id": "Disease::MESH:D008288",
    "name": "Malaria"
},
{
    "id": "Disease::MESH:D016779",
    "name": "Malaria"
},
{
    "id": "Disease::MESH:D054081",
    "name": "Malformations of Cortical Development"
},
{
    "id": "Disease::MESH:D054220",
    "name": "Malformations of Cortical Development"
},
{
    "id": "Disease::MESH:D065703",
    "name": "Malformations of Cortical Development"
},
{
    "id": "Disease::MESH:D008303",
    "name": "Malignant Carcinoid Syndrome"
},
{
    "id": "Disease::MESH:D008305",
    "name": "Malignant Hyperthermia"
},
{
    "id": "Disease::MESH:D008309",
    "name": "Mallory-Weiss Syndrome"
},
{
    "id": "Disease::MESH:D044342",
    "name": "Malnutrition"
},
{
    "id": "Disease::MESH:D008311",
    "name": "Malocclusion"
},
{
    "id": "Disease::MESH:D044904",
    "name": "Mannosidase Deficiency Diseases"
},
{
    "id": "Disease::MESH:D008368",
    "name": "Mansonelliasis"
},
{
    "id": "Disease::MESH:D008375",
    "name": "Maple Syrup Urine Disease"
},
{
    "id": "Disease::MESH:D054319",
    "name": "Marchiafava-Bignami Disease"
},
{
    "id": "Disease::MESH:D008380",
    "name": "Marek Disease"
},
{
    "id": "Disease::MESH:D008382",
    "name": "Marfan Syndrome"
},
{
    "id": "Disease::MESH:D002189",
    "name": "Marijuana Abuse"
},
{
    "id": "Disease::MESH:D047508",
    "name": "Massive Hepatic Necrosis"
},
{
    "id": "Disease::MESH:D012515",
    "name": "Mast-Cell Sarcoma"
},
{
    "id": "Disease::MESH:D008413",
    "name": "Mastitis"
},
{
    "id": "Disease::MESH:D034801",
    "name": "Mastocytoma"
},
{
    "id": "Disease::MESH:D034701",
    "name": "Mastocytosis"
},
{
    "id": "Disease::MESH:D034721",
    "name": "Mastocytosis"
},
{
    "id": "Disease::MESH:D008415",
    "name": "Mastocytosis"
},
{
    "id": "Disease::MESH:D059373",
    "name": "Mastodynia"
},
{
    "id": "Disease::MESH:D008417",
    "name": "Mastoiditis"
},
{
    "id": "Disease::MESH:D008439",
    "name": "Maxillary Diseases"
},
{
    "id": "Disease::MESH:D008444",
    "name": "Maxillary Sinus Neoplasms"
},
{
    "id": "Disease::MESH:D015523",
    "name": "Maxillary Sinusitis"
},
{
    "id": "Disease::MESH:D019767",
    "name": "Maxillofacial Abnormalities"
},
{
    "id": "Disease::MESH:D008457",
    "name": "Measles"
},
{
    "id": "Disease::MESH:D008471",
    "name": "Meconium Aspiration Syndrome"
},
{
    "id": "Disease::MESH:D020423",
    "name": "Median Neuropathy"
},
{
    "id": "Disease::MESH:D008479",
    "name": "Mediastinal Neoplasms"
},
{
    "id": "Disease::MESH:D008480",
    "name": "Mediastinitis"
},
{
    "id": "Disease::MESH:D007691",
    "name": "Medullary Sponge Kidney"
},
{
    "id": "Disease::MESH:D008527",
    "name": "Medulloblastoma"
},
{
    "id": "Disease::MESH:D008532",
    "name": "Megacolon"
},
{
    "id": "Disease::MESH:D058627",
    "name": "Megalencephaly"
},
{
    "id": "Disease::MESH:D008538",
    "name": "Meige Syndrome"
},
{
    "id": "Disease::MESH:D008546",
    "name": "Melanoma"
},
{
    "id": "Disease::MESH:D018328",
    "name": "Melanoma"
},
{
    "id": "Disease::MESH:D008545",
    "name": "Melanoma"
},
{
    "id": "Disease::MESH:D008548",
    "name": "Melanosis"
},
{
    "id": "Disease::MESH:D008554",
    "name": "Melioidosis"
},
{
    "id": "Disease::MESH:D008556",
    "name": "Melkersson-Rosenthal Syndrome"
},
{
    "id": "Disease::MESH:D008557",
    "name": "Melorheostosis"
},
{
    "id": "Disease::MESH:D008569",
    "name": "Memory Disorders"
},
{
    "id": "Disease::MESH:D008575",
    "name": "Meniere Disease"
},
{
    "id": "Disease::MESH:D055756",
    "name": "Meningeal Carcinomatosis"
},
{
    "id": "Disease::MESH:D008577",
    "name": "Meningeal Neoplasms"
},
{
    "id": "Disease::MESH:D008579",
    "name": "Meningioma"
},
{
    "id": "Disease::MESH:D008582",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D008581",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D016919",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D008586",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D008584",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D008587",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D016920",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D008583",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D008585",
    "name": "Meningitis"
},
{
    "id": "Disease::MESH:D008589",
    "name": "Meningococcal Infections"
},
{
    "id": "Disease::MESH:D008590",
    "name": "Meningoencephalitis"
},
{
    "id": "Disease::MESH:D008591",
    "name": "Meningomyelocele"
},
{
    "id": "Disease::MESH:D007706",
    "name": "Menkes Kinky Hair Syndrome"
},
{
    "id": "Disease::MESH:D008594",
    "name": "Menopause"
},
{
    "id": "Disease::MESH:D008595",
    "name": "Menorrhagia"
},
{
    "id": "Disease::MESH:D008599",
    "name": "Menstruation Disturbances"
},
{
    "id": "Disease::MESH:D001523",
    "name": "Mental Disorders"
},
{
    "id": "Disease::MESH:D038901",
    "name": "Mental Retardation"
},
{
    "id": "Disease::MESH:D020262",
    "name": "Mercury Poisoning"
},
{
    "id": "Disease::MESH:D008630",
    "name": "Mercury Poisoning"
},
{
    "id": "Disease::MESH:D065666",
    "name": "Mesenteric Ischemia"
},
{
    "id": "Disease::MESH:D008654",
    "name": "Mesothelioma"
},
{
    "id": "Disease::MESH:D008659",
    "name": "Metabolic Diseases"
},
{
    "id": "Disease::MESH:D065606",
    "name": "Metabolic Side Effects of Drugs and Substances"
},
{
    "id": "Disease::MESH:D024821",
    "name": "Metabolic Syndrome"
},
{
    "id": "Disease::MESH:D008661",
    "name": "Metabolism"
},
{
    "id": "Disease::MESH:D008679",
    "name": "Metaplasia"
},
{
    "id": "Disease::MESH:D008708",
    "name": "Methemoglobinemia"
},
{
    "id": "Disease::MESH:D008796",
    "name": "Metrorrhagia"
},
{
    "id": "Disease::MESH:D054078",
    "name": "Mevalonate Kinase Deficiency"
},
{
    "id": "Disease::MESH:D008831",
    "name": "Microcephaly"
},
{
    "id": "Disease::MESH:D048629",
    "name": "Micronuclei"
},
{
    "id": "Disease::MESH:D008850",
    "name": "Microphthalmos"
},
{
    "id": "Disease::MESH:D053842",
    "name": "Microsatellite Instability"
},
{
    "id": "Disease::MESH:D055953",
    "name": "Microscopic Polyangiitis"
},
{
    "id": "Disease::MESH:D016881",
    "name": "Microsporidiosis"
},
{
    "id": "Disease::MESH:D017566",
    "name": "Microvascular Angina"
},
{
    "id": "Disease::MESH:D065207",
    "name": "Middle East Respiratory Syndrome Coronavirus"
},
{
    "id": "Disease::MESH:D008881",
    "name": "Migraine Disorders"
},
{
    "id": "Disease::MESH:D020325",
    "name": "Migraine with Aura"
},
{
    "id": "Disease::MESH:D020326",
    "name": "Migraine without Aura"
},
{
    "id": "Disease::MESH:D008883",
    "name": "Miliaria"
},
{
    "id": "Disease::MESH:D016269",
    "name": "Milk Hypersensitivity"
},
{
    "id": "Disease::MESH:D019846",
    "name": "Miller Fisher Syndrome"
},
{
    "id": "Disease::MESH:D043204",
    "name": "Mineralocorticoid Excess Syndrome"
},
{
    "id": "Disease::MESH:D015877",
    "name": "Miosis"
},
{
    "id": "Disease::MESH:D008924",
    "name": "Mite Infestations"
},
{
    "id": "Disease::MESH:D028361",
    "name": "Mitochondrial Diseases"
},
{
    "id": "Disease::MESH:D017237",
    "name": "Mitochondrial Encephalomyopathies"
},
{
    "id": "Disease::MESH:D017240",
    "name": "Mitochondrial Myopathies"
},
{
    "id": "Disease::MESH:D008944",
    "name": "Mitral Valve Insufficiency"
},
{
    "id": "Disease::MESH:D008945",
    "name": "Mitral Valve Prolapse"
},
{
    "id": "Disease::MESH:D008946",
    "name": "Mitral Valve Stenosis"
},
{
    "id": "Disease::MESH:D008947",
    "name": "Mixed Connective Tissue Disease"
},
{
    "id": "Disease::MESH:D018198",
    "name": "Mixed Tumor"
},
{
    "id": "Disease::MESH:D008976",
    "name": "Molluscum Contagiosum"
},
{
    "id": "Disease::MESH:D049328",
    "name": "Mongolian Spot"
},
{
    "id": "Disease::MESH:D056734",
    "name": "Monilethrix"
},
{
    "id": "Disease::MESH:D045908",
    "name": "Monkeypox"
},
{
    "id": "Disease::MESH:D008998",
    "name": "Monoclonal Gammopathy of Undetermined Significance"
},
{
    "id": "Disease::MESH:D019964",
    "name": "Mood Disorders"
},
{
    "id": "Disease::MESH:D048968",
    "name": "Morning Sickness"
},
{
    "id": "Disease::MESH:D009021",
    "name": "Morphine Dependence"
},
{
    "id": "Disease::MESH:D009041",
    "name": "Motion Sickness"
},
{
    "id": "Disease::MESH:D016472",
    "name": "Motor Neuron Disease"
},
{
    "id": "Disease::MESH:D019957",
    "name": "Motor Skills Disorders"
},
{
    "id": "Disease::MESH:D009059",
    "name": "Mouth Diseases"
},
{
    "id": "Disease::MESH:D009062",
    "name": "Mouth Neoplasms"
},
{
    "id": "Disease::MESH:D009069",
    "name": "Movement Disorders"
},
{
    "id": "Disease::MESH:D009072",
    "name": "Moyamoya Disease"
},
{
    "id": "Disease::MESH:D017520",
    "name": "Mucinoses"
},
{
    "id": "Disease::MESH:D000507",
    "name": "Mucinosis"
},
{
    "id": "Disease::MESH:D009078",
    "name": "Mucocele"
},
{
    "id": "Disease::MESH:D009080",
    "name": "Mucocutaneous Lymph Node Syndrome"
},
{
    "id": "Disease::MESH:D009081",
    "name": "Mucolipidoses"
},
{
    "id": "Disease::MESH:D009083",
    "name": "Mucopolysaccharidoses"
},
{
    "id": "Disease::MESH:D008059",
    "name": "Mucopolysaccharidosis I"
},
{
    "id": "Disease::MESH:D016532",
    "name": "Mucopolysaccharidosis II"
},
{
    "id": "Disease::MESH:D009084",
    "name": "Mucopolysaccharidosis III"
},
{
    "id": "Disease::MESH:D009085",
    "name": "Mucopolysaccharidosis IV"
},
{
    "id": "Disease::MESH:D009087",
    "name": "Mucopolysaccharidosis VI"
},
{
    "id": "Disease::MESH:D016538",
    "name": "Mucopolysaccharidosis VII"
},
{
    "id": "Disease::MESH:D009091",
    "name": "Mucormycosis"
},
{
    "id": "Disease::MESH:D052016",
    "name": "Mucositis"
},
{
    "id": "Disease::MESH:D055653",
    "name": "Muir-Torre Syndrome"
},
{
    "id": "Disease::MESH:D050336",
    "name": "Mulibrey Nanism"
},
{
    "id": "Disease::MESH:D057985",
    "name": "Multi-Ingredient Cold"
},
{
    "id": "Disease::MESH:D054069",
    "name": "Multiple Acyl Coenzyme A Dehydrogenase Deficiency"
},
{
    "id": "Disease::MESH:D009100",
    "name": "Multiple Carboxylase Deficiency"
},
{
    "id": "Disease::MESH:D009377",
    "name": "Multiple Endocrine Neoplasia"
},
{
    "id": "Disease::MESH:D018761",
    "name": "Multiple Endocrine Neoplasia Type 1"
},
{
    "id": "Disease::MESH:D009101",
    "name": "Multiple Myeloma"
},
{
    "id": "Disease::MESH:D009102",
    "name": "Multiple Organ Failure"
},
{
    "id": "Disease::MESH:D009103",
    "name": "Multiple Sclerosis"
},
{
    "id": "Disease::MESH:D020529",
    "name": "Multiple Sclerosis"
},
{
    "id": "Disease::MESH:D020528",
    "name": "Multiple Sclerosis"
},
{
    "id": "Disease::MESH:D052517",
    "name": "Multiple Sulfatase Deficiency Disease"
},
{
    "id": "Disease::MESH:D019578",
    "name": "Multiple System Atrophy"
},
{
    "id": "Disease::MESH:D009104",
    "name": "Multiple Trauma"
},
{
    "id": "Disease::MESH:D009107",
    "name": "Mumps"
},
{
    "id": "Disease::MESH:D016183",
    "name": "Murine Acquired Immunodeficiency Syndrome"
},
{
    "id": "Disease::MESH:D006517",
    "name": "Murine hepatitis virus"
},
{
    "id": "Disease::MESH:D009119",
    "name": "Muscle Contraction"
},
{
    "id": "Disease::MESH:D009120",
    "name": "Muscle Cramp"
},
{
    "id": "Disease::MESH:D009122",
    "name": "Muscle Hypertonia"
},
{
    "id": "Disease::MESH:D009123",
    "name": "Muscle Hypotonia"
},
{
    "id": "Disease::MESH:D019042",
    "name": "Muscle Neoplasms"
},
{
    "id": "Disease::MESH:D009127",
    "name": "Muscle Rigidity"
},
{
    "id": "Disease::MESH:D009128",
    "name": "Muscle Spasticity"
},
{
    "id": "Disease::MESH:D018908",
    "name": "Muscle Weakness"
},
{
    "id": "Disease::MESH:D009133",
    "name": "Muscular Atrophy"
},
{
    "id": "Disease::MESH:D009134",
    "name": "Muscular Atrophy"
},
{
    "id": "Disease::MESH:D009135",
    "name": "Muscular Diseases"
},
{
    "id": "Disease::MESH:D020966",
    "name": "Muscular Disorders"
},
{
    "id": "Disease::MESH:D049288",
    "name": "Muscular Dystrophies"
},
{
    "id": "Disease::MESH:D009136",
    "name": "Muscular Dystrophies"
},
{
    "id": "Disease::MESH:D020389",
    "name": "Muscular Dystrophy"
},
{
    "id": "Disease::MESH:D039141",
    "name": "Muscular Dystrophy"
},
{
    "id": "Disease::MESH:D020388",
    "name": "Muscular Dystrophy"
},
{
    "id": "Disease::MESH:D009139",
    "name": "Musculoskeletal Abnormalities"
},
{
    "id": "Disease::MESH:D009140",
    "name": "Musculoskeletal Diseases"
},
{
    "id": "Disease::MESH:D059352",
    "name": "Musculoskeletal Pain"
},
{
    "id": "Disease::MESH:D009145",
    "name": "Mushroom Poisoning"
},
{
    "id": "Disease::MESH:D009155",
    "name": "Mutism"
},
{
    "id": "Disease::MESH:D063806",
    "name": "Myalgia"
},
{
    "id": "Disease::MESH:D009157",
    "name": "Myasthenia Gravis"
},
{
    "id": "Disease::MESH:D020720",
    "name": "Myasthenia Gravis"
},
{
    "id": "Disease::MESH:D020294",
    "name": "Myasthenic Syndromes"
},
{
    "id": "Disease::MESH:D008271",
    "name": "Mycetoma"
},
{
    "id": "Disease::MESH:D009164",
    "name": "Mycobacterium Infections"
},
{
    "id": "Disease::MESH:D009165",
    "name": "Mycobacterium Infections"
},
{
    "id": "Disease::MESH:D015270",
    "name": "Mycobacterium avium-intracellulare Infection"
},
{
    "id": "Disease::MESH:D009175",
    "name": "Mycoplasma Infections"
},
{
    "id": "Disease::MESH:D009181",
    "name": "Mycoses"
},
{
    "id": "Disease::MESH:D009182",
    "name": "Mycosis Fungoides"
},
{
    "id": "Disease::MESH:D015651",
    "name": "Mycotoxicosis"
},
{
    "id": "Disease::MESH:D015878",
    "name": "Mydriasis"
},
{
    "id": "Disease::MESH:D017590",
    "name": "Myelinolysis"
},
{
    "id": "Disease::MESH:D009188",
    "name": "Myelitis"
},
{
    "id": "Disease::MESH:D009187",
    "name": "Myelitis"
},
{
    "id": "Disease::MESH:D009190",
    "name": "Myelodysplastic Syndromes"
},
{
    "id": "Disease::MESH:D018209",
    "name": "Myelolipoma"
},
{
    "id": "Disease::MESH:D009196",
    "name": "Myeloproliferative Disorders"
},
{
    "id": "Disease::MESH:D009198",
    "name": "Myiasis"
},
{
    "id": "Disease::MESH:D009203",
    "name": "Myocardial Infarction"
},
{
    "id": "Disease::MESH:D017202",
    "name": "Myocardial Ischemia"
},
{
    "id": "Disease::MESH:D015428",
    "name": "Myocardial Reperfusion Injury"
},
{
    "id": "Disease::MESH:D009204",
    "name": "Myocardial Revascularization"
},
{
    "id": "Disease::MESH:D017682",
    "name": "Myocardial Stunning"
},
{
    "id": "Disease::MESH:D009205",
    "name": "Myocarditis"
},
{
    "id": "Disease::MESH:D002527",
    "name": "Myoclonic Cerebellar Dyssynergia"
},
{
    "id": "Disease::MESH:D020191",
    "name": "Myoclonic Epilepsies"
},
{
    "id": "Disease::MESH:D020190",
    "name": "Myoclonic Epilepsy"
},
{
    "id": "Disease::MESH:D009207",
    "name": "Myoclonus"
},
{
    "id": "Disease::MESH:D009208",
    "name": "Myoepithelioma"
},
{
    "id": "Disease::MESH:D009209",
    "name": "Myofascial Pain Syndromes"
},
{
    "id": "Disease::MESH:D009212",
    "name": "Myoglobinuria"
},
{
    "id": "Disease::MESH:D020385",
    "name": "Myokymia"
},
{
    "id": "Disease::MESH:D009214",
    "name": "Myoma"
},
{
    "id": "Disease::MESH:D020914",
    "name": "Myopathies"
},
{
    "id": "Disease::MESH:D020512",
    "name": "Myopathy"
},
{
    "id": "Disease::MESH:D047728",
    "name": "Myopia"
},
{
    "id": "Disease::MESH:D009216",
    "name": "Myopia"
},
{
    "id": "Disease::MESH:D009217",
    "name": "Myosarcoma"
},
{
    "id": "Disease::MESH:D009220",
    "name": "Myositis"
},
{
    "id": "Disease::MESH:D018979",
    "name": "Myositis"
},
{
    "id": "Disease::MESH:D009221",
    "name": "Myositis Ossificans"
},
{
    "id": "Disease::MESH:D009222",
    "name": "Myotonia"
},
{
    "id": "Disease::MESH:D009224",
    "name": "Myotonia Congenita"
},
{
    "id": "Disease::MESH:D020967",
    "name": "Myotonic Disorders"
},
{
    "id": "Disease::MESH:D009223",
    "name": "Myotonic Dystrophy"
},
{
    "id": "Disease::MESH:D063371",
    "name": "Myringosclerosis"
},
{
    "id": "Disease::MESH:D009230",
    "name": "Myxedema"
},
{
    "id": "Disease::MESH:D009232",
    "name": "Myxoma"
},
{
    "id": "Disease::MESH:D009234",
    "name": "Myxomatosis"
},
{
    "id": "Disease::MESH:D009260",
    "name": "Nail Diseases"
},
{
    "id": "Disease::MESH:D009261",
    "name": "Nail-Patella Syndrome"
},
{
    "id": "Disease::MESH:D009290",
    "name": "Narcolepsy"
},
{
    "id": "Disease::MESH:D015508",
    "name": "Nasal Obstruction"
},
{
    "id": "Disease::MESH:D009298",
    "name": "Nasal Polyps"
},
{
    "id": "Disease::MESH:D061270",
    "name": "Nasal Septal Perforation"
},
{
    "id": "Disease::MESH:D009304",
    "name": "Nasopharyngitis"
},
{
    "id": "Disease::MESH:D009325",
    "name": "Nausea"
},
{
    "id": "Disease::MESH:D019838",
    "name": "Neck Injuries"
},
{
    "id": "Disease::MESH:D019547",
    "name": "Neck Pain"
},
{
    "id": "Disease::MESH:D009335",
    "name": "Necrobiosis Lipoidica"
},
{
    "id": "Disease::MESH:D009336",
    "name": "Necrosis"
},
{
    "id": "Disease::MESH:D016602",
    "name": "Needlestick Injuries"
},
{
    "id": "Disease::MESH:D058069",
    "name": "Neglected Diseases"
},
{
    "id": "Disease::MESH:D009349",
    "name": "Nematode Infections"
},
{
    "id": "Disease::MESH:D058426",
    "name": "Neointima"
},
{
    "id": "Disease::MESH:D009357",
    "name": "Neonatal Abstinence Syndrome"
},
{
    "id": "Disease::MESH:D018365",
    "name": "Neoplasm"
},
{
    "id": "Disease::MESH:D009361",
    "name": "Neoplasm Invasiveness"
},
{
    "id": "Disease::MESH:D009362",
    "name": "Neoplasm Metastasis"
},
{
    "id": "Disease::MESH:D061206",
    "name": "Neoplasm Micrometastasis"
},
{
    "id": "Disease::MESH:D009366",
    "name": "Neoplasm Seeding"
},
{
    "id": "Disease::MESH:D054219",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D009383",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D018295",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D009379",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D009369",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D016609",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D018205",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D009373",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D018297",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D018302",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D018307",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D009375",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D017169",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D009378",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D009382",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D018204",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D018213",
    "name": "Neoplasms"
},
{
    "id": "Disease::MESH:D009371",
    "name": "Neoplasms by Site"
},
{
    "id": "Disease::MESH:D009360",
    "name": "Neoplastic Cells"
},
{
    "id": "Disease::MESH:D009386",
    "name": "Neoplastic Syndromes"
},
{
    "id": "Disease::MESH:D009393",
    "name": "Nephritis"
},
{
    "id": "Disease::MESH:D009394",
    "name": "Nephritis"
},
{
    "id": "Disease::MESH:D009395",
    "name": "Nephritis"
},
{
    "id": "Disease::MESH:D009397",
    "name": "Nephrocalcinosis"
},
{
    "id": "Disease::MESH:D054989",
    "name": "Nephrogenic Fibrosing Dermopathy"
},
{
    "id": "Disease::MESH:D053040",
    "name": "Nephrolithiasis"
},
{
    "id": "Disease::MESH:D018201",
    "name": "Nephroma"
},
{
    "id": "Disease::MESH:D009400",
    "name": "Nephrosclerosis"
},
{
    "id": "Disease::MESH:D009402",
    "name": "Nephrosis"
},
{
    "id": "Disease::MESH:D009401",
    "name": "Nephrosis"
},
{
    "id": "Disease::MESH:D009404",
    "name": "Nephrotic Syndrome"
},
{
    "id": "Disease::MESH:D009408",
    "name": "Nerve Compression Syndromes"
},
{
    "id": "Disease::MESH:D009410",
    "name": "Nerve Degeneration"
},
{
    "id": "Disease::MESH:D018317",
    "name": "Nerve Sheath Neoplasms"
},
{
    "id": "Disease::MESH:D009422",
    "name": "Nervous System Diseases"
},
{
    "id": "Disease::MESH:D009421",
    "name": "Nervous System Malformations"
},
{
    "id": "Disease::MESH:D046768",
    "name": "Nesidioblastosis"
},
{
    "id": "Disease::MESH:D056770",
    "name": "Netherton Syndrome"
},
{
    "id": "Disease::MESH:D009436",
    "name": "Neural Tube Defects"
},
{
    "id": "Disease::MESH:D051474",
    "name": "Neuralgia"
},
{
    "id": "Disease::MESH:D009437",
    "name": "Neuralgia"
},
{
    "id": "Disease::MESH:D009440",
    "name": "Neurasthenia"
},
{
    "id": "Disease::MESH:D009442",
    "name": "Neurilemmoma"
},
{
    "id": "Disease::MESH:D009443",
    "name": "Neuritis"
},
{
    "id": "Disease::MESH:D054546",
    "name": "Neuroacanthocytosis"
},
{
    "id": "Disease::MESH:D020953",
    "name": "Neuroaspergillosis"
},
{
    "id": "Disease::MESH:D019150",
    "name": "Neuroaxonal Dystrophies"
},
{
    "id": "Disease::MESH:D019954",
    "name": "Neurobehavioral Manifestations"
},
{
    "id": "Disease::MESH:D009447",
    "name": "Neuroblastoma"
},
{
    "id": "Disease::MESH:D009449",
    "name": "Neurocirculatory Asthenia"
},
{
    "id": "Disease::MESH:D019965",
    "name": "Neurocognitive Disorders"
},
{
    "id": "Disease::MESH:D020752",
    "name": "Neurocutaneous Syndromes"
},
{
    "id": "Disease::MESH:D020019",
    "name": "Neurocysticercosis"
},
{
    "id": "Disease::MESH:D018306",
    "name": "Neurocytoma"
},
{
    "id": "Disease::MESH:D019636",
    "name": "Neurodegenerative Diseases"
},
{
    "id": "Disease::MESH:D009450",
    "name": "Neurodermatitis"
},
{
    "id": "Disease::MESH:D018241",
    "name": "Neuroectodermal Tumors"
},
{
    "id": "Disease::MESH:D018242",
    "name": "Neuroectodermal Tumors"
},
{
    "id": "Disease::MESH:D017599",
    "name": "Neuroectodermal Tumors"
},
{
    "id": "Disease::MESH:D018358",
    "name": "Neuroendocrine Tumors"
},
{
    "id": "Disease::MESH:D018318",
    "name": "Neurofibroma"
},
{
    "id": "Disease::MESH:D009455",
    "name": "Neurofibroma"
},
{
    "id": "Disease::MESH:D017253",
    "name": "Neurofibromatoses"
},
{
    "id": "Disease::MESH:D009456",
    "name": "Neurofibromatosis 1"
},
{
    "id": "Disease::MESH:D016518",
    "name": "Neurofibromatosis 2"
},
{
    "id": "Disease::MESH:D018319",
    "name": "Neurofibrosarcoma"
},
{
    "id": "Disease::MESH:D055496",
    "name": "Neurogenic Bowel"
},
{
    "id": "Disease::MESH:D020078",
    "name": "Neurogenic Inflammation"
},
{
    "id": "Disease::MESH:D009459",
    "name": "Neuroleptic Malignant Syndrome"
},
{
    "id": "Disease::MESH:D009461",
    "name": "Neurologic Manifestations"
},
{
    "id": "Disease::MESH:D009464",
    "name": "Neuroma"
},
{
    "id": "Disease::MESH:D009463",
    "name": "Neuroma"
},
{
    "id": "Disease::MESH:D019148",
    "name": "Neuromuscular Blockade"
},
{
    "id": "Disease::MESH:D009468",
    "name": "Neuromuscular Diseases"
},
{
    "id": "Disease::MESH:D020511",
    "name": "Neuromuscular Junction Diseases"
},
{
    "id": "Disease::MESH:D009471",
    "name": "Neuromyelitis Optica"
},
{
    "id": "Disease::MESH:D009472",
    "name": "Neuronal Ceroid-Lipofuscinoses"
},
{
    "id": "Disease::MESH:D020818",
    "name": "Neuroschistosomiasis"
},
{
    "id": "Disease::MESH:D009494",
    "name": "Neurosyphilis"
},
{
    "id": "Disease::MESH:D018321",
    "name": "Neurothekeoma"
},
{
    "id": "Disease::MESH:D009497",
    "name": "Neurotic Disorders"
},
{
    "id": "Disease::MESH:D020258",
    "name": "Neurotoxicity Syndromes"
},
{
    "id": "Disease::MESH:D009503",
    "name": "Neutropenia"
},
{
    "id": "Disease::MESH:D018329",
    "name": "Nevus"
},
{
    "id": "Disease::MESH:D018330",
    "name": "Nevus"
},
{
    "id": "Disease::MESH:D054000",
    "name": "Nevus"
},
{
    "id": "Disease::MESH:D009506",
    "name": "Nevus"
},
{
    "id": "Disease::MESH:D018332",
    "name": "Nevus"
},
{
    "id": "Disease::MESH:D009508",
    "name": "Nevus"
},
{
    "id": "Disease::MESH:D009507",
    "name": "Nevus of Ota"
},
{
    "id": "Disease::MESH:D009521",
    "name": "Newcastle Disease"
},
{
    "id": "Disease::MESH:D052536",
    "name": "Niemann-Pick Disease"
},
{
    "id": "Disease::MESH:D052556",
    "name": "Niemann-Pick Disease"
},
{
    "id": "Disease::MESH:D009542",
    "name": "Niemann-Pick Diseases"
},
{
    "id": "Disease::MESH:D009755",
    "name": "Night Blindness"
},
{
    "id": "Disease::MESH:D020184",
    "name": "Night Terrors"
},
{
    "id": "Disease::MESH:D049932",
    "name": "Nijmegen Breakage Syndrome"
},
{
    "id": "Disease::MESH:D054318",
    "name": "No-Reflow Phenomenon"
},
{
    "id": "Disease::MESH:D009617",
    "name": "Nocardia Infections"
},
{
    "id": "Disease::MESH:D059226",
    "name": "Nociceptive Pain"
},
{
    "id": "Disease::MESH:D053158",
    "name": "Nocturia"
},
{
    "id": "Disease::MESH:D053206",
    "name": "Nocturnal Enuresis"
},
{
    "id": "Disease::MESH:D020189",
    "name": "Nocturnal Myoclonus Syndrome"
},
{
    "id": "Disease::MESH:D065626",
    "name": "Non-alcoholic Fatty Liver Disease"
},
{
    "id": "Disease::MESH:D009634",
    "name": "Noonan Syndrome"
},
{
    "id": "Disease::MESH:D009668",
    "name": "Nose Diseases"
},
{
    "id": "Disease::MESH:D009759",
    "name": "Nystagmus"
},
{
    "id": "Disease::MESH:D020417",
    "name": "Nystagmus"
},
{
    "id": "Disease::MESH:D056128",
    "name": "Obesity"
},
{
    "id": "Disease::MESH:D009765",
    "name": "Obesity"
},
{
    "id": "Disease::MESH:D009767",
    "name": "Obesity"
},
{
    "id": "Disease::MESH:D010845",
    "name": "Obesity Hypoventilation Syndrome"
},
{
    "id": "Disease::MESH:D009771",
    "name": "Obsessive-Compulsive Disorder"
},
{
    "id": "Disease::MESH:D007752",
    "name": "Obstetric Labor"
},
{
    "id": "Disease::MESH:D009784",
    "name": "Occupational Diseases"
},
{
    "id": "Disease::MESH:D009794",
    "name": "Ochronosis"
},
{
    "id": "Disease::MESH:D009798",
    "name": "Ocular Hypertension"
},
{
    "id": "Disease::MESH:D015814",
    "name": "Ocular Hypotension"
},
{
    "id": "Disease::MESH:D015835",
    "name": "Ocular Motility Disorders"
},
{
    "id": "Disease::MESH:D009800",
    "name": "Oculocerebrorenal Syndrome"
},
{
    "id": "Disease::MESH:D015840",
    "name": "Oculomotor Nerve Diseases"
},
{
    "id": "Disease::MESH:D009807",
    "name": "Odontogenic Cysts"
},
{
    "id": "Disease::MESH:D009810",
    "name": "Odontoma"
},
{
    "id": "Disease::MESH:D009814",
    "name": "Oesophagostomiasis"
},
{
    "id": "Disease::MESH:D000857",
    "name": "Olfaction Disorders"
},
{
    "id": "Disease::MESH:D009837",
    "name": "Oligodendroglioma"
},
{
    "id": "Disease::MESH:D016104",
    "name": "Oligohydramnios"
},
{
    "id": "Disease::MESH:D009839",
    "name": "Oligomenorrhea"
},
{
    "id": "Disease::MESH:D009845",
    "name": "Oligospermia"
},
{
    "id": "Disease::MESH:D009846",
    "name": "Oliguria"
},
{
    "id": "Disease::MESH:D015827",
    "name": "Onchocerciasis"
},
{
    "id": "Disease::MESH:D009855",
    "name": "Onchocerciasis"
},
{
    "id": "Disease::MESH:D054039",
    "name": "Onycholysis"
},
{
    "id": "Disease::MESH:D014009",
    "name": "Onychomycosis"
},
{
    "id": "Disease::MESH:D009879",
    "name": "Ophthalmia"
},
{
    "id": "Disease::MESH:D009878",
    "name": "Ophthalmia Neonatorum"
},
{
    "id": "Disease::MESH:D009886",
    "name": "Ophthalmoplegia"
},
{
    "id": "Disease::MESH:D017246",
    "name": "Ophthalmoplegia"
},
{
    "id": "Disease::MESH:D009293",
    "name": "Opioid-Related Disorders"
},
{
    "id": "Disease::MESH:D009889",
    "name": "Opisthorchiasis"
},
{
    "id": "Disease::MESH:D009894",
    "name": "Opportunistic Infections"
},
{
    "id": "Disease::MESH:D053578",
    "name": "Opsoclonus-Myoclonus Syndrome"
},
{
    "id": "Disease::MESH:D015418",
    "name": "Optic Atrophies"
},
{
    "id": "Disease::MESH:D029241",
    "name": "Optic Atrophy"
},
{
    "id": "Disease::MESH:D009896",
    "name": "Optic Atrophy"
},
{
    "id": "Disease::MESH:D029242",
    "name": "Optic Atrophy"
},
{
    "id": "Disease::MESH:D009901",
    "name": "Optic Nerve Diseases"
},
{
    "id": "Disease::MESH:D020339",
    "name": "Optic Nerve Glioma"
},
{
    "id": "Disease::MESH:D020221",
    "name": "Optic Nerve Injuries"
},
{
    "id": "Disease::MESH:D009902",
    "name": "Optic Neuritis"
},
{
    "id": "Disease::MESH:D018917",
    "name": "Optic Neuropathy"
},
{
    "id": "Disease::MESH:D009909",
    "name": "Oral Health"
},
{
    "id": "Disease::MESH:D006472",
    "name": "Oral Hemorrhage"
},
{
    "id": "Disease::MESH:D009914",
    "name": "Oral Submucous Fibrosis"
},
{
    "id": "Disease::MESH:D019226",
    "name": "Oral Ulcer"
},
{
    "id": "Disease::MESH:D054517",
    "name": "Orbital Cellulitis"
},
{
    "id": "Disease::MESH:D009916",
    "name": "Orbital Diseases"
},
{
    "id": "Disease::MESH:D009917",
    "name": "Orbital Fractures"
},
{
    "id": "Disease::MESH:D055622",
    "name": "Orbital Myositis"
},
{
    "id": "Disease::MESH:D016727",
    "name": "Orbital Pseudotumor"
},
{
    "id": "Disease::MESH:D009920",
    "name": "Orchitis"
},
{
    "id": "Disease::MESH:D062025",
    "name": "Organophosphate Poisoning"
},
{
    "id": "Disease::MESH:D020163",
    "name": "Ornithine Carbamoyltransferase Deficiency Disease"
},
{
    "id": "Disease::MESH:D009958",
    "name": "Orofaciodigital Syndromes"
},
{
    "id": "Disease::MESH:D009959",
    "name": "Oropharyngeal Neoplasms"
},
{
    "id": "Disease::MESH:D054971",
    "name": "Orthostatic Intolerance"
},
{
    "id": "Disease::MESH:D009999",
    "name": "Ossification"
},
{
    "id": "Disease::MESH:D017887",
    "name": "Ossification of Posterior Longitudinal Ligament"
},
{
    "id": "Disease::MESH:D010000",
    "name": "Osteitis"
},
{
    "id": "Disease::MESH:D010001",
    "name": "Osteitis Deformans"
},
{
    "id": "Disease::MESH:D055013",
    "name": "Osteoarthritis"
},
{
    "id": "Disease::MESH:D015207",
    "name": "Osteoarthritis"
},
{
    "id": "Disease::MESH:D010003",
    "name": "Osteoarthritis"
},
{
    "id": "Disease::MESH:D020370",
    "name": "Osteoarthritis"
},
{
    "id": "Disease::MESH:D010004",
    "name": "Osteoarthropathy"
},
{
    "id": "Disease::MESH:D018215",
    "name": "Osteoblastoma"
},
{
    "id": "Disease::MESH:D010007",
    "name": "Osteochondritis"
},
{
    "id": "Disease::MESH:D010009",
    "name": "Osteochondrodysplasias"
},
{
    "id": "Disease::MESH:D015831",
    "name": "Osteochondroma"
},
{
    "id": "Disease::MESH:D055034",
    "name": "Osteochondrosis"
},
{
    "id": "Disease::MESH:D010013",
    "name": "Osteogenesis Imperfecta"
},
{
    "id": "Disease::MESH:D010014",
    "name": "Osteolysis"
},
{
    "id": "Disease::MESH:D010015",
    "name": "Osteolysis"
},
{
    "id": "Disease::MESH:D010017",
    "name": "Osteoma"
},
{
    "id": "Disease::MESH:D010018",
    "name": "Osteomalacia"
},
{
    "id": "Disease::MESH:D010019",
    "name": "Osteomyelitis"
},
{
    "id": "Disease::MESH:D010020",
    "name": "Osteonecrosis"
},
{
    "id": "Disease::MESH:D010022",
    "name": "Osteopetrosis"
},
{
    "id": "Disease::MESH:D054850",
    "name": "Osteophyte"
},
{
    "id": "Disease::MESH:D015663",
    "name": "Osteoporosis"
},
{
    "id": "Disease::MESH:D010024",
    "name": "Osteoporosis"
},
{
    "id": "Disease::MESH:D058866",
    "name": "Osteoporotic Fractures"
},
{
    "id": "Disease::MESH:D010025",
    "name": "Osteoradionecrosis"
},
{
    "id": "Disease::MESH:D012516",
    "name": "Osteosarcoma"
},
{
    "id": "Disease::MESH:D010026",
    "name": "Osteosclerosis"
},
{
    "id": "Disease::MESH:D010031",
    "name": "Otitis"
},
{
    "id": "Disease::MESH:D010032",
    "name": "Otitis Externa"
},
{
    "id": "Disease::MESH:D010035",
    "name": "Otitis Media"
},
{
    "id": "Disease::MESH:D010033",
    "name": "Otitis Media"
},
{
    "id": "Disease::MESH:D010034",
    "name": "Otitis Media with Effusion"
},
{
    "id": "Disease::MESH:D059249",
    "name": "Otomycosis"
},
{
    "id": "Disease::MESH:D010038",
    "name": "Otorhinolaryngologic Diseases"
},
{
    "id": "Disease::MESH:D010040",
    "name": "Otosclerosis"
},
{
    "id": "Disease::MESH:D010048",
    "name": "Ovarian Cysts"
},
{
    "id": "Disease::MESH:D010049",
    "name": "Ovarian Diseases"
},
{
    "id": "Disease::MESH:D016471",
    "name": "Ovarian Hyperstimulation Syndrome"
},
{
    "id": "Disease::MESH:D010051",
    "name": "Ovarian Neoplasms"
},
{
    "id": "Disease::MESH:D010052",
    "name": "Ovariectomy"
},
{
    "id": "Disease::MESH:D057887",
    "name": "Overbite"
},
{
    "id": "Disease::MESH:D044343",
    "name": "Overnutrition"
},
{
    "id": "Disease::MESH:D050177",
    "name": "Overweight"
},
{
    "id": "Disease::MESH:D010123",
    "name": "Oxyuriasis"
},
{
    "id": "Disease::MESH:D016878",
    "name": "POEMS Syndrome"
},
{
    "id": "Disease::MESH:D053549",
    "name": "Pachyonychia Congenita"
},
{
    "id": "Disease::MESH:D010145",
    "name": "Paget Disease"
},
{
    "id": "Disease::MESH:D010146",
    "name": "Pain"
},
{
    "id": "Disease::MESH:D010149",
    "name": "Pain"
},
{
    "id": "Disease::MESH:D018710",
    "name": "Pain Clinics"
},
{
    "id": "Disease::MESH:D000699",
    "name": "Pain Insensitivity"
},
{
    "id": "Disease::MESH:D054975",
    "name": "Pallister-Hall Syndrome"
},
{
    "id": "Disease::MESH:D010181",
    "name": "Pancreatic Cyst"
},
{
    "id": "Disease::MESH:D010182",
    "name": "Pancreatic Diseases"
},
{
    "id": "Disease::MESH:D010185",
    "name": "Pancreatic Fistula"
},
{
    "id": "Disease::MESH:D010190",
    "name": "Pancreatic Neoplasms"
},
{
    "id": "Disease::MESH:D010192",
    "name": "Pancreatic Pseudocyst"
},
{
    "id": "Disease::MESH:D010195",
    "name": "Pancreatitis"
},
{
    "id": "Disease::MESH:D050500",
    "name": "Pancreatitis"
},
{
    "id": "Disease::MESH:D019283",
    "name": "Pancreatitis"
},
{
    "id": "Disease::MESH:D055589",
    "name": "Pancreatitis"
},
{
    "id": "Disease::MESH:D019512",
    "name": "Pancreatitis"
},
{
    "id": "Disease::MESH:D010198",
    "name": "Pancytopenia"
},
{
    "id": "Disease::MESH:D016584",
    "name": "Panic Disorder"
},
{
    "id": "Disease::MESH:D015434",
    "name": "Panniculitis"
},
{
    "id": "Disease::MESH:D010201",
    "name": "Panniculitis"
},
{
    "id": "Disease::MESH:D015435",
    "name": "Panniculitis"
},
{
    "id": "Disease::MESH:D015436",
    "name": "Panniculitis"
},
{
    "id": "Disease::MESH:D006211",
    "name": "Pantothenate Kinase-Associated Neurodegeneration"
},
{
    "id": "Disease::MESH:D015864",
    "name": "Panuveitis"
},
{
    "id": "Disease::MESH:D010211",
    "name": "Papilledema"
},
{
    "id": "Disease::MESH:D018308",
    "name": "Papilloma"
},
{
    "id": "Disease::MESH:D010212",
    "name": "Papilloma"
},
{
    "id": "Disease::MESH:D020288",
    "name": "Papilloma"
},
{
    "id": "Disease::MESH:D030361",
    "name": "Papillomavirus Infections"
},
{
    "id": "Disease::MESH:D010229",
    "name": "Paracoccidioidomycosis"
},
{
    "id": "Disease::MESH:D010235",
    "name": "Paraganglioma"
},
{
    "id": "Disease::MESH:D010237",
    "name": "Paragonimiasis"
},
{
    "id": "Disease::MESH:D010245",
    "name": "Paralyses"
},
{
    "id": "Disease::MESH:D020513",
    "name": "Paralysis"
},
{
    "id": "Disease::MESH:D010243",
    "name": "Paralysis"
},
{
    "id": "Disease::MESH:D010249",
    "name": "Parametritis"
},
{
    "id": "Disease::MESH:D018184",
    "name": "Paramyxoviridae Infections"
},
{
    "id": "Disease::MESH:D010255",
    "name": "Paranasal Sinus Neoplasms"
},
{
    "id": "Disease::MESH:D020362",
    "name": "Paraneoplastic Cerebellar Degeneration"
},
{
    "id": "Disease::MESH:D009384",
    "name": "Paraneoplastic Endocrine Syndromes"
},
{
    "id": "Disease::MESH:D010257",
    "name": "Paraneoplastic Syndromes"
},
{
    "id": "Disease::MESH:D010259",
    "name": "Paranoid Disorders"
},
{
    "id": "Disease::MESH:D015493",
    "name": "Paraparesis"
},
{
    "id": "Disease::MESH:D020336",
    "name": "Paraparesis"
},
{
    "id": "Disease::MESH:D010262",
    "name": "Paraphilic Disorders"
},
{
    "id": "Disease::MESH:D010265",
    "name": "Paraproteinemias"
},
{
    "id": "Disease::MESH:D010267",
    "name": "Parapsoriasis"
},
{
    "id": "Disease::MESH:D018512",
    "name": "Parasitemia"
},
{
    "id": "Disease::MESH:D010272",
    "name": "Parasitic Diseases"
},
{
    "id": "Disease::MESH:D020447",
    "name": "Parasomnias"
},
{
    "id": "Disease::MESH:D010279",
    "name": "Parathyroid Diseases"
},
{
    "id": "Disease::MESH:D010282",
    "name": "Parathyroid Neoplasms"
},
{
    "id": "Disease::MESH:D010283",
    "name": "Paratuberculosis"
},
{
    "id": "Disease::MESH:D010284",
    "name": "Paratyphoid Fever"
},
{
    "id": "Disease::MESH:D010291",
    "name": "Paresis"
},
{
    "id": "Disease::MESH:D010292",
    "name": "Paresthesia"
},
{
    "id": "Disease::MESH:D010301",
    "name": "Parkinson Disease"
},
{
    "id": "Disease::MESH:D010302",
    "name": "Parkinson Disease"
},
{
    "id": "Disease::MESH:D010300",
    "name": "Parkinson Disease"
},
{
    "id": "Disease::MESH:D020734",
    "name": "Parkinsonian Disorders"
},
{
    "id": "Disease::MESH:D010304",
    "name": "Paronychia"
},
{
    "id": "Disease::MESH:D010309",
    "name": "Parotitis"
},
{
    "id": "Disease::MESH:D051302",
    "name": "Paroxysmal Hemicrania"
},
{
    "id": "Disease::MESH:D015868",
    "name": "Pars Planitis"
},
{
    "id": "Disease::MESH:D010319",
    "name": "Parturient Paresis"
},
{
    "id": "Disease::MESH:D010326",
    "name": "Pasteurella Infections"
},
{
    "id": "Disease::MESH:D012766",
    "name": "Pasteurellosis"
},
{
    "id": "Disease::MESH:D013568",
    "name": "Pathological Conditions"
},
{
    "id": "Disease::MESH:D021183",
    "name": "Peanut Hypersensitivity"
},
{
    "id": "Disease::MESH:D063766",
    "name": "Pediatric Obesity"
},
{
    "id": "Disease::MESH:D010374",
    "name": "Pediculus"
},
{
    "id": "Disease::MESH:D010381",
    "name": "Pelger-Huet Anomaly"
},
{
    "id": "Disease::MESH:D010382",
    "name": "Peliosis Hepatis"
},
{
    "id": "Disease::MESH:D020371",
    "name": "Pelizaeus-Merzbacher Disease"
},
{
    "id": "Disease::MESH:D010383",
    "name": "Pellagra"
},
{
    "id": "Disease::MESH:D059952",
    "name": "Pelvic Floor Disorders"
},
{
    "id": "Disease::MESH:D034161",
    "name": "Pelvic Infection"
},
{
    "id": "Disease::MESH:D000292",
    "name": "Pelvic Inflammatory Disease"
},
{
    "id": "Disease::MESH:D010386",
    "name": "Pelvic Neoplasms"
},
{
    "id": "Disease::MESH:D056887",
    "name": "Pelvic Organ Prolapse"
},
{
    "id": "Disease::MESH:D017699",
    "name": "Pelvic Pain"
},
{
    "id": "Disease::MESH:D010391",
    "name": "Pemphigoid"
},
{
    "id": "Disease::MESH:D010390",
    "name": "Pemphigoid"
},
{
    "id": "Disease::MESH:D006559",
    "name": "Pemphigoid Gestationis"
},
{
    "id": "Disease::MESH:D010392",
    "name": "Pemphigus"
},
{
    "id": "Disease::MESH:D016506",
    "name": "Pemphigus"
},
{
    "id": "Disease::MESH:D010411",
    "name": "Penile Induration"
},
{
    "id": "Disease::MESH:D010412",
    "name": "Penile Neoplasms"
},
{
    "id": "Disease::MESH:D010437",
    "name": "Peptic Ulcer"
},
{
    "id": "Disease::MESH:D010438",
    "name": "Peptic Ulcer Hemorrhage"
},
{
    "id": "Disease::MESH:D010468",
    "name": "Perceptual Disorders"
},
{
    "id": "Disease::MESH:D060805",
    "name": "Performance Anxiety"
},
{
    "id": "Disease::MESH:D057873",
    "name": "Peri-Implantitis"
},
{
    "id": "Disease::MESH:D010483",
    "name": "Periapical Diseases"
},
{
    "id": "Disease::MESH:D010484",
    "name": "Periapical Granuloma"
},
{
    "id": "Disease::MESH:D010485",
    "name": "Periapical Periodontitis"
},
{
    "id": "Disease::MESH:D010489",
    "name": "Periarthritis"
},
{
    "id": "Disease::MESH:D010490",
    "name": "Pericardial Effusion"
},
{
    "id": "Disease::MESH:D010493",
    "name": "Pericarditis"
},
{
    "id": "Disease::MESH:D010495",
    "name": "Pericarditis"
},
{
    "id": "Disease::MESH:D010494",
    "name": "Pericarditis"
},
{
    "id": "Disease::MESH:D010497",
    "name": "Pericoronitis"
},
{
    "id": "Disease::MESH:D017622",
    "name": "Periodontal Attachment Loss"
},
{
    "id": "Disease::MESH:D010509",
    "name": "Periodontal Cyst"
},
{
    "id": "Disease::MESH:D010510",
    "name": "Periodontal Diseases"
},
{
    "id": "Disease::MESH:D010518",
    "name": "Periodontitis"
},
{
    "id": "Disease::MESH:D010522",
    "name": "Periostitis"
},
{
    "id": "Disease::MESH:D058729",
    "name": "Peripheral Arterial Disease"
},
{
    "id": "Disease::MESH:D059348",
    "name": "Peripheral Nerve Injuries"
},
{
    "id": "Disease::MESH:D010523",
    "name": "Peripheral Nervous System Diseases"
},
{
    "id": "Disease::MESH:D010524",
    "name": "Peripheral Nervous System Neoplasms"
},
{
    "id": "Disease::MESH:D016491",
    "name": "Peripheral Vascular Diseases"
},
{
    "id": "Disease::MESH:D057068",
    "name": "Periprosthetic Fractures"
},
{
    "id": "Disease::MESH:D010532",
    "name": "Peritoneal Diseases"
},
{
    "id": "Disease::MESH:D056627",
    "name": "Peritoneal Fibrosis"
},
{
    "id": "Disease::MESH:D010538",
    "name": "Peritonitis"
},
{
    "id": "Disease::MESH:D014395",
    "name": "Peritonitis"
},
{
    "id": "Disease::MESH:D000039",
    "name": "Peritonsillar Abscess"
},
{
    "id": "Disease::MESH:D054973",
    "name": "Perivascular Epithelioid Cell Neoplasms"
},
{
    "id": "Disease::MESH:D054091",
    "name": "Periventricular Nodular Heterotopia"
},
{
    "id": "Disease::MESH:D018901",
    "name": "Peroxisomal Disorders"
},
{
    "id": "Disease::MESH:D018923",
    "name": "Persian Gulf Syndrome"
},
{
    "id": "Disease::MESH:D010547",
    "name": "Persistent Fetal Circulation Syndrome"
},
{
    "id": "Disease::MESH:D018458",
    "name": "Persistent Vegetative State"
},
{
    "id": "Disease::MESH:D010554",
    "name": "Personality Disorders"
},
{
    "id": "Disease::MESH:D059270",
    "name": "Petrositis"
},
{
    "id": "Disease::MESH:D010580",
    "name": "Peutz-Jeghers Syndrome"
},
{
    "id": "Disease::MESH:D060446",
    "name": "Phaeohyphomycosis"
},
{
    "id": "Disease::MESH:D010585",
    "name": "Phagocyte Bactericidal Dysfunction"
},
{
    "id": "Disease::MESH:D010591",
    "name": "Phantom Limb"
},
{
    "id": "Disease::MESH:D010610",
    "name": "Pharyngeal Neoplasms"
},
{
    "id": "Disease::MESH:D010612",
    "name": "Pharyngitis"
},
{
    "id": "Disease::MESH:D010623",
    "name": "Phencyclidine Abuse"
},
{
    "id": "Disease::MESH:D010661",
    "name": "Phenylketonurias"
},
{
    "id": "Disease::MESH:D010673",
    "name": "Pheochromocytoma"
},
{
    "id": "Disease::MESH:D010677",
    "name": "Philadelphia Chromosome"
},
{
    "id": "Disease::MESH:D010688",
    "name": "Phimosis"
},
{
    "id": "Disease::MESH:D010689",
    "name": "Phlebitis"
},
{
    "id": "Disease::MESH:D010698",
    "name": "Phobic Disorders"
},
{
    "id": "Disease::MESH:D020795",
    "name": "Photophobia"
},
{
    "id": "Disease::MESH:D010787",
    "name": "Photosensitivity Disorders"
},
{
    "id": "Disease::MESH:D020061",
    "name": "Phthirus"
},
{
    "id": "Disease::MESH:D003557",
    "name": "Phyllodes Tumor"
},
{
    "id": "Disease::MESH:D010842",
    "name": "Pica"
},
{
    "id": "Disease::MESH:D020774",
    "name": "Pick Disease of the Brain"
},
{
    "id": "Disease::MESH:D016116",
    "name": "Piebaldism"
},
{
    "id": "Disease::MESH:D010855",
    "name": "Pierre Robin Syndrome"
},
{
    "id": "Disease::MESH:D010859",
    "name": "Pigmentation Disorders"
},
{
    "id": "Disease::MESH:D018296",
    "name": "Pilomatrixoma"
},
{
    "id": "Disease::MESH:D010864",
    "name": "Pilonidal Sinus"
},
{
    "id": "Disease::MESH:D010871",
    "name": "Pinealoma"
},
{
    "id": "Disease::MESH:D059407",
    "name": "Pinguecula"
},
{
    "id": "Disease::MESH:D010874",
    "name": "Pinta"
},
{
    "id": "Disease::MESH:D047748",
    "name": "Pituitary ACTH Hypersecretion"
},
{
    "id": "Disease::MESH:D010899",
    "name": "Pituitary Apoplexy"
},
{
    "id": "Disease::MESH:D010900",
    "name": "Pituitary Diseases"
},
{
    "id": "Disease::MESH:D010911",
    "name": "Pituitary Neoplasms"
},
{
    "id": "Disease::MESH:D010915",
    "name": "Pityriasis"
},
{
    "id": "Disease::MESH:D017514",
    "name": "Pityriasis Lichenoides"
},
{
    "id": "Disease::MESH:D017515",
    "name": "Pityriasis Rosea"
},
{
    "id": "Disease::MESH:D010916",
    "name": "Pityriasis Rubra Pilaris"
},
{
    "id": "Disease::MESH:D018457",
    "name": "Placenta"
},
{
    "id": "Disease::MESH:D010921",
    "name": "Placenta Accreta"
},
{
    "id": "Disease::MESH:D010922",
    "name": "Placenta Diseases"
},
{
    "id": "Disease::MESH:D010923",
    "name": "Placenta Previa"
},
{
    "id": "Disease::MESH:D010927",
    "name": "Placental Insufficiency"
},
{
    "id": "Disease::MESH:D010930",
    "name": "Plague"
},
{
    "id": "Disease::MESH:D010939",
    "name": "Plant Poisoning"
},
{
    "id": "Disease::MESH:D058226",
    "name": "Plaque"
},
{
    "id": "Disease::MESH:D058225",
    "name": "Plaque"
},
{
    "id": "Disease::MESH:D010954",
    "name": "Plasmacytoma"
},
{
    "id": "Disease::MESH:D010974",
    "name": "Platelet Aggregation"
},
{
    "id": "Disease::MESH:D010985",
    "name": "Platybasia"
},
{
    "id": "Disease::MESH:D010995",
    "name": "Pleural Diseases"
},
{
    "id": "Disease::MESH:D016066",
    "name": "Pleural Effusion"
},
{
    "id": "Disease::MESH:D010996",
    "name": "Pleural Effusion"
},
{
    "id": "Disease::MESH:D010998",
    "name": "Pleurisy"
},
{
    "id": "Disease::MESH:D011004",
    "name": "Plummer-Vinson Syndrome"
},
{
    "id": "Disease::MESH:D011006",
    "name": "Pneumatosis Cystoides Intestinalis"
},
{
    "id": "Disease::MESH:D011007",
    "name": "Pneumocephalus"
},
{
    "id": "Disease::MESH:D011008",
    "name": "Pneumococcal Infections"
},
{
    "id": "Disease::MESH:D011009",
    "name": "Pneumoconiosis"
},
{
    "id": "Disease::MESH:D016720",
    "name": "Pneumocystis Infections"
},
{
    "id": "Disease::MESH:D011023",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D011014",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D053717",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D011020",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D011015",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D011018",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D011019",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D011017",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D018410",
    "name": "Pneumonia"
},
{
    "id": "Disease::MESH:D045729",
    "name": "Pneumonia of Swine"
},
{
    "id": "Disease::MESH:D011027",
    "name": "Pneumoperitoneum"
},
{
    "id": "Disease::MESH:D011030",
    "name": "Pneumothorax"
},
{
    "id": "Disease::MESH:D011041",
    "name": "Poisoning"
},
{
    "id": "Disease::MESH:D011051",
    "name": "Poliomyelitis"
},
{
    "id": "Disease::MESH:D010488",
    "name": "Polyarteritis Nodosa"
},
{
    "id": "Disease::MESH:D011081",
    "name": "Polychondritis"
},
{
    "id": "Disease::MESH:D017044",
    "name": "Polycystic Kidney"
},
{
    "id": "Disease::MESH:D016891",
    "name": "Polycystic Kidney"
},
{
    "id": "Disease::MESH:D007690",
    "name": "Polycystic Kidney Diseases"
},
{
    "id": "Disease::MESH:D011085",
    "name": "Polycystic Ovary Syndrome"
},
{
    "id": "Disease::MESH:D011086",
    "name": "Polycythemia"
},
{
    "id": "Disease::MESH:D011087",
    "name": "Polycythemia Vera"
},
{
    "id": "Disease::MESH:D017689",
    "name": "Polydactyly"
},
{
    "id": "Disease::MESH:D059606",
    "name": "Polydipsia"
},
{
    "id": "Disease::MESH:D016884",
    "name": "Polyendocrinopathies"
},
{
    "id": "Disease::MESH:D006831",
    "name": "Polyhydramnios"
},
{
    "id": "Disease::MESH:D065706",
    "name": "Polymicrogyria"
},
{
    "id": "Disease::MESH:D011111",
    "name": "Polymyalgia Rheumatica"
},
{
    "id": "Disease::MESH:D017285",
    "name": "Polymyositis"
},
{
    "id": "Disease::MESH:D011115",
    "name": "Polyneuropathies"
},
{
    "id": "Disease::MESH:D011123",
    "name": "Polyploidy"
},
{
    "id": "Disease::MESH:D011127",
    "name": "Polyps"
},
{
    "id": "Disease::MESH:D011129",
    "name": "Polyradiculoneuropathy"
},
{
    "id": "Disease::MESH:D020277",
    "name": "Polyradiculoneuropathy"
},
{
    "id": "Disease::MESH:D011128",
    "name": "Polyradiculopathy"
},
{
    "id": "Disease::MESH:D011141",
    "name": "Polyuria"
},
{
    "id": "Disease::MESH:D019318",
    "name": "Porcine Reproductive and Respiratory Syndrome"
},
{
    "id": "Disease::MESH:D065708",
    "name": "Porencephaly"
},
{
    "id": "Disease::MESH:D017499",
    "name": "Porokeratosis"
},
{
    "id": "Disease::MESH:D057091",
    "name": "Poroma"
},
{
    "id": "Disease::MESH:D017118",
    "name": "Porphyria"
},
{
    "id": "Disease::MESH:D046350",
    "name": "Porphyria"
},
{
    "id": "Disease::MESH:D017092",
    "name": "Porphyria"
},
{
    "id": "Disease::MESH:D017119",
    "name": "Porphyria Cutanea Tarda"
},
{
    "id": "Disease::MESH:D011164",
    "name": "Porphyrias"
},
{
    "id": "Disease::MESH:D017094",
    "name": "Porphyrias"
},
{
    "id": "Disease::MESH:D019339",
    "name": "Port-Wine Stain"
},
{
    "id": "Disease::MESH:D018467",
    "name": "Positive-Pressure Respiration"
},
{
    "id": "Disease::MESH:D038223",
    "name": "Post-Concussion Syndrome"
},
{
    "id": "Disease::MESH:D051299",
    "name": "Post-Dural Puncture Headache"
},
{
    "id": "Disease::MESH:D051298",
    "name": "Post-Traumatic Headache"
},
{
    "id": "Disease::MESH:D057851",
    "name": "Posterior Capsular Rupture"
},
{
    "id": "Disease::MESH:D054038",
    "name": "Posterior Leukoencephalopathy Syndrome"
},
{
    "id": "Disease::MESH:D011183",
    "name": "Postoperative Complications"
},
{
    "id": "Disease::MESH:D019106",
    "name": "Postoperative Hemorrhage"
},
{
    "id": "Disease::MESH:D020250",
    "name": "Postoperative Nausea and Vomiting"
},
{
    "id": "Disease::MESH:D006473",
    "name": "Postpartum Hemorrhage"
},
{
    "id": "Disease::MESH:D050032",
    "name": "Postpartum Thyroiditis"
},
{
    "id": "Disease::MESH:D011185",
    "name": "Postpericardiotomy Syndrome"
},
{
    "id": "Disease::MESH:D016262",
    "name": "Postpoliomyelitis Syndrome"
},
{
    "id": "Disease::MESH:D054972",
    "name": "Postural Orthostatic Tachycardia Syndrome"
},
{
    "id": "Disease::MESH:D019449",
    "name": "Pouchitis"
},
{
    "id": "Disease::MESH:D031261",
    "name": "Poult Enteritis Mortality Syndrome"
},
{
    "id": "Disease::MESH:D011213",
    "name": "Poxviridae Infections"
},
{
    "id": "Disease::MESH:D011218",
    "name": "Prader-Willi Syndrome"
},
{
    "id": "Disease::MESH:D011225",
    "name": "Pre-Eclampsia"
},
{
    "id": "Disease::MESH:D011226",
    "name": "Pre-Excitation Syndromes"
},
{
    "id": "Disease::MESH:D011230",
    "name": "Precancerous Conditions"
},
{
    "id": "Disease::MESH:D015452",
    "name": "Precursor B-Cell Lymphoblastic Leukemia-Lymphoma"
},
{
    "id": "Disease::MESH:D054198",
    "name": "Precursor Cell Lymphoblastic Leukemia-Lymphoma"
},
{
    "id": "Disease::MESH:D054218",
    "name": "Precursor T-Cell Lymphoblastic Leukemia-Lymphoma"
},
{
    "id": "Disease::MESH:D011236",
    "name": "Prediabetic State"
},
{
    "id": "Disease::MESH:D011274",
    "name": "Pregnancy"
},
{
    "id": "Disease::MESH:D011247",
    "name": "Pregnancy"
},
{
    "id": "Disease::MESH:D065173",
    "name": "Pregnancy"
},
{
    "id": "Disease::MESH:D011271",
    "name": "Pregnancy"
},
{
    "id": "Disease::MESH:D011254",
    "name": "Pregnancy in Diabetics"
},
{
    "id": "Disease::MESH:D058246",
    "name": "Prehypertension"
},
{
    "id": "Disease::MESH:D011289",
    "name": "Preleukemia"
},
{
    "id": "Disease::MESH:D047928",
    "name": "Premature Birth"
},
{
    "id": "Disease::MESH:D061686",
    "name": "Premature Ejaculation"
},
{
    "id": "Disease::MESH:D065446",
    "name": "Premenstrual Dysphoric Disorder"
},
{
    "id": "Disease::MESH:D011293",
    "name": "Premenstrual Syndrome"
},
{
    "id": "Disease::MESH:D011297",
    "name": "Prenatal Exposure Delayed Effects"
},
{
    "id": "Disease::MESH:D011305",
    "name": "Presbyopia"
},
{
    "id": "Disease::MESH:D003668",
    "name": "Pressure Ulcer"
},
{
    "id": "Disease::MESH:D011317",
    "name": "Priapism"
},
{
    "id": "Disease::MESH:D054969",
    "name": "Primary Dysautonomias"
},
{
    "id": "Disease::MESH:D055031",
    "name": "Primary Graft Dysfunction"
},
{
    "id": "Disease::MESH:D000081207",
    "name": "Primary Immunodeficiency Diseases"
},
{
    "id": "Disease::MESH:D055728",
    "name": "Primary Myelofibrosis"
},
{
    "id": "Disease::MESH:D016649",
    "name": "Primary Ovarian Insufficiency"
},
{
    "id": "Disease::MESH:D017096",
    "name": "Prion Diseases"
},
{
    "id": "Disease::MESH:D011349",
    "name": "Proctitis"
},
{
    "id": "Disease::MESH:D011350",
    "name": "Proctocolitis"
},
{
    "id": "Disease::MESH:D062706",
    "name": "Prodromal Symptoms"
},
{
    "id": "Disease::MESH:D011371",
    "name": "Progeria"
},
{
    "id": "Disease::MESH:D015175",
    "name": "Prolactinoma"
},
{
    "id": "Disease::MESH:D056732",
    "name": "Prolidase Deficiency"
},
{
    "id": "Disease::MESH:D056693",
    "name": "Propionic Acidemia"
},
{
    "id": "Disease::MESH:D020238",
    "name": "Prosopagnosia"
},
{
    "id": "Disease::MESH:D011469",
    "name": "Prostatic Diseases"
},
{
    "id": "Disease::MESH:D011470",
    "name": "Prostatic Hyperplasia"
},
{
    "id": "Disease::MESH:D019048",
    "name": "Prostatic Intraepithelial Neoplasia"
},
{
    "id": "Disease::MESH:D011471",
    "name": "Prostatic Neoplasms"
},
{
    "id": "Disease::MESH:D064129",
    "name": "Prostatic Neoplasms"
},
{
    "id": "Disease::MESH:D053448",
    "name": "Prostatism"
},
{
    "id": "Disease::MESH:D011472",
    "name": "Prostatitis"
},
{
    "id": "Disease::MESH:D011475",
    "name": "Prosthesis Failure"
},
{
    "id": "Disease::MESH:D020151",
    "name": "Protein C Deficiency"
},
{
    "id": "Disease::MESH:D011488",
    "name": "Protein Deficiency"
},
{
    "id": "Disease::MESH:D018455",
    "name": "Protein S Deficiency"
},
{
    "id": "Disease::MESH:D011502",
    "name": "Protein-Energy Malnutrition"
},
{
    "id": "Disease::MESH:D011504",
    "name": "Protein-Losing Enteropathies"
},
{
    "id": "Disease::MESH:D011507",
    "name": "Proteinuria"
},
{
    "id": "Disease::MESH:D057165",
    "name": "Proteostasis Deficiencies"
},
{
    "id": "Disease::MESH:D016715",
    "name": "Proteus Syndrome"
},
{
    "id": "Disease::MESH:D046351",
    "name": "Protoporphyria"
},
{
    "id": "Disease::MESH:D011528",
    "name": "Protozoan Infections"
},
{
    "id": "Disease::MESH:D011536",
    "name": "Prurigo"
},
{
    "id": "Disease::MESH:D011537",
    "name": "Pruritus"
},
{
    "id": "Disease::MESH:D011538",
    "name": "Pruritus Ani"
},
{
    "id": "Disease::MESH:D011539",
    "name": "Pruritus Vulvae"
},
{
    "id": "Disease::MESH:D011542",
    "name": "Pseudarthrosis"
},
{
    "id": "Disease::MESH:D011546",
    "name": "Pseudohypoaldosteronism"
},
{
    "id": "Disease::MESH:D011547",
    "name": "Pseudohypoparathyroidism"
},
{
    "id": "Disease::MESH:D019310",
    "name": "Pseudolymphoma"
},
{
    "id": "Disease::MESH:D011552",
    "name": "Pseudomonas Infections"
},
{
    "id": "Disease::MESH:D011553",
    "name": "Pseudomyxoma Peritonei"
},
{
    "id": "Disease::MESH:D011556",
    "name": "Pseudopseudohypoparathyroidism"
},
{
    "id": "Disease::MESH:D011559",
    "name": "Pseudotumor Cerebri"
},
{
    "id": "Disease::MESH:D011561",
    "name": "Pseudoxanthoma Elasticum"
},
{
    "id": "Disease::MESH:D009956",
    "name": "Psittacosis"
},
{
    "id": "Disease::MESH:D016659",
    "name": "Psoas Abscess"
},
{
    "id": "Disease::MESH:D011565",
    "name": "Psoriasis"
},
{
    "id": "Disease::MESH:D011595",
    "name": "Psychomotor Agitation"
},
{
    "id": "Disease::MESH:D011596",
    "name": "Psychomotor Disorders"
},
{
    "id": "Disease::MESH:D011602",
    "name": "Psychophysiologic Disorders"
},
{
    "id": "Disease::MESH:D011605",
    "name": "Psychoses"
},
{
    "id": "Disease::MESH:D011618",
    "name": "Psychotic Disorders"
},
{
    "id": "Disease::MESH:D011628",
    "name": "Puberty"
},
{
    "id": "Disease::MESH:D011629",
    "name": "Puberty"
},
{
    "id": "Disease::MESH:D011649",
    "name": "Pulmonary Alveolar Proteinosis"
},
{
    "id": "Disease::MESH:D055732",
    "name": "Pulmonary Aspergillosis"
},
{
    "id": "Disease::MESH:D001261",
    "name": "Pulmonary Atelectasis"
},
{
    "id": "Disease::MESH:D018633",
    "name": "Pulmonary Atresia"
},
{
    "id": "Disease::MESH:D018202",
    "name": "Pulmonary Blastoma"
},
{
    "id": "Disease::MESH:D029424",
    "name": "Pulmonary Disease"
},
{
    "id": "Disease::MESH:D011654",
    "name": "Pulmonary Edema"
},
{
    "id": "Disease::MESH:D011655",
    "name": "Pulmonary Embolism"
},
{
    "id": "Disease::MESH:D011656",
    "name": "Pulmonary Emphysema"
},
{
    "id": "Disease::MESH:D011657",
    "name": "Pulmonary Eosinophilia"
},
{
    "id": "Disease::MESH:D011658",
    "name": "Pulmonary Fibrosis"
},
{
    "id": "Disease::MESH:D011660",
    "name": "Pulmonary Heart Disease"
},
{
    "id": "Disease::MESH:D047868",
    "name": "Pulmonary Sclerosing Hemangioma"
},
{
    "id": "Disease::MESH:D011662",
    "name": "Pulmonary Subvalvular Stenosis"
},
{
    "id": "Disease::MESH:D011665",
    "name": "Pulmonary Valve Insufficiency"
},
{
    "id": "Disease::MESH:D011666",
    "name": "Pulmonary Valve Stenosis"
},
{
    "id": "Disease::MESH:D011668",
    "name": "Pulmonary Veno-Occlusive Disease"
},
{
    "id": "Disease::MESH:D011671",
    "name": "Pulpitis"
},
{
    "id": "Disease::MESH:D011681",
    "name": "Pupil Disorders"
},
{
    "id": "Disease::MESH:D011686",
    "name": "Purine-Pyrimidine Metabolism"
},
{
    "id": "Disease::MESH:D016553",
    "name": "Purpura"
},
{
    "id": "Disease::MESH:D011696",
    "name": "Purpura"
},
{
    "id": "Disease::MESH:D011697",
    "name": "Purpura"
},
{
    "id": "Disease::MESH:D011693",
    "name": "Purpura"
},
{
    "id": "Disease::MESH:D055665",
    "name": "Purpura Fulminans"
},
{
    "id": "Disease::MESH:D020146",
    "name": "Putaminal Hemorrhage"
},
{
    "id": "Disease::MESH:D058631",
    "name": "Pycnodysostosis"
},
{
    "id": "Disease::MESH:D011702",
    "name": "Pyelitis"
},
{
    "id": "Disease::MESH:D011704",
    "name": "Pyelonephritis"
},
{
    "id": "Disease::MESH:D011707",
    "name": "Pyloric Stenosis"
},
{
    "id": "Disease::MESH:D046248",
    "name": "Pyloric Stenosis"
},
{
    "id": "Disease::MESH:D011711",
    "name": "Pyoderma"
},
{
    "id": "Disease::MESH:D017511",
    "name": "Pyoderma Gangrenosum"
},
{
    "id": "Disease::MESH:D055112",
    "name": "Pyometra"
},
{
    "id": "Disease::MESH:D015324",
    "name": "Pyruvate Carboxylase Deficiency Disease"
},
{
    "id": "Disease::MESH:D015325",
    "name": "Pyruvate Dehydrogenase Complex Deficiency Disease"
},
{
    "id": "Disease::MESH:D011776",
    "name": "Pyuria"
},
{
    "id": "Disease::MESH:D011778",
    "name": "Q Fever"
},
{
    "id": "Disease::MESH:D011782",
    "name": "Quadriplegia"
},
{
    "id": "Disease::MESH:D020187",
    "name": "REM Sleep Behavior Disorder"
},
{
    "id": "Disease::MESH:D012327",
    "name": "RNA Virus Infections"
},
{
    "id": "Disease::MESH:D011818",
    "name": "Rabies"
},
{
    "id": "Disease::MESH:D020425",
    "name": "Radial Neuropathy"
},
{
    "id": "Disease::MESH:D011832",
    "name": "Radiation Injuries"
},
{
    "id": "Disease::MESH:D017564",
    "name": "Radiation Pneumonitis"
},
{
    "id": "Disease::MESH:D011842",
    "name": "Radicular Cyst"
},
{
    "id": "Disease::MESH:D011843",
    "name": "Radiculopathy"
},
{
    "id": "Disease::MESH:D011855",
    "name": "Radiodermatitis"
},
{
    "id": "Disease::MESH:D011900",
    "name": "Ranula"
},
{
    "id": "Disease::MESH:D035583",
    "name": "Rare Diseases"
},
{
    "id": "Disease::MESH:D011906",
    "name": "Rat-Bite Fever"
},
{
    "id": "Disease::MESH:D011928",
    "name": "Raynaud Disease"
},
{
    "id": "Disease::MESH:D012004",
    "name": "Rectal Neoplasms"
},
{
    "id": "Disease::MESH:D012008",
    "name": "Recurrence"
},
{
    "id": "Disease::MESH:D012010",
    "name": "Red-Cell Aplasia"
},
{
    "id": "Disease::MESH:D012021",
    "name": "Reflex"
},
{
    "id": "Disease::MESH:D012019",
    "name": "Reflex Sympathetic Dystrophy"
},
{
    "id": "Disease::MESH:D012030",
    "name": "Refractive Errors"
},
{
    "id": "Disease::MESH:D012035",
    "name": "Refsum Disease"
},
{
    "id": "Disease::MESH:D012061",
    "name": "Relapsing Fever"
},
{
    "id": "Disease::MESH:D000608",
    "name": "Renal Aminoacidurias"
},
{
    "id": "Disease::MESH:D012078",
    "name": "Renal Artery Obstruction"
},
{
    "id": "Disease::MESH:D056844",
    "name": "Renal Colic"
},
{
    "id": "Disease::MESH:D051437",
    "name": "Renal Insufficiency"
},
{
    "id": "Disease::MESH:D051436",
    "name": "Renal Insufficiency"
},
{
    "id": "Disease::MESH:D059228",
    "name": "Renal Nutcracker Syndrome"
},
{
    "id": "Disease::MESH:D015499",
    "name": "Renal Tubular Transport"
},
{
    "id": "Disease::MESH:D012088",
    "name": "Reoviridae Infections"
},
{
    "id": "Disease::MESH:D015427",
    "name": "Reperfusion Injury"
},
{
    "id": "Disease::MESH:D060737",
    "name": "Reproductive Tract Infections"
},
{
    "id": "Disease::MESH:D012120",
    "name": "Respiration Disorders"
},
{
    "id": "Disease::MESH:D053120",
    "name": "Respiratory Aspiration"
},
{
    "id": "Disease::MESH:D063466",
    "name": "Respiratory Aspiration of Gastric Contents"
},
{
    "id": "Disease::MESH:D012128",
    "name": "Respiratory Distress Syndrome"
},
{
    "id": "Disease::MESH:D012130",
    "name": "Respiratory Hypersensitivity"
},
{
    "id": "Disease::MESH:D012131",
    "name": "Respiratory Insufficiency"
},
{
    "id": "Disease::MESH:D012133",
    "name": "Respiratory Paralysis"
},
{
    "id": "Disease::MESH:D018357",
    "name": "Respiratory Syncytial Virus Infections"
},
{
    "id": "Disease::MESH:D015619",
    "name": "Respiratory System Abnormalities"
},
{
    "id": "Disease::MESH:D012140",
    "name": "Respiratory Tract Diseases"
},
{
    "id": "Disease::MESH:D012141",
    "name": "Respiratory Tract Infections"
},
{
    "id": "Disease::MESH:D066066",
    "name": "Response Evaluation Criteria in Solid Tumors"
},
{
    "id": "Disease::MESH:D012148",
    "name": "Restless Legs Syndrome"
},
{
    "id": "Disease::MESH:D045262",
    "name": "Reticulocytosis"
},
{
    "id": "Disease::MESH:D015356",
    "name": "Retinal Artery Occlusion"
},
{
    "id": "Disease::MESH:D012162",
    "name": "Retinal Degeneration"
},
{
    "id": "Disease::MESH:D012163",
    "name": "Retinal Detachment"
},
{
    "id": "Disease::MESH:D012164",
    "name": "Retinal Diseases"
},
{
    "id": "Disease::MESH:D015593",
    "name": "Retinal Drusen"
},
{
    "id": "Disease::MESH:D015792",
    "name": "Retinal Dysplasia"
},
{
    "id": "Disease::MESH:D058499",
    "name": "Retinal Dystrophies"
},
{
    "id": "Disease::MESH:D015882",
    "name": "Retinal Necrosis Syndrome"
},
{
    "id": "Disease::MESH:D019572",
    "name": "Retinal Neoplasms"
},
{
    "id": "Disease::MESH:D015861",
    "name": "Retinal Neovascularization"
},
{
    "id": "Disease::MESH:D012167",
    "name": "Retinal Perforations"
},
{
    "id": "Disease::MESH:D058456",
    "name": "Retinal Telangiectasis"
},
{
    "id": "Disease::MESH:D031300",
    "name": "Retinal Vasculitis"
},
{
    "id": "Disease::MESH:D012170",
    "name": "Retinal Vein Occlusion"
},
{
    "id": "Disease::MESH:D012173",
    "name": "Retinitis"
},
{
    "id": "Disease::MESH:D012174",
    "name": "Retinitis Pigmentosa"
},
{
    "id": "Disease::MESH:D012175",
    "name": "Retinoblastoma"
},
{
    "id": "Disease::MESH:D012178",
    "name": "Retinopathy of Prematurity"
},
{
    "id": "Disease::MESH:D041441",
    "name": "Retinoschisis"
},
{
    "id": "Disease::MESH:D012183",
    "name": "Retrograde Degeneration"
},
{
    "id": "Disease::MESH:D012185",
    "name": "Retroperitoneal Fibrosis"
},
{
    "id": "Disease::MESH:D012186",
    "name": "Retroperitoneal Neoplasms"
},
{
    "id": "Disease::MESH:D012192",
    "name": "Retroviridae Infections"
},
{
    "id": "Disease::MESH:D015518",
    "name": "Rett Syndrome"
},
{
    "id": "Disease::MESH:D012202",
    "name": "Reye Syndrome"
},
{
    "id": "Disease::MESH:D018335",
    "name": "Rhabdoid Tumor"
},
{
    "id": "Disease::MESH:D012206",
    "name": "Rhabdomyolysis"
},
{
    "id": "Disease::MESH:D012207",
    "name": "Rhabdomyoma"
},
{
    "id": "Disease::MESH:D018233",
    "name": "Rhabdomyosarcoma"
},
{
    "id": "Disease::MESH:D012208",
    "name": "Rhabdomyosarcoma"
},
{
    "id": "Disease::MESH:D018232",
    "name": "Rhabdomyosarcoma"
},
{
    "id": "Disease::MESH:D012216",
    "name": "Rheumatic Diseases"
},
{
    "id": "Disease::MESH:D012213",
    "name": "Rheumatic Fever"
},
{
    "id": "Disease::MESH:D012214",
    "name": "Rheumatic Heart Disease"
},
{
    "id": "Disease::MESH:D012218",
    "name": "Rheumatoid Nodule"
},
{
    "id": "Disease::MESH:D056653",
    "name": "Rheumatoid Vasculitis"
},
{
    "id": "Disease::MESH:D012220",
    "name": "Rhinitis"
},
{
    "id": "Disease::MESH:D012221",
    "name": "Rhinitis"
},
{
    "id": "Disease::MESH:D012223",
    "name": "Rhinitis"
},
{
    "id": "Disease::MESH:D006255",
    "name": "Rhinitis"
},
{
    "id": "Disease::MESH:D065631",
    "name": "Rhinitis"
},
{
    "id": "Disease::MESH:D012224",
    "name": "Rhinophyma"
},
{
    "id": "Disease::MESH:D012226",
    "name": "Rhinoscleroma"
},
{
    "id": "Disease::MESH:D012227",
    "name": "Rhinosporidiosis"
},
{
    "id": "Disease::MESH:D012257",
    "name": "Riboflavin Deficiency"
},
{
    "id": "Disease::MESH:D063730",
    "name": "Rickets"
},
{
    "id": "Disease::MESH:D012279",
    "name": "Rickets"
},
{
    "id": "Disease::MESH:D012282",
    "name": "Rickettsia Infections"
},
{
    "id": "Disease::MESH:D012298",
    "name": "Rigor Mortis"
},
{
    "id": "Disease::MESH:D012303",
    "name": "Ring Chromosomes"
},
{
    "id": "Disease::MESH:D012373",
    "name": "Rocky Mountain Spotted Fever"
},
{
    "id": "Disease::MESH:D029597",
    "name": "Romano-Ward Syndrome"
},
{
    "id": "Disease::MESH:D012393",
    "name": "Rosacea"
},
{
    "id": "Disease::MESH:D012400",
    "name": "Rotavirus Infections"
},
{
    "id": "Disease::MESH:D011038",
    "name": "Rothmund-Thomson Syndrome"
},
{
    "id": "Disease::MESH:D012409",
    "name": "Rubella"
},
{
    "id": "Disease::MESH:D012415",
    "name": "Rubinstein-Taybi Syndrome"
},
{
    "id": "Disease::MESH:D012421",
    "name": "Rupture"
},
{
    "id": "Disease::MESH:D012422",
    "name": "Rupture"
},
{
    "id": "Disease::MESH:D045473",
    "name": "SARS Virus"
},
{
    "id": "Disease::SARS-CoV2 E",
    "name": "SARS-CoV2 E"
},
{
    "id": "Disease::SARS-CoV2 M",
    "name": "SARS-CoV2 M"
},
{
    "id": "Disease::SARS-CoV2 N",
    "name": "SARS-CoV2 N"
},
{
    "id": "Disease::SARS-CoV2 Spike",
    "name": "SARS-CoV2 Spike"
},
{
    "id": "Disease::SARS-CoV2 nsp1",
    "name": "SARS-CoV2 nsp1"
},
{
    "id": "Disease::SARS-CoV2 nsp10",
    "name": "SARS-CoV2 nsp10"
},
{
    "id": "Disease::SARS-CoV2 nsp11",
    "name": "SARS-CoV2 nsp11"
},
{
    "id": "Disease::SARS-CoV2 nsp12",
    "name": "SARS-CoV2 nsp12"
},
{
    "id": "Disease::SARS-CoV2 nsp13",
    "name": "SARS-CoV2 nsp13"
},
{
    "id": "Disease::SARS-CoV2 nsp14",
    "name": "SARS-CoV2 nsp14"
},
{
    "id": "Disease::SARS-CoV2 nsp15",
    "name": "SARS-CoV2 nsp15"
},
{
    "id": "Disease::SARS-CoV2 nsp2",
    "name": "SARS-CoV2 nsp2"
},
{
    "id": "Disease::SARS-CoV2 nsp4",
    "name": "SARS-CoV2 nsp4"
},
{
    "id": "Disease::SARS-CoV2 nsp5",
    "name": "SARS-CoV2 nsp5"
},
{
    "id": "Disease::SARS-CoV2 nsp5_C145A",
    "name": "SARS-CoV2 nsp5_C145A"
},
{
    "id": "Disease::SARS-CoV2 nsp6",
    "name": "SARS-CoV2 nsp6"
},
{
    "id": "Disease::SARS-CoV2 nsp7",
    "name": "SARS-CoV2 nsp7"
},
{
    "id": "Disease::SARS-CoV2 nsp8",
    "name": "SARS-CoV2 nsp8"
},
{
    "id": "Disease::SARS-CoV2 nsp9",
    "name": "SARS-CoV2 nsp9"
},
{
    "id": "Disease::MESH:D050798",
    "name": "SUNCT Syndrome"
},
{
    "id": "Disease::MESH:D058566",
    "name": "Sacroiliitis"
},
{
    "id": "Disease::MESH:D012466",
    "name": "Salivary Gland Diseases"
},
{
    "id": "Disease::MESH:D012468",
    "name": "Salivary Gland Neoplasms"
},
{
    "id": "Disease::MESH:D012472",
    "name": "Salivation"
},
{
    "id": "Disease::MESH:D012475",
    "name": "Salmonella"
},
{
    "id": "Disease::MESH:D012480",
    "name": "Salmonella Infections"
},
{
    "id": "Disease::MESH:D012488",
    "name": "Salpingitis"
},
{
    "id": "Disease::MESH:D012497",
    "name": "Sandhoff Disease"
},
{
    "id": "Disease::MESH:D012507",
    "name": "Sarcoidosis"
},
{
    "id": "Disease::MESH:D017565",
    "name": "Sarcoidosis"
},
{
    "id": "Disease::MESH:D012514",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D018227",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D012509",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D023981",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D018234",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D012517",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D018203",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D013584",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D001357",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D012512",
    "name": "Sarcoma"
},
{
    "id": "Disease::MESH:D055948",
    "name": "Sarcopenia"
},
{
    "id": "Disease::MESH:D012532",
    "name": "Scabies"
},
{
    "id": "Disease::MESH:D012536",
    "name": "Scalp Dermatoses"
},
{
    "id": "Disease::MESH:D012541",
    "name": "Scarlet Fever"
},
{
    "id": "Disease::MESH:D012552",
    "name": "Schistosomiasis"
},
{
    "id": "Disease::MESH:D012553",
    "name": "Schistosomiasis haematobia"
},
{
    "id": "Disease::MESH:D012554",
    "name": "Schistosomiasis japonica"
},
{
    "id": "Disease::MESH:D012555",
    "name": "Schistosomiasis mansoni"
},
{
    "id": "Disease::MESH:D065707",
    "name": "Schizencephaly"
},
{
    "id": "Disease::MESH:D012559",
    "name": "Schizophrenia"
},
{
    "id": "Disease::MESH:D012563",
    "name": "Schizophrenia"
},
{
    "id": "Disease::MESH:D012561",
    "name": "Schizophrenia"
},
{
    "id": "Disease::MESH:D012562",
    "name": "Schizophrenia"
},
{
    "id": "Disease::MESH:D012560",
    "name": "Schizophrenia"
},
{
    "id": "Disease::MESH:D012569",
    "name": "Schizotypal Personality Disorder"
},
{
    "id": "Disease::MESH:D019873",
    "name": "Schnitzler Syndrome"
},
{
    "id": "Disease::MESH:D020426",
    "name": "Sciatic Neuropathy"
},
{
    "id": "Disease::MESH:D012585",
    "name": "Sciatica"
},
{
    "id": "Disease::MESH:D012592",
    "name": "Scleredema Adultorum"
},
{
    "id": "Disease::MESH:D012593",
    "name": "Sclerema Neonatorum"
},
{
    "id": "Disease::MESH:D015423",
    "name": "Scleritis"
},
{
    "id": "Disease::MESH:D012595",
    "name": "Scleroderma"
},
{
    "id": "Disease::MESH:D012594",
    "name": "Scleroderma"
},
{
    "id": "Disease::MESH:D045743",
    "name": "Scleroderma"
},
{
    "id": "Disease::MESH:D053718",
    "name": "Scleromyxedema"
},
{
    "id": "Disease::MESH:D012598",
    "name": "Sclerosis"
},
{
    "id": "Disease::MESH:D012600",
    "name": "Scoliosis"
},
{
    "id": "Disease::MESH:D012607",
    "name": "Scotoma"
},
{
    "id": "Disease::MESH:D012608",
    "name": "Scrapie"
},
{
    "id": "Disease::MESH:D012612",
    "name": "Scrub Typhus"
},
{
    "id": "Disease::MESH:D012614",
    "name": "Scurvy"
},
{
    "id": "Disease::MESH:D016574",
    "name": "Seasonal Affective Disorder"
},
{
    "id": "Disease::MESH:D003294",
    "name": "Seizures"
},
{
    "id": "Disease::MESH:D012640",
    "name": "Seizures"
},
{
    "id": "Disease::MESH:D016728",
    "name": "Self-Injurious Behavior"
},
{
    "id": "Disease::MESH:D018239",
    "name": "Seminoma"
},
{
    "id": "Disease::MESH:D012678",
    "name": "Sensation Disorders"
},
{
    "id": "Disease::MESH:D018805",
    "name": "Sepsis"
},
{
    "id": "Disease::MESH:D065166",
    "name": "Sepsis-Associated Encephalopathy"
},
{
    "id": "Disease::MESH:D025962",
    "name": "Septo-Optic Dysplasia"
},
{
    "id": "Disease::MESH:D020230",
    "name": "Serotonin Syndrome"
},
{
    "id": "Disease::MESH:D016868",
    "name": "Serratia Infections"
},
{
    "id": "Disease::MESH:D054331",
    "name": "Sertoli Cell-Only Syndrome"
},
{
    "id": "Disease::MESH:D018310",
    "name": "Sertoli-Leydig Cell Tumor"
},
{
    "id": "Disease::MESH:D012713",
    "name": "Serum Sickness"
},
{
    "id": "Disease::MESH:D045169",
    "name": "Severe Acute Respiratory Syndrome"
},
{
    "id": "Disease::MESH:D016511",
    "name": "Severe Combined Immunodeficiency"
},
{
    "id": "Disease::MESH:D019595",
    "name": "Severe Dengue"
},
{
    "id": "Disease::MESH:D018312",
    "name": "Sex Cord-Gonadal Stromal Tumors"
},
{
    "id": "Disease::MESH:D012735",
    "name": "Sexual Dysfunction"
},
{
    "id": "Disease::MESH:D020018",
    "name": "Sexual Dysfunctions"
},
{
    "id": "Disease::MESH:D019968",
    "name": "Sexual and Gender Disorders"
},
{
    "id": "Disease::MESH:D012749",
    "name": "Sexually Transmitted Diseases"
},
{
    "id": "Disease::MESH:D012751",
    "name": "Sezary Syndrome"
},
{
    "id": "Disease::MESH:D012753",
    "name": "Shared Paranoid Disorder"
},
{
    "id": "Disease::MESH:D057096",
    "name": "Shellfish Poisoning"
},
{
    "id": "Disease::MESH:D012760",
    "name": "Shigella"
},
{
    "id": "Disease::MESH:D012774",
    "name": "Shock"
},
{
    "id": "Disease::MESH:D012770",
    "name": "Shock"
},
{
    "id": "Disease::MESH:D012769",
    "name": "Shock"
},
{
    "id": "Disease::MESH:D012771",
    "name": "Shock"
},
{
    "id": "Disease::MESH:D012772",
    "name": "Shock"
},
{
    "id": "Disease::MESH:D012778",
    "name": "Short Bowel Syndrome"
},
{
    "id": "Disease::MESH:D012779",
    "name": "Short Rib-Polydactyly Syndrome"
},
{
    "id": "Disease::MESH:D012783",
    "name": "Shoulder Dislocation"
},
{
    "id": "Disease::MESH:D019534",
    "name": "Shoulder Impingement Syndrome"
},
{
    "id": "Disease::MESH:D020069",
    "name": "Shoulder Pain"
},
{
    "id": "Disease::MESH:D012791",
    "name": "Shy-Drager Syndrome"
},
{
    "id": "Disease::MESH:D012793",
    "name": "Sialadenitis"
},
{
    "id": "Disease::MESH:D029461",
    "name": "Sialic Acid Storage Disease"
},
{
    "id": "Disease::MESH:D012798",
    "name": "Sialorrhea"
},
{
    "id": "Disease::MESH:D018877",
    "name": "Sick Building Syndrome"
},
{
    "id": "Disease::MESH:D012804",
    "name": "Sick Sinus Syndrome"
},
{
    "id": "Disease::MESH:D012805",
    "name": "Sickle Cell Trait"
},
{
    "id": "Disease::MESH:D012806",
    "name": "Siderosis"
},
{
    "id": "Disease::MESH:D012817",
    "name": "Signs and Symptoms"
},
{
    "id": "Disease::MESH:D012818",
    "name": "Signs and Symptoms"
},
{
    "id": "Disease::MESH:D012829",
    "name": "Silicosis"
},
{
    "id": "Disease::MESH:D056730",
    "name": "Silver-Russell Syndrome"
},
{
    "id": "Disease::MESH:D018139",
    "name": "Simplexvirus"
},
{
    "id": "Disease::MESH:D058529",
    "name": "Single Umbilical Artery"
},
{
    "id": "Disease::MESH:D012848",
    "name": "Sinoatrial Block"
},
{
    "id": "Disease::MESH:D054138",
    "name": "Sinus Arrest"
},
{
    "id": "Disease::MESH:D012851",
    "name": "Sinus Thrombosis"
},
{
    "id": "Disease::MESH:D012852",
    "name": "Sinusitis"
},
{
    "id": "Disease::MESH:D058288",
    "name": "Sister Mary Joseph's Nodule"
},
{
    "id": "Disease::MESH:D012859",
    "name": "Sjogren's Syndrome"
},
{
    "id": "Disease::MESH:D016111",
    "name": "Sjogren-Larsson Syndrome"
},
{
    "id": "Disease::MESH:D015595",
    "name": "Skin Aging"
},
{
    "id": "Disease::MESH:D012872",
    "name": "Skin Diseases"
},
{
    "id": "Disease::MESH:D012871",
    "name": "Skin Diseases"
},
{
    "id": "Disease::MESH:D017444",
    "name": "Skin Diseases"
},
{
    "id": "Disease::MESH:D017192",
    "name": "Skin Diseases"
},
{
    "id": "Disease::MESH:D012878",
    "name": "Skin Neoplasms"
},
{
    "id": "Disease::MESH:D012880",
    "name": "Skin Pigmentation"
},
{
    "id": "Disease::MESH:D012883",
    "name": "Skin Ulcer"
},
{
    "id": "Disease::MESH:D019292",
    "name": "Skull Base Neoplasms"
},
{
    "id": "Disease::MESH:D020181",
    "name": "Sleep Apnea"
},
{
    "id": "Disease::MESH:D020182",
    "name": "Sleep Apnea"
},
{
    "id": "Disease::MESH:D012891",
    "name": "Sleep Apnea Syndromes"
},
{
    "id": "Disease::MESH:D020186",
    "name": "Sleep Bruxism"
},
{
    "id": "Disease::MESH:D012892",
    "name": "Sleep Deprivation"
},
{
    "id": "Disease::MESH:D020178",
    "name": "Sleep Disorders"
},
{
    "id": "Disease::MESH:D007319",
    "name": "Sleep Initiation and Maintenance Disorders"
},
{
    "id": "Disease::MESH:D012893",
    "name": "Sleep Wake Disorders"
},
{
    "id": "Disease::MESH:D020922",
    "name": "Sleep-Wake Transition Disorders"
},
{
    "id": "Disease::MESH:D000077260",
    "name": "Sleepiness"
},
{
    "id": "Disease::MESH:D056124",
    "name": "Slit Ventricle Syndrome"
},
{
    "id": "Disease::MESH:D055752",
    "name": "Small Cell Lung Carcinoma"
},
{
    "id": "Disease::MESH:D012899",
    "name": "Smallpox"
},
{
    "id": "Disease::MESH:D016369",
    "name": "Smear Layer"
},
{
    "id": "Disease::MESH:D019082",
    "name": "Smith-Lemli-Opitz Syndrome"
},
{
    "id": "Disease::MESH:D058496",
    "name": "Smith-Magenis Syndrome"
},
{
    "id": "Disease::MESH:D015208",
    "name": "Smoke Inhalation Injury"
},
{
    "id": "Disease::MESH:D018235",
    "name": "Smooth Muscle Tumor"
},
{
    "id": "Disease::MESH:D012909",
    "name": "Snake Bites"
},
{
    "id": "Disease::MESH:D018461",
    "name": "Soft Tissue Infections"
},
{
    "id": "Disease::MESH:D017695",
    "name": "Soft Tissue Injuries"
},
{
    "id": "Disease::MESH:D012983",
    "name": "Soft Tissue Neoplasms"
},
{
    "id": "Disease::MESH:D054363",
    "name": "Solitary Fibrous Tumor"
},
{
    "id": "Disease::MESH:D054364",
    "name": "Solitary Fibrous Tumors"
},
{
    "id": "Disease::MESH:D003074",
    "name": "Solitary Pulmonary Nodule"
},
{
    "id": "Disease::MESH:D013001",
    "name": "Somatoform Disorders"
},
{
    "id": "Disease::MESH:D020886",
    "name": "Somatosensory Disorders"
},
{
    "id": "Disease::MESH:D013009",
    "name": "Somnambulism"
},
{
    "id": "Disease::MESH:D058495",
    "name": "Sotos Syndrome"
},
{
    "id": "Disease::MESH:D018489",
    "name": "Space Motion Sickness"
},
{
    "id": "Disease::MESH:D013035",
    "name": "Spasm"
},
{
    "id": "Disease::MESH:D013036",
    "name": "Spasms"
},
{
    "id": "Disease::MESH:D015419",
    "name": "Spastic Paraplegia"
},
{
    "id": "Disease::MESH:D013086",
    "name": "Spermatic Cord Torsion"
},
{
    "id": "Disease::MESH:D013103",
    "name": "Spherocytosis"
},
{
    "id": "Disease::MESH:D046628",
    "name": "Sphincter of Oddi Dysfunction"
},
{
    "id": "Disease::MESH:D001098",
    "name": "Spider Bites"
},
{
    "id": "Disease::MESH:D016137",
    "name": "Spina Bifida Cystica"
},
{
    "id": "Disease::MESH:D016136",
    "name": "Spina Bifida Occulta"
},
{
    "id": "Disease::MESH:D013117",
    "name": "Spinal Cord Compression"
},
{
    "id": "Disease::MESH:D013118",
    "name": "Spinal Cord Diseases"
},
{
    "id": "Disease::MESH:D013119",
    "name": "Spinal Cord Injuries"
},
{
    "id": "Disease::MESH:D020760",
    "name": "Spinal Cord Ischemia"
},
{
    "id": "Disease::MESH:D013120",
    "name": "Spinal Cord Neoplasms"
},
{
    "id": "Disease::MESH:D016135",
    "name": "Spinal Dysraphism"
},
{
    "id": "Disease::MESH:D013124",
    "name": "Spinal Injuries"
},
{
    "id": "Disease::MESH:D014897",
    "name": "Spinal Muscular Atrophies of Childhood"
},
{
    "id": "Disease::MESH:D013130",
    "name": "Spinal Stenosis"
},
{
    "id": "Disease::MESH:D020754",
    "name": "Spinocerebellar Ataxias"
},
{
    "id": "Disease::MESH:D013132",
    "name": "Spinocerebellar Degenerations"
},
{
    "id": "Disease::MESH:D013158",
    "name": "Splenic Diseases"
},
{
    "id": "Disease::MESH:D013160",
    "name": "Splenic Neoplasms"
},
{
    "id": "Disease::MESH:D013161",
    "name": "Splenic Rupture"
},
{
    "id": "Disease::MESH:D013163",
    "name": "Splenomegaly"
},
{
    "id": "Disease::MESH:D025241",
    "name": "Spondylarthritis"
},
{
    "id": "Disease::MESH:D025242",
    "name": "Spondylarthropathies"
},
{
    "id": "Disease::MESH:D013167",
    "name": "Spondylitis"
},
{
    "id": "Disease::MESH:D013166",
    "name": "Spondylitis"
},
{
    "id": "Disease::MESH:D055009",
    "name": "Spondylosis"
},
{
    "id": "Disease::MESH:D013174",
    "name": "Sporotrichosis"
},
{
    "id": "Disease::MESH:D013180",
    "name": "Sprains and Strains"
},
{
    "id": "Disease::MESH:D013182",
    "name": "Sprue"
},
{
    "id": "Disease::MESH:D065310",
    "name": "Squamous Intraepithelial Lesions of the Cervix"
},
{
    "id": "Disease::MESH:D013203",
    "name": "Staphylococcal Infections"
},
{
    "id": "Disease::MESH:D013206",
    "name": "Staphylococcal Scalded Skin Syndrome"
},
{
    "id": "Disease::MESH:D013207",
    "name": "Staphylococcal Skin Infections"
},
{
    "id": "Disease::MESH:D013224",
    "name": "Status Asthmaticus"
},
{
    "id": "Disease::MESH:D013226",
    "name": "Status Epilepticus"
},
{
    "id": "Disease::MESH:D045602",
    "name": "Steatorrhea"
},
{
    "id": "Disease::MESH:D013262",
    "name": "Stevens-Johnson Syndrome"
},
{
    "id": "Disease::MESH:D016750",
    "name": "Stiff-Person Syndrome"
},
{
    "id": "Disease::MESH:D016706",
    "name": "Still's Disease"
},
{
    "id": "Disease::MESH:D050497",
    "name": "Stillbirth"
},
{
    "id": "Disease::MESH:D013272",
    "name": "Stomach Diseases"
},
{
    "id": "Disease::MESH:D013274",
    "name": "Stomach Neoplasms"
},
{
    "id": "Disease::MESH:D013276",
    "name": "Stomach Ulcer"
},
{
    "id": "Disease::MESH:D013280",
    "name": "Stomatitis"
},
{
    "id": "Disease::MESH:D013282",
    "name": "Stomatitis"
},
{
    "id": "Disease::MESH:D013283",
    "name": "Stomatitis"
},
{
    "id": "Disease::MESH:D013281",
    "name": "Stomatitis"
},
{
    "id": "Disease::MESH:D009057",
    "name": "Stomatognathic Diseases"
},
{
    "id": "Disease::MESH:D013285",
    "name": "Strabismus"
},
{
    "id": "Disease::MESH:D013290",
    "name": "Streptococcal Infections"
},
{
    "id": "Disease::MESH:D013313",
    "name": "Stress Disorders"
},
{
    "id": "Disease::MESH:D040701",
    "name": "Stress Disorders"
},
{
    "id": "Disease::MESH:D057896",
    "name": "Striae Distensae"
},
{
    "id": "Disease::MESH:D020955",
    "name": "Striatonigral Degeneration"
},
{
    "id": "Disease::MESH:D020521",
    "name": "Stroke"
},
{
    "id": "Disease::MESH:D013322",
    "name": "Strongyloidiasis"
},
{
    "id": "Disease::MESH:D053608",
    "name": "Stupor"
},
{
    "id": "Disease::MESH:D013341",
    "name": "Sturge-Weber Syndrome"
},
{
    "id": "Disease::MESH:D052879",
    "name": "Subacute Combined Degeneration"
},
{
    "id": "Disease::MESH:D013344",
    "name": "Subacute Sclerosing Panencephalitis"
},
{
    "id": "Disease::MESH:D013345",
    "name": "Subarachnoid Hemorrhage"
},
{
    "id": "Disease::MESH:D013352",
    "name": "Subcutaneous Emphysema"
},
{
    "id": "Disease::MESH:D013362",
    "name": "Sublingual Gland Neoplasms"
},
{
    "id": "Disease::MESH:D013364",
    "name": "Submandibular Gland Diseases"
},
{
    "id": "Disease::MESH:D015819",
    "name": "Substance Abuse"
},
{
    "id": "Disease::MESH:D013375",
    "name": "Substance Withdrawal Syndrome"
},
{
    "id": "Disease::MESH:D019966",
    "name": "Substance-Related Disorders"
},
{
    "id": "Disease::MESH:D013398",
    "name": "Sudden Infant Death"
},
{
    "id": "Disease::MESH:D013436",
    "name": "Sulfhemoglobinemia"
},
{
    "id": "Disease::MESH:D013471",
    "name": "Sunburn"
},
{
    "id": "Disease::MESH:D015163",
    "name": "Superinfection"
},
{
    "id": "Disease::MESH:D013478",
    "name": "Superior Mesenteric Artery Syndrome"
},
{
    "id": "Disease::MESH:D013494",
    "name": "Supranuclear Palsy"
},
{
    "id": "Disease::MESH:D013530",
    "name": "Surgical Wound Infection"
},
{
    "id": "Disease::MESH:D018614",
    "name": "Sweating Sickness"
},
{
    "id": "Disease::MESH:D016463",
    "name": "Sweet Syndrome"
},
{
    "id": "Disease::MESH:D013555",
    "name": "Swine Vesicular Disease"
},
{
    "id": "Disease::MESH:D013575",
    "name": "Syncope"
},
{
    "id": "Disease::MESH:D019462",
    "name": "Syncope"
},
{
    "id": "Disease::MESH:D013576",
    "name": "Syndactyly"
},
{
    "id": "Disease::MESH:D013577",
    "name": "Syndrome"
},
{
    "id": "Disease::MESH:D013580",
    "name": "Synostosis"
},
{
    "id": "Disease::MESH:D013581",
    "name": "Synovial Cyst"
},
{
    "id": "Disease::MESH:D013586",
    "name": "Synovitis"
},
{
    "id": "Disease::MESH:D013585",
    "name": "Synovitis"
},
{
    "id": "Disease::MESH:D013592",
    "name": "Syphilis"
},
{
    "id": "Disease::MESH:D013590",
    "name": "Syphilis"
},
{
    "id": "Disease::MESH:D013587",
    "name": "Syphilis"
},
{
    "id": "Disease::MESH:D018252",
    "name": "Syringoma"
},
{
    "id": "Disease::MESH:D013595",
    "name": "Syringomyelia"
},
{
    "id": "Disease::MESH:D018746",
    "name": "Systemic Inflammatory Response Syndrome"
},
{
    "id": "Disease::MESH:D056647",
    "name": "Systemic Vasculitis"
},
{
    "id": "Disease::MESH:D018344",
    "name": "T-Lymphocytopenia"
},
{
    "id": "Disease::MESH:D057177",
    "name": "TDP-43 Proteinopathies"
},
{
    "id": "Disease::MESH:D013606",
    "name": "Tabes Dorsalis"
},
{
    "id": "Disease::MESH:D013615",
    "name": "Tachycardia"
},
{
    "id": "Disease::MESH:D017180",
    "name": "Tachycardia"
},
{
    "id": "Disease::MESH:D013617",
    "name": "Tachycardia"
},
{
    "id": "Disease::MESH:D013616",
    "name": "Tachycardia"
},
{
    "id": "Disease::MESH:D054139",
    "name": "Tachycardia"
},
{
    "id": "Disease::MESH:D013610",
    "name": "Tachycardia"
},
{
    "id": "Disease::MESH:D013611",
    "name": "Tachycardia"
},
{
    "id": "Disease::MESH:D013614",
    "name": "Tachycardia"
},
{
    "id": "Disease::MESH:D059246",
    "name": "Tachypnea"
},
{
    "id": "Disease::MESH:D013622",
    "name": "Taeniasis"
},
{
    "id": "Disease::MESH:D013625",
    "name": "Takayasu Arteritis"
},
{
    "id": "Disease::MESH:D054549",
    "name": "Takotsubo Cardiomyopathy"
},
{
    "id": "Disease::MESH:D013631",
    "name": "Tangier Disease"
},
{
    "id": "Disease::MESH:D013651",
    "name": "Taste Disorders"
},
{
    "id": "Disease::MESH:D024801",
    "name": "Tauopathies"
},
{
    "id": "Disease::MESH:D049290",
    "name": "Tay-Sachs Disease"
},
{
    "id": "Disease::MESH:D013661",
    "name": "Tay-Sachs Disease"
},
{
    "id": "Disease::MESH:D013683",
    "name": "Telangiectasia"
},
{
    "id": "Disease::MESH:D013684",
    "name": "Telangiectasis"
},
{
    "id": "Disease::MESH:D013705",
    "name": "Temporomandibular Joint Disorders"
},
{
    "id": "Disease::MESH:D013706",
    "name": "Temporomandibular Joint Dysfunction Syndrome"
},
{
    "id": "Disease::MESH:D052256",
    "name": "Tendinopathy"
},
{
    "id": "Disease::MESH:D053682",
    "name": "Tendon Entrapment"
},
{
    "id": "Disease::MESH:D013708",
    "name": "Tendon Injuries"
},
{
    "id": "Disease::MESH:D013716",
    "name": "Tennis Elbow"
},
{
    "id": "Disease::MESH:D013717",
    "name": "Tenosynovitis"
},
{
    "id": "Disease::MESH:D018781",
    "name": "Tension-Type Headache"
},
{
    "id": "Disease::MESH:D018243",
    "name": "Teratocarcinoma"
},
{
    "id": "Disease::MESH:D013724",
    "name": "Teratoma"
},
{
    "id": "Disease::MESH:D013733",
    "name": "Testicular Diseases"
},
{
    "id": "Disease::MESH:D013736",
    "name": "Testicular Neoplasms"
},
{
    "id": "Disease::MESH:D013742",
    "name": "Tetanus"
},
{
    "id": "Disease::MESH:D013746",
    "name": "Tetany"
},
{
    "id": "Disease::MESH:D013771",
    "name": "Tetralogy of Fallot"
},
{
    "id": "Disease::MESH:D057891",
    "name": "Tetraploidy"
},
{
    "id": "Disease::MESH:D013786",
    "name": "Thalamic Diseases"
},
{
    "id": "Disease::MESH:D013789",
    "name": "Thalassemia"
},
{
    "id": "Disease::MESH:D013801",
    "name": "Theileriasis"
},
{
    "id": "Disease::MESH:D013832",
    "name": "Thiamine Deficiency"
},
{
    "id": "Disease::MESH:D013851",
    "name": "Thinness"
},
{
    "id": "Disease::MESH:D013896",
    "name": "Thoracic Diseases"
},
{
    "id": "Disease::MESH:D013898",
    "name": "Thoracic Injuries"
},
{
    "id": "Disease::MESH:D013901",
    "name": "Thoracic Outlet Syndrome"
},
{
    "id": "Disease::MESH:D013915",
    "name": "Thrombasthenia"
},
{
    "id": "Disease::MESH:D013919",
    "name": "Thromboangiitis Obliterans"
},
{
    "id": "Disease::MESH:D013920",
    "name": "Thrombocythemia"
},
{
    "id": "Disease::MESH:D054098",
    "name": "Thrombocytopenia"
},
{
    "id": "Disease::MESH:D013921",
    "name": "Thrombocytopenia"
},
{
    "id": "Disease::MESH:D013922",
    "name": "Thrombocytosis"
},
{
    "id": "Disease::MESH:D013923",
    "name": "Thromboembolism"
},
{
    "id": "Disease::MESH:D019851",
    "name": "Thrombophilia"
},
{
    "id": "Disease::MESH:D013924",
    "name": "Thrombophlebitis"
},
{
    "id": "Disease::MESH:D013927",
    "name": "Thrombosis"
},
{
    "id": "Disease::MESH:D057049",
    "name": "Thrombotic Microangiopathies"
},
{
    "id": "Disease::MESH:D013945",
    "name": "Thymoma"
},
{
    "id": "Disease::MESH:D013952",
    "name": "Thymus Hyperplasia"
},
{
    "id": "Disease::MESH:D013953",
    "name": "Thymus Neoplasms"
},
{
    "id": "Disease::MESH:D013955",
    "name": "Thyroglossal Cyst"
},
{
    "id": "Disease::MESH:D065646",
    "name": "Thyroid Carcinoma"
},
{
    "id": "Disease::MESH:D013958",
    "name": "Thyroid Crisis"
},
{
    "id": "Disease::MESH:D013959",
    "name": "Thyroid Diseases"
},
{
    "id": "Disease::MESH:D050033",
    "name": "Thyroid Dysgenesis"
},
{
    "id": "Disease::MESH:D018382",
    "name": "Thyroid Hormone Resistance Syndrome"
},
{
    "id": "Disease::MESH:D013964",
    "name": "Thyroid Neoplasms"
},
{
    "id": "Disease::MESH:D016606",
    "name": "Thyroid Nodule"
},
{
    "id": "Disease::MESH:D013966",
    "name": "Thyroiditis"
},
{
    "id": "Disease::MESH:D013968",
    "name": "Thyroiditis"
},
{
    "id": "Disease::MESH:D013969",
    "name": "Thyroiditis"
},
{
    "id": "Disease::MESH:D013967",
    "name": "Thyroiditis"
},
{
    "id": "Disease::MESH:D013971",
    "name": "Thyrotoxicosis"
},
{
    "id": "Disease::MESH:D013981",
    "name": "Tic Disorders"
},
{
    "id": "Disease::MESH:D013984",
    "name": "Tick Infestations"
},
{
    "id": "Disease::MESH:D017282",
    "name": "Tick-Borne Diseases"
},
{
    "id": "Disease::MESH:D020323",
    "name": "Tics"
},
{
    "id": "Disease::MESH:D013991",
    "name": "Tietze's Syndrome"
},
{
    "id": "Disease::MESH:D014005",
    "name": "Tinea"
},
{
    "id": "Disease::MESH:D014006",
    "name": "Tinea Capitis"
},
{
    "id": "Disease::MESH:D014008",
    "name": "Tinea Pedis"
},
{
    "id": "Disease::MESH:D014010",
    "name": "Tinea Versicolor"
},
{
    "id": "Disease::MESH:D014012",
    "name": "Tinnitus"
},
{
    "id": "Disease::MESH:D000267",
    "name": "Tissue Adhesions"
},
{
    "id": "Disease::MESH:D014029",
    "name": "Tobacco Use Disorder"
},
{
    "id": "Disease::MESH:D020333",
    "name": "Tolosa-Hunt Syndrome"
},
{
    "id": "Disease::MESH:D014063",
    "name": "Tongue"
},
{
    "id": "Disease::MESH:D014060",
    "name": "Tongue Diseases"
},
{
    "id": "Disease::MESH:D014062",
    "name": "Tongue Neoplasms"
},
{
    "id": "Disease::MESH:D014069",
    "name": "Tonsillitis"
},
{
    "id": "Disease::MESH:D019553",
    "name": "Tooth"
},
{
    "id": "Disease::MESH:D014096",
    "name": "Tooth"
},
{
    "id": "Disease::MESH:D014095",
    "name": "Tooth"
},
{
    "id": "Disease::MESH:D014071",
    "name": "Tooth Abnormalities"
},
{
    "id": "Disease::MESH:D019217",
    "name": "Tooth Attrition"
},
{
    "id": "Disease::MESH:D014084",
    "name": "Tooth Avulsion"
},
{
    "id": "Disease::MESH:D017001",
    "name": "Tooth Demineralization"
},
{
    "id": "Disease::MESH:D014076",
    "name": "Tooth Diseases"
},
{
    "id": "Disease::MESH:D014077",
    "name": "Tooth Erosion"
},
{
    "id": "Disease::MESH:D014078",
    "name": "Tooth Eruption"
},
{
    "id": "Disease::MESH:D016388",
    "name": "Tooth Loss"
},
{
    "id": "Disease::MESH:D014085",
    "name": "Tooth Migration"
},
{
    "id": "Disease::MESH:D057085",
    "name": "Tooth Wear"
},
{
    "id": "Disease::MESH:D014098",
    "name": "Toothache"
},
{
    "id": "Disease::MESH:D016171",
    "name": "Torsades de Pointes"
},
{
    "id": "Disease::MESH:D014103",
    "name": "Torticollis"
},
{
    "id": "Disease::MESH:D005879",
    "name": "Tourette Syndrome"
},
{
    "id": "Disease::MESH:D014115",
    "name": "Toxemia"
},
{
    "id": "Disease::MESH:D004786",
    "name": "Toxic Actions"
},
{
    "id": "Disease::MESH:D014120",
    "name": "Toxocariasis"
},
{
    "id": "Disease::MESH:D016781",
    "name": "Toxoplasmosis"
},
{
    "id": "Disease::MESH:D014125",
    "name": "Toxoplasmosis"
},
{
    "id": "Disease::MESH:D014123",
    "name": "Toxoplasmosis"
},
{
    "id": "Disease::MESH:D014126",
    "name": "Toxoplasmosis"
},
{
    "id": "Disease::MESH:D014133",
    "name": "Tracheal Diseases"
},
{
    "id": "Disease::MESH:D014135",
    "name": "Tracheal Stenosis"
},
{
    "id": "Disease::MESH:D014138",
    "name": "Tracheoesophageal Fistula"
},
{
    "id": "Disease::MESH:D055090",
    "name": "Tracheomalacia"
},
{
    "id": "Disease::MESH:D014141",
    "name": "Trachoma"
},
{
    "id": "Disease::MESH:D065227",
    "name": "Transfusion Reaction"
},
{
    "id": "Disease::MESH:D059245",
    "name": "Transient Tachypnea of the Newborn"
},
{
    "id": "Disease::MESH:D014188",
    "name": "Transposition of Great Vessels"
},
{
    "id": "Disease::MESH:D014189",
    "name": "Transsexualism"
},
{
    "id": "Disease::MESH:D020196",
    "name": "Trauma"
},
{
    "id": "Disease::MESH:D014201",
    "name": "Trematode Infections"
},
{
    "id": "Disease::MESH:D014202",
    "name": "Tremor"
},
{
    "id": "Disease::MESH:D014211",
    "name": "Treponemal Infections"
},
{
    "id": "Disease::MESH:D014219",
    "name": "Trial of Labor"
},
{
    "id": "Disease::MESH:D014235",
    "name": "Trichinellosis"
},
{
    "id": "Disease::MESH:D014245",
    "name": "Trichomonas Infections"
},
{
    "id": "Disease::MESH:D014247",
    "name": "Trichomonas Vaginitis"
},
{
    "id": "Disease::MESH:D060586",
    "name": "Trichosporonosis"
},
{
    "id": "Disease::MESH:D054463",
    "name": "Trichothiodystrophy Syndromes"
},
{
    "id": "Disease::MESH:D014256",
    "name": "Trichotillomania"
},
{
    "id": "Disease::MESH:D014257",
    "name": "Trichuriasis"
},
{
    "id": "Disease::MESH:D014262",
    "name": "Tricuspid Valve Insufficiency"
},
{
    "id": "Disease::MESH:D020433",
    "name": "Trigeminal Nerve Diseases"
},
{
    "id": "Disease::MESH:D014277",
    "name": "Trigeminal Neuralgia"
},
{
    "id": "Disease::MESH:D052582",
    "name": "Trigger Finger Disorder"
},
{
    "id": "Disease::MESH:D064726",
    "name": "Triple Negative Breast Neoplasms"
},
{
    "id": "Disease::MESH:D014313",
    "name": "Trismus"
},
{
    "id": "Disease::MESH:D020432",
    "name": "Trochlear Nerve Diseases"
},
{
    "id": "Disease::MESH:D014328",
    "name": "Trophoblastic Neoplasms"
},
{
    "id": "Disease::MESH:D018245",
    "name": "Trophoblastic Tumor"
},
{
    "id": "Disease::MESH:D014352",
    "name": "Trypanosomiasis"
},
{
    "id": "Disease::MESH:D014353",
    "name": "Trypanosomiasis"
},
{
    "id": "Disease::MESH:D016862",
    "name": "Tuberculoma"
},
{
    "id": "Disease::MESH:D014375",
    "name": "Tuberculoma"
},
{
    "id": "Disease::MESH:D014376",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014398",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014382",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014397",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014391",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014393",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014388",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014384",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014394",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014401",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014396",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D018088",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014387",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014392",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014390",
    "name": "Tuberculosis"
},
{
    "id": "Disease::MESH:D014402",
    "name": "Tuberous Sclerosis"
},
{
    "id": "Disease::MESH:D014406",
    "name": "Tularemia"
},
{
    "id": "Disease::MESH:D015275",
    "name": "Tumor Lysis Syndrome"
},
{
    "id": "Disease::MESH:D014412",
    "name": "Tumor Virus Infections"
},
{
    "id": "Disease::MESH:D014424",
    "name": "Turner Syndrome"
},
{
    "id": "Disease::MESH:D018058",
    "name": "Tympanic Membrane Perforation"
},
{
    "id": "Disease::MESH:D014435",
    "name": "Typhoid Fever"
},
{
    "id": "Disease::MESH:D014438",
    "name": "Typhus"
},
{
    "id": "Disease::MESH:D020176",
    "name": "Tyrosinemias"
},
{
    "id": "Disease::MESH:D014456",
    "name": "Ulcer"
},
{
    "id": "Disease::MESH:D017769",
    "name": "Ulnar Nerve Compression Syndromes"
},
{
    "id": "Disease::MESH:D014474",
    "name": "Unconsciousness"
},
{
    "id": "Disease::MESH:D024182",
    "name": "Uniparental Disomy"
},
{
    "id": "Disease::MESH:D020194",
    "name": "Unverricht-Lundborg Syndrome"
},
{
    "id": "Disease::MESH:D056824",
    "name": "Upper Extremity Deep Vein Thrombosis"
},
{
    "id": "Disease::MESH:D038062",
    "name": "Upper Extremity Deformities"
},
{
    "id": "Disease::MESH:D014496",
    "name": "Urachal Cyst"
},
{
    "id": "Disease::MESH:D056806",
    "name": "Urea Cycle Disorders"
},
{
    "id": "Disease::MESH:D016869",
    "name": "Ureaplasma Infections"
},
{
    "id": "Disease::MESH:D014511",
    "name": "Uremia"
},
{
    "id": "Disease::MESH:D014514",
    "name": "Ureteral Calculi"
},
{
    "id": "Disease::MESH:D014515",
    "name": "Ureteral Diseases"
},
{
    "id": "Disease::MESH:D014516",
    "name": "Ureteral Neoplasms"
},
{
    "id": "Disease::MESH:D014517",
    "name": "Ureteral Obstruction"
},
{
    "id": "Disease::MESH:D053039",
    "name": "Ureterolithiasis"
},
{
    "id": "Disease::MESH:D014522",
    "name": "Urethral Diseases"
},
{
    "id": "Disease::MESH:D014523",
    "name": "Urethral Neoplasms"
},
{
    "id": "Disease::MESH:D014524",
    "name": "Urethral Obstruction"
},
{
    "id": "Disease::MESH:D014525",
    "name": "Urethral Stricture"
},
{
    "id": "Disease::MESH:D014526",
    "name": "Urethritis"
},
{
    "id": "Disease::MESH:D001750",
    "name": "Urinary Bladder"
},
{
    "id": "Disease::MESH:D053201",
    "name": "Urinary Bladder"
},
{
    "id": "Disease::MESH:D001744",
    "name": "Urinary Bladder Calculi"
},
{
    "id": "Disease::MESH:D001745",
    "name": "Urinary Bladder Diseases"
},
{
    "id": "Disease::MESH:D001748",
    "name": "Urinary Bladder Neck Obstruction"
},
{
    "id": "Disease::MESH:D001749",
    "name": "Urinary Bladder Neoplasms"
},
{
    "id": "Disease::MESH:D014545",
    "name": "Urinary Calculi"
},
{
    "id": "Disease::MESH:D014549",
    "name": "Urinary Incontinence"
},
{
    "id": "Disease::MESH:D014550",
    "name": "Urinary Incontinence"
},
{
    "id": "Disease::MESH:D016055",
    "name": "Urinary Retention"
},
{
    "id": "Disease::MESH:D014552",
    "name": "Urinary Tract Infections"
},
{
    "id": "Disease::MESH:D014555",
    "name": "Urination Disorders"
},
{
    "id": "Disease::MESH:D014564",
    "name": "Urogenital Abnormalities"
},
{
    "id": "Disease::MESH:D014565",
    "name": "Urogenital Neoplasms"
},
{
    "id": "Disease::MESH:D052878",
    "name": "Urolithiasis"
},
{
    "id": "Disease::MESH:D014570",
    "name": "Urologic Diseases"
},
{
    "id": "Disease::MESH:D014571",
    "name": "Urologic Neoplasms"
},
{
    "id": "Disease::MESH:D014581",
    "name": "Urticaria"
},
{
    "id": "Disease::MESH:D014582",
    "name": "Urticaria Pigmentosa"
},
{
    "id": "Disease::MESH:D052245",
    "name": "Usher Syndromes"
},
{
    "id": "Disease::MESH:D002581",
    "name": "Uterine Cervical Incompetence"
},
{
    "id": "Disease::MESH:D002583",
    "name": "Uterine Cervical Neoplasms"
},
{
    "id": "Disease::MESH:D002575",
    "name": "Uterine Cervicitis"
},
{
    "id": "Disease::MESH:D014590",
    "name": "Uterine Contraction"
},
{
    "id": "Disease::MESH:D014591",
    "name": "Uterine Diseases"
},
{
    "id": "Disease::MESH:D014592",
    "name": "Uterine Hemorrhage"
},
{
    "id": "Disease::MESH:D014593",
    "name": "Uterine Inertia"
},
{
    "id": "Disease::MESH:D014594",
    "name": "Uterine Neoplasms"
},
{
    "id": "Disease::MESH:D060725",
    "name": "Uterine Retroversion"
},
{
    "id": "Disease::MESH:D014597",
    "name": "Uterine Rupture"
},
{
    "id": "Disease::MESH:D015866",
    "name": "Uveitis"
},
{
    "id": "Disease::MESH:D015867",
    "name": "Uveitis"
},
{
    "id": "Disease::MESH:D014606",
    "name": "Uveitis"
},
{
    "id": "Disease::MESH:D014605",
    "name": "Uveitis"
},
{
    "id": "Disease::MESH:D014607",
    "name": "Uveomeningoencephalitic Syndrome"
},
{
    "id": "Disease::MESH:D019522",
    "name": "Vaginal Discharge"
},
{
    "id": "Disease::MESH:D014623",
    "name": "Vaginal Diseases"
},
{
    "id": "Disease::MESH:D014625",
    "name": "Vaginal Neoplasms"
},
{
    "id": "Disease::MESH:D014627",
    "name": "Vaginitis"
},
{
    "id": "Disease::MESH:D016585",
    "name": "Vaginosis"
},
{
    "id": "Disease::MESH:D014647",
    "name": "Varicose Ulcer"
},
{
    "id": "Disease::MESH:D014648",
    "name": "Varicose Veins"
},
{
    "id": "Disease::MESH:D061205",
    "name": "Vascular Calcification"
},
{
    "id": "Disease::MESH:D014652",
    "name": "Vascular Diseases"
},
{
    "id": "Disease::MESH:D014653",
    "name": "Vascular Headaches"
},
{
    "id": "Disease::MESH:D054079",
    "name": "Vascular Malformations"
},
{
    "id": "Disease::MESH:D019043",
    "name": "Vascular Neoplasms"
},
{
    "id": "Disease::MESH:D066253",
    "name": "Vascular Remodeling"
},
{
    "id": "Disease::MESH:D057772",
    "name": "Vascular System Injuries"
},
{
    "id": "Disease::MESH:D020293",
    "name": "Vasculitis"
},
{
    "id": "Disease::MESH:D014657",
    "name": "Vasculitis"
},
{
    "id": "Disease::MESH:D018366",
    "name": "Vasculitis"
},
{
    "id": "Disease::MESH:D056987",
    "name": "Vasoplegia"
},
{
    "id": "Disease::MESH:D020301",
    "name": "Vasospasm"
},
{
    "id": "Disease::MESH:D054080",
    "name": "Vein of Galen Malformations"
},
{
    "id": "Disease::MESH:D014685",
    "name": "Venereal Tumors"
},
{
    "id": "Disease::MESH:D014689",
    "name": "Venous Insufficiency"
},
{
    "id": "Disease::MESH:D054556",
    "name": "Venous Thromboembolism"
},
{
    "id": "Disease::MESH:D020246",
    "name": "Venous Thrombosis"
},
{
    "id": "Disease::MESH:D055397",
    "name": "Ventilator-Induced Lung Injury"
},
{
    "id": "Disease::MESH:D018497",
    "name": "Ventricular Dysfunction"
},
{
    "id": "Disease::MESH:D018754",
    "name": "Ventricular Dysfunction"
},
{
    "id": "Disease::MESH:D018487",
    "name": "Ventricular Dysfunction"
},
{
    "id": "Disease::MESH:D014693",
    "name": "Ventricular Fibrillation"
},
{
    "id": "Disease::MESH:D054141",
    "name": "Ventricular Flutter"
},
{
    "id": "Disease::MESH:D014694",
    "name": "Ventricular Outflow Obstruction"
},
{
    "id": "Disease::MESH:D018879",
    "name": "Ventricular Premature Complexes"
},
{
    "id": "Disease::MESH:D020257",
    "name": "Ventricular Remodeling"
},
{
    "id": "Disease::MESH:D018658",
    "name": "Ventricular Septal Rupture"
},
{
    "id": "Disease::MESH:D020217",
    "name": "Vertebral Artery Dissection"
},
{
    "id": "Disease::MESH:D014715",
    "name": "Vertebrobasilar Insufficiency"
},
{
    "id": "Disease::MESH:D014717",
    "name": "Vertigo"
},
{
    "id": "Disease::MESH:D014718",
    "name": "Vesico-Ureteral Reflux"
},
{
    "id": "Disease::MESH:D054243",
    "name": "Vesicular Stomatitis"
},
{
    "id": "Disease::MESH:D015837",
    "name": "Vestibular Diseases"
},
{
    "id": "Disease::MESH:D020338",
    "name": "Vestibular Neuronitis"
},
{
    "id": "Disease::MESH:D000160",
    "name": "Vestibulocochlear Nerve Diseases"
},
{
    "id": "Disease::MESH:D003969",
    "name": "Vipoma"
},
{
    "id": "Disease::MESH:D014766",
    "name": "Viremia"
},
{
    "id": "Disease::MESH:D034363",
    "name": "Viridans Streptococci"
},
{
    "id": "Disease::MESH:D014770",
    "name": "Virilism"
},
{
    "id": "Disease::MESH:D014777",
    "name": "Virus Diseases"
},
{
    "id": "Disease::MESH:D059265",
    "name": "Visceral Pain"
},
{
    "id": "Disease::MESH:D015354",
    "name": "Vision"
},
{
    "id": "Disease::MESH:D014786",
    "name": "Vision Disorders"
},
{
    "id": "Disease::MESH:D014802",
    "name": "Vitamin A Deficiency"
},
{
    "id": "Disease::MESH:D014806",
    "name": "Vitamin B 12 Deficiency"
},
{
    "id": "Disease::MESH:D026681",
    "name": "Vitamin B 6 Deficiency"
},
{
    "id": "Disease::MESH:D014804",
    "name": "Vitamin B Deficiency"
},
{
    "id": "Disease::MESH:D014808",
    "name": "Vitamin D Deficiency"
},
{
    "id": "Disease::MESH:D014811",
    "name": "Vitamin E Deficiency"
},
{
    "id": "Disease::MESH:D014813",
    "name": "Vitamin K Deficiency"
},
{
    "id": "Disease::MESH:D006475",
    "name": "Vitamin K Deficiency Bleeding"
},
{
    "id": "Disease::MESH:D057826",
    "name": "Vitelliform Macular Dystrophy"
},
{
    "id": "Disease::MESH:D014820",
    "name": "Vitiligo"
},
{
    "id": "Disease::MESH:D018630",
    "name": "Vitreoretinopathy"
},
{
    "id": "Disease::MESH:D020255",
    "name": "Vitreous Detachment"
},
{
    "id": "Disease::MESH:D014826",
    "name": "Vocal Cord Paralysis"
},
{
    "id": "Disease::MESH:D014839",
    "name": "Vomiting"
},
{
    "id": "Disease::MESH:D014845",
    "name": "Vulvar Diseases"
},
{
    "id": "Disease::MESH:D007724",
    "name": "Vulvar Lichen Sclerosus"
},
{
    "id": "Disease::MESH:D014846",
    "name": "Vulvar Neoplasms"
},
{
    "id": "Disease::MESH:D054515",
    "name": "Vulvar Vestibulitis"
},
{
    "id": "Disease::MESH:D014847",
    "name": "Vulvitis"
},
{
    "id": "Disease::MESH:D056650",
    "name": "Vulvodynia"
},
{
    "id": "Disease::MESH:D014848",
    "name": "Vulvovaginitis"
},
{
    "id": "Disease::MESH:D014849",
    "name": "Waardenburg Syndrome"
},
{
    "id": "Disease::MESH:D008258",
    "name": "Waldenstrom Macroglobulinemia"
},
{
    "id": "Disease::MESH:D058494",
    "name": "Walker-Warburg Syndrome"
},
{
    "id": "Disease::MESH:D014855",
    "name": "Wallerian Degeneration"
},
{
    "id": "Disease::MESH:D014860",
    "name": "Warts"
},
{
    "id": "Disease::MESH:D034081",
    "name": "Wasting Disease"
},
{
    "id": "Disease::MESH:D019282",
    "name": "Wasting Syndrome"
},
{
    "id": "Disease::MESH:D014869",
    "name": "Water Intoxication"
},
{
    "id": "Disease::MESH:D014883",
    "name": "Water-Electrolyte Imbalance"
},
{
    "id": "Disease::MESH:D014884",
    "name": "Waterhouse-Friderichsen Syndrome"
},
{
    "id": "Disease::MESH:D015430",
    "name": "Weight Gain"
},
{
    "id": "Disease::MESH:D015431",
    "name": "Weight Loss"
},
{
    "id": "Disease::MESH:D056846",
    "name": "Weill-Marchesani Syndrome"
},
{
    "id": "Disease::MESH:D014898",
    "name": "Werner Syndrome"
},
{
    "id": "Disease::MESH:D014899",
    "name": "Wernicke Encephalopathy"
},
{
    "id": "Disease::MESH:D057135",
    "name": "Wet Macular Degeneration"
},
{
    "id": "Disease::MESH:D021182",
    "name": "Wheat Hypersensitivity"
},
{
    "id": "Disease::MESH:D014911",
    "name": "Whiplash Injuries"
},
{
    "id": "Disease::MESH:D008061",
    "name": "Whipple Disease"
},
{
    "id": "Disease::MESH:D014917",
    "name": "Whooping Cough"
},
{
    "id": "Disease::MESH:D018980",
    "name": "Williams Syndrome"
},
{
    "id": "Disease::MESH:D009396",
    "name": "Wilms Tumor"
},
{
    "id": "Disease::MESH:D014923",
    "name": "Wiskott-Aldrich Syndrome"
},
{
    "id": "Disease::MESH:D014927",
    "name": "Wolff-Parkinson-White Syndrome"
},
{
    "id": "Disease::MESH:D014929",
    "name": "Wolfram Syndrome"
},
{
    "id": "Disease::MESH:D015223",
    "name": "Wolman Disease"
},
{
    "id": "Disease::MESH:D014945",
    "name": "Wound Healing"
},
{
    "id": "Disease::MESH:D014946",
    "name": "Wound Infection"
},
{
    "id": "Disease::MESH:D014947",
    "name": "Wounds and Injuries"
},
{
    "id": "Disease::MESH:D014958",
    "name": "Wuchereria bancrofti"
},
{
    "id": "Disease::MESH:D053632",
    "name": "X-Linked Combined Immunodeficiency Diseases"
},
{
    "id": "Disease::MESH:D014972",
    "name": "Xanthogranuloma"
},
{
    "id": "Disease::MESH:D019294",
    "name": "Xanthomatosis"
},
{
    "id": "Disease::MESH:D014973",
    "name": "Xanthomatosis"
},
{
    "id": "Disease::MESH:D014983",
    "name": "Xeroderma Pigmentosum"
},
{
    "id": "Disease::MESH:D014985",
    "name": "Xerophthalmia"
},
{
    "id": "Disease::MESH:D014987",
    "name": "Xerostomia"
},
{
    "id": "Disease::MESH:D016711",
    "name": "Yang Deficiency"
},
{
    "id": "Disease::MESH:D015001",
    "name": "Yaws"
},
{
    "id": "Disease::MESH:D015004",
    "name": "Yellow Fever"
},
{
    "id": "Disease::MESH:D056684",
    "name": "Yellow Nail Syndrome"
},
{
    "id": "Disease::MESH:D015009",
    "name": "Yersinia Infections"
},
{
    "id": "Disease::MESH:D015012",
    "name": "Yersinia pseudotuberculosis Infections"
},
{
    "id": "Disease::MESH:D015211",
    "name": "Zellweger Syndrome"
},
{
    "id": "Disease::MESH:D015043",
    "name": "Zollinger-Ellison Syndrome"
},
{
    "id": "Disease::MESH:D020096",
    "name": "Zygomycosis"
},
{
    "id": "Disease::MESH:D019896",
    "name": "alpha 1-Antitrypsin Deficiency"
},
{
    "id": "Disease::MESH:D008363",
    "name": "alpha-Mannosidosis"
},
{
    "id": "Disease::MESH:D017085",
    "name": "alpha-Thalassemia"
},
{
    "id": "Disease::MESH:D017086",
    "name": "beta-Thalassemia"
},
{
    "id": "Disease::MESH:D006623",
    "name": "von Hippel-Lindau Disease"
},
{
    "id": "Disease::MESH:D056729",
    "name": "von Willebrand Disease"
},
{
    "id": "Disease::MESH:D056728",
    "name": "von Willebrand Disease"
},
{
    "id": "Disease::MESH:D056725",
    "name": "von Willebrand Disease"
},
{
    "id": "Disease::MESH:D014842",
    "name": "von Willebrand Diseases"
}
],
  TarKGE_target: [
    { id: "29974", name: "A1CF" },
    { id: "144568", name: "A2ML1" },
    { id: "53947", name: "A4GALT" },
    { id: "8086", name: "AAAS" },
    { id: "65985", name: "AACS" },
    { id: "79719", name: "AAGAB" },
    { id: "22848", name: "AAK1" },
    { id: "28971", name: "AAMDC" },
    { id: "25980", name: "AAR2" },
    { id: "441376", name: "AARD" },
    { id: "57505", name: "AARS2" },
    { id: "80755", name: "AARSD1" },
    { id: "60496", name: "AASDHPPT" },
    { id: "10157", name: "AASS" },
    { id: "26574", name: "AATF" },
    { id: "9625", name: "AATK" },
    { id: "18", name: "ABAT" },
    { id: "10349", name: "ABCA10" },
    { id: "26154", name: "ABCA12" },
    { id: "154664", name: "ABCA13" },
    { id: "23461", name: "ABCA5" },
    { id: "23460", name: "ABCA6" },
    { id: "10347", name: "ABCA7" },
    { id: "10350", name: "ABCA9" },
    { id: "5243", name: "ABCB1" },
    { id: "23456", name: "ABCB10" },
    { id: "8647", name: "ABCB11" },
    { id: "340273", name: "ABCB5" },
    { id: "10058", name: "ABCB6" },
    { id: "11194", name: "ABCB8" },
    { id: "23457", name: "ABCB9" },
    { id: "4363", name: "ABCC1" },
    { id: "89845", name: "ABCC10" },
    { id: "94160", name: "ABCC12" },
    { id: "1244", name: "ABCC2" },
    { id: "8714", name: "ABCC3" },
    { id: "10257", name: "ABCC4" },
    { id: "10057", name: "ABCC5" },
    { id: "368", name: "ABCC6" },
    { id: "6833", name: "ABCC8" },
    { id: "10060", name: "ABCC9" },
    { id: "215", name: "ABCD1" },
    { id: "225", name: "ABCD2" },
    { id: "5825", name: "ABCD3" },
    { id: "5826", name: "ABCD4" },
    { id: "6059", name: "ABCE1" },
    { id: "10061", name: "ABCF2" },
    { id: "55324", name: "ABCF3" },
    { id: "9619", name: "ABCG1" },
    { id: "9429", name: "ABCG2" },
    { id: "64137", name: "ABCG4" },
    { id: "64240", name: "ABCG5" },
    { id: "64241", name: "ABCG8" },
    { id: "55347", name: "ABHD10" },
    { id: "83451", name: "ABHD11" },
    { id: "26090", name: "ABHD12" },
    { id: "25864", name: "ABHD14A" },
    { id: "84836", name: "ABHD14B" },
    { id: "116236", name: "ABHD15" },
    { id: "7920", name: "ABHD16A" },
    { id: "81926", name: "ABHD17A" },
    { id: "11057", name: "ABHD2" },
    { id: "171586", name: "ABHD3" },
    { id: "63874", name: "ABHD4" },
    { id: "51099", name: "ABHD5" },
    { id: "57406", name: "ABHD6" },
    { id: "10006", name: "ABI1" },
    { id: "10152", name: "ABI2" },
    { id: "51225", name: "ABI3" },
    { id: "25890", name: "ABI3BP" },
    { id: "25", name: "ABL1" },
    { id: "27", name: "ABL2" },
    { id: "3983", name: "ABLIM1" },
    { id: "84448", name: "ABLIM2" },
    { id: "22885", name: "ABLIM3" },
    { id: "29", name: "ABR" },
    { id: "137735", name: "ABRA" },
    { id: "58527", name: "ABRACL" },
    { id: "29777", name: "ABT1" },
    { id: "80325", name: "ABTB1" },
    { id: "25841", name: "ABTB2" },
    { id: "10449", name: "ACAA2" },
    { id: "31", name: "ACACA" },
    { id: "80724", name: "ACAD10" },
    { id: "84129", name: "ACAD11" },
    { id: "27034", name: "ACAD8" },
    { id: "28976", name: "ACAD9" },
    { id: "34", name: "ACADM" },
    { id: "37", name: "ACADVL" },
    { id: "9744", name: "ACAP1" },
    { id: "23527", name: "ACAP2" },
    { id: "116983", name: "ACAP3" },
    { id: "39", name: "ACAT2" },
    { id: "64746", name: "ACBD3" },
    { id: "79777", name: "ACBD4" },
    { id: "91452", name: "ACBD5" },
    { id: "84320", name: "ACBD6" },
    { id: "84680", name: "ACCS" },
    { id: "65057", name: "ACD" },
    { id: "1636", name: "ACE" },
    { id: "59272", name: "ACE2" },
    { id: "125981", name: "ACER1" },
    { id: "340485", name: "ACER2" },
    { id: "55331", name: "ACER3" },
    { id: "43", name: "ACHE" },
    { id: "22985", name: "ACIN1" },
    { id: "47", name: "ACLY" },
    { id: "130013", name: "ACMSD" },
    { id: "50", name: "ACO2" },
    { id: "641371", name: "ACOT1" },
    { id: "134526", name: "ACOT12" },
    { id: "55856", name: "ACOT13" },
    { id: "10965", name: "ACOT2" },
    { id: "11332", name: "ACOT7" },
    { id: "10005", name: "ACOT8" },
    { id: "23597", name: "ACOT9" },
    { id: "8309", name: "ACOX2" },
    { id: "8310", name: "ACOX3" },
    { id: "55289", name: "ACOXL" },
    { id: "52", name: "ACP1" },
    { id: "51205", name: "ACP6" },
    { id: "92370", name: "ACPL2" },
    { id: "55", name: "ACPP" },
    { id: "84519", name: "ACRBP" },
    { id: "93953", name: "ACRC" },
    { id: "23205", name: "ACSBG1" },
    { id: "81616", name: "ACSBG2" },
    { id: "80221", name: "ACSF2" },
    { id: "197322", name: "ACSF3" },
    { id: "2180", name: "ACSL1" },
    { id: "2181", name: "ACSL3" },
    { id: "2182", name: "ACSL4" },
    { id: "51703", name: "ACSL5" },
    { id: "23305", name: "ACSL6" },
    { id: "116285", name: "ACSM1" },
    { id: "348158", name: "ACSM2B" },
    { id: "54988", name: "ACSM5" },
    { id: "84532", name: "ACSS1" },
    { id: "55902", name: "ACSS2" },
    { id: "79611", name: "ACSS3" },
    { id: "58", name: "ACTA1" },
    { id: "59", name: "ACTA2" },
    { id: "60", name: "ACTB" },
    { id: "345651", name: "ACTBL2" },
    { id: "70", name: "ACTC1" },
    { id: "71", name: "ACTG1" },
    { id: "51412", name: "ACTL6B" },
    { id: "10881", name: "ACTL7A" },
    { id: "10880", name: "ACTL7B" },
    { id: "81569", name: "ACTL8" },
    { id: "87", name: "ACTN1" },
    { id: "88", name: "ACTN2" },
    { id: "89", name: "ACTN3" },
    { id: "81", name: "ACTN4" },
    { id: "55860", name: "ACTR10" },
    { id: "10121", name: "ACTR1A" },
    { id: "10120", name: "ACTR1B" },
    { id: "10097", name: "ACTR2" },
    { id: "10096", name: "ACTR3" },
    { id: "57180", name: "ACTR3B" },
    { id: "79913", name: "ACTR5" },
    { id: "64431", name: "ACTR6" },
    { id: "93973", name: "ACTR8" },
    { id: "139741", name: "ACTRT1" },
    { id: "91", name: "ACVR1B" },
    { id: "130399", name: "ACVR1C" },
    { id: "92", name: "ACVR2A" },
    { id: "93", name: "ACVR2B" },
    { id: "94", name: "ACVRL1" },
    { id: "95", name: "ACY1" },
    { id: "91703", name: "ACY3" },
    { id: "8038", name: "ADAM12" },
    { id: "8751", name: "ADAM15" },
    { id: "6868", name: "ADAM17" },
    { id: "8728", name: "ADAM19" },
    { id: "2515", name: "ADAM2" },
    { id: "8747", name: "ADAM21" },
    { id: "53616", name: "ADAM22" },
    { id: "8745", name: "ADAM23" },
    { id: "10863", name: "ADAM28" },
    { id: "11086", name: "ADAM29" },
    { id: "80332", name: "ADAM33" },
    { id: "8754", name: "ADAM9" },
    { id: "9510", name: "ADAMTS1" },
    { id: "81794", name: "ADAMTS10" },
    { id: "81792", name: "ADAMTS12" },
    { id: "11093", name: "ADAMTS13" },
    { id: "170691", name: "ADAMTS17" },
    { id: "9509", name: "ADAMTS2" },
    { id: "80070", name: "ADAMTS20" },
    { id: "9507", name: "ADAMTS4" },
    { id: "11096", name: "ADAMTS5" },
    { id: "11174", name: "ADAMTS6" },
    { id: "11173", name: "ADAMTS7" },
    { id: "56999", name: "ADAMTS9" },
    { id: "9719", name: "ADAMTSL2" },
    { id: "57188", name: "ADAMTSL3" },
    { id: "54507", name: "ADAMTSL4" },
    { id: "339366", name: "ADAMTSL5" },
    { id: "11033", name: "ADAP1" },
    { id: "55803", name: "ADAP2" },
    { id: "103", name: "ADAR" },
    { id: "104", name: "ADARB1" },
    { id: "23536", name: "ADAT1" },
    { id: "134637", name: "ADAT2" },
    { id: "113179", name: "ADAT3" },
    { id: "113451", name: "ADC" },
    { id: "57143", name: "ADCK1" },
    { id: "90956", name: "ADCK2" },
    { id: "56997", name: "ADCK3" },
    { id: "79934", name: "ADCK4" },
    { id: "203054", name: "ADCK5" },
    { id: "55811", name: "ADCY10" },
    { id: "108", name: "ADCY2" },
    { id: "196883", name: "ADCY4" },
    { id: "111", name: "ADCY5" },
    { id: "117", name: "ADCYAP1R1" },
    { id: "119", name: "ADD2" },
    { id: "124", name: "ADH1A" },
    { id: "125", name: "ADH1B" },
    { id: "126", name: "ADH1C" },
    { id: "128", name: "ADH5" },
    { id: "131", name: "ADH7" },
    { id: "137872", name: "ADHFE1" },
    { id: "55256", name: "ADI1" },
    { id: "9370", name: "ADIPOQ" },
    { id: "51094", name: "ADIPOR1" },
    { id: "79602", name: "ADIPOR2" },
    { id: "10974", name: "ADIRF" },
    { id: "133", name: "ADM" },
    { id: "199800", name: "ADM5" },
    { id: "23394", name: "ADNP" },
    { id: "22850", name: "ADNP2" },
    { id: "84890", name: "ADO" },
    { id: "134", name: "ADORA1" },
    { id: "135", name: "ADORA2A" },
    { id: "136", name: "ADORA2B" },
    { id: "83440", name: "ADPGK" },
    { id: "54936", name: "ADPRHL2" },
    { id: "56985", name: "ADPRM" },
    { id: "148", name: "ADRA1A" },
    { id: "153", name: "ADRB1" },
    { id: "154", name: "ADRB2" },
    { id: "155", name: "ADRB3" },
    { id: "156", name: "ADRBK1" },
    { id: "157", name: "ADRBK2" },
    { id: "11047", name: "ADRM1" },
    { id: "159", name: "ADSS" },
    { id: "122622", name: "ADSSL1" },
    { id: "84830", name: "ADTRP" },
    { id: "121536", name: "AEBP2" },
    { id: "64782", name: "AEN" },
    { id: "166", name: "AES" },
    { id: "60312", name: "AFAP1" },
    { id: "134265", name: "AFAP1L1" },
    { id: "84632", name: "AFAP1L2" },
    { id: "4299", name: "AFF1" },
    { id: "2334", name: "AFF2" },
    { id: "3899", name: "AFF3" },
    { id: "27125", name: "AFF4" },
    { id: "10939", name: "AFG3L2" },
    { id: "125061", name: "AFMID" },
    { id: "54812", name: "AFTPH" },
    { id: "175", name: "AGA" },
    { id: "116987", name: "AGAP1" },
    { id: "116986", name: "AGAP2" },
    { id: "116988", name: "AGAP3" },
    { id: "119016", name: "AGAP4" },
    { id: "729092", name: "AGAP5" },
    { id: "123624", name: "AGBL1" },
    { id: "79841", name: "AGBL2" },
    { id: "84871", name: "AGBL4" },
    { id: "177", name: "AGER" },
    { id: "3267", name: "AGFG1" },
    { id: "3268", name: "AGFG2" },
    { id: "55109", name: "AGGF1" },
    { id: "55750", name: "AGK" },
    { id: "26523", name: "AGO1" },
    { id: "27161", name: "AGO2" },
    { id: "192669", name: "AGO3" },
    { id: "192670", name: "AGO4" },
    { id: "10554", name: "AGPAT1" },
    { id: "10555", name: "AGPAT2" },
    { id: "56894", name: "AGPAT3" },
    { id: "56895", name: "AGPAT4" },
    { id: "55326", name: "AGPAT5" },
    { id: "137964", name: "AGPAT6" },
    { id: "84803", name: "AGPAT9" },
    { id: "8540", name: "AGPS" },
    { id: "10551", name: "AGR2" },
    { id: "155465", name: "AGR3" },
    { id: "375790", name: "AGRN" },
    { id: "183", name: "AGT" },
    { id: "23287", name: "AGTPBP1" },
    { id: "185", name: "AGTR1" },
    { id: "186", name: "AGTR2" },
    { id: "57085", name: "AGTRAP" },
    { id: "189", name: "AGXT" },
    { id: "64902", name: "AGXT2" },
    { id: "25909", name: "AHCTF1" },
    { id: "191", name: "AHCY" },
    { id: "10768", name: "AHCYL1" },
    { id: "23382", name: "AHCYL2" },
    { id: "27245", name: "AHDC1" },
    { id: "54806", name: "AHI1" },
    { id: "79026", name: "AHNAK" },
    { id: "113146", name: "AHNAK2" },
    { id: "196", name: "AHR" },
    { id: "57491", name: "AHRR" },
    { id: "10598", name: "AHSA1" },
    { id: "130872", name: "AHSA2" },
    { id: "197", name: "AHSG" },
    { id: "51327", name: "AHSP" },
    { id: "57379", name: "AICDA" },
    { id: "64853", name: "AIDA" },
    { id: "199", name: "AIF1" },
    { id: "83543", name: "AIF1L" },
    { id: "9131", name: "AIFM1" },
    { id: "84883", name: "AIFM2" },
    { id: "150209", name: "AIFM3" },
    { id: "51390", name: "AIG1" },
    { id: "55057", name: "AIM1L" },
    { id: "9447", name: "AIM2" },
    { id: "9255", name: "AIMP1" },
    { id: "7965", name: "AIMP2" },
    { id: "9049", name: "AIP" },
    { id: "23746", name: "AIPL1" },
    { id: "326", name: "AIRE" },
    { id: "55966", name: "AJAP1" },
    { id: "84962", name: "AJUBA" },
    { id: "50808", name: "AK3" },
    { id: "26289", name: "AK5" },
    { id: "122481", name: "AK7" },
    { id: "158067", name: "AK8" },
    { id: "221264", name: "AK9" },
    { id: "8165", name: "AKAP1" },
    { id: "11216", name: "AKAP10" },
    { id: "11215", name: "AKAP11" },
    { id: "9590", name: "AKAP12" },
    { id: "11214", name: "AKAP13" },
    { id: "158798", name: "AKAP14" },
    { id: "8227", name: "AKAP17A" },
    { id: "11217", name: "AKAP2" },
    { id: "10566", name: "AKAP3" },
    { id: "8852", name: "AKAP4" },
    { id: "9495", name: "AKAP5" },
    { id: "9472", name: "AKAP6" },
    { id: "9465", name: "AKAP7" },
    { id: "10270", name: "AKAP8" },
    { id: "26993", name: "AKAP8L" },
    { id: "10142", name: "AKAP9" },
    { id: "56672", name: "AKIP1" },
    { id: "79647", name: "AKIRIN1" },
    { id: "55122", name: "AKIRIN2" },
    { id: "80709", name: "AKNA" },
    { id: "254268", name: "AKNAD1" },
    { id: "10327", name: "AKR1A1" },
    { id: "57016", name: "AKR1B10" },
    { id: "1645", name: "AKR1C1" },
    { id: "8644", name: "AKR1C3" },
    { id: "8574", name: "AKR7A2" },
    { id: "22977", name: "AKR7A3" },
    { id: "246181", name: "AKR7L" },
    { id: "207", name: "AKT1" },
    { id: "84335", name: "AKT1S1" },
    { id: "208", name: "AKT2" },
    { id: "10000", name: "AKT3" },
    { id: "64400", name: "AKTIP" },
    { id: "210", name: "ALAD" },
    { id: "211", name: "ALAS1" },
    { id: "213", name: "ALB" },
    { id: "214", name: "ALCAM" },
    { id: "126133", name: "ALDH16A1" },
    { id: "5832", name: "ALDH18A1" },
    { id: "216", name: "ALDH1A1" },
    { id: "8854", name: "ALDH1A2" },
    { id: "219", name: "ALDH1B1" },
    { id: "10840", name: "ALDH1L1" },
    { id: "160428", name: "ALDH1L2" },
    { id: "217", name: "ALDH2" },
    { id: "218", name: "ALDH3A1" },
    { id: "224", name: "ALDH3A2" },
    { id: "8659", name: "ALDH4A1" },
    { id: "7915", name: "ALDH5A1" },
    { id: "4329", name: "ALDH6A1" },
    { id: "501", name: "ALDH7A1" },
    { id: "64577", name: "ALDH8A1" },
    { id: "223", name: "ALDH9A1" },
    { id: "226", name: "ALDOA" },
    { id: "229", name: "ALDOB" },
    { id: "230", name: "ALDOC" },
    { id: "56052", name: "ALG1" },
    { id: "84920", name: "ALG10" },
    { id: "144245", name: "ALG10B" },
    { id: "440138", name: "ALG11" },
    { id: "79087", name: "ALG12" },
    { id: "79868", name: "ALG13" },
    { id: "199857", name: "ALG14" },
    { id: "200810", name: "ALG1L" },
    { id: "644974", name: "ALG1L2" },
    { id: "85365", name: "ALG2" },
    { id: "10195", name: "ALG3" },
    { id: "29929", name: "ALG6" },
    { id: "79053", name: "ALG8" },
    { id: "79796", name: "ALG9" },
    { id: "238", name: "ALK" },
    { id: "8846", name: "ALKBH1" },
    { id: "121642", name: "ALKBH2" },
    { id: "221120", name: "ALKBH3" },
    { id: "54784", name: "ALKBH4" },
    { id: "54890", name: "ALKBH5" },
    { id: "84266", name: "ALKBH7" },
    { id: "91801", name: "ALKBH8" },
    { id: "55821", name: "ALLC" },
    { id: "7840", name: "ALMS1" },
    { id: "239", name: "ALOX12" },
    { id: "240", name: "ALOX5" },
    { id: "241", name: "ALOX5AP" },
    { id: "59344", name: "ALOXE3" },
    { id: "80216", name: "ALPK1" },
    { id: "115701", name: "ALPK2" },
    { id: "57538", name: "ALPK3" },
    { id: "250", name: "ALPP" },
    { id: "251", name: "ALPPL2" },
    { id: "57679", name: "ALS2" },
    { id: "259173", name: "ALS2CL" },
    { id: "151254", name: "ALS2CR11" },
    { id: "130540", name: "ALS2CR12" },
    { id: "8092", name: "ALX1" },
    { id: "60529", name: "ALX4" },
    { id: "10189", name: "ALYREF" },
    { id: "23600", name: "AMACR" },
    { id: "259", name: "AMBP" },
    { id: "55626", name: "AMBRA1" },
    { id: "262", name: "AMD1" },
    { id: "144193", name: "AMDHD1" },
    { id: "51005", name: "AMDHD2" },
    { id: "265", name: "AMELX" },
    { id: "139285", name: "AMER1" },
    { id: "219287", name: "AMER2" },
    { id: "205147", name: "AMER3" },
    { id: "267", name: "AMFR" },
    { id: "269", name: "AMHR2" },
    { id: "120425", name: "AMICA1" },
    { id: "57463", name: "AMIGO1" },
    { id: "347902", name: "AMIGO2" },
    { id: "386724", name: "AMIGO3" },
    { id: "9949", name: "AMMECR1" },
    { id: "83607", name: "AMMECR1L" },
    { id: "81693", name: "AMN" },
    { id: "154796", name: "AMOT" },
    { id: "154810", name: "AMOTL1" },
    { id: "51421", name: "AMOTL2" },
    { id: "270", name: "AMPD1" },
    { id: "271", name: "AMPD2" },
    { id: "272", name: "AMPD3" },
    { id: "273", name: "AMPH" },
    { id: "51321", name: "AMZ2" },
    { id: "64682", name: "ANAPC1" },
    { id: "10393", name: "ANAPC10" },
    { id: "51529", name: "ANAPC11" },
    { id: "25847", name: "ANAPC13" },
    { id: "25906", name: "ANAPC15" },
    { id: "119504", name: "ANAPC16" },
    { id: "29882", name: "ANAPC2" },
    { id: "29945", name: "ANAPC4" },
    { id: "51433", name: "ANAPC5" },
    { id: "51434", name: "ANAPC7" },
    { id: "283", name: "ANG" },
    { id: "23357", name: "ANGEL1" },
    { id: "90806", name: "ANGEL2" },
    { id: "284", name: "ANGPT1" },
    { id: "285", name: "ANGPT2" },
    { id: "51378", name: "ANGPT4" },
    { id: "9068", name: "ANGPTL1" },
    { id: "27329", name: "ANGPTL3" },
    { id: "51129", name: "ANGPTL4" },
    { id: "83854", name: "ANGPTL6" },
    { id: "10218", name: "ANGPTL7" },
    { id: "150709", name: "ANKAR" },
    { id: "348094", name: "ANKDD1A" },
    { id: "63926", name: "ANKEF1" },
    { id: "51479", name: "ANKFY1" },
    { id: "56172", name: "ANKH" },
    { id: "54882", name: "ANKHD1" },
    { id: "54467", name: "ANKIB1" },
    { id: "255239", name: "ANKK1" },
    { id: "126549", name: "ANKLE1" },
    { id: "23141", name: "ANKLE2" },
    { id: "57037", name: "ANKMY2" },
    { id: "57763", name: "ANKRA2" },
    { id: "27063", name: "ANKRD1" },
    { id: "55608", name: "ANKRD10" },
    { id: "29123", name: "ANKRD11" },
    { id: "23253", name: "ANKRD12" },
    { id: "88455", name: "ANKRD13A" },
    { id: "124930", name: "ANKRD13B" },
    { id: "81573", name: "ANKRD13C" },
    { id: "338692", name: "ANKRD13D" },
    { id: "26057", name: "ANKRD17" },
    { id: "253650", name: "ANKRD18A" },
    { id: "26287", name: "ANKRD2" },
    { id: "84210", name: "ANKRD20A1" },
    { id: "118932", name: "ANKRD22" },
    { id: "200539", name: "ANKRD23" },
    { id: "170961", name: "ANKRD24" },
    { id: "22852", name: "ANKRD26" },
    { id: "124149", name: "ANKRD26P1" },
    { id: "84079", name: "ANKRD27" },
    { id: "23243", name: "ANKRD28" },
    { id: "91074", name: "ANKRD30A" },
    { id: "256006", name: "ANKRD31" },
    { id: "84250", name: "ANKRD32" },
    { id: "341405", name: "ANKRD33" },
    { id: "284615", name: "ANKRD34A" },
    { id: "148741", name: "ANKRD35" },
    { id: "375248", name: "ANKRD36" },
    { id: "57730", name: "ANKRD36B" },
    { id: "84832", name: "ANKRD36BP1" },
    { id: "353322", name: "ANKRD37" },
    { id: "51239", name: "ANKRD39" },
    { id: "91369", name: "ANKRD40" },
    { id: "338699", name: "ANKRD42" },
    { id: "91526", name: "ANKRD44" },
    { id: "339416", name: "ANKRD45" },
    { id: "157567", name: "ANKRD46" },
    { id: "54851", name: "ANKRD49" },
    { id: "57182", name: "ANKRD50" },
    { id: "283373", name: "ANKRD52" },
    { id: "79998", name: "ANKRD53" },
    { id: "129138", name: "ANKRD54" },
    { id: "79722", name: "ANKRD55" },
    { id: "22881", name: "ANKRD6" },
    { id: "441869", name: "ANKRD65" },
    { id: "56311", name: "ANKRD7" },
    { id: "23294", name: "ANKS1A" },
    { id: "56899", name: "ANKS1B" },
    { id: "124401", name: "ANKS3" },
    { id: "257629", name: "ANKS4B" },
    { id: "203286", name: "ANKS6" },
    { id: "55139", name: "ANKZF1" },
    { id: "54443", name: "ANLN" },
    { id: "55107", name: "ANO1" },
    { id: "55129", name: "ANO10" },
    { id: "57101", name: "ANO2" },
    { id: "63982", name: "ANO3" },
    { id: "203859", name: "ANO5" },
    { id: "196527", name: "ANO6" },
    { id: "57719", name: "ANO8" },
    { id: "338440", name: "ANO9" },
    { id: "8125", name: "ANP32A" },
    { id: "10541", name: "ANP32B" },
    { id: "23520", name: "ANP32C" },
    { id: "23519", name: "ANP32D" },
    { id: "81611", name: "ANP32E" },
    { id: "290", name: "ANPEP" },
    { id: "84168", name: "ANTXR1" },
    { id: "118429", name: "ANTXR2" },
    { id: "301", name: "ANXA1" },
    { id: "11199", name: "ANXA10" },
    { id: "311", name: "ANXA11" },
    { id: "302", name: "ANXA2" },
    { id: "307", name: "ANXA4" },
    { id: "308", name: "ANXA5" },
    { id: "309", name: "ANXA6" },
    { id: "310", name: "ANXA7" },
    { id: "653145", name: "ANXA8" },
    { id: "8416", name: "ANXA9" },
    { id: "8639", name: "AOC3" },
    { id: "316", name: "AOX1" },
    { id: "55435", name: "AP1AR" },
    { id: "162", name: "AP1B1" },
    { id: "164", name: "AP1G1" },
    { id: "8906", name: "AP1G2" },
    { id: "8907", name: "AP1M1" },
    { id: "10053", name: "AP1M2" },
    { id: "1174", name: "AP1S1" },
    { id: "8905", name: "AP1S2" },
    { id: "130340", name: "AP1S3" },
    { id: "160", name: "AP2A1" },
    { id: "161", name: "AP2A2" },
    { id: "163", name: "AP2B1" },
    { id: "1173", name: "AP2M1" },
    { id: "1175", name: "AP2S1" },
    { id: "8546", name: "AP3B1" },
    { id: "8120", name: "AP3B2" },
    { id: "8943", name: "AP3D1" },
    { id: "26985", name: "AP3M1" },
    { id: "10947", name: "AP3M2" },
    { id: "1176", name: "AP3S1" },
    { id: "10239", name: "AP3S2" },
    { id: "10717", name: "AP4B1" },
    { id: "23431", name: "AP4E1" },
    { id: "9179", name: "AP4M1" },
    { id: "11154", name: "AP4S1" },
    { id: "91056", name: "AP5B1" },
    { id: "55745", name: "AP5M1" },
    { id: "55317", name: "AP5S1" },
    { id: "9907", name: "AP5Z1" },
    { id: "320", name: "APBA1" },
    { id: "9546", name: "APBA3" },
    { id: "322", name: "APBB1" },
    { id: "54518", name: "APBB1IP" },
    { id: "10307", name: "APBB3" },
    { id: "324", name: "APC" },
    { id: "10297", name: "APC2" },
    { id: "147495", name: "APCDD1" },
    { id: "164284", name: "APCDD1L" },
    { id: "325", name: "APCS" },
    { id: "327", name: "APEH" },
    { id: "328", name: "APEX1" },
    { id: "27301", name: "APEX2" },
    { id: "51107", name: "APH1A" },
    { id: "83464", name: "APH1B" },
    { id: "8539", name: "API5" },
    { id: "51074", name: "APIP" },
    { id: "378708", name: "APITD1" },
    { id: "200558", name: "APLF" },
    { id: "8862", name: "APLN" },
    { id: "333", name: "APLP1" },
    { id: "334", name: "APLP2" },
    { id: "57136", name: "APMAP" },
    { id: "335", name: "APOA1" },
    { id: "128240", name: "APOA1BP" },
    { id: "336", name: "APOA2" },
    { id: "337", name: "APOA4" },
    { id: "116519", name: "APOA5" },
    { id: "338", name: "APOB" },
    { id: "10930", name: "APOBEC2" },
    { id: "200315", name: "APOBEC3A" },
    { id: "9582", name: "APOBEC3B" },
    { id: "27350", name: "APOBEC3C" },
    { id: "140564", name: "APOBEC3D" },
    { id: "200316", name: "APOBEC3F" },
    { id: "60489", name: "APOBEC3G" },
    { id: "341", name: "APOC1" },
    { id: "344", name: "APOC2" },
    { id: "345", name: "APOC3" },
    { id: "347", name: "APOD" },
    { id: "348", name: "APOE" },
    { id: "350", name: "APOH" },
    { id: "8542", name: "APOL1" },
    { id: "23780", name: "APOL2" },
    { id: "80833", name: "APOL3" },
    { id: "80832", name: "APOL4" },
    { id: "80831", name: "APOL5" },
    { id: "80830", name: "APOL6" },
    { id: "81575", name: "APOLD1" },
    { id: "55937", name: "APOM" },
    { id: "79135", name: "APOO" },
    { id: "139322", name: "APOOL" },
    { id: "84334", name: "APOPT1" },
    { id: "351", name: "APP" },
    { id: "10513", name: "APPBP2" },
    { id: "26060", name: "APPL1" },
    { id: "55198", name: "APPL2" },
    { id: "353", name: "APRT" },
    { id: "54840", name: "APTX" },
    { id: "358", name: "AQP1" },
    { id: "89872", name: "AQP10" },
    { id: "282679", name: "AQP11" },
    { id: "359", name: "AQP2" },
    { id: "206338", name: "AQPEP" },
    { id: "9716", name: "AQR" },
    { id: "367", name: "AR" },
    { id: "369", name: "ARAF" },
    { id: "116985", name: "ARAP1" },
    { id: "64411", name: "ARAP3" },
    { id: "23237", name: "ARC" },
    { id: "372", name: "ARCN1" },
    { id: "374", name: "AREG" },
    { id: "9870", name: "AREL1" },
    { id: "375", name: "ARF1" },
    { id: "377", name: "ARF3" },
    { id: "381", name: "ARF5" },
    { id: "382", name: "ARF6" },
    { id: "55738", name: "ARFGAP1" },
    { id: "84364", name: "ARFGAP2" },
    { id: "26286", name: "ARFGAP3" },
    { id: "10565", name: "ARFGEF1" },
    { id: "10564", name: "ARFGEF2" },
    { id: "27236", name: "ARFIP1" },
    { id: "23647", name: "ARFIP2" },
    { id: "10139", name: "ARFRP1" },
    { id: "383", name: "ARG1" },
    { id: "384", name: "ARG2" },
    { id: "55082", name: "ARGLU1" },
    { id: "392", name: "ARHGAP1" },
    { id: "79658", name: "ARHGAP10" },
    { id: "9824", name: "ARHGAP11A" },
    { id: "94134", name: "ARHGAP12" },
    { id: "55843", name: "ARHGAP15" },
    { id: "55114", name: "ARHGAP17" },
    { id: "93663", name: "ARHGAP18" },
    { id: "84986", name: "ARHGAP19" },
    { id: "57569", name: "ARHGAP20" },
    { id: "57584", name: "ARHGAP21" },
    { id: "58504", name: "ARHGAP22" },
    { id: "57636", name: "ARHGAP23" },
    { id: "83478", name: "ARHGAP24" },
    { id: "9938", name: "ARHGAP25" },
    { id: "23092", name: "ARHGAP26" },
    { id: "201176", name: "ARHGAP27" },
    { id: "9411", name: "ARHGAP29" },
    { id: "57514", name: "ARHGAP31" },
    { id: "9743", name: "ARHGAP32" },
    { id: "115703", name: "ARHGAP33" },
    { id: "2909", name: "ARHGAP35" },
    { id: "158763", name: "ARHGAP36" },
    { id: "393", name: "ARHGAP4" },
    { id: "9912", name: "ARHGAP44" },
    { id: "394", name: "ARHGAP5" },
    { id: "23779", name: "ARHGAP8" },
    { id: "64333", name: "ARHGAP9" },
    { id: "396", name: "ARHGDIA" },
    { id: "397", name: "ARHGDIB" },
    { id: "398", name: "ARHGDIG" },
    { id: "9138", name: "ARHGEF1" },
    { id: "9639", name: "ARHGEF10" },
    { id: "55160", name: "ARHGEF10L" },
    { id: "9826", name: "ARHGEF11" },
    { id: "23365", name: "ARHGEF12" },
    { id: "22899", name: "ARHGEF15" },
    { id: "27237", name: "ARHGEF16" },
    { id: "9828", name: "ARHGEF17" },
    { id: "23370", name: "ARHGEF18" },
    { id: "128272", name: "ARHGEF19" },
    { id: "9181", name: "ARHGEF2" },
    { id: "115557", name: "ARHGEF25" },
    { id: "64283", name: "ARHGEF28" },
    { id: "50650", name: "ARHGEF3" },
    { id: "445328", name: "ARHGEF35" },
    { id: "84904", name: "ARHGEF39" },
    { id: "50649", name: "ARHGEF4" },
    { id: "55701", name: "ARHGEF40" },
    { id: "7984", name: "ARHGEF5" },
    { id: "9459", name: "ARHGEF6" },
    { id: "8874", name: "ARHGEF7" },
    { id: "23229", name: "ARHGEF9" },
    { id: "8289", name: "ARID1A" },
    { id: "57492", name: "ARID1B" },
    { id: "196528", name: "ARID2" },
    { id: "1820", name: "ARID3A" },
    { id: "10620", name: "ARID3B" },
    { id: "138715", name: "ARID3C" },
    { id: "5926", name: "ARID4A" },
    { id: "51742", name: "ARID4B" },
    { id: "10865", name: "ARID5A" },
    { id: "84159", name: "ARID5B" },
    { id: "25820", name: "ARIH1" },
    { id: "10425", name: "ARIH2" },
    { id: "285598", name: "ARL10" },
    { id: "115761", name: "ARL11" },
    { id: "392509", name: "ARL13A" },
    { id: "200894", name: "ARL13B" },
    { id: "80117", name: "ARL14" },
    { id: "120534", name: "ARL14EP" },
    { id: "54622", name: "ARL15" },
    { id: "339231", name: "ARL16" },
    { id: "51326", name: "ARL17A" },
    { id: "23568", name: "ARL2BP" },
    { id: "10124", name: "ARL4A" },
    { id: "10123", name: "ARL4C" },
    { id: "379", name: "ARL4D" },
    { id: "26225", name: "ARL5A" },
    { id: "221079", name: "ARL5B" },
    { id: "84100", name: "ARL6" },
    { id: "23204", name: "ARL6IP1" },
    { id: "51329", name: "ARL6IP4" },
    { id: "10550", name: "ARL6IP5" },
    { id: "151188", name: "ARL6IP6" },
    { id: "127829", name: "ARL8A" },
    { id: "55207", name: "ARL8B" },
    { id: "55156", name: "ARMC1" },
    { id: "83787", name: "ARMC10" },
    { id: "84071", name: "ARMC2" },
    { id: "55130", name: "ARMC4" },
    { id: "79798", name: "ARMC5" },
    { id: "93436", name: "ARMC6" },
    { id: "79637", name: "ARMC7" },
    { id: "25852", name: "ARMC8" },
    { id: "80210", name: "ARMC9" },
    { id: "51309", name: "ARMCX1" },
    { id: "9823", name: "ARMCX2" },
    { id: "51566", name: "ARMCX3" },
    { id: "64860", name: "ARMCX5" },
    { id: "405", name: "ARNT" },
    { id: "9915", name: "ARNT2" },
    { id: "406", name: "ARNTL" },
    { id: "56938", name: "ARNTL2" },
    { id: "10552", name: "ARPC1A" },
    { id: "10095", name: "ARPC1B" },
    { id: "10109", name: "ARPC2" },
    { id: "10094", name: "ARPC3" },
    { id: "10093", name: "ARPC4" },
    { id: "100526693", name: "ARPC4-TTLL3" },
    { id: "10092", name: "ARPC5" },
    { id: "81873", name: "ARPC5L" },
    { id: "10776", name: "ARPP19" },
    { id: "10777", name: "ARPP21" },
    { id: "407", name: "ARR3" },
    { id: "408", name: "ARRB1" },
    { id: "409", name: "ARRB2" },
    { id: "92714", name: "ARRDC1" },
    { id: "27106", name: "ARRDC2" },
    { id: "57561", name: "ARRDC3" },
    { id: "91947", name: "ARRDC4" },
    { id: "645432", name: "ARRDC5" },
    { id: "410", name: "ARSA" },
    { id: "340075", name: "ARSI" },
    { id: "79642", name: "ARSJ" },
    { id: "153642", name: "ARSK" },
    { id: "419", name: "ART3" },
    { id: "420", name: "ART4" },
    { id: "9048", name: "ARTN" },
    { id: "64801", name: "ARV1" },
    { id: "170302", name: "ARX" },
    { id: "57412", name: "AS3MT" },
    { id: "427", name: "ASAH1" },
    { id: "56624", name: "ASAH2" },
    { id: "653308", name: "ASAH2B" },
    { id: "50807", name: "ASAP1" },
    { id: "8853", name: "ASAP2" },
    { id: "55616", name: "ASAP3" },
    { id: "51665", name: "ASB1" },
    { id: "136371", name: "ASB10" },
    { id: "140456", name: "ASB11" },
    { id: "142689", name: "ASB12" },
    { id: "79754", name: "ASB13" },
    { id: "142686", name: "ASB14" },
    { id: "142685", name: "ASB15" },
    { id: "92591", name: "ASB16" },
    { id: "127247", name: "ASB17" },
    { id: "401036", name: "ASB18" },
    { id: "51676", name: "ASB2" },
    { id: "51130", name: "ASB3" },
    { id: "51666", name: "ASB4" },
    { id: "140458", name: "ASB5" },
    { id: "140459", name: "ASB6" },
    { id: "140460", name: "ASB7" },
    { id: "140461", name: "ASB8" },
    { id: "140462", name: "ASB9" },
    { id: "51008", name: "ASCC1" },
    { id: "84164", name: "ASCC2" },
    { id: "10973", name: "ASCC3" },
    { id: "429", name: "ASCL1" },
    { id: "430", name: "ASCL2" },
    { id: "56676", name: "ASCL3" },
    { id: "121549", name: "ASCL4" },
    { id: "25842", name: "ASF1A" },
    { id: "55723", name: "ASF1B" },
    { id: "432", name: "ASGR1" },
    { id: "433", name: "ASGR2" },
    { id: "55870", name: "ASH1L" },
    { id: "9070", name: "ASH2L" },
    { id: "9311", name: "ASIC3" },
    { id: "55515", name: "ASIC4" },
    { id: "435", name: "ASL" },
    { id: "438", name: "ASMT" },
    { id: "8623", name: "ASMTL" },
    { id: "439", name: "ASNA1" },
    { id: "440", name: "ASNS" },
    { id: "54529", name: "ASNSD1" },
    { id: "443", name: "ASPA" },
    { id: "554235", name: "ASPDH" },
    { id: "444", name: "ASPH" },
    { id: "259266", name: "ASPM" },
    { id: "151516", name: "ASPRV1" },
    { id: "79058", name: "ASPSCR1" },
    { id: "80150", name: "ASRGL1" },
    { id: "445", name: "ASS1" },
    { id: "28990", name: "ASTE1" },
    { id: "431705", name: "ASTL" },
    { id: "23245", name: "ASTN2" },
    { id: "55726", name: "ASUN" },
    { id: "171023", name: "ASXL1" },
    { id: "55252", name: "ASXL2" },
    { id: "136991", name: "ASZ1" },
    { id: "84896", name: "ATAD1" },
    { id: "29028", name: "ATAD2" },
    { id: "54454", name: "ATAD2B" },
    { id: "55210", name: "ATAD3A" },
    { id: "83858", name: "ATAD3B" },
    { id: "219293", name: "ATAD3C" },
    { id: "79915", name: "ATAD5" },
    { id: "79969", name: "ATAT1" },
    { id: "85300", name: "ATCAY" },
    { id: "11101", name: "ATE1" },
    { id: "466", name: "ATF1" },
    { id: "1386", name: "ATF2" },
    { id: "467", name: "ATF3" },
    { id: "468", name: "ATF4" },
    { id: "22809", name: "ATF5" },
    { id: "22926", name: "ATF6" },
    { id: "1388", name: "ATF6B" },
    { id: "11016", name: "ATF7" },
    { id: "55729", name: "ATF7IP" },
    { id: "80063", name: "ATF7IP2" },
    { id: "83734", name: "ATG10" },
    { id: "9140", name: "ATG12" },
    { id: "9776", name: "ATG13" },
    { id: "22863", name: "ATG14" },
    { id: "55054", name: "ATG16L1" },
    { id: "89849", name: "ATG16L2" },
    { id: "23130", name: "ATG2A" },
    { id: "55102", name: "ATG2B" },
    { id: "64422", name: "ATG3" },
    { id: "115201", name: "ATG4A" },
    { id: "23192", name: "ATG4B" },
    { id: "84938", name: "ATG4C" },
    { id: "84971", name: "ATG4D" },
    { id: "9474", name: "ATG5" },
    { id: "10533", name: "ATG7" },
    { id: "79065", name: "ATG9A" },
    { id: "285973", name: "ATG9B" },
    { id: "80162", name: "ATHL1" },
    { id: "471", name: "ATIC" },
    { id: "51062", name: "ATL1" },
    { id: "64225", name: "ATL2" },
    { id: "25923", name: "ATL3" },
    { id: "472", name: "ATM" },
    { id: "23300", name: "ATMIN" },
    { id: "1822", name: "ATN1" },
    { id: "220202", name: "ATOH7" },
    { id: "84913", name: "ATOH8" },
    { id: "475", name: "ATOX1" },
    { id: "57205", name: "ATP10D" },
    { id: "23250", name: "ATP11A" },
    { id: "23200", name: "ATP11B" },
    { id: "286410", name: "ATP11C" },
    { id: "57130", name: "ATP13A1" },
    { id: "23400", name: "ATP13A2" },
    { id: "79572", name: "ATP13A3" },
    { id: "84239", name: "ATP13A4" },
    { id: "476", name: "ATP1A1" },
    { id: "481", name: "ATP1B1" },
    { id: "483", name: "ATP1B3" },
    { id: "23439", name: "ATP1B4" },
    { id: "488", name: "ATP2A2" },
    { id: "493", name: "ATP2B4" },
    { id: "27032", name: "ATP2C1" },
    { id: "9914", name: "ATP2C2" },
    { id: "495", name: "ATP4A" },
    { id: "496", name: "ATP4B" },
    { id: "498", name: "ATP5A1" },
    { id: "506", name: "ATP5B" },
    { id: "509", name: "ATP5C1" },
    { id: "513", name: "ATP5D" },
    { id: "515", name: "ATP5F1" },
    { id: "518", name: "ATP5G3" },
    { id: "10476", name: "ATP5H" },
    { id: "9551", name: "ATP5J2" },
    { id: "100526740", name: "ATP5J2-PTCD1" },
    { id: "10632", name: "ATP5L" },
    { id: "539", name: "ATP5O" },
    { id: "27109", name: "ATP5S" },
    { id: "537", name: "ATP6AP1" },
    { id: "10159", name: "ATP6AP2" },
    { id: "535", name: "ATP6V0A1" },
    { id: "23545", name: "ATP6V0A2" },
    { id: "50617", name: "ATP6V0A4" },
    { id: "533", name: "ATP6V0B" },
    { id: "527", name: "ATP6V0C" },
    { id: "9114", name: "ATP6V0D1" },
    { id: "245972", name: "ATP6V0D2" },
    { id: "8992", name: "ATP6V0E1" },
    { id: "155066", name: "ATP6V0E2" },
    { id: "523", name: "ATP6V1A" },
    { id: "525", name: "ATP6V1B1" },
    { id: "526", name: "ATP6V1B2" },
    { id: "528", name: "ATP6V1C1" },
    { id: "245973", name: "ATP6V1C2" },
    { id: "51382", name: "ATP6V1D" },
    { id: "529", name: "ATP6V1E1" },
    { id: "90423", name: "ATP6V1E2" },
    { id: "9296", name: "ATP6V1F" },
    { id: "9550", name: "ATP6V1G1" },
    { id: "534", name: "ATP6V1G2" },
    { id: "127124", name: "ATP6V1G3" },
    { id: "51606", name: "ATP6V1H" },
    { id: "538", name: "ATP7A" },
    { id: "540", name: "ATP7B" },
    { id: "51761", name: "ATP8A2" },
    { id: "57198", name: "ATP8B2" },
    { id: "148229", name: "ATP8B3" },
    { id: "79895", name: "ATP8B4" },
    { id: "10079", name: "ATP9A" },
    { id: "374868", name: "ATP9B" },
    { id: "64756", name: "ATPAF1" },
    { id: "91647", name: "ATPAF2" },
    { id: "93974", name: "ATPIF1" },
    { id: "545", name: "ATR" },
    { id: "51374", name: "ATRAID" },
    { id: "84126", name: "ATRIP" },
    { id: "8455", name: "ATRN" },
    { id: "26033", name: "ATRNL1" },
    { id: "6310", name: "ATXN1" },
    { id: "25814", name: "ATXN10" },
    { id: "342371", name: "ATXN1L" },
    { id: "6311", name: "ATXN2" },
    { id: "11273", name: "ATXN2L" },
    { id: "4287", name: "ATXN3" },
    { id: "92552", name: "ATXN3L" },
    { id: "6314", name: "ATXN7" },
    { id: "222255", name: "ATXN7L1" },
    { id: "127002", name: "ATXN7L2" },
    { id: "56970", name: "ATXN7L3" },
    { id: "552889", name: "ATXN7L3B" },
    { id: "549", name: "AUH" },
    { id: "79000", name: "AUNIP" },
    { id: "550", name: "AUP1" },
    { id: "6790", name: "AURKA" },
    { id: "54998", name: "AURKAIP1" },
    { id: "9212", name: "AURKB" },
    { id: "6795", name: "AURKC" },
    { id: "26053", name: "AUTS2" },
    { id: "57099", name: "AVEN" },
    { id: "10677", name: "AVIL" },
    { id: "23080", name: "AVL9" },
    { id: "60370", name: "AVPI1" },
    { id: "552", name: "AVPR1A" },
    { id: "553", name: "AVPR1B" },
    { id: "554", name: "AVPR2" },
    { id: "126859", name: "AXDND1" },
    { id: "8312", name: "AXIN1" },
    { id: "8313", name: "AXIN2" },
    { id: "558", name: "AXL" },
    { id: "563", name: "AZGP1" },
    { id: "22994", name: "AZI1" },
    { id: "64343", name: "AZI2" },
    { id: "51582", name: "AZIN1" },
    { id: "566", name: "AZU1" },
    { id: "567", name: "B2M" },
    { id: "8706", name: "B3GALNT1" },
    { id: "148789", name: "B3GALNT2" },
    { id: "8705", name: "B3GALT4" },
    { id: "126792", name: "B3GALT6" },
    { id: "27087", name: "B3GAT1" },
    { id: "26229", name: "B3GAT3" },
    { id: "11041", name: "B3GNT1" },
    { id: "10678", name: "B3GNT2" },
    { id: "79369", name: "B3GNT4" },
    { id: "84002", name: "B3GNT5" },
    { id: "84752", name: "B3GNT9" },
    { id: "146712", name: "B3GNTL1" },
    { id: "2583", name: "B4GALNT1" },
    { id: "124872", name: "B4GALNT2" },
    { id: "2683", name: "B4GALT1" },
    { id: "8704", name: "B4GALT2" },
    { id: "8703", name: "B4GALT3" },
    { id: "9334", name: "B4GALT5" },
    { id: "11285", name: "B4GALT7" },
    { id: "27077", name: "B9D1" },
    { id: "80776", name: "B9D2" },
    { id: "79870", name: "BAALC" },
    { id: "570", name: "BAAT" },
    { id: "29086", name: "BABAM1" },
    { id: "23621", name: "BACE1" },
    { id: "25825", name: "BACE2" },
    { id: "571", name: "BACH1" },
    { id: "60468", name: "BACH2" },
    { id: "572", name: "BAD" },
    { id: "573", name: "BAG1" },
    { id: "9532", name: "BAG2" },
    { id: "9531", name: "BAG3" },
    { id: "9530", name: "BAG4" },
    { id: "9529", name: "BAG5" },
    { id: "7917", name: "BAG6" },
    { id: "85316", name: "BAGE5" },
    { id: "57597", name: "BAHCC1" },
    { id: "22893", name: "BAHD1" },
    { id: "575", name: "BAI1" },
    { id: "576", name: "BAI2" },
    { id: "10458", name: "BAIAP2" },
    { id: "55971", name: "BAIAP2L1" },
    { id: "80115", name: "BAIAP2L2" },
    { id: "8938", name: "BAIAP3" },
    { id: "578", name: "BAK1" },
    { id: "25805", name: "BAMBI" },
    { id: "8815", name: "BANF1" },
    { id: "140836", name: "BANF2" },
    { id: "55024", name: "BANK1" },
    { id: "54971", name: "BANP" },
    { id: "8314", name: "BAP1" },
    { id: "580", name: "BARD1" },
    { id: "56751", name: "BARHL1" },
    { id: "343472", name: "BARHL2" },
    { id: "56033", name: "BARX1" },
    { id: "8538", name: "BARX2" },
    { id: "10409", name: "BASP1" },
    { id: "10538", name: "BATF" },
    { id: "116071", name: "BATF2" },
    { id: "55509", name: "BATF3" },
    { id: "581", name: "BAX" },
    { id: "11177", name: "BAZ1A" },
    { id: "9031", name: "BAZ1B" },
    { id: "11176", name: "BAZ2A" },
    { id: "29994", name: "BAZ2B" },
    { id: "27113", name: "BBC3" },
    { id: "92482", name: "BBIP1" },
    { id: "8424", name: "BBOX1" },
    { id: "582", name: "BBS1" },
    { id: "79738", name: "BBS10" },
    { id: "166379", name: "BBS12" },
    { id: "583", name: "BBS2" },
    { id: "585", name: "BBS4" },
    { id: "129880", name: "BBS5" },
    { id: "55212", name: "BBS7" },
    { id: "27241", name: "BBS9" },
    { id: "56987", name: "BBX" },
    { id: "4059", name: "BCAM" },
    { id: "63827", name: "BCAN" },
    { id: "55973", name: "BCAP29" },
    { id: "10134", name: "BCAP31" },
    { id: "9564", name: "BCAR1" },
    { id: "8412", name: "BCAR3" },
    { id: "8537", name: "BCAS1" },
    { id: "10286", name: "BCAS2" },
    { id: "54828", name: "BCAS3" },
    { id: "55653", name: "BCAS4" },
    { id: "586", name: "BCAT1" },
    { id: "587", name: "BCAT2" },
    { id: "56647", name: "BCCIP" },
    { id: "144233", name: "BCDIN3D" },
    { id: "590", name: "BCHE" },
    { id: "594", name: "BCKDHB" },
    { id: "10295", name: "BCKDK" },
    { id: "8915", name: "BCL10" },
    { id: "53335", name: "BCL11A" },
    { id: "64919", name: "BCL11B" },
    { id: "596", name: "BCL2" },
    { id: "597", name: "BCL2A1" },
    { id: "598", name: "BCL2L1" },
    { id: "10017", name: "BCL2L10" },
    { id: "10018", name: "BCL2L11" },
    { id: "83596", name: "BCL2L12" },
    { id: "23786", name: "BCL2L13" },
    { id: "79370", name: "BCL2L14" },
    { id: "440603", name: "BCL2L15" },
    { id: "599", name: "BCL2L2" },
    { id: "602", name: "BCL3" },
    { id: "604", name: "BCL6" },
    { id: "255877", name: "BCL6B" },
    { id: "605", name: "BCL7A" },
    { id: "9275", name: "BCL7B" },
    { id: "9274", name: "BCL7C" },
    { id: "283149", name: "BCL9L" },
    { id: "9774", name: "BCLAF1" },
    { id: "54880", name: "BCOR" },
    { id: "63035", name: "BCORL1" },
    { id: "613", name: "BCR" },
    { id: "622", name: "BDH1" },
    { id: "56898", name: "BDH2" },
    { id: "624", name: "BDKRB2" },
    { id: "627", name: "BDNF" },
    { id: "55814", name: "BDP1" },
    { id: "146227", name: "BEAN1" },
    { id: "8678", name: "BECN1" },
    { id: "441925", name: "BECN1P1" },
    { id: "57596", name: "BEGAIN" },
    { id: "139105", name: "BEND2" },
    { id: "57673", name: "BEND3" },
    { id: "389206", name: "BEND4" },
    { id: "79656", name: "BEND5" },
    { id: "222389", name: "BEND7" },
    { id: "7439", name: "BEST1" },
    { id: "10282", name: "BET1" },
    { id: "51272", name: "BET1L" },
    { id: "55859", name: "BEX1" },
    { id: "84707", name: "BEX2" },
    { id: "56271", name: "BEX4" },
    { id: "340542", name: "BEX5" },
    { id: "51283", name: "BFAR" },
    { id: "631", name: "BFSP1" },
    { id: "8419", name: "BFSP2" },
    { id: "632", name: "BGLAP" },
    { id: "633", name: "BGN" },
    { id: "168620", name: "BHLHA15" },
    { id: "80823", name: "BHLHB9" },
    { id: "128408", name: "BHLHE23" },
    { id: "8553", name: "BHLHE40" },
    { id: "79365", name: "BHLHE41" },
    { id: "635", name: "BHMT" },
    { id: "23743", name: "BHMT2" },
    { id: "80114", name: "BICC1" },
    { id: "636", name: "BICD1" },
    { id: "23299", name: "BICD2" },
    { id: "637", name: "BID" },
    { id: "638", name: "BIK" },
    { id: "274", name: "BIN1" },
    { id: "51411", name: "BIN2" },
    { id: "55909", name: "BIN3" },
    { id: "329", name: "BIRC2" },
    { id: "330", name: "BIRC3" },
    { id: "332", name: "BIRC5" },
    { id: "57448", name: "BIRC6" },
    { id: "79444", name: "BIRC7" },
    { id: "112401", name: "BIRC8" },
    { id: "54841", name: "BIVM" },
    { id: "10904", name: "BLCAP" },
    { id: "414899", name: "BLID" },
    { id: "640", name: "BLK" },
    { id: "641", name: "BLM" },
    { id: "642", name: "BLMH" },
    { id: "29760", name: "BLNK" },
    { id: "2647", name: "BLOC1S1" },
    { id: "282991", name: "BLOC1S2" },
    { id: "388552", name: "BLOC1S3" },
    { id: "55330", name: "BLOC1S4" },
    { id: "63915", name: "BLOC1S5" },
    { id: "100526836", name: "BLOC1S5-TXNDC5" },
    { id: "26258", name: "BLOC1S6" },
    { id: "644", name: "BLVRA" },
    { id: "8548", name: "BLZF1" },
    { id: "90427", name: "BMF" },
    { id: "648", name: "BMI1" },
    { id: "649", name: "BMP1" },
    { id: "27302", name: "BMP10" },
    { id: "9210", name: "BMP15" },
    { id: "650", name: "BMP2" },
    { id: "55589", name: "BMP2K" },
    { id: "652", name: "BMP4" },
    { id: "654", name: "BMP6" },
    { id: "655", name: "BMP7" },
    { id: "656", name: "BMP8B" },
    { id: "168667", name: "BMPER" },
    { id: "657", name: "BMPR1A" },
    { id: "658", name: "BMPR1B" },
    { id: "659", name: "BMPR2" },
    { id: "9790", name: "BMS1" },
    { id: "660", name: "BMX" },
    { id: "54796", name: "BNC2" },
    { id: "662", name: "BNIP1" },
    { id: "663", name: "BNIP2" },
    { id: "664", name: "BNIP3" },
    { id: "665", name: "BNIP3L" },
    { id: "149428", name: "BNIPL" },
    { id: "91653", name: "BOC" },
    { id: "91272", name: "BOD1" },
    { id: "259282", name: "BOD1L1" },
    { id: "284257", name: "BOD1L2" },
    { id: "666", name: "BOK" },
    { id: "51027", name: "BOLA1" },
    { id: "552900", name: "BOLA2" },
    { id: "654483", name: "BOLA2B" },
    { id: "388962", name: "BOLA3" },
    { id: "66037", name: "BOLL" },
    { id: "23246", name: "BOP1" },
    { id: "79866", name: "BORA" },
    { id: "669", name: "BPGM" },
    { id: "51297", name: "BPIFA1" },
    { id: "140683", name: "BPIFA2" },
    { id: "359710", name: "BPIFB3" },
    { id: "10380", name: "BPNT1" },
    { id: "9083", name: "BPY2" },
    { id: "442867", name: "BPY2B" },
    { id: "442868", name: "BPY2C" },
    { id: "673", name: "BRAF" },
    { id: "8315", name: "BRAP" },
    { id: "221927", name: "BRAT1" },
    { id: "672", name: "BRCA1" },
    { id: "675", name: "BRCA2" },
    { id: "79184", name: "BRCC3" },
    { id: "23774", name: "BRD1" },
    { id: "6046", name: "BRD2" },
    { id: "8019", name: "BRD3" },
    { id: "23476", name: "BRD4" },
    { id: "29117", name: "BRD7" },
    { id: "10902", name: "BRD8" },
    { id: "65980", name: "BRD9" },
    { id: "9577", name: "BRE" },
    { id: "2972", name: "BRF1" },
    { id: "55290", name: "BRF2" },
    { id: "25798", name: "BRI3" },
    { id: "140707", name: "BRI3BP" },
    { id: "283870", name: "BRICD5" },
    { id: "83990", name: "BRIP1" },
    { id: "55299", name: "BRIX1" },
    { id: "55845", name: "BRK1" },
    { id: "25855", name: "BRMS1" },
    { id: "84312", name: "BRMS1L" },
    { id: "148362", name: "BROX" },
    { id: "7862", name: "BRPF1" },
    { id: "27154", name: "BRPF3" },
    { id: "84446", name: "BRSK1" },
    { id: "9024", name: "BRSK2" },
    { id: "54014", name: "BRWD1" },
    { id: "254065", name: "BRWD3" },
    { id: "26580", name: "BSCL2" },
    { id: "55108", name: "BSDC1" },
    { id: "682", name: "BSG" },
    { id: "8927", name: "BSN" },
    { id: "7809", name: "BSND" },
    { id: "54836", name: "BSPRY" },
    { id: "683", name: "BST1" },
    { id: "684", name: "BST2" },
    { id: "9044", name: "BTAF1" },
    { id: "53339", name: "BTBD1" },
    { id: "84280", name: "BTBD10" },
    { id: "118663", name: "BTBD16" },
    { id: "55643", name: "BTBD2" },
    { id: "22903", name: "BTBD3" },
    { id: "90135", name: "BTBD6" },
    { id: "55727", name: "BTBD7" },
    { id: "284697", name: "BTBD8" },
    { id: "114781", name: "BTBD9" },
    { id: "686", name: "BTD" },
    { id: "689", name: "BTF3" },
    { id: "91408", name: "BTF3L4" },
    { id: "7832", name: "BTG2" },
    { id: "10950", name: "BTG3" },
    { id: "54766", name: "BTG4" },
    { id: "695", name: "BTK" },
    { id: "151888", name: "BTLA" },
    { id: "11120", name: "BTN2A1" },
    { id: "10385", name: "BTN2A2" },
    { id: "11119", name: "BTN3A1" },
    { id: "11118", name: "BTN3A2" },
    { id: "10384", name: "BTN3A3" },
    { id: "56244", name: "BTNL2" },
    { id: "79908", name: "BTNL8" },
    { id: "8945", name: "BTRC" },
    { id: "699", name: "BUB1" },
    { id: "701", name: "BUB1B" },
    { id: "9184", name: "BUB3" },
    { id: "84811", name: "BUD13" },
    { id: "8896", name: "BUD31" },
    { id: "11149", name: "BVES" },
    { id: "705", name: "BYSL" },
    { id: "9256", name: "BZRAP1" },
    { id: "9689", name: "BZW1" },
    { id: "28969", name: "BZW2" },
    { id: "11067", name: "C10orf10" },
    { id: "221060", name: "C10orf111" },
    { id: "55088", name: "C10orf118" },
    { id: "26148", name: "C10orf12" },
    { id: "399814", name: "C10orf120" },
    { id: "100127889", name: "C10orf131" },
    { id: "26098", name: "C10orf137" },
    { id: "56652", name: "C10orf2" },
    { id: "119032", name: "C10orf32" },
    { id: "219738", name: "C10orf35" },
    { id: "64115", name: "C10orf54" },
    { id: "414236", name: "C10orf55" },
    { id: "414157", name: "C10orf62" },
    { id: "79591", name: "C10orf76" },
    { id: "143379", name: "C10orf82" },
    { id: "404216", name: "C10orf85" },
    { id: "80007", name: "C10orf88" },
    { id: "118611", name: "C10orf90" },
    { id: "64776", name: "C11orf1" },
    { id: "56673", name: "C11orf16" },
    { id: "56946", name: "C11orf30" },
    { id: "256329", name: "C11orf35" },
    { id: "219833", name: "C11orf45" },
    { id: "79081", name: "C11orf48" },
    { id: "79096", name: "C11orf49" },
    { id: "91894", name: "C11orf52" },
    { id: "341032", name: "C11orf53" },
    { id: "28970", name: "C11orf54" },
    { id: "55216", name: "C11orf57" },
    { id: "10944", name: "C11orf58" },
    { id: "79864", name: "C11orf63" },
    { id: "160140", name: "C11orf65" },
    { id: "83638", name: "C11orf68" },
    { id: "85016", name: "C11orf70" },
    { id: "54494", name: "C11orf71" },
    { id: "51501", name: "C11orf73" },
    { id: "119710", name: "C11orf74" },
    { id: "79703", name: "C11orf80" },
    { id: "790955", name: "C11orf83" },
    { id: "144097", name: "C11orf84" },
    { id: "399947", name: "C11orf87" },
    { id: "60314", name: "C12orf10" },
    { id: "90488", name: "C12orf23" },
    { id: "91298", name: "C12orf29" },
    { id: "80763", name: "C12orf39" },
    { id: "57102", name: "C12orf4" },
    { id: "283461", name: "C12orf40" },
    { id: "64897", name: "C12orf43" },
    { id: "60673", name: "C12orf44" },
    { id: "121053", name: "C12orf45" },
    { id: "79794", name: "C12orf49" },
    { id: "160419", name: "C12orf50" },
    { id: "84934", name: "C12orf52" },
    { id: "113246", name: "C12orf57" },
    { id: "144608", name: "C12orf60" },
    { id: "91574", name: "C12orf65" },
    { id: "387856", name: "C12orf68" },
    { id: "338809", name: "C12orf74" },
    { id: "387882", name: "C12orf75" },
    { id: "400073", name: "C12orf76" },
    { id: "11161", name: "C14orf1" },
    { id: "55195", name: "C14orf105" },
    { id: "55017", name: "C14orf119" },
    { id: "84520", name: "C14orf142" },
    { id: "80017", name: "C14orf159" },
    { id: "51637", name: "C14orf166" },
    { id: "283579", name: "C14orf178" },
    { id: "400258", name: "C14orf180" },
    { id: "122525", name: "C14orf28" },
    { id: "317761", name: "C14orf39" },
    { id: "283643", name: "C14orf80" },
    { id: "60686", name: "C14orf93" },
    { id: "161502", name: "C15orf26" },
    { id: "123591", name: "C15orf27" },
    { id: "348110", name: "C15orf38" },
    { id: "56905", name: "C15orf39" },
    { id: "123207", name: "C15orf40" },
    { id: "84529", name: "C15orf41" },
    { id: "145645", name: "C15orf43" },
    { id: "388115", name: "C15orf52" },
    { id: "400360", name: "C15orf54" },
    { id: "90416", name: "C15orf57" },
    { id: "388135", name: "C15orf59" },
    { id: "146325", name: "C16orf11" },
    { id: "84326", name: "C16orf13" },
    { id: "89927", name: "C16orf45" },
    { id: "283897", name: "C16orf54" },
    { id: "64755", name: "C16orf58" },
    { id: "80178", name: "C16orf59" },
    { id: "57020", name: "C16orf62" },
    { id: "80262", name: "C16orf70" },
    { id: "29035", name: "C16orf72" },
    { id: "404550", name: "C16orf74" },
    { id: "123970", name: "C16orf78" },
    { id: "29105", name: "C16orf80" },
    { id: "388272", name: "C16orf87" },
    { id: "146556", name: "C16orf89" },
    { id: "146378", name: "C16orf92" },
    { id: "256302", name: "C17orf103" },
    { id: "284067", name: "C17orf105" },
    { id: "284083", name: "C17orf47" },
    { id: "124944", name: "C17orf49" },
    { id: "146853", name: "C17orf50" },
    { id: "339263", name: "C17orf51" },
    { id: "78995", name: "C17orf53" },
    { id: "54785", name: "C17orf59" },
    { id: "79415", name: "C17orf62" },
    { id: "256957", name: "C17orf66" },
    { id: "339210", name: "C17orf67" },
    { id: "80233", name: "C17orf70" },
    { id: "92340", name: "C17orf72" },
    { id: "64149", name: "C17orf75" },
    { id: "146723", name: "C17orf77" },
    { id: "55028", name: "C17orf80" },
    { id: "388407", name: "C17orf82" },
    { id: "55421", name: "C17orf85" },
    { id: "100170841", name: "C17orf96" },
    { id: "400566", name: "C17orf97" },
    { id: "83608", name: "C18orf21" },
    { id: "147339", name: "C18orf25" },
    { id: "497661", name: "C18orf32" },
    { id: "162681", name: "C18orf54" },
    { id: "494514", name: "C18orf56" },
    { id: "644041", name: "C18orf63" },
    { id: "29919", name: "C18orf8" },
    { id: "56005", name: "C19orf10" },
    { id: "83636", name: "C19orf12" },
    { id: "147685", name: "C19orf18" },
    { id: "148223", name: "C19orf25" },
    { id: "255809", name: "C19orf38" },
    { id: "91442", name: "C19orf40" },
    { id: "79002", name: "C19orf43" },
    { id: "84167", name: "C19orf44" },
    { id: "374877", name: "C19orf45" },
    { id: "126526", name: "C19orf47" },
    { id: "90580", name: "C19orf52" },
    { id: "28974", name: "C19orf53" },
    { id: "284325", name: "C19orf54" },
    { id: "148137", name: "C19orf55" },
    { id: "79173", name: "C19orf57" },
    { id: "199675", name: "C19orf59" },
    { id: "55049", name: "C19orf60" },
    { id: "55337", name: "C19orf66" },
    { id: "646457", name: "C19orf67" },
    { id: "374920", name: "C19orf68" },
    { id: "125988", name: "C19orf70" },
    { id: "55150", name: "C19orf73" },
    { id: "55908", name: "C19orf80" },
    { id: "342918", name: "C19orf81" },
    { id: "10438", name: "C1D" },
    { id: "56913", name: "C1GALT1" },
    { id: "29071", name: "C1GALT1C1" },
    { id: "257044", name: "C1orf101" },
    { id: "92346", name: "C1orf105" },
    { id: "55765", name: "C1orf106" },
    { id: "54955", name: "C1orf109" },
    { id: "339512", name: "C1orf110" },
    { id: "284680", name: "C1orf111" },
    { id: "55732", name: "C1orf112" },
    { id: "79762", name: "C1orf115" },
    { id: "79098", name: "C1orf116" },
    { id: "127687", name: "C1orf122" },
    { id: "54987", name: "C1orf123" },
    { id: "128061", name: "C1orf131" },
    { id: "199920", name: "C1orf168" },
    { id: "84808", name: "C1orf170" },
    { id: "126695", name: "C1orf172" },
    { id: "127254", name: "C1orf173" },
    { id: "339448", name: "C1orf174" },
    { id: "439927", name: "C1orf180" },
    { id: "388701", name: "C1orf189" },
    { id: "84886", name: "C1orf198" },
    { id: "81563", name: "C1orf21" },
    { id: "127703", name: "C1orf216" },
    { id: "400793", name: "C1orf226" },
    { id: "339541", name: "C1orf228" },
    { id: "643988", name: "C1orf233" },
    { id: "79169", name: "C1orf35" },
    { id: "25912", name: "C1orf43" },
    { id: "79078", name: "C1orf50" },
    { id: "148523", name: "C1orf51" },
    { id: "148423", name: "C1orf52" },
    { id: "388722", name: "C1orf53" },
    { id: "54964", name: "C1orf56" },
    { id: "10485", name: "C1orf61" },
    { id: "57035", name: "C1orf63" },
    { id: "149563", name: "C1orf64" },
    { id: "164127", name: "C1orf65" },
    { id: "148304", name: "C1orf74" },
    { id: "112770", name: "C1orf85" },
    { id: "199990", name: "C1orf86" },
    { id: "127795", name: "C1orf87" },
    { id: "84970", name: "C1orf94" },
    { id: "375057", name: "C1orf95" },
    { id: "712", name: "C1QA" },
    { id: "713", name: "C1QB" },
    { id: "708", name: "C1QBP" },
    { id: "714", name: "C1QC" },
    { id: "389941", name: "C1QL3" },
    { id: "338761", name: "C1QL4" },
    { id: "114897", name: "C1QTNF1" },
    { id: "114898", name: "C1QTNF2" },
    { id: "114899", name: "C1QTNF3" },
    { id: "114900", name: "C1QTNF4" },
    { id: "114902", name: "C1QTNF5" },
    { id: "114904", name: "C1QTNF6" },
    { id: "114905", name: "C1QTNF7" },
    { id: "390664", name: "C1QTNF8" },
    { id: "338872", name: "C1QTNF9" },
    { id: "387911", name: "C1QTNF9B" },
    { id: "542767", name: "C1QTNF9B-AS1" },
    { id: "715", name: "C1R" },
    { id: "51279", name: "C1RL" },
    { id: "716", name: "C1S" },
    { id: "717", name: "C2" },
    { id: "140688", name: "C20orf112" },
    { id: "128653", name: "C20orf141" },
    { id: "140873", name: "C20orf173" },
    { id: "25943", name: "C20orf194" },
    { id: "79025", name: "C20orf195" },
    { id: "149840", name: "C20orf196" },
    { id: "284756", name: "C20orf197" },
    { id: "55969", name: "C20orf24" },
    { id: "54976", name: "C20orf27" },
    { id: "140834", name: "C20orf62" },
    { id: "128602", name: "C20orf85" },
    { id: "140680", name: "C20orf96" },
    { id: "84996", name: "C21orf119" },
    { id: "755", name: "C21orf2" },
    { id: "8209", name: "C21orf33" },
    { id: "54058", name: "C21orf58" },
    { id: "56683", name: "C21orf59" },
    { id: "54149", name: "C21orf91" },
    { id: "150248", name: "C22orf15" },
    { id: "84645", name: "C22orf23" },
    { id: "25775", name: "C22orf24" },
    { id: "55267", name: "C22orf26" },
    { id: "128977", name: "C22orf39" },
    { id: "51233", name: "C22orf43" },
    { id: "79640", name: "C22orf46" },
    { id: "25966", name: "C2CD2" },
    { id: "9854", name: "C2CD2L" },
    { id: "26005", name: "C2CD3" },
    { id: "9847", name: "C2CD5" },
    { id: "150590", name: "C2orf15" },
    { id: "84226", name: "C2orf16" },
    { id: "408029", name: "C2orf27B" },
    { id: "84417", name: "C2orf40" },
    { id: "54980", name: "C2orf42" },
    { id: "60526", name: "C2orf43" },
    { id: "80304", name: "C2orf44" },
    { id: "79568", name: "C2orf47" },
    { id: "79074", name: "C2orf49" },
    { id: "130813", name: "C2orf50" },
    { id: "339779", name: "C2orf53" },
    { id: "165100", name: "C2orf57" },
    { id: "285051", name: "C2orf61" },
    { id: "375307", name: "C2orf62" },
    { id: "401027", name: "C2orf66" },
    { id: "388969", name: "C2orf68" },
    { id: "205327", name: "C2orf69" },
    { id: "129852", name: "C2orf73" },
    { id: "339804", name: "C2orf74" },
    { id: "130355", name: "C2orf76" },
    { id: "389084", name: "C2orf82" },
    { id: "84281", name: "C2orf88" },
    { id: "718", name: "C3" },
    { id: "719", name: "C3AR1" },
    { id: "25871", name: "C3orf17" },
    { id: "84077", name: "C3orf20" },
    { id: "285315", name: "C3orf33" },
    { id: "80111", name: "C3orf36" },
    { id: "56941", name: "C3orf37" },
    { id: "285237", name: "C3orf38" },
    { id: "255798", name: "C3orf43" },
    { id: "79669", name: "C3orf52" },
    { id: "152078", name: "C3orf55" },
    { id: "375341", name: "C3orf62" },
    { id: "200844", name: "C3orf67" },
    { id: "720", name: "C4A" },
    { id: "721", name: "C4B" },
    { id: "722", name: "C4BPA" },
    { id: "84103", name: "C4orf17" },
    { id: "55286", name: "C4orf19" },
    { id: "55345", name: "C4orf21" },
    { id: "255119", name: "C4orf22" },
    { id: "152816", name: "C4orf26" },
    { id: "54969", name: "C4orf27" },
    { id: "80167", name: "C4orf29" },
    { id: "401152", name: "C4orf3" },
    { id: "132720", name: "C4orf32" },
    { id: "132321", name: "C4orf33" },
    { id: "152940", name: "C4orf45" },
    { id: "201725", name: "C4orf46" },
    { id: "727", name: "C5" },
    { id: "728", name: "C5AR1" },
    { id: "140947", name: "C5orf20" },
    { id: "55322", name: "C5orf22" },
    { id: "134553", name: "C5orf24" },
    { id: "202299", name: "C5orf27" },
    { id: "64417", name: "C5orf28" },
    { id: "90355", name: "C5orf30" },
    { id: "375444", name: "C5orf34" },
    { id: "65250", name: "C5orf42" },
    { id: "51149", name: "C5orf45" },
    { id: "389336", name: "C5orf46" },
    { id: "134121", name: "C5orf49" },
    { id: "285636", name: "C5orf51" },
    { id: "63920", name: "C5orf54" },
    { id: "116349", name: "C5orf55" },
    { id: "285679", name: "C5orf60" },
    { id: "729", name: "C6" },
    { id: "64771", name: "C6orf106" },
    { id: "168090", name: "C6orf118" },
    { id: "387263", name: "C6orf120" },
    { id: "221545", name: "C6orf136" },
    { id: "135398", name: "C6orf141" },
    { id: "29113", name: "C6orf15" },
    { id: "154313", name: "C6orf165" },
    { id: "404220", name: "C6orf201" },
    { id: "51250", name: "C6orf203" },
    { id: "79624", name: "C6orf211" },
    { id: "389384", name: "C6orf222" },
    { id: "221416", name: "C6orf223" },
    { id: "441150", name: "C6orf226" },
    { id: "80739", name: "C6orf25" },
    { id: "57827", name: "C6orf47" },
    { id: "50854", name: "C6orf48" },
    { id: "135154", name: "C6orf57" },
    { id: "81688", name: "C6orf62" },
    { id: "221477", name: "C6orf89" },
    { id: "730", name: "C7" },
    { id: "79020", name: "C7orf25" },
    { id: "79034", name: "C7orf26" },
    { id: "136895", name: "C7orf31" },
    { id: "135927", name: "C7orf34" },
    { id: "222166", name: "C7orf41" },
    { id: "55262", name: "C7orf43" },
    { id: "78996", name: "C7orf49" },
    { id: "84310", name: "C7orf50" },
    { id: "154791", name: "C7orf55" },
    { id: "154743", name: "C7orf60" },
    { id: "219557", name: "C7orf62" },
    { id: "732", name: "C8B" },
    { id: "733", name: "C8G" },
    { id: "65265", name: "C8orf33" },
    { id: "116328", name: "C8orf34" },
    { id: "157657", name: "C8orf37" },
    { id: "56892", name: "C8orf4" },
    { id: "254778", name: "C8orf46" },
    { id: "203111", name: "C8orf47" },
    { id: "157773", name: "C8orf48" },
    { id: "401466", name: "C8orf59" },
    { id: "203076", name: "C8orf74" },
    { id: "84933", name: "C8orf76" },
    { id: "735", name: "C9" },
    { id: "414318", name: "C9orf106" },
    { id: "51490", name: "C9orf114" },
    { id: "138162", name: "C9orf116" },
    { id: "286207", name: "C9orf117" },
    { id: "90871", name: "C9orf123" },
    { id: "401563", name: "C9orf139" },
    { id: "286257", name: "C9orf142" },
    { id: "389766", name: "C9orf153" },
    { id: "51531", name: "C9orf156" },
    { id: "79095", name: "C9orf16" },
    { id: "158055", name: "C9orf163" },
    { id: "375791", name: "C9orf169" },
    { id: "389799", name: "C9orf171" },
    { id: "84688", name: "C9orf24" },
    { id: "85026", name: "C9orf37" },
    { id: "55071", name: "C9orf40" },
    { id: "138199", name: "C9orf41" },
    { id: "257169", name: "C9orf43" },
    { id: "375759", name: "C9orf50" },
    { id: "84267", name: "C9orf64" },
    { id: "203228", name: "C9orf72" },
    { id: "51759", name: "C9orf78" },
    { id: "84270", name: "C9orf89" },
    { id: "11092", name: "C9orf9" },
    { id: "203197", name: "C9orf91" },
    { id: "771", name: "CA12" },
    { id: "377677", name: "CA13" },
    { id: "23632", name: "CA14" },
    { id: "761", name: "CA3" },
    { id: "11238", name: "CA5B" },
    { id: "768", name: "CA9" },
    { id: "79886", name: "CAAP1" },
    { id: "51719", name: "CAB39" },
    { id: "81617", name: "CAB39L" },
    { id: "23523", name: "CABIN1" },
    { id: "91768", name: "CABLES1" },
    { id: "81928", name: "CABLES2" },
    { id: "9478", name: "CABP1" },
    { id: "51475", name: "CABP2" },
    { id: "57010", name: "CABP4" },
    { id: "56344", name: "CABP5" },
    { id: "26256", name: "CABYR" },
    { id: "11094", name: "CACFD1" },
    { id: "57685", name: "CACHD1" },
    { id: "773", name: "CACNA1A" },
    { id: "774", name: "CACNA1B" },
    { id: "777", name: "CACNA1E" },
    { id: "8913", name: "CACNA1G" },
    { id: "8912", name: "CACNA1H" },
    { id: "8911", name: "CACNA1I" },
    { id: "9254", name: "CACNA2D2" },
    { id: "55799", name: "CACNA2D3" },
    { id: "782", name: "CACNB1" },
    { id: "784", name: "CACNB3" },
    { id: "785", name: "CACNB4" },
    { id: "786", name: "CACNG1" },
    { id: "10369", name: "CACNG2" },
    { id: "10368", name: "CACNG3" },
    { id: "27092", name: "CACNG4" },
    { id: "58509", name: "CACTIN" },
    { id: "143384", name: "CACUL1" },
    { id: "27101", name: "CACYBP" },
    { id: "790", name: "CAD" },
    { id: "23705", name: "CADM1" },
    { id: "57863", name: "CADM3" },
    { id: "199731", name: "CADM4" },
    { id: "8618", name: "CADPS" },
    { id: "93664", name: "CADPS2" },
    { id: "285782", name: "CAGE1" },
    { id: "793", name: "CALB1" },
    { id: "796", name: "CALCA" },
    { id: "57658", name: "CALCOCO1" },
    { id: "10241", name: "CALCOCO2" },
    { id: "799", name: "CALCR" },
    { id: "10203", name: "CALCRL" },
    { id: "800", name: "CALD1" },
    { id: "255022", name: "CALHM1" },
    { id: "801", name: "CALM1" },
    { id: "805", name: "CALM2" },
    { id: "808", name: "CALM3" },
    { id: "51806", name: "CALML5" },
    { id: "163688", name: "CALML6" },
    { id: "83698", name: "CALN1" },
    { id: "811", name: "CALR" },
    { id: "125972", name: "CALR3" },
    { id: "813", name: "CALU" },
    { id: "50632", name: "CALY" },
    { id: "8536", name: "CAMK1" },
    { id: "57118", name: "CAMK1D" },
    { id: "57172", name: "CAMK1G" },
    { id: "815", name: "CAMK2A" },
    { id: "816", name: "CAMK2B" },
    { id: "817", name: "CAMK2D" },
    { id: "818", name: "CAMK2G" },
    { id: "55450", name: "CAMK2N1" },
    { id: "94032", name: "CAMK2N2" },
    { id: "814", name: "CAMK4" },
    { id: "84254", name: "CAMKK1" },
    { id: "10645", name: "CAMKK2" },
    { id: "79012", name: "CAMKV" },
    { id: "819", name: "CAMLG" },
    { id: "157922", name: "CAMSAP1" },
    { id: "23271", name: "CAMSAP2" },
    { id: "57662", name: "CAMSAP3" },
    { id: "23261", name: "CAMTA1" },
    { id: "23125", name: "CAMTA2" },
    { id: "55832", name: "CAND1" },
    { id: "23066", name: "CAND2" },
    { id: "124583", name: "CANT1" },
    { id: "821", name: "CANX" },
    { id: "10487", name: "CAP1" },
    { id: "10486", name: "CAP2" },
    { id: "823", name: "CAPN1" },
    { id: "11132", name: "CAPN10" },
    { id: "11131", name: "CAPN11" },
    { id: "824", name: "CAPN2" },
    { id: "825", name: "CAPN3" },
    { id: "827", name: "CAPN6" },
    { id: "23473", name: "CAPN7" },
    { id: "826", name: "CAPNS1" },
    { id: "84290", name: "CAPNS2" },
    { id: "4076", name: "CAPRIN1" },
    { id: "65981", name: "CAPRIN2" },
    { id: "133690", name: "CAPSL" },
    { id: "829", name: "CAPZA1" },
    { id: "830", name: "CAPZA2" },
    { id: "93661", name: "CAPZA3" },
    { id: "832", name: "CAPZB" },
    { id: "29775", name: "CARD10" },
    { id: "84433", name: "CARD11" },
    { id: "79092", name: "CARD14" },
    { id: "114769", name: "CARD16" },
    { id: "440068", name: "CARD17" },
    { id: "59082", name: "CARD18" },
    { id: "84674", name: "CARD6" },
    { id: "22900", name: "CARD8" },
    { id: "64170", name: "CARD9" },
    { id: "79800", name: "CARF" },
    { id: "23589", name: "CARHSP1" },
    { id: "55739", name: "CARKD" },
    { id: "10498", name: "CARM1" },
    { id: "57571", name: "CARNS1" },
    { id: "833", name: "CARS" },
    { id: "79587", name: "CARS2" },
    { id: "9607", name: "CARTPT" },
    { id: "55259", name: "CASC1" },
    { id: "22794", name: "CASC3" },
    { id: "113201", name: "CASC4" },
    { id: "57082", name: "CASC5" },
    { id: "8573", name: "CASK" },
    { id: "57524", name: "CASKIN1" },
    { id: "57513", name: "CASKIN2" },
    { id: "834", name: "CASP1" },
    { id: "843", name: "CASP10" },
    { id: "100506742", name: "CASP12" },
    { id: "23581", name: "CASP14" },
    { id: "835", name: "CASP2" },
    { id: "836", name: "CASP3" },
    { id: "837", name: "CASP4" },
    { id: "838", name: "CASP5" },
    { id: "839", name: "CASP6" },
    { id: "840", name: "CASP7" },
    { id: "841", name: "CASP8" },
    { id: "9994", name: "CASP8AP2" },
    { id: "842", name: "CASP9" },
    { id: "845", name: "CASQ2" },
    { id: "846", name: "CASR" },
    { id: "57091", name: "CASS4" },
    { id: "831", name: "CAST" },
    { id: "54897", name: "CASZ1" },
    { id: "847", name: "CAT" },
    { id: "117144", name: "CATSPER1" },
    { id: "117155", name: "CATSPER2" },
    { id: "257062", name: "CATSPERD" },
    { id: "57828", name: "CATSPERG" },
    { id: "857", name: "CAV1" },
    { id: "858", name: "CAV2" },
    { id: "859", name: "CAV3" },
    { id: "9139", name: "CBFA2T2" },
    { id: "863", name: "CBFA2T3" },
    { id: "865", name: "CBFB" },
    { id: "867", name: "CBL" },
    { id: "868", name: "CBLB" },
    { id: "23624", name: "CBLC" },
    { id: "79872", name: "CBLL1" },
    { id: "643866", name: "CBLN3" },
    { id: "140689", name: "CBLN4" },
    { id: "874", name: "CBR3" },
    { id: "84869", name: "CBR4" },
    { id: "875", name: "CBS" },
    { id: "55871", name: "CBWD1" },
    { id: "150472", name: "CBWD2" },
    { id: "445571", name: "CBWD3" },
    { id: "220869", name: "CBWD5" },
    { id: "644019", name: "CBWD6" },
    { id: "10951", name: "CBX1" },
    { id: "84733", name: "CBX2" },
    { id: "11335", name: "CBX3" },
    { id: "8535", name: "CBX4" },
    { id: "23468", name: "CBX5" },
    { id: "23466", name: "CBX6" },
    { id: "23492", name: "CBX7" },
    { id: "57332", name: "CBX8" },
    { id: "25776", name: "CBY1" },
    { id: "646019", name: "CBY3" },
    { id: "54862", name: "CC2D1A" },
    { id: "200014", name: "CC2D1B" },
    { id: "57545", name: "CC2D2A" },
    { id: "55749", name: "CCAR1" },
    { id: "147372", name: "CCBE1" },
    { id: "883", name: "CCBL1" },
    { id: "56267", name: "CCBL2" },
    { id: "1238", name: "CCBP2" },
    { id: "112869", name: "CCDC101" },
    { id: "92922", name: "CCDC102A" },
    { id: "79839", name: "CCDC102B" },
    { id: "388389", name: "CCDC103" },
    { id: "112942", name: "CCDC104" },
    { id: "126402", name: "CCDC105" },
    { id: "29903", name: "CCDC106" },
    { id: "203260", name: "CCDC107" },
    { id: "255101", name: "CCDC108" },
    { id: "55013", name: "CCDC109B" },
    { id: "220136", name: "CCDC11" },
    { id: "256309", name: "CCDC110" },
    { id: "201973", name: "CCDC111" },
    { id: "153733", name: "CCDC112" },
    { id: "29070", name: "CCDC113" },
    { id: "93233", name: "CCDC114" },
    { id: "84317", name: "CCDC115" },
    { id: "164592", name: "CCDC116" },
    { id: "150275", name: "CCDC117" },
    { id: "151903", name: "CCDC12" },
    { id: "90060", name: "CCDC120" },
    { id: "79635", name: "CCDC121" },
    { id: "115098", name: "CCDC124" },
    { id: "202243", name: "CCDC125" },
    { id: "90693", name: "CCDC126" },
    { id: "133957", name: "CCDC127" },
    { id: "152206", name: "CCDC13" },
    { id: "81576", name: "CCDC130" },
    { id: "55610", name: "CCDC132" },
    { id: "79879", name: "CCDC134" },
    { id: "84229", name: "CCDC135" },
    { id: "64753", name: "CCDC136" },
    { id: "339230", name: "CCDC137" },
    { id: "165055", name: "CCDC138" },
    { id: "64770", name: "CCDC14" },
    { id: "285025", name: "CCDC141" },
    { id: "9720", name: "CCDC144A" },
    { id: "339184", name: "CCDC144NL" },
    { id: "57639", name: "CCDC146" },
    { id: "159686", name: "CCDC147" },
    { id: "130940", name: "CCDC148" },
    { id: "91050", name: "CCDC149" },
    { id: "80071", name: "CCDC15" },
    { id: "284992", name: "CCDC150" },
    { id: "115948", name: "CCDC151" },
    { id: "283152", name: "CCDC153" },
    { id: "147872", name: "CCDC155" },
    { id: "550631", name: "CCDC157" },
    { id: "339965", name: "CCDC158" },
    { id: "126075", name: "CCDC159" },
    { id: "154467", name: "CCDC167" },
    { id: "643677", name: "CCDC168" },
    { id: "149483", name: "CCDC17" },
    { id: "80129", name: "CCDC170" },
    { id: "203238", name: "CCDC171" },
    { id: "374355", name: "CCDC172" },
    { id: "51244", name: "CCDC174" },
    { id: "80127", name: "CCDC176" },
    { id: "343099", name: "CCDC18" },
    { id: "57821", name: "CCDC181" },
    { id: "25790", name: "CCDC19" },
    { id: "28952", name: "CCDC22" },
    { id: "374969", name: "CCDC23" },
    { id: "149473", name: "CCDC24" },
    { id: "55246", name: "CCDC25" },
    { id: "137196", name: "CCDC26" },
    { id: "148870", name: "CCDC27" },
    { id: "25901", name: "CCDC28A" },
    { id: "79140", name: "CCDC28B" },
    { id: "728621", name: "CCDC30" },
    { id: "80125", name: "CCDC33" },
    { id: "339834", name: "CCDC36" },
    { id: "348807", name: "CCDC37" },
    { id: "339829", name: "CCDC39" },
    { id: "55036", name: "CCDC40" },
    { id: "51134", name: "CCDC41" },
    { id: "146849", name: "CCDC42" },
    { id: "387885", name: "CCDC42B" },
    { id: "124808", name: "CCDC43" },
    { id: "57003", name: "CCDC47" },
    { id: "152137", name: "CCDC50" },
    { id: "79714", name: "CCDC51" },
    { id: "51019", name: "CCDC53" },
    { id: "284001", name: "CCDC57" },
    { id: "131076", name: "CCDC58" },
    { id: "29080", name: "CCDC59" },
    { id: "8030", name: "CCDC6" },
    { id: "160777", name: "CCDC60" },
    { id: "729440", name: "CCDC61" },
    { id: "92558", name: "CCDC64" },
    { id: "146439", name: "CCDC64B" },
    { id: "285331", name: "CCDC66" },
    { id: "159989", name: "CCDC67" },
    { id: "80323", name: "CCDC68" },
    { id: "26112", name: "CCDC69" },
    { id: "83446", name: "CCDC70" },
    { id: "64925", name: "CCDC71" },
    { id: "168455", name: "CCDC71L" },
    { id: "90557", name: "CCDC74A" },
    { id: "91409", name: "CCDC74B" },
    { id: "84318", name: "CCDC77" },
    { id: "83987", name: "CCDC8" },
    { id: "151887", name: "CCDC80" },
    { id: "79780", name: "CCDC82" },
    { id: "338657", name: "CCDC84" },
    { id: "114800", name: "CCDC85A" },
    { id: "11007", name: "CCDC85B" },
    { id: "317762", name: "CCDC85C" },
    { id: "79080", name: "CCDC86" },
    { id: "55231", name: "CCDC87" },
    { id: "55704", name: "CCDC88A" },
    { id: "283234", name: "CCDC88B" },
    { id: "440193", name: "CCDC88C" },
    { id: "220388", name: "CCDC89" },
    { id: "26093", name: "CCDC9" },
    { id: "60492", name: "CCDC90B" },
    { id: "55297", name: "CCDC91" },
    { id: "80212", name: "CCDC92" },
    { id: "54520", name: "CCDC93" },
    { id: "55702", name: "CCDC94" },
    { id: "257236", name: "CCDC96" },
    { id: "90324", name: "CCDC97" },
    { id: "196477", name: "CCER1" },
    { id: "54535", name: "CCHCR1" },
    { id: "881", name: "CCIN" },
    { id: "886", name: "CCKAR" },
    { id: "887", name: "CCKBR" },
    { id: "6346", name: "CCL1" },
    { id: "6356", name: "CCL11" },
    { id: "6357", name: "CCL13" },
    { id: "6358", name: "CCL14" },
    { id: "6359", name: "CCL15" },
    { id: "6360", name: "CCL16" },
    { id: "6361", name: "CCL17" },
    { id: "6362", name: "CCL18" },
    { id: "6363", name: "CCL19" },
    { id: "6347", name: "CCL2" },
    { id: "6364", name: "CCL20" },
    { id: "6366", name: "CCL21" },
    { id: "6367", name: "CCL22" },
    { id: "6368", name: "CCL23" },
    { id: "6369", name: "CCL24" },
    { id: "6370", name: "CCL25" },
    { id: "10344", name: "CCL26" },
    { id: "10850", name: "CCL27" },
    { id: "56477", name: "CCL28" },
    { id: "6348", name: "CCL3" },
    { id: "6349", name: "CCL3L1" },
    { id: "414062", name: "CCL3L3" },
    { id: "6351", name: "CCL4" },
    { id: "9560", name: "CCL4L1" },
    { id: "388372", name: "CCL4L2" },
    { id: "6352", name: "CCL5" },
    { id: "6354", name: "CCL7" },
    { id: "6355", name: "CCL8" },
    { id: "83605", name: "CCM2" },
    { id: "140706", name: "CCM2L" },
    { id: "8900", name: "CCNA1" },
    { id: "890", name: "CCNA2" },
    { id: "891", name: "CCNB1" },
    { id: "57820", name: "CCNB1IP1" },
    { id: "9133", name: "CCNB2" },
    { id: "85417", name: "CCNB3" },
    { id: "892", name: "CCNC" },
    { id: "595", name: "CCND1" },
    { id: "894", name: "CCND2" },
    { id: "896", name: "CCND3" },
    { id: "23582", name: "CCNDBP1" },
    { id: "898", name: "CCNE1" },
    { id: "9134", name: "CCNE2" },
    { id: "899", name: "CCNF" },
    { id: "900", name: "CCNG1" },
    { id: "902", name: "CCNH" },
    { id: "10983", name: "CCNI" },
    { id: "54619", name: "CCNJ" },
    { id: "79616", name: "CCNJL" },
    { id: "8812", name: "CCNK" },
    { id: "57018", name: "CCNL1" },
    { id: "81669", name: "CCNL2" },
    { id: "10309", name: "CCNO" },
    { id: "904", name: "CCNT1" },
    { id: "219771", name: "CCNY" },
    { id: "151195", name: "CCNYL1" },
    { id: "9738", name: "CCP110" },
    { id: "9236", name: "CCPG1" },
    { id: "1230", name: "CCR1" },
    { id: "2826", name: "CCR10" },
    { id: "729230", name: "CCR2" },
    { id: "1232", name: "CCR3" },
    { id: "1233", name: "CCR4" },
    { id: "1234", name: "CCR5" },
    { id: "1236", name: "CCR7" },
    { id: "1237", name: "CCR8" },
    { id: "10803", name: "CCR9" },
    { id: "51554", name: "CCRL1" },
    { id: "9973", name: "CCS" },
    { id: "401145", name: "CCSER1" },
    { id: "54462", name: "CCSER2" },
    { id: "10576", name: "CCT2" },
    { id: "7203", name: "CCT3" },
    { id: "10575", name: "CCT4" },
    { id: "22948", name: "CCT5" },
    { id: "908", name: "CCT6A" },
    { id: "10693", name: "CCT6B" },
    { id: "10574", name: "CCT7" },
    { id: "10694", name: "CCT8" },
    { id: "51622", name: "CCZ1" },
    { id: "221960", name: "CCZ1B" },
    { id: "9398", name: "CD101" },
    { id: "135228", name: "CD109" },
    { id: "929", name: "CD14" },
    { id: "11126", name: "CD160" },
    { id: "9332", name: "CD163" },
    { id: "8763", name: "CD164" },
    { id: "388611", name: "CD164L2" },
    { id: "57126", name: "CD177" },
    { id: "930", name: "CD19" },
    { id: "909", name: "CD1A" },
    { id: "910", name: "CD1B" },
    { id: "912", name: "CD1D" },
    { id: "913", name: "CD1E" },
    { id: "4345", name: "CD200" },
    { id: "131450", name: "CD200R1" },
    { id: "50489", name: "CD207" },
    { id: "30835", name: "CD209" },
    { id: "933", name: "CD22" },
    { id: "10666", name: "CD226" },
    { id: "100133941", name: "CD24" },
    { id: "51744", name: "CD244" },
    { id: "919", name: "CD247" },
    { id: "57124", name: "CD248" },
    { id: "939", name: "CD27" },
    { id: "29126", name: "CD274" },
    { id: "80381", name: "CD276" },
    { id: "940", name: "CD28" },
    { id: "23607", name: "CD2AP" },
    { id: "10421", name: "CD2BP2" },
    { id: "11314", name: "CD300A" },
    { id: "10871", name: "CD300C" },
    { id: "342510", name: "CD300E" },
    { id: "124599", name: "CD300LB" },
    { id: "100131439", name: "CD300LD" },
    { id: "146722", name: "CD300LF" },
    { id: "146894", name: "CD300LG" },
    { id: "9936", name: "CD302" },
    { id: "51293", name: "CD320" },
    { id: "945", name: "CD33" },
    { id: "948", name: "CD36" },
    { id: "952", name: "CD38" },
    { id: "915", name: "CD3D" },
    { id: "916", name: "CD3E" },
    { id: "10849", name: "CD3EAP" },
    { id: "917", name: "CD3G" },
    { id: "920", name: "CD4" },
    { id: "958", name: "CD40" },
    { id: "959", name: "CD40LG" },
    { id: "960", name: "CD44" },
    { id: "4179", name: "CD46" },
    { id: "961", name: "CD47" },
    { id: "962", name: "CD48" },
    { id: "921", name: "CD5" },
    { id: "963", name: "CD53" },
    { id: "1604", name: "CD55" },
    { id: "965", name: "CD58" },
    { id: "966", name: "CD59" },
    { id: "923", name: "CD6" },
    { id: "967", name: "CD63" },
    { id: "968", name: "CD68" },
    { id: "969", name: "CD69" },
    { id: "970", name: "CD70" },
    { id: "971", name: "CD72" },
    { id: "972", name: "CD74" },
    { id: "973", name: "CD79A" },
    { id: "974", name: "CD79B" },
    { id: "941", name: "CD80" },
    { id: "975", name: "CD81" },
    { id: "3732", name: "CD82" },
    { id: "9308", name: "CD83" },
    { id: "8832", name: "CD84" },
    { id: "942", name: "CD86" },
    { id: "925", name: "CD8A" },
    { id: "926", name: "CD8B" },
    { id: "928", name: "CD9" },
    { id: "22918", name: "CD93" },
    { id: "10225", name: "CD96" },
    { id: "4267", name: "CD99" },
    { id: "83692", name: "CD99L2" },
    { id: "978", name: "CDA" },
    { id: "146059", name: "CDAN1" },
    { id: "8872", name: "CDC123" },
    { id: "8556", name: "CDC14A" },
    { id: "8555", name: "CDC14B" },
    { id: "8881", name: "CDC16" },
    { id: "991", name: "CDC20" },
    { id: "166979", name: "CDC20B" },
    { id: "8697", name: "CDC23" },
    { id: "993", name: "CDC25A" },
    { id: "994", name: "CDC25B" },
    { id: "995", name: "CDC25C" },
    { id: "246184", name: "CDC26" },
    { id: "996", name: "CDC27" },
    { id: "997", name: "CDC34" },
    { id: "11140", name: "CDC37" },
    { id: "55664", name: "CDC37L1" },
    { id: "51362", name: "CDC40" },
    { id: "998", name: "CDC42" },
    { id: "8476", name: "CDC42BPA" },
    { id: "9578", name: "CDC42BPB" },
    { id: "55561", name: "CDC42BPG" },
    { id: "11135", name: "CDC42EP1" },
    { id: "10435", name: "CDC42EP2" },
    { id: "10602", name: "CDC42EP3" },
    { id: "23580", name: "CDC42EP4" },
    { id: "148170", name: "CDC42EP5" },
    { id: "56882", name: "CDC42SE1" },
    { id: "56990", name: "CDC42SE2" },
    { id: "8318", name: "CDC45" },
    { id: "988", name: "CDC5L" },
    { id: "990", name: "CDC6" },
    { id: "8317", name: "CDC7" },
    { id: "79577", name: "CDC73" },
    { id: "157313", name: "CDCA2" },
    { id: "83461", name: "CDCA3" },
    { id: "55038", name: "CDCA4" },
    { id: "113130", name: "CDCA5" },
    { id: "83879", name: "CDCA7" },
    { id: "55536", name: "CDCA7L" },
    { id: "55143", name: "CDCA8" },
    { id: "64866", name: "CDCP1" },
    { id: "999", name: "CDH1" },
    { id: "1008", name: "CDH10" },
    { id: "1009", name: "CDH11" },
    { id: "1010", name: "CDH12" },
    { id: "1012", name: "CDH13" },
    { id: "1013", name: "CDH15" },
    { id: "1016", name: "CDH18" },
    { id: "28513", name: "CDH19" },
    { id: "1000", name: "CDH2" },
    { id: "64405", name: "CDH22" },
    { id: "64072", name: "CDH23" },
    { id: "64403", name: "CDH24" },
    { id: "1001", name: "CDH3" },
    { id: "1002", name: "CDH4" },
    { id: "1004", name: "CDH6" },
    { id: "1005", name: "CDH7" },
    { id: "1007", name: "CDH9" },
    { id: "92211", name: "CDHR1" },
    { id: "54825", name: "CDHR2" },
    { id: "222256", name: "CDHR3" },
    { id: "53841", name: "CDHR5" },
    { id: "29965", name: "CDIP1" },
    { id: "10423", name: "CDIPT" },
    { id: "983", name: "CDK1" },
    { id: "8558", name: "CDK10" },
    { id: "728642", name: "CDK11A" },
    { id: "984", name: "CDK11B" },
    { id: "51755", name: "CDK12" },
    { id: "8621", name: "CDK13" },
    { id: "5218", name: "CDK14" },
    { id: "65061", name: "CDK15" },
    { id: "5127", name: "CDK16" },
    { id: "5128", name: "CDK17" },
    { id: "5129", name: "CDK18" },
    { id: "23097", name: "CDK19" },
    { id: "1017", name: "CDK2" },
    { id: "23552", name: "CDK20" },
    { id: "8099", name: "CDK2AP1" },
    { id: "10263", name: "CDK2AP2" },
    { id: "1018", name: "CDK3" },
    { id: "1019", name: "CDK4" },
    { id: "1020", name: "CDK5" },
    { id: "8851", name: "CDK5R1" },
    { id: "8941", name: "CDK5R2" },
    { id: "51654", name: "CDK5RAP1" },
    { id: "55755", name: "CDK5RAP2" },
    { id: "80279", name: "CDK5RAP3" },
    { id: "1021", name: "CDK6" },
    { id: "1022", name: "CDK7" },
    { id: "1024", name: "CDK8" },
    { id: "1025", name: "CDK9" },
    { id: "54901", name: "CDKAL1" },
    { id: "8814", name: "CDKL1" },
    { id: "8999", name: "CDKL2" },
    { id: "51265", name: "CDKL3" },
    { id: "344387", name: "CDKL4" },
    { id: "6792", name: "CDKL5" },
    { id: "1026", name: "CDKN1A" },
    { id: "1027", name: "CDKN1B" },
    { id: "1028", name: "CDKN1C" },
    { id: "1029", name: "CDKN2A" },
    { id: "55602", name: "CDKN2AIP" },
    { id: "91368", name: "CDKN2AIPNL" },
    { id: "1030", name: "CDKN2B" },
    { id: "1031", name: "CDKN2C" },
    { id: "1032", name: "CDKN2D" },
    { id: "1033", name: "CDKN3" },
    { id: "441549", name: "CDNF" },
    { id: "50937", name: "CDON" },
    { id: "150383", name: "CDPF1" },
    { id: "1039", name: "CDR2" },
    { id: "30850", name: "CDR2L" },
    { id: "284040", name: "CDRT4" },
    { id: "1040", name: "CDS1" },
    { id: "8760", name: "CDS2" },
    { id: "1041", name: "CDSN" },
    { id: "81620", name: "CDT1" },
    { id: "55573", name: "CDV3" },
    { id: "1045", name: "CDX2" },
    { id: "9085", name: "CDY1" },
    { id: "9426", name: "CDY2A" },
    { id: "9425", name: "CDYL" },
    { id: "124359", name: "CDYL2" },
    { id: "634", name: "CEACAM1" },
    { id: "1084", name: "CEACAM3" },
    { id: "1048", name: "CEACAM5" },
    { id: "4680", name: "CEACAM6" },
    { id: "1087", name: "CEACAM7" },
    { id: "1088", name: "CEACAM8" },
    { id: "1050", name: "CEBPA" },
    { id: "1051", name: "CEBPB" },
    { id: "1052", name: "CEBPD" },
    { id: "1053", name: "CEBPE" },
    { id: "1054", name: "CEBPG" },
    { id: "10153", name: "CEBPZ" },
    { id: "51816", name: "CECR1" },
    { id: "27443", name: "CECR2" },
    { id: "27440", name: "CECR5" },
    { id: "27439", name: "CECR6" },
    { id: "1056", name: "CEL" },
    { id: "1990", name: "CELA1" },
    { id: "51032", name: "CELA2B" },
    { id: "10658", name: "CELF1" },
    { id: "10659", name: "CELF2" },
    { id: "11189", name: "CELF3" },
    { id: "56853", name: "CELF4" },
    { id: "60680", name: "CELF5" },
    { id: "60677", name: "CELF6" },
    { id: "9620", name: "CELSR1" },
    { id: "1952", name: "CELSR2" },
    { id: "1951", name: "CELSR3" },
    { id: "752014", name: "CEMP1" },
    { id: "51286", name: "CEND1" },
    { id: "1058", name: "CENPA" },
    { id: "1059", name: "CENPB" },
    { id: "92806", name: "CENPBD1" },
    { id: "1060", name: "CENPC1" },
    { id: "1062", name: "CENPE" },
    { id: "1063", name: "CENPF" },
    { id: "64946", name: "CENPH" },
    { id: "2491", name: "CENPI" },
    { id: "55835", name: "CENPJ" },
    { id: "64105", name: "CENPK" },
    { id: "91687", name: "CENPL" },
    { id: "79019", name: "CENPM" },
    { id: "55839", name: "CENPN" },
    { id: "79172", name: "CENPO" },
    { id: "401541", name: "CENPP" },
    { id: "55166", name: "CENPQ" },
    { id: "80152", name: "CENPT" },
    { id: "201161", name: "CENPV" },
    { id: "387103", name: "CENPW" },
    { id: "9731", name: "CEP104" },
    { id: "201134", name: "CEP112" },
    { id: "153241", name: "CEP120" },
    { id: "145508", name: "CEP128" },
    { id: "9662", name: "CEP135" },
    { id: "22995", name: "CEP152" },
    { id: "22897", name: "CEP164" },
    { id: "9859", name: "CEP170" },
    { id: "283638", name: "CEP170B" },
    { id: "645455", name: "CEP170P1" },
    { id: "84984", name: "CEP19" },
    { id: "55125", name: "CEP192" },
    { id: "11190", name: "CEP250" },
    { id: "80184", name: "CEP290" },
    { id: "9857", name: "CEP350" },
    { id: "95681", name: "CEP41" },
    { id: "80817", name: "CEP44" },
    { id: "55165", name: "CEP55" },
    { id: "9702", name: "CEP57" },
    { id: "285753", name: "CEP57L1" },
    { id: "80254", name: "CEP63" },
    { id: "23177", name: "CEP68" },
    { id: "80321", name: "CEP70" },
    { id: "55722", name: "CEP72" },
    { id: "79959", name: "CEP76" },
    { id: "84131", name: "CEP78" },
    { id: "64793", name: "CEP85" },
    { id: "387119", name: "CEP85L" },
    { id: "84902", name: "CEP89" },
    { id: "90799", name: "CEP95" },
    { id: "79598", name: "CEP97" },
    { id: "10390", name: "CEPT1" },
    { id: "9350", name: "CER1" },
    { id: "51148", name: "CERCAM" },
    { id: "64781", name: "CERK" },
    { id: "10715", name: "CERS1" },
    { id: "29956", name: "CERS2" },
    { id: "91012", name: "CERS5" },
    { id: "253782", name: "CERS6" },
    { id: "1066", name: "CES1" },
    { id: "23491", name: "CES3" },
    { id: "283848", name: "CES4A" },
    { id: "1068", name: "CETN1" },
    { id: "1069", name: "CETN2" },
    { id: "1071", name: "CETP" },
    { id: "629", name: "CFB" },
    { id: "1675", name: "CFD" },
    { id: "10428", name: "CFDP1" },
    { id: "3075", name: "CFH" },
    { id: "3078", name: "CFHR1" },
    { id: "3080", name: "CFHR2" },
    { id: "10878", name: "CFHR3" },
    { id: "10877", name: "CFHR4" },
    { id: "81494", name: "CFHR5" },
    { id: "3426", name: "CFI" },
    { id: "1072", name: "CFL1" },
    { id: "1073", name: "CFL2" },
    { id: "8837", name: "CFLAR" },
    { id: "5199", name: "CFP" },
    { id: "1080", name: "CFTR" },
    { id: "1081", name: "CGA" },
    { id: "1082", name: "CGB" },
    { id: "114336", name: "CGB2" },
    { id: "93659", name: "CGB5" },
    { id: "8545", name: "CGGBP1" },
    { id: "57530", name: "CGN" },
    { id: "84952", name: "CGNL1" },
    { id: "10668", name: "CGRRF1" },
    { id: "9023", name: "CH25H" },
    { id: "79094", name: "CHAC1" },
    { id: "494143", name: "CHAC2" },
    { id: "10036", name: "CHAF1A" },
    { id: "8208", name: "CHAF1B" },
    { id: "283489", name: "CHAMP1" },
    { id: "1103", name: "CHAT" },
    { id: "118487", name: "CHCHD1" },
    { id: "51142", name: "CHCHD2" },
    { id: "54927", name: "CHCHD3" },
    { id: "131474", name: "CHCHD4" },
    { id: "84303", name: "CHCHD6" },
    { id: "1105", name: "CHD1" },
    { id: "9557", name: "CHD1L" },
    { id: "1107", name: "CHD3" },
    { id: "1108", name: "CHD4" },
    { id: "26038", name: "CHD5" },
    { id: "84181", name: "CHD6" },
    { id: "55636", name: "CHD7" },
    { id: "57680", name: "CHD8" },
    { id: "80205", name: "CHD9" },
    { id: "55349", name: "CHDH" },
    { id: "1111", name: "CHEK1" },
    { id: "11200", name: "CHEK2" },
    { id: "10523", name: "CHERP" },
    { id: "55743", name: "CHFR" },
    { id: "1114", name: "CHGB" },
    { id: "1116", name: "CHI3L1" },
    { id: "27159", name: "CHIA" },
    { id: "53344", name: "CHIC1" },
    { id: "26511", name: "CHIC2" },
    { id: "66005", name: "CHID1" },
    { id: "1119", name: "CHKA" },
    { id: "1120", name: "CHKB" },
    { id: "10752", name: "CHL1" },
    { id: "5119", name: "CHMP1A" },
    { id: "57132", name: "CHMP1B" },
    { id: "27243", name: "CHMP2A" },
    { id: "25978", name: "CHMP2B" },
    { id: "51652", name: "CHMP3" },
    { id: "29082", name: "CHMP4A" },
    { id: "128866", name: "CHMP4B" },
    { id: "92421", name: "CHMP4C" },
    { id: "51510", name: "CHMP5" },
    { id: "79643", name: "CHMP6" },
    { id: "91782", name: "CHMP7" },
    { id: "1123", name: "CHN1" },
    { id: "1124", name: "CHN2" },
    { id: "26973", name: "CHORDC1" },
    { id: "11261", name: "CHP1" },
    { id: "63928", name: "CHP2" },
    { id: "79586", name: "CHPF" },
    { id: "54480", name: "CHPF2" },
    { id: "56994", name: "CHPT1" },
    { id: "54108", name: "CHRAC1" },
    { id: "8646", name: "CHRD" },
    { id: "91851", name: "CHRDL1" },
    { id: "25884", name: "CHRDL2" },
    { id: "1129", name: "CHRM2" },
    { id: "1131", name: "CHRM3" },
    { id: "1138", name: "CHRNA5" },
    { id: "1139", name: "CHRNA7" },
    { id: "55584", name: "CHRNA9" },
    { id: "1141", name: "CHRNB2" },
    { id: "1143", name: "CHRNB4" },
    { id: "1144", name: "CHRND" },
    { id: "1145", name: "CHRNE" },
    { id: "1146", name: "CHRNG" },
    { id: "8534", name: "CHST1" },
    { id: "9486", name: "CHST10" },
    { id: "55501", name: "CHST12" },
    { id: "166012", name: "CHST13" },
    { id: "113189", name: "CHST14" },
    { id: "51363", name: "CHST15" },
    { id: "10164", name: "CHST4" },
    { id: "83539", name: "CHST9" },
    { id: "22856", name: "CHSY1" },
    { id: "337876", name: "CHSY3" },
    { id: "63922", name: "CHTF18" },
    { id: "54921", name: "CHTF8" },
    { id: "26097", name: "CHTOP" },
    { id: "1147", name: "CHUK" },
    { id: "91612", name: "CHURC1" },
    { id: "100529261", name: "CHURC1-FNTB" },
    { id: "9391", name: "CIAO1" },
    { id: "57019", name: "CIAPIN1" },
    { id: "10519", name: "CIB1" },
    { id: "10518", name: "CIB2" },
    { id: "117286", name: "CIB3" },
    { id: "130106", name: "CIB4" },
    { id: "23152", name: "CIC" },
    { id: "27141", name: "CIDEB" },
    { id: "63924", name: "CIDEC" },
    { id: "4261", name: "CIITA" },
    { id: "8483", name: "CILP" },
    { id: "148113", name: "CILP2" },
    { id: "51550", name: "CINP" },
    { id: "9541", name: "CIR1" },
    { id: "1153", name: "CIRBP" },
    { id: "84916", name: "CIRH1A" },
    { id: "55847", name: "CISD1" },
    { id: "493856", name: "CISD2" },
    { id: "284106", name: "CISD3" },
    { id: "1154", name: "CISH" },
    { id: "11113", name: "CIT" },
    { id: "4435", name: "CITED1" },
    { id: "10370", name: "CITED2" },
    { id: "163732", name: "CITED4" },
    { id: "25792", name: "CIZ1" },
    { id: "26586", name: "CKAP2" },
    { id: "150468", name: "CKAP2L" },
    { id: "10970", name: "CKAP4" },
    { id: "9793", name: "CKAP5" },
    { id: "1152", name: "CKB" },
    { id: "51192", name: "CKLF" },
    { id: "100529251", name: "CKLF-CMTM1" },
    { id: "1158", name: "CKM" },
    { id: "548596", name: "CKMT1A" },
    { id: "1159", name: "CKMT1B" },
    { id: "1160", name: "CKMT2" },
    { id: "1163", name: "CKS1B" },
    { id: "137529", name: "CKS1BP7" },
    { id: "1164", name: "CKS2" },
    { id: "23332", name: "CLASP1" },
    { id: "23122", name: "CLASP2" },
    { id: "11129", name: "CLASRP" },
    { id: "1178", name: "CLC" },
    { id: "1179", name: "CLCA1" },
    { id: "9635", name: "CLCA2" },
    { id: "22802", name: "CLCA4" },
    { id: "23155", name: "CLCC1" },
    { id: "23529", name: "CLCF1" },
    { id: "1180", name: "CLCN1" },
    { id: "1182", name: "CLCN3" },
    { id: "1184", name: "CLCN5" },
    { id: "9076", name: "CLDN1" },
    { id: "9071", name: "CLDN10" },
    { id: "5010", name: "CLDN11" },
    { id: "9069", name: "CLDN12" },
    { id: "24146", name: "CLDN15" },
    { id: "10686", name: "CLDN16" },
    { id: "149461", name: "CLDN19" },
    { id: "9075", name: "CLDN2" },
    { id: "49861", name: "CLDN20" },
    { id: "137075", name: "CLDN23" },
    { id: "1365", name: "CLDN3" },
    { id: "7122", name: "CLDN5" },
    { id: "9074", name: "CLDN6" },
    { id: "9073", name: "CLDN8" },
    { id: "9080", name: "CLDN9" },
    { id: "56650", name: "CLDND1" },
    { id: "125875", name: "CLDND2" },
    { id: "10462", name: "CLEC10A" },
    { id: "6320", name: "CLEC11A" },
    { id: "387837", name: "CLEC12B" },
    { id: "23274", name: "CLEC16A" },
    { id: "388512", name: "CLEC17A" },
    { id: "348174", name: "CLEC18A" },
    { id: "283971", name: "CLEC18C" },
    { id: "728276", name: "CLEC19A" },
    { id: "51267", name: "CLEC1A" },
    { id: "51266", name: "CLEC1B" },
    { id: "9976", name: "CLEC2B" },
    { id: "29121", name: "CLEC2D" },
    { id: "154790", name: "CLEC2L" },
    { id: "7123", name: "CLEC3B" },
    { id: "50856", name: "CLEC4A" },
    { id: "170482", name: "CLEC4C" },
    { id: "26253", name: "CLEC4E" },
    { id: "165530", name: "CLEC4F" },
    { id: "339390", name: "CLEC4G" },
    { id: "10332", name: "CLEC4M" },
    { id: "23601", name: "CLEC5A" },
    { id: "64581", name: "CLEC7A" },
    { id: "283420", name: "CLEC9A" },
    { id: "1047", name: "CLGN" },
    { id: "130162", name: "CLHC1" },
    { id: "1192", name: "CLIC1" },
    { id: "9022", name: "CLIC3" },
    { id: "25932", name: "CLIC4" },
    { id: "53405", name: "CLIC5" },
    { id: "54102", name: "CLIC6" },
    { id: "9685", name: "CLINT1" },
    { id: "6249", name: "CLIP1" },
    { id: "7461", name: "CLIP2" },
    { id: "25999", name: "CLIP3" },
    { id: "79745", name: "CLIP4" },
    { id: "1195", name: "CLK1" },
    { id: "1196", name: "CLK2" },
    { id: "1198", name: "CLK3" },
    { id: "57396", name: "CLK4" },
    { id: "79789", name: "CLMN" },
    { id: "79827", name: "CLMP" },
    { id: "1201", name: "CLN3" },
    { id: "1203", name: "CLN5" },
    { id: "54982", name: "CLN6" },
    { id: "2055", name: "CLN8" },
    { id: "116449", name: "CLNK" },
    { id: "1207", name: "CLNS1A" },
    { id: "9575", name: "CLOCK" },
    { id: "10978", name: "CLP1" },
    { id: "81570", name: "CLPB" },
    { id: "8192", name: "CLPP" },
    { id: "389383", name: "CLPSL2" },
    { id: "81037", name: "CLPTM1L" },
    { id: "10845", name: "CLPX" },
    { id: "645104", name: "CLRN2" },
    { id: "119467", name: "CLRN3" },
    { id: "63967", name: "CLSPN" },
    { id: "22883", name: "CLSTN1" },
    { id: "9746", name: "CLSTN3" },
    { id: "1211", name: "CLTA" },
    { id: "1212", name: "CLTB" },
    { id: "1213", name: "CLTC" },
    { id: "8218", name: "CLTCL1" },
    { id: "1191", name: "CLU" },
    { id: "23059", name: "CLUAP1" },
    { id: "23277", name: "CLUH" },
    { id: "157807", name: "CLVS1" },
    { id: "134829", name: "CLVS2" },
    { id: "171425", name: "CLYBL" },
    { id: "1215", name: "CMA1" },
    { id: "55907", name: "CMAS" },
    { id: "134147", name: "CMBL" },
    { id: "152100", name: "CMC1" },
    { id: "56942", name: "CMC2" },
    { id: "100272147", name: "CMC4" },
    { id: "80790", name: "CMIP" },
    { id: "51727", name: "CMPK1" },
    { id: "129607", name: "CMPK2" },
    { id: "84319", name: "CMSS1" },
    { id: "113540", name: "CMTM1" },
    { id: "146225", name: "CMTM2" },
    { id: "123920", name: "CMTM3" },
    { id: "146223", name: "CMTM4" },
    { id: "116173", name: "CMTM5" },
    { id: "54918", name: "CMTM6" },
    { id: "112616", name: "CMTM7" },
    { id: "152189", name: "CMTM8" },
    { id: "202333", name: "CMYA5" },
    { id: "7555", name: "CNBP" },
    { id: "84735", name: "CNDP1" },
    { id: "55748", name: "CNDP2" },
    { id: "255919", name: "CNEP1R1" },
    { id: "84518", name: "CNFN" },
    { id: "1260", name: "CNGA2" },
    { id: "1261", name: "CNGA3" },
    { id: "1258", name: "CNGB1" },
    { id: "10175", name: "CNIH" },
    { id: "254263", name: "CNIH2" },
    { id: "149111", name: "CNIH3" },
    { id: "29097", name: "CNIH4" },
    { id: "10256", name: "CNKSR1" },
    { id: "22866", name: "CNKSR2" },
    { id: "154043", name: "CNKSR3" },
    { id: "1264", name: "CNN1" },
    { id: "1265", name: "CNN2" },
    { id: "26507", name: "CNNM1" },
    { id: "54805", name: "CNNM2" },
    { id: "26505", name: "CNNM3" },
    { id: "26504", name: "CNNM4" },
    { id: "23019", name: "CNOT1" },
    { id: "25904", name: "CNOT10" },
    { id: "55571", name: "CNOT11" },
    { id: "4848", name: "CNOT2" },
    { id: "4849", name: "CNOT3" },
    { id: "4850", name: "CNOT4" },
    { id: "57472", name: "CNOT6" },
    { id: "246175", name: "CNOT6L" },
    { id: "29883", name: "CNOT7" },
    { id: "9337", name: "CNOT8" },
    { id: "27013", name: "CNPPD1" },
    { id: "10330", name: "CNPY2" },
    { id: "10695", name: "CNPY3" },
    { id: "245812", name: "CNPY4" },
    { id: "1268", name: "CNR1" },
    { id: "25927", name: "CNRIP1" },
    { id: "163882", name: "CNST" },
    { id: "1270", name: "CNTF" },
    { id: "1271", name: "CNTFR" },
    { id: "54875", name: "CNTLN" },
    { id: "1272", name: "CNTN1" },
    { id: "6900", name: "CNTN2" },
    { id: "5067", name: "CNTN3" },
    { id: "152330", name: "CNTN4" },
    { id: "53942", name: "CNTN5" },
    { id: "27255", name: "CNTN6" },
    { id: "8506", name: "CNTNAP1" },
    { id: "26047", name: "CNTNAP2" },
    { id: "79937", name: "CNTNAP3" },
    { id: "728577", name: "CNTNAP3B" },
    { id: "85445", name: "CNTNAP4" },
    { id: "11064", name: "CNTRL" },
    { id: "116840", name: "CNTROB" },
    { id: "55744", name: "COA1" },
    { id: "28958", name: "COA3" },
    { id: "493753", name: "COA5" },
    { id: "388753", name: "COA6" },
    { id: "80347", name: "COASY" },
    { id: "23242", name: "COBL" },
    { id: "22837", name: "COBLL1" },
    { id: "1690", name: "COCH" },
    { id: "9382", name: "COG1" },
    { id: "22796", name: "COG2" },
    { id: "83548", name: "COG3" },
    { id: "25839", name: "COG4" },
    { id: "10466", name: "COG5" },
    { id: "57511", name: "COG6" },
    { id: "91949", name: "COG7" },
    { id: "84342", name: "COG8" },
    { id: "8161", name: "COIL" },
    { id: "1300", name: "COL10A1" },
    { id: "1301", name: "COL11A1" },
    { id: "1302", name: "COL11A2" },
    { id: "1303", name: "COL12A1" },
    { id: "1305", name: "COL13A1" },
    { id: "7373", name: "COL14A1" },
    { id: "1307", name: "COL16A1" },
    { id: "1308", name: "COL17A1" },
    { id: "80781", name: "COL18A1" },
    { id: "1277", name: "COL1A1" },
    { id: "1278", name: "COL1A2" },
    { id: "57642", name: "COL20A1" },
    { id: "91522", name: "COL23A1" },
    { id: "255631", name: "COL24A1" },
    { id: "84570", name: "COL25A1" },
    { id: "136227", name: "COL26A1" },
    { id: "1280", name: "COL2A1" },
    { id: "1281", name: "COL3A1" },
    { id: "1282", name: "COL4A1" },
    { id: "1284", name: "COL4A2" },
    { id: "1285", name: "COL4A3" },
    { id: "10087", name: "COL4A3BP" },
    { id: "1287", name: "COL4A5" },
    { id: "1288", name: "COL4A6" },
    { id: "1289", name: "COL5A1" },
    { id: "1290", name: "COL5A2" },
    { id: "50509", name: "COL5A3" },
    { id: "1291", name: "COL6A1" },
    { id: "1292", name: "COL6A2" },
    { id: "256076", name: "COL6A5" },
    { id: "1294", name: "COL7A1" },
    { id: "1296", name: "COL8A2" },
    { id: "1297", name: "COL9A1" },
    { id: "1298", name: "COL9A2" },
    { id: "1299", name: "COL9A3" },
    { id: "78989", name: "COLEC11" },
    { id: "81035", name: "COLEC12" },
    { id: "79709", name: "COLGALT1" },
    { id: "8292", name: "COLQ" },
    { id: "150684", name: "COMMD1" },
    { id: "51397", name: "COMMD10" },
    { id: "51122", name: "COMMD2" },
    { id: "23412", name: "COMMD3" },
    { id: "54939", name: "COMMD4" },
    { id: "28991", name: "COMMD5" },
    { id: "170622", name: "COMMD6" },
    { id: "149951", name: "COMMD7" },
    { id: "54951", name: "COMMD8" },
    { id: "29099", name: "COMMD9" },
    { id: "1311", name: "COMP" },
    { id: "1312", name: "COMT" },
    { id: "118881", name: "COMTD1" },
    { id: "1314", name: "COPA" },
    { id: "1315", name: "COPB1" },
    { id: "9276", name: "COPB2" },
    { id: "11316", name: "COPE" },
    { id: "22820", name: "COPG1" },
    { id: "26958", name: "COPG2" },
    { id: "55352", name: "COPRS" },
    { id: "9318", name: "COPS2" },
    { id: "8533", name: "COPS3" },
    { id: "51138", name: "COPS4" },
    { id: "10987", name: "COPS5" },
    { id: "10980", name: "COPS6" },
    { id: "50813", name: "COPS7A" },
    { id: "64708", name: "COPS7B" },
    { id: "10920", name: "COPS8" },
    { id: "22818", name: "COPZ1" },
    { id: "51226", name: "COPZ2" },
    { id: "80219", name: "COQ10B" },
    { id: "27235", name: "COQ2" },
    { id: "51805", name: "COQ3" },
    { id: "51117", name: "COQ4" },
    { id: "84274", name: "COQ5" },
    { id: "51004", name: "COQ6" },
    { id: "10229", name: "COQ7" },
    { id: "57017", name: "COQ9" },
    { id: "11151", name: "CORO1A" },
    { id: "57175", name: "CORO1B" },
    { id: "23603", name: "CORO1C" },
    { id: "7464", name: "CORO2A" },
    { id: "10391", name: "CORO2B" },
    { id: "84940", name: "CORO6" },
    { id: "79585", name: "CORO7" },
    { id: "23406", name: "COTL1" },
    { id: "4512", name: "COX1" },
    { id: "84987", name: "COX14" },
    { id: "1355", name: "COX15" },
    { id: "51241", name: "COX16" },
    { id: "10063", name: "COX17" },
    { id: "285521", name: "COX18" },
    { id: "116228", name: "COX20" },
    { id: "1327", name: "COX4I1" },
    { id: "84701", name: "COX4I2" },
    { id: "9377", name: "COX5A" },
    { id: "1329", name: "COX5B" },
    { id: "1339", name: "COX6A2" },
    { id: "1340", name: "COX6B1" },
    { id: "125965", name: "COX6B2" },
    { id: "1345", name: "COX6C" },
    { id: "9167", name: "COX7A2L" },
    { id: "1351", name: "COX8A" },
    { id: "1356", name: "CP" },
    { id: "1357", name: "CPA1" },
    { id: "1358", name: "CPA2" },
    { id: "51200", name: "CPA4" },
    { id: "27151", name: "CPAMD8" },
    { id: "1361", name: "CPB2" },
    { id: "1362", name: "CPD" },
    { id: "1363", name: "CPE" },
    { id: "64506", name: "CPEB1" },
    { id: "22849", name: "CPEB3" },
    { id: "80315", name: "CPEB4" },
    { id: "79974", name: "CPED1" },
    { id: "10815", name: "CPLX1" },
    { id: "10814", name: "CPLX2" },
    { id: "1368", name: "CPM" },
    { id: "1369", name: "CPN1" },
    { id: "1370", name: "CPN2" },
    { id: "8904", name: "CPNE1" },
    { id: "221184", name: "CPNE2" },
    { id: "8895", name: "CPNE3" },
    { id: "131034", name: "CPNE4" },
    { id: "57699", name: "CPNE5" },
    { id: "9362", name: "CPNE6" },
    { id: "27132", name: "CPNE7" },
    { id: "144402", name: "CPNE8" },
    { id: "151835", name: "CPNE9" },
    { id: "55313", name: "CPPED1" },
    { id: "1373", name: "CPS1" },
    { id: "29894", name: "CPSF1" },
    { id: "53981", name: "CPSF2" },
    { id: "51692", name: "CPSF3" },
    { id: "54973", name: "CPSF3L" },
    { id: "10898", name: "CPSF4" },
    { id: "11052", name: "CPSF6" },
    { id: "79869", name: "CPSF7" },
    { id: "1374", name: "CPT1A" },
    { id: "126129", name: "CPT1C" },
    { id: "54504", name: "CPVL" },
    { id: "56265", name: "CPXM1" },
    { id: "119587", name: "CPXM2" },
    { id: "1378", name: "CR1" },
    { id: "1380", name: "CR2" },
    { id: "1381", name: "CRABP1" },
    { id: "1382", name: "CRABP2" },
    { id: "8738", name: "CRADD" },
    { id: "57585", name: "CRAMP1L" },
    { id: "1384", name: "CRAT" },
    { id: "23418", name: "CRB1" },
    { id: "286204", name: "CRB2" },
    { id: "92359", name: "CRB3" },
    { id: "51185", name: "CRBN" },
    { id: "27297", name: "CRCP" },
    { id: "54544", name: "CRCT1" },
    { id: "1385", name: "CREB1" },
    { id: "10488", name: "CREB3" },
    { id: "90993", name: "CREB3L1" },
    { id: "64764", name: "CREB3L2" },
    { id: "84699", name: "CREB3L3" },
    { id: "148327", name: "CREB3L4" },
    { id: "9586", name: "CREB5" },
    { id: "1387", name: "CREBBP" },
    { id: "153222", name: "CREBRF" },
    { id: "58487", name: "CREBZF" },
    { id: "8804", name: "CREG1" },
    { id: "78987", name: "CRELD1" },
    { id: "79174", name: "CRELD2" },
    { id: "1390", name: "CREM" },
    { id: "1392", name: "CRH" },
    { id: "1394", name: "CRHR1" },
    { id: "1395", name: "CRHR2" },
    { id: "51232", name: "CRIM1" },
    { id: "1396", name: "CRIP1" },
    { id: "1397", name: "CRIP2" },
    { id: "401262", name: "CRIP3" },
    { id: "285464", name: "CRIPAK" },
    { id: "9419", name: "CRIPT" },
    { id: "10321", name: "CRISP3" },
    { id: "83716", name: "CRISPLD2" },
    { id: "1398", name: "CRK" },
    { id: "1399", name: "CRKL" },
    { id: "9244", name: "CRLF1" },
    { id: "64109", name: "CRLF2" },
    { id: "51379", name: "CRLF3" },
    { id: "54675", name: "CRLS1" },
    { id: "1400", name: "CRMP1" },
    { id: "51340", name: "CRNKL1" },
    { id: "49860", name: "CRNN" },
    { id: "9696", name: "CROCC" },
    { id: "84809", name: "CROCCP2" },
    { id: "54677", name: "CROT" },
    { id: "1401", name: "CRP" },
    { id: "55118", name: "CRTAC1" },
    { id: "10491", name: "CRTAP" },
    { id: "23373", name: "CRTC1" },
    { id: "200186", name: "CRTC2" },
    { id: "64784", name: "CRTC3" },
    { id: "1406", name: "CRX" },
    { id: "1407", name: "CRY1" },
    { id: "1409", name: "CRYAA" },
    { id: "1410", name: "CRYAB" },
    { id: "1411", name: "CRYBA1" },
    { id: "1414", name: "CRYBB1" },
    { id: "1415", name: "CRYBB2" },
    { id: "1417", name: "CRYBB3" },
    { id: "131544", name: "CRYBG3" },
    { id: "1420", name: "CRYGC" },
    { id: "1421", name: "CRYGD" },
    { id: "51084", name: "CRYL1" },
    { id: "1429", name: "CRYZ" },
    { id: "9946", name: "CRYZL1" },
    { id: "1431", name: "CS" },
    { id: "51380", name: "CSAD" },
    { id: "158511", name: "CSAG1" },
    { id: "27254", name: "CSDC2" },
    { id: "7812", name: "CSDE1" },
    { id: "1434", name: "CSE1L" },
    { id: "1435", name: "CSF1" },
    { id: "1436", name: "CSF1R" },
    { id: "1437", name: "CSF2" },
    { id: "1438", name: "CSF2RA" },
    { id: "1439", name: "CSF2RB" },
    { id: "1440", name: "CSF3" },
    { id: "1441", name: "CSF3R" },
    { id: "55790", name: "CSGALNACT1" },
    { id: "55454", name: "CSGALNACT2" },
    { id: "1445", name: "CSK" },
    { id: "64478", name: "CSMD1" },
    { id: "114784", name: "CSMD2" },
    { id: "114788", name: "CSMD3" },
    { id: "1447", name: "CSN2" },
    { id: "1448", name: "CSN3" },
    { id: "1452", name: "CSNK1A1" },
    { id: "122011", name: "CSNK1A1L" },
    { id: "1453", name: "CSNK1D" },
    { id: "1454", name: "CSNK1E" },
    { id: "53944", name: "CSNK1G1" },
    { id: "1455", name: "CSNK1G2" },
    { id: "1456", name: "CSNK1G3" },
    { id: "1457", name: "CSNK2A1" },
    { id: "1459", name: "CSNK2A2" },
    { id: "283106", name: "CSNK2A3" },
    { id: "1460", name: "CSNK2B" },
    { id: "1464", name: "CSPG4" },
    { id: "79848", name: "CSPP1" },
    { id: "64651", name: "CSRNP1" },
    { id: "81566", name: "CSRNP2" },
    { id: "80034", name: "CSRNP3" },
    { id: "1465", name: "CSRP1" },
    { id: "1466", name: "CSRP2" },
    { id: "57325", name: "CSRP2BP" },
    { id: "8048", name: "CSRP3" },
    { id: "140880", name: "CST11" },
    { id: "1470", name: "CST2" },
    { id: "1471", name: "CST3" },
    { id: "8530", name: "CST7" },
    { id: "128821", name: "CST9L" },
    { id: "1475", name: "CSTA" },
    { id: "1476", name: "CSTB" },
    { id: "1477", name: "CSTF1" },
    { id: "1478", name: "CSTF2" },
    { id: "23283", name: "CSTF2T" },
    { id: "1479", name: "CSTF3" },
    { id: "728911", name: "CT45A2" },
    { id: "441519", name: "CT45A3" },
    { id: "441520", name: "CT45A4" },
    { id: "441521", name: "CT45A5" },
    { id: "541465", name: "CT45A6" },
    { id: "246100", name: "CTAG1A" },
    { id: "30848", name: "CTAG2" },
    { id: "4253", name: "CTAGE5" },
    { id: "1487", name: "CTBP1" },
    { id: "1488", name: "CTBP2" },
    { id: "80169", name: "CTC1" },
    { id: "10664", name: "CTCF" },
    { id: "140690", name: "CTCFL" },
    { id: "1283", name: "CTD" },
    { id: "23399", name: "CTDNEP1" },
    { id: "9150", name: "CTDP1" },
    { id: "58190", name: "CTDSP1" },
    { id: "10106", name: "CTDSP2" },
    { id: "10217", name: "CTDSPL" },
    { id: "51496", name: "CTDSPL2" },
    { id: "1489", name: "CTF1" },
    { id: "1490", name: "CTGF" },
    { id: "1491", name: "CTH" },
    { id: "115908", name: "CTHRC1" },
    { id: "9811", name: "CTIF" },
    { id: "1493", name: "CTLA4" },
    { id: "1495", name: "CTNNA1" },
    { id: "29119", name: "CTNNA3" },
    { id: "8727", name: "CTNNAL1" },
    { id: "1499", name: "CTNNB1" },
    { id: "56998", name: "CTNNBIP1" },
    { id: "56259", name: "CTNNBL1" },
    { id: "1500", name: "CTNND1" },
    { id: "1501", name: "CTNND2" },
    { id: "1503", name: "CTPS1" },
    { id: "56474", name: "CTPS2" },
    { id: "9646", name: "CTR9" },
    { id: "1504", name: "CTRB1" },
    { id: "11330", name: "CTRC" },
    { id: "1506", name: "CTRL" },
    { id: "5476", name: "CTSA" },
    { id: "1508", name: "CTSB" },
    { id: "1075", name: "CTSC" },
    { id: "1509", name: "CTSD" },
    { id: "1510", name: "CTSE" },
    { id: "8722", name: "CTSF" },
    { id: "1511", name: "CTSG" },
    { id: "1512", name: "CTSH" },
    { id: "1514", name: "CTSL1" },
    { id: "392360", name: "CTSL3P" },
    { id: "1520", name: "CTSS" },
    { id: "2017", name: "CTTN" },
    { id: "83992", name: "CTTNBP2" },
    { id: "55917", name: "CTTNBP2NL" },
    { id: "90353", name: "CTU1" },
    { id: "348180", name: "CTU2" },
    { id: "404217", name: "CTXN1" },
    { id: "613212", name: "CTXN3" },
    { id: "8029", name: "CUBN" },
    { id: "404093", name: "CUEDC1" },
    { id: "79004", name: "CUEDC2" },
    { id: "8454", name: "CUL1" },
    { id: "8453", name: "CUL2" },
    { id: "8452", name: "CUL3" },
    { id: "8451", name: "CUL4A" },
    { id: "8450", name: "CUL4B" },
    { id: "8065", name: "CUL5" },
    { id: "9820", name: "CUL7" },
    { id: "23113", name: "CUL9" },
    { id: "51596", name: "CUTA" },
    { id: "51076", name: "CUTC" },
    { id: "1523", name: "CUX1" },
    { id: "23316", name: "CUX2" },
    { id: "50624", name: "CUZD1" },
    { id: "51503", name: "CWC15" },
    { id: "57703", name: "CWC22" },
    { id: "54883", name: "CWC25" },
    { id: "10283", name: "CWC27" },
    { id: "55280", name: "CWF19L1" },
    { id: "143884", name: "CWF19L2" },
    { id: "6376", name: "CX3CL1" },
    { id: "1525", name: "CXADR" },
    { id: "2919", name: "CXCL1" },
    { id: "3627", name: "CXCL10" },
    { id: "6373", name: "CXCL11" },
    { id: "6387", name: "CXCL12" },
    { id: "10563", name: "CXCL13" },
    { id: "9547", name: "CXCL14" },
    { id: "58191", name: "CXCL16" },
    { id: "2920", name: "CXCL2" },
    { id: "6374", name: "CXCL5" },
    { id: "6372", name: "CXCL6" },
    { id: "4283", name: "CXCL9" },
    { id: "3577", name: "CXCR1" },
    { id: "3579", name: "CXCR2" },
    { id: "2833", name: "CXCR3" },
    { id: "7852", name: "CXCR4" },
    { id: "643", name: "CXCR5" },
    { id: "57007", name: "CXCR7" },
    { id: "256643", name: "CXorf23" },
    { id: "25763", name: "CXorf27" },
    { id: "159013", name: "CXorf38" },
    { id: "91966", name: "CXorf40A" },
    { id: "54967", name: "CXorf48" },
    { id: "63932", name: "CXorf56" },
    { id: "55086", name: "CXorf57" },
    { id: "347487", name: "CXorf66" },
    { id: "340602", name: "CXorf67" },
    { id: "30827", name: "CXXC1" },
    { id: "285093", name: "CXXC11" },
    { id: "80319", name: "CXXC4" },
    { id: "51523", name: "CXXC5" },
    { id: "1534", name: "CYB561" },
    { id: "220002", name: "CYB561A3" },
    { id: "284613", name: "CYB561D1" },
    { id: "11068", name: "CYB561D2" },
    { id: "80777", name: "CYB5B" },
    { id: "124637", name: "CYB5D1" },
    { id: "51706", name: "CYB5R1" },
    { id: "51700", name: "CYB5R2" },
    { id: "1727", name: "CYB5R3" },
    { id: "51167", name: "CYB5R4" },
    { id: "1535", name: "CYBA" },
    { id: "1536", name: "CYBB" },
    { id: "79901", name: "CYBRD1" },
    { id: "1537", name: "CYC1" },
    { id: "54205", name: "CYCS" },
    { id: "23191", name: "CYFIP1" },
    { id: "26999", name: "CYFIP2" },
    { id: "114757", name: "CYGB" },
    { id: "50626", name: "CYHR1" },
    { id: "1539", name: "CYLC2" },
    { id: "1540", name: "CYLD" },
    { id: "1583", name: "CYP11A1" },
    { id: "1585", name: "CYP11B2" },
    { id: "1586", name: "CYP17A1" },
    { id: "1588", name: "CYP19A1" },
    { id: "1543", name: "CYP1A1" },
    { id: "1544", name: "CYP1A2" },
    { id: "57404", name: "CYP20A1" },
    { id: "56603", name: "CYP26B1" },
    { id: "340665", name: "CYP26C1" },
    { id: "339761", name: "CYP27C1" },
    { id: "1548", name: "CYP2A6" },
    { id: "1562", name: "CYP2C18" },
    { id: "1558", name: "CYP2C8" },
    { id: "1565", name: "CYP2D6" },
    { id: "1571", name: "CYP2E1" },
    { id: "120227", name: "CYP2R1" },
    { id: "29785", name: "CYP2S1" },
    { id: "113612", name: "CYP2U1" },
    { id: "54905", name: "CYP2W1" },
    { id: "51302", name: "CYP39A1" },
    { id: "1576", name: "CYP3A4" },
    { id: "1577", name: "CYP3A5" },
    { id: "1579", name: "CYP4A11" },
    { id: "57834", name: "CYP4F11" },
    { id: "66002", name: "CYP4F12" },
    { id: "8529", name: "CYP4F2" },
    { id: "126410", name: "CYP4F22" },
    { id: "285440", name: "CYP4V2" },
    { id: "260293", name: "CYP4X1" },
    { id: "9420", name: "CYP7B1" },
    { id: "57105", name: "CYSLTR2" },
    { id: "84418", name: "CYSTM1" },
    { id: "9267", name: "CYTH1" },
    { id: "9266", name: "CYTH2" },
    { id: "9265", name: "CYTH3" },
    { id: "27128", name: "CYTH4" },
    { id: "9595", name: "CYTIP" },
    { id: "728294", name: "D2HGDH" },
    { id: "23002", name: "DAAM1" },
    { id: "23500", name: "DAAM2" },
    { id: "1600", name: "DAB1" },
    { id: "1601", name: "DAB2" },
    { id: "153090", name: "DAB2IP" },
    { id: "1602", name: "DACH1" },
    { id: "117154", name: "DACH2" },
    { id: "51339", name: "DACT1" },
    { id: "168002", name: "DACT2" },
    { id: "147906", name: "DACT3" },
    { id: "1603", name: "DAD1" },
    { id: "1605", name: "DAG1" },
    { id: "221955", name: "DAGLB" },
    { id: "26007", name: "DAK" },
    { id: "55152", name: "DALRD3" },
    { id: "267012", name: "DAOA" },
    { id: "7818", name: "DAP3" },
    { id: "1612", name: "DAPK1" },
    { id: "23604", name: "DAPK2" },
    { id: "1613", name: "DAPK3" },
    { id: "92196", name: "DAPL1" },
    { id: "27071", name: "DAPP1" },
    { id: "2532", name: "DARC" },
    { id: "1615", name: "DARS" },
    { id: "55157", name: "DARS2" },
    { id: "164781", name: "DAW1" },
    { id: "1616", name: "DAXX" },
    { id: "1617", name: "DAZ1" },
    { id: "57055", name: "DAZ2" },
    { id: "57054", name: "DAZ3" },
    { id: "57135", name: "DAZ4" },
    { id: "26528", name: "DAZAP1" },
    { id: "9802", name: "DAZAP2" },
    { id: "1618", name: "DAZL" },
    { id: "1620", name: "DBC1" },
    { id: "10926", name: "DBF4" },
    { id: "80174", name: "DBF4B" },
    { id: "1621", name: "DBH" },
    { id: "1622", name: "DBI" },
    { id: "1627", name: "DBN1" },
    { id: "55861", name: "DBNDD2" },
    { id: "28988", name: "DBNL" },
    { id: "1628", name: "DBP" },
    { id: "51163", name: "DBR1" },
    { id: "1629", name: "DBT" },
    { id: "79269", name: "DCAF10" },
    { id: "80344", name: "DCAF11" },
    { id: "25853", name: "DCAF12" },
    { id: "139170", name: "DCAF12L1" },
    { id: "340578", name: "DCAF12L2" },
    { id: "25879", name: "DCAF13" },
    { id: "90379", name: "DCAF15" },
    { id: "54876", name: "DCAF16" },
    { id: "80067", name: "DCAF17" },
    { id: "26094", name: "DCAF4" },
    { id: "285429", name: "DCAF4L1" },
    { id: "138009", name: "DCAF4L2" },
    { id: "8816", name: "DCAF5" },
    { id: "55827", name: "DCAF6" },
    { id: "10238", name: "DCAF7" },
    { id: "50717", name: "DCAF8" },
    { id: "347442", name: "DCAF8L2" },
    { id: "79877", name: "DCAKD" },
    { id: "285761", name: "DCBLD1" },
    { id: "131566", name: "DCBLD2" },
    { id: "1630", name: "DCC" },
    { id: "117159", name: "DCD" },
    { id: "51473", name: "DCDC2" },
    { id: "149069", name: "DCDC2B" },
    { id: "8642", name: "DCHS1" },
    { id: "54798", name: "DCHS2" },
    { id: "1633", name: "DCK" },
    { id: "9201", name: "DCLK1" },
    { id: "166614", name: "DCLK2" },
    { id: "85443", name: "DCLK3" },
    { id: "9937", name: "DCLRE1A" },
    { id: "64858", name: "DCLRE1B" },
    { id: "64421", name: "DCLRE1C" },
    { id: "1634", name: "DCN" },
    { id: "55802", name: "DCP1A" },
    { id: "196513", name: "DCP1B" },
    { id: "167227", name: "DCP2" },
    { id: "28960", name: "DCPS" },
    { id: "149095", name: "DCST1" },
    { id: "127579", name: "DCST2" },
    { id: "81501", name: "DCSTAMP" },
    { id: "1638", name: "DCT" },
    { id: "1635", name: "DCTD" },
    { id: "1639", name: "DCTN1" },
    { id: "10540", name: "DCTN2" },
    { id: "11258", name: "DCTN3" },
    { id: "51164", name: "DCTN4" },
    { id: "84516", name: "DCTN5" },
    { id: "10671", name: "DCTN6" },
    { id: "79077", name: "DCTPP1" },
    { id: "54165", name: "DCUN1D1" },
    { id: "55208", name: "DCUN1D2" },
    { id: "123879", name: "DCUN1D3" },
    { id: "23142", name: "DCUN1D4" },
    { id: "84259", name: "DCUN1D5" },
    { id: "1641", name: "DCX" },
    { id: "51181", name: "DCXR" },
    { id: "79016", name: "DDA1" },
    { id: "23576", name: "DDAH1" },
    { id: "23564", name: "DDAH2" },
    { id: "1642", name: "DDB1" },
    { id: "1643", name: "DDB2" },
    { id: "1644", name: "DDC" },
    { id: "80821", name: "DDHD1" },
    { id: "23259", name: "DDHD2" },
    { id: "414301", name: "DDI1" },
    { id: "84301", name: "DDI2" },
    { id: "1649", name: "DDIT3" },
    { id: "54541", name: "DDIT4" },
    { id: "115265", name: "DDIT4L" },
    { id: "23109", name: "DDN" },
    { id: "8528", name: "DDO" },
    { id: "1650", name: "DDOST" },
    { id: "780", name: "DDR1" },
    { id: "4921", name: "DDR2" },
    { id: "65992", name: "DDRGK1" },
    { id: "100037417", name: "DDTL" },
    { id: "1653", name: "DDX1" },
    { id: "1662", name: "DDX10" },
    { id: "10521", name: "DDX17" },
    { id: "8886", name: "DDX18" },
    { id: "55308", name: "DDX19A" },
    { id: "11269", name: "DDX19B" },
    { id: "11218", name: "DDX20" },
    { id: "9188", name: "DDX21" },
    { id: "9416", name: "DDX23" },
    { id: "57062", name: "DDX24" },
    { id: "29118", name: "DDX25" },
    { id: "203522", name: "DDX26B" },
    { id: "55661", name: "DDX27" },
    { id: "55794", name: "DDX28" },
    { id: "64794", name: "DDX31" },
    { id: "10212", name: "DDX39A" },
    { id: "7919", name: "DDX39B" },
    { id: "1654", name: "DDX3X" },
    { id: "8653", name: "DDX3Y" },
    { id: "54514", name: "DDX4" },
    { id: "51428", name: "DDX41" },
    { id: "11325", name: "DDX42" },
    { id: "55510", name: "DDX43" },
    { id: "9879", name: "DDX46" },
    { id: "51202", name: "DDX47" },
    { id: "54555", name: "DDX49" },
    { id: "1655", name: "DDX5" },
    { id: "79009", name: "DDX50" },
    { id: "317781", name: "DDX51" },
    { id: "11056", name: "DDX52" },
    { id: "168400", name: "DDX53" },
    { id: "79039", name: "DDX54" },
    { id: "57696", name: "DDX55" },
    { id: "54606", name: "DDX56" },
    { id: "23586", name: "DDX58" },
    { id: "1656", name: "DDX6" },
    { id: "55601", name: "DDX60" },
    { id: "91351", name: "DDX60L" },
    { id: "10522", name: "DEAF1" },
    { id: "50514", name: "DEC1" },
    { id: "1666", name: "DECR1" },
    { id: "26063", name: "DECR2" },
    { id: "9191", name: "DEDD" },
    { id: "162989", name: "DEDD2" },
    { id: "50619", name: "DEF6" },
    { id: "54849", name: "DEF8" },
    { id: "1667", name: "DEFA1" },
    { id: "1668", name: "DEFA3" },
    { id: "1671", name: "DEFA6" },
    { id: "1672", name: "DEFB1" },
    { id: "414325", name: "DEFB103A" },
    { id: "55894", name: "DEFB103B" },
    { id: "245911", name: "DEFB108B" },
    { id: "245929", name: "DEFB115" },
    { id: "245934", name: "DEFB121" },
    { id: "245937", name: "DEFB124" },
    { id: "140850", name: "DEFB127" },
    { id: "644414", name: "DEFB131" },
    { id: "1673", name: "DEFB4A" },
    { id: "8560", name: "DEGS1" },
    { id: "7913", name: "DEK" },
    { id: "57706", name: "DENND1A" },
    { id: "163486", name: "DENND1B" },
    { id: "163259", name: "DENND2C" },
    { id: "22898", name: "DENND3" },
    { id: "10260", name: "DENND4A" },
    { id: "55667", name: "DENND4C" },
    { id: "23258", name: "DENND5A" },
    { id: "160518", name: "DENND5B" },
    { id: "201627", name: "DENND6A" },
    { id: "414918", name: "DENND6B" },
    { id: "8562", name: "DENR" },
    { id: "55635", name: "DEPDC1" },
    { id: "55789", name: "DEPDC1B" },
    { id: "9681", name: "DEPDC5" },
    { id: "91614", name: "DEPDC7" },
    { id: "64798", name: "DEPTOR" },
    { id: "51071", name: "DERA" },
    { id: "79139", name: "DERL1" },
    { id: "51009", name: "DERL2" },
    { id: "91319", name: "DERL3" },
    { id: "1674", name: "DES" },
    { id: "27351", name: "DESI1" },
    { id: "55070", name: "DET1" },
    { id: "28955", name: "DEXI" },
    { id: "1676", name: "DFFA" },
    { id: "1677", name: "DFFB" },
    { id: "1687", name: "DFNA5" },
    { id: "25861", name: "DFNB31" },
    { id: "8694", name: "DGAT1" },
    { id: "84649", name: "DGAT2" },
    { id: "347516", name: "DGAT2L6" },
    { id: "8220", name: "DGCR14" },
    { id: "9993", name: "DGCR2" },
    { id: "8214", name: "DGCR6" },
    { id: "85359", name: "DGCR6L" },
    { id: "54487", name: "DGCR8" },
    { id: "8527", name: "DGKD" },
    { id: "8526", name: "DGKE" },
    { id: "1608", name: "DGKG" },
    { id: "160851", name: "DGKH" },
    { id: "9162", name: "DGKI" },
    { id: "1609", name: "DGKQ" },
    { id: "8525", name: "DGKZ" },
    { id: "1716", name: "DGUOK" },
    { id: "79947", name: "DHDDS" },
    { id: "1719", name: "DHFR" },
    { id: "200895", name: "DHFRL1" },
    { id: "50846", name: "DHH" },
    { id: "1723", name: "DHODH" },
    { id: "1725", name: "DHPS" },
    { id: "115817", name: "DHRS1" },
    { id: "79154", name: "DHRS11" },
    { id: "79758", name: "DHRS12" },
    { id: "147015", name: "DHRS13" },
    { id: "10202", name: "DHRS2" },
    { id: "9249", name: "DHRS3" },
    { id: "10901", name: "DHRS4" },
    { id: "728635", name: "DHRS4L1" },
    { id: "317749", name: "DHRS4L2" },
    { id: "51635", name: "DHRS7" },
    { id: "25979", name: "DHRS7B" },
    { id: "207063", name: "DHRSX" },
    { id: "55526", name: "DHTKD1" },
    { id: "1665", name: "DHX15" },
    { id: "8449", name: "DHX16" },
    { id: "54505", name: "DHX29" },
    { id: "22907", name: "DHX30" },
    { id: "55760", name: "DHX32" },
    { id: "56919", name: "DHX33" },
    { id: "9704", name: "DHX34" },
    { id: "60625", name: "DHX35" },
    { id: "170506", name: "DHX36" },
    { id: "57647", name: "DHX37" },
    { id: "9785", name: "DHX38" },
    { id: "79665", name: "DHX40" },
    { id: "90957", name: "DHX57" },
    { id: "79132", name: "DHX58" },
    { id: "1659", name: "DHX8" },
    { id: "1660", name: "DHX9" },
    { id: "56616", name: "DIABLO" },
    { id: "1729", name: "DIAPH1" },
    { id: "1730", name: "DIAPH2" },
    { id: "81624", name: "DIAPH3" },
    { id: "23405", name: "DICER1" },
    { id: "11083", name: "DIDO1" },
    { id: "27042", name: "DIEXF" },
    { id: "27292", name: "DIMT1" },
    { id: "1733", name: "DIO1" },
    { id: "1735", name: "DIO3" },
    { id: "23181", name: "DIP2A" },
    { id: "57609", name: "DIP2B" },
    { id: "22982", name: "DIP2C" },
    { id: "148252", name: "DIRAS1" },
    { id: "54769", name: "DIRAS2" },
    { id: "9077", name: "DIRAS3" },
    { id: "84925", name: "DIRC2" },
    { id: "22894", name: "DIS3" },
    { id: "115752", name: "DIS3L" },
    { id: "129563", name: "DIS3L2" },
    { id: "27185", name: "DISC1" },
    { id: "84976", name: "DISP1" },
    { id: "85455", name: "DISP2" },
    { id: "85458", name: "DIXDC1" },
    { id: "1736", name: "DKC1" },
    { id: "22943", name: "DKK1" },
    { id: "27123", name: "DKK2" },
    { id: "27122", name: "DKK3" },
    { id: "27120", name: "DKKL1" },
    { id: "1737", name: "DLAT" },
    { id: "10395", name: "DLC1" },
    { id: "1738", name: "DLD" },
    { id: "10301", name: "DLEU1" },
    { id: "8847", name: "DLEU2" },
    { id: "1739", name: "DLG1" },
    { id: "1740", name: "DLG2" },
    { id: "1741", name: "DLG3" },
    { id: "1742", name: "DLG4" },
    { id: "9231", name: "DLG5" },
    { id: "9229", name: "DLGAP1" },
    { id: "9228", name: "DLGAP2" },
    { id: "58512", name: "DLGAP3" },
    { id: "22839", name: "DLGAP4" },
    { id: "9787", name: "DLGAP5" },
    { id: "8788", name: "DLK1" },
    { id: "65989", name: "DLK2" },
    { id: "28514", name: "DLL1" },
    { id: "10683", name: "DLL3" },
    { id: "54567", name: "DLL4" },
    { id: "1743", name: "DLST" },
    { id: "1748", name: "DLX4" },
    { id: "1749", name: "DLX5" },
    { id: "285987", name: "DLX6-AS1" },
    { id: "55929", name: "DMAP1" },
    { id: "1755", name: "DMBT1" },
    { id: "127343", name: "DMBX1" },
    { id: "11144", name: "DMC1" },
    { id: "1756", name: "DMD" },
    { id: "93099", name: "DMKN" },
    { id: "1758", name: "DMP1" },
    { id: "1760", name: "DMPK" },
    { id: "10655", name: "DMRT2" },
    { id: "58524", name: "DMRT3" },
    { id: "63951", name: "DMRTA1" },
    { id: "63948", name: "DMRTB1" },
    { id: "63946", name: "DMRTC2" },
    { id: "9988", name: "DMTF1" },
    { id: "2039", name: "DMTN" },
    { id: "1762", name: "DMWD" },
    { id: "1657", name: "DMXL1" },
    { id: "23312", name: "DMXL2" },
    { id: "123872", name: "DNAAF1" },
    { id: "55172", name: "DNAAF2" },
    { id: "352909", name: "DNAAF3" },
    { id: "25981", name: "DNAH1" },
    { id: "8701", name: "DNAH11" },
    { id: "127602", name: "DNAH14" },
    { id: "8632", name: "DNAH17" },
    { id: "146754", name: "DNAH2" },
    { id: "55567", name: "DNAH3" },
    { id: "56171", name: "DNAH7" },
    { id: "1770", name: "DNAH9" },
    { id: "27019", name: "DNAI1" },
    { id: "64446", name: "DNAI2" },
    { id: "3301", name: "DNAJA1" },
    { id: "10294", name: "DNAJA2" },
    { id: "9093", name: "DNAJA3" },
    { id: "55466", name: "DNAJA4" },
    { id: "3337", name: "DNAJB1" },
    { id: "51726", name: "DNAJB11" },
    { id: "54788", name: "DNAJB12" },
    { id: "374407", name: "DNAJB13" },
    { id: "79982", name: "DNAJB14" },
    { id: "3300", name: "DNAJB2" },
    { id: "414061", name: "DNAJB3" },
    { id: "11080", name: "DNAJB4" },
    { id: "25822", name: "DNAJB5" },
    { id: "10049", name: "DNAJB6" },
    { id: "150353", name: "DNAJB7" },
    { id: "165721", name: "DNAJB8" },
    { id: "4189", name: "DNAJB9" },
    { id: "64215", name: "DNAJC1" },
    { id: "54431", name: "DNAJC10" },
    { id: "55735", name: "DNAJC11" },
    { id: "56521", name: "DNAJC12" },
    { id: "23317", name: "DNAJC13" },
    { id: "85406", name: "DNAJC14" },
    { id: "29103", name: "DNAJC15" },
    { id: "23341", name: "DNAJC16" },
    { id: "55192", name: "DNAJC17" },
    { id: "131118", name: "DNAJC19" },
    { id: "27000", name: "DNAJC2" },
    { id: "134218", name: "DNAJC21" },
    { id: "79962", name: "DNAJC22" },
    { id: "120526", name: "DNAJC24" },
    { id: "548645", name: "DNAJC25" },
    { id: "51277", name: "DNAJC27" },
    { id: "54943", name: "DNAJC28" },
    { id: "5611", name: "DNAJC3" },
    { id: "84277", name: "DNAJC30" },
    { id: "3338", name: "DNAJC4" },
    { id: "80331", name: "DNAJC5" },
    { id: "85479", name: "DNAJC5B" },
    { id: "9829", name: "DNAJC6" },
    { id: "7266", name: "DNAJC7" },
    { id: "22826", name: "DNAJC8" },
    { id: "23234", name: "DNAJC9" },
    { id: "83544", name: "DNAL1" },
    { id: "10126", name: "DNAL4" },
    { id: "7802", name: "DNALI1" },
    { id: "1773", name: "DNASE1" },
    { id: "1775", name: "DNASE1L2" },
    { id: "1776", name: "DNASE1L3" },
    { id: "58511", name: "DNASE2B" },
    { id: "373863", name: "DND1" },
    { id: "92737", name: "DNER" },
    { id: "144132", name: "DNHD1" },
    { id: "728489", name: "DNLZ" },
    { id: "1759", name: "DNM1" },
    { id: "10059", name: "DNM1L" },
    { id: "1785", name: "DNM2" },
    { id: "26052", name: "DNM3" },
    { id: "23268", name: "DNMBP" },
    { id: "1786", name: "DNMT1" },
    { id: "1788", name: "DNMT3A" },
    { id: "1789", name: "DNMT3B" },
    { id: "29947", name: "DNMT3L" },
    { id: "23549", name: "DNPEP" },
    { id: "10591", name: "DNPH1" },
    { id: "1791", name: "DNTT" },
    { id: "116092", name: "DNTTIP1" },
    { id: "30836", name: "DNTTIP2" },
    { id: "8448", name: "DOC2A" },
    { id: "8447", name: "DOC2B" },
    { id: "1793", name: "DOCK1" },
    { id: "55619", name: "DOCK10" },
    { id: "139818", name: "DOCK11" },
    { id: "1794", name: "DOCK2" },
    { id: "1795", name: "DOCK3" },
    { id: "9732", name: "DOCK4" },
    { id: "80005", name: "DOCK5" },
    { id: "57572", name: "DOCK6" },
    { id: "85440", name: "DOCK7" },
    { id: "81704", name: "DOCK8" },
    { id: "23348", name: "DOCK9" },
    { id: "83475", name: "DOHH" },
    { id: "1796", name: "DOK1" },
    { id: "9046", name: "DOK2" },
    { id: "79930", name: "DOK3" },
    { id: "55715", name: "DOK4" },
    { id: "55816", name: "DOK5" },
    { id: "220164", name: "DOK6" },
    { id: "285489", name: "DOK7" },
    { id: "22845", name: "DOLK" },
    { id: "57171", name: "DOLPP1" },
    { id: "1797", name: "DOM3Z" },
    { id: "29980", name: "DONSON" },
    { id: "23033", name: "DOPEY1" },
    { id: "9980", name: "DOPEY2" },
    { id: "84444", name: "DOT1L" },
    { id: "25911", name: "DPCD" },
    { id: "135656", name: "DPCR1" },
    { id: "1800", name: "DPEP1" },
    { id: "8193", name: "DPF1" },
    { id: "5977", name: "DPF2" },
    { id: "8110", name: "DPF3" },
    { id: "1802", name: "DPH2" },
    { id: "285381", name: "DPH3" },
    { id: "51611", name: "DPH5" },
    { id: "92715", name: "DPH7" },
    { id: "8813", name: "DPM1" },
    { id: "8818", name: "DPM2" },
    { id: "54344", name: "DPM3" },
    { id: "57628", name: "DPP10" },
    { id: "10072", name: "DPP3" },
    { id: "1803", name: "DPP4" },
    { id: "29952", name: "DPP7" },
    { id: "54878", name: "DPP8" },
    { id: "91039", name: "DPP9" },
    { id: "151871", name: "DPPA2" },
    { id: "359787", name: "DPPA3" },
    { id: "55211", name: "DPPA4" },
    { id: "1805", name: "DPT" },
    { id: "23333", name: "DPY19L1" },
    { id: "283417", name: "DPY19L2" },
    { id: "147991", name: "DPY19L3" },
    { id: "286148", name: "DPY19L4" },
    { id: "84661", name: "DPY30" },
    { id: "1808", name: "DPYSL2" },
    { id: "1809", name: "DPYSL3" },
    { id: "10570", name: "DPYSL4" },
    { id: "56896", name: "DPYSL5" },
    { id: "1810", name: "DR1" },
    { id: "55332", name: "DRAM1" },
    { id: "10589", name: "DRAP1" },
    { id: "374946", name: "DRAXIN" },
    { id: "92749", name: "DRC1" },
    { id: "1812", name: "DRD1" },
    { id: "1813", name: "DRD2" },
    { id: "1815", name: "DRD4" },
    { id: "4733", name: "DRG1" },
    { id: "1819", name: "DRG2" },
    { id: "29102", name: "DROSHA" },
    { id: "1821", name: "DRP2" },
    { id: "1823", name: "DSC1" },
    { id: "1824", name: "DSC2" },
    { id: "1825", name: "DSC3" },
    { id: "57453", name: "DSCAML1" },
    { id: "79075", name: "DSCC1" },
    { id: "10311", name: "DSCR3" },
    { id: "84677", name: "DSCR8" },
    { id: "29940", name: "DSE" },
    { id: "92126", name: "DSEL" },
    { id: "1828", name: "DSG1" },
    { id: "1829", name: "DSG2" },
    { id: "1830", name: "DSG3" },
    { id: "147409", name: "DSG4" },
    { id: "79980", name: "DSN1" },
    { id: "1832", name: "DSP" },
    { id: "667", name: "DST" },
    { id: "11034", name: "DSTN" },
    { id: "25778", name: "DSTYK" },
    { id: "92675", name: "DTD1" },
    { id: "112487", name: "DTD2" },
    { id: "51514", name: "DTL" },
    { id: "1837", name: "DTNA" },
    { id: "1838", name: "DTNB" },
    { id: "84062", name: "DTNBP1" },
    { id: "56986", name: "DTWD1" },
    { id: "285605", name: "DTWD2" },
    { id: "1840", name: "DTX1" },
    { id: "113878", name: "DTX2" },
    { id: "196403", name: "DTX3" },
    { id: "151636", name: "DTX3L" },
    { id: "23220", name: "DTX4" },
    { id: "1841", name: "DTYMK" },
    { id: "53905", name: "DUOX1" },
    { id: "90527", name: "DUOXA1" },
    { id: "338599", name: "DUPD1" },
    { id: "64118", name: "DUS1L" },
    { id: "54920", name: "DUS2L" },
    { id: "56931", name: "DUS3L" },
    { id: "11062", name: "DUS4L" },
    { id: "1843", name: "DUSP1" },
    { id: "11221", name: "DUSP10" },
    { id: "8446", name: "DUSP11" },
    { id: "11266", name: "DUSP12" },
    { id: "51207", name: "DUSP13" },
    { id: "11072", name: "DUSP14" },
    { id: "128853", name: "DUSP15" },
    { id: "80824", name: "DUSP16" },
    { id: "150290", name: "DUSP18" },
    { id: "142679", name: "DUSP19" },
    { id: "1844", name: "DUSP2" },
    { id: "63904", name: "DUSP21" },
    { id: "56940", name: "DUSP22" },
    { id: "54935", name: "DUSP23" },
    { id: "78986", name: "DUSP26" },
    { id: "1845", name: "DUSP3" },
    { id: "1846", name: "DUSP4" },
    { id: "1848", name: "DUSP6" },
    { id: "1849", name: "DUSP7" },
    { id: "1850", name: "DUSP8" },
    { id: "1852", name: "DUSP9" },
    { id: "1854", name: "DUT" },
    { id: "26582", name: "DUX3" },
    { id: "22947", name: "DUX4" },
    { id: "1855", name: "DVL1" },
    { id: "1856", name: "DVL2" },
    { id: "1857", name: "DVL3" },
    { id: "143241", name: "DYDC1" },
    { id: "84332", name: "DYDC2" },
    { id: "54808", name: "DYM" },
    { id: "1778", name: "DYNC1H1" },
    { id: "1780", name: "DYNC1I1" },
    { id: "1781", name: "DYNC1I2" },
    { id: "51143", name: "DYNC1LI1" },
    { id: "1783", name: "DYNC1LI2" },
    { id: "79659", name: "DYNC2H1" },
    { id: "51626", name: "DYNC2LI1" },
    { id: "8655", name: "DYNLL1" },
    { id: "140735", name: "DYNLL2" },
    { id: "83658", name: "DYNLRB1" },
    { id: "83657", name: "DYNLRB2" },
    { id: "6993", name: "DYNLT1" },
    { id: "6990", name: "DYNLT3" },
    { id: "1859", name: "DYRK1A" },
    { id: "9149", name: "DYRK1B" },
    { id: "8445", name: "DYRK2" },
    { id: "8444", name: "DYRK3" },
    { id: "8798", name: "DYRK4" },
    { id: "8291", name: "DYSF" },
    { id: "161582", name: "DYX1C1" },
    { id: "55184", name: "DZANK1" },
    { id: "22873", name: "DZIP1" },
    { id: "199221", name: "DZIP1L" },
    { id: "9666", name: "DZIP3" },
    { id: "1869", name: "E2F1" },
    { id: "1870", name: "E2F2" },
    { id: "1871", name: "E2F3" },
    { id: "1874", name: "E2F4" },
    { id: "1875", name: "E2F5" },
    { id: "1876", name: "E2F6" },
    { id: "144455", name: "E2F7" },
    { id: "79733", name: "E2F8" },
    { id: "1877", name: "E4F1" },
    { id: "85403", name: "EAF1" },
    { id: "55840", name: "EAF2" },
    { id: "55837", name: "EAPP" },
    { id: "124454", name: "EARS2" },
    { id: "9166", name: "EBAG9" },
    { id: "1879", name: "EBF1" },
    { id: "64641", name: "EBF2" },
    { id: "253738", name: "EBF3" },
    { id: "57593", name: "EBF4" },
    { id: "10148", name: "EBI3" },
    { id: "55096", name: "EBLN2" },
    { id: "10969", name: "EBNA1BP2" },
    { id: "10682", name: "EBP" },
    { id: "84650", name: "EBPL" },
    { id: "11319", name: "ECD" },
    { id: "1889", name: "ECE1" },
    { id: "9718", name: "ECE2" },
    { id: "9427", name: "ECEL1" },
    { id: "1891", name: "ECH1" },
    { id: "55862", name: "ECHDC1" },
    { id: "55268", name: "ECHDC2" },
    { id: "1892", name: "ECHS1" },
    { id: "1632", name: "ECI1" },
    { id: "10455", name: "ECI2" },
    { id: "1893", name: "ECM1" },
    { id: "641700", name: "ECSCR" },
    { id: "51295", name: "ECSIT" },
    { id: "1894", name: "ECT2" },
    { id: "1896", name: "EDA" },
    { id: "60401", name: "EDA2R" },
    { id: "10913", name: "EDAR" },
    { id: "128178", name: "EDARADD" },
    { id: "80153", name: "EDC3" },
    { id: "23644", name: "EDC4" },
    { id: "64184", name: "EDDM3B" },
    { id: "9695", name: "EDEM1" },
    { id: "55741", name: "EDEM2" },
    { id: "80267", name: "EDEM3" },
    { id: "8721", name: "EDF1" },
    { id: "10085", name: "EDIL3" },
    { id: "1906", name: "EDN1" },
    { id: "1907", name: "EDN2" },
    { id: "1908", name: "EDN3" },
    { id: "1909", name: "EDNRA" },
    { id: "1910", name: "EDNRB" },
    { id: "8411", name: "EEA1" },
    { id: "8726", name: "EED" },
    { id: "1915", name: "EEF1A1" },
    { id: "1917", name: "EEF1A2" },
    { id: "1933", name: "EEF1B2" },
    { id: "1936", name: "EEF1D" },
    { id: "9521", name: "EEF1E1" },
    { id: "1937", name: "EEF1G" },
    { id: "1938", name: "EEF2" },
    { id: "29904", name: "EEF2K" },
    { id: "60678", name: "EEFSEC" },
    { id: "80820", name: "EEPD1" },
    { id: "90141", name: "EFCAB11" },
    { id: "90288", name: "EFCAB12" },
    { id: "9813", name: "EFCAB14" },
    { id: "84288", name: "EFCAB2" },
    { id: "146779", name: "EFCAB3" },
    { id: "283229", name: "EFCAB4A" },
    { id: "84766", name: "EFCAB4B" },
    { id: "374786", name: "EFCAB5" },
    { id: "64800", name: "EFCAB6" },
    { id: "2202", name: "EFEMP1" },
    { id: "30008", name: "EFEMP2" },
    { id: "114327", name: "EFHC1" },
    { id: "80258", name: "EFHC2" },
    { id: "80303", name: "EFHD1" },
    { id: "79180", name: "EFHD2" },
    { id: "1942", name: "EFNA1" },
    { id: "1943", name: "EFNA2" },
    { id: "1946", name: "EFNA5" },
    { id: "1947", name: "EFNB1" },
    { id: "1948", name: "EFNB2" },
    { id: "1949", name: "EFNB3" },
    { id: "23167", name: "EFR3A" },
    { id: "22979", name: "EFR3B" },
    { id: "10278", name: "EFS" },
    { id: "79631", name: "EFTUD1" },
    { id: "9343", name: "EFTUD2" },
    { id: "1950", name: "EGF" },
    { id: "25975", name: "EGFL6" },
    { id: "51162", name: "EGFL7" },
    { id: "80864", name: "EGFL8" },
    { id: "133584", name: "EGFLAM" },
    { id: "1956", name: "EGFR" },
    { id: "54583", name: "EGLN1" },
    { id: "112398", name: "EGLN2" },
    { id: "112399", name: "EGLN3" },
    { id: "1958", name: "EGR1" },
    { id: "1959", name: "EGR2" },
    { id: "1960", name: "EGR3" },
    { id: "23301", name: "EHBP1" },
    { id: "254102", name: "EHBP1L1" },
    { id: "10938", name: "EHD1" },
    { id: "30846", name: "EHD2" },
    { id: "30845", name: "EHD3" },
    { id: "30844", name: "EHD4" },
    { id: "26298", name: "EHF" },
    { id: "1962", name: "EHHADH" },
    { id: "79813", name: "EHMT1" },
    { id: "10919", name: "EHMT2" },
    { id: "9538", name: "EI24" },
    { id: "23741", name: "EID1" },
    { id: "163126", name: "EID2" },
    { id: "126272", name: "EID2B" },
    { id: "493861", name: "EID3" },
    { id: "10209", name: "EIF1" },
    { id: "84285", name: "EIF1AD" },
    { id: "1964", name: "EIF1AX" },
    { id: "9086", name: "EIF1AY" },
    { id: "10289", name: "EIF1B" },
    { id: "83939", name: "EIF2A" },
    { id: "27102", name: "EIF2AK1" },
    { id: "5610", name: "EIF2AK2" },
    { id: "9451", name: "EIF2AK3" },
    { id: "440275", name: "EIF2AK4" },
    { id: "1967", name: "EIF2B1" },
    { id: "8892", name: "EIF2B2" },
    { id: "8891", name: "EIF2B3" },
    { id: "8890", name: "EIF2B4" },
    { id: "8893", name: "EIF2B5" },
    { id: "1939", name: "EIF2D" },
    { id: "1965", name: "EIF2S1" },
    { id: "8894", name: "EIF2S2" },
    { id: "1968", name: "EIF2S3" },
    { id: "8661", name: "EIF3A" },
    { id: "8662", name: "EIF3B" },
    { id: "8663", name: "EIF3C" },
    { id: "728689", name: "EIF3CL" },
    { id: "8664", name: "EIF3D" },
    { id: "3646", name: "EIF3E" },
    { id: "8665", name: "EIF3F" },
    { id: "8666", name: "EIF3G" },
    { id: "8667", name: "EIF3H" },
    { id: "8668", name: "EIF3I" },
    { id: "8669", name: "EIF3J" },
    { id: "27335", name: "EIF3K" },
    { id: "51386", name: "EIF3L" },
    { id: "10480", name: "EIF3M" },
    { id: "1973", name: "EIF4A1" },
    { id: "1974", name: "EIF4A2" },
    { id: "9775", name: "EIF4A3" },
    { id: "1975", name: "EIF4B" },
    { id: "1977", name: "EIF4E" },
    { id: "9470", name: "EIF4E2" },
    { id: "317649", name: "EIF4E3" },
    { id: "1979", name: "EIF4EBP2" },
    { id: "8637", name: "EIF4EBP3" },
    { id: "56478", name: "EIF4ENIF1" },
    { id: "1981", name: "EIF4G1" },
    { id: "1982", name: "EIF4G2" },
    { id: "8672", name: "EIF4G3" },
    { id: "7458", name: "EIF4H" },
    { id: "1983", name: "EIF5" },
    { id: "1984", name: "EIF5A" },
    { id: "56648", name: "EIF5A2" },
    { id: "143244", name: "EIF5AL1" },
    { id: "9669", name: "EIF5B" },
    { id: "3692", name: "EIF6" },
    { id: "60528", name: "ELAC2" },
    { id: "1991", name: "ELANE" },
    { id: "1994", name: "ELAVL1" },
    { id: "1993", name: "ELAVL2" },
    { id: "1995", name: "ELAVL3" },
    { id: "1996", name: "ELAVL4" },
    { id: "1997", name: "ELF1" },
    { id: "1998", name: "ELF2" },
    { id: "1999", name: "ELF3" },
    { id: "2000", name: "ELF4" },
    { id: "392617", name: "ELFN1" },
    { id: "114794", name: "ELFN2" },
    { id: "2002", name: "ELK1" },
    { id: "2004", name: "ELK3" },
    { id: "2005", name: "ELK4" },
    { id: "8178", name: "ELL" },
    { id: "22936", name: "ELL2" },
    { id: "80237", name: "ELL3" },
    { id: "9844", name: "ELMO1" },
    { id: "63916", name: "ELMO2" },
    { id: "79767", name: "ELMO3" },
    { id: "55531", name: "ELMOD1" },
    { id: "255520", name: "ELMOD2" },
    { id: "84173", name: "ELMOD3" },
    { id: "91748", name: "ELMSAN1" },
    { id: "2006", name: "ELN" },
    { id: "84337", name: "ELOF1" },
    { id: "64834", name: "ELOVL1" },
    { id: "54898", name: "ELOVL2" },
    { id: "83401", name: "ELOVL3" },
    { id: "6785", name: "ELOVL4" },
    { id: "60481", name: "ELOVL5" },
    { id: "79071", name: "ELOVL6" },
    { id: "55250", name: "ELP2" },
    { id: "55140", name: "ELP3" },
    { id: "26610", name: "ELP4" },
    { id: "23587", name: "ELP5" },
    { id: "54859", name: "ELP6" },
    { id: "64100", name: "ELSPBP1" },
    { id: "64123", name: "ELTD1" },
    { id: "133418", name: "EMB" },
    { id: "23065", name: "EMC1" },
    { id: "284361", name: "EMC10" },
    { id: "9694", name: "EMC2" },
    { id: "55831", name: "EMC3" },
    { id: "51234", name: "EMC4" },
    { id: "83460", name: "EMC6" },
    { id: "56851", name: "EMC7" },
    { id: "10328", name: "EMC8" },
    { id: "51016", name: "EMC9" },
    { id: "51705", name: "EMCN" },
    { id: "2010", name: "EMD" },
    { id: "146956", name: "EME1" },
    { id: "197342", name: "EME2" },
    { id: "10436", name: "EMG1" },
    { id: "129080", name: "EMID1" },
    { id: "11117", name: "EMILIN1" },
    { id: "84034", name: "EMILIN2" },
    { id: "90187", name: "EMILIN3" },
    { id: "2009", name: "EML1" },
    { id: "24139", name: "EML2" },
    { id: "256364", name: "EML3" },
    { id: "27436", name: "EML4" },
    { id: "161436", name: "EML5" },
    { id: "2012", name: "EMP1" },
    { id: "2014", name: "EMP3" },
    { id: "30817", name: "EMR2" },
    { id: "2016", name: "EMX1" },
    { id: "2018", name: "EMX2" },
    { id: "55740", name: "ENAH" },
    { id: "8507", name: "ENC1" },
    { id: "23052", name: "ENDOD1" },
    { id: "284131", name: "ENDOV" },
    { id: "2022", name: "ENG" },
    { id: "64772", name: "ENGASE" },
    { id: "375704", name: "ENHO" },
    { id: "84080", name: "ENKD1" },
    { id: "219670", name: "ENKUR" },
    { id: "2023", name: "ENO1" },
    { id: "2026", name: "ENO2" },
    { id: "2027", name: "ENO3" },
    { id: "58478", name: "ENOPH1" },
    { id: "55556", name: "ENOSF1" },
    { id: "55068", name: "ENOX1" },
    { id: "10495", name: "ENOX2" },
    { id: "2028", name: "ENPEP" },
    { id: "5167", name: "ENPP1" },
    { id: "5168", name: "ENPP2" },
    { id: "5169", name: "ENPP3" },
    { id: "133121", name: "ENPP6" },
    { id: "339221", name: "ENPP7" },
    { id: "2029", name: "ENSA" },
    { id: "146705", name: "ENTHD2" },
    { id: "9583", name: "ENTPD4" },
    { id: "957", name: "ENTPD5" },
    { id: "377841", name: "ENTPD8" },
    { id: "56943", name: "ENY2" },
    { id: "285203", name: "EOGT" },
    { id: "8320", name: "EOMES" },
    { id: "2033", name: "EP300" },
    { id: "57634", name: "EP400" },
    { id: "347918", name: "EP400NL" },
    { id: "2034", name: "EPAS1" },
    { id: "2035", name: "EPB41" },
    { id: "2036", name: "EPB41L1" },
    { id: "2037", name: "EPB41L2" },
    { id: "23136", name: "EPB41L3" },
    { id: "64097", name: "EPB41L4A" },
    { id: "54566", name: "EPB41L4B" },
    { id: "57669", name: "EPB41L5" },
    { id: "2038", name: "EPB42" },
    { id: "80314", name: "EPC1" },
    { id: "26122", name: "EPC2" },
    { id: "4072", name: "EPCAM" },
    { id: "54749", name: "EPDR1" },
    { id: "57724", name: "EPG5" },
    { id: "2041", name: "EPHA1" },
    { id: "284656", name: "EPHA10" },
    { id: "1969", name: "EPHA2" },
    { id: "2042", name: "EPHA3" },
    { id: "2043", name: "EPHA4" },
    { id: "2044", name: "EPHA5" },
    { id: "285220", name: "EPHA6" },
    { id: "2045", name: "EPHA7" },
    { id: "2046", name: "EPHA8" },
    { id: "2047", name: "EPHB1" },
    { id: "2048", name: "EPHB2" },
    { id: "2049", name: "EPHB3" },
    { id: "2050", name: "EPHB4" },
    { id: "2051", name: "EPHB6" },
    { id: "2052", name: "EPHX1" },
    { id: "2053", name: "EPHX2" },
    { id: "7957", name: "EPM2A" },
    { id: "9852", name: "EPM2AIP1" },
    { id: "29924", name: "EPN1" },
    { id: "22905", name: "EPN2" },
    { id: "55040", name: "EPN3" },
    { id: "2056", name: "EPO" },
    { id: "2057", name: "EPOR" },
    { id: "57119", name: "EPPIN" },
    { id: "83481", name: "EPPK1" },
    { id: "2058", name: "EPRS" },
    { id: "2060", name: "EPS15" },
    { id: "58513", name: "EPS15L1" },
    { id: "2059", name: "EPS8" },
    { id: "54869", name: "EPS8L1" },
    { id: "64787", name: "EPS8L2" },
    { id: "79574", name: "EPS8L3" },
    { id: "94240", name: "EPSTI1" },
    { id: "85465", name: "EPT1" },
    { id: "8288", name: "EPX" },
    { id: "1833", name: "EPYC" },
    { id: "26284", name: "ERAL1" },
    { id: "51752", name: "ERAP1" },
    { id: "2064", name: "ERBB2" },
    { id: "55914", name: "ERBB2IP" },
    { id: "2065", name: "ERBB3" },
    { id: "2066", name: "ERBB4" },
    { id: "23085", name: "ERC1" },
    { id: "26059", name: "ERC2" },
    { id: "2067", name: "ERCC1" },
    { id: "2068", name: "ERCC2" },
    { id: "2071", name: "ERCC3" },
    { id: "2072", name: "ERCC4" },
    { id: "2073", name: "ERCC5" },
    { id: "2074", name: "ERCC6" },
    { id: "54821", name: "ERCC6L" },
    { id: "375748", name: "ERCC6L2" },
    { id: "1161", name: "ERCC8" },
    { id: "2069", name: "EREG" },
    { id: "2077", name: "ERF" },
    { id: "2078", name: "ERG" },
    { id: "57222", name: "ERGIC1" },
    { id: "51290", name: "ERGIC2" },
    { id: "51614", name: "ERGIC3" },
    { id: "2079", name: "ERH" },
    { id: "90459", name: "ERI1" },
    { id: "112479", name: "ERI2" },
    { id: "79033", name: "ERI3" },
    { id: "157697", name: "ERICH1" },
    { id: "285141", name: "ERICH2" },
    { id: "27248", name: "ERLEC1" },
    { id: "10613", name: "ERLIN1" },
    { id: "11160", name: "ERLIN2" },
    { id: "114625", name: "ERMAP" },
    { id: "57471", name: "ERMN" },
    { id: "79956", name: "ERMP1" },
    { id: "2081", name: "ERN1" },
    { id: "30001", name: "ERO1L" },
    { id: "56605", name: "ERO1LB" },
    { id: "121506", name: "ERP27" },
    { id: "10961", name: "ERP29" },
    { id: "23071", name: "ERP44" },
    { id: "54206", name: "ERRFI1" },
    { id: "405754", name: "ERVFRD-1" },
    { id: "64006", name: "ERVK-6" },
    { id: "147664", name: "ERVV-1" },
    { id: "90952", name: "ESAM" },
    { id: "114799", name: "ESCO1" },
    { id: "157570", name: "ESCO2" },
    { id: "2098", name: "ESD" },
    { id: "51575", name: "ESF1" },
    { id: "11082", name: "ESM1" },
    { id: "9700", name: "ESPL1" },
    { id: "83715", name: "ESPN" },
    { id: "339768", name: "ESPNL" },
    { id: "2099", name: "ESR1" },
    { id: "2100", name: "ESR2" },
    { id: "54845", name: "ESRP1" },
    { id: "80004", name: "ESRP2" },
    { id: "2101", name: "ESRRA" },
    { id: "2103", name: "ESRRB" },
    { id: "2104", name: "ESRRG" },
    { id: "80712", name: "ESX1" },
    { id: "23344", name: "ESYT1" },
    { id: "57488", name: "ESYT2" },
    { id: "83850", name: "ESYT3" },
    { id: "54465", name: "ETAA1" },
    { id: "2107", name: "ETF1" },
    { id: "2108", name: "ETFA" },
    { id: "2109", name: "ETFB" },
    { id: "2110", name: "ETFDH" },
    { id: "23474", name: "ETHE1" },
    { id: "55500", name: "ETNK1" },
    { id: "55224", name: "ETNK2" },
    { id: "2113", name: "ETS1" },
    { id: "2114", name: "ETS2" },
    { id: "2115", name: "ETV1" },
    { id: "2117", name: "ETV3" },
    { id: "2118", name: "ETV4" },
    { id: "2119", name: "ETV5" },
    { id: "2120", name: "ETV6" },
    { id: "51513", name: "ETV7" },
    { id: "84141", name: "EVA1A" },
    { id: "55194", name: "EVA1B" },
    { id: "132884", name: "EVC2" },
    { id: "2123", name: "EVI2A" },
    { id: "7813", name: "EVI5" },
    { id: "115704", name: "EVI5L" },
    { id: "51466", name: "EVL" },
    { id: "2125", name: "EVPL" },
    { id: "2130", name: "EWSR1" },
    { id: "161829", name: "EXD1" },
    { id: "55218", name: "EXD2" },
    { id: "54932", name: "EXD3" },
    { id: "9156", name: "EXO1" },
    { id: "55763", name: "EXOC1" },
    { id: "55770", name: "EXOC2" },
    { id: "11336", name: "EXOC3" },
    { id: "283849", name: "EXOC3L1" },
    { id: "90332", name: "EXOC3L2" },
    { id: "60412", name: "EXOC4" },
    { id: "10640", name: "EXOC5" },
    { id: "54536", name: "EXOC6" },
    { id: "23233", name: "EXOC6B" },
    { id: "23265", name: "EXOC7" },
    { id: "149371", name: "EXOC8" },
    { id: "9941", name: "EXOG" },
    { id: "51013", name: "EXOSC1" },
    { id: "5394", name: "EXOSC10" },
    { id: "23404", name: "EXOSC2" },
    { id: "51010", name: "EXOSC3" },
    { id: "54512", name: "EXOSC4" },
    { id: "56915", name: "EXOSC5" },
    { id: "118460", name: "EXOSC6" },
    { id: "23016", name: "EXOSC7" },
    { id: "11340", name: "EXOSC8" },
    { id: "5393", name: "EXOSC9" },
    { id: "23086", name: "EXPH5" },
    { id: "2131", name: "EXT1" },
    { id: "2132", name: "EXT2" },
    { id: "2137", name: "EXTL3" },
    { id: "2138", name: "EYA1" },
    { id: "2139", name: "EYA2" },
    { id: "2140", name: "EYA3" },
    { id: "2145", name: "EZH1" },
    { id: "2146", name: "EZH2" },
    { id: "7430", name: "EZR" },
    { id: "2159", name: "F10" },
    { id: "2160", name: "F11" },
    { id: "50848", name: "F11R" },
    { id: "2161", name: "F12" },
    { id: "2162", name: "F13A1" },
    { id: "2165", name: "F13B" },
    { id: "2147", name: "F2" },
    { id: "2149", name: "F2R" },
    { id: "2150", name: "F2RL1" },
    { id: "2151", name: "F2RL2" },
    { id: "9002", name: "F2RL3" },
    { id: "2152", name: "F3" },
    { id: "2153", name: "F5" },
    { id: "2155", name: "F7" },
    { id: "2157", name: "F8" },
    { id: "8263", name: "F8A1" },
    { id: "2158", name: "F9" },
    { id: "79152", name: "FA2H" },
    { id: "158584", name: "FAAH2" },
    { id: "2169", name: "FABP2" },
    { id: "2167", name: "FABP4" },
    { id: "2171", name: "FABP5" },
    { id: "8772", name: "FADD" },
    { id: "3992", name: "FADS1" },
    { id: "9415", name: "FADS2" },
    { id: "283985", name: "FADS6" },
    { id: "11124", name: "FAF1" },
    { id: "23197", name: "FAF2" },
    { id: "2184", name: "FAH" },
    { id: "81889", name: "FAHD1" },
    { id: "51011", name: "FAHD2A" },
    { id: "151313", name: "FAHD2B" },
    { id: "55179", name: "FAIM" },
    { id: "23017", name: "FAIM2" },
    { id: "9214", name: "FAIM3" },
    { id: "144347", name: "FAM101A" },
    { id: "359845", name: "FAM101B" },
    { id: "399665", name: "FAM102A" },
    { id: "83640", name: "FAM103A1" },
    { id: "84923", name: "FAM104A" },
    { id: "90268", name: "FAM105B" },
    { id: "11170", name: "FAM107A" },
    { id: "83641", name: "FAM107B" },
    { id: "144717", name: "FAM109A" },
    { id: "150368", name: "FAM109B" },
    { id: "83541", name: "FAM110A" },
    { id: "90362", name: "FAM110B" },
    { id: "642273", name: "FAM110C" },
    { id: "79927", name: "FAM110D" },
    { id: "63901", name: "FAM111A" },
    { id: "374393", name: "FAM111B" },
    { id: "92689", name: "FAM114A1" },
    { id: "10827", name: "FAM114A2" },
    { id: "9747", name: "FAM115A" },
    { id: "81558", name: "FAM117A" },
    { id: "150864", name: "FAM117B" },
    { id: "55007", name: "FAM118A" },
    { id: "79607", name: "FAM118B" },
    { id: "23196", name: "FAM120A" },
    { id: "158293", name: "FAM120AOS" },
    { id: "84498", name: "FAM120B" },
    { id: "54954", name: "FAM120C" },
    { id: "116224", name: "FAM122A" },
    { id: "159090", name: "FAM122B" },
    { id: "159091", name: "FAM122C" },
    { id: "220108", name: "FAM124A" },
    { id: "79843", name: "FAM124B" },
    { id: "285172", name: "FAM126B" },
    { id: "8933", name: "FAM127A" },
    { id: "26071", name: "FAM127B" },
    { id: "441518", name: "FAM127C" },
    { id: "116496", name: "FAM129A" },
    { id: "64855", name: "FAM129B" },
    { id: "199786", name: "FAM129C" },
    { id: "131408", name: "FAM131A" },
    { id: "348487", name: "FAM131C" },
    { id: "286499", name: "FAM133A" },
    { id: "257415", name: "FAM133B" },
    { id: "79137", name: "FAM134A" },
    { id: "54463", name: "FAM134B" },
    { id: "162427", name: "FAM134C" },
    { id: "57579", name: "FAM135A" },
    { id: "51059", name: "FAM135B" },
    { id: "84908", name: "FAM136A" },
    { id: "10144", name: "FAM13A" },
    { id: "51306", name: "FAM13B" },
    { id: "220965", name: "FAM13C" },
    { id: "338094", name: "FAM151A" },
    { id: "167555", name: "FAM151B" },
    { id: "285596", name: "FAM153A" },
    { id: "202134", name: "FAM153B" },
    { id: "158297", name: "FAM154A" },
    { id: "29057", name: "FAM156A" },
    { id: "84067", name: "FAM160A2" },
    { id: "57700", name: "FAM160B1" },
    { id: "64760", name: "FAM160B2" },
    { id: "84140", name: "FAM161A" },
    { id: "145483", name: "FAM161B" },
    { id: "26355", name: "FAM162A" },
    { id: "401565", name: "FAM166A" },
    { id: "730112", name: "FAM166B" },
    { id: "83648", name: "FAM167A" },
    { id: "23201", name: "FAM168A" },
    { id: "130074", name: "FAM168B" },
    { id: "26049", name: "FAM169A" },
    { id: "221061", name: "FAM171A1" },
    { id: "284069", name: "FAM171A2" },
    { id: "165215", name: "FAM171B" },
    { id: "83989", name: "FAM172A" },
    { id: "65990", name: "FAM173A" },
    { id: "134145", name: "FAM173B" },
    { id: "400451", name: "FAM174B" },
    { id: "84142", name: "FAM175A" },
    { id: "23172", name: "FAM175B" },
    { id: "283635", name: "FAM177A1" },
    { id: "55719", name: "FAM178A" },
    { id: "23116", name: "FAM179B" },
    { id: "90050", name: "FAM181A" },
    { id: "79632", name: "FAM184A" },
    { id: "27146", name: "FAM184B" },
    { id: "80013", name: "FAM188A" },
    { id: "23359", name: "FAM189A1" },
    { id: "9413", name: "FAM189A2" },
    { id: "10712", name: "FAM189B" },
    { id: "80011", name: "FAM192A" },
    { id: "54540", name: "FAM193B" },
    { id: "84331", name: "FAM195A" },
    { id: "348262", name: "FAM195B" },
    { id: "642938", name: "FAM196A" },
    { id: "139231", name: "FAM199X" },
    { id: "407738", name: "FAM19A1" },
    { id: "151647", name: "FAM19A4" },
    { id: "221786", name: "FAM200A" },
    { id: "51236", name: "FAM203A" },
    { id: "54942", name: "FAM206A" },
    { id: "85395", name: "FAM207A" },
    { id: "23272", name: "FAM208A" },
    { id: "54906", name: "FAM208B" },
    { id: "56975", name: "FAM20C" },
    { id: "125228", name: "FAM210A" },
    { id: "116151", name: "FAM210B" },
    { id: "389119", name: "FAM212A" },
    { id: "55924", name: "FAM212B" },
    { id: "84293", name: "FAM213A" },
    { id: "127281", name: "FAM213B" },
    { id: "56204", name: "FAM214A" },
    { id: "80256", name: "FAM214B" },
    { id: "29902", name: "FAM216A" },
    { id: "222826", name: "FAM217A" },
    { id: "152756", name: "FAM218A" },
    { id: "203259", name: "FAM219A" },
    { id: "57184", name: "FAM219B" },
    { id: "387680", name: "FAM21A" },
    { id: "253725", name: "FAM21C" },
    { id: "84792", name: "FAM220A" },
    { id: "340277", name: "FAM221A" },
    { id: "392307", name: "FAM221B" },
    { id: "84915", name: "FAM222A" },
    { id: "55731", name: "FAM222B" },
    { id: "646851", name: "FAM227A" },
    { id: "375190", name: "FAM228B" },
    { id: "196792", name: "FAM24B" },
    { id: "254228", name: "FAM26E" },
    { id: "100131997", name: "FAM27E3" },
    { id: "26017", name: "FAM32A" },
    { id: "54537", name: "FAM35A" },
    { id: "60343", name: "FAM3A" },
    { id: "54097", name: "FAM3B" },
    { id: "10447", name: "FAM3C" },
    { id: "163933", name: "FAM43B" },
    { id: "404636", name: "FAM45A" },
    { id: "55603", name: "FAM46A" },
    { id: "115572", name: "FAM46B" },
    { id: "54855", name: "FAM46C" },
    { id: "169966", name: "FAM46D" },
    { id: "170062", name: "FAM47B" },
    { id: "100129583", name: "FAM47E" },
    { id: "51571", name: "FAM49B" },
    { id: "9130", name: "FAM50A" },
    { id: "26240", name: "FAM50B" },
    { id: "152877", name: "FAM53A" },
    { id: "9679", name: "FAM53B" },
    { id: "51307", name: "FAM53C" },
    { id: "79850", name: "FAM57A" },
    { id: "92002", name: "FAM58A" },
    { id: "339479", name: "FAM5C" },
    { id: "58516", name: "FAM60A" },
    { id: "55793", name: "FAM63A" },
    { id: "54629", name: "FAM63B" },
    { id: "54478", name: "FAM64A" },
    { id: "79567", name: "FAM65A" },
    { id: "9750", name: "FAM65B" },
    { id: "140876", name: "FAM65C" },
    { id: "388650", name: "FAM69A" },
    { id: "138311", name: "FAM69B" },
    { id: "196472", name: "FAM71C" },
    { id: "161142", name: "FAM71D" },
    { id: "112703", name: "FAM71E1" },
    { id: "284418", name: "FAM71E2" },
    { id: "84691", name: "FAM71F1" },
    { id: "729533", name: "FAM72A" },
    { id: "653820", name: "FAM72B" },
    { id: "728833", name: "FAM72D" },
    { id: "374986", name: "FAM73A" },
    { id: "84895", name: "FAM73B" },
    { id: "401507", name: "FAM74A1" },
    { id: "143684", name: "FAM76B" },
    { id: "286336", name: "FAM78A" },
    { id: "145773", name: "FAM81A" },
    { id: "153643", name: "FAM81B" },
    { id: "84985", name: "FAM83A" },
    { id: "222584", name: "FAM83B" },
    { id: "128876", name: "FAM83C" },
    { id: "81610", name: "FAM83D" },
    { id: "113828", name: "FAM83F" },
    { id: "644815", name: "FAM83G" },
    { id: "286077", name: "FAM83H" },
    { id: "151354", name: "FAM84A" },
    { id: "157638", name: "FAM84B" },
    { id: "196483", name: "FAM86A" },
    { id: "85002", name: "FAM86B1" },
    { id: "653333", name: "FAM86B2" },
    { id: "55199", name: "FAM86C1" },
    { id: "23625", name: "FAM89B" },
    { id: "51439", name: "FAM8A1" },
    { id: "55138", name: "FAM90A1" },
    { id: "157769", name: "FAM91A1" },
    { id: "137392", name: "FAM92A1" },
    { id: "84191", name: "FAM96A" },
    { id: "51647", name: "FAM96B" },
    { id: "25940", name: "FAM98A" },
    { id: "283742", name: "FAM98B" },
    { id: "147965", name: "FAM98C" },
    { id: "171482", name: "FAM9A" },
    { id: "171483", name: "FAM9B" },
    { id: "171484", name: "FAM9C" },
    { id: "22909", name: "FAN1" },
    { id: "2175", name: "FANCA" },
    { id: "2187", name: "FANCB" },
    { id: "2176", name: "FANCC" },
    { id: "2177", name: "FANCD2" },
    { id: "2178", name: "FANCE" },
    { id: "2188", name: "FANCF" },
    { id: "2189", name: "FANCG" },
    { id: "55215", name: "FANCI" },
    { id: "55120", name: "FANCL" },
    { id: "57697", name: "FANCM" },
    { id: "92565", name: "FANK1" },
    { id: "2191", name: "FAP" },
    { id: "84188", name: "FAR1" },
    { id: "55711", name: "FAR2" },
    { id: "10160", name: "FARP1" },
    { id: "9855", name: "FARP2" },
    { id: "10667", name: "FARS2" },
    { id: "2193", name: "FARSA" },
    { id: "10056", name: "FARSB" },
    { id: "355", name: "FAS" },
    { id: "356", name: "FASLG" },
    { id: "2194", name: "FASN" },
    { id: "10922", name: "FASTK" },
    { id: "79675", name: "FASTKD1" },
    { id: "22868", name: "FASTKD2" },
    { id: "79072", name: "FASTKD3" },
    { id: "60493", name: "FASTKD5" },
    { id: "2195", name: "FAT1" },
    { id: "120114", name: "FAT3" },
    { id: "79633", name: "FAT4" },
    { id: "89885", name: "FATE1" },
    { id: "2197", name: "FAU" },
    { id: "84553", name: "FAXC" },
    { id: "10826", name: "FAXDC2" },
    { id: "85302", name: "FBF1" },
    { id: "2091", name: "FBL" },
    { id: "54751", name: "FBLIM1" },
    { id: "2192", name: "FBLN1" },
    { id: "2199", name: "FBLN2" },
    { id: "10516", name: "FBLN5" },
    { id: "2200", name: "FBN1" },
    { id: "2201", name: "FBN2" },
    { id: "84467", name: "FBN3" },
    { id: "2203", name: "FBP1" },
    { id: "8789", name: "FBP2" },
    { id: "64319", name: "FBRS" },
    { id: "57666", name: "FBRSL1" },
    { id: "54850", name: "FBXL12" },
    { id: "222235", name: "FBXL13" },
    { id: "144699", name: "FBXL14" },
    { id: "79176", name: "FBXL15" },
    { id: "146330", name: "FBXL16" },
    { id: "64839", name: "FBXL17" },
    { id: "80028", name: "FBXL18" },
    { id: "54620", name: "FBXL19" },
    { id: "25827", name: "FBXL2" },
    { id: "84961", name: "FBXL20" },
    { id: "283807", name: "FBXL22" },
    { id: "26224", name: "FBXL3" },
    { id: "26235", name: "FBXL4" },
    { id: "26234", name: "FBXL5" },
    { id: "26233", name: "FBXL6" },
    { id: "23194", name: "FBXL7" },
    { id: "55336", name: "FBXL8" },
    { id: "26267", name: "FBXO10" },
    { id: "80204", name: "FBXO11" },
    { id: "201456", name: "FBXO15" },
    { id: "157574", name: "FBXO16" },
    { id: "115290", name: "FBXO17" },
    { id: "84893", name: "FBXO18" },
    { id: "26232", name: "FBXO2" },
    { id: "23014", name: "FBXO21" },
    { id: "26263", name: "FBXO22" },
    { id: "26261", name: "FBXO24" },
    { id: "26260", name: "FBXO25" },
    { id: "126433", name: "FBXO27" },
    { id: "23219", name: "FBXO28" },
    { id: "26273", name: "FBXO3" },
    { id: "84085", name: "FBXO30" },
    { id: "79791", name: "FBXO31" },
    { id: "114907", name: "FBXO32" },
    { id: "254170", name: "FBXO33" },
    { id: "55030", name: "FBXO34" },
    { id: "81545", name: "FBXO38" },
    { id: "162517", name: "FBXO39" },
    { id: "26272", name: "FBXO4" },
    { id: "51725", name: "FBXO40" },
    { id: "150726", name: "FBXO41" },
    { id: "54455", name: "FBXO42" },
    { id: "286151", name: "FBXO43" },
    { id: "93611", name: "FBXO44" },
    { id: "200933", name: "FBXO45" },
    { id: "23403", name: "FBXO46" },
    { id: "554251", name: "FBXO48" },
    { id: "26271", name: "FBXO5" },
    { id: "26270", name: "FBXO6" },
    { id: "25793", name: "FBXO7" },
    { id: "26269", name: "FBXO8" },
    { id: "26268", name: "FBXO9" },
    { id: "10517", name: "FBXW10" },
    { id: "23291", name: "FBXW11" },
    { id: "26190", name: "FBXW2" },
    { id: "6468", name: "FBXW4" },
    { id: "54461", name: "FBXW5" },
    { id: "55294", name: "FBXW7" },
    { id: "26259", name: "FBXW8" },
    { id: "84261", name: "FBXW9" },
    { id: "83953", name: "FCAMR" },
    { id: "2204", name: "FCAR" },
    { id: "2205", name: "FCER1A" },
    { id: "2207", name: "FCER1G" },
    { id: "2208", name: "FCER2" },
    { id: "51077", name: "FCF1" },
    { id: "8857", name: "FCGBP" },
    { id: "2209", name: "FCGR1A" },
    { id: "2212", name: "FCGR2A" },
    { id: "2213", name: "FCGR2B" },
    { id: "9103", name: "FCGR2C" },
    { id: "2214", name: "FCGR3A" },
    { id: "2215", name: "FCGR3B" },
    { id: "2217", name: "FCGRT" },
    { id: "23149", name: "FCHO1" },
    { id: "115548", name: "FCHO2" },
    { id: "89848", name: "FCHSD1" },
    { id: "9873", name: "FCHSD2" },
    { id: "2219", name: "FCN1" },
    { id: "2220", name: "FCN2" },
    { id: "8547", name: "FCN3" },
    { id: "79368", name: "FCRL2" },
    { id: "115352", name: "FCRL3" },
    { id: "83417", name: "FCRL4" },
    { id: "83416", name: "FCRL5" },
    { id: "127943", name: "FCRLB" },
    { id: "260436", name: "FDCSP" },
    { id: "2222", name: "FDFT1" },
    { id: "2224", name: "FDPS" },
    { id: "2230", name: "FDX1" },
    { id: "112812", name: "FDX1L" },
    { id: "91893", name: "FDXACB1" },
    { id: "2232", name: "FDXR" },
    { id: "2235", name: "FECH" },
    { id: "55527", name: "FEM1A" },
    { id: "10116", name: "FEM1B" },
    { id: "56929", name: "FEM1C" },
    { id: "2237", name: "FEN1" },
    { id: "2241", name: "FER" },
    { id: "654463", name: "FER1L6" },
    { id: "222894", name: "FERD3L" },
    { id: "55612", name: "FERMT1" },
    { id: "10979", name: "FERMT2" },
    { id: "83706", name: "FERMT3" },
    { id: "2242", name: "FES" },
    { id: "26998", name: "FETUB" },
    { id: "54738", name: "FEV" },
    { id: "9638", name: "FEZ1" },
    { id: "9637", name: "FEZ2" },
    { id: "389549", name: "FEZF1" },
    { id: "2864", name: "FFAR1" },
    { id: "2867", name: "FFAR2" },
    { id: "2243", name: "FGA" },
    { id: "2244", name: "FGB" },
    { id: "2245", name: "FGD1" },
    { id: "221472", name: "FGD2" },
    { id: "89846", name: "FGD3" },
    { id: "121512", name: "FGD4" },
    { id: "152273", name: "FGD5" },
    { id: "55785", name: "FGD6" },
    { id: "2246", name: "FGF1" },
    { id: "2255", name: "FGF10" },
    { id: "2257", name: "FGF12" },
    { id: "2258", name: "FGF13" },
    { id: "8823", name: "FGF16" },
    { id: "8822", name: "FGF17" },
    { id: "8817", name: "FGF18" },
    { id: "9965", name: "FGF19" },
    { id: "2247", name: "FGF2" },
    { id: "26291", name: "FGF21" },
    { id: "27006", name: "FGF22" },
    { id: "8074", name: "FGF23" },
    { id: "2250", name: "FGF5" },
    { id: "2252", name: "FGF7" },
    { id: "2254", name: "FGF9" },
    { id: "9982", name: "FGFBP1" },
    { id: "83888", name: "FGFBP2" },
    { id: "2260", name: "FGFR1" },
    { id: "11116", name: "FGFR1OP" },
    { id: "26127", name: "FGFR1OP2" },
    { id: "2263", name: "FGFR2" },
    { id: "2261", name: "FGFR3" },
    { id: "2264", name: "FGFR4" },
    { id: "53834", name: "FGFRL1" },
    { id: "2266", name: "FGG" },
    { id: "55277", name: "FGGY" },
    { id: "2267", name: "FGL1" },
    { id: "2268", name: "FGR" },
    { id: "2271", name: "FH" },
    { id: "2272", name: "FHIT" },
    { id: "2273", name: "FHL1" },
    { id: "2274", name: "FHL2" },
    { id: "2275", name: "FHL3" },
    { id: "9457", name: "FHL5" },
    { id: "29109", name: "FHOD1" },
    { id: "80206", name: "FHOD3" },
    { id: "84929", name: "FIBCD1" },
    { id: "9158", name: "FIBP" },
    { id: "11153", name: "FICD" },
    { id: "9896", name: "FIG4" },
    { id: "2277", name: "FIGF" },
    { id: "344018", name: "FIGLA" },
    { id: "55137", name: "FIGN" },
    { id: "63979", name: "FIGNL1" },
    { id: "401720", name: "FIGNL2" },
    { id: "27145", name: "FILIP1" },
    { id: "11259", name: "FILIP1L" },
    { id: "81608", name: "FIP1L1" },
    { id: "51024", name: "FIS1" },
    { id: "161247", name: "FITM1" },
    { id: "128486", name: "FITM2" },
    { id: "84922", name: "FIZ1" },
    { id: "24147", name: "FJX1" },
    { id: "60681", name: "FKBP10" },
    { id: "51303", name: "FKBP11" },
    { id: "55033", name: "FKBP14" },
    { id: "23307", name: "FKBP15" },
    { id: "2280", name: "FKBP1A" },
    { id: "2281", name: "FKBP1B" },
    { id: "2286", name: "FKBP2" },
    { id: "2287", name: "FKBP3" },
    { id: "2288", name: "FKBP4" },
    { id: "2289", name: "FKBP5" },
    { id: "8468", name: "FKBP6" },
    { id: "51661", name: "FKBP7" },
    { id: "23770", name: "FKBP8" },
    { id: "11328", name: "FKBP9" },
    { id: "63943", name: "FKBPL" },
    { id: "79147", name: "FKRP" },
    { id: "80308", name: "FLAD1" },
    { id: "201163", name: "FLCN" },
    { id: "2312", name: "FLG" },
    { id: "388698", name: "FLG2" },
    { id: "2313", name: "FLI1" },
    { id: "2314", name: "FLII" },
    { id: "2316", name: "FLNA" },
    { id: "2317", name: "FLNB" },
    { id: "2318", name: "FLNC" },
    { id: "10211", name: "FLOT1" },
    { id: "2319", name: "FLOT2" },
    { id: "23769", name: "FLRT1" },
    { id: "23768", name: "FLRT2" },
    { id: "23767", name: "FLRT3" },
    { id: "2321", name: "FLT1" },
    { id: "2322", name: "FLT3" },
    { id: "2323", name: "FLT3LG" },
    { id: "2324", name: "FLT4" },
    { id: "28982", name: "FLVCR1" },
    { id: "84256", name: "FLYWCH1" },
    { id: "114984", name: "FLYWCH2" },
    { id: "342184", name: "FMN1" },
    { id: "752", name: "FMNL1" },
    { id: "114793", name: "FMNL2" },
    { id: "91010", name: "FMNL3" },
    { id: "2332", name: "FMR1" },
    { id: "2335", name: "FN1" },
    { id: "79672", name: "FN3KRP" },
    { id: "23048", name: "FNBP1" },
    { id: "54874", name: "FNBP1L" },
    { id: "23360", name: "FNBP4" },
    { id: "64778", name: "FNDC3B" },
    { id: "64838", name: "FNDC4" },
    { id: "252995", name: "FNDC5" },
    { id: "54752", name: "FNDC8" },
    { id: "408263", name: "FNDC9" },
    { id: "96459", name: "FNIP1" },
    { id: "57600", name: "FNIP2" },
    { id: "2339", name: "FNTA" },
    { id: "2342", name: "FNTB" },
    { id: "54914", name: "FOCAD" },
    { id: "123811", name: "FOPNL" },
    { id: "2353", name: "FOS" },
    { id: "2354", name: "FOSB" },
    { id: "8061", name: "FOSL1" },
    { id: "2355", name: "FOSL2" },
    { id: "3169", name: "FOXA1" },
    { id: "3170", name: "FOXA2" },
    { id: "3171", name: "FOXA3" },
    { id: "27023", name: "FOXB1" },
    { id: "2296", name: "FOXC1" },
    { id: "2303", name: "FOXC2" },
    { id: "27022", name: "FOXD3" },
    { id: "2298", name: "FOXD4" },
    { id: "200350", name: "FOXD4L1" },
    { id: "286380", name: "FOXD4L3" },
    { id: "349334", name: "FOXD4L4" },
    { id: "653404", name: "FOXD4L6" },
    { id: "2304", name: "FOXE1" },
    { id: "2294", name: "FOXF1" },
    { id: "2295", name: "FOXF2" },
    { id: "2290", name: "FOXG1" },
    { id: "8928", name: "FOXH1" },
    { id: "2299", name: "FOXI1" },
    { id: "399823", name: "FOXI2" },
    { id: "2302", name: "FOXJ1" },
    { id: "55810", name: "FOXJ2" },
    { id: "22887", name: "FOXJ3" },
    { id: "221937", name: "FOXK1" },
    { id: "3607", name: "FOXK2" },
    { id: "2300", name: "FOXL1" },
    { id: "668", name: "FOXL2" },
    { id: "2305", name: "FOXM1" },
    { id: "8456", name: "FOXN1" },
    { id: "3344", name: "FOXN2" },
    { id: "1112", name: "FOXN3" },
    { id: "121643", name: "FOXN4" },
    { id: "2308", name: "FOXO1" },
    { id: "2309", name: "FOXO3" },
    { id: "4303", name: "FOXO4" },
    { id: "100132074", name: "FOXO6" },
    { id: "27086", name: "FOXP1" },
    { id: "93986", name: "FOXP2" },
    { id: "50943", name: "FOXP3" },
    { id: "116113", name: "FOXP4" },
    { id: "94234", name: "FOXQ1" },
    { id: "283150", name: "FOXR1" },
    { id: "139628", name: "FOXR2" },
    { id: "55572", name: "FOXRED1" },
    { id: "80020", name: "FOXRED2" },
    { id: "2307", name: "FOXS1" },
    { id: "8790", name: "FPGT" },
    { id: "100526835", name: "FPGT-TNNI3K" },
    { id: "2357", name: "FPR1" },
    { id: "2358", name: "FPR2" },
    { id: "118924", name: "FRA10AC1" },
    { id: "80144", name: "FRAS1" },
    { id: "10023", name: "FRAT1" },
    { id: "23401", name: "FRAT2" },
    { id: "158326", name: "FREM1" },
    { id: "341640", name: "FREM2" },
    { id: "284802", name: "FRG1B" },
    { id: "2444", name: "FRK" },
    { id: "79981", name: "FRMD1" },
    { id: "257019", name: "FRMD3" },
    { id: "55691", name: "FRMD4A" },
    { id: "23150", name: "FRMD4B" },
    { id: "84978", name: "FRMD5" },
    { id: "122786", name: "FRMD6" },
    { id: "90167", name: "FRMD7" },
    { id: "83786", name: "FRMD8" },
    { id: "22844", name: "FRMPD1" },
    { id: "9758", name: "FRMPD4" },
    { id: "10818", name: "FRS2" },
    { id: "10817", name: "FRS3" },
    { id: "10129", name: "FRY" },
    { id: "285527", name: "FRYL" },
    { id: "2487", name: "FRZB" },
    { id: "100861412", name: "FSBP" },
    { id: "6624", name: "FSCN1" },
    { id: "79187", name: "FSD1" },
    { id: "123722", name: "FSD2" },
    { id: "2488", name: "FSHB" },
    { id: "2492", name: "FSHR" },
    { id: "161835", name: "FSIP1" },
    { id: "401024", name: "FSIP2" },
    { id: "10468", name: "FST" },
    { id: "11167", name: "FSTL1" },
    { id: "10272", name: "FSTL3" },
    { id: "23105", name: "FSTL4" },
    { id: "56884", name: "FSTL5" },
    { id: "10841", name: "FTCD" },
    { id: "2495", name: "FTH1" },
    { id: "53940", name: "FTHL17" },
    { id: "2512", name: "FTL" },
    { id: "79068", name: "FTO" },
    { id: "24140", name: "FTSJ1" },
    { id: "29960", name: "FTSJ2" },
    { id: "117246", name: "FTSJ3" },
    { id: "55783", name: "FTSJD1" },
    { id: "23070", name: "FTSJD2" },
    { id: "8880", name: "FUBP1" },
    { id: "8939", name: "FUBP3" },
    { id: "2517", name: "FUCA1" },
    { id: "139341", name: "FUNDC1" },
    { id: "65991", name: "FUNDC2" },
    { id: "282969", name: "FUOM" },
    { id: "5045", name: "FURIN" },
    { id: "2521", name: "FUS" },
    { id: "2523", name: "FUT1" },
    { id: "84750", name: "FUT10" },
    { id: "170384", name: "FUT11" },
    { id: "2526", name: "FUT4" },
    { id: "2530", name: "FUT8" },
    { id: "80199", name: "FUZ" },
    { id: "2395", name: "FXN" },
    { id: "8087", name: "FXR1" },
    { id: "9513", name: "FXR2" },
    { id: "5348", name: "FXYD1" },
    { id: "486", name: "FXYD2" },
    { id: "5349", name: "FXYD3" },
    { id: "53827", name: "FXYD5" },
    { id: "53826", name: "FXYD6" },
    { id: "53822", name: "FXYD7" },
    { id: "2533", name: "FYB" },
    { id: "79443", name: "FYCO1" },
    { id: "2534", name: "FYN" },
    { id: "84248", name: "FYTTD1" },
    { id: "8321", name: "FZD1" },
    { id: "11211", name: "FZD10" },
    { id: "2535", name: "FZD2" },
    { id: "7976", name: "FZD3" },
    { id: "8322", name: "FZD4" },
    { id: "7855", name: "FZD5" },
    { id: "8323", name: "FZD6" },
    { id: "8324", name: "FZD7" },
    { id: "8325", name: "FZD8" },
    { id: "8326", name: "FZD9" },
    { id: "51343", name: "FZR1" },
    { id: "50486", name: "G0S2" },
    { id: "55632", name: "G2E3" },
    { id: "10146", name: "G3BP1" },
    { id: "9908", name: "G3BP2" },
    { id: "2538", name: "G6PC" },
    { id: "2539", name: "G6PD" },
    { id: "2548", name: "GAA" },
    { id: "2549", name: "GAB1" },
    { id: "9846", name: "GAB2" },
    { id: "139716", name: "GAB3" },
    { id: "11337", name: "GABARAP" },
    { id: "23710", name: "GABARAPL1" },
    { id: "11345", name: "GABARAPL2" },
    { id: "2550", name: "GABBR1" },
    { id: "9568", name: "GABBR2" },
    { id: "2551", name: "GABPA" },
    { id: "2553", name: "GABPB1" },
    { id: "126626", name: "GABPB2" },
    { id: "2554", name: "GABRA1" },
    { id: "2557", name: "GABRA4" },
    { id: "2560", name: "GABRB1" },
    { id: "2566", name: "GABRG2" },
    { id: "55879", name: "GABRQ" },
    { id: "2569", name: "GABRR1" },
    { id: "2570", name: "GABRR2" },
    { id: "2571", name: "GAD1" },
    { id: "2572", name: "GAD2" },
    { id: "1647", name: "GADD45A" },
    { id: "4616", name: "GADD45B" },
    { id: "10912", name: "GADD45G" },
    { id: "90480", name: "GADD45GIP1" },
    { id: "2543", name: "GAGE1" },
    { id: "729428", name: "GAGE12B" },
    { id: "729447", name: "GAGE2A" },
    { id: "2580", name: "GAK" },
    { id: "51083", name: "GAL" },
    { id: "9514", name: "GAL3ST1" },
    { id: "64090", name: "GAL3ST2" },
    { id: "79690", name: "GAL3ST4" },
    { id: "2582", name: "GALE" },
    { id: "2584", name: "GALK1" },
    { id: "130589", name: "GALM" },
    { id: "2589", name: "GALNT1" },
    { id: "55568", name: "GALNT10" },
    { id: "63917", name: "GALNT11" },
    { id: "79695", name: "GALNT12" },
    { id: "114805", name: "GALNT13" },
    { id: "79623", name: "GALNT14" },
    { id: "117248", name: "GALNT15" },
    { id: "2590", name: "GALNT2" },
    { id: "8693", name: "GALNT4" },
    { id: "11227", name: "GALNT5" },
    { id: "11226", name: "GALNT6" },
    { id: "51809", name: "GALNT7" },
    { id: "168391", name: "GALNTL5" },
    { id: "85569", name: "GALP" },
    { id: "8811", name: "GALR2" },
    { id: "8484", name: "GALR3" },
    { id: "2592", name: "GALT" },
    { id: "2593", name: "GAMT" },
    { id: "8139", name: "GAN" },
    { id: "23193", name: "GANAB" },
    { id: "2596", name: "GAP43" },
    { id: "2597", name: "GAPDH" },
    { id: "26330", name: "GAPDHS" },
    { id: "26130", name: "GAPVD1" },
    { id: "54433", name: "GAR1" },
    { id: "64762", name: "GAREM" },
    { id: "150946", name: "GAREML" },
    { id: "84253", name: "GARNL3" },
    { id: "2617", name: "GARS" },
    { id: "2618", name: "GART" },
    { id: "2619", name: "GAS1" },
    { id: "2620", name: "GAS2" },
    { id: "10634", name: "GAS2L1" },
    { id: "246176", name: "GAS2L2" },
    { id: "283431", name: "GAS2L3" },
    { id: "2621", name: "GAS6" },
    { id: "8522", name: "GAS7" },
    { id: "2622", name: "GAS8" },
    { id: "2520", name: "GAST" },
    { id: "2623", name: "GATA1" },
    { id: "2624", name: "GATA2" },
    { id: "2625", name: "GATA3" },
    { id: "2626", name: "GATA4" },
    { id: "140628", name: "GATA5" },
    { id: "2627", name: "GATA6" },
    { id: "57798", name: "GATAD1" },
    { id: "54815", name: "GATAD2A" },
    { id: "57459", name: "GATAD2B" },
    { id: "283459", name: "GATC" },
    { id: "2628", name: "GATM" },
    { id: "352954", name: "GATS" },
    { id: "729438", name: "GATSL2" },
    { id: "652968", name: "GATSL3" },
    { id: "2629", name: "GBA" },
    { id: "57704", name: "GBA2" },
    { id: "57733", name: "GBA3" },
    { id: "2631", name: "GBAS" },
    { id: "2632", name: "GBE1" },
    { id: "8729", name: "GBF1" },
    { id: "2633", name: "GBP1" },
    { id: "2634", name: "GBP2" },
    { id: "115362", name: "GBP5" },
    { id: "388646", name: "GBP7" },
    { id: "2638", name: "GC" },
    { id: "25801", name: "GCA" },
    { id: "23464", name: "GCAT" },
    { id: "79571", name: "GCC1" },
    { id: "9648", name: "GCC2" },
    { id: "2639", name: "GCDH" },
    { id: "6936", name: "GCFC2" },
    { id: "2641", name: "GCG" },
    { id: "2642", name: "GCGR" },
    { id: "2643", name: "GCH1" },
    { id: "2644", name: "GCHFR" },
    { id: "2646", name: "GCKR" },
    { id: "2729", name: "GCLC" },
    { id: "2730", name: "GCLM" },
    { id: "8521", name: "GCM1" },
    { id: "9247", name: "GCM2" },
    { id: "10985", name: "GCN1L1" },
    { id: "2650", name: "GCNT1" },
    { id: "9245", name: "GCNT3" },
    { id: "145781", name: "GCOM1" },
    { id: "257144", name: "GCSAM" },
    { id: "9615", name: "GDA" },
    { id: "54332", name: "GDAP1" },
    { id: "54834", name: "GDAP2" },
    { id: "51573", name: "GDE1" },
    { id: "10220", name: "GDF11" },
    { id: "9518", name: "GDF15" },
    { id: "2658", name: "GDF2" },
    { id: "9573", name: "GDF3" },
    { id: "8200", name: "GDF5" },
    { id: "392255", name: "GDF6" },
    { id: "151449", name: "GDF7" },
    { id: "2661", name: "GDF9" },
    { id: "2664", name: "GDI1" },
    { id: "2665", name: "GDI2" },
    { id: "2668", name: "GDNF" },
    { id: "284161", name: "GDPD1" },
    { id: "54857", name: "GDPD2" },
    { id: "79153", name: "GDPD3" },
    { id: "81544", name: "GDPD5" },
    { id: "390637", name: "GDPGP1" },
    { id: "2669", name: "GEM" },
    { id: "8487", name: "GEMIN2" },
    { id: "50628", name: "GEMIN4" },
    { id: "25929", name: "GEMIN5" },
    { id: "79833", name: "GEMIN6" },
    { id: "79760", name: "GEMIN7" },
    { id: "54960", name: "GEMIN8" },
    { id: "348654", name: "GEN1" },
    { id: "51608", name: "GET4" },
    { id: "2670", name: "GFAP" },
    { id: "2671", name: "GFER" },
    { id: "2672", name: "GFI1" },
    { id: "8328", name: "GFI1B" },
    { id: "85476", name: "GFM1" },
    { id: "84340", name: "GFM2" },
    { id: "54438", name: "GFOD1" },
    { id: "81577", name: "GFOD2" },
    { id: "2673", name: "GFPT1" },
    { id: "9945", name: "GFPT2" },
    { id: "2674", name: "GFRA1" },
    { id: "2675", name: "GFRA2" },
    { id: "64096", name: "GFRA4" },
    { id: "26088", name: "GGA1" },
    { id: "23062", name: "GGA2" },
    { id: "23163", name: "GGA3" },
    { id: "87769", name: "GGACT" },
    { id: "79017", name: "GGCT" },
    { id: "2677", name: "GGCX" },
    { id: "8836", name: "GGH" },
    { id: "199720", name: "GGN" },
    { id: "79893", name: "GGNBP2" },
    { id: "9453", name: "GGPS1" },
    { id: "2678", name: "GGT1" },
    { id: "124975", name: "GGT6" },
    { id: "2686", name: "GGT7" },
    { id: "92086", name: "GGTLC1" },
    { id: "91227", name: "GGTLC2" },
    { id: "2688", name: "GH1" },
    { id: "84514", name: "GHDC" },
    { id: "27069", name: "GHITM" },
    { id: "2690", name: "GHR" },
    { id: "2691", name: "GHRH" },
    { id: "2692", name: "GHRHR" },
    { id: "51738", name: "GHRL" },
    { id: "2693", name: "GHSR" },
    { id: "79018", name: "GID4" },
    { id: "54994", name: "GID8" },
    { id: "2694", name: "GIF" },
    { id: "64599", name: "GIGYF1" },
    { id: "26058", name: "GIGYF2" },
    { id: "170575", name: "GIMAP1" },
    { id: "55303", name: "GIMAP4" },
    { id: "55340", name: "GIMAP5" },
    { id: "474344", name: "GIMAP6" },
    { id: "168537", name: "GIMAP7" },
    { id: "155038", name: "GIMAP8" },
    { id: "54826", name: "GIN1" },
    { id: "116254", name: "GINM1" },
    { id: "9837", name: "GINS1" },
    { id: "51659", name: "GINS2" },
    { id: "64785", name: "GINS3" },
    { id: "84296", name: "GINS4" },
    { id: "2695", name: "GIP" },
    { id: "10755", name: "GIPC1" },
    { id: "54810", name: "GIPC2" },
    { id: "126326", name: "GIPC3" },
    { id: "2696", name: "GIPR" },
    { id: "28964", name: "GIT1" },
    { id: "9815", name: "GIT2" },
    { id: "2697", name: "GJA1" },
    { id: "2700", name: "GJA3" },
    { id: "2702", name: "GJA5" },
    { id: "81025", name: "GJA9" },
    { id: "2705", name: "GJB1" },
    { id: "2706", name: "GJB2" },
    { id: "2707", name: "GJB3" },
    { id: "127534", name: "GJB4" },
    { id: "10804", name: "GJB6" },
    { id: "10052", name: "GJC1" },
    { id: "57165", name: "GJC2" },
    { id: "125111", name: "GJD3" },
    { id: "219770", name: "GJD4" },
    { id: "2710", name: "GK" },
    { id: "256356", name: "GK5" },
    { id: "80318", name: "GKAP1" },
    { id: "56287", name: "GKN1" },
    { id: "200504", name: "GKN2" },
    { id: "2717", name: "GLA" },
    { id: "2720", name: "GLB1" },
    { id: "79411", name: "GLB1L" },
    { id: "89944", name: "GLB1L2" },
    { id: "113263", name: "GLCCI1" },
    { id: "26035", name: "GLCE" },
    { id: "2731", name: "GLDC" },
    { id: "2733", name: "GLE1" },
    { id: "2734", name: "GLG1" },
    { id: "2735", name: "GLI1" },
    { id: "2736", name: "GLI2" },
    { id: "2737", name: "GLI3" },
    { id: "144321", name: "GLIPR1L2" },
    { id: "152007", name: "GLIPR2" },
    { id: "148979", name: "GLIS1" },
    { id: "84662", name: "GLIS2" },
    { id: "169792", name: "GLIS3" },
    { id: "11146", name: "GLMN" },
    { id: "2739", name: "GLO1" },
    { id: "51031", name: "GLOD4" },
    { id: "392465", name: "GLOD5" },
    { id: "2740", name: "GLP1R" },
    { id: "9340", name: "GLP2R" },
    { id: "2742", name: "GLRA2" },
    { id: "2745", name: "GLRX" },
    { id: "51022", name: "GLRX2" },
    { id: "10539", name: "GLRX3" },
    { id: "51218", name: "GLRX5" },
    { id: "2744", name: "GLS" },
    { id: "27165", name: "GLS2" },
    { id: "55830", name: "GLT8D1" },
    { id: "83468", name: "GLT8D2" },
    { id: "51228", name: "GLTP" },
    { id: "80772", name: "GLTPD1" },
    { id: "388323", name: "GLTPD2" },
    { id: "29998", name: "GLTSCR1" },
    { id: "23506", name: "GLTSCR1L" },
    { id: "29997", name: "GLTSCR2" },
    { id: "2746", name: "GLUD1" },
    { id: "2747", name: "GLUD2" },
    { id: "2752", name: "GLUL" },
    { id: "10249", name: "GLYAT" },
    { id: "389396", name: "GLYATL3" },
    { id: "132158", name: "GLYCTK" },
    { id: "84656", name: "GLYR1" },
    { id: "2760", name: "GM2A" },
    { id: "64395", name: "GMCL1" },
    { id: "64396", name: "GMCL1P1" },
    { id: "2762", name: "GMDS" },
    { id: "10691", name: "GMEB1" },
    { id: "26205", name: "GMEB2" },
    { id: "2764", name: "GMFB" },
    { id: "9535", name: "GMFG" },
    { id: "51291", name: "GMIP" },
    { id: "51053", name: "GMNN" },
    { id: "29926", name: "GMPPA" },
    { id: "29925", name: "GMPPB" },
    { id: "2766", name: "GMPR" },
    { id: "51292", name: "GMPR2" },
    { id: "8833", name: "GMPS" },
    { id: "2767", name: "GNA11" },
    { id: "2768", name: "GNA12" },
    { id: "10672", name: "GNA13" },
    { id: "9630", name: "GNA14" },
    { id: "2769", name: "GNA15" },
    { id: "2770", name: "GNAI1" },
    { id: "2771", name: "GNAI2" },
    { id: "2773", name: "GNAI3" },
    { id: "2775", name: "GNAO1" },
    { id: "2776", name: "GNAQ" },
    { id: "2778", name: "GNAS" },
    { id: "2779", name: "GNAT1" },
    { id: "2780", name: "GNAT2" },
    { id: "346562", name: "GNAT3" },
    { id: "2781", name: "GNAZ" },
    { id: "2782", name: "GNB1" },
    { id: "54584", name: "GNB1L" },
    { id: "2783", name: "GNB2" },
    { id: "10399", name: "GNB2L1" },
    { id: "2784", name: "GNB3" },
    { id: "59345", name: "GNB4" },
    { id: "10681", name: "GNB5" },
    { id: "10020", name: "GNE" },
    { id: "2790", name: "GNG10" },
    { id: "2791", name: "GNG11" },
    { id: "55970", name: "GNG12" },
    { id: "51764", name: "GNG13" },
    { id: "54331", name: "GNG2" },
    { id: "2785", name: "GNG3" },
    { id: "2786", name: "GNG4" },
    { id: "2787", name: "GNG5" },
    { id: "2788", name: "GNG7" },
    { id: "94235", name: "GNG8" },
    { id: "2792", name: "GNGT1" },
    { id: "2793", name: "GNGT2" },
    { id: "2794", name: "GNL1" },
    { id: "29889", name: "GNL2" },
    { id: "26354", name: "GNL3" },
    { id: "54552", name: "GNL3L" },
    { id: "10578", name: "GNLY" },
    { id: "27232", name: "GNMT" },
    { id: "253724", name: "GNN" },
    { id: "8443", name: "GNPAT" },
    { id: "10007", name: "GNPDA1" },
    { id: "132789", name: "GNPDA2" },
    { id: "64841", name: "GNPNAT1" },
    { id: "79158", name: "GNPTAB" },
    { id: "84572", name: "GNPTG" },
    { id: "2797", name: "GNRH2" },
    { id: "2798", name: "GNRHR" },
    { id: "114814", name: "GNRHR2" },
    { id: "2799", name: "GNS" },
    { id: "2800", name: "GOLGA1" },
    { id: "2801", name: "GOLGA2" },
    { id: "2802", name: "GOLGA3" },
    { id: "2803", name: "GOLGA4" },
    { id: "9950", name: "GOLGA5" },
    { id: "342096", name: "GOLGA6A" },
    { id: "647042", name: "GOLGA6L10" },
    { id: "283685", name: "GOLGA6L2" },
    { id: "100133220", name: "GOLGA6L3" },
    { id: "440295", name: "GOLGA6L9" },
    { id: "51125", name: "GOLGA7" },
    { id: "401647", name: "GOLGA7B" },
    { id: "23015", name: "GOLGA8A" },
    { id: "440270", name: "GOLGA8B" },
    { id: "100132979", name: "GOLGA8DP" },
    { id: "283768", name: "GOLGA8G" },
    { id: "653073", name: "GOLGA8J" },
    { id: "643699", name: "GOLGA8R" },
    { id: "2804", name: "GOLGB1" },
    { id: "27333", name: "GOLIM4" },
    { id: "51280", name: "GOLM1" },
    { id: "64083", name: "GOLPH3" },
    { id: "55204", name: "GOLPH3L" },
    { id: "51026", name: "GOLT1B" },
    { id: "54856", name: "GON4L" },
    { id: "57120", name: "GOPC" },
    { id: "92344", name: "GORAB" },
    { id: "64689", name: "GORASP1" },
    { id: "26003", name: "GORASP2" },
    { id: "9527", name: "GOSR1" },
    { id: "9570", name: "GOSR2" },
    { id: "2805", name: "GOT1" },
    { id: "137362", name: "GOT1L1" },
    { id: "2806", name: "GOT2" },
    { id: "2811", name: "GP1BA" },
    { id: "2812", name: "GP1BB" },
    { id: "2813", name: "GP2" },
    { id: "2814", name: "GP5" },
    { id: "51206", name: "GP6" },
    { id: "2815", name: "GP9" },
    { id: "8733", name: "GPAA1" },
    { id: "57678", name: "GPAM" },
    { id: "7918", name: "GPANK1" },
    { id: "150763", name: "GPAT2" },
    { id: "55094", name: "GPATCH1" },
    { id: "253635", name: "GPATCH11" },
    { id: "55105", name: "GPATCH2" },
    { id: "55668", name: "GPATCH2L" },
    { id: "54865", name: "GPATCH4" },
    { id: "23131", name: "GPATCH8" },
    { id: "65056", name: "GPBP1" },
    { id: "60313", name: "GPBP1L1" },
    { id: "2817", name: "GPC1" },
    { id: "221914", name: "GPC2" },
    { id: "2719", name: "GPC3" },
    { id: "2239", name: "GPC4" },
    { id: "56261", name: "GPCPD1" },
    { id: "2819", name: "GPD1" },
    { id: "23171", name: "GPD1L" },
    { id: "2820", name: "GPD2" },
    { id: "170589", name: "GPHA2" },
    { id: "122876", name: "GPHB5" },
    { id: "10243", name: "GPHN" },
    { id: "2821", name: "GPI" },
    { id: "338328", name: "GPIHBP1" },
    { id: "27238", name: "GPKOW" },
    { id: "2822", name: "GPLD1" },
    { id: "2824", name: "GPM6B" },
    { id: "11321", name: "GPN1" },
    { id: "54707", name: "GPN2" },
    { id: "51184", name: "GPN3" },
    { id: "10457", name: "GPNMB" },
    { id: "2825", name: "GPR1" },
    { id: "83550", name: "GPR101" },
    { id: "57720", name: "GPR107" },
    { id: "56927", name: "GPR108" },
    { id: "165082", name: "GPR113" },
    { id: "221188", name: "GPR114" },
    { id: "221393", name: "GPR115" },
    { id: "139760", name: "GPR119" },
    { id: "2835", name: "GPR12" },
    { id: "25960", name: "GPR124" },
    { id: "166647", name: "GPR125" },
    { id: "57211", name: "GPR126" },
    { id: "84873", name: "GPR128" },
    { id: "64582", name: "GPR135" },
    { id: "350383", name: "GPR142" },
    { id: "4935", name: "GPR143" },
    { id: "134391", name: "GPR151" },
    { id: "390212", name: "GPR152" },
    { id: "387509", name: "GPR153" },
    { id: "151556", name: "GPR155" },
    { id: "57512", name: "GPR158" },
    { id: "23432", name: "GPR161" },
    { id: "11245", name: "GPR176" },
    { id: "440435", name: "GPR179" },
    { id: "2845", name: "GPR22" },
    { id: "2848", name: "GPR25" },
    { id: "2827", name: "GPR3" },
    { id: "2861", name: "GPR37" },
    { id: "9283", name: "GPR37L1" },
    { id: "11250", name: "GPR45" },
    { id: "9248", name: "GPR50" },
    { id: "9289", name: "GPR56" },
    { id: "83873", name: "GPR61" },
    { id: "81491", name: "GPR63" },
    { id: "10149", name: "GPR64" },
    { id: "27201", name: "GPR78" },
    { id: "653519", name: "GPR89A" },
    { id: "51463", name: "GPR89B" },
    { id: "84059", name: "GPR98" },
    { id: "9737", name: "GPRASP1" },
    { id: "114928", name: "GPRASP2" },
    { id: "9052", name: "GPRC5A" },
    { id: "51704", name: "GPRC5B" },
    { id: "55890", name: "GPRC5C" },
    { id: "55507", name: "GPRC5D" },
    { id: "114787", name: "GPRIN1" },
    { id: "9721", name: "GPRIN2" },
    { id: "2873", name: "GPS1" },
    { id: "2874", name: "GPS2" },
    { id: "26086", name: "GPSM1" },
    { id: "29899", name: "GPSM2" },
    { id: "63940", name: "GPSM3" },
    { id: "2875", name: "GPT" },
    { id: "84706", name: "GPT2" },
    { id: "2876", name: "GPX1" },
    { id: "2877", name: "GPX2" },
    { id: "257202", name: "GPX6" },
    { id: "2882", name: "GPX7" },
    { id: "493869", name: "GPX8" },
    { id: "57655", name: "GRAMD1A" },
    { id: "54762", name: "GRAMD1C" },
    { id: "196996", name: "GRAMD2" },
    { id: "65983", name: "GRAMD3" },
    { id: "23151", name: "GRAMD4" },
    { id: "10750", name: "GRAP" },
    { id: "9402", name: "GRAP2" },
    { id: "160622", name: "GRASP" },
    { id: "2887", name: "GRB10" },
    { id: "2888", name: "GRB14" },
    { id: "2885", name: "GRB2" },
    { id: "2886", name: "GRB7" },
    { id: "9687", name: "GREB1" },
    { id: "26585", name: "GREM1" },
    { id: "64388", name: "GREM2" },
    { id: "29841", name: "GRHL1" },
    { id: "79977", name: "GRHL2" },
    { id: "57822", name: "GRHL3" },
    { id: "9380", name: "GRHPR" },
    { id: "2890", name: "GRIA1" },
    { id: "2891", name: "GRIA2" },
    { id: "2892", name: "GRIA3" },
    { id: "2893", name: "GRIA4" },
    { id: "2894", name: "GRID1" },
    { id: "2895", name: "GRID2" },
    { id: "392862", name: "GRID2IP" },
    { id: "2897", name: "GRIK1" },
    { id: "2898", name: "GRIK2" },
    { id: "2899", name: "GRIK3" },
    { id: "2900", name: "GRIK4" },
    { id: "2901", name: "GRIK5" },
    { id: "2902", name: "GRIN1" },
    { id: "2903", name: "GRIN2A" },
    { id: "2904", name: "GRIN2B" },
    { id: "2905", name: "GRIN2C" },
    { id: "2906", name: "GRIN2D" },
    { id: "116443", name: "GRIN3A" },
    { id: "116444", name: "GRIN3B" },
    { id: "2907", name: "GRINA" },
    { id: "23426", name: "GRIP1" },
    { id: "80852", name: "GRIP2" },
    { id: "56850", name: "GRIPAP1" },
    { id: "6011", name: "GRK1" },
    { id: "2868", name: "GRK4" },
    { id: "2869", name: "GRK5" },
    { id: "2870", name: "GRK6" },
    { id: "131890", name: "GRK7" },
    { id: "2911", name: "GRM1" },
    { id: "2912", name: "GRM2" },
    { id: "2913", name: "GRM3" },
    { id: "2914", name: "GRM4" },
    { id: "2915", name: "GRM5" },
    { id: "2916", name: "GRM6" },
    { id: "2917", name: "GRM7" },
    { id: "2918", name: "GRM8" },
    { id: "2896", name: "GRN" },
    { id: "2922", name: "GRP" },
    { id: "80273", name: "GRPEL1" },
    { id: "134266", name: "GRPEL2" },
    { id: "2925", name: "GRPR" },
    { id: "79774", name: "GRTP1" },
    { id: "83743", name: "GRWD1" },
    { id: "389207", name: "GRXCR1" },
    { id: "54103", name: "GSAP" },
    { id: "145258", name: "GSC" },
    { id: "284110", name: "GSDMA" },
    { id: "55876", name: "GSDMB" },
    { id: "79792", name: "GSDMD" },
    { id: "23199", name: "GSE1" },
    { id: "83445", name: "GSG1" },
    { id: "146395", name: "GSG1L" },
    { id: "83903", name: "GSG2" },
    { id: "2931", name: "GSK3A" },
    { id: "2932", name: "GSK3B" },
    { id: "51527", name: "GSKIP" },
    { id: "2934", name: "GSN" },
    { id: "2935", name: "GSPT1" },
    { id: "23708", name: "GSPT2" },
    { id: "2936", name: "GSR" },
    { id: "2937", name: "GSS" },
    { id: "2938", name: "GSTA1" },
    { id: "2939", name: "GSTA2" },
    { id: "2940", name: "GSTA3" },
    { id: "2941", name: "GSTA4" },
    { id: "79807", name: "GSTCD" },
    { id: "373156", name: "GSTK1" },
    { id: "2944", name: "GSTM1" },
    { id: "2946", name: "GSTM2" },
    { id: "2947", name: "GSTM3" },
    { id: "2948", name: "GSTM4" },
    { id: "2949", name: "GSTM5" },
    { id: "9446", name: "GSTO1" },
    { id: "119391", name: "GSTO2" },
    { id: "2950", name: "GSTP1" },
    { id: "2952", name: "GSTT1" },
    { id: "2953", name: "GSTT2" },
    { id: "653689", name: "GSTT2B" },
    { id: "2954", name: "GSTZ1" },
    { id: "170825", name: "GSX2" },
    { id: "79712", name: "GTDC1" },
    { id: "2957", name: "GTF2A1" },
    { id: "11036", name: "GTF2A1L" },
    { id: "2958", name: "GTF2A2" },
    { id: "2959", name: "GTF2B" },
    { id: "2960", name: "GTF2E1" },
    { id: "2961", name: "GTF2E2" },
    { id: "2962", name: "GTF2F1" },
    { id: "2963", name: "GTF2F2" },
    { id: "2965", name: "GTF2H1" },
    { id: "2966", name: "GTF2H2" },
    { id: "730394", name: "GTF2H2D" },
    { id: "2967", name: "GTF2H3" },
    { id: "2968", name: "GTF2H4" },
    { id: "404672", name: "GTF2H5" },
    { id: "2969", name: "GTF2I" },
    { id: "9569", name: "GTF2IRD1" },
    { id: "84163", name: "GTF2IRD2" },
    { id: "389524", name: "GTF2IRD2B" },
    { id: "2971", name: "GTF3A" },
    { id: "2975", name: "GTF3C1" },
    { id: "2976", name: "GTF3C2" },
    { id: "9330", name: "GTF3C3" },
    { id: "9329", name: "GTF3C4" },
    { id: "9328", name: "GTF3C5" },
    { id: "112495", name: "GTF3C6" },
    { id: "9567", name: "GTPBP1" },
    { id: "85865", name: "GTPBP10" },
    { id: "54676", name: "GTPBP2" },
    { id: "84705", name: "GTPBP3" },
    { id: "23560", name: "GTPBP4" },
    { id: "8225", name: "GTPBP6" },
    { id: "29083", name: "GTPBP8" },
    { id: "51512", name: "GTSE1" },
    { id: "121355", name: "GTSF1" },
    { id: "2980", name: "GUCA2A" },
    { id: "83606", name: "GUCD1" },
    { id: "2977", name: "GUCY1A2" },
    { id: "2983", name: "GUCY1B3" },
    { id: "2984", name: "GUCY2C" },
    { id: "3000", name: "GUCY2D" },
    { id: "2986", name: "GUCY2F" },
    { id: "60558", name: "GUF1" },
    { id: "51454", name: "GULP1" },
    { id: "2990", name: "GUSB" },
    { id: "728411", name: "GUSBP1" },
    { id: "283464", name: "GXYLT1" },
    { id: "2992", name: "GYG1" },
    { id: "8908", name: "GYG2" },
    { id: "2993", name: "GYPA" },
    { id: "2995", name: "GYPC" },
    { id: "2997", name: "GYS1" },
    { id: "64412", name: "GZF1" },
    { id: "3001", name: "GZMA" },
    { id: "3002", name: "GZMB" },
    { id: "2999", name: "GZMH" },
    { id: "3003", name: "GZMK" },
    { id: "3004", name: "GZMM" },
    { id: "3005", name: "H1F0" },
    { id: "341567", name: "H1FNT" },
    { id: "8971", name: "H1FX" },
    { id: "474382", name: "H2AFB1" },
    { id: "474381", name: "H2AFB2" },
    { id: "83740", name: "H2AFB3" },
    { id: "55766", name: "H2AFJ" },
    { id: "94239", name: "H2AFV" },
    { id: "3014", name: "H2AFX" },
    { id: "9555", name: "H2AFY" },
    { id: "55506", name: "H2AFY2" },
    { id: "3015", name: "H2AFZ" },
    { id: "286436", name: "H2BFM" },
    { id: "3020", name: "H3F3A" },
    { id: "3021", name: "H3F3B" },
    { id: "440093", name: "H3F3C" },
    { id: "23498", name: "HAAO" },
    { id: "3026", name: "HABP2" },
    { id: "22927", name: "HABP4" },
    { id: "57531", name: "HACE1" },
    { id: "26061", name: "HACL1" },
    { id: "3033", name: "HADH" },
    { id: "3030", name: "HADHA" },
    { id: "3032", name: "HADHB" },
    { id: "3029", name: "HAGH" },
    { id: "84264", name: "HAGHL" },
    { id: "57817", name: "HAMP" },
    { id: "9421", name: "HAND1" },
    { id: "9464", name: "HAND2" },
    { id: "9001", name: "HAP1" },
    { id: "1404", name: "HAPLN1" },
    { id: "60484", name: "HAPLN2" },
    { id: "3035", name: "HARS" },
    { id: "23438", name: "HARS2" },
    { id: "3036", name: "HAS1" },
    { id: "3038", name: "HAS3" },
    { id: "8520", name: "HAT1" },
    { id: "115106", name: "HAUS1" },
    { id: "55142", name: "HAUS2" },
    { id: "79441", name: "HAUS3" },
    { id: "54930", name: "HAUS4" },
    { id: "23354", name: "HAUS5" },
    { id: "54801", name: "HAUS6" },
    { id: "55559", name: "HAUS7" },
    { id: "93323", name: "HAUS8" },
    { id: "84868", name: "HAVCR2" },
    { id: "10456", name: "HAX1" },
    { id: "3039", name: "HBA1" },
    { id: "3040", name: "HBA2" },
    { id: "3043", name: "HBB" },
    { id: "3045", name: "HBD" },
    { id: "3046", name: "HBE1" },
    { id: "1839", name: "HBEGF" },
    { id: "3047", name: "HBG1" },
    { id: "3048", name: "HBG2" },
    { id: "26959", name: "HBP1" },
    { id: "3049", name: "HBQ1" },
    { id: "10767", name: "HBS1L" },
    { id: "3050", name: "HBZ" },
    { id: "3052", name: "HCCS" },
    { id: "3054", name: "HCFC1" },
    { id: "54985", name: "HCFC1R1" },
    { id: "29915", name: "HCFC2" },
    { id: "3055", name: "HCK" },
    { id: "3059", name: "HCLS1" },
    { id: "348980", name: "HCN1" },
    { id: "610", name: "HCN2" },
    { id: "57657", name: "HCN3" },
    { id: "10021", name: "HCN4" },
    { id: "10866", name: "HCP5" },
    { id: "3061", name: "HCRTR1" },
    { id: "3062", name: "HCRTR2" },
    { id: "10870", name: "HCST" },
    { id: "3065", name: "HDAC1" },
    { id: "83933", name: "HDAC10" },
    { id: "79885", name: "HDAC11" },
    { id: "3066", name: "HDAC2" },
    { id: "8841", name: "HDAC3" },
    { id: "9759", name: "HDAC4" },
    { id: "10014", name: "HDAC5" },
    { id: "10013", name: "HDAC6" },
    { id: "51564", name: "HDAC7" },
    { id: "55869", name: "HDAC8" },
    { id: "9734", name: "HDAC9" },
    { id: "3067", name: "HDC" },
    { id: "51020", name: "HDDC2" },
    { id: "374659", name: "HDDC3" },
    { id: "3068", name: "HDGF" },
    { id: "84717", name: "HDGFRP2" },
    { id: "50810", name: "HDGFRP3" },
    { id: "8226", name: "HDHD1" },
    { id: "84064", name: "HDHD2" },
    { id: "81932", name: "HDHD3" },
    { id: "3069", name: "HDLBP" },
    { id: "139324", name: "HDX" },
    { id: "55127", name: "HEATR1" },
    { id: "54919", name: "HEATR2" },
    { id: "55027", name: "HEATR3" },
    { id: "399671", name: "HEATR4" },
    { id: "25938", name: "HEATR5A" },
    { id: "54497", name: "HEATR5B" },
    { id: "63897", name: "HEATR6" },
    { id: "50865", name: "HEBP1" },
    { id: "23593", name: "HEBP2" },
    { id: "25831", name: "HECTD1" },
    { id: "143279", name: "HECTD2" },
    { id: "79654", name: "HECTD3" },
    { id: "283450", name: "HECTD4" },
    { id: "23072", name: "HECW1" },
    { id: "57520", name: "HECW2" },
    { id: "57493", name: "HEG1" },
    { id: "92797", name: "HELB" },
    { id: "3070", name: "HELLS" },
    { id: "113510", name: "HELQ" },
    { id: "391723", name: "HELT" },
    { id: "9931", name: "HELZ" },
    { id: "85441", name: "HELZ2" },
    { id: "55363", name: "HEMGN" },
    { id: "51409", name: "HEMK1" },
    { id: "113802", name: "HENMT1" },
    { id: "8925", name: "HERC1" },
    { id: "8924", name: "HERC2" },
    { id: "8916", name: "HERC3" },
    { id: "26091", name: "HERC4" },
    { id: "51191", name: "HERC5" },
    { id: "55008", name: "HERC6" },
    { id: "9709", name: "HERPUD1" },
    { id: "64224", name: "HERPUD2" },
    { id: "3280", name: "HES1" },
    { id: "54626", name: "HES2" },
    { id: "57801", name: "HES4" },
    { id: "388585", name: "HES5" },
    { id: "55502", name: "HES6" },
    { id: "84667", name: "HES7" },
    { id: "8820", name: "HESX1" },
    { id: "3073", name: "HEXA" },
    { id: "3074", name: "HEXB" },
    { id: "284004", name: "HEXDC" },
    { id: "10614", name: "HEXIM1" },
    { id: "124790", name: "HEXIM2" },
    { id: "23462", name: "HEY1" },
    { id: "23493", name: "HEY2" },
    { id: "26508", name: "HEYL" },
    { id: "3077", name: "HFE" },
    { id: "148738", name: "HFE2" },
    { id: "3081", name: "HGD" },
    { id: "3082", name: "HGF" },
    { id: "3083", name: "HGFAC" },
    { id: "9146", name: "HGS" },
    { id: "55733", name: "HHAT" },
    { id: "57467", name: "HHATL" },
    { id: "3087", name: "HHEX" },
    { id: "64399", name: "HHIP" },
    { id: "84439", name: "HHIPL1" },
    { id: "79802", name: "HHIPL2" },
    { id: "11147", name: "HHLA3" },
    { id: "84641", name: "HIATL1" },
    { id: "11112", name: "HIBADH" },
    { id: "26275", name: "HIBCH" },
    { id: "3090", name: "HIC1" },
    { id: "23119", name: "HIC2" },
    { id: "283987", name: "HID1" },
    { id: "3091", name: "HIF1A" },
    { id: "55662", name: "HIF1AN" },
    { id: "64344", name: "HIF3A" },
    { id: "25994", name: "HIGD1A" },
    { id: "613227", name: "HIGD1C" },
    { id: "192286", name: "HIGD2A" },
    { id: "123346", name: "HIGD2B" },
    { id: "29923", name: "HILPDA" },
    { id: "25988", name: "HINFP" },
    { id: "3094", name: "HINT1" },
    { id: "84681", name: "HINT2" },
    { id: "135114", name: "HINT3" },
    { id: "3092", name: "HIP1" },
    { id: "9026", name: "HIP1R" },
    { id: "204851", name: "HIPK1" },
    { id: "28996", name: "HIPK2" },
    { id: "10114", name: "HIPK3" },
    { id: "147746", name: "HIPK4" },
    { id: "7290", name: "HIRA" },
    { id: "8479", name: "HIRIP3" },
    { id: "3024", name: "HIST1H1A" },
    { id: "3009", name: "HIST1H1B" },
    { id: "3006", name: "HIST1H1C" },
    { id: "3007", name: "HIST1H1D" },
    { id: "3008", name: "HIST1H1E" },
    { id: "3010", name: "HIST1H1T" },
    { id: "221613", name: "HIST1H2AA" },
    { id: "8335", name: "HIST1H2AB" },
    { id: "8334", name: "HIST1H2AC" },
    { id: "3013", name: "HIST1H2AD" },
    { id: "8969", name: "HIST1H2AG" },
    { id: "85235", name: "HIST1H2AH" },
    { id: "8329", name: "HIST1H2AI" },
    { id: "8331", name: "HIST1H2AJ" },
    { id: "8332", name: "HIST1H2AL" },
    { id: "255626", name: "HIST1H2BA" },
    { id: "3018", name: "HIST1H2BB" },
    { id: "8347", name: "HIST1H2BC" },
    { id: "3017", name: "HIST1H2BD" },
    { id: "8339", name: "HIST1H2BG" },
    { id: "8345", name: "HIST1H2BH" },
    { id: "8970", name: "HIST1H2BJ" },
    { id: "85236", name: "HIST1H2BK" },
    { id: "8340", name: "HIST1H2BL" },
    { id: "8342", name: "HIST1H2BM" },
    { id: "8341", name: "HIST1H2BN" },
    { id: "8348", name: "HIST1H2BO" },
    { id: "8350", name: "HIST1H3A" },
    { id: "8352", name: "HIST1H3C" },
    { id: "8353", name: "HIST1H3E" },
    { id: "8968", name: "HIST1H3F" },
    { id: "8355", name: "HIST1H3G" },
    { id: "8357", name: "HIST1H3H" },
    { id: "8354", name: "HIST1H3I" },
    { id: "8359", name: "HIST1H4A" },
    { id: "8361", name: "HIST1H4F" },
    { id: "8369", name: "HIST1H4G" },
    { id: "8365", name: "HIST1H4H" },
    { id: "8294", name: "HIST1H4I" },
    { id: "8363", name: "HIST1H4J" },
    { id: "8337", name: "HIST2H2AA3" },
    { id: "317772", name: "HIST2H2AB" },
    { id: "8338", name: "HIST2H2AC" },
    { id: "337873", name: "HIST2H2BC" },
    { id: "8349", name: "HIST2H2BE" },
    { id: "440689", name: "HIST2H2BF" },
    { id: "333932", name: "HIST2H3A" },
    { id: "126961", name: "HIST2H3C" },
    { id: "653604", name: "HIST2H3D" },
    { id: "8370", name: "HIST2H4A" },
    { id: "92815", name: "HIST3H2A" },
    { id: "128312", name: "HIST3H2BB" },
    { id: "8290", name: "HIST3H3" },
    { id: "121504", name: "HIST4H4" },
    { id: "3096", name: "HIVEP1" },
    { id: "59269", name: "HIVEP3" },
    { id: "55355", name: "HJURP" },
    { id: "3098", name: "HK1" },
    { id: "3099", name: "HK2" },
    { id: "3101", name: "HK3" },
    { id: "80201", name: "HKDC1" },
    { id: "3105", name: "HLA-A" },
    { id: "3106", name: "HLA-B" },
    { id: "3107", name: "HLA-C" },
    { id: "3108", name: "HLA-DMA" },
    { id: "3109", name: "HLA-DMB" },
    { id: "3111", name: "HLA-DOA" },
    { id: "3112", name: "HLA-DOB" },
    { id: "3113", name: "HLA-DPA1" },
    { id: "3115", name: "HLA-DPB1" },
    { id: "3117", name: "HLA-DQA1" },
    { id: "3118", name: "HLA-DQA2" },
    { id: "3119", name: "HLA-DQB1" },
    { id: "3122", name: "HLA-DRA" },
    { id: "3123", name: "HLA-DRB1" },
    { id: "3125", name: "HLA-DRB3" },
    { id: "3126", name: "HLA-DRB4" },
    { id: "3127", name: "HLA-DRB5" },
    { id: "3133", name: "HLA-E" },
    { id: "3134", name: "HLA-F" },
    { id: "3135", name: "HLA-G" },
    { id: "3141", name: "HLCS" },
    { id: "3131", name: "HLF" },
    { id: "6596", name: "HLTF" },
    { id: "3142", name: "HLX" },
    { id: "81502", name: "HM13" },
    { id: "79618", name: "HMBOX1" },
    { id: "3145", name: "HMBS" },
    { id: "83872", name: "HMCN1" },
    { id: "10363", name: "HMG20A" },
    { id: "10362", name: "HMG20B" },
    { id: "3159", name: "HMGA1" },
    { id: "8091", name: "HMGA2" },
    { id: "3146", name: "HMGB1" },
    { id: "3148", name: "HMGB2" },
    { id: "3149", name: "HMGB3" },
    { id: "127540", name: "HMGB4" },
    { id: "3155", name: "HMGCL" },
    { id: "3156", name: "HMGCR" },
    { id: "3157", name: "HMGCS1" },
    { id: "3158", name: "HMGCS2" },
    { id: "3150", name: "HMGN1" },
    { id: "3151", name: "HMGN2" },
    { id: "9324", name: "HMGN3" },
    { id: "10473", name: "HMGN4" },
    { id: "79366", name: "HMGN5" },
    { id: "22993", name: "HMGXB3" },
    { id: "10042", name: "HMGXB4" },
    { id: "23526", name: "HMHA1" },
    { id: "3161", name: "HMMR" },
    { id: "3162", name: "HMOX1" },
    { id: "3163", name: "HMOX2" },
    { id: "51617", name: "HMP19" },
    { id: "340784", name: "HMX3" },
    { id: "51155", name: "HN1" },
    { id: "90861", name: "HN1L" },
    { id: "6927", name: "HNF1A" },
    { id: "6928", name: "HNF1B" },
    { id: "3172", name: "HNF4A" },
    { id: "3174", name: "HNF4G" },
    { id: "3176", name: "HNMT" },
    { id: "10949", name: "HNRNPA0" },
    { id: "3178", name: "HNRNPA1" },
    { id: "144983", name: "HNRNPA1L2" },
    { id: "3181", name: "HNRNPA2B1" },
    { id: "220988", name: "HNRNPA3" },
    { id: "3182", name: "HNRNPAB" },
    { id: "3183", name: "HNRNPC" },
    { id: "343069", name: "HNRNPCL1" },
    { id: "440563", name: "HNRNPCP5" },
    { id: "3184", name: "HNRNPD" },
    { id: "9987", name: "HNRNPDL" },
    { id: "3185", name: "HNRNPF" },
    { id: "3187", name: "HNRNPH1" },
    { id: "3188", name: "HNRNPH2" },
    { id: "3189", name: "HNRNPH3" },
    { id: "3190", name: "HNRNPK" },
    { id: "3191", name: "HNRNPL" },
    { id: "92906", name: "HNRNPLL" },
    { id: "4670", name: "HNRNPM" },
    { id: "10236", name: "HNRNPR" },
    { id: "3192", name: "HNRNPU" },
    { id: "11100", name: "HNRNPUL1" },
    { id: "221092", name: "HNRNPUL2" },
    { id: "9456", name: "HOMER1" },
    { id: "9455", name: "HOMER2" },
    { id: "9454", name: "HOMER3" },
    { id: "57594", name: "HOMEZ" },
    { id: "51361", name: "HOOK1" },
    { id: "29911", name: "HOOK2" },
    { id: "84376", name: "HOOK3" },
    { id: "84525", name: "HOPX" },
    { id: "84072", name: "HORMAD1" },
    { id: "150280", name: "HORMAD2" },
    { id: "100124700", name: "HOTAIR" },
    { id: "3198", name: "HOXA1" },
    { id: "3206", name: "HOXA10" },
    { id: "3207", name: "HOXA11" },
    { id: "3200", name: "HOXA3" },
    { id: "3202", name: "HOXA5" },
    { id: "3205", name: "HOXA9" },
    { id: "3211", name: "HOXB1" },
    { id: "10481", name: "HOXB13" },
    { id: "3212", name: "HOXB2" },
    { id: "3213", name: "HOXB3" },
    { id: "3214", name: "HOXB4" },
    { id: "3215", name: "HOXB5" },
    { id: "3216", name: "HOXB6" },
    { id: "3217", name: "HOXB7" },
    { id: "3219", name: "HOXB9" },
    { id: "3226", name: "HOXC10" },
    { id: "3227", name: "HOXC11" },
    { id: "3229", name: "HOXC13" },
    { id: "3221", name: "HOXC4" },
    { id: "3222", name: "HOXC5" },
    { id: "3223", name: "HOXC6" },
    { id: "3224", name: "HOXC8" },
    { id: "3225", name: "HOXC9" },
    { id: "3231", name: "HOXD1" },
    { id: "3236", name: "HOXD10" },
    { id: "3237", name: "HOXD11" },
    { id: "3238", name: "HOXD12" },
    { id: "3239", name: "HOXD13" },
    { id: "3232", name: "HOXD3" },
    { id: "3233", name: "HOXD4" },
    { id: "3234", name: "HOXD8" },
    { id: "3235", name: "HOXD9" },
    { id: "3240", name: "HP" },
    { id: "50809", name: "HP1BP3" },
    { id: "3241", name: "HPCAL1" },
    { id: "51440", name: "HPCAL4" },
    { id: "3242", name: "HPD" },
    { id: "84842", name: "HPDL" },
    { id: "3248", name: "HPGD" },
    { id: "27306", name: "HPGDS" },
    { id: "3249", name: "HPN" },
    { id: "3250", name: "HPR" },
    { id: "3251", name: "HPRT1" },
    { id: "89781", name: "HPS4" },
    { id: "11234", name: "HPS5" },
    { id: "79803", name: "HPS6" },
    { id: "10855", name: "HPSE" },
    { id: "3263", name: "HPX" },
    { id: "55806", name: "HR" },
    { id: "3265", name: "HRAS" },
    { id: "57110", name: "HRASLS" },
    { id: "54979", name: "HRASLS2" },
    { id: "117245", name: "HRASLS5" },
    { id: "3273", name: "HRG" },
    { id: "3269", name: "HRH1" },
    { id: "11255", name: "HRH3" },
    { id: "59340", name: "HRH4" },
    { id: "8739", name: "HRK" },
    { id: "388697", name: "HRNR" },
    { id: "10247", name: "HRSP12" },
    { id: "64342", name: "HS1BP3" },
    { id: "9957", name: "HS3ST1" },
    { id: "9394", name: "HS6ST1" },
    { id: "90161", name: "HS6ST2" },
    { id: "3281", name: "HSBP1" },
    { id: "440498", name: "HSBP1L1" },
    { id: "150274", name: "HSCB" },
    { id: "3290", name: "HSD11B1" },
    { id: "374875", name: "HSD11B1L" },
    { id: "3291", name: "HSD11B2" },
    { id: "3292", name: "HSD17B1" },
    { id: "3028", name: "HSD17B10" },
    { id: "51170", name: "HSD17B11" },
    { id: "51144", name: "HSD17B12" },
    { id: "51171", name: "HSD17B14" },
    { id: "3293", name: "HSD17B3" },
    { id: "3295", name: "HSD17B4" },
    { id: "51478", name: "HSD17B7" },
    { id: "7923", name: "HSD17B8" },
    { id: "3284", name: "HSD3B2" },
    { id: "80270", name: "HSD3B7" },
    { id: "83693", name: "HSDL1" },
    { id: "84263", name: "HSDL2" },
    { id: "3297", name: "HSF1" },
    { id: "3298", name: "HSF2" },
    { id: "11077", name: "HSF2BP" },
    { id: "3299", name: "HSF4" },
    { id: "124535", name: "HSF5" },
    { id: "100506164", name: "HSFX1" },
    { id: "86614", name: "HSFY1" },
    { id: "159119", name: "HSFY2" },
    { id: "84941", name: "HSH2D" },
    { id: "3320", name: "HSP90AA1" },
    { id: "3324", name: "HSP90AA2" },
    { id: "3326", name: "HSP90AB1" },
    { id: "391634", name: "HSP90AB2P" },
    { id: "7184", name: "HSP90B1" },
    { id: "259217", name: "HSPA12A" },
    { id: "116835", name: "HSPA12B" },
    { id: "6782", name: "HSPA13" },
    { id: "51182", name: "HSPA14" },
    { id: "3303", name: "HSPA1A" },
    { id: "3304", name: "HSPA1B" },
    { id: "3305", name: "HSPA1L" },
    { id: "3306", name: "HSPA2" },
    { id: "3308", name: "HSPA4" },
    { id: "22824", name: "HSPA4L" },
    { id: "3309", name: "HSPA5" },
    { id: "3310", name: "HSPA6" },
    { id: "3312", name: "HSPA8" },
    { id: "3313", name: "HSPA9" },
    { id: "3315", name: "HSPB1" },
    { id: "51668", name: "HSPB11" },
    { id: "3316", name: "HSPB2" },
    { id: "8988", name: "HSPB3" },
    { id: "126393", name: "HSPB6" },
    { id: "27129", name: "HSPB7" },
    { id: "26353", name: "HSPB8" },
    { id: "94086", name: "HSPB9" },
    { id: "79663", name: "HSPBAP1" },
    { id: "23640", name: "HSPBP1" },
    { id: "3329", name: "HSPD1" },
    { id: "3336", name: "HSPE1" },
    { id: "3339", name: "HSPG2" },
    { id: "10808", name: "HSPH1" },
    { id: "10553", name: "HTATIP2" },
    { id: "27336", name: "HTATSF1" },
    { id: "3346", name: "HTN1" },
    { id: "3350", name: "HTR1A" },
    { id: "3351", name: "HTR1B" },
    { id: "3352", name: "HTR1D" },
    { id: "3355", name: "HTR1F" },
    { id: "3356", name: "HTR2A" },
    { id: "3357", name: "HTR2B" },
    { id: "3358", name: "HTR2C" },
    { id: "3359", name: "HTR3A" },
    { id: "9177", name: "HTR3B" },
    { id: "170572", name: "HTR3C" },
    { id: "200909", name: "HTR3D" },
    { id: "3360", name: "HTR4" },
    { id: "3362", name: "HTR6" },
    { id: "3363", name: "HTR7" },
    { id: "5654", name: "HTRA1" },
    { id: "27429", name: "HTRA2" },
    { id: "203100", name: "HTRA4" },
    { id: "3064", name: "HTT" },
    { id: "30811", name: "HUNK" },
    { id: "3364", name: "HUS1" },
    { id: "135458", name: "HUS1B" },
    { id: "10075", name: "HUWE1" },
    { id: "84329", name: "HVCN1" },
    { id: "3373", name: "HYAL1" },
    { id: "8692", name: "HYAL2" },
    { id: "8372", name: "HYAL3" },
    { id: "54768", name: "HYDIN" },
    { id: "81888", name: "HYI" },
    { id: "123688", name: "HYKK" },
    { id: "219844", name: "HYLS1" },
    { id: "10525", name: "HYOU1" },
    { id: "25764", name: "HYPK" },
    { id: "285148", name: "IAH1" },
    { id: "3375", name: "IAPP" },
    { id: "3376", name: "IARS" },
    { id: "55699", name: "IARS2" },
    { id: "200205", name: "IBA57" },
    { id: "25998", name: "IBTK" },
    { id: "3382", name: "ICA1" },
    { id: "130026", name: "ICA1L" },
    { id: "3383", name: "ICAM1" },
    { id: "3385", name: "ICAM3" },
    { id: "7087", name: "ICAM5" },
    { id: "22858", name: "ICK" },
    { id: "23463", name: "ICMT" },
    { id: "29851", name: "ICOS" },
    { id: "3396", name: "ICT1" },
    { id: "3397", name: "ID1" },
    { id: "3398", name: "ID2" },
    { id: "3399", name: "ID3" },
    { id: "3400", name: "ID4" },
    { id: "3416", name: "IDE" },
    { id: "3417", name: "IDH1" },
    { id: "3418", name: "IDH2" },
    { id: "3419", name: "IDH3A" },
    { id: "3420", name: "IDH3B" },
    { id: "3421", name: "IDH3G" },
    { id: "3422", name: "IDI1" },
    { id: "91734", name: "IDI2" },
    { id: "3620", name: "IDO1" },
    { id: "169355", name: "IDO2" },
    { id: "3423", name: "IDS" },
    { id: "9592", name: "IER2" },
    { id: "8870", name: "IER3" },
    { id: "51124", name: "IER3IP1" },
    { id: "51278", name: "IER5" },
    { id: "389792", name: "IER5L" },
    { id: "25900", name: "IFFO1" },
    { id: "126917", name: "IFFO2" },
    { id: "3428", name: "IFI16" },
    { id: "3429", name: "IFI27" },
    { id: "83982", name: "IFI27L2" },
    { id: "10437", name: "IFI30" },
    { id: "10561", name: "IFI44" },
    { id: "64135", name: "IFIH1" },
    { id: "3434", name: "IFIT1" },
    { id: "439996", name: "IFIT1B" },
    { id: "3433", name: "IFIT2" },
    { id: "3437", name: "IFIT3" },
    { id: "24138", name: "IFIT5" },
    { id: "8519", name: "IFITM1" },
    { id: "10581", name: "IFITM2" },
    { id: "10410", name: "IFITM3" },
    { id: "387733", name: "IFITM5" },
    { id: "160492", name: "IFLTD1" },
    { id: "3439", name: "IFNA1" },
    { id: "3449", name: "IFNA16" },
    { id: "3441", name: "IFNA4" },
    { id: "3442", name: "IFNA5" },
    { id: "3445", name: "IFNA8" },
    { id: "3454", name: "IFNAR1" },
    { id: "3455", name: "IFNAR2" },
    { id: "3456", name: "IFNB1" },
    { id: "338376", name: "IFNE" },
    { id: "3458", name: "IFNG" },
    { id: "3459", name: "IFNGR1" },
    { id: "3460", name: "IFNGR2" },
    { id: "56832", name: "IFNK" },
    { id: "282618", name: "IFNL1" },
    { id: "282616", name: "IFNL2" },
    { id: "282617", name: "IFNL3" },
    { id: "163702", name: "IFNLR1" },
    { id: "3467", name: "IFNW1" },
    { id: "3475", name: "IFRD1" },
    { id: "7866", name: "IFRD2" },
    { id: "55764", name: "IFT122" },
    { id: "9742", name: "IFT140" },
    { id: "26160", name: "IFT172" },
    { id: "90410", name: "IFT20" },
    { id: "11020", name: "IFT27" },
    { id: "112752", name: "IFT43" },
    { id: "56912", name: "IFT46" },
    { id: "51098", name: "IFT52" },
    { id: "55081", name: "IFT57" },
    { id: "80173", name: "IFT74" },
    { id: "57560", name: "IFT80" },
    { id: "28981", name: "IFT81" },
    { id: "8100", name: "IFT88" },
    { id: "3476", name: "IGBP1" },
    { id: "9543", name: "IGDCC3" },
    { id: "57722", name: "IGDCC4" },
    { id: "3479", name: "IGF1" },
    { id: "3480", name: "IGF1R" },
    { id: "3481", name: "IGF2" },
    { id: "51214", name: "IGF2-AS" },
    { id: "10642", name: "IGF2BP1" },
    { id: "10644", name: "IGF2BP2" },
    { id: "10643", name: "IGF2BP3" },
    { id: "3482", name: "IGF2R" },
    { id: "3483", name: "IGFALS" },
    { id: "3484", name: "IGFBP1" },
    { id: "3485", name: "IGFBP2" },
    { id: "3486", name: "IGFBP3" },
    { id: "3487", name: "IGFBP4" },
    { id: "3488", name: "IGFBP5" },
    { id: "3489", name: "IGFBP6" },
    { id: "3490", name: "IGFBP7" },
    { id: "347252", name: "IGFBPL1" },
    { id: "374918", name: "IGFL1" },
    { id: "147920", name: "IGFL2" },
    { id: "388555", name: "IGFL3" },
    { id: "79713", name: "IGFLR1" },
    { id: "91156", name: "IGFN1" },
    { id: "3493", name: "IGHA1" },
    { id: "3495", name: "IGHD" },
    { id: "3500", name: "IGHG1" },
    { id: "3507", name: "IGHM" },
    { id: "3508", name: "IGHMBP2" },
    { id: "492311", name: "IGIP" },
    { id: "3512", name: "IGJ" },
    { id: "3514", name: "IGKC" },
    { id: "28299", name: "IGKV1-5" },
    { id: "28882", name: "IGKV2D-29" },
    { id: "3537", name: "IGLC1" },
    { id: "28834", name: "IGLC7" },
    { id: "3543", name: "IGLL1" },
    { id: "100423062", name: "IGLL5" },
    { id: "3547", name: "IGSF1" },
    { id: "285313", name: "IGSF10" },
    { id: "152404", name: "IGSF11" },
    { id: "84966", name: "IGSF21" },
    { id: "150084", name: "IGSF5" },
    { id: "10261", name: "IGSF6" },
    { id: "93185", name: "IGSF8" },
    { id: "3550", name: "IK" },
    { id: "121457", name: "IKBIP" },
    { id: "8518", name: "IKBKAP" },
    { id: "3551", name: "IKBKB" },
    { id: "9641", name: "IKBKE" },
    { id: "8517", name: "IKBKG" },
    { id: "10320", name: "IKZF1" },
    { id: "22807", name: "IKZF2" },
    { id: "22806", name: "IKZF3" },
    { id: "64375", name: "IKZF4" },
    { id: "64376", name: "IKZF5" },
    { id: "3586", name: "IL10" },
    { id: "3587", name: "IL10RA" },
    { id: "3588", name: "IL10RB" },
    { id: "3590", name: "IL11RA" },
    { id: "3592", name: "IL12A" },
    { id: "3593", name: "IL12B" },
    { id: "3594", name: "IL12RB1" },
    { id: "3595", name: "IL12RB2" },
    { id: "3596", name: "IL13" },
    { id: "3597", name: "IL13RA1" },
    { id: "3598", name: "IL13RA2" },
    { id: "3600", name: "IL15" },
    { id: "3601", name: "IL15RA" },
    { id: "3603", name: "IL16" },
    { id: "3605", name: "IL17A" },
    { id: "27190", name: "IL17B" },
    { id: "112744", name: "IL17F" },
    { id: "23765", name: "IL17RA" },
    { id: "55540", name: "IL17RB" },
    { id: "84818", name: "IL17RC" },
    { id: "54756", name: "IL17RD" },
    { id: "132014", name: "IL17RE" },
    { id: "3606", name: "IL18" },
    { id: "10068", name: "IL18BP" },
    { id: "8809", name: "IL18R1" },
    { id: "8807", name: "IL18RAP" },
    { id: "3552", name: "IL1A" },
    { id: "3553", name: "IL1B" },
    { id: "84639", name: "IL1F10" },
    { id: "3554", name: "IL1R1" },
    { id: "7850", name: "IL1R2" },
    { id: "3556", name: "IL1RAP" },
    { id: "11141", name: "IL1RAPL1" },
    { id: "9173", name: "IL1RL1" },
    { id: "8808", name: "IL1RL2" },
    { id: "3557", name: "IL1RN" },
    { id: "3558", name: "IL2" },
    { id: "53832", name: "IL20RA" },
    { id: "53833", name: "IL20RB" },
    { id: "59067", name: "IL21" },
    { id: "50615", name: "IL21R" },
    { id: "50616", name: "IL22" },
    { id: "58985", name: "IL22RA1" },
    { id: "116379", name: "IL22RA2" },
    { id: "51561", name: "IL23A" },
    { id: "149233", name: "IL23R" },
    { id: "11009", name: "IL24" },
    { id: "64806", name: "IL25" },
    { id: "55801", name: "IL26" },
    { id: "246778", name: "IL27" },
    { id: "9466", name: "IL27RA" },
    { id: "3559", name: "IL2RA" },
    { id: "3560", name: "IL2RB" },
    { id: "3561", name: "IL2RG" },
    { id: "3562", name: "IL3" },
    { id: "386653", name: "IL31" },
    { id: "133396", name: "IL31RA" },
    { id: "9235", name: "IL32" },
    { id: "90865", name: "IL33" },
    { id: "146433", name: "IL34" },
    { id: "27177", name: "IL36B" },
    { id: "56300", name: "IL36G" },
    { id: "26525", name: "IL36RN" },
    { id: "27178", name: "IL37" },
    { id: "3563", name: "IL3RA" },
    { id: "3565", name: "IL4" },
    { id: "259307", name: "IL4I1" },
    { id: "3566", name: "IL4R" },
    { id: "3567", name: "IL5" },
    { id: "3568", name: "IL5RA" },
    { id: "3569", name: "IL6" },
    { id: "3570", name: "IL6R" },
    { id: "3572", name: "IL6ST" },
    { id: "3574", name: "IL7" },
    { id: "3575", name: "IL7R" },
    { id: "3576", name: "IL8" },
    { id: "3581", name: "IL9R" },
    { id: "387597", name: "ILDR2" },
    { id: "3608", name: "ILF2" },
    { id: "3609", name: "ILF3" },
    { id: "3611", name: "ILK" },
    { id: "80895", name: "ILKAP" },
    { id: "10994", name: "ILVBL" },
    { id: "83943", name: "IMMP2L" },
    { id: "10989", name: "IMMT" },
    { id: "55272", name: "IMP3" },
    { id: "92856", name: "IMP4" },
    { id: "3612", name: "IMPA1" },
    { id: "3613", name: "IMPA2" },
    { id: "55364", name: "IMPACT" },
    { id: "54928", name: "IMPAD1" },
    { id: "3614", name: "IMPDH1" },
    { id: "3615", name: "IMPDH2" },
    { id: "9118", name: "INA" },
    { id: "10207", name: "INADL" },
    { id: "388324", name: "INCA1" },
    { id: "3619", name: "INCENP" },
    { id: "64423", name: "INF2" },
    { id: "3621", name: "ING1" },
    { id: "3622", name: "ING2" },
    { id: "54556", name: "ING3" },
    { id: "51147", name: "ING4" },
    { id: "84289", name: "ING5" },
    { id: "3623", name: "INHA" },
    { id: "3624", name: "INHBA" },
    { id: "3625", name: "INHBB" },
    { id: "3626", name: "INHBC" },
    { id: "83729", name: "INHBE" },
    { id: "58493", name: "INIP" },
    { id: "54617", name: "INO80" },
    { id: "83444", name: "INO80B" },
    { id: "125476", name: "INO80C" },
    { id: "54891", name: "INO80D" },
    { id: "283899", name: "INO80E" },
    { id: "3628", name: "INPP1" },
    { id: "3631", name: "INPP4A" },
    { id: "8821", name: "INPP4B" },
    { id: "3633", name: "INPP5B" },
    { id: "3635", name: "INPP5D" },
    { id: "56623", name: "INPP5E" },
    { id: "22876", name: "INPP5F" },
    { id: "27124", name: "INPP5J" },
    { id: "51763", name: "INPP5K" },
    { id: "3636", name: "INPPL1" },
    { id: "3630", name: "INS" },
    { id: "387755", name: "INSC" },
    { id: "3638", name: "INSIG1" },
    { id: "51141", name: "INSIG2" },
    { id: "723961", name: "INS-IGF2" },
    { id: "3642", name: "INSM1" },
    { id: "3643", name: "INSR" },
    { id: "3645", name: "INSRR" },
    { id: "26173", name: "INTS1" },
    { id: "55174", name: "INTS10" },
    { id: "57117", name: "INTS12" },
    { id: "57508", name: "INTS2" },
    { id: "65123", name: "INTS3" },
    { id: "92105", name: "INTS4" },
    { id: "80789", name: "INTS5" },
    { id: "26512", name: "INTS6" },
    { id: "25896", name: "INTS7" },
    { id: "55656", name: "INTS8" },
    { id: "55756", name: "INTS9" },
    { id: "27130", name: "INVS" },
    { id: "9807", name: "IP6K1" },
    { id: "51447", name: "IP6K2" },
    { id: "117283", name: "IP6K3" },
    { id: "26034", name: "IPCEF1" },
    { id: "253430", name: "IPMK" },
    { id: "51194", name: "IPO11" },
    { id: "9670", name: "IPO13" },
    { id: "79711", name: "IPO4" },
    { id: "3843", name: "IPO5" },
    { id: "10527", name: "IPO7" },
    { id: "10526", name: "IPO8" },
    { id: "55705", name: "IPO9" },
    { id: "64768", name: "IPPK" },
    { id: "79781", name: "IQCA1" },
    { id: "9657", name: "IQCB1" },
    { id: "55721", name: "IQCC" },
    { id: "23288", name: "IQCE" },
    { id: "389123", name: "IQCF2" },
    { id: "401067", name: "IQCF3" },
    { id: "84223", name: "IQCG" },
    { id: "64799", name: "IQCH" },
    { id: "654502", name: "IQCJ" },
    { id: "124152", name: "IQCK" },
    { id: "8826", name: "IQGAP1" },
    { id: "10788", name: "IQGAP2" },
    { id: "128239", name: "IQGAP3" },
    { id: "9922", name: "IQSEC1" },
    { id: "154865", name: "IQUB" },
    { id: "3654", name: "IRAK1" },
    { id: "134728", name: "IRAK1BP1" },
    { id: "3656", name: "IRAK2" },
    { id: "11213", name: "IRAK3" },
    { id: "51135", name: "IRAK4" },
    { id: "3658", name: "IREB2" },
    { id: "3659", name: "IRF1" },
    { id: "3660", name: "IRF2" },
    { id: "26145", name: "IRF2BP1" },
    { id: "359948", name: "IRF2BP2" },
    { id: "64207", name: "IRF2BPL" },
    { id: "3661", name: "IRF3" },
    { id: "3662", name: "IRF4" },
    { id: "3663", name: "IRF5" },
    { id: "3664", name: "IRF6" },
    { id: "3665", name: "IRF7" },
    { id: "3394", name: "IRF8" },
    { id: "10379", name: "IRF9" },
    { id: "56269", name: "IRGC" },
    { id: "126298", name: "IRGQ" },
    { id: "3667", name: "IRS1" },
    { id: "8660", name: "IRS2" },
    { id: "8471", name: "IRS4" },
    { id: "79192", name: "IRX1" },
    { id: "153572", name: "IRX2" },
    { id: "50805", name: "IRX4" },
    { id: "79190", name: "IRX6" },
    { id: "81689", name: "ISCA1" },
    { id: "122961", name: "ISCA2" },
    { id: "23479", name: "ISCU" },
    { id: "9636", name: "ISG15" },
    { id: "81875", name: "ISG20L2" },
    { id: "3670", name: "ISL1" },
    { id: "64843", name: "ISL2" },
    { id: "51015", name: "ISOC1" },
    { id: "79763", name: "ISOC2" },
    { id: "9798", name: "IST1" },
    { id: "91464", name: "ISX" },
    { id: "57461", name: "ISY1" },
    { id: "51477", name: "ISYNA1" },
    { id: "83737", name: "ITCH" },
    { id: "81533", name: "ITFG1" },
    { id: "55846", name: "ITFG2" },
    { id: "83986", name: "ITFG3" },
    { id: "3672", name: "ITGA1" },
    { id: "8515", name: "ITGA10" },
    { id: "22801", name: "ITGA11" },
    { id: "3673", name: "ITGA2" },
    { id: "3674", name: "ITGA2B" },
    { id: "3675", name: "ITGA3" },
    { id: "3676", name: "ITGA4" },
    { id: "3678", name: "ITGA5" },
    { id: "3655", name: "ITGA6" },
    { id: "3679", name: "ITGA7" },
    { id: "8516", name: "ITGA8" },
    { id: "3680", name: "ITGA9" },
    { id: "3681", name: "ITGAD" },
    { id: "3682", name: "ITGAE" },
    { id: "3683", name: "ITGAL" },
    { id: "3684", name: "ITGAM" },
    { id: "3685", name: "ITGAV" },
    { id: "3687", name: "ITGAX" },
    { id: "3688", name: "ITGB1" },
    { id: "9270", name: "ITGB1BP1" },
    { id: "26548", name: "ITGB1BP2" },
    { id: "3689", name: "ITGB2" },
    { id: "3690", name: "ITGB3" },
    { id: "23421", name: "ITGB3BP" },
    { id: "3691", name: "ITGB4" },
    { id: "3693", name: "ITGB5" },
    { id: "3694", name: "ITGB6" },
    { id: "3695", name: "ITGB7" },
    { id: "3696", name: "ITGB8" },
    { id: "3697", name: "ITIH1" },
    { id: "3698", name: "ITIH2" },
    { id: "3699", name: "ITIH3" },
    { id: "3700", name: "ITIH4" },
    { id: "80760", name: "ITIH5" },
    { id: "347365", name: "ITIH6" },
    { id: "3702", name: "ITK" },
    { id: "55600", name: "ITLN1" },
    { id: "9452", name: "ITM2A" },
    { id: "9445", name: "ITM2B" },
    { id: "81618", name: "ITM2C" },
    { id: "3704", name: "ITPA" },
    { id: "3705", name: "ITPK1" },
    { id: "3706", name: "ITPKA" },
    { id: "3707", name: "ITPKB" },
    { id: "80271", name: "ITPKC" },
    { id: "3708", name: "ITPR1" },
    { id: "3709", name: "ITPR2" },
    { id: "3710", name: "ITPR3" },
    { id: "85450", name: "ITPRIP" },
    { id: "150771", name: "ITPRIPL1" },
    { id: "162073", name: "ITPRIPL2" },
    { id: "6453", name: "ITSN1" },
    { id: "50618", name: "ITSN2" },
    { id: "3712", name: "IVD" },
    { id: "3713", name: "IVL" },
    { id: "10625", name: "IVNS1ABP" },
    { id: "55677", name: "IWS1" },
    { id: "389434", name: "IYD" },
    { id: "113177", name: "IZUMO4" },
    { id: "3714", name: "JAG2" },
    { id: "84522", name: "JAGN1" },
    { id: "3716", name: "JAK1" },
    { id: "3717", name: "JAK2" },
    { id: "3718", name: "JAK3" },
    { id: "152789", name: "JAKMIP1" },
    { id: "9832", name: "JAKMIP2" },
    { id: "58494", name: "JAM2" },
    { id: "83700", name: "JAM3" },
    { id: "3720", name: "JARID2" },
    { id: "221895", name: "JAZF1" },
    { id: "122953", name: "JDP2" },
    { id: "80853", name: "JHDM1D" },
    { id: "51528", name: "JKAMP" },
    { id: "221037", name: "JMJD1C" },
    { id: "65094", name: "JMJD4" },
    { id: "23210", name: "JMJD6" },
    { id: "100137047", name: "JMJD7" },
    { id: "8681", name: "JMJD7-PLA2G4B" },
    { id: "133746", name: "JMY" },
    { id: "9929", name: "JOSD1" },
    { id: "126119", name: "JOSD2" },
    { id: "56704", name: "JPH1" },
    { id: "57158", name: "JPH2" },
    { id: "57338", name: "JPH3" },
    { id: "84502", name: "JPH4" },
    { id: "8629", name: "JRK" },
    { id: "126306", name: "JSRP1" },
    { id: "10899", name: "JTB" },
    { id: "3725", name: "JUN" },
    { id: "3726", name: "JUNB" },
    { id: "3727", name: "JUND" },
    { id: "3728", name: "JUP" },
    { id: "353219", name: "KAAG1" },
    { id: "3730", name: "KAL1" },
    { id: "8997", name: "KALRN" },
    { id: "23189", name: "KANK1" },
    { id: "25959", name: "KANK2" },
    { id: "256949", name: "KANK3" },
    { id: "163782", name: "KANK4" },
    { id: "284058", name: "KANSL1" },
    { id: "151050", name: "KANSL1L" },
    { id: "54934", name: "KANSL2" },
    { id: "55683", name: "KANSL3" },
    { id: "3735", name: "KARS" },
    { id: "2648", name: "KAT2A" },
    { id: "8850", name: "KAT2B" },
    { id: "10524", name: "KAT5" },
    { id: "7994", name: "KAT6A" },
    { id: "23522", name: "KAT6B" },
    { id: "11143", name: "KAT7" },
    { id: "84148", name: "KAT8" },
    { id: "11104", name: "KATNA1" },
    { id: "84056", name: "KATNAL1" },
    { id: "83473", name: "KATNAL2" },
    { id: "10300", name: "KATNB1" },
    { id: "79768", name: "KATNBL1" },
    { id: "23254", name: "KAZN" },
    { id: "9920", name: "KBTBD11" },
    { id: "390594", name: "KBTBD13" },
    { id: "25948", name: "KBTBD2" },
    { id: "143879", name: "KBTBD3" },
    { id: "55709", name: "KBTBD4" },
    { id: "89890", name: "KBTBD6" },
    { id: "84078", name: "KBTBD7" },
    { id: "84541", name: "KBTBD8" },
    { id: "56888", name: "KCMF1" },
    { id: "3736", name: "KCNA1" },
    { id: "3737", name: "KCNA2" },
    { id: "3738", name: "KCNA3" },
    { id: "3739", name: "KCNA4" },
    { id: "3741", name: "KCNA5" },
    { id: "3742", name: "KCNA6" },
    { id: "7881", name: "KCNAB1" },
    { id: "8514", name: "KCNAB2" },
    { id: "9196", name: "KCNAB3" },
    { id: "3745", name: "KCNB1" },
    { id: "9312", name: "KCNB2" },
    { id: "3746", name: "KCNC1" },
    { id: "3747", name: "KCNC2" },
    { id: "3750", name: "KCND1" },
    { id: "3751", name: "KCND2" },
    { id: "3752", name: "KCND3" },
    { id: "3753", name: "KCNE1" },
    { id: "23630", name: "KCNE1L" },
    { id: "9992", name: "KCNE2" },
    { id: "10008", name: "KCNE3" },
    { id: "23704", name: "KCNE4" },
    { id: "3754", name: "KCNF1" },
    { id: "3755", name: "KCNG1" },
    { id: "26251", name: "KCNG2" },
    { id: "170850", name: "KCNG3" },
    { id: "93107", name: "KCNG4" },
    { id: "3756", name: "KCNH1" },
    { id: "3757", name: "KCNH2" },
    { id: "23416", name: "KCNH3" },
    { id: "27133", name: "KCNH5" },
    { id: "90134", name: "KCNH7" },
    { id: "30820", name: "KCNIP1" },
    { id: "30819", name: "KCNIP2" },
    { id: "30818", name: "KCNIP3" },
    { id: "80333", name: "KCNIP4" },
    { id: "3758", name: "KCNJ1" },
    { id: "3766", name: "KCNJ10" },
    { id: "3767", name: "KCNJ11" },
    { id: "3768", name: "KCNJ12" },
    { id: "3772", name: "KCNJ15" },
    { id: "3773", name: "KCNJ16" },
    { id: "100134444", name: "KCNJ18" },
    { id: "3759", name: "KCNJ2" },
    { id: "3760", name: "KCNJ3" },
    { id: "3761", name: "KCNJ4" },
    { id: "3762", name: "KCNJ5" },
    { id: "3763", name: "KCNJ6" },
    { id: "3764", name: "KCNJ8" },
    { id: "3765", name: "KCNJ9" },
    { id: "3775", name: "KCNK1" },
    { id: "54207", name: "KCNK10" },
    { id: "56660", name: "KCNK12" },
    { id: "60598", name: "KCNK15" },
    { id: "83795", name: "KCNK16" },
    { id: "89822", name: "KCNK17" },
    { id: "338567", name: "KCNK18" },
    { id: "3777", name: "KCNK3" },
    { id: "50801", name: "KCNK4" },
    { id: "8645", name: "KCNK5" },
    { id: "51305", name: "KCNK9" },
    { id: "3778", name: "KCNMA1" },
    { id: "3779", name: "KCNMB1" },
    { id: "10242", name: "KCNMB2" },
    { id: "3780", name: "KCNN1" },
    { id: "3781", name: "KCNN2" },
    { id: "3782", name: "KCNN3" },
    { id: "3783", name: "KCNN4" },
    { id: "3784", name: "KCNQ1" },
    { id: "3785", name: "KCNQ2" },
    { id: "3786", name: "KCNQ3" },
    { id: "9132", name: "KCNQ4" },
    { id: "56479", name: "KCNQ5" },
    { id: "283518", name: "KCNRG" },
    { id: "3790", name: "KCNS3" },
    { id: "27012", name: "KCNV1" },
    { id: "169522", name: "KCNV2" },
    { id: "284252", name: "KCTD1" },
    { id: "83892", name: "KCTD10" },
    { id: "147040", name: "KCTD11" },
    { id: "115207", name: "KCTD12" },
    { id: "253980", name: "KCTD13" },
    { id: "65987", name: "KCTD14" },
    { id: "79047", name: "KCTD15" },
    { id: "57528", name: "KCTD16" },
    { id: "79734", name: "KCTD17" },
    { id: "130535", name: "KCTD18" },
    { id: "146212", name: "KCTD19" },
    { id: "23510", name: "KCTD2" },
    { id: "222658", name: "KCTD20" },
    { id: "283219", name: "KCTD21" },
    { id: "51133", name: "KCTD3" },
    { id: "386618", name: "KCTD4" },
    { id: "54442", name: "KCTD5" },
    { id: "200845", name: "KCTD6" },
    { id: "154881", name: "KCTD7" },
    { id: "386617", name: "KCTD8" },
    { id: "54793", name: "KCTD9" },
    { id: "79070", name: "KDELC1" },
    { id: "143888", name: "KDELC2" },
    { id: "10945", name: "KDELR1" },
    { id: "11014", name: "KDELR2" },
    { id: "11015", name: "KDELR3" },
    { id: "23028", name: "KDM1A" },
    { id: "221656", name: "KDM1B" },
    { id: "22992", name: "KDM2A" },
    { id: "84678", name: "KDM2B" },
    { id: "55818", name: "KDM3A" },
    { id: "51780", name: "KDM3B" },
    { id: "9682", name: "KDM4A" },
    { id: "23030", name: "KDM4B" },
    { id: "23081", name: "KDM4C" },
    { id: "55693", name: "KDM4D" },
    { id: "5927", name: "KDM5A" },
    { id: "10765", name: "KDM5B" },
    { id: "8242", name: "KDM5C" },
    { id: "8284", name: "KDM5D" },
    { id: "7403", name: "KDM6A" },
    { id: "23135", name: "KDM6B" },
    { id: "79831", name: "KDM8" },
    { id: "3791", name: "KDR" },
    { id: "9817", name: "KEAP1" },
    { id: "3792", name: "KEL" },
    { id: "80759", name: "KHDC1" },
    { id: "154288", name: "KHDC3L" },
    { id: "10657", name: "KHDRBS1" },
    { id: "202559", name: "KHDRBS2" },
    { id: "10656", name: "KHDRBS3" },
    { id: "3795", name: "KHK" },
    { id: "23351", name: "KHNYN" },
    { id: "8570", name: "KHSRP" },
    { id: "9933", name: "KIAA0020" },
    { id: "9674", name: "KIAA0040" },
    { id: "9808", name: "KIAA0087" },
    { id: "9703", name: "KIAA0100" },
    { id: "9768", name: "KIAA0101" },
    { id: "9812", name: "KIAA0141" },
    { id: "9772", name: "KIAA0195" },
    { id: "9897", name: "KIAA0196" },
    { id: "9711", name: "KIAA0226" },
    { id: "80183", name: "KIAA0226L" },
    { id: "9778", name: "KIAA0232" },
    { id: "9766", name: "KIAA0247" },
    { id: "9856", name: "KIAA0319" },
    { id: "79932", name: "KIAA0319L" },
    { id: "9710", name: "KIAA0355" },
    { id: "23392", name: "KIAA0368" },
    { id: "9692", name: "KIAA0391" },
    { id: "9729", name: "KIAA0408" },
    { id: "9665", name: "KIAA0430" },
    { id: "9764", name: "KIAA0513" },
    { id: "23247", name: "KIAA0556" },
    { id: "9786", name: "KIAA0586" },
    { id: "9851", name: "KIAA0753" },
    { id: "643314", name: "KIAA0754" },
    { id: "285600", name: "KIAA0825" },
    { id: "22889", name: "KIAA0907" },
    { id: "23240", name: "KIAA0922" },
    { id: "23313", name: "KIAA0930" },
    { id: "23379", name: "KIAA0947" },
    { id: "22832", name: "KIAA1009" },
    { id: "23251", name: "KIAA1024" },
    { id: "23325", name: "KIAA1033" },
    { id: "23285", name: "KIAA1107" },
    { id: "84162", name: "KIAA1109" },
    { id: "57456", name: "KIAA1143" },
    { id: "57189", name: "KIAA1147" },
    { id: "57462", name: "KIAA1161" },
    { id: "57179", name: "KIAA1191" },
    { id: "57214", name: "KIAA1199" },
    { id: "57482", name: "KIAA1211" },
    { id: "56243", name: "KIAA1217" },
    { id: "57221", name: "KIAA1244" },
    { id: "26128", name: "KIAA1279" },
    { id: "57535", name: "KIAA1324" },
    { id: "57536", name: "KIAA1328" },
    { id: "57562", name: "KIAA1377" },
    { id: "57577", name: "KIAA1407" },
    { id: "25962", name: "KIAA1429" },
    { id: "57587", name: "KIAA1430" },
    { id: "57589", name: "KIAA1432" },
    { id: "57608", name: "KIAA1462" },
    { id: "57613", name: "KIAA1467" },
    { id: "57614", name: "KIAA1468" },
    { id: "57648", name: "KIAA1522" },
    { id: "57650", name: "KIAA1524" },
    { id: "57670", name: "KIAA1549" },
    { id: "25758", name: "KIAA1549L" },
    { id: "55196", name: "KIAA1551" },
    { id: "57691", name: "KIAA1586" },
    { id: "57698", name: "KIAA1598" },
    { id: "85379", name: "KIAA1671" },
    { id: "80726", name: "KIAA1683" },
    { id: "55425", name: "KIAA1704" },
    { id: "80856", name: "KIAA1715" },
    { id: "85459", name: "KIAA1731" },
    { id: "85457", name: "KIAA1737" },
    { id: "84451", name: "KIAA1804" },
    { id: "84542", name: "KIAA1841" },
    { id: "340390", name: "KIAA1875" },
    { id: "158405", name: "KIAA1958" },
    { id: "57805", name: "KIAA1967" },
    { id: "84960", name: "KIAA1984" },
    { id: "90231", name: "KIAA2013" },
    { id: "205717", name: "KIAA2018" },
    { id: "340533", name: "KIAA2022" },
    { id: "158358", name: "KIAA2026" },
    { id: "57498", name: "KIDINS220" },
    { id: "3832", name: "KIF11" },
    { id: "113220", name: "KIF12" },
    { id: "63971", name: "KIF13A" },
    { id: "23303", name: "KIF13B" },
    { id: "9928", name: "KIF14" },
    { id: "56992", name: "KIF15" },
    { id: "55614", name: "KIF16B" },
    { id: "57576", name: "KIF17" },
    { id: "81930", name: "KIF18A" },
    { id: "146909", name: "KIF18B" },
    { id: "547", name: "KIF1A" },
    { id: "23095", name: "KIF1B" },
    { id: "10749", name: "KIF1C" },
    { id: "10112", name: "KIF20A" },
    { id: "9585", name: "KIF20B" },
    { id: "55605", name: "KIF21A" },
    { id: "23046", name: "KIF21B" },
    { id: "3835", name: "KIF22" },
    { id: "9493", name: "KIF23" },
    { id: "347240", name: "KIF24" },
    { id: "100505879", name: "KIF25-AS1" },
    { id: "26153", name: "KIF26A" },
    { id: "55083", name: "KIF26B" },
    { id: "55582", name: "KIF27" },
    { id: "3796", name: "KIF2A" },
    { id: "84643", name: "KIF2B" },
    { id: "11004", name: "KIF2C" },
    { id: "11127", name: "KIF3A" },
    { id: "9371", name: "KIF3B" },
    { id: "3797", name: "KIF3C" },
    { id: "24137", name: "KIF4A" },
    { id: "285643", name: "KIF4B" },
    { id: "3798", name: "KIF5A" },
    { id: "3799", name: "KIF5B" },
    { id: "3800", name: "KIF5C" },
    { id: "221458", name: "KIF6" },
    { id: "374654", name: "KIF7" },
    { id: "64147", name: "KIF9" },
    { id: "22920", name: "KIFAP3" },
    { id: "3833", name: "KIFC1" },
    { id: "90990", name: "KIFC2" },
    { id: "3801", name: "KIFC3" },
    { id: "22944", name: "KIN" },
    { id: "3802", name: "KIR2DL1" },
    { id: "3803", name: "KIR2DL2" },
    { id: "3804", name: "KIR2DL3" },
    { id: "3805", name: "KIR2DL4" },
    { id: "57292", name: "KIR2DL5A" },
    { id: "3806", name: "KIR2DS1" },
    { id: "100132285", name: "KIR2DS2" },
    { id: "3809", name: "KIR2DS4" },
    { id: "3811", name: "KIR3DL1" },
    { id: "3812", name: "KIR3DL2" },
    { id: "3813", name: "KIR3DS1" },
    { id: "55243", name: "KIRREL" },
    { id: "84063", name: "KIRREL2" },
    { id: "84623", name: "KIRREL3" },
    { id: "283165", name: "KIRREL3-AS3" },
    { id: "3814", name: "KISS1" },
    { id: "84634", name: "KISS1R" },
    { id: "3815", name: "KIT" },
    { id: "4254", name: "KITLG" },
    { id: "9365", name: "KL" },
    { id: "152831", name: "KLB" },
    { id: "3831", name: "KLC1" },
    { id: "64837", name: "KLC2" },
    { id: "147700", name: "KLC3" },
    { id: "89953", name: "KLC4" },
    { id: "10661", name: "KLF1" },
    { id: "7071", name: "KLF10" },
    { id: "8462", name: "KLF11" },
    { id: "11278", name: "KLF12" },
    { id: "51621", name: "KLF13" },
    { id: "136259", name: "KLF14" },
    { id: "28999", name: "KLF15" },
    { id: "83855", name: "KLF16" },
    { id: "128209", name: "KLF17" },
    { id: "10365", name: "KLF2" },
    { id: "51274", name: "KLF3" },
    { id: "9314", name: "KLF4" },
    { id: "688", name: "KLF5" },
    { id: "1316", name: "KLF6" },
    { id: "8609", name: "KLF7" },
    { id: "11279", name: "KLF8" },
    { id: "122773", name: "KLHDC1" },
    { id: "23008", name: "KLHDC10" },
    { id: "23588", name: "KLHDC2" },
    { id: "116138", name: "KLHDC3" },
    { id: "54758", name: "KLHDC4" },
    { id: "113730", name: "KLHDC7B" },
    { id: "55220", name: "KLHDC8A" },
    { id: "200942", name: "KLHDC8B" },
    { id: "126823", name: "KLHDC9" },
    { id: "57626", name: "KLHL1" },
    { id: "317719", name: "KLHL10" },
    { id: "55175", name: "KLHL11" },
    { id: "59349", name: "KLHL12" },
    { id: "90293", name: "KLHL13" },
    { id: "57565", name: "KLHL14" },
    { id: "80311", name: "KLHL15" },
    { id: "339451", name: "KLHL17" },
    { id: "23276", name: "KLHL18" },
    { id: "11275", name: "KLHL2" },
    { id: "27252", name: "KLHL20" },
    { id: "9903", name: "KLHL21" },
    { id: "84861", name: "KLHL22" },
    { id: "151230", name: "KLHL23" },
    { id: "54800", name: "KLHL24" },
    { id: "64410", name: "KLHL25" },
    { id: "55295", name: "KLHL26" },
    { id: "54813", name: "KLHL28" },
    { id: "114818", name: "KLHL29" },
    { id: "26249", name: "KLHL3" },
    { id: "401265", name: "KLHL31" },
    { id: "114792", name: "KLHL32" },
    { id: "123103", name: "KLHL33" },
    { id: "257240", name: "KLHL34" },
    { id: "283212", name: "KLHL35" },
    { id: "79786", name: "KLHL36" },
    { id: "340359", name: "KLHL38" },
    { id: "56062", name: "KLHL4" },
    { id: "131377", name: "KLHL40" },
    { id: "10324", name: "KLHL41" },
    { id: "57542", name: "KLHL42" },
    { id: "51088", name: "KLHL5" },
    { id: "89857", name: "KLHL6" },
    { id: "55975", name: "KLHL7" },
    { id: "57563", name: "KLHL8" },
    { id: "55958", name: "KLHL9" },
    { id: "43849", name: "KLK12" },
    { id: "26085", name: "KLK13" },
    { id: "55554", name: "KLK15" },
    { id: "3817", name: "KLK2" },
    { id: "354", name: "KLK3" },
    { id: "9622", name: "KLK4" },
    { id: "25818", name: "KLK5" },
    { id: "5653", name: "KLK6" },
    { id: "5650", name: "KLK7" },
    { id: "11202", name: "KLK8" },
    { id: "284366", name: "KLK9" },
    { id: "3818", name: "KLKB1" },
    { id: "3821", name: "KLRC1" },
    { id: "3822", name: "KLRC2" },
    { id: "8302", name: "KLRC4" },
    { id: "3824", name: "KLRD1" },
    { id: "10219", name: "KLRG1" },
    { id: "22914", name: "KLRK1" },
    { id: "4297", name: "KMT2A" },
    { id: "9757", name: "KMT2B" },
    { id: "58508", name: "KMT2C" },
    { id: "8085", name: "KMT2D" },
    { id: "55904", name: "KMT2E" },
    { id: "148930", name: "KNCN" },
    { id: "85442", name: "KNDC1" },
    { id: "3827", name: "KNG1" },
    { id: "400506", name: "KNOP1" },
    { id: "90417", name: "KNSTRN" },
    { id: "9735", name: "KNTC1" },
    { id: "3836", name: "KPNA1" },
    { id: "3838", name: "KPNA2" },
    { id: "3839", name: "KPNA3" },
    { id: "3840", name: "KPNA4" },
    { id: "3841", name: "KPNA5" },
    { id: "23633", name: "KPNA6" },
    { id: "402569", name: "KPNA7" },
    { id: "3837", name: "KPNB1" },
    { id: "448834", name: "KPRP" },
    { id: "11133", name: "KPTN" },
    { id: "3845", name: "KRAS" },
    { id: "84626", name: "KRBA1" },
    { id: "124751", name: "KRBA2" },
    { id: "55634", name: "KRBOX4" },
    { id: "51315", name: "KRCC1" },
    { id: "83999", name: "KREMEN1" },
    { id: "79412", name: "KREMEN2" },
    { id: "65095", name: "KRI1" },
    { id: "889", name: "KRIT1" },
    { id: "11103", name: "KRR1" },
    { id: "3848", name: "KRT1" },
    { id: "3858", name: "KRT10" },
    { id: "3859", name: "KRT12" },
    { id: "3860", name: "KRT13" },
    { id: "3861", name: "KRT14" },
    { id: "3866", name: "KRT15" },
    { id: "3868", name: "KRT16" },
    { id: "3872", name: "KRT17" },
    { id: "3875", name: "KRT18" },
    { id: "3880", name: "KRT19" },
    { id: "3849", name: "KRT2" },
    { id: "54474", name: "KRT20" },
    { id: "125113", name: "KRT222" },
    { id: "25984", name: "KRT23" },
    { id: "192666", name: "KRT24" },
    { id: "147183", name: "KRT25" },
    { id: "353288", name: "KRT26" },
    { id: "342574", name: "KRT27" },
    { id: "162605", name: "KRT28" },
    { id: "3850", name: "KRT3" },
    { id: "3881", name: "KRT31" },
    { id: "3882", name: "KRT32" },
    { id: "3883", name: "KRT33A" },
    { id: "3884", name: "KRT33B" },
    { id: "3885", name: "KRT34" },
    { id: "3886", name: "KRT35" },
    { id: "8689", name: "KRT36" },
    { id: "8688", name: "KRT37" },
    { id: "8687", name: "KRT38" },
    { id: "390792", name: "KRT39" },
    { id: "3851", name: "KRT4" },
    { id: "125115", name: "KRT40" },
    { id: "3852", name: "KRT5" },
    { id: "3853", name: "KRT6A" },
    { id: "3854", name: "KRT6B" },
    { id: "286887", name: "KRT6C" },
    { id: "3855", name: "KRT7" },
    { id: "112802", name: "KRT71" },
    { id: "140807", name: "KRT72" },
    { id: "319101", name: "KRT73" },
    { id: "121391", name: "KRT74" },
    { id: "9119", name: "KRT75" },
    { id: "51350", name: "KRT76" },
    { id: "374454", name: "KRT77" },
    { id: "196374", name: "KRT78" },
    { id: "338785", name: "KRT79" },
    { id: "3856", name: "KRT8" },
    { id: "144501", name: "KRT80" },
    { id: "3887", name: "KRT81" },
    { id: "3888", name: "KRT82" },
    { id: "3889", name: "KRT83" },
    { id: "3890", name: "KRT84" },
    { id: "3891", name: "KRT85" },
    { id: "3892", name: "KRT86" },
    { id: "3857", name: "KRT9" },
    { id: "386677", name: "KRTAP10-1" },
    { id: "353333", name: "KRTAP10-10" },
    { id: "386678", name: "KRTAP10-11" },
    { id: "386682", name: "KRTAP10-3" },
    { id: "386672", name: "KRTAP10-4" },
    { id: "386680", name: "KRTAP10-5" },
    { id: "386674", name: "KRTAP10-6" },
    { id: "386675", name: "KRTAP10-7" },
    { id: "386681", name: "KRTAP10-8" },
    { id: "386676", name: "KRTAP10-9" },
    { id: "81851", name: "KRTAP1-1" },
    { id: "337880", name: "KRTAP11-1" },
    { id: "353332", name: "KRTAP12-1" },
    { id: "353323", name: "KRTAP12-2" },
    { id: "386683", name: "KRTAP12-3" },
    { id: "386684", name: "KRTAP12-4" },
    { id: "81850", name: "KRTAP1-3" },
    { id: "140258", name: "KRTAP13-1" },
    { id: "337959", name: "KRTAP13-2" },
    { id: "337960", name: "KRTAP13-3" },
    { id: "284827", name: "KRTAP13-4" },
    { id: "83895", name: "KRTAP1-5" },
    { id: "254950", name: "KRTAP15-1" },
    { id: "83902", name: "KRTAP17-1" },
    { id: "337882", name: "KRTAP19-1" },
    { id: "337969", name: "KRTAP19-2" },
    { id: "337970", name: "KRTAP19-3" },
    { id: "337971", name: "KRTAP19-4" },
    { id: "337972", name: "KRTAP19-5" },
    { id: "337973", name: "KRTAP19-6" },
    { id: "337974", name: "KRTAP19-7" },
    { id: "337979", name: "KRTAP22-1" },
    { id: "730755", name: "KRTAP2-3" },
    { id: "337963", name: "KRTAP23-1" },
    { id: "85294", name: "KRTAP2-4" },
    { id: "388818", name: "KRTAP26-1" },
    { id: "83896", name: "KRTAP3-1" },
    { id: "83897", name: "KRTAP3-2" },
    { id: "85293", name: "KRTAP3-3" },
    { id: "85285", name: "KRTAP4-1" },
    { id: "653240", name: "KRTAP4-11" },
    { id: "83755", name: "KRTAP4-12" },
    { id: "85291", name: "KRTAP4-2" },
    { id: "84616", name: "KRTAP4-4" },
    { id: "85289", name: "KRTAP4-5" },
    { id: "100132476", name: "KRTAP4-7" },
    { id: "387264", name: "KRTAP5-1" },
    { id: "440051", name: "KRTAP5-11" },
    { id: "440021", name: "KRTAP5-2" },
    { id: "387266", name: "KRTAP5-3" },
    { id: "387267", name: "KRTAP5-4" },
    { id: "440023", name: "KRTAP5-6" },
    { id: "440050", name: "KRTAP5-7" },
    { id: "57830", name: "KRTAP5-8" },
    { id: "3846", name: "KRTAP5-9" },
    { id: "337966", name: "KRTAP6-1" },
    { id: "337967", name: "KRTAP6-2" },
    { id: "337968", name: "KRTAP6-3" },
    { id: "337879", name: "KRTAP8-1" },
    { id: "83899", name: "KRTAP9-2" },
    { id: "83900", name: "KRTAP9-3" },
    { id: "85280", name: "KRTAP9-4" },
    { id: "83901", name: "KRTAP9-8" },
    { id: "200185", name: "KRTCAP2" },
    { id: "200634", name: "KRTCAP3" },
    { id: "8844", name: "KSR1" },
    { id: "283455", name: "KSR2" },
    { id: "112970", name: "KTI12" },
    { id: "3895", name: "KTN1" },
    { id: "79036", name: "KXD1" },
    { id: "339855", name: "KY" },
    { id: "8942", name: "KYNU" },
    { id: "3897", name: "L1CAM" },
    { id: "54596", name: "L1TD1" },
    { id: "79944", name: "L2HGDH" },
    { id: "26013", name: "L3MBTL1" },
    { id: "83746", name: "L3MBTL2" },
    { id: "84456", name: "L3MBTL3" },
    { id: "91133", name: "L3MBTL4" },
    { id: "246269", name: "LACE1" },
    { id: "90070", name: "LACRT" },
    { id: "114294", name: "LACTB" },
    { id: "51110", name: "LACTB2" },
    { id: "3898", name: "LAD1" },
    { id: "8270", name: "LAGE3" },
    { id: "3903", name: "LAIR1" },
    { id: "3906", name: "LALBA" },
    { id: "284217", name: "LAMA1" },
    { id: "3908", name: "LAMA2" },
    { id: "3909", name: "LAMA3" },
    { id: "3910", name: "LAMA4" },
    { id: "3911", name: "LAMA5" },
    { id: "3912", name: "LAMB1" },
    { id: "3913", name: "LAMB2" },
    { id: "3914", name: "LAMB3" },
    { id: "3915", name: "LAMC1" },
    { id: "3918", name: "LAMC2" },
    { id: "10319", name: "LAMC3" },
    { id: "3916", name: "LAMP1" },
    { id: "3920", name: "LAMP2" },
    { id: "27074", name: "LAMP3" },
    { id: "55004", name: "LAMTOR1" },
    { id: "28956", name: "LAMTOR2" },
    { id: "8649", name: "LAMTOR3" },
    { id: "389541", name: "LAMTOR4" },
    { id: "10542", name: "LAMTOR5" },
    { id: "10314", name: "LANCL1" },
    { id: "55915", name: "LANCL2" },
    { id: "51056", name: "LAP3" },
    { id: "9741", name: "LAPTM4A" },
    { id: "55353", name: "LAPTM4B" },
    { id: "7805", name: "LAPTM5" },
    { id: "23367", name: "LARP1" },
    { id: "55132", name: "LARP1B" },
    { id: "113251", name: "LARP4" },
    { id: "23185", name: "LARP4B" },
    { id: "55323", name: "LARP6" },
    { id: "51574", name: "LARP7" },
    { id: "51520", name: "LARS" },
    { id: "81887", name: "LAS1L" },
    { id: "3927", name: "LASP1" },
    { id: "27040", name: "LAT" },
    { id: "7462", name: "LAT2" },
    { id: "9113", name: "LATS1" },
    { id: "26524", name: "LATS2" },
    { id: "54900", name: "LAX1" },
    { id: "143903", name: "LAYN" },
    { id: "81606", name: "LBH" },
    { id: "3929", name: "LBP" },
    { id: "3930", name: "LBR" },
    { id: "10660", name: "LBX1" },
    { id: "85474", name: "LBX2" },
    { id: "167691", name: "LCA5" },
    { id: "150082", name: "LCA5L" },
    { id: "3931", name: "LCAT" },
    { id: "353131", name: "LCE1A" },
    { id: "353132", name: "LCE1B" },
    { id: "353133", name: "LCE1C" },
    { id: "353134", name: "LCE1D" },
    { id: "353135", name: "LCE1E" },
    { id: "353137", name: "LCE1F" },
    { id: "353139", name: "LCE2A" },
    { id: "26239", name: "LCE2B" },
    { id: "353140", name: "LCE2C" },
    { id: "353141", name: "LCE2D" },
    { id: "353142", name: "LCE3A" },
    { id: "353143", name: "LCE3B" },
    { id: "353144", name: "LCE3C" },
    { id: "84648", name: "LCE3D" },
    { id: "353145", name: "LCE3E" },
    { id: "199834", name: "LCE4A" },
    { id: "254910", name: "LCE5A" },
    { id: "3932", name: "LCK" },
    { id: "253558", name: "LCLAT1" },
    { id: "51451", name: "LCMT1" },
    { id: "9836", name: "LCMT2" },
    { id: "3933", name: "LCN1" },
    { id: "286256", name: "LCN12" },
    { id: "389812", name: "LCN15" },
    { id: "3934", name: "LCN2" },
    { id: "84458", name: "LCOR" },
    { id: "254251", name: "LCORL" },
    { id: "3936", name: "LCP1" },
    { id: "3937", name: "LCP2" },
    { id: "3938", name: "LCT" },
    { id: "8861", name: "LDB1" },
    { id: "9079", name: "LDB2" },
    { id: "11155", name: "LDB3" },
    { id: "3939", name: "LDHA" },
    { id: "160287", name: "LDHAL6A" },
    { id: "92483", name: "LDHAL6B" },
    { id: "3945", name: "LDHB" },
    { id: "3948", name: "LDHC" },
    { id: "197257", name: "LDHD" },
    { id: "3949", name: "LDLR" },
    { id: "388633", name: "LDLRAD1" },
    { id: "26119", name: "LDLRAP1" },
    { id: "23641", name: "LDOC1" },
    { id: "84247", name: "LDOC1L" },
    { id: "11061", name: "LECT1" },
    { id: "3950", name: "LECT2" },
    { id: "51176", name: "LEF1" },
    { id: "93273", name: "LEMD1" },
    { id: "221496", name: "LEMD2" },
    { id: "23592", name: "LEMD3" },
    { id: "79165", name: "LENG1" },
    { id: "114823", name: "LENG8" },
    { id: "94059", name: "LENG9" },
    { id: "123169", name: "LEO1" },
    { id: "3952", name: "LEP" },
    { id: "3953", name: "LEPR" },
    { id: "64175", name: "LEPRE1" },
    { id: "55214", name: "LEPREL1" },
    { id: "10536", name: "LEPREL2" },
    { id: "10609", name: "LEPREL4" },
    { id: "23484", name: "LEPROTL1" },
    { id: "3954", name: "LETM1" },
    { id: "137994", name: "LETM2" },
    { id: "25875", name: "LETMD1" },
    { id: "3955", name: "LFNG" },
    { id: "3956", name: "LGALS1" },
    { id: "85329", name: "LGALS12" },
    { id: "29124", name: "LGALS13" },
    { id: "56891", name: "LGALS14" },
    { id: "3957", name: "LGALS2" },
    { id: "3958", name: "LGALS3" },
    { id: "3959", name: "LGALS3BP" },
    { id: "3960", name: "LGALS4" },
    { id: "3963", name: "LGALS7" },
    { id: "653499", name: "LGALS7B" },
    { id: "3964", name: "LGALS8" },
    { id: "3965", name: "LGALS9" },
    { id: "284194", name: "LGALS9B" },
    { id: "654346", name: "LGALS9C" },
    { id: "29094", name: "LGALSL" },
    { id: "9211", name: "LGI1" },
    { id: "203190", name: "LGI3" },
    { id: "5641", name: "LGMN" },
    { id: "55366", name: "LGR4" },
    { id: "51557", name: "LGSN" },
    { id: "3972", name: "LHB" },
    { id: "3973", name: "LHCGR" },
    { id: "10184", name: "LHFPL2" },
    { id: "375612", name: "LHFPL3" },
    { id: "222662", name: "LHFPL5" },
    { id: "64077", name: "LHPP" },
    { id: "3975", name: "LHX1" },
    { id: "9355", name: "LHX2" },
    { id: "8022", name: "LHX3" },
    { id: "89884", name: "LHX4" },
    { id: "26468", name: "LHX6" },
    { id: "431707", name: "LHX8" },
    { id: "56956", name: "LHX9" },
    { id: "3976", name: "LIF" },
    { id: "3977", name: "LIFR" },
    { id: "3978", name: "LIG1" },
    { id: "3980", name: "LIG3" },
    { id: "3981", name: "LIG4" },
    { id: "11026", name: "LILRA3" },
    { id: "10859", name: "LILRB1" },
    { id: "10288", name: "LILRB2" },
    { id: "11006", name: "LILRB4" },
    { id: "10990", name: "LILRB5" },
    { id: "3982", name: "LIM2" },
    { id: "51474", name: "LIMA1" },
    { id: "22998", name: "LIMCH1" },
    { id: "8994", name: "LIMD1" },
    { id: "80774", name: "LIMD2" },
    { id: "54923", name: "LIME1" },
    { id: "3984", name: "LIMK1" },
    { id: "3985", name: "LIMK2" },
    { id: "3987", name: "LIMS1" },
    { id: "55679", name: "LIMS2" },
    { id: "96626", name: "LIMS3" },
    { id: "100288695", name: "LIMS3L" },
    { id: "79727", name: "LIN28A" },
    { id: "389421", name: "LIN28B" },
    { id: "55957", name: "LIN37" },
    { id: "91750", name: "LIN52" },
    { id: "132660", name: "LIN54" },
    { id: "8825", name: "LIN7A" },
    { id: "64130", name: "LIN7B" },
    { id: "55327", name: "LIN7C" },
    { id: "286826", name: "LIN9" },
    { id: "147650", name: "LINC00085" },
    { id: "112597", name: "LINC00152" },
    { id: "401288", name: "LINC00242" },
    { id: "29931", name: "LINC00312" },
    { id: "284836", name: "LINC00319" },
    { id: "284185", name: "LINC00482" },
    { id: "221718", name: "LINC00518" },
    { id: "256369", name: "LINC00521" },
    { id: "147525", name: "LINC00526" },
    { id: "26138", name: "LINC00588" },
    { id: "253128", name: "LINC00612" },
    { id: "84894", name: "LINGO1" },
    { id: "158038", name: "LINGO2" },
    { id: "55180", name: "LINS" },
    { id: "3988", name: "LIPA" },
    { id: "3990", name: "LIPC" },
    { id: "3991", name: "LIPE" },
    { id: "8513", name: "LIPF" },
    { id: "9388", name: "LIPG" },
    { id: "200879", name: "LIPH" },
    { id: "142910", name: "LIPJ" },
    { id: "340654", name: "LIPM" },
    { id: "51601", name: "LIPT1" },
    { id: "387787", name: "LIPT2" },
    { id: "9516", name: "LITAF" },
    { id: "167410", name: "LIX1" },
    { id: "3996", name: "LLGL1" },
    { id: "3993", name: "LLGL2" },
    { id: "84298", name: "LLPH" },
    { id: "3998", name: "LMAN1" },
    { id: "79748", name: "LMAN1L" },
    { id: "10960", name: "LMAN2" },
    { id: "81562", name: "LMAN2L" },
    { id: "64327", name: "LMBR1" },
    { id: "55716", name: "LMBR1L" },
    { id: "55788", name: "LMBRD1" },
    { id: "92255", name: "LMBRD2" },
    { id: "29995", name: "LMCD1" },
    { id: "64788", name: "LMF1" },
    { id: "91289", name: "LMF2" },
    { id: "4000", name: "LMNA" },
    { id: "4001", name: "LMNB1" },
    { id: "84823", name: "LMNB2" },
    { id: "4004", name: "LMO1" },
    { id: "4005", name: "LMO2" },
    { id: "55885", name: "LMO3" },
    { id: "8543", name: "LMO4" },
    { id: "4008", name: "LMO7" },
    { id: "25802", name: "LMOD1" },
    { id: "22853", name: "LMTK2" },
    { id: "114783", name: "LMTK3" },
    { id: "4009", name: "LMX1A" },
    { id: "4010", name: "LMX1B" },
    { id: "348801", name: "LNP1" },
    { id: "4012", name: "LNPEP" },
    { id: "84708", name: "LNX1" },
    { id: "222484", name: "LNX2" },
    { id: "100507537", name: "LOC100507537" },
    { id: "284837", name: "LOC284837" },
    { id: "541471", name: "LOC541471" },
    { id: "649330", name: "LOC649330" },
    { id: "728392", name: "LOC728392" },
    { id: "118426", name: "LOH12CR1" },
    { id: "9361", name: "LONP1" },
    { id: "83752", name: "LONP2" },
    { id: "91694", name: "LONRF1" },
    { id: "164832", name: "LONRF2" },
    { id: "79836", name: "LONRF3" },
    { id: "4014", name: "LOR" },
    { id: "4015", name: "LOX" },
    { id: "125336", name: "LOXHD1" },
    { id: "4016", name: "LOXL1" },
    { id: "4017", name: "LOXL2" },
    { id: "84695", name: "LOXL3" },
    { id: "84171", name: "LOXL4" },
    { id: "4018", name: "LPA" },
    { id: "80350", name: "LPAL2" },
    { id: "9170", name: "LPAR2" },
    { id: "23566", name: "LPAR3" },
    { id: "2846", name: "LPAR4" },
    { id: "10161", name: "LPAR6" },
    { id: "79888", name: "LPCAT1" },
    { id: "54947", name: "LPCAT2" },
    { id: "10162", name: "LPCAT3" },
    { id: "254531", name: "LPCAT4" },
    { id: "9926", name: "LPGAT1" },
    { id: "22859", name: "LPHN1" },
    { id: "23266", name: "LPHN2" },
    { id: "23284", name: "LPHN3" },
    { id: "23175", name: "LPIN1" },
    { id: "9663", name: "LPIN2" },
    { id: "4023", name: "LPL" },
    { id: "4026", name: "LPP" },
    { id: "64748", name: "LPPR2" },
    { id: "79948", name: "LPPR3" },
    { id: "9890", name: "LPPR4" },
    { id: "9404", name: "LPXN" },
    { id: "9227", name: "LRAT" },
    { id: "987", name: "LRBA" },
    { id: "23143", name: "LRCH1" },
    { id: "57631", name: "LRCH2" },
    { id: "84859", name: "LRCH3" },
    { id: "4034", name: "LRCH4" },
    { id: "57622", name: "LRFN1" },
    { id: "78999", name: "LRFN4" },
    { id: "116844", name: "LRG1" },
    { id: "136332", name: "LRGUK" },
    { id: "55791", name: "LRIF1" },
    { id: "26018", name: "LRIG1" },
    { id: "121227", name: "LRIG3" },
    { id: "26103", name: "LRIT1" },
    { id: "4035", name: "LRP1" },
    { id: "26020", name: "LRP10" },
    { id: "84918", name: "LRP11" },
    { id: "29967", name: "LRP12" },
    { id: "53353", name: "LRP1B" },
    { id: "4036", name: "LRP2" },
    { id: "55805", name: "LRP2BP" },
    { id: "4038", name: "LRP4" },
    { id: "4041", name: "LRP5" },
    { id: "91355", name: "LRP5L" },
    { id: "4040", name: "LRP6" },
    { id: "7804", name: "LRP8" },
    { id: "4043", name: "LRPAP1" },
    { id: "10128", name: "LRPPRC" },
    { id: "122769", name: "LRR1" },
    { id: "55227", name: "LRRC1" },
    { id: "390205", name: "LRRC10B" },
    { id: "9684", name: "LRRC14" },
    { id: "131578", name: "LRRC15" },
    { id: "55604", name: "LRRC16A" },
    { id: "474354", name: "LRRC18" },
    { id: "79442", name: "LRRC2" },
    { id: "55222", name: "LRRC20" },
    { id: "10233", name: "LRRC23" },
    { id: "441381", name: "LRRC24" },
    { id: "126364", name: "LRRC25" },
    { id: "80313", name: "LRRC27" },
    { id: "123355", name: "LRRC28" },
    { id: "26231", name: "LRRC29" },
    { id: "81543", name: "LRRC3" },
    { id: "114659", name: "LRRC37B" },
    { id: "116135", name: "LRRC3B" },
    { id: "64101", name: "LRRC4" },
    { id: "55631", name: "LRRC40" },
    { id: "10489", name: "LRRC41" },
    { id: "115353", name: "LRRC42" },
    { id: "254050", name: "LRRC43" },
    { id: "201255", name: "LRRC45" },
    { id: "90506", name: "LRRC46" },
    { id: "57470", name: "LRRC47" },
    { id: "83450", name: "LRRC48" },
    { id: "54839", name: "LRRC49" },
    { id: "57689", name: "LRRC4C" },
    { id: "440699", name: "LRRC52" },
    { id: "115399", name: "LRRC56" },
    { id: "255252", name: "LRRC57" },
    { id: "116064", name: "LRRC58" },
    { id: "55379", name: "LRRC59" },
    { id: "23639", name: "LRRC6" },
    { id: "65999", name: "LRRC61" },
    { id: "100130742", name: "LRRC69" },
    { id: "57554", name: "LRRC7" },
    { id: "100130733", name: "LRRC70" },
    { id: "221424", name: "LRRC73" },
    { id: "56262", name: "LRRC8A" },
    { id: "84230", name: "LRRC8C" },
    { id: "55144", name: "LRRC8D" },
    { id: "80131", name: "LRRC8E" },
    { id: "85444", name: "LRRCC1" },
    { id: "9208", name: "LRRFIP1" },
    { id: "9209", name: "LRRFIP2" },
    { id: "84125", name: "LRRIQ1" },
    { id: "127255", name: "LRRIQ3" },
    { id: "344657", name: "LRRIQ4" },
    { id: "79705", name: "LRRK1" },
    { id: "120892", name: "LRRK2" },
    { id: "57633", name: "LRRN1" },
    { id: "164312", name: "LRRN4" },
    { id: "221091", name: "LRRN4CL" },
    { id: "90678", name: "LRSAM1" },
    { id: "220074", name: "LRTOMT" },
    { id: "222229", name: "LRWD1" },
    { id: "4045", name: "LSAMP" },
    { id: "55341", name: "LSG1" },
    { id: "27257", name: "LSM1" },
    { id: "84967", name: "LSM10" },
    { id: "134353", name: "LSM11" },
    { id: "124801", name: "LSM12" },
    { id: "26065", name: "LSM14A" },
    { id: "149986", name: "LSM14B" },
    { id: "57819", name: "LSM2" },
    { id: "27258", name: "LSM3" },
    { id: "25804", name: "LSM4" },
    { id: "23658", name: "LSM5" },
    { id: "11157", name: "LSM6" },
    { id: "51690", name: "LSM7" },
    { id: "84316", name: "LSMD1" },
    { id: "286006", name: "LSMEM1" },
    { id: "132228", name: "LSMEM2" },
    { id: "4046", name: "LSP1" },
    { id: "51599", name: "LSR" },
    { id: "4047", name: "LSS" },
    { id: "7940", name: "LST1" },
    { id: "4049", name: "LTA" },
    { id: "4048", name: "LTA4H" },
    { id: "4050", name: "LTB" },
    { id: "1241", name: "LTB4R" },
    { id: "56413", name: "LTB4R2" },
    { id: "4052", name: "LTBP1" },
    { id: "4053", name: "LTBP2" },
    { id: "4054", name: "LTBP3" },
    { id: "8425", name: "LTBP4" },
    { id: "4055", name: "LTBR" },
    { id: "4056", name: "LTC4S" },
    { id: "4057", name: "LTF" },
    { id: "4058", name: "LTK" },
    { id: "26046", name: "LTN1" },
    { id: "84946", name: "LTV1" },
    { id: "55692", name: "LUC7L" },
    { id: "51631", name: "LUC7L2" },
    { id: "51747", name: "LUC7L3" },
    { id: "4060", name: "LUM" },
    { id: "541468", name: "LURAP1" },
    { id: "286343", name: "LURAP1L" },
    { id: "7798", name: "LUZP1" },
    { id: "338645", name: "LUZP2" },
    { id: "51213", name: "LUZP4" },
    { id: "56925", name: "LXN" },
    { id: "8581", name: "LY6D" },
    { id: "4061", name: "LY6E" },
    { id: "58496", name: "LY6G5B" },
    { id: "80740", name: "LY6G6C" },
    { id: "58530", name: "LY6G6D" },
    { id: "259215", name: "LY6G6F" },
    { id: "4062", name: "LY6H" },
    { id: "54742", name: "LY6K" },
    { id: "9450", name: "LY86" },
    { id: "4063", name: "LY9" },
    { id: "23643", name: "LY96" },
    { id: "55646", name: "LYAR" },
    { id: "129530", name: "LYG1" },
    { id: "4066", name: "LYL1" },
    { id: "4067", name: "LYN" },
    { id: "66004", name: "LYNX1" },
    { id: "27076", name: "LYPD3" },
    { id: "130576", name: "LYPD6B" },
    { id: "10434", name: "LYPLA1" },
    { id: "11313", name: "LYPLA2" },
    { id: "127018", name: "LYPLAL1" },
    { id: "57149", name: "LYRM1" },
    { id: "57226", name: "LYRM2" },
    { id: "57128", name: "LYRM4" },
    { id: "144363", name: "LYRM5" },
    { id: "90624", name: "LYRM7" },
    { id: "388695", name: "LYSMD1" },
    { id: "256586", name: "LYSMD2" },
    { id: "116068", name: "LYSMD3" },
    { id: "1130", name: "LYST" },
    { id: "10894", name: "LYVE1" },
    { id: "4069", name: "LYZ" },
    { id: "131375", name: "LYZL4" },
    { id: "57151", name: "LYZL6" },
    { id: "84328", name: "LZIC" },
    { id: "54585", name: "LZTFL1" },
    { id: "8216", name: "LZTR1" },
    { id: "11178", name: "LZTS1" },
    { id: "84445", name: "LZTS2" },
    { id: "9762", name: "LZTS3" },
    { id: "130951", name: "M1AP" },
    { id: "4074", name: "M6PR" },
    { id: "89876", name: "MAATS1" },
    { id: "10586", name: "MAB21L2" },
    { id: "126868", name: "MAB21L3" },
    { id: "346389", name: "MACC1" },
    { id: "23499", name: "MACF1" },
    { id: "28992", name: "MACROD1" },
    { id: "140733", name: "MACROD2" },
    { id: "8379", name: "MAD1L1" },
    { id: "4085", name: "MAD2L1" },
    { id: "9587", name: "MAD2L1BP" },
    { id: "10459", name: "MAD2L2" },
    { id: "8174", name: "MADCAM1" },
    { id: "8567", name: "MADD" },
    { id: "10296", name: "MAEA" },
    { id: "84944", name: "MAEL" },
    { id: "4094", name: "MAF" },
    { id: "84232", name: "MAF1" },
    { id: "389692", name: "MAFA" },
    { id: "9935", name: "MAFB" },
    { id: "23764", name: "MAFF" },
    { id: "4097", name: "MAFG" },
    { id: "727764", name: "MAFIP" },
    { id: "7975", name: "MAFK" },
    { id: "4099", name: "MAG" },
    { id: "4100", name: "MAGEA1" },
    { id: "4109", name: "MAGEA10" },
    { id: "4110", name: "MAGEA11" },
    { id: "4111", name: "MAGEA12" },
    { id: "266740", name: "MAGEA2B" },
    { id: "4102", name: "MAGEA3" },
    { id: "4103", name: "MAGEA4" },
    { id: "4105", name: "MAGEA6" },
    { id: "4107", name: "MAGEA8" },
    { id: "139422", name: "MAGEB10" },
    { id: "286514", name: "MAGEB18" },
    { id: "4113", name: "MAGEB2" },
    { id: "4115", name: "MAGEB4" },
    { id: "158809", name: "MAGEB6" },
    { id: "9947", name: "MAGEC1" },
    { id: "51438", name: "MAGEC2" },
    { id: "139081", name: "MAGEC3" },
    { id: "9500", name: "MAGED1" },
    { id: "10916", name: "MAGED2" },
    { id: "728239", name: "MAGED4" },
    { id: "81557", name: "MAGED4B" },
    { id: "57692", name: "MAGEE1" },
    { id: "139599", name: "MAGEE2" },
    { id: "64110", name: "MAGEF1" },
    { id: "28986", name: "MAGEH1" },
    { id: "54551", name: "MAGEL2" },
    { id: "9223", name: "MAGI1" },
    { id: "9863", name: "MAGI2" },
    { id: "260425", name: "MAGI3" },
    { id: "79917", name: "MAGIX" },
    { id: "4116", name: "MAGOH" },
    { id: "55110", name: "MAGOHB" },
    { id: "84061", name: "MAGT1" },
    { id: "4117", name: "MAK" },
    { id: "84549", name: "MAK16" },
    { id: "4118", name: "MAL" },
    { id: "114569", name: "MAL2" },
    { id: "7851", name: "MALL" },
    { id: "115416", name: "MALSU1" },
    { id: "10892", name: "MALT1" },
    { id: "256691", name: "MAMDC2" },
    { id: "9794", name: "MAML1" },
    { id: "84441", name: "MAML2" },
    { id: "55534", name: "MAML3" },
    { id: "284358", name: "MAMSTR" },
    { id: "10905", name: "MAN1A2" },
    { id: "11253", name: "MAN1B1" },
    { id: "57134", name: "MAN1C1" },
    { id: "4124", name: "MAN2A1" },
    { id: "4122", name: "MAN2A2" },
    { id: "4125", name: "MAN2B1" },
    { id: "23324", name: "MAN2B2" },
    { id: "4123", name: "MAN2C1" },
    { id: "63905", name: "MANBAL" },
    { id: "79694", name: "MANEA" },
    { id: "7873", name: "MANF" },
    { id: "4128", name: "MAOA" },
    { id: "4129", name: "MAOB" },
    { id: "4130", name: "MAP1A" },
    { id: "4131", name: "MAP1B" },
    { id: "84557", name: "MAP1LC3A" },
    { id: "81631", name: "MAP1LC3B" },
    { id: "643246", name: "MAP1LC3B2" },
    { id: "440738", name: "MAP1LC3C" },
    { id: "55201", name: "MAP1S" },
    { id: "4133", name: "MAP2" },
    { id: "5604", name: "MAP2K1" },
    { id: "5605", name: "MAP2K2" },
    { id: "5606", name: "MAP2K3" },
    { id: "6416", name: "MAP2K4" },
    { id: "5608", name: "MAP2K6" },
    { id: "5609", name: "MAP2K7" },
    { id: "4214", name: "MAP3K1" },
    { id: "4294", name: "MAP3K10" },
    { id: "4296", name: "MAP3K11" },
    { id: "7786", name: "MAP3K12" },
    { id: "9175", name: "MAP3K13" },
    { id: "9020", name: "MAP3K14" },
    { id: "389840", name: "MAP3K15" },
    { id: "80122", name: "MAP3K19" },
    { id: "10746", name: "MAP3K2" },
    { id: "4215", name: "MAP3K3" },
    { id: "4216", name: "MAP3K4" },
    { id: "4217", name: "MAP3K5" },
    { id: "9064", name: "MAP3K6" },
    { id: "6885", name: "MAP3K7" },
    { id: "56911", name: "MAP3K7CL" },
    { id: "1326", name: "MAP3K8" },
    { id: "4293", name: "MAP3K9" },
    { id: "4134", name: "MAP4" },
    { id: "11184", name: "MAP4K1" },
    { id: "5871", name: "MAP4K2" },
    { id: "8491", name: "MAP4K3" },
    { id: "9448", name: "MAP4K4" },
    { id: "11183", name: "MAP4K5" },
    { id: "9053", name: "MAP7" },
    { id: "55700", name: "MAP7D1" },
    { id: "256714", name: "MAP7D2" },
    { id: "79649", name: "MAP7D3" },
    { id: "79884", name: "MAP9" },
    { id: "5594", name: "MAPK1" },
    { id: "5602", name: "MAPK10" },
    { id: "5600", name: "MAPK11" },
    { id: "6300", name: "MAPK12" },
    { id: "5603", name: "MAPK13" },
    { id: "1432", name: "MAPK14" },
    { id: "225689", name: "MAPK15" },
    { id: "93487", name: "MAPK1IP1L" },
    { id: "5595", name: "MAPK3" },
    { id: "5596", name: "MAPK4" },
    { id: "5597", name: "MAPK6" },
    { id: "5598", name: "MAPK7" },
    { id: "5599", name: "MAPK8" },
    { id: "9479", name: "MAPK8IP1" },
    { id: "23542", name: "MAPK8IP2" },
    { id: "23162", name: "MAPK8IP3" },
    { id: "5601", name: "MAPK9" },
    { id: "79109", name: "MAPKAP1" },
    { id: "9261", name: "MAPKAPK2" },
    { id: "7867", name: "MAPKAPK3" },
    { id: "8550", name: "MAPKAPK5" },
    { id: "23005", name: "MAPKBP1" },
    { id: "22919", name: "MAPRE1" },
    { id: "10982", name: "MAPRE2" },
    { id: "22924", name: "MAPRE3" },
    { id: "4137", name: "MAPT" },
    { id: "55016", name: "MARCH1" },
    { id: "162333", name: "MARCH10" },
    { id: "51257", name: "MARCH2" },
    { id: "115123", name: "MARCH3" },
    { id: "57574", name: "MARCH4" },
    { id: "54708", name: "MARCH5" },
    { id: "10299", name: "MARCH6" },
    { id: "64844", name: "MARCH7" },
    { id: "220972", name: "MARCH8" },
    { id: "92979", name: "MARCH9" },
    { id: "4082", name: "MARCKS" },
    { id: "65108", name: "MARCKSL1" },
    { id: "4139", name: "MARK1" },
    { id: "2011", name: "MARK2" },
    { id: "4140", name: "MARK3" },
    { id: "57787", name: "MARK4" },
    { id: "4141", name: "MARS" },
    { id: "83742", name: "MARVELD1" },
    { id: "153562", name: "MARVELD2" },
    { id: "91862", name: "MARVELD3" },
    { id: "4142", name: "MAS1" },
    { id: "5648", name: "MASP1" },
    { id: "10747", name: "MASP2" },
    { id: "22983", name: "MAST1" },
    { id: "23139", name: "MAST2" },
    { id: "23031", name: "MAST3" },
    { id: "375449", name: "MAST4" },
    { id: "84930", name: "MASTL" },
    { id: "4143", name: "MAT1A" },
    { id: "4144", name: "MAT2A" },
    { id: "27430", name: "MAT2B" },
    { id: "4145", name: "MATK" },
    { id: "4146", name: "MATN1" },
    { id: "4147", name: "MATN2" },
    { id: "4148", name: "MATN3" },
    { id: "8785", name: "MATN4" },
    { id: "9782", name: "MATR3" },
    { id: "23383", name: "MAU2" },
    { id: "57506", name: "MAVS" },
    { id: "4149", name: "MAX" },
    { id: "4150", name: "MAZ" },
    { id: "4151", name: "MB" },
    { id: "115004", name: "MB21D1" },
    { id: "151963", name: "MB21D2" },
    { id: "4152", name: "MBD1" },
    { id: "8932", name: "MBD2" },
    { id: "53615", name: "MBD3" },
    { id: "85509", name: "MBD3L1" },
    { id: "125997", name: "MBD3L2" },
    { id: "8930", name: "MBD4" },
    { id: "55777", name: "MBD5" },
    { id: "114785", name: "MBD6" },
    { id: "51562", name: "MBIP" },
    { id: "4153", name: "MBL2" },
    { id: "255374", name: "MBLAC1" },
    { id: "153364", name: "MBLAC2" },
    { id: "4154", name: "MBNL1" },
    { id: "10150", name: "MBNL2" },
    { id: "55796", name: "MBNL3" },
    { id: "154141", name: "MBOAT1" },
    { id: "129642", name: "MBOAT2" },
    { id: "79143", name: "MBOAT7" },
    { id: "4155", name: "MBP" },
    { id: "54799", name: "MBTD1" },
    { id: "8720", name: "MBTPS1" },
    { id: "51360", name: "MBTPS2" },
    { id: "4157", name: "MC1R" },
    { id: "4158", name: "MC2R" },
    { id: "4159", name: "MC3R" },
    { id: "4160", name: "MC4R" },
    { id: "4161", name: "MC5R" },
    { id: "4162", name: "MCAM" },
    { id: "27349", name: "MCAT" },
    { id: "4163", name: "MCC" },
    { id: "56922", name: "MCCC1" },
    { id: "64087", name: "MCCC2" },
    { id: "401250", name: "MCCD1" },
    { id: "84693", name: "MCEE" },
    { id: "4168", name: "MCF2" },
    { id: "23263", name: "MCF2L" },
    { id: "23101", name: "MCF2L2" },
    { id: "90411", name: "MCFD2" },
    { id: "2847", name: "MCHR1" },
    { id: "84539", name: "MCHR2" },
    { id: "345643", name: "MCIDAS" },
    { id: "4170", name: "MCL1" },
    { id: "55388", name: "MCM10" },
    { id: "4171", name: "MCM2" },
    { id: "4172", name: "MCM3" },
    { id: "8888", name: "MCM3AP" },
    { id: "4173", name: "MCM4" },
    { id: "4174", name: "MCM5" },
    { id: "4175", name: "MCM6" },
    { id: "4176", name: "MCM7" },
    { id: "84515", name: "MCM8" },
    { id: "254394", name: "MCM9" },
    { id: "79892", name: "MCMBP" },
    { id: "157777", name: "MCMDC2" },
    { id: "57192", name: "MCOLN1" },
    { id: "55283", name: "MCOLN3" },
    { id: "79648", name: "MCPH1" },
    { id: "10445", name: "MCRS1" },
    { id: "55784", name: "MCTP2" },
    { id: "28985", name: "MCTS1" },
    { id: "90550", name: "MCU" },
    { id: "9656", name: "MDC1" },
    { id: "4188", name: "MDFI" },
    { id: "29969", name: "MDFIC" },
    { id: "266727", name: "MDGA1" },
    { id: "161357", name: "MDGA2" },
    { id: "4190", name: "MDH1" },
    { id: "4191", name: "MDH2" },
    { id: "4192", name: "MDK" },
    { id: "56890", name: "MDM1" },
    { id: "4193", name: "MDM2" },
    { id: "4194", name: "MDM4" },
    { id: "23195", name: "MDN1" },
    { id: "145553", name: "MDP1" },
    { id: "4199", name: "ME1" },
    { id: "4200", name: "ME2" },
    { id: "4201", name: "MEA1" },
    { id: "64769", name: "MEAF6" },
    { id: "2122", name: "MECOM" },
    { id: "4204", name: "MECP2" },
    { id: "51102", name: "MECR" },
    { id: "5469", name: "MED1" },
    { id: "84246", name: "MED10" },
    { id: "400569", name: "MED11" },
    { id: "9968", name: "MED12" },
    { id: "116931", name: "MED12L" },
    { id: "9969", name: "MED13" },
    { id: "23389", name: "MED13L" },
    { id: "9282", name: "MED14" },
    { id: "51586", name: "MED15" },
    { id: "10025", name: "MED16" },
    { id: "9440", name: "MED17" },
    { id: "54797", name: "MED18" },
    { id: "219541", name: "MED19" },
    { id: "9477", name: "MED20" },
    { id: "9412", name: "MED21" },
    { id: "6837", name: "MED22" },
    { id: "9439", name: "MED23" },
    { id: "9862", name: "MED24" },
    { id: "81857", name: "MED25" },
    { id: "9441", name: "MED26" },
    { id: "9442", name: "MED27" },
    { id: "80306", name: "MED28" },
    { id: "55588", name: "MED29" },
    { id: "90390", name: "MED30" },
    { id: "51003", name: "MED31" },
    { id: "29079", name: "MED4" },
    { id: "10001", name: "MED6" },
    { id: "9443", name: "MED7" },
    { id: "112950", name: "MED8" },
    { id: "55090", name: "MED9" },
    { id: "84935", name: "MEDAG" },
    { id: "4205", name: "MEF2A" },
    { id: "100271849", name: "MEF2B" },
    { id: "729991", name: "MEF2BNB" },
    { id: "4208", name: "MEF2C" },
    { id: "4209", name: "MEF2D" },
    { id: "4210", name: "MEFV" },
    { id: "84466", name: "MEGF10" },
    { id: "84465", name: "MEGF11" },
    { id: "1953", name: "MEGF6" },
    { id: "1954", name: "MEGF8" },
    { id: "150365", name: "MEI1" },
    { id: "644890", name: "MEIG1" },
    { id: "254528", name: "MEIOB" },
    { id: "4211", name: "MEIS1" },
    { id: "4212", name: "MEIS2" },
    { id: "56917", name: "MEIS3" },
    { id: "9833", name: "MELK" },
    { id: "51072", name: "MEMO1" },
    { id: "4221", name: "MEN1" },
    { id: "4222", name: "MEOX1" },
    { id: "4223", name: "MEOX2" },
    { id: "4224", name: "MEP1A" },
    { id: "4225", name: "MEP1B" },
    { id: "56257", name: "MEPCE" },
    { id: "56955", name: "MEPE" },
    { id: "10461", name: "MERTK" },
    { id: "59274", name: "MESDC1" },
    { id: "23184", name: "MESDC2" },
    { id: "55897", name: "MESP1" },
    { id: "145873", name: "MESP2" },
    { id: "4232", name: "MEST" },
    { id: "4233", name: "MET" },
    { id: "23173", name: "METAP1" },
    { id: "10988", name: "METAP2" },
    { id: "284207", name: "METRNL" },
    { id: "4234", name: "METTL1" },
    { id: "399818", name: "METTL10" },
    { id: "751071", name: "METTL12" },
    { id: "51603", name: "METTL13" },
    { id: "57721", name: "METTL14" },
    { id: "196074", name: "METTL15" },
    { id: "79066", name: "METTL16" },
    { id: "64745", name: "METTL17" },
    { id: "92342", name: "METTL18" },
    { id: "254013", name: "METTL20" },
    { id: "151194", name: "METTL21A" },
    { id: "25895", name: "METTL21B" },
    { id: "196541", name: "METTL21C" },
    { id: "79609", name: "METTL21D" },
    { id: "79091", name: "METTL22" },
    { id: "124512", name: "METTL23" },
    { id: "84190", name: "METTL25" },
    { id: "339175", name: "METTL2A" },
    { id: "55798", name: "METTL2B" },
    { id: "56339", name: "METTL3" },
    { id: "64863", name: "METTL4" },
    { id: "29081", name: "METTL5" },
    { id: "131965", name: "METTL6" },
    { id: "25840", name: "METTL7A" },
    { id: "196410", name: "METTL7B" },
    { id: "79828", name: "METTL8" },
    { id: "51108", name: "METTL9" },
    { id: "92312", name: "MEX3A" },
    { id: "84206", name: "MEX3B" },
    { id: "51320", name: "MEX3C" },
    { id: "399664", name: "MEX3D" },
    { id: "4236", name: "MFAP1" },
    { id: "4237", name: "MFAP2" },
    { id: "9848", name: "MFAP3L" },
    { id: "4239", name: "MFAP4" },
    { id: "8076", name: "MFAP5" },
    { id: "56947", name: "MFF" },
    { id: "9258", name: "MFHAS1" },
    { id: "4241", name: "MFI2" },
    { id: "55669", name: "MFN1" },
    { id: "9927", name: "MFN2" },
    { id: "4242", name: "MFNG" },
    { id: "83552", name: "MFRP" },
    { id: "64747", name: "MFSD1" },
    { id: "10227", name: "MFSD10" },
    { id: "79157", name: "MFSD11" },
    { id: "113655", name: "MFSD3" },
    { id: "84975", name: "MFSD5" },
    { id: "54842", name: "MFSD6" },
    { id: "84179", name: "MFSD7" },
    { id: "256471", name: "MFSD8" },
    { id: "84804", name: "MFSD9" },
    { id: "23269", name: "MGA" },
    { id: "8972", name: "MGAM" },
    { id: "84709", name: "MGARP" },
    { id: "4245", name: "MGAT1" },
    { id: "11282", name: "MGAT4B" },
    { id: "146664", name: "MGAT5B" },
    { id: "399693", name: "MGC50722" },
    { id: "10724", name: "MGEA5" },
    { id: "11343", name: "MGLL" },
    { id: "92667", name: "MGME1" },
    { id: "4255", name: "MGMT" },
    { id: "4256", name: "MGP" },
    { id: "23295", name: "MGRN1" },
    { id: "4257", name: "MGST1" },
    { id: "4258", name: "MGST2" },
    { id: "4259", name: "MGST3" },
    { id: "8190", name: "MIA" },
    { id: "375056", name: "MIA3" },
    { id: "57534", name: "MIB1" },
    { id: "142678", name: "MIB2" },
    { id: "100507436", name: "MICA" },
    { id: "64780", name: "MICAL1" },
    { id: "9645", name: "MICAL2" },
    { id: "57553", name: "MICAL3" },
    { id: "84953", name: "MICALCL" },
    { id: "85377", name: "MICALL1" },
    { id: "79778", name: "MICALL2" },
    { id: "4277", name: "MICB" },
    { id: "10367", name: "MICU1" },
    { id: "221154", name: "MICU2" },
    { id: "4281", name: "MID1" },
    { id: "58526", name: "MID1IP1" },
    { id: "11043", name: "MID2" },
    { id: "90007", name: "MIDN" },
    { id: "84299", name: "MIEN1" },
    { id: "57708", name: "MIER1" },
    { id: "54531", name: "MIER2" },
    { id: "166968", name: "MIER3" },
    { id: "4282", name: "MIF" },
    { id: "57409", name: "MIF4GD" },
    { id: "60672", name: "MIIP" },
    { id: "84864", name: "MINA" },
    { id: "50488", name: "MINK1" },
    { id: "440574", name: "MINOS1" },
    { id: "9562", name: "MINPP1" },
    { id: "54468", name: "MIOS" },
    { id: "55586", name: "MIOX" },
    { id: "4284", name: "MIP" },
    { id: "4285", name: "MIPEP" },
    { id: "145282", name: "MIPOL1" },
    { id: "406884", name: "MIRLET7B" },
    { id: "406891", name: "MIRLET7I" },
    { id: "79003", name: "MIS12" },
    { id: "54069", name: "MIS18A" },
    { id: "55320", name: "MIS18BP1" },
    { id: "126353", name: "MISP" },
    { id: "129531", name: "MITD1" },
    { id: "4286", name: "MITF" },
    { id: "4288", name: "MKI67" },
    { id: "84365", name: "MKI67IP" },
    { id: "8195", name: "MKKS" },
    { id: "57591", name: "MKL1" },
    { id: "57496", name: "MKL2" },
    { id: "4289", name: "MKLN1" },
    { id: "8569", name: "MKNK1" },
    { id: "2872", name: "MKNK2" },
    { id: "23608", name: "MKRN1" },
    { id: "23609", name: "MKRN2" },
    { id: "7681", name: "MKRN3" },
    { id: "7682", name: "MKRN4P" },
    { id: "54903", name: "MKS1" },
    { id: "283078", name: "MKX" },
    { id: "23209", name: "MLC1" },
    { id: "9761", name: "MLEC" },
    { id: "4291", name: "MLF1" },
    { id: "79682", name: "MLF1IP" },
    { id: "8079", name: "MLF2" },
    { id: "4292", name: "MLH1" },
    { id: "27030", name: "MLH3" },
    { id: "90523", name: "MLIP" },
    { id: "197259", name: "MLKL" },
    { id: "4298", name: "MLLT1" },
    { id: "8028", name: "MLLT10" },
    { id: "10962", name: "MLLT11" },
    { id: "4300", name: "MLLT3" },
    { id: "4301", name: "MLLT4" },
    { id: "4302", name: "MLLT6" },
    { id: "4295", name: "MLN" },
    { id: "2862", name: "MLNR" },
    { id: "79083", name: "MLPH" },
    { id: "64223", name: "MLST8" },
    { id: "6945", name: "MLX" },
    { id: "22877", name: "MLXIP" },
    { id: "51085", name: "MLXIPL" },
    { id: "23417", name: "MLYCD" },
    { id: "166785", name: "MMAA" },
    { id: "326625", name: "MMAB" },
    { id: "25974", name: "MMACHC" },
    { id: "27249", name: "MMADHC" },
    { id: "221938", name: "MMD2" },
    { id: "4311", name: "MME" },
    { id: "93380", name: "MMGT1" },
    { id: "4312", name: "MMP1" },
    { id: "4319", name: "MMP10" },
    { id: "4321", name: "MMP12" },
    { id: "4322", name: "MMP13" },
    { id: "4323", name: "MMP14" },
    { id: "4324", name: "MMP15" },
    { id: "4325", name: "MMP16" },
    { id: "4326", name: "MMP17" },
    { id: "4327", name: "MMP19" },
    { id: "4313", name: "MMP2" },
    { id: "9313", name: "MMP20" },
    { id: "8511", name: "MMP23A" },
    { id: "10893", name: "MMP24" },
    { id: "64386", name: "MMP25" },
    { id: "56547", name: "MMP26" },
    { id: "79148", name: "MMP28" },
    { id: "4314", name: "MMP3" },
    { id: "4316", name: "MMP7" },
    { id: "4317", name: "MMP8" },
    { id: "4318", name: "MMP9" },
    { id: "22915", name: "MMRN1" },
    { id: "79812", name: "MMRN2" },
    { id: "64210", name: "MMS19" },
    { id: "253714", name: "MMS22L" },
    { id: "4330", name: "MN1" },
    { id: "4331", name: "MNAT1" },
    { id: "84057", name: "MND1" },
    { id: "4332", name: "MNDA" },
    { id: "84300", name: "MNF1" },
    { id: "55329", name: "MNS1" },
    { id: "4335", name: "MNT" },
    { id: "64112", name: "MOAP1" },
    { id: "55233", name: "MOB1A" },
    { id: "92597", name: "MOB1B" },
    { id: "81532", name: "MOB2" },
    { id: "126308", name: "MOB3A" },
    { id: "79817", name: "MOB3B" },
    { id: "148932", name: "MOB3C" },
    { id: "25843", name: "MOB4" },
    { id: "4336", name: "MOBP" },
    { id: "55034", name: "MOCOS" },
    { id: "4338", name: "MOCS2" },
    { id: "27304", name: "MOCS3" },
    { id: "346606", name: "MOGAT3" },
    { id: "7841", name: "MOGS" },
    { id: "5891", name: "MOK" },
    { id: "84315", name: "MON1A" },
    { id: "22879", name: "MON1B" },
    { id: "23041", name: "MON2" },
    { id: "27136", name: "MORC1" },
    { id: "22880", name: "MORC2" },
    { id: "23515", name: "MORC3" },
    { id: "79710", name: "MORC4" },
    { id: "10933", name: "MORF4L1" },
    { id: "9643", name: "MORF4L2" },
    { id: "79906", name: "MORN1" },
    { id: "729967", name: "MORN2" },
    { id: "283385", name: "MORN3" },
    { id: "118812", name: "MORN4" },
    { id: "254956", name: "MORN5" },
    { id: "4342", name: "MOS" },
    { id: "56180", name: "MOSPD1" },
    { id: "158747", name: "MOSPD2" },
    { id: "64598", name: "MOSPD3" },
    { id: "4343", name: "MOV10" },
    { id: "26002", name: "MOXD1" },
    { id: "51660", name: "MPC1" },
    { id: "25874", name: "MPC2" },
    { id: "9526", name: "MPDU1" },
    { id: "8777", name: "MPDZ" },
    { id: "219972", name: "MPEG1" },
    { id: "4350", name: "MPG" },
    { id: "10199", name: "MPHOSPH10" },
    { id: "10200", name: "MPHOSPH6" },
    { id: "54737", name: "MPHOSPH8" },
    { id: "10198", name: "MPHOSPH9" },
    { id: "4351", name: "MPI" },
    { id: "4352", name: "MPL" },
    { id: "136647", name: "MPLKIP" },
    { id: "84954", name: "MPND" },
    { id: "4353", name: "MPO" },
    { id: "4354", name: "MPP1" },
    { id: "4355", name: "MPP2" },
    { id: "4356", name: "MPP3" },
    { id: "58538", name: "MPP4" },
    { id: "64398", name: "MPP5" },
    { id: "51678", name: "MPP6" },
    { id: "143098", name: "MPP7" },
    { id: "65258", name: "MPPE1" },
    { id: "758", name: "MPPED1" },
    { id: "23164", name: "MPRIP" },
    { id: "4357", name: "MPST" },
    { id: "4358", name: "MPV17" },
    { id: "84769", name: "MPV17L2" },
    { id: "9019", name: "MPZL1" },
    { id: "10205", name: "MPZL2" },
    { id: "196264", name: "MPZL3" },
    { id: "56246", name: "MRAP" },
    { id: "112609", name: "MRAP2" },
    { id: "22808", name: "MRAS" },
    { id: "4360", name: "MRC1" },
    { id: "9902", name: "MRC2" },
    { id: "4361", name: "MRE11A" },
    { id: "55686", name: "MREG" },
    { id: "93621", name: "MRFAP1" },
    { id: "114932", name: "MRFAP1L1" },
    { id: "55257", name: "MRGBP" },
    { id: "259249", name: "MRGPRX1" },
    { id: "117194", name: "MRGPRX2" },
    { id: "84245", name: "MRI1" },
    { id: "79922", name: "MRM1" },
    { id: "133558", name: "MROH2B" },
    { id: "389690", name: "MROH5" },
    { id: "642475", name: "MROH6" },
    { id: "140699", name: "MROH8" },
    { id: "78988", name: "MRP63" },
    { id: "65008", name: "MRPL1" },
    { id: "124995", name: "MRPL10" },
    { id: "65003", name: "MRPL11" },
    { id: "6182", name: "MRPL12" },
    { id: "28998", name: "MRPL13" },
    { id: "64928", name: "MRPL14" },
    { id: "29088", name: "MRPL15" },
    { id: "54948", name: "MRPL16" },
    { id: "63875", name: "MRPL17" },
    { id: "29074", name: "MRPL18" },
    { id: "9801", name: "MRPL19" },
    { id: "51069", name: "MRPL2" },
    { id: "55052", name: "MRPL20" },
    { id: "219927", name: "MRPL21" },
    { id: "29093", name: "MRPL22" },
    { id: "6150", name: "MRPL23" },
    { id: "79590", name: "MRPL24" },
    { id: "51264", name: "MRPL27" },
    { id: "10573", name: "MRPL28" },
    { id: "11222", name: "MRPL3" },
    { id: "51263", name: "MRPL30" },
    { id: "64983", name: "MRPL32" },
    { id: "64981", name: "MRPL34" },
    { id: "51318", name: "MRPL35" },
    { id: "64979", name: "MRPL36" },
    { id: "51253", name: "MRPL37" },
    { id: "64978", name: "MRPL38" },
    { id: "54148", name: "MRPL39" },
    { id: "51073", name: "MRPL4" },
    { id: "64976", name: "MRPL40" },
    { id: "64975", name: "MRPL41" },
    { id: "28977", name: "MRPL42" },
    { id: "84545", name: "MRPL43" },
    { id: "65080", name: "MRPL44" },
    { id: "84311", name: "MRPL45" },
    { id: "26589", name: "MRPL46" },
    { id: "57129", name: "MRPL47" },
    { id: "51642", name: "MRPL48" },
    { id: "740", name: "MRPL49" },
    { id: "54534", name: "MRPL50" },
    { id: "51258", name: "MRPL51" },
    { id: "122704", name: "MRPL52" },
    { id: "116540", name: "MRPL53" },
    { id: "116541", name: "MRPL54" },
    { id: "128308", name: "MRPL55" },
    { id: "65005", name: "MRPL9" },
    { id: "55173", name: "MRPS10" },
    { id: "64963", name: "MRPS11" },
    { id: "6183", name: "MRPS12" },
    { id: "63931", name: "MRPS14" },
    { id: "64960", name: "MRPS15" },
    { id: "51021", name: "MRPS16" },
    { id: "51373", name: "MRPS17" },
    { id: "55168", name: "MRPS18A" },
    { id: "28973", name: "MRPS18B" },
    { id: "51023", name: "MRPS18C" },
    { id: "51116", name: "MRPS2" },
    { id: "54460", name: "MRPS21" },
    { id: "56945", name: "MRPS22" },
    { id: "51649", name: "MRPS23" },
    { id: "64951", name: "MRPS24" },
    { id: "64432", name: "MRPS25" },
    { id: "64949", name: "MRPS26" },
    { id: "23107", name: "MRPS27" },
    { id: "28957", name: "MRPS28" },
    { id: "10884", name: "MRPS30" },
    { id: "10240", name: "MRPS31" },
    { id: "51650", name: "MRPS33" },
    { id: "65993", name: "MRPS34" },
    { id: "60488", name: "MRPS35" },
    { id: "92259", name: "MRPS36" },
    { id: "64969", name: "MRPS5" },
    { id: "64968", name: "MRPS6" },
    { id: "51081", name: "MRPS7" },
    { id: "64965", name: "MRPS9" },
    { id: "92399", name: "MRRF" },
    { id: "57380", name: "MRS2" },
    { id: "51154", name: "MRTO4" },
    { id: "10335", name: "MRVI1" },
    { id: "931", name: "MS4A1" },
    { id: "54860", name: "MS4A12" },
    { id: "503497", name: "MS4A13" },
    { id: "84689", name: "MS4A14" },
    { id: "2206", name: "MS4A2" },
    { id: "51338", name: "MS4A4A" },
    { id: "64232", name: "MS4A5" },
    { id: "58475", name: "MS4A7" },
    { id: "79684", name: "MSANTD2" },
    { id: "91283", name: "MSANTD3" },
    { id: "84437", name: "MSANTD4" },
    { id: "9242", name: "MSC" },
    { id: "343930", name: "MSGN1" },
    { id: "4436", name: "MSH2" },
    { id: "4437", name: "MSH3" },
    { id: "4438", name: "MSH4" },
    { id: "4439", name: "MSH5" },
    { id: "2956", name: "MSH6" },
    { id: "4440", name: "MSI1" },
    { id: "124540", name: "MSI2" },
    { id: "339287", name: "MSL1" },
    { id: "55167", name: "MSL2" },
    { id: "10943", name: "MSL3" },
    { id: "151507", name: "MSL3P1" },
    { id: "10232", name: "MSLN" },
    { id: "4477", name: "MSMB" },
    { id: "6307", name: "MSMO1" },
    { id: "4478", name: "MSN" },
    { id: "4481", name: "MSR1" },
    { id: "51734", name: "MSRB1" },
    { id: "22921", name: "MSRB2" },
    { id: "253827", name: "MSRB3" },
    { id: "118490", name: "MSS51" },
    { id: "4485", name: "MST1" },
    { id: "4486", name: "MST1R" },
    { id: "51765", name: "MST4" },
    { id: "2660", name: "MSTN" },
    { id: "55154", name: "MSTO1" },
    { id: "4487", name: "MSX1" },
    { id: "4488", name: "MSX2" },
    { id: "4489", name: "MT1A" },
    { id: "4490", name: "MT1B" },
    { id: "326343", name: "MT1DP" },
    { id: "4499", name: "MT1M" },
    { id: "4502", name: "MT2A" },
    { id: "9112", name: "MTA1" },
    { id: "9219", name: "MTA2" },
    { id: "57504", name: "MTA3" },
    { id: "4507", name: "MTAP" },
    { id: "27085", name: "MTBP" },
    { id: "23787", name: "MTCH1" },
    { id: "23788", name: "MTCH2" },
    { id: "4515", name: "MTCP1" },
    { id: "92140", name: "MTDH" },
    { id: "7978", name: "MTERF" },
    { id: "51001", name: "MTERFD1" },
    { id: "130916", name: "MTERFD2" },
    { id: "4520", name: "MTF1" },
    { id: "22823", name: "MTF2" },
    { id: "123263", name: "MTFMT" },
    { id: "51537", name: "MTFP1" },
    { id: "9650", name: "MTFR1" },
    { id: "56181", name: "MTFR1L" },
    { id: "113115", name: "MTFR2" },
    { id: "92170", name: "MTG1" },
    { id: "26164", name: "MTG2" },
    { id: "4522", name: "MTHFD1" },
    { id: "25902", name: "MTHFD1L" },
    { id: "10797", name: "MTHFD2" },
    { id: "4524", name: "MTHFR" },
    { id: "10588", name: "MTHFS" },
    { id: "64779", name: "MTHFSD" },
    { id: "4528", name: "MTIF2" },
    { id: "219402", name: "MTIF3" },
    { id: "9633", name: "MTL5" },
    { id: "4534", name: "MTM1" },
    { id: "8776", name: "MTMR1" },
    { id: "54893", name: "MTMR10" },
    { id: "10903", name: "MTMR11" },
    { id: "54545", name: "MTMR12" },
    { id: "64419", name: "MTMR14" },
    { id: "8898", name: "MTMR2" },
    { id: "8897", name: "MTMR3" },
    { id: "9110", name: "MTMR4" },
    { id: "9107", name: "MTMR6" },
    { id: "9108", name: "MTMR7" },
    { id: "55613", name: "MTMR8" },
    { id: "66036", name: "MTMR9" },
    { id: "4543", name: "MTNR1A" },
    { id: "4544", name: "MTNR1B" },
    { id: "25821", name: "MTO1" },
    { id: "2475", name: "MTOR" },
    { id: "55149", name: "MTPAP" },
    { id: "136319", name: "MTPN" },
    { id: "4548", name: "MTR" },
    { id: "9617", name: "MTRF1" },
    { id: "54516", name: "MTRF1L" },
    { id: "100462977", name: "MTRNR2L1" },
    { id: "4552", name: "MTRR" },
    { id: "9788", name: "MTSS1" },
    { id: "92154", name: "MTSS1L" },
    { id: "4547", name: "MTTP" },
    { id: "57509", name: "MTUS1" },
    { id: "23281", name: "MTUS2" },
    { id: "4580", name: "MTX1" },
    { id: "10651", name: "MTX2" },
    { id: "345778", name: "MTX3" },
    { id: "4582", name: "MUC1" },
    { id: "10071", name: "MUC12" },
    { id: "56667", name: "MUC13" },
    { id: "94025", name: "MUC16" },
    { id: "4583", name: "MUC2" },
    { id: "200958", name: "MUC20" },
    { id: "4585", name: "MUC4" },
    { id: "727897", name: "MUC5B" },
    { id: "4589", name: "MUC7" },
    { id: "118430", name: "MUCL1" },
    { id: "79594", name: "MUL1" },
    { id: "84939", name: "MUM1" },
    { id: "139221", name: "MUM1L1" },
    { id: "347273", name: "MURC" },
    { id: "80198", name: "MUS81" },
    { id: "4593", name: "MUSK" },
    { id: "389125", name: "MUSTN1" },
    { id: "4594", name: "MUT" },
    { id: "4595", name: "MUTYH" },
    { id: "93343", name: "MVB12A" },
    { id: "89853", name: "MVB12B" },
    { id: "4597", name: "MVD" },
    { id: "4598", name: "MVK" },
    { id: "9961", name: "MVP" },
    { id: "4599", name: "MX1" },
    { id: "4600", name: "MX2" },
    { id: "4084", name: "MXD1" },
    { id: "83463", name: "MXD3" },
    { id: "10608", name: "MXD4" },
    { id: "4601", name: "MXI1" },
    { id: "25878", name: "MXRA5" },
    { id: "439921", name: "MXRA7" },
    { id: "54587", name: "MXRA8" },
    { id: "91663", name: "MYADM" },
    { id: "4602", name: "MYB" },
    { id: "10514", name: "MYBBP1A" },
    { id: "4603", name: "MYBL1" },
    { id: "4605", name: "MYBL2" },
    { id: "4604", name: "MYBPC1" },
    { id: "4606", name: "MYBPC2" },
    { id: "4607", name: "MYBPC3" },
    { id: "4608", name: "MYBPH" },
    { id: "343263", name: "MYBPHL" },
    { id: "4609", name: "MYC" },
    { id: "26292", name: "MYCBP" },
    { id: "23077", name: "MYCBP2" },
    { id: "84073", name: "MYCBPAP" },
    { id: "4610", name: "MYCL1" },
    { id: "4613", name: "MYCN" },
    { id: "4615", name: "MYD88" },
    { id: "50804", name: "MYEF2" },
    { id: "26579", name: "MYEOV" },
    { id: "150678", name: "MYEOV2" },
    { id: "4617", name: "MYF5" },
    { id: "4618", name: "MYF6" },
    { id: "4619", name: "MYH1" },
    { id: "4628", name: "MYH10" },
    { id: "4629", name: "MYH11" },
    { id: "8735", name: "MYH13" },
    { id: "79784", name: "MYH14" },
    { id: "22989", name: "MYH15" },
    { id: "4620", name: "MYH2" },
    { id: "4621", name: "MYH3" },
    { id: "4622", name: "MYH4" },
    { id: "4624", name: "MYH6" },
    { id: "4625", name: "MYH7" },
    { id: "57644", name: "MYH7B" },
    { id: "4626", name: "MYH8" },
    { id: "4627", name: "MYH9" },
    { id: "4632", name: "MYL1" },
    { id: "93408", name: "MYL10" },
    { id: "10627", name: "MYL12A" },
    { id: "103910", name: "MYL12B" },
    { id: "4633", name: "MYL2" },
    { id: "4634", name: "MYL3" },
    { id: "4635", name: "MYL4" },
    { id: "4636", name: "MYL5" },
    { id: "4637", name: "MYL6" },
    { id: "140465", name: "MYL6B" },
    { id: "58498", name: "MYL7" },
    { id: "10398", name: "MYL9" },
    { id: "29116", name: "MYLIP" },
    { id: "4638", name: "MYLK" },
    { id: "85366", name: "MYLK2" },
    { id: "91807", name: "MYLK3" },
    { id: "340156", name: "MYLK4" },
    { id: "29895", name: "MYLPF" },
    { id: "55892", name: "MYNN" },
    { id: "4651", name: "MYO10" },
    { id: "51168", name: "MYO15A" },
    { id: "80022", name: "MYO15B" },
    { id: "23026", name: "MYO16" },
    { id: "399687", name: "MYO18A" },
    { id: "84700", name: "MYO18B" },
    { id: "80179", name: "MYO19" },
    { id: "4640", name: "MYO1A" },
    { id: "4430", name: "MYO1B" },
    { id: "4641", name: "MYO1C" },
    { id: "4642", name: "MYO1D" },
    { id: "4643", name: "MYO1E" },
    { id: "4542", name: "MYO1F" },
    { id: "64005", name: "MYO1G" },
    { id: "53904", name: "MYO3A" },
    { id: "140469", name: "MYO3B" },
    { id: "4644", name: "MYO5A" },
    { id: "4645", name: "MYO5B" },
    { id: "55930", name: "MYO5C" },
    { id: "4646", name: "MYO6" },
    { id: "4647", name: "MYO7A" },
    { id: "4649", name: "MYO9A" },
    { id: "4650", name: "MYO9B" },
    { id: "4653", name: "MYOC" },
    { id: "93649", name: "MYOCD" },
    { id: "4654", name: "MYOD1" },
    { id: "26509", name: "MYOF" },
    { id: "4656", name: "MYOG" },
    { id: "8736", name: "MYOM1" },
    { id: "9172", name: "MYOM2" },
    { id: "127294", name: "MYOM3" },
    { id: "9499", name: "MYOT" },
    { id: "58529", name: "MYOZ1" },
    { id: "51778", name: "MYOZ2" },
    { id: "91977", name: "MYOZ3" },
    { id: "84665", name: "MYPN" },
    { id: "339344", name: "MYPOP" },
    { id: "745", name: "MYRF" },
    { id: "25924", name: "MYRIP" },
    { id: "114803", name: "MYSM1" },
    { id: "4661", name: "MYT1" },
    { id: "23040", name: "MYT1L" },
    { id: "100820829", name: "MYZAP" },
    { id: "51237", name: "MZB1" },
    { id: "7593", name: "MZF1" },
    { id: "440145", name: "MZT1" },
    { id: "653784", name: "MZT2A" },
    { id: "80097", name: "MZT2B" },
    { id: "9683", name: "N4BP1" },
    { id: "55728", name: "N4BP2" },
    { id: "10443", name: "N4BP2L2" },
    { id: "23138", name: "N4BP3" },
    { id: "29104", name: "N6AMT1" },
    { id: "221143", name: "N6AMT2" },
    { id: "8260", name: "NAA10" },
    { id: "84779", name: "NAA11" },
    { id: "80155", name: "NAA15" },
    { id: "79612", name: "NAA16" },
    { id: "51126", name: "NAA20" },
    { id: "80018", name: "NAA25" },
    { id: "122830", name: "NAA30" },
    { id: "60560", name: "NAA35" },
    { id: "51691", name: "NAA38" },
    { id: "79829", name: "NAA40" },
    { id: "80218", name: "NAA50" },
    { id: "79903", name: "NAA60" },
    { id: "27163", name: "NAAA" },
    { id: "254827", name: "NAALADL2" },
    { id: "4664", name: "NAB1" },
    { id: "4665", name: "NAB2" },
    { id: "64859", name: "NABP1" },
    { id: "79035", name: "NABP2" },
    { id: "4666", name: "NACA" },
    { id: "342538", name: "NACA2" },
    { id: "23148", name: "NACAD" },
    { id: "83955", name: "NACAP1" },
    { id: "112939", name: "NACC1" },
    { id: "138151", name: "NACC2" },
    { id: "65220", name: "NADK" },
    { id: "133686", name: "NADK2" },
    { id: "55191", name: "NADSYN1" },
    { id: "8883", name: "NAE1" },
    { id: "92345", name: "NAF1" },
    { id: "4668", name: "NAGA" },
    { id: "55577", name: "NAGK" },
    { id: "51172", name: "NAGPA" },
    { id: "162417", name: "NAGS" },
    { id: "203245", name: "NAIF1" },
    { id: "4671", name: "NAIP" },
    { id: "259232", name: "NALCN" },
    { id: "10135", name: "NAMPT" },
    { id: "79923", name: "NANOG" },
    { id: "340719", name: "NANOS1" },
    { id: "339345", name: "NANOS2" },
    { id: "342977", name: "NANOS3" },
    { id: "140838", name: "NANP" },
    { id: "54187", name: "NANS" },
    { id: "4673", name: "NAP1L1" },
    { id: "4674", name: "NAP1L2" },
    { id: "4675", name: "NAP1L3" },
    { id: "4676", name: "NAP1L4" },
    { id: "266812", name: "NAP1L5" },
    { id: "8775", name: "NAPA" },
    { id: "63908", name: "NAPB" },
    { id: "222236", name: "NAPEPLD" },
    { id: "8774", name: "NAPG" },
    { id: "93100", name: "NAPRT1" },
    { id: "9476", name: "NAPSA" },
    { id: "26502", name: "NARF" },
    { id: "64428", name: "NARFL" },
    { id: "79664", name: "NARG2" },
    { id: "4677", name: "NARS" },
    { id: "79731", name: "NARS2" },
    { id: "4678", name: "NASP" },
    { id: "55226", name: "NAT10" },
    { id: "57106", name: "NAT14" },
    { id: "375607", name: "NAT16" },
    { id: "24142", name: "NAT6" },
    { id: "9027", name: "NAT8" },
    { id: "51471", name: "NAT8B" },
    { id: "339983", name: "NAT8L" },
    { id: "26151", name: "NAT9" },
    { id: "89796", name: "NAV1" },
    { id: "89797", name: "NAV2" },
    { id: "89795", name: "NAV3" },
    { id: "51594", name: "NBAS" },
    { id: "26960", name: "NBEA" },
    { id: "65065", name: "NBEAL1" },
    { id: "23218", name: "NBEAL2" },
    { id: "4681", name: "NBL1" },
    { id: "4683", name: "NBN" },
    { id: "55672", name: "NBPF1" },
    { id: "100132406", name: "NBPF10" },
    { id: "200030", name: "NBPF11" },
    { id: "25832", name: "NBPF14" },
    { id: "284565", name: "NBPF15" },
    { id: "84224", name: "NBPF3" },
    { id: "4077", name: "NBR1" },
    { id: "83988", name: "NCALD" },
    { id: "4684", name: "NCAM1" },
    { id: "4685", name: "NCAM2" },
    { id: "9918", name: "NCAPD2" },
    { id: "23310", name: "NCAPD3" },
    { id: "64151", name: "NCAPG" },
    { id: "54892", name: "NCAPG2" },
    { id: "23397", name: "NCAPH" },
    { id: "29781", name: "NCAPH2" },
    { id: "4686", name: "NCBP1" },
    { id: "22916", name: "NCBP2" },
    { id: "342897", name: "NCCRP1" },
    { id: "23154", name: "NCDN" },
    { id: "57552", name: "NCEH1" },
    { id: "653361", name: "NCF1" },
    { id: "4688", name: "NCF2" },
    { id: "4689", name: "NCF4" },
    { id: "4690", name: "NCK1" },
    { id: "8440", name: "NCK2" },
    { id: "10787", name: "NCKAP1" },
    { id: "344148", name: "NCKAP5" },
    { id: "57701", name: "NCKAP5L" },
    { id: "51517", name: "NCKIPSD" },
    { id: "4691", name: "NCL" },
    { id: "56926", name: "NCLN" },
    { id: "8648", name: "NCOA1" },
    { id: "10499", name: "NCOA2" },
    { id: "8202", name: "NCOA3" },
    { id: "8031", name: "NCOA4" },
    { id: "57727", name: "NCOA5" },
    { id: "23054", name: "NCOA6" },
    { id: "135112", name: "NCOA7" },
    { id: "9611", name: "NCOR1" },
    { id: "9612", name: "NCOR2" },
    { id: "9437", name: "NCR1" },
    { id: "9436", name: "NCR2" },
    { id: "259197", name: "NCR3" },
    { id: "374383", name: "NCR3LG1" },
    { id: "23413", name: "NCS1" },
    { id: "23385", name: "NCSTN" },
    { id: "4538", name: "ND4" },
    { id: "55706", name: "NDC1" },
    { id: "10403", name: "NDC80" },
    { id: "54820", name: "NDE1" },
    { id: "81565", name: "NDEL1" },
    { id: "80762", name: "NDFIP1" },
    { id: "54602", name: "NDFIP2" },
    { id: "4692", name: "NDN" },
    { id: "56160", name: "NDNL2" },
    { id: "27158", name: "NDOR1" },
    { id: "4693", name: "NDP" },
    { id: "10397", name: "NDRG1" },
    { id: "57447", name: "NDRG2" },
    { id: "57446", name: "NDRG3" },
    { id: "65009", name: "NDRG4" },
    { id: "8509", name: "NDST2" },
    { id: "4694", name: "NDUFA1" },
    { id: "4705", name: "NDUFA10" },
    { id: "126328", name: "NDUFA11" },
    { id: "55967", name: "NDUFA12" },
    { id: "51079", name: "NDUFA13" },
    { id: "4695", name: "NDUFA2" },
    { id: "4697", name: "NDUFA4" },
    { id: "56901", name: "NDUFA4L2" },
    { id: "4698", name: "NDUFA5" },
    { id: "4700", name: "NDUFA6" },
    { id: "4701", name: "NDUFA7" },
    { id: "4702", name: "NDUFA8" },
    { id: "4704", name: "NDUFA9" },
    { id: "4706", name: "NDUFAB1" },
    { id: "51103", name: "NDUFAF1" },
    { id: "91942", name: "NDUFAF2" },
    { id: "25915", name: "NDUFAF3" },
    { id: "29078", name: "NDUFAF4" },
    { id: "79133", name: "NDUFAF5" },
    { id: "137682", name: "NDUFAF6" },
    { id: "4707", name: "NDUFB1" },
    { id: "4716", name: "NDUFB10" },
    { id: "54539", name: "NDUFB11" },
    { id: "4708", name: "NDUFB2" },
    { id: "4709", name: "NDUFB3" },
    { id: "4710", name: "NDUFB4" },
    { id: "4711", name: "NDUFB5" },
    { id: "4712", name: "NDUFB6" },
    { id: "4713", name: "NDUFB7" },
    { id: "4714", name: "NDUFB8" },
    { id: "4715", name: "NDUFB9" },
    { id: "4718", name: "NDUFC2" },
    { id: "4719", name: "NDUFS1" },
    { id: "4720", name: "NDUFS2" },
    { id: "4722", name: "NDUFS3" },
    { id: "4724", name: "NDUFS4" },
    { id: "4725", name: "NDUFS5" },
    { id: "4726", name: "NDUFS6" },
    { id: "374291", name: "NDUFS7" },
    { id: "4728", name: "NDUFS8" },
    { id: "4723", name: "NDUFV1" },
    { id: "4729", name: "NDUFV2" },
    { id: "4731", name: "NDUFV3" },
    { id: "4703", name: "NEB" },
    { id: "10529", name: "NEBL" },
    { id: "64168", name: "NECAB1" },
    { id: "54550", name: "NECAB2" },
    { id: "63941", name: "NECAB3" },
    { id: "25977", name: "NECAP1" },
    { id: "55707", name: "NECAP2" },
    { id: "121441", name: "NEDD1" },
    { id: "4734", name: "NEDD4" },
    { id: "23327", name: "NEDD4L" },
    { id: "4738", name: "NEDD8" },
    { id: "4739", name: "NEDD9" },
    { id: "4744", name: "NEFH" },
    { id: "4747", name: "NEFL" },
    { id: "4741", name: "NEFM" },
    { id: "257194", name: "NEGR1" },
    { id: "79661", name: "NEIL1" },
    { id: "252969", name: "NEIL2" },
    { id: "55247", name: "NEIL3" },
    { id: "4750", name: "NEK1" },
    { id: "152110", name: "NEK10" },
    { id: "79858", name: "NEK11" },
    { id: "4751", name: "NEK2" },
    { id: "4752", name: "NEK3" },
    { id: "6787", name: "NEK4" },
    { id: "10783", name: "NEK6" },
    { id: "140609", name: "NEK7" },
    { id: "284086", name: "NEK8" },
    { id: "91754", name: "NEK9" },
    { id: "7469", name: "NELFA" },
    { id: "25920", name: "NELFB" },
    { id: "51497", name: "NELFCD" },
    { id: "7936", name: "NELFE" },
    { id: "4745", name: "NELL1" },
    { id: "4753", name: "NELL2" },
    { id: "9147", name: "NEMF" },
    { id: "29937", name: "NENF" },
    { id: "4756", name: "NEO1" },
    { id: "10763", name: "NES" },
    { id: "10276", name: "NET1" },
    { id: "81832", name: "NETO1" },
    { id: "81831", name: "NETO2" },
    { id: "4758", name: "NEU1" },
    { id: "10825", name: "NEU3" },
    { id: "129807", name: "NEU4" },
    { id: "9148", name: "NEURL" },
    { id: "140825", name: "NEURL2" },
    { id: "93082", name: "NEURL3" },
    { id: "84461", name: "NEURL4" },
    { id: "4760", name: "NEUROD1" },
    { id: "58158", name: "NEUROD4" },
    { id: "63974", name: "NEUROD6" },
    { id: "4762", name: "NEUROG1" },
    { id: "63973", name: "NEUROG2" },
    { id: "50674", name: "NEUROG3" },
    { id: "91624", name: "NEXN" },
    { id: "4763", name: "NF1" },
    { id: "4771", name: "NF2" },
    { id: "150372", name: "NFAM1" },
    { id: "23114", name: "NFASC" },
    { id: "10725", name: "NFAT5" },
    { id: "4772", name: "NFATC1" },
    { id: "4773", name: "NFATC2" },
    { id: "84901", name: "NFATC2IP" },
    { id: "4775", name: "NFATC3" },
    { id: "4776", name: "NFATC4" },
    { id: "4778", name: "NFE2" },
    { id: "4779", name: "NFE2L1" },
    { id: "4780", name: "NFE2L2" },
    { id: "9603", name: "NFE2L3" },
    { id: "58160", name: "NFE4" },
    { id: "4774", name: "NFIA" },
    { id: "4781", name: "NFIB" },
    { id: "4782", name: "NFIC" },
    { id: "4783", name: "NFIL3" },
    { id: "4784", name: "NFIX" },
    { id: "4790", name: "NFKB1" },
    { id: "4791", name: "NFKB2" },
    { id: "4792", name: "NFKBIA" },
    { id: "4793", name: "NFKBIB" },
    { id: "84807", name: "NFKBID" },
    { id: "4794", name: "NFKBIE" },
    { id: "4795", name: "NFKBIL1" },
    { id: "64332", name: "NFKBIZ" },
    { id: "4798", name: "NFRKB" },
    { id: "9054", name: "NFS1" },
    { id: "27247", name: "NFU1" },
    { id: "4799", name: "NFX1" },
    { id: "152518", name: "NFXL1" },
    { id: "4800", name: "NFYA" },
    { id: "4801", name: "NFYB" },
    { id: "4802", name: "NFYC" },
    { id: "58157", name: "NGB" },
    { id: "25983", name: "NGDN" },
    { id: "25791", name: "NGEF" },
    { id: "4803", name: "NGF" },
    { id: "4804", name: "NGFR" },
    { id: "27018", name: "NGFRAP1" },
    { id: "55768", name: "NGLY1" },
    { id: "51335", name: "NGRN" },
    { id: "79840", name: "NHEJ1" },
    { id: "4807", name: "NHLH1" },
    { id: "378884", name: "NHLRC1" },
    { id: "374354", name: "NHLRC2" },
    { id: "387921", name: "NHLRC3" },
    { id: "283948", name: "NHLRC4" },
    { id: "55651", name: "NHP2" },
    { id: "4809", name: "NHP2L1" },
    { id: "4810", name: "NHS" },
    { id: "340527", name: "NHSL2" },
    { id: "84276", name: "NICN1" },
    { id: "4811", name: "NID1" },
    { id: "22795", name: "NID2" },
    { id: "60491", name: "NIF3L1" },
    { id: "167359", name: "NIM1" },
    { id: "51199", name: "NIN" },
    { id: "4814", name: "NINJ1" },
    { id: "4815", name: "NINJ2" },
    { id: "22981", name: "NINL" },
    { id: "51388", name: "NIP7" },
    { id: "81614", name: "NIPA2" },
    { id: "57185", name: "NIPAL3" },
    { id: "348938", name: "NIPAL4" },
    { id: "25836", name: "NIPBL" },
    { id: "8508", name: "NIPSNAP1" },
    { id: "25934", name: "NIPSNAP3A" },
    { id: "11188", name: "NISCH" },
    { id: "4817", name: "NIT1" },
    { id: "56954", name: "NIT2" },
    { id: "79576", name: "NKAP" },
    { id: "222698", name: "NKAPL" },
    { id: "85407", name: "NKD1" },
    { id: "85409", name: "NKD2" },
    { id: "4818", name: "NKG7" },
    { id: "28512", name: "NKIRAS1" },
    { id: "28511", name: "NKIRAS2" },
    { id: "55922", name: "NKRF" },
    { id: "4820", name: "NKTR" },
    { id: "7080", name: "NKX2-1" },
    { id: "4821", name: "NKX2-2" },
    { id: "159296", name: "NKX2-3" },
    { id: "644524", name: "NKX2-4" },
    { id: "1482", name: "NKX2-5" },
    { id: "26257", name: "NKX2-8" },
    { id: "4824", name: "NKX3-1" },
    { id: "4825", name: "NKX6-1" },
    { id: "54475", name: "NLE1" },
    { id: "22871", name: "NLGN1" },
    { id: "57555", name: "NLGN2" },
    { id: "54413", name: "NLGN3" },
    { id: "57502", name: "NLGN4X" },
    { id: "51701", name: "NLK" },
    { id: "57486", name: "NLN" },
    { id: "197358", name: "NLRC3" },
    { id: "58484", name: "NLRC4" },
    { id: "84166", name: "NLRC5" },
    { id: "22861", name: "NLRP1" },
    { id: "338322", name: "NLRP10" },
    { id: "204801", name: "NLRP11" },
    { id: "91662", name: "NLRP12" },
    { id: "55655", name: "NLRP2" },
    { id: "114548", name: "NLRP3" },
    { id: "147945", name: "NLRP4" },
    { id: "126206", name: "NLRP5" },
    { id: "199713", name: "NLRP7" },
    { id: "79671", name: "NLRX1" },
    { id: "4828", name: "NMB" },
    { id: "4829", name: "NMBR" },
    { id: "51068", name: "NMD3" },
    { id: "4830", name: "NME1" },
    { id: "4831", name: "NME2" },
    { id: "4832", name: "NME3" },
    { id: "4833", name: "NME4" },
    { id: "8382", name: "NME5" },
    { id: "10201", name: "NME6" },
    { id: "29922", name: "NME7" },
    { id: "51314", name: "NME8" },
    { id: "9111", name: "NMI" },
    { id: "64802", name: "NMNAT1" },
    { id: "23057", name: "NMNAT2" },
    { id: "57407", name: "NMRAL1" },
    { id: "54981", name: "NMRK1" },
    { id: "27231", name: "NMRK2" },
    { id: "129521", name: "NMS" },
    { id: "4836", name: "NMT1" },
    { id: "9397", name: "NMT2" },
    { id: "10874", name: "NMU" },
    { id: "10316", name: "NMUR1" },
    { id: "56923", name: "NMUR2" },
    { id: "4826", name: "NNAT" },
    { id: "4837", name: "NNMT" },
    { id: "23530", name: "NNT" },
    { id: "84273", name: "NOA1" },
    { id: "28987", name: "NOB1" },
    { id: "26155", name: "NOC2L" },
    { id: "64318", name: "NOC3L" },
    { id: "79050", name: "NOC4L" },
    { id: "10392", name: "NOD1" },
    { id: "64127", name: "NOD2" },
    { id: "4838", name: "NODAL" },
    { id: "9241", name: "NOG" },
    { id: "79954", name: "NOL10" },
    { id: "25926", name: "NOL11" },
    { id: "79159", name: "NOL12" },
    { id: "8996", name: "NOL3" },
    { id: "8715", name: "NOL4" },
    { id: "65083", name: "NOL6" },
    { id: "51406", name: "NOL7" },
    { id: "55035", name: "NOL8" },
    { id: "79707", name: "NOL9" },
    { id: "9221", name: "NOLC1" },
    { id: "64434", name: "NOM1" },
    { id: "23420", name: "NOMO1" },
    { id: "283820", name: "NOMO2" },
    { id: "408050", name: "NOMO3" },
    { id: "4841", name: "NONO" },
    { id: "55505", name: "NOP10" },
    { id: "8602", name: "NOP14" },
    { id: "51491", name: "NOP16" },
    { id: "4839", name: "NOP2" },
    { id: "10528", name: "NOP56" },
    { id: "51602", name: "NOP58" },
    { id: "161424", name: "NOP9" },
    { id: "4842", name: "NOS1" },
    { id: "9722", name: "NOS1AP" },
    { id: "4843", name: "NOS2" },
    { id: "4846", name: "NOS3" },
    { id: "51070", name: "NOSIP" },
    { id: "115677", name: "NOSTRIN" },
    { id: "4851", name: "NOTCH1" },
    { id: "4853", name: "NOTCH2" },
    { id: "388677", name: "NOTCH2NL" },
    { id: "4854", name: "NOTCH3" },
    { id: "4855", name: "NOTCH4" },
    { id: "4856", name: "NOV" },
    { id: "4857", name: "NOVA1" },
    { id: "27035", name: "NOX1" },
    { id: "50507", name: "NOX4" },
    { id: "79400", name: "NOX5" },
    { id: "10811", name: "NOXA1" },
    { id: "124056", name: "NOXO1" },
    { id: "4862", name: "NPAS2" },
    { id: "64067", name: "NPAS3" },
    { id: "266743", name: "NPAS4" },
    { id: "4863", name: "NPAT" },
    { id: "256933", name: "NPB" },
    { id: "2832", name: "NPBWR2" },
    { id: "4864", name: "NPC1" },
    { id: "29881", name: "NPC1L1" },
    { id: "10577", name: "NPC2" },
    { id: "56654", name: "NPDC1" },
    { id: "79716", name: "NPEPL1" },
    { id: "9520", name: "NPEPPS" },
    { id: "8620", name: "NPFF" },
    { id: "64106", name: "NPFFR1" },
    { id: "10886", name: "NPFFR2" },
    { id: "4867", name: "NPHP1" },
    { id: "27031", name: "NPHP3" },
    { id: "261734", name: "NPHP4" },
    { id: "4868", name: "NPHS1" },
    { id: "7827", name: "NPHS2" },
    { id: "101059938", name: "NPIPA7" },
    { id: "440348", name: "NPIPB15" },
    { id: "23117", name: "NPIPB3" },
    { id: "80896", name: "NPL" },
    { id: "55666", name: "NPLOC4" },
    { id: "4869", name: "NPM1" },
    { id: "10361", name: "NPM2" },
    { id: "10360", name: "NPM3" },
    { id: "255743", name: "NPNT" },
    { id: "4878", name: "NPPA" },
    { id: "4879", name: "NPPB" },
    { id: "4880", name: "NPPC" },
    { id: "4881", name: "NPR1" },
    { id: "4882", name: "NPR2" },
    { id: "4883", name: "NPR3" },
    { id: "10641", name: "NPRL2" },
    { id: "8131", name: "NPRL3" },
    { id: "27020", name: "NPTN" },
    { id: "4884", name: "NPTX1" },
    { id: "4885", name: "NPTX2" },
    { id: "23467", name: "NPTXR" },
    { id: "64111", name: "NPVF" },
    { id: "283869", name: "NPW" },
    { id: "4852", name: "NPY" },
    { id: "4886", name: "NPY1R" },
    { id: "4887", name: "NPY2R" },
    { id: "4889", name: "NPY5R" },
    { id: "1728", name: "NQO1" },
    { id: "4835", name: "NQO2" },
    { id: "8431", name: "NR0B2" },
    { id: "9572", name: "NR1D1" },
    { id: "9975", name: "NR1D2" },
    { id: "7376", name: "NR1H2" },
    { id: "10062", name: "NR1H3" },
    { id: "9971", name: "NR1H4" },
    { id: "8856", name: "NR1I2" },
    { id: "9970", name: "NR1I3" },
    { id: "7181", name: "NR2C1" },
    { id: "7182", name: "NR2C2" },
    { id: "126382", name: "NR2C2AP" },
    { id: "7101", name: "NR2E1" },
    { id: "10002", name: "NR2E3" },
    { id: "7025", name: "NR2F1" },
    { id: "7026", name: "NR2F2" },
    { id: "2063", name: "NR2F6" },
    { id: "2908", name: "NR3C1" },
    { id: "4306", name: "NR3C2" },
    { id: "3164", name: "NR4A1" },
    { id: "4929", name: "NR4A2" },
    { id: "8013", name: "NR4A3" },
    { id: "2516", name: "NR5A1" },
    { id: "2494", name: "NR5A2" },
    { id: "2649", name: "NR6A1" },
    { id: "4892", name: "NRAP" },
    { id: "441478", name: "NRARP" },
    { id: "4893", name: "NRAS" },
    { id: "29982", name: "NRBF2" },
    { id: "29959", name: "NRBP1" },
    { id: "340371", name: "NRBP2" },
    { id: "4897", name: "NRCAM" },
    { id: "4898", name: "NRD1" },
    { id: "55051", name: "NRDE2" },
    { id: "9315", name: "NREP" },
    { id: "4899", name: "NRF1" },
    { id: "3084", name: "NRG1" },
    { id: "9542", name: "NRG2" },
    { id: "10718", name: "NRG3" },
    { id: "145957", name: "NRG4" },
    { id: "4900", name: "NRGN" },
    { id: "8204", name: "NRIP1" },
    { id: "83714", name: "NRIP2" },
    { id: "203447", name: "NRK" },
    { id: "4901", name: "NRL" },
    { id: "11270", name: "NRM" },
    { id: "51299", name: "NRN1" },
    { id: "123904", name: "NRN1L" },
    { id: "8829", name: "NRP1" },
    { id: "8828", name: "NRP2" },
    { id: "140767", name: "NRSN1" },
    { id: "80023", name: "NRSN2" },
    { id: "4902", name: "NRTN" },
    { id: "9378", name: "NRXN1" },
    { id: "9379", name: "NRXN2" },
    { id: "9369", name: "NRXN3" },
    { id: "10412", name: "NSA2" },
    { id: "64324", name: "NSD1" },
    { id: "50814", name: "NSDHL" },
    { id: "4905", name: "NSF" },
    { id: "55968", name: "NSFL1C" },
    { id: "27065", name: "NSG1" },
    { id: "25936", name: "NSL1" },
    { id: "8439", name: "NSMAF" },
    { id: "197370", name: "NSMCE1" },
    { id: "286053", name: "NSMCE2" },
    { id: "54780", name: "NSMCE4A" },
    { id: "26012", name: "NSMF" },
    { id: "84081", name: "NSRP1" },
    { id: "54888", name: "NSUN2" },
    { id: "63899", name: "NSUN3" },
    { id: "387338", name: "NSUN4" },
    { id: "55695", name: "NSUN5" },
    { id: "30833", name: "NT5C" },
    { id: "84618", name: "NT5C1A" },
    { id: "93034", name: "NT5C1B" },
    { id: "22978", name: "NT5C2" },
    { id: "51251", name: "NT5C3A" },
    { id: "115024", name: "NT5C3B" },
    { id: "221294", name: "NT5DC1" },
    { id: "64943", name: "NT5DC2" },
    { id: "51559", name: "NT5DC3" },
    { id: "4907", name: "NT5E" },
    { id: "56953", name: "NT5M" },
    { id: "123803", name: "NTAN1" },
    { id: "4908", name: "NTF3" },
    { id: "4909", name: "NTF4" },
    { id: "4913", name: "NTHL1" },
    { id: "50863", name: "NTM" },
    { id: "28989", name: "NTMT1" },
    { id: "9423", name: "NTN1" },
    { id: "4917", name: "NTN3" },
    { id: "59277", name: "NTN4" },
    { id: "84628", name: "NTNG2" },
    { id: "84284", name: "NTPCR" },
    { id: "4914", name: "NTRK1" },
    { id: "4915", name: "NTRK2" },
    { id: "4916", name: "NTRK3" },
    { id: "4922", name: "NTS" },
    { id: "4923", name: "NTSR1" },
    { id: "23620", name: "NTSR2" },
    { id: "9891", name: "NUAK1" },
    { id: "81788", name: "NUAK2" },
    { id: "51667", name: "NUB1" },
    { id: "4682", name: "NUBP1" },
    { id: "10101", name: "NUBP2" },
    { id: "80224", name: "NUBPL" },
    { id: "4924", name: "NUCB1" },
    { id: "4925", name: "NUCB2" },
    { id: "64710", name: "NUCKS1" },
    { id: "10726", name: "NUDC" },
    { id: "84955", name: "NUDCD1" },
    { id: "134492", name: "NUDCD2" },
    { id: "23386", name: "NUDCD3" },
    { id: "4521", name: "NUDT1" },
    { id: "170685", name: "NUDT10" },
    { id: "55190", name: "NUDT11" },
    { id: "83594", name: "NUDT12" },
    { id: "25961", name: "NUDT13" },
    { id: "256281", name: "NUDT14" },
    { id: "55270", name: "NUDT15" },
    { id: "131870", name: "NUDT16" },
    { id: "84309", name: "NUDT16L1" },
    { id: "79873", name: "NUDT18" },
    { id: "390916", name: "NUDT19" },
    { id: "11051", name: "NUDT21" },
    { id: "84304", name: "NUDT22" },
    { id: "11165", name: "NUDT3" },
    { id: "11163", name: "NUDT4" },
    { id: "11164", name: "NUDT5" },
    { id: "11162", name: "NUDT6" },
    { id: "283927", name: "NUDT7" },
    { id: "53343", name: "NUDT9" },
    { id: "83540", name: "NUF2" },
    { id: "26747", name: "NUFIP1" },
    { id: "57532", name: "NUFIP2" },
    { id: "4926", name: "NUMA1" },
    { id: "8650", name: "NUMB" },
    { id: "9253", name: "NUMBL" },
    { id: "57122", name: "NUP107" },
    { id: "55746", name: "NUP133" },
    { id: "9972", name: "NUP153" },
    { id: "9631", name: "NUP155" },
    { id: "23279", name: "NUP160" },
    { id: "23511", name: "NUP188" },
    { id: "23165", name: "NUP205" },
    { id: "23225", name: "NUP210" },
    { id: "91181", name: "NUP210L" },
    { id: "8021", name: "NUP214" },
    { id: "129401", name: "NUP35" },
    { id: "79023", name: "NUP37" },
    { id: "348995", name: "NUP43" },
    { id: "10762", name: "NUP50" },
    { id: "53371", name: "NUP54" },
    { id: "23636", name: "NUP62" },
    { id: "54830", name: "NUP62CL" },
    { id: "79902", name: "NUP85" },
    { id: "4927", name: "NUP88" },
    { id: "9688", name: "NUP93" },
    { id: "4928", name: "NUP98" },
    { id: "9818", name: "NUPL1" },
    { id: "11097", name: "NUPL2" },
    { id: "26471", name: "NUPR1" },
    { id: "116150", name: "NUS1" },
    { id: "51203", name: "NUSAP1" },
    { id: "10204", name: "NUTF2" },
    { id: "256646", name: "NUTM1" },
    { id: "54754", name: "NUTM2F" },
    { id: "4931", name: "NVL" },
    { id: "284434", name: "NWD1" },
    { id: "10482", name: "NXF1" },
    { id: "56001", name: "NXF2" },
    { id: "56000", name: "NXF3" },
    { id: "55998", name: "NXF5" },
    { id: "64359", name: "NXN" },
    { id: "115861", name: "NXNL1" },
    { id: "120406", name: "NXPE2" },
    { id: "91775", name: "NXPE3" },
    { id: "54827", name: "NXPE4" },
    { id: "30010", name: "NXPH1" },
    { id: "11249", name: "NXPH2" },
    { id: "11248", name: "NXPH3" },
    { id: "29107", name: "NXT1" },
    { id: "55916", name: "NXT2" },
    { id: "222950", name: "NYAP1" },
    { id: "57624", name: "NYAP2" },
    { id: "57523", name: "NYNRIN" },
    { id: "60506", name: "NYX" },
    { id: "221443", name: "OARD1" },
    { id: "4938", name: "OAS1" },
    { id: "4939", name: "OAS2" },
    { id: "4940", name: "OAS3" },
    { id: "8638", name: "OASL" },
    { id: "4942", name: "OAT" },
    { id: "4946", name: "OAZ1" },
    { id: "4947", name: "OAZ2" },
    { id: "51686", name: "OAZ3" },
    { id: "79991", name: "OBFC1" },
    { id: "29991", name: "OBP2A" },
    { id: "29989", name: "OBP2B" },
    { id: "84033", name: "OBSCN" },
    { id: "23363", name: "OBSL1" },
    { id: "729330", name: "OC90" },
    { id: "79629", name: "OCEL1" },
    { id: "54940", name: "OCIAD1" },
    { id: "132299", name: "OCIAD2" },
    { id: "100506658", name: "OCLN" },
    { id: "654231", name: "OCM" },
    { id: "4951", name: "OCM2" },
    { id: "4952", name: "OCRL" },
    { id: "54959", name: "ODAM" },
    { id: "4953", name: "ODC1" },
    { id: "4956", name: "ODF1" },
    { id: "4957", name: "ODF2" },
    { id: "57489", name: "ODF2L" },
    { id: "113746", name: "ODF3" },
    { id: "440836", name: "ODF3B" },
    { id: "161753", name: "ODF3L1" },
    { id: "284451", name: "ODF3L2" },
    { id: "146852", name: "ODF4" },
    { id: "266553", name: "OFCC1" },
    { id: "8481", name: "OFD1" },
    { id: "4967", name: "OGDH" },
    { id: "55753", name: "OGDHL" },
    { id: "55239", name: "OGFOD1" },
    { id: "79676", name: "OGFOD2" },
    { id: "11054", name: "OGFR" },
    { id: "4968", name: "OGG1" },
    { id: "4969", name: "OGN" },
    { id: "8473", name: "OGT" },
    { id: "11339", name: "OIP5" },
    { id: "170392", name: "OIT3" },
    { id: "29789", name: "OLA1" },
    { id: "10439", name: "OLFM1" },
    { id: "93145", name: "OLFM2" },
    { id: "118427", name: "OLFM3" },
    { id: "10562", name: "OLFM4" },
    { id: "169611", name: "OLFML2A" },
    { id: "56944", name: "OLFML3" },
    { id: "116448", name: "OLIG1" },
    { id: "10215", name: "OLIG2" },
    { id: "167826", name: "OLIG3" },
    { id: "4973", name: "OLR1" },
    { id: "115209", name: "OMA1" },
    { id: "3175", name: "ONECUT1" },
    { id: "4976", name: "OPA1" },
    { id: "80207", name: "OPA3" },
    { id: "93377", name: "OPALIN" },
    { id: "4978", name: "OPCML" },
    { id: "4983", name: "OPHN1" },
    { id: "26873", name: "OPLAH" },
    { id: "5956", name: "OPN1LW" },
    { id: "2652", name: "OPN1MW" },
    { id: "94233", name: "OPN4" },
    { id: "4985", name: "OPRD1" },
    { id: "4986", name: "OPRK1" },
    { id: "4987", name: "OPRL1" },
    { id: "4988", name: "OPRM1" },
    { id: "26254", name: "OPTC" },
    { id: "10133", name: "OPTN" },
    { id: "282770", name: "OR10AG1" },
    { id: "26533", name: "OR10G3" },
    { id: "26476", name: "OR10J1" },
    { id: "401994", name: "OR14I1" },
    { id: "8388", name: "OR1E2" },
    { id: "26740", name: "OR1J2" },
    { id: "26735", name: "OR1L3" },
    { id: "125963", name: "OR1M1" },
    { id: "144125", name: "OR2AG1" },
    { id: "391211", name: "OR2G6" },
    { id: "26716", name: "OR2H1" },
    { id: "284521", name: "OR2L13" },
    { id: "127059", name: "OR2M5" },
    { id: "403244", name: "OR2T35" },
    { id: "254879", name: "OR2T6" },
    { id: "26692", name: "OR2W1" },
    { id: "4995", name: "OR3A2" },
    { id: "283093", name: "OR4C12" },
    { id: "219983", name: "OR4D6" },
    { id: "390648", name: "OR4F6" },
    { id: "219431", name: "OR4S2" },
    { id: "79339", name: "OR51B4" },
    { id: "143503", name: "OR51E1" },
    { id: "23538", name: "OR52A1" },
    { id: "338751", name: "OR52L1" },
    { id: "219982", name: "OR5A1" },
    { id: "402135", name: "OR5K2" },
    { id: "390155", name: "OR5T1" },
    { id: "135946", name: "OR6B1" },
    { id: "150681", name: "OR6B3" },
    { id: "390321", name: "OR6C1" },
    { id: "26659", name: "OR7A5" },
    { id: "26658", name: "OR7C2" },
    { id: "162998", name: "OR7D2" },
    { id: "390883", name: "OR7G3" },
    { id: "26595", name: "OR8B2" },
    { id: "283160", name: "OR8D2" },
    { id: "338662", name: "OR8D4" },
    { id: "390151", name: "OR8H2" },
    { id: "84876", name: "ORAI1" },
    { id: "80228", name: "ORAI2" },
    { id: "93129", name: "ORAI3" },
    { id: "220064", name: "ORAOV1" },
    { id: "4998", name: "ORC1" },
    { id: "4999", name: "ORC2" },
    { id: "23595", name: "ORC3" },
    { id: "5000", name: "ORC4" },
    { id: "5001", name: "ORC5" },
    { id: "23594", name: "ORC6" },
    { id: "5004", name: "ORM1" },
    { id: "94101", name: "ORMDL1" },
    { id: "29095", name: "ORMDL2" },
    { id: "94103", name: "ORMDL3" },
    { id: "10956", name: "OS9" },
    { id: "5007", name: "OSBP" },
    { id: "23762", name: "OSBP2" },
    { id: "114884", name: "OSBPL10" },
    { id: "114885", name: "OSBPL11" },
    { id: "114876", name: "OSBPL1A" },
    { id: "9885", name: "OSBPL2" },
    { id: "26031", name: "OSBPL3" },
    { id: "114879", name: "OSBPL5" },
    { id: "114880", name: "OSBPL6" },
    { id: "114882", name: "OSBPL8" },
    { id: "114883", name: "OSBPL9" },
    { id: "126014", name: "OSCAR" },
    { id: "55644", name: "OSGEP" },
    { id: "64172", name: "OSGEPL1" },
    { id: "29948", name: "OSGIN1" },
    { id: "5008", name: "OSM" },
    { id: "9180", name: "OSMR" },
    { id: "130497", name: "OSR1" },
    { id: "116039", name: "OSR2" },
    { id: "100128731", name: "OST4" },
    { id: "58505", name: "OSTC" },
    { id: "26578", name: "OSTF1" },
    { id: "28962", name: "OSTM1" },
    { id: "340990", name: "OTOG" },
    { id: "92736", name: "OTOP2" },
    { id: "347741", name: "OTOP3" },
    { id: "150677", name: "OTOS" },
    { id: "23440", name: "OTP" },
    { id: "55611", name: "OTUB1" },
    { id: "78990", name: "OTUB2" },
    { id: "220213", name: "OTUD1" },
    { id: "23252", name: "OTUD3" },
    { id: "54726", name: "OTUD4" },
    { id: "55593", name: "OTUD5" },
    { id: "139562", name: "OTUD6A" },
    { id: "51633", name: "OTUD6B" },
    { id: "161725", name: "OTUD7A" },
    { id: "56957", name: "OTUD7B" },
    { id: "5013", name: "OTX1" },
    { id: "5015", name: "OTX2" },
    { id: "124641", name: "OVCA2" },
    { id: "5016", name: "OVGP1" },
    { id: "5017", name: "OVOL1" },
    { id: "58495", name: "OVOL2" },
    { id: "5018", name: "OXA1L" },
    { id: "5019", name: "OXCT1" },
    { id: "64064", name: "OXCT2" },
    { id: "165140", name: "OXER1" },
    { id: "55074", name: "OXR1" },
    { id: "54995", name: "OXSM" },
    { id: "9943", name: "OXSR1" },
    { id: "5020", name: "OXT" },
    { id: "5021", name: "OXTR" },
    { id: "22953", name: "P2RX2" },
    { id: "5025", name: "P2RX4" },
    { id: "9127", name: "P2RX6" },
    { id: "5027", name: "P2RX7" },
    { id: "5028", name: "P2RY1" },
    { id: "64805", name: "P2RY12" },
    { id: "5029", name: "P2RY2" },
    { id: "5031", name: "P2RY6" },
    { id: "286530", name: "P2RY8" },
    { id: "5033", name: "P4HA1" },
    { id: "8974", name: "P4HA2" },
    { id: "283208", name: "P4HA3" },
    { id: "5034", name: "P4HB" },
    { id: "54681", name: "P4HTM" },
    { id: "5036", name: "PA2G4" },
    { id: "80227", name: "PAAF1" },
    { id: "26986", name: "PABPC1" },
    { id: "80336", name: "PABPC1L" },
    { id: "340529", name: "PABPC1L2A" },
    { id: "5042", name: "PABPC3" },
    { id: "8761", name: "PABPC4" },
    { id: "132430", name: "PABPC4L" },
    { id: "140886", name: "PABPC5" },
    { id: "8106", name: "PABPN1" },
    { id: "390748", name: "PABPN1L" },
    { id: "135138", name: "PACRG" },
    { id: "133015", name: "PACRGL" },
    { id: "55690", name: "PACS1" },
    { id: "23241", name: "PACS2" },
    { id: "29993", name: "PACSIN1" },
    { id: "11252", name: "PACSIN2" },
    { id: "29763", name: "PACSIN3" },
    { id: "29943", name: "PADI1" },
    { id: "11240", name: "PADI2" },
    { id: "51702", name: "PADI3" },
    { id: "23569", name: "PADI4" },
    { id: "353238", name: "PADI6" },
    { id: "5047", name: "PAEP" },
    { id: "54623", name: "PAF1" },
    { id: "5048", name: "PAFAH1B1" },
    { id: "5049", name: "PAFAH1B2" },
    { id: "5050", name: "PAFAH1B3" },
    { id: "55824", name: "PAG1" },
    { id: "8712", name: "PAGE1" },
    { id: "203569", name: "PAGE2" },
    { id: "139793", name: "PAGE3" },
    { id: "9506", name: "PAGE4" },
    { id: "90737", name: "PAGE5" },
    { id: "79447", name: "PAGR1" },
    { id: "5053", name: "PAH" },
    { id: "10606", name: "PAICS" },
    { id: "10605", name: "PAIP1" },
    { id: "51247", name: "PAIP2" },
    { id: "400961", name: "PAIP2B" },
    { id: "5058", name: "PAK1" },
    { id: "55003", name: "PAK1IP1" },
    { id: "5062", name: "PAK2" },
    { id: "5063", name: "PAK3" },
    { id: "10298", name: "PAK4" },
    { id: "56924", name: "PAK6" },
    { id: "57144", name: "PAK7" },
    { id: "79728", name: "PALB2" },
    { id: "27143", name: "PALD1" },
    { id: "23022", name: "PALLD" },
    { id: "5064", name: "PALM" },
    { id: "114299", name: "PALM2" },
    { id: "342979", name: "PALM3" },
    { id: "54873", name: "PALMD" },
    { id: "5066", name: "PAM" },
    { id: "51025", name: "PAM16" },
    { id: "9924", name: "PAN2" },
    { id: "255967", name: "PAN3" },
    { id: "53354", name: "PANK1" },
    { id: "80025", name: "PANK2" },
    { id: "79646", name: "PANK3" },
    { id: "55229", name: "PANK4" },
    { id: "24145", name: "PANX1" },
    { id: "56666", name: "PANX2" },
    { id: "116337", name: "PANX3" },
    { id: "167153", name: "PAPD4" },
    { id: "64282", name: "PAPD5" },
    { id: "11044", name: "PAPD7" },
    { id: "89932", name: "PAPLN" },
    { id: "10914", name: "PAPOLA" },
    { id: "56903", name: "PAPOLB" },
    { id: "64895", name: "PAPOLG" },
    { id: "5069", name: "PAPPA" },
    { id: "60676", name: "PAPPA2" },
    { id: "9061", name: "PAPSS1" },
    { id: "9060", name: "PAPSS2" },
    { id: "152559", name: "PAQR3" },
    { id: "124222", name: "PAQR4" },
    { id: "79957", name: "PAQR6" },
    { id: "164091", name: "PAQR7" },
    { id: "85315", name: "PAQR8" },
    { id: "56288", name: "PARD3" },
    { id: "117583", name: "PARD3B" },
    { id: "50855", name: "PARD6A" },
    { id: "84612", name: "PARD6B" },
    { id: "84552", name: "PARD6G" },
    { id: "8505", name: "PARG" },
    { id: "5071", name: "PARK2" },
    { id: "11315", name: "PARK7" },
    { id: "55486", name: "PARL" },
    { id: "5073", name: "PARN" },
    { id: "142", name: "PARP1" },
    { id: "84875", name: "PARP10" },
    { id: "57097", name: "PARP11" },
    { id: "64761", name: "PARP12" },
    { id: "54625", name: "PARP14" },
    { id: "165631", name: "PARP15" },
    { id: "54956", name: "PARP16" },
    { id: "10038", name: "PARP2" },
    { id: "10039", name: "PARP3" },
    { id: "79668", name: "PARP8" },
    { id: "83666", name: "PARP9" },
    { id: "55010", name: "PARPBP" },
    { id: "25973", name: "PARS2" },
    { id: "55742", name: "PARVA" },
    { id: "29780", name: "PARVB" },
    { id: "64098", name: "PARVG" },
    { id: "139135", name: "PASD1" },
    { id: "23178", name: "PASK" },
    { id: "160065", name: "PATE1" },
    { id: "100169851", name: "PATE3" },
    { id: "219988", name: "PATL1" },
    { id: "23598", name: "PATZ1" },
    { id: "5074", name: "PAWR" },
    { id: "5075", name: "PAX1" },
    { id: "5076", name: "PAX2" },
    { id: "5077", name: "PAX3" },
    { id: "5079", name: "PAX5" },
    { id: "5080", name: "PAX6" },
    { id: "5081", name: "PAX7" },
    { id: "7849", name: "PAX8" },
    { id: "5083", name: "PAX9" },
    { id: "94104", name: "PAXBP1" },
    { id: "22976", name: "PAXIP1" },
    { id: "51260", name: "PBDC1" },
    { id: "55872", name: "PBK" },
    { id: "64081", name: "PBLD" },
    { id: "55193", name: "PBRM1" },
    { id: "5087", name: "PBX1" },
    { id: "5089", name: "PBX2" },
    { id: "5090", name: "PBX3" },
    { id: "80714", name: "PBX4" },
    { id: "57326", name: "PBXIP1" },
    { id: "5091", name: "PC" },
    { id: "5092", name: "PCBD1" },
    { id: "84105", name: "PCBD2" },
    { id: "5093", name: "PCBP1" },
    { id: "5094", name: "PCBP2" },
    { id: "54039", name: "PCBP3" },
    { id: "57060", name: "PCBP4" },
    { id: "5095", name: "PCCA" },
    { id: "5096", name: "PCCB" },
    { id: "5097", name: "PCDH1" },
    { id: "57575", name: "PCDH10" },
    { id: "27328", name: "PCDH11X" },
    { id: "51294", name: "PCDH12" },
    { id: "27253", name: "PCDH17" },
    { id: "54510", name: "PCDH18" },
    { id: "57526", name: "PCDH19" },
    { id: "64881", name: "PCDH20" },
    { id: "5099", name: "PCDH7" },
    { id: "5100", name: "PCDH8" },
    { id: "5101", name: "PCDH9" },
    { id: "56147", name: "PCDHA1" },
    { id: "56139", name: "PCDHA10" },
    { id: "56138", name: "PCDHA11" },
    { id: "56137", name: "PCDHA12" },
    { id: "56146", name: "PCDHA2" },
    { id: "56145", name: "PCDHA3" },
    { id: "56144", name: "PCDHA4" },
    { id: "56143", name: "PCDHA5" },
    { id: "56142", name: "PCDHA6" },
    { id: "56141", name: "PCDHA7" },
    { id: "56140", name: "PCDHA8" },
    { id: "9752", name: "PCDHA9" },
    { id: "56134", name: "PCDHAC2" },
    { id: "56126", name: "PCDHB10" },
    { id: "56125", name: "PCDHB11" },
    { id: "56124", name: "PCDHB12" },
    { id: "56122", name: "PCDHB14" },
    { id: "56121", name: "PCDHB15" },
    { id: "56133", name: "PCDHB2" },
    { id: "56132", name: "PCDHB3" },
    { id: "56131", name: "PCDHB4" },
    { id: "26167", name: "PCDHB5" },
    { id: "56129", name: "PCDHB7" },
    { id: "56128", name: "PCDHB8" },
    { id: "56105", name: "PCDHGA11" },
    { id: "26025", name: "PCDHGA12" },
    { id: "56111", name: "PCDHGA4" },
    { id: "56107", name: "PCDHGA9" },
    { id: "56103", name: "PCDHGB2" },
    { id: "5098", name: "PCDHGC3" },
    { id: "56097", name: "PCDHGC5" },
    { id: "64773", name: "PCED1A" },
    { id: "91523", name: "PCED1B" },
    { id: "51585", name: "PCF11" },
    { id: "84759", name: "PCGF1" },
    { id: "7703", name: "PCGF2" },
    { id: "10336", name: "PCGF3" },
    { id: "84333", name: "PCGF5" },
    { id: "84108", name: "PCGF6" },
    { id: "55795", name: "PCID2" },
    { id: "63935", name: "PCIF1" },
    { id: "5105", name: "PCK1" },
    { id: "5106", name: "PCK2" },
    { id: "27445", name: "PCLO" },
    { id: "5108", name: "PCM1" },
    { id: "5110", name: "PCMT1" },
    { id: "115294", name: "PCMTD1" },
    { id: "55251", name: "PCMTD2" },
    { id: "5111", name: "PCNA" },
    { id: "57092", name: "PCNP" },
    { id: "5116", name: "PCNT" },
    { id: "22990", name: "PCNX" },
    { id: "399909", name: "PCNXL3" },
    { id: "64430", name: "PCNXL4" },
    { id: "5118", name: "PCOLCE" },
    { id: "126006", name: "PCP2" },
    { id: "5121", name: "PCP4" },
    { id: "654790", name: "PCP4L1" },
    { id: "5122", name: "PCSK1" },
    { id: "27344", name: "PCSK1N" },
    { id: "5126", name: "PCSK2" },
    { id: "54760", name: "PCSK4" },
    { id: "5125", name: "PCSK5" },
    { id: "5046", name: "PCSK6" },
    { id: "9159", name: "PCSK7" },
    { id: "255738", name: "PCSK9" },
    { id: "58488", name: "PCTP" },
    { id: "51449", name: "PCYOX1" },
    { id: "78991", name: "PCYOX1L" },
    { id: "5130", name: "PCYT1A" },
    { id: "9468", name: "PCYT1B" },
    { id: "5833", name: "PCYT2" },
    { id: "11333", name: "PDAP1" },
    { id: "5132", name: "PDC" },
    { id: "11235", name: "PDCD10" },
    { id: "22984", name: "PDCD11" },
    { id: "80380", name: "PDCD1LG2" },
    { id: "5134", name: "PDCD2" },
    { id: "84306", name: "PDCD2L" },
    { id: "27250", name: "PDCD4" },
    { id: "9141", name: "PDCD5" },
    { id: "10016", name: "PDCD6" },
    { id: "10015", name: "PDCD6IP" },
    { id: "10081", name: "PDCD7" },
    { id: "5082", name: "PDCL" },
    { id: "132954", name: "PDCL2" },
    { id: "79031", name: "PDCL3" },
    { id: "347862", name: "PDDC1" },
    { id: "10846", name: "PDE10A" },
    { id: "50940", name: "PDE11A" },
    { id: "201626", name: "PDE12" },
    { id: "5136", name: "PDE1A" },
    { id: "5138", name: "PDE2A" },
    { id: "5139", name: "PDE3A" },
    { id: "5140", name: "PDE3B" },
    { id: "5141", name: "PDE4A" },
    { id: "5142", name: "PDE4B" },
    { id: "5143", name: "PDE4C" },
    { id: "5144", name: "PDE4D" },
    { id: "9659", name: "PDE4DIP" },
    { id: "8654", name: "PDE5A" },
    { id: "5147", name: "PDE6D" },
    { id: "5148", name: "PDE6G" },
    { id: "5149", name: "PDE6H" },
    { id: "5150", name: "PDE7A" },
    { id: "27115", name: "PDE7B" },
    { id: "5151", name: "PDE8A" },
    { id: "8622", name: "PDE8B" },
    { id: "5152", name: "PDE9A" },
    { id: "64146", name: "PDF" },
    { id: "5154", name: "PDGFA" },
    { id: "5155", name: "PDGFB" },
    { id: "56034", name: "PDGFC" },
    { id: "80310", name: "PDGFD" },
    { id: "5156", name: "PDGFRA" },
    { id: "5159", name: "PDGFRB" },
    { id: "5157", name: "PDGFRL" },
    { id: "5160", name: "PDHA1" },
    { id: "5161", name: "PDHA2" },
    { id: "5162", name: "PDHB" },
    { id: "8050", name: "PDHX" },
    { id: "64714", name: "PDIA2" },
    { id: "2923", name: "PDIA3" },
    { id: "9601", name: "PDIA4" },
    { id: "10954", name: "PDIA5" },
    { id: "10130", name: "PDIA6" },
    { id: "149420", name: "PDIK1L" },
    { id: "204474", name: "PDILT" },
    { id: "5163", name: "PDK1" },
    { id: "5164", name: "PDK2" },
    { id: "5165", name: "PDK3" },
    { id: "5166", name: "PDK4" },
    { id: "9124", name: "PDLIM1" },
    { id: "64236", name: "PDLIM2" },
    { id: "27295", name: "PDLIM3" },
    { id: "8572", name: "PDLIM4" },
    { id: "10611", name: "PDLIM5" },
    { id: "9260", name: "PDLIM7" },
    { id: "54704", name: "PDP1" },
    { id: "57546", name: "PDP2" },
    { id: "5170", name: "PDPK1" },
    { id: "10630", name: "PDPN" },
    { id: "55066", name: "PDPR" },
    { id: "5171", name: "PDR" },
    { id: "81572", name: "PDRG1" },
    { id: "23244", name: "PDS5A" },
    { id: "23047", name: "PDS5B" },
    { id: "23590", name: "PDSS1" },
    { id: "3651", name: "PDX1" },
    { id: "23042", name: "PDXDC1" },
    { id: "8566", name: "PDXK" },
    { id: "57026", name: "PDXP" },
    { id: "5173", name: "PDYN" },
    { id: "51248", name: "PDZD11" },
    { id: "23037", name: "PDZD2" },
    { id: "79849", name: "PDZD3" },
    { id: "57595", name: "PDZD4" },
    { id: "118987", name: "PDZD8" },
    { id: "255762", name: "PDZD9" },
    { id: "5174", name: "PDZK1" },
    { id: "10158", name: "PDZK1IP1" },
    { id: "23024", name: "PDZRN3" },
    { id: "29951", name: "PDZRN4" },
    { id: "8682", name: "PEA15" },
    { id: "79834", name: "PEAK1" },
    { id: "375033", name: "PEAR1" },
    { id: "5037", name: "PEBP1" },
    { id: "157310", name: "PEBP4" },
    { id: "5175", name: "PECAM1" },
    { id: "55825", name: "PECR" },
    { id: "553115", name: "PEF1" },
    { id: "23089", name: "PEG10" },
    { id: "5178", name: "PEG3" },
    { id: "57162", name: "PELI1" },
    { id: "57161", name: "PELI2" },
    { id: "246330", name: "PELI3" },
    { id: "53918", name: "PELO" },
    { id: "27043", name: "PELP1" },
    { id: "10400", name: "PEMT" },
    { id: "5184", name: "PEPD" },
    { id: "5187", name: "PER1" },
    { id: "8864", name: "PER2" },
    { id: "8863", name: "PER3" },
    { id: "23481", name: "PES1" },
    { id: "100131801", name: "PET100" },
    { id: "5192", name: "PEX10" },
    { id: "8800", name: "PEX11A" },
    { id: "8799", name: "PEX11B" },
    { id: "5193", name: "PEX12" },
    { id: "5194", name: "PEX13" },
    { id: "5195", name: "PEX14" },
    { id: "9409", name: "PEX16" },
    { id: "5824", name: "PEX19" },
    { id: "5828", name: "PEX2" },
    { id: "55670", name: "PEX26" },
    { id: "8504", name: "PEX3" },
    { id: "5830", name: "PEX5" },
    { id: "51555", name: "PEX5L" },
    { id: "5190", name: "PEX6" },
    { id: "5191", name: "PEX7" },
    { id: "5196", name: "PF4" },
    { id: "5197", name: "PF4V1" },
    { id: "5198", name: "PFAS" },
    { id: "5201", name: "PFDN1" },
    { id: "5202", name: "PFDN2" },
    { id: "5203", name: "PFDN4" },
    { id: "5204", name: "PFDN5" },
    { id: "10471", name: "PFDN6" },
    { id: "5207", name: "PFKFB1" },
    { id: "5208", name: "PFKFB2" },
    { id: "5209", name: "PFKFB3" },
    { id: "5210", name: "PFKFB4" },
    { id: "5211", name: "PFKL" },
    { id: "5213", name: "PFKM" },
    { id: "5214", name: "PFKP" },
    { id: "5216", name: "PFN1" },
    { id: "5217", name: "PFN2" },
    { id: "643847", name: "PGA4" },
    { id: "5223", name: "PGAM1" },
    { id: "5224", name: "PGAM2" },
    { id: "441531", name: "PGAM4" },
    { id: "192111", name: "PGAM5" },
    { id: "80055", name: "PGAP1" },
    { id: "27315", name: "PGAP2" },
    { id: "84547", name: "PGBD1" },
    { id: "267002", name: "PGBD2" },
    { id: "267004", name: "PGBD3" },
    { id: "79605", name: "PGBD5" },
    { id: "5225", name: "PGC" },
    { id: "5226", name: "PGD" },
    { id: "5228", name: "PGF" },
    { id: "5229", name: "PGGT1B" },
    { id: "5230", name: "PGK1" },
    { id: "25796", name: "PGLS" },
    { id: "8993", name: "PGLYRP1" },
    { id: "114770", name: "PGLYRP2" },
    { id: "114771", name: "PGLYRP3" },
    { id: "5236", name: "PGM1" },
    { id: "55276", name: "PGM2" },
    { id: "283209", name: "PGM2L1" },
    { id: "5238", name: "PGM3" },
    { id: "5239", name: "PGM5" },
    { id: "283871", name: "PGP" },
    { id: "54858", name: "PGPEP1" },
    { id: "5241", name: "PGR" },
    { id: "10857", name: "PGRMC1" },
    { id: "10424", name: "PGRMC2" },
    { id: "9489", name: "PGS1" },
    { id: "221692", name: "PHACTR1" },
    { id: "9749", name: "PHACTR2" },
    { id: "116154", name: "PHACTR3" },
    { id: "65979", name: "PHACTR4" },
    { id: "51808", name: "PHAX" },
    { id: "5245", name: "PHB" },
    { id: "11331", name: "PHB2" },
    { id: "1911", name: "PHC1" },
    { id: "1912", name: "PHC2" },
    { id: "80012", name: "PHC3" },
    { id: "5252", name: "PHF1" },
    { id: "55274", name: "PHF10" },
    { id: "51131", name: "PHF11" },
    { id: "57649", name: "PHF12" },
    { id: "148479", name: "PHF13" },
    { id: "9678", name: "PHF14" },
    { id: "23338", name: "PHF15" },
    { id: "9767", name: "PHF16" },
    { id: "79960", name: "PHF17" },
    { id: "26147", name: "PHF19" },
    { id: "5253", name: "PHF2" },
    { id: "51230", name: "PHF20" },
    { id: "51105", name: "PHF20L1" },
    { id: "51317", name: "PHF21A" },
    { id: "79142", name: "PHF23" },
    { id: "23469", name: "PHF3" },
    { id: "84844", name: "PHF5A" },
    { id: "84295", name: "PHF6" },
    { id: "51533", name: "PHF7" },
    { id: "23133", name: "PHF8" },
    { id: "26227", name: "PHGDH" },
    { id: "55023", name: "PHIP" },
    { id: "5255", name: "PHKA1" },
    { id: "5256", name: "PHKA2" },
    { id: "5257", name: "PHKB" },
    { id: "5260", name: "PHKG1" },
    { id: "5261", name: "PHKG2" },
    { id: "22822", name: "PHLDA1" },
    { id: "7262", name: "PHLDA2" },
    { id: "23612", name: "PHLDA3" },
    { id: "23187", name: "PHLDB1" },
    { id: "90102", name: "PHLDB2" },
    { id: "653583", name: "PHLDB3" },
    { id: "23239", name: "PHLPP1" },
    { id: "23035", name: "PHLPP2" },
    { id: "493911", name: "PHOSPHO2" },
    { id: "100526832", name: "PHOSPHO2-KLHL23" },
    { id: "401", name: "PHOX2A" },
    { id: "8929", name: "PHOX2B" },
    { id: "29085", name: "PHPT1" },
    { id: "57661", name: "PHRF1" },
    { id: "10745", name: "PHTF1" },
    { id: "57157", name: "PHTF2" },
    { id: "5264", name: "PHYH" },
    { id: "254295", name: "PHYHD1" },
    { id: "9796", name: "PHYHIP" },
    { id: "84457", name: "PHYHIPL" },
    { id: "85007", name: "PHYKPL" },
    { id: "221476", name: "PI16" },
    { id: "5266", name: "PI3" },
    { id: "55361", name: "PI4K2A" },
    { id: "55300", name: "PI4K2B" },
    { id: "5297", name: "PI4KA" },
    { id: "728233", name: "PI4KAP1" },
    { id: "375133", name: "PI4KAP2" },
    { id: "5298", name: "PI4KB" },
    { id: "196500", name: "PIANP" },
    { id: "8554", name: "PIAS1" },
    { id: "9063", name: "PIAS2" },
    { id: "10401", name: "PIAS3" },
    { id: "51588", name: "PIAS4" },
    { id: "10464", name: "PIBF1" },
    { id: "8301", name: "PICALM" },
    { id: "9463", name: "PICK1" },
    { id: "55022", name: "PID1" },
    { id: "55367", name: "PIDD" },
    { id: "9780", name: "PIEZO1" },
    { id: "63895", name: "PIEZO2" },
    { id: "80119", name: "PIF1" },
    { id: "5277", name: "PIGA" },
    { id: "9488", name: "PIGB" },
    { id: "5281", name: "PIGF" },
    { id: "54872", name: "PIGG" },
    { id: "5283", name: "PIGH" },
    { id: "10026", name: "PIGK" },
    { id: "93183", name: "PIGM" },
    { id: "23556", name: "PIGN" },
    { id: "84720", name: "PIGO" },
    { id: "51227", name: "PIGP" },
    { id: "9091", name: "PIGQ" },
    { id: "5284", name: "PIGR" },
    { id: "94005", name: "PIGS" },
    { id: "51604", name: "PIGT" },
    { id: "128869", name: "PIGU" },
    { id: "55650", name: "PIGV" },
    { id: "284098", name: "PIGW" },
    { id: "54965", name: "PIGX" },
    { id: "84992", name: "PIGY" },
    { id: "55011", name: "PIH1D1" },
    { id: "120379", name: "PIH1D2" },
    { id: "139212", name: "PIH1D3" },
    { id: "118788", name: "PIK3AP1" },
    { id: "5286", name: "PIK3C2A" },
    { id: "5287", name: "PIK3C2B" },
    { id: "5289", name: "PIK3C3" },
    { id: "5290", name: "PIK3CA" },
    { id: "5291", name: "PIK3CB" },
    { id: "5293", name: "PIK3CD" },
    { id: "5294", name: "PIK3CG" },
    { id: "113791", name: "PIK3IP1" },
    { id: "5295", name: "PIK3R1" },
    { id: "5296", name: "PIK3R2" },
    { id: "8503", name: "PIK3R3" },
    { id: "30849", name: "PIK3R4" },
    { id: "23533", name: "PIK3R5" },
    { id: "146850", name: "PIK3R6" },
    { id: "200576", name: "PIKFYVE" },
    { id: "29992", name: "PILRA" },
    { id: "29990", name: "PILRB" },
    { id: "5292", name: "PIM1" },
    { id: "11040", name: "PIM2" },
    { id: "415116", name: "PIM3" },
    { id: "5300", name: "PIN1" },
    { id: "5301", name: "PIN1P1" },
    { id: "5303", name: "PIN4" },
    { id: "65018", name: "PINK1" },
    { id: "54984", name: "PINX1" },
    { id: "5304", name: "PIP" },
    { id: "5305", name: "PIP4K2A" },
    { id: "8396", name: "PIP4K2B" },
    { id: "79837", name: "PIP4K2C" },
    { id: "8394", name: "PIP5K1A" },
    { id: "8395", name: "PIP5K1B" },
    { id: "23396", name: "PIP5K1C" },
    { id: "138429", name: "PIP5KL1" },
    { id: "51268", name: "PIPOX" },
    { id: "8544", name: "PIR" },
    { id: "23761", name: "PISD" },
    { id: "57095", name: "PITHD1" },
    { id: "5306", name: "PITPNA" },
    { id: "23760", name: "PITPNB" },
    { id: "26207", name: "PITPNC1" },
    { id: "9600", name: "PITPNM1" },
    { id: "57605", name: "PITPNM2" },
    { id: "83394", name: "PITPNM3" },
    { id: "10531", name: "PITRM1" },
    { id: "5307", name: "PITX1" },
    { id: "5308", name: "PITX2" },
    { id: "5309", name: "PITX3" },
    { id: "9271", name: "PIWIL1" },
    { id: "55124", name: "PIWIL2" },
    { id: "440822", name: "PIWIL3" },
    { id: "143689", name: "PIWIL4" },
    { id: "64219", name: "PJA1" },
    { id: "9867", name: "PJA2" },
    { id: "5310", name: "PKD1" },
    { id: "342372", name: "PKD1L3" },
    { id: "339044", name: "PKD1P1" },
    { id: "5311", name: "PKD2" },
    { id: "9033", name: "PKD2L1" },
    { id: "27039", name: "PKD2L2" },
    { id: "10343", name: "PKDREJ" },
    { id: "5314", name: "PKHD1" },
    { id: "5569", name: "PKIA" },
    { id: "5570", name: "PKIB" },
    { id: "11142", name: "PKIG" },
    { id: "5313", name: "PKLR" },
    { id: "5315", name: "PKM" },
    { id: "9088", name: "PKMYT1" },
    { id: "5585", name: "PKN1" },
    { id: "5586", name: "PKN2" },
    { id: "29941", name: "PKN3" },
    { id: "5316", name: "PKNOX1" },
    { id: "63876", name: "PKNOX2" },
    { id: "5317", name: "PKP1" },
    { id: "5318", name: "PKP2" },
    { id: "11187", name: "PKP3" },
    { id: "8502", name: "PKP4" },
    { id: "51365", name: "PLA1A" },
    { id: "8399", name: "PLA2G10" },
    { id: "81579", name: "PLA2G12A" },
    { id: "23659", name: "PLA2G15" },
    { id: "11145", name: "PLA2G16" },
    { id: "5319", name: "PLA2G1B" },
    { id: "5320", name: "PLA2G2A" },
    { id: "26279", name: "PLA2G2D" },
    { id: "30814", name: "PLA2G2E" },
    { id: "64600", name: "PLA2G2F" },
    { id: "50487", name: "PLA2G3" },
    { id: "5321", name: "PLA2G4A" },
    { id: "100137049", name: "PLA2G4B" },
    { id: "8605", name: "PLA2G4C" },
    { id: "283748", name: "PLA2G4D" },
    { id: "255189", name: "PLA2G4F" },
    { id: "5322", name: "PLA2G5" },
    { id: "8398", name: "PLA2G6" },
    { id: "7941", name: "PLA2G7" },
    { id: "22925", name: "PLA2R1" },
    { id: "9373", name: "PLAA" },
    { id: "10761", name: "PLAC1" },
    { id: "51316", name: "PLAC8" },
    { id: "219348", name: "PLAC9" },
    { id: "5324", name: "PLAG1" },
    { id: "5325", name: "PLAGL1" },
    { id: "5327", name: "PLAT" },
    { id: "5328", name: "PLAU" },
    { id: "5329", name: "PLAUR" },
    { id: "151056", name: "PLB1" },
    { id: "79887", name: "PLBD1" },
    { id: "196463", name: "PLBD2" },
    { id: "23236", name: "PLCB1" },
    { id: "5330", name: "PLCB2" },
    { id: "5331", name: "PLCB3" },
    { id: "5332", name: "PLCB4" },
    { id: "5333", name: "PLCD1" },
    { id: "113026", name: "PLCD3" },
    { id: "84812", name: "PLCD4" },
    { id: "51196", name: "PLCE1" },
    { id: "5335", name: "PLCG1" },
    { id: "5336", name: "PLCG2" },
    { id: "23007", name: "PLCH1" },
    { id: "9651", name: "PLCH2" },
    { id: "23228", name: "PLCL2" },
    { id: "55344", name: "PLCXD1" },
    { id: "257068", name: "PLCXD2" },
    { id: "345557", name: "PLCXD3" },
    { id: "5337", name: "PLD1" },
    { id: "5338", name: "PLD2" },
    { id: "23646", name: "PLD3" },
    { id: "122618", name: "PLD4" },
    { id: "200150", name: "PLD5" },
    { id: "201164", name: "PLD6" },
    { id: "5339", name: "PLEC" },
    { id: "5341", name: "PLEK" },
    { id: "26499", name: "PLEK2" },
    { id: "59338", name: "PLEKHA1" },
    { id: "59339", name: "PLEKHA2" },
    { id: "65977", name: "PLEKHA3" },
    { id: "57664", name: "PLEKHA4" },
    { id: "54477", name: "PLEKHA5" },
    { id: "22874", name: "PLEKHA6" },
    { id: "144100", name: "PLEKHA7" },
    { id: "84725", name: "PLEKHA8" },
    { id: "58473", name: "PLEKHB1" },
    { id: "55041", name: "PLEKHB2" },
    { id: "400224", name: "PLEKHD1" },
    { id: "79156", name: "PLEKHF1" },
    { id: "79666", name: "PLEKHF2" },
    { id: "57480", name: "PLEKHG1" },
    { id: "64857", name: "PLEKHG2" },
    { id: "26030", name: "PLEKHG3" },
    { id: "25894", name: "PLEKHG4" },
    { id: "153478", name: "PLEKHG4B" },
    { id: "57449", name: "PLEKHG5" },
    { id: "55200", name: "PLEKHG6" },
    { id: "57475", name: "PLEKHH1" },
    { id: "130271", name: "PLEKHH2" },
    { id: "79990", name: "PLEKHH3" },
    { id: "55111", name: "PLEKHJ1" },
    { id: "9842", name: "PLEKHM1" },
    { id: "23207", name: "PLEKHM2" },
    { id: "389072", name: "PLEKHM3" },
    { id: "84069", name: "PLEKHN1" },
    { id: "51177", name: "PLEKHO1" },
    { id: "80301", name: "PLEKHO2" },
    { id: "79949", name: "PLEKHS1" },
    { id: "5340", name: "PLG" },
    { id: "55848", name: "PLGRKT" },
    { id: "5346", name: "PLIN1" },
    { id: "10226", name: "PLIN3" },
    { id: "729359", name: "PLIN4" },
    { id: "440503", name: "PLIN5" },
    { id: "5347", name: "PLK1" },
    { id: "55857", name: "PLK1S1" },
    { id: "10769", name: "PLK2" },
    { id: "1263", name: "PLK3" },
    { id: "10733", name: "PLK4" },
    { id: "51090", name: "PLLP" },
    { id: "5350", name: "PLN" },
    { id: "5351", name: "PLOD1" },
    { id: "5352", name: "PLOD2" },
    { id: "8985", name: "PLOD3" },
    { id: "5354", name: "PLP1" },
    { id: "5355", name: "PLP2" },
    { id: "5356", name: "PLRG1" },
    { id: "5357", name: "PLS1" },
    { id: "5358", name: "PLS3" },
    { id: "5359", name: "PLSCR1" },
    { id: "57047", name: "PLSCR2" },
    { id: "57048", name: "PLSCR3" },
    { id: "57088", name: "PLSCR4" },
    { id: "5360", name: "PLTP" },
    { id: "83483", name: "PLVAP" },
    { id: "57125", name: "PLXDC1" },
    { id: "84898", name: "PLXDC2" },
    { id: "5361", name: "PLXNA1" },
    { id: "55558", name: "PLXNA3" },
    { id: "5364", name: "PLXNB1" },
    { id: "23654", name: "PLXNB2" },
    { id: "5365", name: "PLXNB3" },
    { id: "10154", name: "PLXNC1" },
    { id: "23129", name: "PLXND1" },
    { id: "135293", name: "PM20D2" },
    { id: "5366", name: "PMAIP1" },
    { id: "5367", name: "PMCH" },
    { id: "56937", name: "PMEPA1" },
    { id: "11243", name: "PMF1" },
    { id: "100527963", name: "PMF1-BGLAP" },
    { id: "5371", name: "PML" },
    { id: "5372", name: "PMM1" },
    { id: "5373", name: "PMM2" },
    { id: "5376", name: "PMP22" },
    { id: "23203", name: "PMPCA" },
    { id: "9512", name: "PMPCB" },
    { id: "5378", name: "PMS1" },
    { id: "5395", name: "PMS2" },
    { id: "441194", name: "PMS2CL" },
    { id: "10654", name: "PMVK" },
    { id: "139728", name: "PNCK" },
    { id: "25957", name: "PNISR" },
    { id: "25953", name: "PNKD" },
    { id: "11284", name: "PNKP" },
    { id: "5406", name: "PNLIP" },
    { id: "5407", name: "PNLIPRP1" },
    { id: "5408", name: "PNLIPRP2" },
    { id: "9240", name: "PNMA1" },
    { id: "10687", name: "PNMA2" },
    { id: "29944", name: "PNMA3" },
    { id: "114824", name: "PNMA5" },
    { id: "84968", name: "PNMA6A" },
    { id: "55228", name: "PNMAL1" },
    { id: "57469", name: "PNMAL2" },
    { id: "5411", name: "PNN" },
    { id: "56902", name: "PNO1" },
    { id: "5368", name: "PNOC" },
    { id: "4860", name: "PNP" },
    { id: "57104", name: "PNPLA2" },
    { id: "80339", name: "PNPLA3" },
    { id: "150379", name: "PNPLA5" },
    { id: "10908", name: "PNPLA6" },
    { id: "50640", name: "PNPLA8" },
    { id: "55163", name: "PNPO" },
    { id: "87178", name: "PNPT1" },
    { id: "10957", name: "PNRC1" },
    { id: "55629", name: "PNRC2" },
    { id: "25886", name: "POC1A" },
    { id: "282809", name: "POC1B" },
    { id: "100528030", name: "POC1B-GALNT4" },
    { id: "134359", name: "POC5" },
    { id: "127435", name: "PODN" },
    { id: "5420", name: "PODXL" },
    { id: "50512", name: "PODXL2" },
    { id: "79983", name: "POF1B" },
    { id: "23509", name: "POFUT1" },
    { id: "23275", name: "POFUT2" },
    { id: "57645", name: "POGK" },
    { id: "56983", name: "POGLUT1" },
    { id: "23126", name: "POGZ" },
    { id: "5422", name: "POLA1" },
    { id: "23649", name: "POLA2" },
    { id: "5423", name: "POLB" },
    { id: "5424", name: "POLD1" },
    { id: "5425", name: "POLD2" },
    { id: "10714", name: "POLD3" },
    { id: "57804", name: "POLD4" },
    { id: "26073", name: "POLDIP2" },
    { id: "84271", name: "POLDIP3" },
    { id: "5426", name: "POLE" },
    { id: "5427", name: "POLE2" },
    { id: "54107", name: "POLE3" },
    { id: "56655", name: "POLE4" },
    { id: "5428", name: "POLG" },
    { id: "11232", name: "POLG2" },
    { id: "5429", name: "POLH" },
    { id: "11201", name: "POLI" },
    { id: "51426", name: "POLK" },
    { id: "27343", name: "POLL" },
    { id: "27434", name: "POLM" },
    { id: "353497", name: "POLN" },
    { id: "10721", name: "POLQ" },
    { id: "25885", name: "POLR1A" },
    { id: "84172", name: "POLR1B" },
    { id: "9533", name: "POLR1C" },
    { id: "51082", name: "POLR1D" },
    { id: "64425", name: "POLR1E" },
    { id: "5430", name: "POLR2A" },
    { id: "5431", name: "POLR2B" },
    { id: "5432", name: "POLR2C" },
    { id: "5433", name: "POLR2D" },
    { id: "5434", name: "POLR2E" },
    { id: "5435", name: "POLR2F" },
    { id: "5436", name: "POLR2G" },
    { id: "5437", name: "POLR2H" },
    { id: "5438", name: "POLR2I" },
    { id: "5439", name: "POLR2J" },
    { id: "246721", name: "POLR2J2" },
    { id: "548644", name: "POLR2J3" },
    { id: "5440", name: "POLR2K" },
    { id: "5441", name: "POLR2L" },
    { id: "81488", name: "POLR2M" },
    { id: "11128", name: "POLR3A" },
    { id: "55703", name: "POLR3B" },
    { id: "10623", name: "POLR3C" },
    { id: "661", name: "POLR3D" },
    { id: "55718", name: "POLR3E" },
    { id: "10621", name: "POLR3F" },
    { id: "10622", name: "POLR3G" },
    { id: "84265", name: "POLR3GL" },
    { id: "171568", name: "POLR3H" },
    { id: "51728", name: "POLR3K" },
    { id: "5442", name: "POLRMT" },
    { id: "9883", name: "POM121" },
    { id: "100101267", name: "POM121C" },
    { id: "5443", name: "POMC" },
    { id: "55624", name: "POMGNT1" },
    { id: "51371", name: "POMP" },
    { id: "10585", name: "POMT1" },
    { id: "29954", name: "POMT2" },
    { id: "22932", name: "POMZP3" },
    { id: "5444", name: "PON1" },
    { id: "5445", name: "PON2" },
    { id: "10940", name: "POP1" },
    { id: "10775", name: "POP4" },
    { id: "51367", name: "POP5" },
    { id: "10248", name: "POP7" },
    { id: "64091", name: "POPDC2" },
    { id: "5447", name: "POR" },
    { id: "64840", name: "PORCN" },
    { id: "10631", name: "POSTN" },
    { id: "25913", name: "POT1" },
    { id: "340441", name: "POTEA" },
    { id: "100996331", name: "POTEB" },
    { id: "100287399", name: "POTEB2" },
    { id: "388468", name: "POTEC" },
    { id: "317754", name: "POTED" },
    { id: "445582", name: "POTEE" },
    { id: "728378", name: "POTEF" },
    { id: "404785", name: "POTEG" },
    { id: "23784", name: "POTEH" },
    { id: "653269", name: "POTEI" },
    { id: "653781", name: "POTEJ" },
    { id: "440915", name: "POTEKP" },
    { id: "641455", name: "POTEM" },
    { id: "5449", name: "POU1F1" },
    { id: "5450", name: "POU2AF1" },
    { id: "5451", name: "POU2F1" },
    { id: "5452", name: "POU2F2" },
    { id: "25833", name: "POU2F3" },
    { id: "5453", name: "POU3F1" },
    { id: "5454", name: "POU3F2" },
    { id: "5455", name: "POU3F3" },
    { id: "5456", name: "POU3F4" },
    { id: "5457", name: "POU4F1" },
    { id: "5458", name: "POU4F2" },
    { id: "5459", name: "POU4F3" },
    { id: "5460", name: "POU5F1" },
    { id: "134187", name: "POU5F2" },
    { id: "11281", name: "POU6F2" },
    { id: "151649", name: "PP2D1" },
    { id: "5464", name: "PPA1" },
    { id: "27068", name: "PPA2" },
    { id: "56342", name: "PPAN" },
    { id: "8611", name: "PPAP2A" },
    { id: "8613", name: "PPAP2B" },
    { id: "8612", name: "PPAP2C" },
    { id: "196051", name: "PPAPDC1A" },
    { id: "403313", name: "PPAPDC2" },
    { id: "5465", name: "PPARA" },
    { id: "5467", name: "PPARD" },
    { id: "5468", name: "PPARG" },
    { id: "10891", name: "PPARGC1A" },
    { id: "133522", name: "PPARGC1B" },
    { id: "5471", name: "PPAT" },
    { id: "5473", name: "PPBP" },
    { id: "60490", name: "PPCDC" },
    { id: "79717", name: "PPCS" },
    { id: "79144", name: "PPDPF" },
    { id: "5475", name: "PPEF1" },
    { id: "5470", name: "PPEF2" },
    { id: "8500", name: "PPFIA1" },
    { id: "8499", name: "PPFIA2" },
    { id: "8541", name: "PPFIA3" },
    { id: "8497", name: "PPFIA4" },
    { id: "8496", name: "PPFIBP1" },
    { id: "8495", name: "PPFIBP2" },
    { id: "51535", name: "PPHLN1" },
    { id: "5478", name: "PPIA" },
    { id: "645142", name: "PPIAL4D" },
    { id: "5479", name: "PPIB" },
    { id: "5480", name: "PPIC" },
    { id: "5481", name: "PPID" },
    { id: "10450", name: "PPIE" },
    { id: "10105", name: "PPIF" },
    { id: "9360", name: "PPIG" },
    { id: "10465", name: "PPIH" },
    { id: "51645", name: "PPIL1" },
    { id: "23759", name: "PPIL2" },
    { id: "53938", name: "PPIL3" },
    { id: "85313", name: "PPIL4" },
    { id: "285755", name: "PPIL6" },
    { id: "9677", name: "PPIP5K1" },
    { id: "23262", name: "PPIP5K2" },
    { id: "5493", name: "PPL" },
    { id: "5494", name: "PPM1A" },
    { id: "5495", name: "PPM1B" },
    { id: "8493", name: "PPM1D" },
    { id: "22843", name: "PPM1E" },
    { id: "9647", name: "PPM1F" },
    { id: "5496", name: "PPM1G" },
    { id: "57460", name: "PPM1H" },
    { id: "333926", name: "PPM1J" },
    { id: "152926", name: "PPM1K" },
    { id: "151742", name: "PPM1L" },
    { id: "51400", name: "PPME1" },
    { id: "5498", name: "PPOX" },
    { id: "5499", name: "PPP1CA" },
    { id: "5500", name: "PPP1CB" },
    { id: "5501", name: "PPP1CC" },
    { id: "5514", name: "PPP1R10" },
    { id: "6992", name: "PPP1R11" },
    { id: "4659", name: "PPP1R12A" },
    { id: "4660", name: "PPP1R12B" },
    { id: "54776", name: "PPP1R12C" },
    { id: "23368", name: "PPP1R13B" },
    { id: "10848", name: "PPP1R13L" },
    { id: "94274", name: "PPP1R14A" },
    { id: "26472", name: "PPP1R14B" },
    { id: "81706", name: "PPP1R14C" },
    { id: "54866", name: "PPP1R14D" },
    { id: "23645", name: "PPP1R15A" },
    { id: "84919", name: "PPP1R15B" },
    { id: "84988", name: "PPP1R16A" },
    { id: "26051", name: "PPP1R16B" },
    { id: "10842", name: "PPP1R17" },
    { id: "170954", name: "PPP1R18" },
    { id: "84152", name: "PPP1R1B" },
    { id: "151242", name: "PPP1R1C" },
    { id: "5504", name: "PPP1R2" },
    { id: "129285", name: "PPP1R21" },
    { id: "9858", name: "PPP1R26" },
    { id: "116729", name: "PPP1R27" },
    { id: "153743", name: "PPP1R2P3" },
    { id: "80316", name: "PPP1R2P9" },
    { id: "220004", name: "PPP1R32" },
    { id: "221908", name: "PPP1R35" },
    { id: "145376", name: "PPP1R36" },
    { id: "284352", name: "PPP1R37" },
    { id: "5506", name: "PPP1R3A" },
    { id: "79660", name: "PPP1R3B" },
    { id: "5507", name: "PPP1R3C" },
    { id: "5509", name: "PPP1R3D" },
    { id: "90673", name: "PPP1R3E" },
    { id: "89801", name: "PPP1R3F" },
    { id: "648791", name: "PPP1R3G" },
    { id: "286187", name: "PPP1R42" },
    { id: "5510", name: "PPP1R7" },
    { id: "5511", name: "PPP1R8" },
    { id: "55607", name: "PPP1R9A" },
    { id: "84687", name: "PPP1R9B" },
    { id: "5515", name: "PPP2CA" },
    { id: "5516", name: "PPP2CB" },
    { id: "5518", name: "PPP2R1A" },
    { id: "5519", name: "PPP2R1B" },
    { id: "5520", name: "PPP2R2A" },
    { id: "5521", name: "PPP2R2B" },
    { id: "5522", name: "PPP2R2C" },
    { id: "55844", name: "PPP2R2D" },
    { id: "5523", name: "PPP2R3A" },
    { id: "28227", name: "PPP2R3B" },
    { id: "55012", name: "PPP2R3C" },
    { id: "5524", name: "PPP2R4" },
    { id: "5525", name: "PPP2R5A" },
    { id: "5526", name: "PPP2R5B" },
    { id: "5527", name: "PPP2R5C" },
    { id: "5528", name: "PPP2R5D" },
    { id: "5529", name: "PPP2R5E" },
    { id: "5530", name: "PPP3CA" },
    { id: "5532", name: "PPP3CB" },
    { id: "5533", name: "PPP3CC" },
    { id: "5534", name: "PPP3R1" },
    { id: "5535", name: "PPP3R2" },
    { id: "5531", name: "PPP4C" },
    { id: "9989", name: "PPP4R1" },
    { id: "151987", name: "PPP4R2" },
    { id: "57718", name: "PPP4R4" },
    { id: "5536", name: "PPP5C" },
    { id: "5537", name: "PPP6C" },
    { id: "22870", name: "PPP6R1" },
    { id: "9701", name: "PPP6R2" },
    { id: "55291", name: "PPP6R3" },
    { id: "23082", name: "PPRC1" },
    { id: "5538", name: "PPT1" },
    { id: "9374", name: "PPT2" },
    { id: "160760", name: "PPTC7" },
    { id: "23398", name: "PPWD1" },
    { id: "10084", name: "PQBP1" },
    { id: "80148", name: "PQLC1" },
    { id: "54896", name: "PQLC2" },
    { id: "84279", name: "PRADC1" },
    { id: "11230", name: "PRAF2" },
    { id: "84106", name: "PRAM1" },
    { id: "23532", name: "PRAME" },
    { id: "65121", name: "PRAMEF1" },
    { id: "343071", name: "PRAMEF10" },
    { id: "390999", name: "PRAMEF12" },
    { id: "729528", name: "PRAMEF14" },
    { id: "654348", name: "PRAMEF16" },
    { id: "391004", name: "PRAMEF17" },
    { id: "65122", name: "PRAMEF2" },
    { id: "118471", name: "PRAP1" },
    { id: "5542", name: "PRB1" },
    { id: "5544", name: "PRB3" },
    { id: "5545", name: "PRB4" },
    { id: "9055", name: "PRC1" },
    { id: "5546", name: "PRCC" },
    { id: "5547", name: "PRCP" },
    { id: "639", name: "PRDM1" },
    { id: "56980", name: "PRDM10" },
    { id: "59336", name: "PRDM13" },
    { id: "63978", name: "PRDM14" },
    { id: "63977", name: "PRDM15" },
    { id: "63976", name: "PRDM16" },
    { id: "7799", name: "PRDM2" },
    { id: "11108", name: "PRDM4" },
    { id: "11107", name: "PRDM5" },
    { id: "93166", name: "PRDM6" },
    { id: "11105", name: "PRDM7" },
    { id: "5052", name: "PRDX1" },
    { id: "7001", name: "PRDX2" },
    { id: "10935", name: "PRDX3" },
    { id: "10549", name: "PRDX4" },
    { id: "25824", name: "PRDX5" },
    { id: "9588", name: "PRDX6" },
    { id: "10113", name: "PREB" },
    { id: "27166", name: "PRELID1" },
    { id: "5549", name: "PRELP" },
    { id: "5550", name: "PREP" },
    { id: "9581", name: "PREPL" },
    { id: "57580", name: "PREX1" },
    { id: "80243", name: "PREX2" },
    { id: "5551", name: "PRF1" },
    { id: "5553", name: "PRG2" },
    { id: "10216", name: "PRG4" },
    { id: "144165", name: "PRICKLE1" },
    { id: "166336", name: "PRICKLE2" },
    { id: "4007", name: "PRICKLE3" },
    { id: "29964", name: "PRICKLE4" },
    { id: "5557", name: "PRIM1" },
    { id: "5558", name: "PRIM2" },
    { id: "145270", name: "PRIMA1" },
    { id: "5562", name: "PRKAA1" },
    { id: "5563", name: "PRKAA2" },
    { id: "5564", name: "PRKAB1" },
    { id: "5565", name: "PRKAB2" },
    { id: "5566", name: "PRKACA" },
    { id: "5567", name: "PRKACB" },
    { id: "5568", name: "PRKACG" },
    { id: "5571", name: "PRKAG1" },
    { id: "51422", name: "PRKAG2" },
    { id: "53632", name: "PRKAG3" },
    { id: "5573", name: "PRKAR1A" },
    { id: "5575", name: "PRKAR1B" },
    { id: "5576", name: "PRKAR2A" },
    { id: "5577", name: "PRKAR2B" },
    { id: "5578", name: "PRKCA" },
    { id: "5579", name: "PRKCB" },
    { id: "5580", name: "PRKCD" },
    { id: "112464", name: "PRKCDBP" },
    { id: "5581", name: "PRKCE" },
    { id: "5582", name: "PRKCG" },
    { id: "5583", name: "PRKCH" },
    { id: "5584", name: "PRKCI" },
    { id: "5588", name: "PRKCQ" },
    { id: "5589", name: "PRKCSH" },
    { id: "5590", name: "PRKCZ" },
    { id: "5587", name: "PRKD1" },
    { id: "25865", name: "PRKD2" },
    { id: "23683", name: "PRKD3" },
    { id: "5591", name: "PRKDC" },
    { id: "5592", name: "PRKG1" },
    { id: "5593", name: "PRKG2" },
    { id: "8575", name: "PRKRA" },
    { id: "79706", name: "PRKRIP1" },
    { id: "5612", name: "PRKRIR" },
    { id: "5613", name: "PRKX" },
    { id: "5616", name: "PRKY" },
    { id: "5617", name: "PRL" },
    { id: "51052", name: "PRLH" },
    { id: "2834", name: "PRLHR" },
    { id: "5618", name: "PRLR" },
    { id: "5619", name: "PRM1" },
    { id: "3276", name: "PRMT1" },
    { id: "90826", name: "PRMT10" },
    { id: "3275", name: "PRMT2" },
    { id: "10196", name: "PRMT3" },
    { id: "10419", name: "PRMT5" },
    { id: "55170", name: "PRMT6" },
    { id: "54496", name: "PRMT7" },
    { id: "56341", name: "PRMT8" },
    { id: "23627", name: "PRND" },
    { id: "5621", name: "PRNP" },
    { id: "5624", name: "PROC" },
    { id: "147011", name: "PROCA1" },
    { id: "10544", name: "PROCR" },
    { id: "128674", name: "PROKR2" },
    { id: "58503", name: "PROL1" },
    { id: "8842", name: "PROM1" },
    { id: "5626", name: "PROP1" },
    { id: "5627", name: "PROS1" },
    { id: "11212", name: "PROSC" },
    { id: "80209", name: "PROSER1" },
    { id: "254427", name: "PROSER2" },
    { id: "5629", name: "PROX1" },
    { id: "8858", name: "PROZ" },
    { id: "8559", name: "PRPF18" },
    { id: "27339", name: "PRPF19" },
    { id: "9129", name: "PRPF3" },
    { id: "26121", name: "PRPF31" },
    { id: "84950", name: "PRPF38A" },
    { id: "55119", name: "PRPF38B" },
    { id: "55015", name: "PRPF39" },
    { id: "9128", name: "PRPF4" },
    { id: "55660", name: "PRPF40A" },
    { id: "25766", name: "PRPF40B" },
    { id: "8899", name: "PRPF4B" },
    { id: "24148", name: "PRPF6" },
    { id: "10594", name: "PRPF8" },
    { id: "5630", name: "PRPH" },
    { id: "5961", name: "PRPH2" },
    { id: "5631", name: "PRPS1" },
    { id: "221823", name: "PRPS1L1" },
    { id: "5634", name: "PRPS2" },
    { id: "5635", name: "PRPSAP1" },
    { id: "5636", name: "PRPSAP2" },
    { id: "55771", name: "PRR11" },
    { id: "57479", name: "PRR12" },
    { id: "54458", name: "PRR13" },
    { id: "78994", name: "PRR14" },
    { id: "253143", name: "PRR14L" },
    { id: "222171", name: "PRR15" },
    { id: "51334", name: "PRR16" },
    { id: "284338", name: "PRR19" },
    { id: "122183", name: "PRR20A" },
    { id: "729240", name: "PRR20C" },
    { id: "163154", name: "PRR22" },
    { id: "389151", name: "PRR23B" },
    { id: "388199", name: "PRR25" },
    { id: "80742", name: "PRR3" },
    { id: "11272", name: "PRR4" },
    { id: "55615", name: "PRR5" },
    { id: "79899", name: "PRR5L" },
    { id: "133619", name: "PRRC1" },
    { id: "7916", name: "PRRC2A" },
    { id: "84726", name: "PRRC2B" },
    { id: "23215", name: "PRRC2C" },
    { id: "5638", name: "PRRG1" },
    { id: "5639", name: "PRRG2" },
    { id: "79056", name: "PRRG4" },
    { id: "80863", name: "PRRT1" },
    { id: "112476", name: "PRRT2" },
    { id: "285368", name: "PRRT3" },
    { id: "401399", name: "PRRT4" },
    { id: "5396", name: "PRRX1" },
    { id: "51450", name: "PRRX2" },
    { id: "5644", name: "PRSS1" },
    { id: "5645", name: "PRSS2" },
    { id: "10942", name: "PRSS21" },
    { id: "11098", name: "PRSS23" },
    { id: "5646", name: "PRSS3" },
    { id: "260429", name: "PRSS33" },
    { id: "339501", name: "PRSS38" },
    { id: "339906", name: "PRSS42" },
    { id: "29122", name: "PRSS50" },
    { id: "646960", name: "PRSS56" },
    { id: "56952", name: "PRTFDC1" },
    { id: "283659", name: "PRTG" },
    { id: "5657", name: "PRTN3" },
    { id: "58497", name: "PRUNE" },
    { id: "158471", name: "PRUNE2" },
    { id: "57716", name: "PRX" },
    { id: "5660", name: "PSAP" },
    { id: "29968", name: "PSAT1" },
    { id: "8000", name: "PSCA" },
    { id: "5662", name: "PSD" },
    { id: "84249", name: "PSD2" },
    { id: "23362", name: "PSD3" },
    { id: "23550", name: "PSD4" },
    { id: "5663", name: "PSEN1" },
    { id: "5664", name: "PSEN2" },
    { id: "55851", name: "PSENEN" },
    { id: "5680", name: "PSG11" },
    { id: "5670", name: "PSG2" },
    { id: "5678", name: "PSG9" },
    { id: "11168", name: "PSIP1" },
    { id: "5681", name: "PSKH1" },
    { id: "85481", name: "PSKH2" },
    { id: "5682", name: "PSMA1" },
    { id: "5683", name: "PSMA2" },
    { id: "5684", name: "PSMA3" },
    { id: "5685", name: "PSMA4" },
    { id: "5686", name: "PSMA5" },
    { id: "5687", name: "PSMA6" },
    { id: "5688", name: "PSMA7" },
    { id: "143471", name: "PSMA8" },
    { id: "5689", name: "PSMB1" },
    { id: "5699", name: "PSMB10" },
    { id: "5690", name: "PSMB2" },
    { id: "5691", name: "PSMB3" },
    { id: "5692", name: "PSMB4" },
    { id: "5693", name: "PSMB5" },
    { id: "5694", name: "PSMB6" },
    { id: "5695", name: "PSMB7" },
    { id: "5696", name: "PSMB8" },
    { id: "5698", name: "PSMB9" },
    { id: "5700", name: "PSMC1" },
    { id: "5701", name: "PSMC2" },
    { id: "5702", name: "PSMC3" },
    { id: "29893", name: "PSMC3IP" },
    { id: "5704", name: "PSMC4" },
    { id: "5705", name: "PSMC5" },
    { id: "5706", name: "PSMC6" },
    { id: "5707", name: "PSMD1" },
    { id: "5716", name: "PSMD10" },
    { id: "5717", name: "PSMD11" },
    { id: "5718", name: "PSMD12" },
    { id: "5719", name: "PSMD13" },
    { id: "10213", name: "PSMD14" },
    { id: "5708", name: "PSMD2" },
    { id: "5709", name: "PSMD3" },
    { id: "5710", name: "PSMD4" },
    { id: "5711", name: "PSMD5" },
    { id: "9861", name: "PSMD6" },
    { id: "5713", name: "PSMD7" },
    { id: "5714", name: "PSMD8" },
    { id: "5715", name: "PSMD9" },
    { id: "5720", name: "PSME1" },
    { id: "5721", name: "PSME2" },
    { id: "10197", name: "PSME3" },
    { id: "23198", name: "PSME4" },
    { id: "9491", name: "PSMF1" },
    { id: "8624", name: "PSMG1" },
    { id: "56984", name: "PSMG2" },
    { id: "84262", name: "PSMG3" },
    { id: "389362", name: "PSMG4" },
    { id: "170679", name: "PSORS1C1" },
    { id: "170680", name: "PSORS1C2" },
    { id: "55269", name: "PSPC1" },
    { id: "5723", name: "PSPH" },
    { id: "84722", name: "PSRC1" },
    { id: "118672", name: "PSTK" },
    { id: "9051", name: "PSTPIP1" },
    { id: "9050", name: "PSTPIP2" },
    { id: "5724", name: "PTAFR" },
    { id: "375743", name: "PTAR1" },
    { id: "5725", name: "PTBP1" },
    { id: "58155", name: "PTBP2" },
    { id: "9991", name: "PTBP3" },
    { id: "26024", name: "PTCD1" },
    { id: "79810", name: "PTCD2" },
    { id: "55037", name: "PTCD3" },
    { id: "5727", name: "PTCH1" },
    { id: "8643", name: "PTCH2" },
    { id: "57540", name: "PTCHD2" },
    { id: "374308", name: "PTCHD3" },
    { id: "171558", name: "PTCRA" },
    { id: "9791", name: "PTDSS1" },
    { id: "81490", name: "PTDSS2" },
    { id: "5728", name: "PTEN" },
    { id: "9317", name: "PTER" },
    { id: "256297", name: "PTF1A" },
    { id: "5729", name: "PTGDR" },
    { id: "11251", name: "PTGDR2" },
    { id: "5730", name: "PTGDS" },
    { id: "5731", name: "PTGER1" },
    { id: "5732", name: "PTGER2" },
    { id: "5733", name: "PTGER3" },
    { id: "5734", name: "PTGER4" },
    { id: "9536", name: "PTGES" },
    { id: "80142", name: "PTGES2" },
    { id: "10728", name: "PTGES3" },
    { id: "5737", name: "PTGFR" },
    { id: "5738", name: "PTGFRN" },
    { id: "5739", name: "PTGIR" },
    { id: "5740", name: "PTGIS" },
    { id: "22949", name: "PTGR1" },
    { id: "145482", name: "PTGR2" },
    { id: "5742", name: "PTGS1" },
    { id: "5743", name: "PTGS2" },
    { id: "5741", name: "PTH" },
    { id: "5745", name: "PTH1R" },
    { id: "113091", name: "PTH2" },
    { id: "5746", name: "PTH2R" },
    { id: "5744", name: "PTHLH" },
    { id: "5747", name: "PTK2" },
    { id: "2185", name: "PTK2B" },
    { id: "5753", name: "PTK6" },
    { id: "5754", name: "PTK7" },
    { id: "5757", name: "PTMA" },
    { id: "5763", name: "PTMS" },
    { id: "5764", name: "PTN" },
    { id: "53635", name: "PTOV1" },
    { id: "7803", name: "PTP4A1" },
    { id: "8073", name: "PTP4A2" },
    { id: "11156", name: "PTP4A3" },
    { id: "138639", name: "PTPDC1" },
    { id: "51495", name: "PTPLAD1" },
    { id: "201562", name: "PTPLB" },
    { id: "114971", name: "PTPMT1" },
    { id: "5770", name: "PTPN1" },
    { id: "5781", name: "PTPN11" },
    { id: "5782", name: "PTPN12" },
    { id: "5783", name: "PTPN13" },
    { id: "5784", name: "PTPN14" },
    { id: "26469", name: "PTPN18" },
    { id: "5771", name: "PTPN2" },
    { id: "11099", name: "PTPN21" },
    { id: "26191", name: "PTPN22" },
    { id: "25930", name: "PTPN23" },
    { id: "5774", name: "PTPN3" },
    { id: "5775", name: "PTPN4" },
    { id: "84867", name: "PTPN5" },
    { id: "5777", name: "PTPN6" },
    { id: "5778", name: "PTPN7" },
    { id: "5780", name: "PTPN9" },
    { id: "5786", name: "PTPRA" },
    { id: "5787", name: "PTPRB" },
    { id: "5788", name: "PTPRC" },
    { id: "5790", name: "PTPRCAP" },
    { id: "5789", name: "PTPRD" },
    { id: "5791", name: "PTPRE" },
    { id: "5792", name: "PTPRF" },
    { id: "5793", name: "PTPRG" },
    { id: "5794", name: "PTPRH" },
    { id: "5795", name: "PTPRJ" },
    { id: "5796", name: "PTPRK" },
    { id: "5797", name: "PTPRM" },
    { id: "5798", name: "PTPRN" },
    { id: "5799", name: "PTPRN2" },
    { id: "5800", name: "PTPRO" },
    { id: "5801", name: "PTPRR" },
    { id: "5802", name: "PTPRS" },
    { id: "11122", name: "PTPRT" },
    { id: "10076", name: "PTPRU" },
    { id: "5803", name: "PTPRZ1" },
    { id: "284119", name: "PTRF" },
    { id: "138428", name: "PTRH1" },
    { id: "51651", name: "PTRH2" },
    { id: "391356", name: "PTRHD1" },
    { id: "5805", name: "PTS" },
    { id: "9232", name: "PTTG1" },
    { id: "754", name: "PTTG1IP" },
    { id: "10744", name: "PTTG2" },
    { id: "5806", name: "PTX3" },
    { id: "22827", name: "PUF60" },
    { id: "9698", name: "PUM1" },
    { id: "23369", name: "PUM2" },
    { id: "5813", name: "PURA" },
    { id: "5814", name: "PURB" },
    { id: "29942", name: "PURG" },
    { id: "80324", name: "PUS1" },
    { id: "150962", name: "PUS10" },
    { id: "83480", name: "PUS3" },
    { id: "54517", name: "PUS7" },
    { id: "83448", name: "PUS7L" },
    { id: "126789", name: "PUSL1" },
    { id: "5817", name: "PVR" },
    { id: "5818", name: "PVRL1" },
    { id: "5819", name: "PVRL2" },
    { id: "25945", name: "PVRL3" },
    { id: "81607", name: "PVRL4" },
    { id: "11137", name: "PWP1" },
    { id: "5822", name: "PWP2" },
    { id: "114825", name: "PWWP2A" },
    { id: "170394", name: "PWWP2B" },
    { id: "221749", name: "PXDC1" },
    { id: "7837", name: "PXDN" },
    { id: "137902", name: "PXDNL" },
    { id: "54899", name: "PXK" },
    { id: "5827", name: "PXMP2" },
    { id: "11264", name: "PXMP4" },
    { id: "5829", name: "PXN" },
    { id: "222659", name: "PXT1" },
    { id: "29108", name: "PYCARD" },
    { id: "5831", name: "PYCR1" },
    { id: "29920", name: "PYCR2" },
    { id: "65263", name: "PYCRL" },
    { id: "260434", name: "PYDC1" },
    { id: "152138", name: "PYDC2" },
    { id: "5834", name: "PYGB" },
    { id: "5836", name: "PYGL" },
    { id: "5837", name: "PYGM" },
    { id: "26108", name: "PYGO1" },
    { id: "90780", name: "PYGO2" },
    { id: "149628", name: "PYHIN1" },
    { id: "79912", name: "PYROXD1" },
    { id: "84795", name: "PYROXD2" },
    { id: "5697", name: "PYY" },
    { id: "5858", name: "PZP" },
    { id: "5859", name: "QARS" },
    { id: "5860", name: "QDPR" },
    { id: "9444", name: "QKI" },
    { id: "25797", name: "QPCT" },
    { id: "54814", name: "QPCTL" },
    { id: "23475", name: "QPRT" },
    { id: "84109", name: "QRFPR" },
    { id: "54870", name: "QRICH1" },
    { id: "84074", name: "QRICH2" },
    { id: "55278", name: "QRSL1" },
    { id: "79832", name: "QSER1" },
    { id: "169714", name: "QSOX2" },
    { id: "81890", name: "QTRT1" },
    { id: "79691", name: "QTRTD1" },
    { id: "203069", name: "R3HCC1" },
    { id: "27291", name: "R3HCC1L" },
    { id: "23518", name: "R3HDM1" },
    { id: "22864", name: "R3HDM2" },
    { id: "91300", name: "R3HDM4" },
    { id: "140902", name: "R3HDML" },
    { id: "10890", name: "RAB10" },
    { id: "8766", name: "RAB11A" },
    { id: "9230", name: "RAB11B" },
    { id: "80223", name: "RAB11FIP1" },
    { id: "22841", name: "RAB11FIP2" },
    { id: "9727", name: "RAB11FIP3" },
    { id: "84440", name: "RAB11FIP4" },
    { id: "26056", name: "RAB11FIP5" },
    { id: "201475", name: "RAB12" },
    { id: "5872", name: "RAB13" },
    { id: "51552", name: "RAB14" },
    { id: "376267", name: "RAB15" },
    { id: "64284", name: "RAB17" },
    { id: "22931", name: "RAB18" },
    { id: "401409", name: "RAB19" },
    { id: "5861", name: "RAB1A" },
    { id: "81876", name: "RAB1B" },
    { id: "441400", name: "RAB1C" },
    { id: "55647", name: "RAB20" },
    { id: "23011", name: "RAB21" },
    { id: "57403", name: "RAB22A" },
    { id: "51715", name: "RAB23" },
    { id: "53917", name: "RAB24" },
    { id: "57111", name: "RAB25" },
    { id: "25837", name: "RAB26" },
    { id: "5873", name: "RAB27A" },
    { id: "5874", name: "RAB27B" },
    { id: "9364", name: "RAB28" },
    { id: "5862", name: "RAB2A" },
    { id: "84932", name: "RAB2B" },
    { id: "27314", name: "RAB30" },
    { id: "11031", name: "RAB31" },
    { id: "10981", name: "RAB32" },
    { id: "9363", name: "RAB33A" },
    { id: "83452", name: "RAB33B" },
    { id: "83871", name: "RAB34" },
    { id: "11021", name: "RAB35" },
    { id: "9609", name: "RAB36" },
    { id: "326624", name: "RAB37" },
    { id: "23682", name: "RAB38" },
    { id: "54734", name: "RAB39A" },
    { id: "116442", name: "RAB39B" },
    { id: "5864", name: "RAB3A" },
    { id: "5865", name: "RAB3B" },
    { id: "115827", name: "RAB3C" },
    { id: "9545", name: "RAB3D" },
    { id: "22930", name: "RAB3GAP1" },
    { id: "25782", name: "RAB3GAP2" },
    { id: "5866", name: "RAB3IL1" },
    { id: "117177", name: "RAB3IP" },
    { id: "142684", name: "RAB40A" },
    { id: "282808", name: "RAB40AL" },
    { id: "10966", name: "RAB40B" },
    { id: "57799", name: "RAB40C" },
    { id: "347517", name: "RAB41" },
    { id: "115273", name: "RAB42" },
    { id: "339122", name: "RAB43" },
    { id: "5867", name: "RAB4A" },
    { id: "53916", name: "RAB4B" },
    { id: "5868", name: "RAB5A" },
    { id: "5869", name: "RAB5B" },
    { id: "5878", name: "RAB5C" },
    { id: "5870", name: "RAB6A" },
    { id: "51560", name: "RAB6B" },
    { id: "84084", name: "RAB6C" },
    { id: "7879", name: "RAB7A" },
    { id: "338382", name: "RAB7B" },
    { id: "8934", name: "RAB7L1" },
    { id: "4218", name: "RAB8A" },
    { id: "51762", name: "RAB8B" },
    { id: "9367", name: "RAB9A" },
    { id: "51209", name: "RAB9B" },
    { id: "10567", name: "RABAC1" },
    { id: "9135", name: "RABEP1" },
    { id: "79874", name: "RABEP2" },
    { id: "10244", name: "RABEPK" },
    { id: "23637", name: "RABGAP1" },
    { id: "9910", name: "RABGAP1L" },
    { id: "27342", name: "RABGEF1" },
    { id: "5875", name: "RABGGTA" },
    { id: "5876", name: "RABGGTB" },
    { id: "5877", name: "RABIF" },
    { id: "11159", name: "RABL2A" },
    { id: "11158", name: "RABL2B" },
    { id: "285282", name: "RABL3" },
    { id: "64792", name: "RABL5" },
    { id: "55684", name: "RABL6" },
    { id: "5879", name: "RAC1" },
    { id: "5880", name: "RAC2" },
    { id: "5881", name: "RAC3" },
    { id: "29127", name: "RACGAP1" },
    { id: "5810", name: "RAD1" },
    { id: "5884", name: "RAD17" },
    { id: "56852", name: "RAD18" },
    { id: "5885", name: "RAD21" },
    { id: "642636", name: "RAD21L1" },
    { id: "5886", name: "RAD23A" },
    { id: "5887", name: "RAD23B" },
    { id: "10111", name: "RAD50" },
    { id: "5888", name: "RAD51" },
    { id: "10635", name: "RAD51AP1" },
    { id: "729475", name: "RAD51AP2" },
    { id: "5890", name: "RAD51B" },
    { id: "5889", name: "RAD51C" },
    { id: "5892", name: "RAD51D" },
    { id: "5893", name: "RAD52" },
    { id: "25788", name: "RAD54B" },
    { id: "8438", name: "RAD54L" },
    { id: "23132", name: "RAD54L2" },
    { id: "5883", name: "RAD9A" },
    { id: "144715", name: "RAD9B" },
    { id: "55698", name: "RADIL" },
    { id: "8480", name: "RAE1" },
    { id: "135250", name: "RAET1E" },
    { id: "353091", name: "RAET1G" },
    { id: "5894", name: "RAF1" },
    { id: "5896", name: "RAG1" },
    { id: "5897", name: "RAG2" },
    { id: "10743", name: "RAI1" },
    { id: "26064", name: "RAI14" },
    { id: "10742", name: "RAI2" },
    { id: "5898", name: "RALA" },
    { id: "5899", name: "RALB" },
    { id: "10928", name: "RALBP1" },
    { id: "253959", name: "RALGAPA1" },
    { id: "57186", name: "RALGAPA2" },
    { id: "57148", name: "RALGAPB" },
    { id: "5900", name: "RALGDS" },
    { id: "9649", name: "RALGPS1" },
    { id: "55103", name: "RALGPS2" },
    { id: "22913", name: "RALY" },
    { id: "138046", name: "RALYL" },
    { id: "10267", name: "RAMP1" },
    { id: "10266", name: "RAMP2" },
    { id: "10268", name: "RAMP3" },
    { id: "5901", name: "RAN" },
    { id: "5902", name: "RANBP1" },
    { id: "57610", name: "RANBP10" },
    { id: "64901", name: "RANBP17" },
    { id: "5903", name: "RANBP2" },
    { id: "8498", name: "RANBP3" },
    { id: "202151", name: "RANBP3L" },
    { id: "26953", name: "RANBP6" },
    { id: "10048", name: "RANBP9" },
    { id: "5905", name: "RANGAP1" },
    { id: "29098", name: "RANGRF" },
    { id: "5906", name: "RAP1A" },
    { id: "5908", name: "RAP1B" },
    { id: "5909", name: "RAP1GAP" },
    { id: "23108", name: "RAP1GAP2" },
    { id: "5910", name: "RAP1GDS1" },
    { id: "5911", name: "RAP2A" },
    { id: "5912", name: "RAP2B" },
    { id: "57826", name: "RAP2C" },
    { id: "2889", name: "RAPGEF1" },
    { id: "9693", name: "RAPGEF2" },
    { id: "10411", name: "RAPGEF3" },
    { id: "11069", name: "RAPGEF4" },
    { id: "9771", name: "RAPGEF5" },
    { id: "51735", name: "RAPGEF6" },
    { id: "51195", name: "RAPGEFL1" },
    { id: "65059", name: "RAPH1" },
    { id: "5913", name: "RAPSN" },
    { id: "5914", name: "RARA" },
    { id: "5915", name: "RARB" },
    { id: "5916", name: "RARG" },
    { id: "5920", name: "RARRES3" },
    { id: "5917", name: "RARS" },
    { id: "57038", name: "RARS2" },
    { id: "5921", name: "RASA1" },
    { id: "5922", name: "RASA2" },
    { id: "22821", name: "RASA3" },
    { id: "10156", name: "RASA4" },
    { id: "100271927", name: "RASA4B" },
    { id: "8437", name: "RASAL1" },
    { id: "9462", name: "RASAL2" },
    { id: "64926", name: "RASAL3" },
    { id: "51655", name: "RASD1" },
    { id: "23551", name: "RASD2" },
    { id: "221002", name: "RASGEF1A" },
    { id: "153020", name: "RASGEF1B" },
    { id: "255426", name: "RASGEF1C" },
    { id: "5923", name: "RASGRF1" },
    { id: "5924", name: "RASGRF2" },
    { id: "10125", name: "RASGRP1" },
    { id: "10235", name: "RASGRP2" },
    { id: "25780", name: "RASGRP3" },
    { id: "115727", name: "RASGRP4" },
    { id: "54922", name: "RASIP1" },
    { id: "91608", name: "RASL10B" },
    { id: "65997", name: "RASL11B" },
    { id: "51285", name: "RASL12" },
    { id: "11186", name: "RASSF1" },
    { id: "644943", name: "RASSF10" },
    { id: "9770", name: "RASSF2" },
    { id: "283349", name: "RASSF3" },
    { id: "83937", name: "RASSF4" },
    { id: "83593", name: "RASSF5" },
    { id: "166824", name: "RASSF6" },
    { id: "8045", name: "RASSF7" },
    { id: "11228", name: "RASSF8" },
    { id: "9182", name: "RASSF9" },
    { id: "125950", name: "RAVER1" },
    { id: "55225", name: "RAVER2" },
    { id: "30062", name: "RAX" },
    { id: "84839", name: "RAX2" },
    { id: "5925", name: "RB1" },
    { id: "9821", name: "RB1CC1" },
    { id: "57786", name: "RBAK" },
    { id: "5928", name: "RBBP4" },
    { id: "5929", name: "RBBP5" },
    { id: "5930", name: "RBBP6" },
    { id: "5931", name: "RBBP7" },
    { id: "5932", name: "RBBP8" },
    { id: "140893", name: "RBBP8NL" },
    { id: "10741", name: "RBBP9" },
    { id: "10616", name: "RBCK1" },
    { id: "79863", name: "RBFA" },
    { id: "54715", name: "RBFOX1" },
    { id: "23543", name: "RBFOX2" },
    { id: "64080", name: "RBKS" },
    { id: "5933", name: "RBL1" },
    { id: "5934", name: "RBL2" },
    { id: "8241", name: "RBM10" },
    { id: "54033", name: "RBM11" },
    { id: "10137", name: "RBM12" },
    { id: "389677", name: "RBM12B" },
    { id: "10432", name: "RBM14" },
    { id: "64783", name: "RBM15" },
    { id: "29890", name: "RBM15B" },
    { id: "84991", name: "RBM17" },
    { id: "92400", name: "RBM18" },
    { id: "9904", name: "RBM19" },
    { id: "282996", name: "RBM20" },
    { id: "55696", name: "RBM22" },
    { id: "55147", name: "RBM23" },
    { id: "221662", name: "RBM24" },
    { id: "58517", name: "RBM25" },
    { id: "64062", name: "RBM26" },
    { id: "54439", name: "RBM27" },
    { id: "55131", name: "RBM28" },
    { id: "5935", name: "RBM3" },
    { id: "155435", name: "RBM33" },
    { id: "23029", name: "RBM34" },
    { id: "55544", name: "RBM38" },
    { id: "9584", name: "RBM39" },
    { id: "5936", name: "RBM4" },
    { id: "55285", name: "RBM41" },
    { id: "79171", name: "RBM42" },
    { id: "375287", name: "RBM43" },
    { id: "129831", name: "RBM45" },
    { id: "166863", name: "RBM46" },
    { id: "54502", name: "RBM47" },
    { id: "84060", name: "RBM48" },
    { id: "83759", name: "RBM4B" },
    { id: "10181", name: "RBM5" },
    { id: "10180", name: "RBM6" },
    { id: "10179", name: "RBM7" },
    { id: "9939", name: "RBM8A" },
    { id: "5937", name: "RBMS1" },
    { id: "5939", name: "RBMS2" },
    { id: "27316", name: "RBMX" },
    { id: "51634", name: "RBMX2" },
    { id: "494115", name: "RBMXL1" },
    { id: "27288", name: "RBMXL2" },
    { id: "139804", name: "RBMXL3" },
    { id: "5940", name: "RBMY1A1" },
    { id: "378950", name: "RBMY1E" },
    { id: "159163", name: "RBMY1F" },
    { id: "378951", name: "RBMY1J" },
    { id: "5947", name: "RBP1" },
    { id: "5948", name: "RBP2" },
    { id: "5949", name: "RBP3" },
    { id: "5950", name: "RBP4" },
    { id: "83758", name: "RBP5" },
    { id: "116362", name: "RBP7" },
    { id: "3516", name: "RBPJ" },
    { id: "11317", name: "RBPJL" },
    { id: "11030", name: "RBPMS" },
    { id: "348093", name: "RBPMS2" },
    { id: "9978", name: "RBX1" },
    { id: "149041", name: "RC3H1" },
    { id: "54542", name: "RC3H2" },
    { id: "1827", name: "RCAN1" },
    { id: "10231", name: "RCAN2" },
    { id: "11123", name: "RCAN3" },
    { id: "55213", name: "RCBTB1" },
    { id: "1102", name: "RCBTB2" },
    { id: "1104", name: "RCC1" },
    { id: "55920", name: "RCC2" },
    { id: "91433", name: "RCCD1" },
    { id: "9986", name: "RCE1" },
    { id: "25898", name: "RCHY1" },
    { id: "10171", name: "RCL1" },
    { id: "5954", name: "RCN1" },
    { id: "5955", name: "RCN2" },
    { id: "57333", name: "RCN3" },
    { id: "23186", name: "RCOR1" },
    { id: "283248", name: "RCOR2" },
    { id: "55758", name: "RCOR3" },
    { id: "92241", name: "RCSD1" },
    { id: "5957", name: "RCVRN" },
    { id: "343035", name: "RD3" },
    { id: "647286", name: "RD3L" },
    { id: "157506", name: "RDH10" },
    { id: "51109", name: "RDH11" },
    { id: "145226", name: "RDH12" },
    { id: "112724", name: "RDH13" },
    { id: "57665", name: "RDH14" },
    { id: "5959", name: "RDH5" },
    { id: "201299", name: "RDM1" },
    { id: "5962", name: "RDX" },
    { id: "9985", name: "REC8" },
    { id: "8434", name: "RECK" },
    { id: "5965", name: "RECQL" },
    { id: "9401", name: "RECQL4" },
    { id: "9400", name: "RECQL5" },
    { id: "65055", name: "REEP1" },
    { id: "51308", name: "REEP2" },
    { id: "221035", name: "REEP3" },
    { id: "80346", name: "REEP4" },
    { id: "7905", name: "REEP5" },
    { id: "92840", name: "REEP6" },
    { id: "5967", name: "REG1A" },
    { id: "5068", name: "REG3A" },
    { id: "5966", name: "REL" },
    { id: "5970", name: "RELA" },
    { id: "5971", name: "RELB" },
    { id: "768211", name: "RELL1" },
    { id: "285613", name: "RELL2" },
    { id: "5649", name: "RELN" },
    { id: "84957", name: "RELT" },
    { id: "28954", name: "REM1" },
    { id: "161253", name: "REM2" },
    { id: "5972", name: "REN" },
    { id: "5973", name: "RENBP" },
    { id: "387849", name: "REP15" },
    { id: "29803", name: "REPIN1" },
    { id: "85021", name: "REPS1" },
    { id: "9185", name: "REPS2" },
    { id: "11079", name: "RER1" },
    { id: "5978", name: "REST" },
    { id: "5979", name: "RET" },
    { id: "56729", name: "RETN" },
    { id: "54884", name: "RETSAT" },
    { id: "51455", name: "REV1" },
    { id: "5980", name: "REV3L" },
    { id: "57455", name: "REXO1" },
    { id: "254958", name: "REXO1L1" },
    { id: "25996", name: "REXO2" },
    { id: "57109", name: "REXO4" },
    { id: "5981", name: "RFC1" },
    { id: "5982", name: "RFC2" },
    { id: "5983", name: "RFC3" },
    { id: "5984", name: "RFC4" },
    { id: "5985", name: "RFC5" },
    { id: "317671", name: "RFESD" },
    { id: "117584", name: "RFFL" },
    { id: "55312", name: "RFK" },
    { id: "10739", name: "RFPL2" },
    { id: "10738", name: "RFPL3" },
    { id: "342931", name: "RFPL4A" },
    { id: "442247", name: "RFPL4B" },
    { id: "91869", name: "RFT1" },
    { id: "23180", name: "RFTN1" },
    { id: "130132", name: "RFTN2" },
    { id: "64326", name: "RFWD2" },
    { id: "55159", name: "RFWD3" },
    { id: "5989", name: "RFX1" },
    { id: "5990", name: "RFX2" },
    { id: "5991", name: "RFX3" },
    { id: "5992", name: "RFX4" },
    { id: "5993", name: "RFX5" },
    { id: "222546", name: "RFX6" },
    { id: "64864", name: "RFX7" },
    { id: "8625", name: "RFXANK" },
    { id: "5994", name: "RFXAP" },
    { id: "57529", name: "RGAG1" },
    { id: "340526", name: "RGAG4" },
    { id: "28984", name: "RGCC" },
    { id: "23179", name: "RGL1" },
    { id: "5863", name: "RGL2" },
    { id: "57139", name: "RGL3" },
    { id: "266747", name: "RGL4" },
    { id: "56963", name: "RGMA" },
    { id: "9104", name: "RGN" },
    { id: "9827", name: "RGP1" },
    { id: "400966", name: "RGPD1" },
    { id: "729857", name: "RGPD2" },
    { id: "653489", name: "RGPD3" },
    { id: "285190", name: "RGPD4" },
    { id: "84220", name: "RGPD5" },
    { id: "729540", name: "RGPD6" },
    { id: "727851", name: "RGPD8" },
    { id: "5995", name: "RGR" },
    { id: "5996", name: "RGS1" },
    { id: "6001", name: "RGS10" },
    { id: "8786", name: "RGS11" },
    { id: "6002", name: "RGS12" },
    { id: "6003", name: "RGS13" },
    { id: "10636", name: "RGS14" },
    { id: "6004", name: "RGS16" },
    { id: "26575", name: "RGS17" },
    { id: "64407", name: "RGS18" },
    { id: "10287", name: "RGS19" },
    { id: "5997", name: "RGS2" },
    { id: "8601", name: "RGS20" },
    { id: "26166", name: "RGS22" },
    { id: "5998", name: "RGS3" },
    { id: "5999", name: "RGS4" },
    { id: "8490", name: "RGS5" },
    { id: "9628", name: "RGS6" },
    { id: "6000", name: "RGS7" },
    { id: "401190", name: "RGS7BP" },
    { id: "85397", name: "RGS8" },
    { id: "8787", name: "RGS9" },
    { id: "388531", name: "RGS9BP" },
    { id: "353299", name: "RGSL1" },
    { id: "6005", name: "RHAG" },
    { id: "84236", name: "RHBDD1" },
    { id: "57414", name: "RHBDD2" },
    { id: "25807", name: "RHBDD3" },
    { id: "64285", name: "RHBDF1" },
    { id: "79651", name: "RHBDF2" },
    { id: "9028", name: "RHBDL1" },
    { id: "54933", name: "RHBDL2" },
    { id: "162494", name: "RHBDL3" },
    { id: "57127", name: "RHBG" },
    { id: "6007", name: "RHD" },
    { id: "6009", name: "RHEB" },
    { id: "121268", name: "RHEBL1" },
    { id: "83695", name: "RHNO1" },
    { id: "6010", name: "RHO" },
    { id: "387", name: "RHOA" },
    { id: "9886", name: "RHOBTB1" },
    { id: "23221", name: "RHOBTB2" },
    { id: "22836", name: "RHOBTB3" },
    { id: "29984", name: "RHOD" },
    { id: "54509", name: "RHOF" },
    { id: "399", name: "RHOH" },
    { id: "57381", name: "RHOJ" },
    { id: "23433", name: "RHOQ" },
    { id: "55288", name: "RHOT1" },
    { id: "89941", name: "RHOT2" },
    { id: "58480", name: "RHOU" },
    { id: "171177", name: "RHOV" },
    { id: "158800", name: "RHOXF1" },
    { id: "84528", name: "RHOXF2" },
    { id: "114822", name: "RHPN1" },
    { id: "78998", name: "RHPN1-AS1" },
    { id: "85415", name: "RHPN2" },
    { id: "158787", name: "RIBC1" },
    { id: "26150", name: "RIBC2" },
    { id: "79608", name: "RIC3" },
    { id: "60626", name: "RIC8A" },
    { id: "55188", name: "RIC8B" },
    { id: "253260", name: "RICTOR" },
    { id: "55183", name: "RIF1" },
    { id: "83547", name: "RILP" },
    { id: "353116", name: "RILPL1" },
    { id: "196383", name: "RILPL2" },
    { id: "23504", name: "RIMBP2" },
    { id: "85376", name: "RIMBP3" },
    { id: "150221", name: "RIMBP3C" },
    { id: "284716", name: "RIMKLA" },
    { id: "57494", name: "RIMKLB" },
    { id: "22999", name: "RIMS1" },
    { id: "9699", name: "RIMS2" },
    { id: "9783", name: "RIMS3" },
    { id: "140730", name: "RIMS4" },
    { id: "9610", name: "RIN1" },
    { id: "54453", name: "RIN2" },
    { id: "79890", name: "RIN3" },
    { id: "6015", name: "RING1" },
    { id: "60561", name: "RINT1" },
    { id: "83732", name: "RIOK1" },
    { id: "55781", name: "RIOK2" },
    { id: "8780", name: "RIOK3" },
    { id: "8737", name: "RIPK1" },
    { id: "8767", name: "RIPK2" },
    { id: "11035", name: "RIPK3" },
    { id: "54101", name: "RIPK4" },
    { id: "92129", name: "RIPPLY1" },
    { id: "134701", name: "RIPPLY2" },
    { id: "53820", name: "RIPPLY3" },
    { id: "6016", name: "RIT1" },
    { id: "6014", name: "RIT2" },
    { id: "6017", name: "RLBP1" },
    { id: "6018", name: "RLF" },
    { id: "51132", name: "RLIM" },
    { id: "117579", name: "RLN3" },
    { id: "146206", name: "RLTPR" },
    { id: "51115", name: "RMDN1" },
    { id: "151393", name: "RMDN2" },
    { id: "55177", name: "RMDN3" },
    { id: "80010", name: "RMI1" },
    { id: "116028", name: "RMI2" },
    { id: "55005", name: "RMND1" },
    { id: "64795", name: "RMND5A" },
    { id: "64777", name: "RMND5B" },
    { id: "6035", name: "RNASE1" },
    { id: "338879", name: "RNASE10" },
    { id: "122651", name: "RNASE11" },
    { id: "6036", name: "RNASE2" },
    { id: "6037", name: "RNASE3" },
    { id: "6039", name: "RNASE6" },
    { id: "84659", name: "RNASE7" },
    { id: "246243", name: "RNASEH1" },
    { id: "10535", name: "RNASEH2A" },
    { id: "79621", name: "RNASEH2B" },
    { id: "84153", name: "RNASEH2C" },
    { id: "440400", name: "RNASEK" },
    { id: "6041", name: "RNASEL" },
    { id: "8635", name: "RNASET2" },
    { id: "27289", name: "RND1" },
    { id: "8153", name: "RND2" },
    { id: "9921", name: "RNF10" },
    { id: "7844", name: "RNF103" },
    { id: "26994", name: "RNF11" },
    { id: "54778", name: "RNF111" },
    { id: "7732", name: "RNF112" },
    { id: "7737", name: "RNF113A" },
    { id: "140432", name: "RNF113B" },
    { id: "55905", name: "RNF114" },
    { id: "27246", name: "RNF115" },
    { id: "79845", name: "RNF122" },
    { id: "63891", name: "RNF123" },
    { id: "54941", name: "RNF125" },
    { id: "55658", name: "RNF126" },
    { id: "79589", name: "RNF128" },
    { id: "11342", name: "RNF13" },
    { id: "55819", name: "RNF130" },
    { id: "168433", name: "RNF133" },
    { id: "84282", name: "RNF135" },
    { id: "51444", name: "RNF138" },
    { id: "11236", name: "RNF139" },
    { id: "9604", name: "RNF14" },
    { id: "50862", name: "RNF141" },
    { id: "9781", name: "RNF144A" },
    { id: "255488", name: "RNF144B" },
    { id: "153830", name: "RNF145" },
    { id: "81847", name: "RNF146" },
    { id: "284996", name: "RNF149" },
    { id: "57484", name: "RNF150" },
    { id: "146310", name: "RNF151" },
    { id: "220441", name: "RNF152" },
    { id: "114804", name: "RNF157" },
    { id: "494470", name: "RNF165" },
    { id: "115992", name: "RNF166" },
    { id: "26001", name: "RNF167" },
    { id: "165918", name: "RNF168" },
    { id: "254225", name: "RNF169" },
    { id: "56163", name: "RNF17" },
    { id: "81790", name: "RNF170" },
    { id: "285671", name: "RNF180" },
    { id: "51255", name: "RNF181" },
    { id: "221687", name: "RNF182" },
    { id: "138065", name: "RNF183" },
    { id: "91445", name: "RNF185" },
    { id: "54546", name: "RNF186" },
    { id: "149603", name: "RNF187" },
    { id: "25897", name: "RNF19A" },
    { id: "127544", name: "RNF19B" },
    { id: "6045", name: "RNF2" },
    { id: "56254", name: "RNF20" },
    { id: "727800", name: "RNF208" },
    { id: "285498", name: "RNF212" },
    { id: "57674", name: "RNF213" },
    { id: "257160", name: "RNF214" },
    { id: "54476", name: "RNF216" },
    { id: "154214", name: "RNF217" },
    { id: "79596", name: "RNF219" },
    { id: "55182", name: "RNF220" },
    { id: "643904", name: "RNF222" },
    { id: "11237", name: "RNF24" },
    { id: "64320", name: "RNF25" },
    { id: "79102", name: "RNF26" },
    { id: "55072", name: "RNF31" },
    { id: "140545", name: "RNF32" },
    { id: "80196", name: "RNF34" },
    { id: "152006", name: "RNF38" },
    { id: "80352", name: "RNF39" },
    { id: "6047", name: "RNF4" },
    { id: "9810", name: "RNF40" },
    { id: "10193", name: "RNF41" },
    { id: "54894", name: "RNF43" },
    { id: "22838", name: "RNF44" },
    { id: "6048", name: "RNF5" },
    { id: "6049", name: "RNF6" },
    { id: "9616", name: "RNF7" },
    { id: "9025", name: "RNF8" },
    { id: "51136", name: "RNFT1" },
    { id: "84900", name: "RNFT2" },
    { id: "8732", name: "RNGTT" },
    { id: "6050", name: "RNH1" },
    { id: "55328", name: "RNLS" },
    { id: "8731", name: "RNMT" },
    { id: "55178", name: "RNMTL1" },
    { id: "55599", name: "RNPC3" },
    { id: "57140", name: "RNPEPL1" },
    { id: "10921", name: "RNPS1" },
    { id: "26871", name: "RNU1-1" },
    { id: "6091", name: "ROBO1" },
    { id: "6092", name: "ROBO2" },
    { id: "64221", name: "ROBO3" },
    { id: "54538", name: "ROBO4" },
    { id: "6093", name: "ROCK1" },
    { id: "9475", name: "ROCK2" },
    { id: "79641", name: "ROGDI" },
    { id: "6094", name: "ROM1" },
    { id: "54763", name: "ROPN1" },
    { id: "152015", name: "ROPN1B" },
    { id: "83853", name: "ROPN1L" },
    { id: "4919", name: "ROR1" },
    { id: "4920", name: "ROR2" },
    { id: "6095", name: "RORA" },
    { id: "6096", name: "RORB" },
    { id: "6097", name: "RORC" },
    { id: "6098", name: "ROS1" },
    { id: "6101", name: "RP1" },
    { id: "94137", name: "RP1L1" },
    { id: "6102", name: "RP2" },
    { id: "6100", name: "RP9" },
    { id: "6117", name: "RPA1" },
    { id: "6118", name: "RPA2" },
    { id: "6119", name: "RPA3" },
    { id: "29935", name: "RPA4" },
    { id: "84268", name: "RPAIN" },
    { id: "26015", name: "RPAP1" },
    { id: "79871", name: "RPAP2" },
    { id: "79657", name: "RPAP3" },
    { id: "6120", name: "RPE" },
    { id: "6121", name: "RPE65" },
    { id: "80135", name: "RPF1" },
    { id: "84154", name: "RPF2" },
    { id: "6103", name: "RPGR" },
    { id: "57096", name: "RPGRIP1" },
    { id: "23322", name: "RPGRIP1L" },
    { id: "22895", name: "RPH3A" },
    { id: "9501", name: "RPH3AL" },
    { id: "22934", name: "RPIA" },
    { id: "6134", name: "RPL10" },
    { id: "4736", name: "RPL10A" },
    { id: "140801", name: "RPL10L" },
    { id: "6135", name: "RPL11" },
    { id: "6136", name: "RPL12" },
    { id: "6137", name: "RPL13" },
    { id: "23521", name: "RPL13A" },
    { id: "9045", name: "RPL14" },
    { id: "6138", name: "RPL15" },
    { id: "6139", name: "RPL17" },
    { id: "6141", name: "RPL18" },
    { id: "6142", name: "RPL18A" },
    { id: "6143", name: "RPL19" },
    { id: "6144", name: "RPL21" },
    { id: "6146", name: "RPL22" },
    { id: "200916", name: "RPL22L1" },
    { id: "9349", name: "RPL23" },
    { id: "6147", name: "RPL23A" },
    { id: "6152", name: "RPL24" },
    { id: "6154", name: "RPL26" },
    { id: "51121", name: "RPL26L1" },
    { id: "6155", name: "RPL27" },
    { id: "6157", name: "RPL27A" },
    { id: "6158", name: "RPL28" },
    { id: "6159", name: "RPL29" },
    { id: "6122", name: "RPL3" },
    { id: "6156", name: "RPL30" },
    { id: "6160", name: "RPL31" },
    { id: "6161", name: "RPL32" },
    { id: "6164", name: "RPL34" },
    { id: "11224", name: "RPL35" },
    { id: "6165", name: "RPL35A" },
    { id: "25873", name: "RPL36" },
    { id: "6173", name: "RPL36A" },
    { id: "100529097", name: "RPL36A-HNRNPH2" },
    { id: "6166", name: "RPL36AL" },
    { id: "6167", name: "RPL37" },
    { id: "6168", name: "RPL37A" },
    { id: "6169", name: "RPL38" },
    { id: "6170", name: "RPL39" },
    { id: "116832", name: "RPL39L" },
    { id: "553117", name: "RPL39P5" },
    { id: "6123", name: "RPL3L" },
    { id: "6124", name: "RPL4" },
    { id: "6171", name: "RPL41" },
    { id: "6125", name: "RPL5" },
    { id: "6128", name: "RPL6" },
    { id: "6129", name: "RPL7" },
    { id: "6130", name: "RPL7A" },
    { id: "285855", name: "RPL7L1" },
    { id: "6132", name: "RPL8" },
    { id: "6133", name: "RPL9" },
    { id: "6175", name: "RPLP0" },
    { id: "220717", name: "RPLP0P6" },
    { id: "6176", name: "RPLP1" },
    { id: "6181", name: "RPLP2" },
    { id: "6184", name: "RPN1" },
    { id: "6185", name: "RPN2" },
    { id: "11102", name: "RPP14" },
    { id: "79897", name: "RPP21" },
    { id: "54913", name: "RPP25" },
    { id: "138716", name: "RPP25L" },
    { id: "10556", name: "RPP30" },
    { id: "10557", name: "RPP38" },
    { id: "10799", name: "RPP40" },
    { id: "55197", name: "RPRD1A" },
    { id: "58490", name: "RPRD1B" },
    { id: "23248", name: "RPRD2" },
    { id: "56475", name: "RPRM" },
    { id: "6204", name: "RPS10" },
    { id: "100529239", name: "RPS10-NUDT3" },
    { id: "93144", name: "RPS10P5" },
    { id: "6205", name: "RPS11" },
    { id: "6206", name: "RPS12" },
    { id: "6207", name: "RPS13" },
    { id: "6208", name: "RPS14" },
    { id: "6209", name: "RPS15" },
    { id: "6210", name: "RPS15A" },
    { id: "6217", name: "RPS16" },
    { id: "6218", name: "RPS17" },
    { id: "6222", name: "RPS18" },
    { id: "6223", name: "RPS19" },
    { id: "91582", name: "RPS19BP1" },
    { id: "6187", name: "RPS2" },
    { id: "6224", name: "RPS20" },
    { id: "6227", name: "RPS21" },
    { id: "6228", name: "RPS23" },
    { id: "6229", name: "RPS24" },
    { id: "6230", name: "RPS25" },
    { id: "6231", name: "RPS26" },
    { id: "441502", name: "RPS26P11" },
    { id: "6232", name: "RPS27" },
    { id: "6233", name: "RPS27A" },
    { id: "51065", name: "RPS27L" },
    { id: "6234", name: "RPS28" },
    { id: "6235", name: "RPS29" },
    { id: "6188", name: "RPS3" },
    { id: "6189", name: "RPS3A" },
    { id: "6191", name: "RPS4X" },
    { id: "6192", name: "RPS4Y1" },
    { id: "140032", name: "RPS4Y2" },
    { id: "6193", name: "RPS5" },
    { id: "6194", name: "RPS6" },
    { id: "6195", name: "RPS6KA1" },
    { id: "6196", name: "RPS6KA2" },
    { id: "6197", name: "RPS6KA3" },
    { id: "8986", name: "RPS6KA4" },
    { id: "9252", name: "RPS6KA5" },
    { id: "27330", name: "RPS6KA6" },
    { id: "6198", name: "RPS6KB1" },
    { id: "6199", name: "RPS6KB2" },
    { id: "26750", name: "RPS6KC1" },
    { id: "83694", name: "RPS6KL1" },
    { id: "6201", name: "RPS7" },
    { id: "6202", name: "RPS8" },
    { id: "6203", name: "RPS9" },
    { id: "3921", name: "RPSA" },
    { id: "388524", name: "RPSAP58" },
    { id: "126638", name: "RPTN" },
    { id: "57521", name: "RPTOR" },
    { id: "113000", name: "RPUSD1" },
    { id: "27079", name: "RPUSD2" },
    { id: "285367", name: "RPUSD3" },
    { id: "84881", name: "RPUSD4" },
    { id: "9125", name: "RQCD1" },
    { id: "6236", name: "RRAD" },
    { id: "10670", name: "RRAGA" },
    { id: "10325", name: "RRAGB" },
    { id: "64121", name: "RRAGC" },
    { id: "58528", name: "RRAGD" },
    { id: "6237", name: "RRAS" },
    { id: "22800", name: "RRAS2" },
    { id: "6238", name: "RRBP1" },
    { id: "6239", name: "RREB1" },
    { id: "10692", name: "RRH" },
    { id: "6240", name: "RRM1" },
    { id: "6241", name: "RRM2" },
    { id: "50484", name: "RRM2B" },
    { id: "54700", name: "RRN3" },
    { id: "8568", name: "RRP1" },
    { id: "23223", name: "RRP12" },
    { id: "51018", name: "RRP15" },
    { id: "23076", name: "RRP1B" },
    { id: "88745", name: "RRP36" },
    { id: "27341", name: "RRP7A" },
    { id: "23378", name: "RRP8" },
    { id: "9136", name: "RRP9" },
    { id: "23212", name: "RRS1" },
    { id: "6247", name: "RS1" },
    { id: "54665", name: "RSBN1" },
    { id: "222194", name: "RSBN1L" },
    { id: "6248", name: "RSC1A1" },
    { id: "51773", name: "RSF1" },
    { id: "79363", name: "RSG1" },
    { id: "26156", name: "RSL1D1" },
    { id: "51187", name: "RSL24D1" },
    { id: "728194", name: "RSPH10B2" },
    { id: "83861", name: "RSPH3" },
    { id: "81492", name: "RSPH6A" },
    { id: "221421", name: "RSPH9" },
    { id: "284654", name: "RSPO1" },
    { id: "340419", name: "RSPO2" },
    { id: "84870", name: "RSPO3" },
    { id: "343637", name: "RSPO4" },
    { id: "89970", name: "RSPRY1" },
    { id: "51319", name: "RSRC1" },
    { id: "65117", name: "RSRC2" },
    { id: "6251", name: "RSU1" },
    { id: "8634", name: "RTCA" },
    { id: "51493", name: "RTCB" },
    { id: "27156", name: "RTDR1" },
    { id: "51750", name: "RTEL1" },
    { id: "23168", name: "RTF1" },
    { id: "51507", name: "RTFDC1" },
    { id: "6242", name: "RTKN" },
    { id: "219790", name: "RTKN2" },
    { id: "6252", name: "RTN1" },
    { id: "6253", name: "RTN2" },
    { id: "10313", name: "RTN3" },
    { id: "57142", name: "RTN4" },
    { id: "84816", name: "RTN4IP1" },
    { id: "65078", name: "RTN4R" },
    { id: "146760", name: "RTN4RL1" },
    { id: "349667", name: "RTN4RL2" },
    { id: "344892", name: "RTP2" },
    { id: "64108", name: "RTP4" },
    { id: "25914", name: "RTTN" },
    { id: "80230", name: "RUFY1" },
    { id: "55680", name: "RUFY2" },
    { id: "22902", name: "RUFY3" },
    { id: "285180", name: "RUFY4" },
    { id: "146923", name: "RUNDC1" },
    { id: "10900", name: "RUNDC3A" },
    { id: "154661", name: "RUNDC3B" },
    { id: "861", name: "RUNX1" },
    { id: "862", name: "RUNX1T1" },
    { id: "860", name: "RUNX2" },
    { id: "864", name: "RUNX3" },
    { id: "23623", name: "RUSC1" },
    { id: "284618", name: "RUSC1-AS1" },
    { id: "9853", name: "RUSC2" },
    { id: "8607", name: "RUVBL1" },
    { id: "10856", name: "RUVBL2" },
    { id: "51389", name: "RWDD1" },
    { id: "10069", name: "RWDD2B" },
    { id: "25950", name: "RWDD3" },
    { id: "201965", name: "RWDD4" },
    { id: "59350", name: "RXFP1" },
    { id: "122042", name: "RXFP2" },
    { id: "51289", name: "RXFP3" },
    { id: "339403", name: "RXFP4" },
    { id: "6256", name: "RXRA" },
    { id: "6257", name: "RXRB" },
    { id: "6258", name: "RXRG" },
    { id: "23429", name: "RYBP" },
    { id: "6259", name: "RYK" },
    { id: "6261", name: "RYR1" },
    { id: "6262", name: "RYR2" },
    { id: "6263", name: "RYR3" },
    { id: "6271", name: "S100A1" },
    { id: "6281", name: "S100A10" },
    { id: "6282", name: "S100A11" },
    { id: "6283", name: "S100A12" },
    { id: "6284", name: "S100A13" },
    { id: "57402", name: "S100A14" },
    { id: "140576", name: "S100A16" },
    { id: "6273", name: "S100A2" },
    { id: "6274", name: "S100A3" },
    { id: "6275", name: "S100A4" },
    { id: "6276", name: "S100A5" },
    { id: "6277", name: "S100A6" },
    { id: "6278", name: "S100A7" },
    { id: "645922", name: "S100A7L2" },
    { id: "6279", name: "S100A8" },
    { id: "6280", name: "S100A9" },
    { id: "6285", name: "S100B" },
    { id: "6286", name: "S100P" },
    { id: "64766", name: "S100PBP" },
    { id: "170591", name: "S100Z" },
    { id: "1901", name: "S1PR1" },
    { id: "9294", name: "S1PR2" },
    { id: "8698", name: "S1PR4" },
    { id: "53637", name: "S1PR5" },
    { id: "6288", name: "SAA1" },
    { id: "6289", name: "SAA2" },
    { id: "6291", name: "SAA4" },
    { id: "113174", name: "SAAL1" },
    { id: "29901", name: "SAC3D1" },
    { id: "22908", name: "SACM1L" },
    { id: "26278", name: "SACS" },
    { id: "10055", name: "SAE1" },
    { id: "6294", name: "SAFB" },
    { id: "9667", name: "SAFB2" },
    { id: "6295", name: "SAG" },
    { id: "6299", name: "SALL1" },
    { id: "6297", name: "SALL2" },
    { id: "27164", name: "SALL3" },
    { id: "57167", name: "SALL4" },
    { id: "90378", name: "SAMD1" },
    { id: "148398", name: "SAMD11" },
    { id: "401474", name: "SAMD12" },
    { id: "154075", name: "SAMD3" },
    { id: "23034", name: "SAMD4A" },
    { id: "55095", name: "SAMD4B" },
    { id: "344658", name: "SAMD7" },
    { id: "142891", name: "SAMD8" },
    { id: "54809", name: "SAMD9" },
    { id: "219285", name: "SAMD9L" },
    { id: "25939", name: "SAMHD1" },
    { id: "25813", name: "SAMM50" },
    { id: "64092", name: "SAMSN1" },
    { id: "79595", name: "SAP130" },
    { id: "10284", name: "SAP18" },
    { id: "100316904", name: "SAP25" },
    { id: "8819", name: "SAP30" },
    { id: "29115", name: "SAP30BP" },
    { id: "79685", name: "SAP30L" },
    { id: "401251", name: "SAPCD1" },
    { id: "89958", name: "SAPCD2" },
    { id: "56681", name: "SAR1A" },
    { id: "51128", name: "SAR1B" },
    { id: "23098", name: "SARM1" },
    { id: "84324", name: "SARNP" },
    { id: "6301", name: "SARS" },
    { id: "54938", name: "SARS2" },
    { id: "9092", name: "SART1" },
    { id: "9733", name: "SART3" },
    { id: "23328", name: "SASH1" },
    { id: "54440", name: "SASH3" },
    { id: "163786", name: "SASS6" },
    { id: "6303", name: "SAT1" },
    { id: "112483", name: "SAT2" },
    { id: "6304", name: "SATB1" },
    { id: "23314", name: "SATB2" },
    { id: "60485", name: "SAV1" },
    { id: "51119", name: "SBDS" },
    { id: "6305", name: "SBF1" },
    { id: "81846", name: "SBF2" },
    { id: "388228", name: "SBK1" },
    { id: "55206", name: "SBNO1" },
    { id: "22904", name: "SBNO2" },
    { id: "374897", name: "SBSN" },
    { id: "58506", name: "SCAF1" },
    { id: "9169", name: "SCAF11" },
    { id: "57466", name: "SCAF4" },
    { id: "22828", name: "SCAF8" },
    { id: "286205", name: "SCAI" },
    { id: "9522", name: "SCAMP1" },
    { id: "10066", name: "SCAMP2" },
    { id: "10067", name: "SCAMP3" },
    { id: "113178", name: "SCAMP4" },
    { id: "192683", name: "SCAMP5" },
    { id: "51282", name: "SCAND1" },
    { id: "22937", name: "SCAP" },
    { id: "49855", name: "SCAPER" },
    { id: "51435", name: "SCARA3" },
    { id: "286133", name: "SCARA5" },
    { id: "949", name: "SCARB1" },
    { id: "950", name: "SCARB2" },
    { id: "8578", name: "SCARF1" },
    { id: "91179", name: "SCARF2" },
    { id: "51097", name: "SCCPDH" },
    { id: "6319", name: "SCD" },
    { id: "79966", name: "SCD5" },
    { id: "8796", name: "SCEL" },
    { id: "23256", name: "SCFD1" },
    { id: "152579", name: "SCFD2" },
    { id: "7857", name: "SCG2" },
    { id: "29106", name: "SCG3" },
    { id: "6447", name: "SCG5" },
    { id: "7356", name: "SCGB1A1" },
    { id: "147199", name: "SCGB1C1" },
    { id: "10648", name: "SCGB1D1" },
    { id: "10647", name: "SCGB1D2" },
    { id: "4250", name: "SCGB2A2" },
    { id: "284402", name: "SCGB2B2" },
    { id: "92304", name: "SCGB3A1" },
    { id: "117156", name: "SCGB3A2" },
    { id: "10590", name: "SCGN" },
    { id: "29970", name: "SCHIP1" },
    { id: "388325", name: "SCIMP" },
    { id: "85477", name: "SCIN" },
    { id: "132320", name: "SCLT1" },
    { id: "51540", name: "SCLY" },
    { id: "22955", name: "SCMH1" },
    { id: "6322", name: "SCML1" },
    { id: "10389", name: "SCML2" },
    { id: "6336", name: "SCN10A" },
    { id: "6323", name: "SCN1A" },
    { id: "6324", name: "SCN1B" },
    { id: "6326", name: "SCN2A" },
    { id: "6328", name: "SCN3A" },
    { id: "55800", name: "SCN3B" },
    { id: "6329", name: "SCN4A" },
    { id: "6330", name: "SCN4B" },
    { id: "6331", name: "SCN5A" },
    { id: "6334", name: "SCN8A" },
    { id: "79005", name: "SCNM1" },
    { id: "6337", name: "SCNN1A" },
    { id: "6338", name: "SCNN1B" },
    { id: "6339", name: "SCNN1D" },
    { id: "6340", name: "SCNN1G" },
    { id: "9997", name: "SCO2" },
    { id: "60592", name: "SCOC" },
    { id: "6342", name: "SCP2" },
    { id: "59342", name: "SCPEP1" },
    { id: "23513", name: "SCRIB" },
    { id: "9805", name: "SCRN1" },
    { id: "90507", name: "SCRN2" },
    { id: "79634", name: "SCRN3" },
    { id: "6343", name: "SCT" },
    { id: "6344", name: "SCTR" },
    { id: "80274", name: "SCUBE1" },
    { id: "57758", name: "SCUBE2" },
    { id: "222663", name: "SCUBE3" },
    { id: "57410", name: "SCYL1" },
    { id: "55681", name: "SCYL2" },
    { id: "57147", name: "SCYL3" },
    { id: "55153", name: "SDAD1" },
    { id: "6382", name: "SDC1" },
    { id: "6383", name: "SDC2" },
    { id: "9672", name: "SDC3" },
    { id: "6385", name: "SDC4" },
    { id: "6386", name: "SDCBP" },
    { id: "27111", name: "SDCBP2" },
    { id: "10807", name: "SDCCAG3" },
    { id: "10806", name: "SDCCAG8" },
    { id: "163859", name: "SDE2" },
    { id: "6388", name: "SDF2" },
    { id: "23753", name: "SDF2L1" },
    { id: "51150", name: "SDF4" },
    { id: "6389", name: "SDHA" },
    { id: "644096", name: "SDHAF1" },
    { id: "54949", name: "SDHAF2" },
    { id: "6390", name: "SDHB" },
    { id: "6391", name: "SDHC" },
    { id: "6392", name: "SDHD" },
    { id: "221935", name: "SDK1" },
    { id: "54549", name: "SDK2" },
    { id: "8436", name: "SDPR" },
    { id: "195814", name: "SDR16C5" },
    { id: "56948", name: "SDR39U1" },
    { id: "93517", name: "SDR42E1" },
    { id: "10993", name: "SDS" },
    { id: "113675", name: "SDSL" },
    { id: "23478", name: "SEC11A" },
    { id: "90701", name: "SEC11C" },
    { id: "6396", name: "SEC13" },
    { id: "6397", name: "SEC14L1" },
    { id: "23541", name: "SEC14L2" },
    { id: "284904", name: "SEC14L4" },
    { id: "9717", name: "SEC14L5" },
    { id: "9919", name: "SEC16A" },
    { id: "89866", name: "SEC16B" },
    { id: "26984", name: "SEC22A" },
    { id: "9554", name: "SEC22B" },
    { id: "9117", name: "SEC22C" },
    { id: "10484", name: "SEC23A" },
    { id: "10483", name: "SEC23B" },
    { id: "11196", name: "SEC23IP" },
    { id: "10802", name: "SEC24A" },
    { id: "10427", name: "SEC24B" },
    { id: "9632", name: "SEC24C" },
    { id: "9871", name: "SEC24D" },
    { id: "22872", name: "SEC31A" },
    { id: "25956", name: "SEC31B" },
    { id: "29927", name: "SEC61A1" },
    { id: "55176", name: "SEC61A2" },
    { id: "10952", name: "SEC61B" },
    { id: "23480", name: "SEC61G" },
    { id: "7095", name: "SEC62" },
    { id: "11231", name: "SEC63" },
    { id: "79048", name: "SECISBP2" },
    { id: "9728", name: "SECISBP2L" },
    { id: "6398", name: "SECTM1" },
    { id: "81929", name: "SEH1L" },
    { id: "6400", name: "SEL1L" },
    { id: "80343", name: "SEL1L2" },
    { id: "23231", name: "SEL1L3" },
    { id: "6401", name: "SELE" },
    { id: "8991", name: "SELENBP1" },
    { id: "58515", name: "SELK" },
    { id: "6402", name: "SELL" },
    { id: "140606", name: "SELM" },
    { id: "83642", name: "SELO" },
    { id: "6403", name: "SELP" },
    { id: "6404", name: "SELPLG" },
    { id: "65260", name: "SELRC1" },
    { id: "51714", name: "SELT" },
    { id: "348303", name: "SELV" },
    { id: "10371", name: "SEMA3A" },
    { id: "7869", name: "SEMA3B" },
    { id: "10512", name: "SEMA3C" },
    { id: "56920", name: "SEMA3G" },
    { id: "64218", name: "SEMA4A" },
    { id: "10509", name: "SEMA4B" },
    { id: "54910", name: "SEMA4C" },
    { id: "10507", name: "SEMA4D" },
    { id: "10505", name: "SEMA4F" },
    { id: "57715", name: "SEMA4G" },
    { id: "9037", name: "SEMA5A" },
    { id: "54437", name: "SEMA5B" },
    { id: "57556", name: "SEMA6A" },
    { id: "80031", name: "SEMA6D" },
    { id: "8482", name: "SEMA7A" },
    { id: "6406", name: "SEMG1" },
    { id: "6407", name: "SEMG2" },
    { id: "29843", name: "SENP1" },
    { id: "59343", name: "SENP2" },
    { id: "26168", name: "SENP3" },
    { id: "205564", name: "SENP5" },
    { id: "26054", name: "SENP6" },
    { id: "57337", name: "SENP7" },
    { id: "123228", name: "SENP8" },
    { id: "22929", name: "SEPHS1" },
    { id: "22928", name: "SEPHS2" },
    { id: "57190", name: "SEPN1" },
    { id: "6414", name: "SEPP1" },
    { id: "51091", name: "SEPSECS" },
    { id: "1731", name: "SEPT1" },
    { id: "151011", name: "SEPT10" },
    { id: "55752", name: "SEPT11" },
    { id: "124404", name: "SEPT12" },
    { id: "346288", name: "SEPT14" },
    { id: "4735", name: "SEPT2" },
    { id: "55964", name: "SEPT3" },
    { id: "5414", name: "SEPT4" },
    { id: "5413", name: "SEPT5" },
    { id: "23157", name: "SEPT6" },
    { id: "989", name: "SEPT7" },
    { id: "23176", name: "SEPT8" },
    { id: "10801", name: "SEPT9" },
    { id: "84947", name: "SERAC1" },
    { id: "26135", name: "SERBP1" },
    { id: "8293", name: "SERF1A" },
    { id: "10169", name: "SERF2" },
    { id: "26297", name: "SERGEF" },
    { id: "253190", name: "SERHL2" },
    { id: "57515", name: "SERINC1" },
    { id: "10955", name: "SERINC3" },
    { id: "27230", name: "SERP1" },
    { id: "387923", name: "SERP2" },
    { id: "5265", name: "SERPINA1" },
    { id: "51156", name: "SERPINA10" },
    { id: "145264", name: "SERPINA12" },
    { id: "12", name: "SERPINA3" },
    { id: "5267", name: "SERPINA4" },
    { id: "5104", name: "SERPINA5" },
    { id: "6906", name: "SERPINA7" },
    { id: "1992", name: "SERPINB1" },
    { id: "89777", name: "SERPINB12" },
    { id: "5275", name: "SERPINB13" },
    { id: "5055", name: "SERPINB2" },
    { id: "6317", name: "SERPINB3" },
    { id: "6318", name: "SERPINB4" },
    { id: "5268", name: "SERPINB5" },
    { id: "5269", name: "SERPINB6" },
    { id: "5271", name: "SERPINB8" },
    { id: "5272", name: "SERPINB9" },
    { id: "462", name: "SERPINC1" },
    { id: "3053", name: "SERPIND1" },
    { id: "5054", name: "SERPINE1" },
    { id: "5270", name: "SERPINE2" },
    { id: "5176", name: "SERPINF1" },
    { id: "5345", name: "SERPINF2" },
    { id: "710", name: "SERPING1" },
    { id: "871", name: "SERPINH1" },
    { id: "29950", name: "SERTAD1" },
    { id: "9792", name: "SERTAD2" },
    { id: "29946", name: "SERTAD3" },
    { id: "56256", name: "SERTAD4" },
    { id: "400120", name: "SERTM1" },
    { id: "27244", name: "SESN1" },
    { id: "83667", name: "SESN2" },
    { id: "143686", name: "SESN3" },
    { id: "91404", name: "SESTD1" },
    { id: "6418", name: "SET" },
    { id: "26040", name: "SETBP1" },
    { id: "9739", name: "SETD1A" },
    { id: "23067", name: "SETD1B" },
    { id: "29072", name: "SETD2" },
    { id: "84193", name: "SETD3" },
    { id: "54093", name: "SETD4" },
    { id: "55209", name: "SETD5" },
    { id: "79918", name: "SETD6" },
    { id: "80854", name: "SETD7" },
    { id: "387893", name: "SETD8" },
    { id: "133383", name: "SETD9" },
    { id: "9869", name: "SETDB1" },
    { id: "83852", name: "SETDB2" },
    { id: "6419", name: "SETMAR" },
    { id: "23064", name: "SETX" },
    { id: "124925", name: "SEZ6" },
    { id: "26470", name: "SEZ6L2" },
    { id: "7536", name: "SF1" },
    { id: "10291", name: "SF3A1" },
    { id: "8175", name: "SF3A2" },
    { id: "10946", name: "SF3A3" },
    { id: "23451", name: "SF3B1" },
    { id: "51639", name: "SF3B14" },
    { id: "10992", name: "SF3B2" },
    { id: "23450", name: "SF3B3" },
    { id: "10262", name: "SF3B4" },
    { id: "83443", name: "SF3B5" },
    { id: "9814", name: "SFI1" },
    { id: "51460", name: "SFMBT1" },
    { id: "57713", name: "SFMBT2" },
    { id: "2810", name: "SFN" },
    { id: "6421", name: "SFPQ" },
    { id: "119392", name: "SFR1" },
    { id: "6422", name: "SFRP1" },
    { id: "6423", name: "SFRP2" },
    { id: "6424", name: "SFRP4" },
    { id: "6433", name: "SFSWAP" },
    { id: "113402", name: "SFT2D1" },
    { id: "375035", name: "SFT2D2" },
    { id: "84826", name: "SFT2D3" },
    { id: "653509", name: "SFTPA1" },
    { id: "729238", name: "SFTPA2" },
    { id: "6439", name: "SFTPB" },
    { id: "6440", name: "SFTPC" },
    { id: "6441", name: "SFTPD" },
    { id: "94081", name: "SFXN1" },
    { id: "118980", name: "SFXN2" },
    { id: "81855", name: "SFXN3" },
    { id: "119559", name: "SFXN4" },
    { id: "6442", name: "SGCA" },
    { id: "6443", name: "SGCB" },
    { id: "6444", name: "SGCD" },
    { id: "8910", name: "SGCE" },
    { id: "6445", name: "SGCG" },
    { id: "137868", name: "SGCZ" },
    { id: "84251", name: "SGIP1" },
    { id: "6446", name: "SGK1" },
    { id: "84197", name: "SGK196" },
    { id: "10110", name: "SGK2" },
    { id: "157285", name: "SGK223" },
    { id: "23678", name: "SGK3" },
    { id: "124923", name: "SGK494" },
    { id: "259230", name: "SGMS1" },
    { id: "166929", name: "SGMS2" },
    { id: "151648", name: "SGOL1" },
    { id: "151246", name: "SGOL2" },
    { id: "8879", name: "SGPL1" },
    { id: "81537", name: "SGPP1" },
    { id: "6448", name: "SGSH" },
    { id: "129049", name: "SGSM1" },
    { id: "9905", name: "SGSM2" },
    { id: "27352", name: "SGSM3" },
    { id: "6449", name: "SGTA" },
    { id: "54557", name: "SGTB" },
    { id: "25970", name: "SH2B1" },
    { id: "10603", name: "SH2B2" },
    { id: "10019", name: "SH2B3" },
    { id: "4068", name: "SH2D1A" },
    { id: "117157", name: "SH2D1B" },
    { id: "9047", name: "SH2D2A" },
    { id: "10045", name: "SH2D3A" },
    { id: "10044", name: "SH2D3C" },
    { id: "63898", name: "SH2D4A" },
    { id: "400745", name: "SH2D5" },
    { id: "6450", name: "SH3BGR" },
    { id: "6451", name: "SH3BGRL" },
    { id: "83699", name: "SH3BGRL2" },
    { id: "83442", name: "SH3BGRL3" },
    { id: "23616", name: "SH3BP1" },
    { id: "6452", name: "SH3BP2" },
    { id: "23677", name: "SH3BP4" },
    { id: "9467", name: "SH3BP5" },
    { id: "80851", name: "SH3BP5L" },
    { id: "152503", name: "SH3D19" },
    { id: "79729", name: "SH3D21" },
    { id: "6455", name: "SH3GL1" },
    { id: "6456", name: "SH3GL2" },
    { id: "6457", name: "SH3GL3" },
    { id: "51100", name: "SH3GLB1" },
    { id: "56904", name: "SH3GLB2" },
    { id: "30011", name: "SH3KBP1" },
    { id: "9644", name: "SH3PXD2A" },
    { id: "285590", name: "SH3PXD2B" },
    { id: "57630", name: "SH3RF1" },
    { id: "153769", name: "SH3RF2" },
    { id: "344558", name: "SH3RF3" },
    { id: "54436", name: "SH3TC1" },
    { id: "26751", name: "SH3YL1" },
    { id: "50944", name: "SHANK1" },
    { id: "22941", name: "SHANK2" },
    { id: "85358", name: "SHANK3" },
    { id: "81858", name: "SHARPIN" },
    { id: "6461", name: "SHB" },
    { id: "6462", name: "SHBG" },
    { id: "6464", name: "SHC1" },
    { id: "25759", name: "SHC2" },
    { id: "53358", name: "SHC3" },
    { id: "399694", name: "SHC4" },
    { id: "79801", name: "SHCBP1" },
    { id: "81626", name: "SHCBP1L" },
    { id: "56961", name: "SHD" },
    { id: "126669", name: "SHE" },
    { id: "90525", name: "SHF" },
    { id: "7979", name: "SHFM1" },
    { id: "6469", name: "SHH" },
    { id: "387914", name: "SHISA2" },
    { id: "152573", name: "SHISA3" },
    { id: "51246", name: "SHISA5" },
    { id: "388336", name: "SHISA6" },
    { id: "92799", name: "SHKBP1" },
    { id: "6470", name: "SHMT1" },
    { id: "6472", name: "SHMT2" },
    { id: "8036", name: "SHOC2" },
    { id: "6473", name: "SHOX" },
    { id: "6474", name: "SHOX2" },
    { id: "23729", name: "SHPK" },
    { id: "257218", name: "SHPRH" },
    { id: "55164", name: "SHQ1" },
    { id: "134549", name: "SHROOM1" },
    { id: "57619", name: "SHROOM3" },
    { id: "6476", name: "SI" },
    { id: "54414", name: "SIAE" },
    { id: "6477", name: "SIAH1" },
    { id: "6478", name: "SIAH2" },
    { id: "59307", name: "SIGIRR" },
    { id: "6614", name: "SIGLEC1" },
    { id: "89790", name: "SIGLEC10" },
    { id: "114132", name: "SIGLEC11" },
    { id: "89858", name: "SIGLEC12" },
    { id: "100049587", name: "SIGLEC14" },
    { id: "8778", name: "SIGLEC5" },
    { id: "27036", name: "SIGLEC7" },
    { id: "27181", name: "SIGLEC8" },
    { id: "27180", name: "SIGLEC9" },
    { id: "10280", name: "SIGMAR1" },
    { id: "150094", name: "SIK1" },
    { id: "23235", name: "SIK2" },
    { id: "23387", name: "SIK3" },
    { id: "80143", name: "SIKE1" },
    { id: "64374", name: "SIL1" },
    { id: "6492", name: "SIM1" },
    { id: "6493", name: "SIM2" },
    { id: "375484", name: "SIMC1" },
    { id: "25942", name: "SIN3A" },
    { id: "23309", name: "SIN3B" },
    { id: "6494", name: "SIPA1" },
    { id: "26037", name: "SIPA1L1" },
    { id: "57568", name: "SIPA1L2" },
    { id: "23094", name: "SIPA1L3" },
    { id: "140885", name: "SIRPA" },
    { id: "10326", name: "SIRPB1" },
    { id: "128646", name: "SIRPD" },
    { id: "55423", name: "SIRPG" },
    { id: "23411", name: "SIRT1" },
    { id: "22933", name: "SIRT2" },
    { id: "23410", name: "SIRT3" },
    { id: "23409", name: "SIRT4" },
    { id: "23408", name: "SIRT5" },
    { id: "51548", name: "SIRT6" },
    { id: "51547", name: "SIRT7" },
    { id: "27240", name: "SIT1" },
    { id: "10572", name: "SIVA1" },
    { id: "6495", name: "SIX1" },
    { id: "10736", name: "SIX2" },
    { id: "6496", name: "SIX3" },
    { id: "51804", name: "SIX4" },
    { id: "147912", name: "SIX5" },
    { id: "4990", name: "SIX6" },
    { id: "220134", name: "SKA1" },
    { id: "348235", name: "SKA2" },
    { id: "221150", name: "SKA3" },
    { id: "8631", name: "SKAP1" },
    { id: "8935", name: "SKAP2" },
    { id: "6497", name: "SKI" },
    { id: "6498", name: "SKIL" },
    { id: "6499", name: "SKIV2L" },
    { id: "23517", name: "SKIV2L2" },
    { id: "390598", name: "SKOR1" },
    { id: "6500", name: "SKP1" },
    { id: "6502", name: "SKP2" },
    { id: "6503", name: "SLA" },
    { id: "84174", name: "SLA2" },
    { id: "122060", name: "SLAIN1" },
    { id: "57606", name: "SLAIN2" },
    { id: "6504", name: "SLAMF1" },
    { id: "114836", name: "SLAMF6" },
    { id: "57823", name: "SLAMF7" },
    { id: "7884", name: "SLBP" },
    { id: "201780", name: "SLC10A4" },
    { id: "4891", name: "SLC11A2" },
    { id: "6557", name: "SLC12A1" },
    { id: "6558", name: "SLC12A2" },
    { id: "6559", name: "SLC12A3" },
    { id: "6560", name: "SLC12A4" },
    { id: "57468", name: "SLC12A5" },
    { id: "9990", name: "SLC12A6" },
    { id: "10723", name: "SLC12A7" },
    { id: "84561", name: "SLC12A8" },
    { id: "56996", name: "SLC12A9" },
    { id: "26266", name: "SLC13A4" },
    { id: "284111", name: "SLC13A5" },
    { id: "8170", name: "SLC14A2" },
    { id: "6565", name: "SLC15A2" },
    { id: "51296", name: "SLC15A3" },
    { id: "121260", name: "SLC15A4" },
    { id: "6566", name: "SLC16A1" },
    { id: "117247", name: "SLC16A10" },
    { id: "201232", name: "SLC16A13" },
    { id: "6567", name: "SLC16A2" },
    { id: "9123", name: "SLC16A3" },
    { id: "9122", name: "SLC16A4" },
    { id: "9121", name: "SLC16A5" },
    { id: "9120", name: "SLC16A6" },
    { id: "9194", name: "SLC16A7" },
    { id: "23539", name: "SLC16A8" },
    { id: "220963", name: "SLC16A9" },
    { id: "6568", name: "SLC17A1" },
    { id: "10246", name: "SLC17A2" },
    { id: "26503", name: "SLC17A5" },
    { id: "57030", name: "SLC17A7" },
    { id: "63910", name: "SLC17A9" },
    { id: "6571", name: "SLC18A2" },
    { id: "116843", name: "SLC18B1" },
    { id: "10560", name: "SLC19A2" },
    { id: "80704", name: "SLC19A3" },
    { id: "6505", name: "SLC1A1" },
    { id: "6506", name: "SLC1A2" },
    { id: "6507", name: "SLC1A3" },
    { id: "6510", name: "SLC1A5" },
    { id: "6574", name: "SLC20A1" },
    { id: "6580", name: "SLC22A1" },
    { id: "55867", name: "SLC22A11" },
    { id: "116085", name: "SLC22A12" },
    { id: "9389", name: "SLC22A14" },
    { id: "85413", name: "SLC22A16" },
    { id: "51310", name: "SLC22A17" },
    { id: "5002", name: "SLC22A18" },
    { id: "6582", name: "SLC22A2" },
    { id: "440044", name: "SLC22A20" },
    { id: "63027", name: "SLC22A23" },
    { id: "283238", name: "SLC22A24" },
    { id: "6581", name: "SLC22A3" },
    { id: "6583", name: "SLC22A4" },
    { id: "6584", name: "SLC22A5" },
    { id: "9356", name: "SLC22A6" },
    { id: "114571", name: "SLC22A9" },
    { id: "9963", name: "SLC23A1" },
    { id: "9962", name: "SLC23A2" },
    { id: "151295", name: "SLC23A3" },
    { id: "9187", name: "SLC24A1" },
    { id: "25769", name: "SLC24A2" },
    { id: "283652", name: "SLC24A5" },
    { id: "80024", name: "SLC24A6" },
    { id: "6576", name: "SLC25A1" },
    { id: "1468", name: "SLC25A10" },
    { id: "8402", name: "SLC25A11" },
    { id: "8604", name: "SLC25A12" },
    { id: "10165", name: "SLC25A13" },
    { id: "10166", name: "SLC25A15" },
    { id: "10478", name: "SLC25A17" },
    { id: "83733", name: "SLC25A18" },
    { id: "60386", name: "SLC25A19" },
    { id: "89874", name: "SLC25A21" },
    { id: "79751", name: "SLC25A22" },
    { id: "79085", name: "SLC25A23" },
    { id: "29957", name: "SLC25A24" },
    { id: "114789", name: "SLC25A25" },
    { id: "115286", name: "SLC25A26" },
    { id: "9481", name: "SLC25A27" },
    { id: "123096", name: "SLC25A29" },
    { id: "5250", name: "SLC25A3" },
    { id: "253512", name: "SLC25A30" },
    { id: "83447", name: "SLC25A31" },
    { id: "81034", name: "SLC25A32" },
    { id: "84275", name: "SLC25A33" },
    { id: "284723", name: "SLC25A34" },
    { id: "399512", name: "SLC25A35" },
    { id: "55186", name: "SLC25A36" },
    { id: "51312", name: "SLC25A37" },
    { id: "54977", name: "SLC25A38" },
    { id: "51629", name: "SLC25A39" },
    { id: "284439", name: "SLC25A42" },
    { id: "91137", name: "SLC25A46" },
    { id: "283600", name: "SLC25A47" },
    { id: "153328", name: "SLC25A48" },
    { id: "292", name: "SLC25A5" },
    { id: "92014", name: "SLC25A51" },
    { id: "293", name: "SLC25A6" },
    { id: "10861", name: "SLC26A1" },
    { id: "284129", name: "SLC26A11" },
    { id: "1811", name: "SLC26A3" },
    { id: "375611", name: "SLC26A5" },
    { id: "65010", name: "SLC26A6" },
    { id: "116369", name: "SLC26A8" },
    { id: "115019", name: "SLC26A9" },
    { id: "376497", name: "SLC27A1" },
    { id: "11001", name: "SLC27A2" },
    { id: "11000", name: "SLC27A3" },
    { id: "10999", name: "SLC27A4" },
    { id: "10998", name: "SLC27A5" },
    { id: "28965", name: "SLC27A6" },
    { id: "9153", name: "SLC28A2" },
    { id: "6513", name: "SLC2A1" },
    { id: "81031", name: "SLC2A10" },
    { id: "66035", name: "SLC2A11" },
    { id: "154091", name: "SLC2A12" },
    { id: "144195", name: "SLC2A14" },
    { id: "6514", name: "SLC2A2" },
    { id: "6515", name: "SLC2A3" },
    { id: "6517", name: "SLC2A4" },
    { id: "56731", name: "SLC2A4RG" },
    { id: "6518", name: "SLC2A5" },
    { id: "11182", name: "SLC2A6" },
    { id: "29988", name: "SLC2A8" },
    { id: "7779", name: "SLC30A1" },
    { id: "7780", name: "SLC30A2" },
    { id: "7781", name: "SLC30A3" },
    { id: "7782", name: "SLC30A4" },
    { id: "64924", name: "SLC30A5" },
    { id: "55676", name: "SLC30A6" },
    { id: "148867", name: "SLC30A7" },
    { id: "169026", name: "SLC30A8" },
    { id: "10463", name: "SLC30A9" },
    { id: "1317", name: "SLC31A1" },
    { id: "140679", name: "SLC32A1" },
    { id: "9197", name: "SLC33A1" },
    { id: "6569", name: "SLC34A1" },
    { id: "10568", name: "SLC34A2" },
    { id: "142680", name: "SLC34A3" },
    { id: "10559", name: "SLC35A1" },
    { id: "7355", name: "SLC35A2" },
    { id: "23443", name: "SLC35A3" },
    { id: "113829", name: "SLC35A4" },
    { id: "55032", name: "SLC35A5" },
    { id: "10237", name: "SLC35B1" },
    { id: "347734", name: "SLC35B2" },
    { id: "51000", name: "SLC35B3" },
    { id: "84912", name: "SLC35B4" },
    { id: "51006", name: "SLC35C2" },
    { id: "23169", name: "SLC35D1" },
    { id: "79939", name: "SLC35E1" },
    { id: "55508", name: "SLC35E3" },
    { id: "339665", name: "SLC35E4" },
    { id: "54733", name: "SLC35F2" },
    { id: "148641", name: "SLC35F3" },
    { id: "80255", name: "SLC35F5" },
    { id: "54978", name: "SLC35F6" },
    { id: "159371", name: "SLC35G1" },
    { id: "80723", name: "SLC35G2" },
    { id: "83650", name: "SLC35G5" },
    { id: "206358", name: "SLC36A1" },
    { id: "120103", name: "SLC36A4" },
    { id: "54020", name: "SLC37A1" },
    { id: "84255", name: "SLC37A3" },
    { id: "2542", name: "SLC37A4" },
    { id: "81539", name: "SLC38A1" },
    { id: "124565", name: "SLC38A10" },
    { id: "54407", name: "SLC38A2" },
    { id: "10991", name: "SLC38A3" },
    { id: "92745", name: "SLC38A5" },
    { id: "55238", name: "SLC38A7" },
    { id: "153129", name: "SLC38A9" },
    { id: "27173", name: "SLC39A1" },
    { id: "57181", name: "SLC39A10" },
    { id: "201266", name: "SLC39A11" },
    { id: "91252", name: "SLC39A13" },
    { id: "23516", name: "SLC39A14" },
    { id: "29986", name: "SLC39A2" },
    { id: "29985", name: "SLC39A3" },
    { id: "55630", name: "SLC39A4" },
    { id: "25800", name: "SLC39A6" },
    { id: "7922", name: "SLC39A7" },
    { id: "64116", name: "SLC39A8" },
    { id: "55334", name: "SLC39A9" },
    { id: "6520", name: "SLC3A2" },
    { id: "30061", name: "SLC40A1" },
    { id: "254428", name: "SLC41A1" },
    { id: "84102", name: "SLC41A2" },
    { id: "54946", name: "SLC41A3" },
    { id: "8501", name: "SLC43A1" },
    { id: "124935", name: "SLC43A2" },
    { id: "29015", name: "SLC43A3" },
    { id: "23446", name: "SLC44A1" },
    { id: "57153", name: "SLC44A2" },
    { id: "126969", name: "SLC44A3" },
    { id: "50651", name: "SLC45A1" },
    { id: "57210", name: "SLC45A4" },
    { id: "113235", name: "SLC46A1" },
    { id: "55244", name: "SLC47A1" },
    { id: "55652", name: "SLC48A1" },
    { id: "6521", name: "SLC4A1" },
    { id: "57282", name: "SLC4A10" },
    { id: "83959", name: "SLC4A11" },
    { id: "22950", name: "SLC4A1AP" },
    { id: "6522", name: "SLC4A2" },
    { id: "6508", name: "SLC4A3" },
    { id: "8671", name: "SLC4A4" },
    { id: "57835", name: "SLC4A5" },
    { id: "9497", name: "SLC4A7" },
    { id: "9498", name: "SLC4A8" },
    { id: "55974", name: "SLC50A1" },
    { id: "200931", name: "SLC51A" },
    { id: "123264", name: "SLC51B" },
    { id: "55065", name: "SLC52A1" },
    { id: "79581", name: "SLC52A2" },
    { id: "6523", name: "SLC5A1" },
    { id: "115584", name: "SLC5A11" },
    { id: "6526", name: "SLC5A3" },
    { id: "8884", name: "SLC5A6" },
    { id: "60482", name: "SLC5A7" },
    { id: "160728", name: "SLC5A8" },
    { id: "6529", name: "SLC6A1" },
    { id: "6539", name: "SLC6A12" },
    { id: "6540", name: "SLC6A13" },
    { id: "55117", name: "SLC6A15" },
    { id: "388662", name: "SLC6A17" },
    { id: "6530", name: "SLC6A2" },
    { id: "54716", name: "SLC6A20" },
    { id: "6531", name: "SLC6A3" },
    { id: "6532", name: "SLC6A4" },
    { id: "9152", name: "SLC6A5" },
    { id: "6535", name: "SLC6A8" },
    { id: "6536", name: "SLC6A9" },
    { id: "6541", name: "SLC7A1" },
    { id: "56301", name: "SLC7A10" },
    { id: "23657", name: "SLC7A11" },
    { id: "57709", name: "SLC7A14" },
    { id: "84889", name: "SLC7A3" },
    { id: "8140", name: "SLC7A5" },
    { id: "9057", name: "SLC7A6" },
    { id: "84138", name: "SLC7A6OS" },
    { id: "9056", name: "SLC7A7" },
    { id: "23428", name: "SLC7A8" },
    { id: "11136", name: "SLC7A9" },
    { id: "6546", name: "SLC8A1" },
    { id: "6543", name: "SLC8A2" },
    { id: "6547", name: "SLC8A3" },
    { id: "6548", name: "SLC9A1" },
    { id: "6549", name: "SLC9A2" },
    { id: "6550", name: "SLC9A3" },
    { id: "9368", name: "SLC9A3R1" },
    { id: "9351", name: "SLC9A3R2" },
    { id: "389015", name: "SLC9A4" },
    { id: "6553", name: "SLC9A5" },
    { id: "10479", name: "SLC9A6" },
    { id: "84679", name: "SLC9A7" },
    { id: "23315", name: "SLC9A8" },
    { id: "285195", name: "SLC9A9" },
    { id: "133308", name: "SLC9B2" },
    { id: "6579", name: "SLCO1A2" },
    { id: "53919", name: "SLCO1C1" },
    { id: "6578", name: "SLCO2A1" },
    { id: "11309", name: "SLCO2B1" },
    { id: "28232", name: "SLCO3A1" },
    { id: "28231", name: "SLCO4A1" },
    { id: "353189", name: "SLCO4C1" },
    { id: "91607", name: "SLFN11" },
    { id: "55106", name: "SLFN12" },
    { id: "100506736", name: "SLFN12L" },
    { id: "162394", name: "SLFN5" },
    { id: "200172", name: "SLFNL1" },
    { id: "81892", name: "SLIRP" },
    { id: "6585", name: "SLIT1" },
    { id: "9353", name: "SLIT2" },
    { id: "6586", name: "SLIT3" },
    { id: "114798", name: "SLITRK1" },
    { id: "22865", name: "SLITRK3" },
    { id: "139065", name: "SLITRK4" },
    { id: "84189", name: "SLITRK6" },
    { id: "9748", name: "SLK" },
    { id: "7871", name: "SLMAP" },
    { id: "10650", name: "SLMO1" },
    { id: "6588", name: "SLN" },
    { id: "6590", name: "SLPI" },
    { id: "79811", name: "SLTM" },
    { id: "10569", name: "SLU7" },
    { id: "548593", name: "SLX1A" },
    { id: "100526830", name: "SLX1A-SULT1A3" },
    { id: "79008", name: "SLX1B" },
    { id: "84464", name: "SLX4" },
    { id: "128710", name: "SLX4IP" },
    { id: "4086", name: "SMAD1" },
    { id: "4087", name: "SMAD2" },
    { id: "4088", name: "SMAD3" },
    { id: "4089", name: "SMAD4" },
    { id: "4090", name: "SMAD5" },
    { id: "4091", name: "SMAD6" },
    { id: "4092", name: "SMAD7" },
    { id: "4093", name: "SMAD9" },
    { id: "57228", name: "SMAGP" },
    { id: "60682", name: "SMAP1" },
    { id: "64744", name: "SMAP2" },
    { id: "6594", name: "SMARCA1" },
    { id: "6595", name: "SMARCA2" },
    { id: "6597", name: "SMARCA4" },
    { id: "8467", name: "SMARCA5" },
    { id: "56916", name: "SMARCAD1" },
    { id: "50485", name: "SMARCAL1" },
    { id: "6598", name: "SMARCB1" },
    { id: "6599", name: "SMARCC1" },
    { id: "6601", name: "SMARCC2" },
    { id: "6602", name: "SMARCD1" },
    { id: "6603", name: "SMARCD2" },
    { id: "6604", name: "SMARCD3" },
    { id: "6605", name: "SMARCE1" },
    { id: "8243", name: "SMC1A" },
    { id: "27127", name: "SMC1B" },
    { id: "10592", name: "SMC2" },
    { id: "9126", name: "SMC3" },
    { id: "10051", name: "SMC4" },
    { id: "23137", name: "SMC5" },
    { id: "79677", name: "SMC6" },
    { id: "23347", name: "SMCHD1" },
    { id: "440087", name: "SMCO3" },
    { id: "56935", name: "SMCO4" },
    { id: "4184", name: "SMCP" },
    { id: "125170", name: "SMCR7" },
    { id: "54471", name: "SMCR7L" },
    { id: "140775", name: "SMCR8" },
    { id: "55671", name: "SMEK1" },
    { id: "57223", name: "SMEK2" },
    { id: "23049", name: "SMG1" },
    { id: "23381", name: "SMG5" },
    { id: "23293", name: "SMG6" },
    { id: "9887", name: "SMG7" },
    { id: "55181", name: "SMG8" },
    { id: "56006", name: "SMG9" },
    { id: "388588", name: "SMIM1" },
    { id: "644538", name: "SMIM10" },
    { id: "54065", name: "SMIM11" },
    { id: "201895", name: "SMIM14" },
    { id: "79024", name: "SMIM2" },
    { id: "389203", name: "SMIM20" },
    { id: "85027", name: "SMIM3" },
    { id: "440957", name: "SMIM4" },
    { id: "643008", name: "SMIM5" },
    { id: "100507203", name: "SMLR1" },
    { id: "6606", name: "SMN1" },
    { id: "6607", name: "SMN2" },
    { id: "10285", name: "SMNDC1" },
    { id: "6608", name: "SMO" },
    { id: "64093", name: "SMOC1" },
    { id: "64094", name: "SMOC2" },
    { id: "54498", name: "SMOX" },
    { id: "6609", name: "SMPD1" },
    { id: "6610", name: "SMPD2" },
    { id: "55512", name: "SMPD3" },
    { id: "55627", name: "SMPD4" },
    { id: "10924", name: "SMPDL3A" },
    { id: "27293", name: "SMPDL3B" },
    { id: "23676", name: "SMPX" },
    { id: "26952", name: "SMR3A" },
    { id: "10879", name: "SMR3B" },
    { id: "6611", name: "SMS" },
    { id: "6525", name: "SMTN" },
    { id: "342527", name: "SMTNL2" },
    { id: "55234", name: "SMU1" },
    { id: "23583", name: "SMUG1" },
    { id: "57154", name: "SMURF1" },
    { id: "64750", name: "SMURF2" },
    { id: "150572", name: "SMYD1" },
    { id: "56950", name: "SMYD2" },
    { id: "64754", name: "SMYD3" },
    { id: "114826", name: "SMYD4" },
    { id: "10322", name: "SMYD5" },
    { id: "6615", name: "SNAI1" },
    { id: "6591", name: "SNAI2" },
    { id: "333929", name: "SNAI3" },
    { id: "8773", name: "SNAP23" },
    { id: "6616", name: "SNAP25" },
    { id: "9342", name: "SNAP29" },
    { id: "116841", name: "SNAP47" },
    { id: "9892", name: "SNAP91" },
    { id: "6617", name: "SNAPC1" },
    { id: "6618", name: "SNAPC2" },
    { id: "6619", name: "SNAPC3" },
    { id: "6621", name: "SNAPC4" },
    { id: "10302", name: "SNAPC5" },
    { id: "23557", name: "SNAPIN" },
    { id: "6622", name: "SNCA" },
    { id: "9627", name: "SNCAIP" },
    { id: "6620", name: "SNCB" },
    { id: "6623", name: "SNCG" },
    { id: "27044", name: "SND1" },
    { id: "25992", name: "SNED1" },
    { id: "11267", name: "SNF8" },
    { id: "79753", name: "SNIP1" },
    { id: "9751", name: "SNPH" },
    { id: "54861", name: "SNRK" },
    { id: "23020", name: "SNRNP200" },
    { id: "79622", name: "SNRNP25" },
    { id: "11017", name: "SNRNP27" },
    { id: "11066", name: "SNRNP35" },
    { id: "9410", name: "SNRNP40" },
    { id: "154007", name: "SNRNP48" },
    { id: "6625", name: "SNRNP70" },
    { id: "6626", name: "SNRPA" },
    { id: "6627", name: "SNRPA1" },
    { id: "6628", name: "SNRPB" },
    { id: "6629", name: "SNRPB2" },
    { id: "6631", name: "SNRPC" },
    { id: "6632", name: "SNRPD1" },
    { id: "6633", name: "SNRPD2" },
    { id: "6634", name: "SNRPD3" },
    { id: "6635", name: "SNRPE" },
    { id: "6636", name: "SNRPF" },
    { id: "6637", name: "SNRPG" },
    { id: "6638", name: "SNRPN" },
    { id: "6640", name: "SNTA1" },
    { id: "6641", name: "SNTB1" },
    { id: "6645", name: "SNTB2" },
    { id: "54212", name: "SNTG1" },
    { id: "54221", name: "SNTG2" },
    { id: "10073", name: "SNUPN" },
    { id: "8926", name: "SNURF" },
    { id: "22938", name: "SNW1" },
    { id: "6642", name: "SNX1" },
    { id: "29887", name: "SNX10" },
    { id: "29916", name: "SNX11" },
    { id: "29934", name: "SNX12" },
    { id: "23161", name: "SNX13" },
    { id: "57231", name: "SNX14" },
    { id: "29907", name: "SNX15" },
    { id: "9784", name: "SNX17" },
    { id: "112574", name: "SNX18" },
    { id: "399979", name: "SNX19" },
    { id: "6643", name: "SNX2" },
    { id: "124460", name: "SNX20" },
    { id: "90203", name: "SNX21" },
    { id: "79856", name: "SNX22" },
    { id: "28966", name: "SNX24" },
    { id: "83891", name: "SNX25" },
    { id: "81609", name: "SNX27" },
    { id: "92017", name: "SNX29" },
    { id: "8724", name: "SNX3" },
    { id: "401548", name: "SNX30" },
    { id: "169166", name: "SNX31" },
    { id: "254122", name: "SNX32" },
    { id: "257364", name: "SNX33" },
    { id: "8723", name: "SNX4" },
    { id: "27131", name: "SNX5" },
    { id: "58533", name: "SNX6" },
    { id: "51375", name: "SNX7" },
    { id: "29886", name: "SNX8" },
    { id: "51429", name: "SNX9" },
    { id: "6646", name: "SOAT1" },
    { id: "8435", name: "SOAT2" },
    { id: "55084", name: "SOBP" },
    { id: "8651", name: "SOCS1" },
    { id: "8835", name: "SOCS2" },
    { id: "9021", name: "SOCS3" },
    { id: "122809", name: "SOCS4" },
    { id: "9655", name: "SOCS5" },
    { id: "9306", name: "SOCS6" },
    { id: "30837", name: "SOCS7" },
    { id: "6647", name: "SOD1" },
    { id: "6648", name: "SOD2" },
    { id: "6649", name: "SOD3" },
    { id: "140710", name: "SOGA1" },
    { id: "23255", name: "SOGA2" },
    { id: "387104", name: "SOGA3" },
    { id: "402381", name: "SOHLH1" },
    { id: "54937", name: "SOHLH2" },
    { id: "6650", name: "SOLH" },
    { id: "6651", name: "SON" },
    { id: "10580", name: "SORBS1" },
    { id: "8470", name: "SORBS2" },
    { id: "10174", name: "SORBS3" },
    { id: "114815", name: "SORCS1" },
    { id: "57537", name: "SORCS2" },
    { id: "22986", name: "SORCS3" },
    { id: "6652", name: "SORD" },
    { id: "6653", name: "SORL1" },
    { id: "6272", name: "SORT1" },
    { id: "6654", name: "SOS1" },
    { id: "6655", name: "SOS2" },
    { id: "50964", name: "SOST" },
    { id: "25928", name: "SOSTDC1" },
    { id: "65124", name: "SOWAHC" },
    { id: "6656", name: "SOX1" },
    { id: "6663", name: "SOX10" },
    { id: "6664", name: "SOX11" },
    { id: "6666", name: "SOX12" },
    { id: "9580", name: "SOX13" },
    { id: "8403", name: "SOX14" },
    { id: "6665", name: "SOX15" },
    { id: "64321", name: "SOX17" },
    { id: "54345", name: "SOX18" },
    { id: "6657", name: "SOX2" },
    { id: "6658", name: "SOX3" },
    { id: "11063", name: "SOX30" },
    { id: "6659", name: "SOX4" },
    { id: "6660", name: "SOX5" },
    { id: "55553", name: "SOX6" },
    { id: "83595", name: "SOX7" },
    { id: "30812", name: "SOX8" },
    { id: "6662", name: "SOX9" },
    { id: "6667", name: "SP1" },
    { id: "6672", name: "SP100" },
    { id: "3431", name: "SP110" },
    { id: "11262", name: "SP140" },
    { id: "93349", name: "SP140L" },
    { id: "6668", name: "SP2" },
    { id: "6670", name: "SP3" },
    { id: "6671", name: "SP4" },
    { id: "389058", name: "SP5" },
    { id: "80320", name: "SP6" },
    { id: "121340", name: "SP7" },
    { id: "221833", name: "SP8" },
    { id: "53340", name: "SPA17" },
    { id: "124912", name: "SPACA3" },
    { id: "389852", name: "SPACA5" },
    { id: "122258", name: "SPACA7" },
    { id: "6674", name: "SPAG1" },
    { id: "10407", name: "SPAG11B" },
    { id: "79582", name: "SPAG16" },
    { id: "6676", name: "SPAG4" },
    { id: "10615", name: "SPAG5" },
    { id: "9576", name: "SPAG6" },
    { id: "9552", name: "SPAG7" },
    { id: "26206", name: "SPAG8" },
    { id: "9043", name: "SPAG9" },
    { id: "30014", name: "SPANXA1" },
    { id: "728712", name: "SPANXA2" },
    { id: "728695", name: "SPANXB1" },
    { id: "64663", name: "SPANXC" },
    { id: "64648", name: "SPANXD" },
    { id: "494119", name: "SPANXN2" },
    { id: "139067", name: "SPANXN3" },
    { id: "441525", name: "SPANXN4" },
    { id: "6678", name: "SPARC" },
    { id: "8404", name: "SPARCL1" },
    { id: "6683", name: "SPAST" },
    { id: "100505741", name: "SPATA1" },
    { id: "353324", name: "SPATA12" },
    { id: "132671", name: "SPATA18" },
    { id: "9825", name: "SPATA2" },
    { id: "64847", name: "SPATA20" },
    { id: "374955", name: "SPATA21" },
    { id: "84690", name: "SPATA22" },
    { id: "202051", name: "SPATA24" },
    { id: "128497", name: "SPATA25" },
    { id: "124044", name: "SPATA2L" },
    { id: "130560", name: "SPATA3" },
    { id: "26165", name: "SPATA31A7" },
    { id: "286234", name: "SPATA31E1" },
    { id: "124783", name: "SPATA32" },
    { id: "132851", name: "SPATA4" },
    { id: "166378", name: "SPATA5" },
    { id: "79029", name: "SPATA5L1" },
    { id: "55812", name: "SPATA7" },
    { id: "145946", name: "SPATA8" },
    { id: "375686", name: "SPATC1" },
    { id: "84221", name: "SPATC1L" },
    { id: "221409", name: "SPATS1" },
    { id: "65244", name: "SPATS2" },
    { id: "26010", name: "SPATS2L" },
    { id: "147841", name: "SPC24" },
    { id: "57405", name: "SPC25" },
    { id: "28972", name: "SPCS1" },
    { id: "9789", name: "SPCS2" },
    { id: "60559", name: "SPCS3" },
    { id: "25803", name: "SPDEF" },
    { id: "54908", name: "SPDL1" },
    { id: "245711", name: "SPDYA" },
    { id: "387778", name: "SPDYC" },
    { id: "441273", name: "SPDYE2" },
    { id: "388333", name: "SPDYE4" },
    { id: "92521", name: "SPECC1" },
    { id: "23384", name: "SPECC1L" },
    { id: "25876", name: "SPEF1" },
    { id: "79925", name: "SPEF2" },
    { id: "10290", name: "SPEG" },
    { id: "374768", name: "SPEM1" },
    { id: "23013", name: "SPEN" },
    { id: "220082", name: "SPERT" },
    { id: "246777", name: "SPESP1" },
    { id: "80208", name: "SPG11" },
    { id: "23111", name: "SPG20" },
    { id: "51324", name: "SPG21" },
    { id: "6687", name: "SPG7" },
    { id: "8877", name: "SPHK1" },
    { id: "56848", name: "SPHK2" },
    { id: "80309", name: "SPHKAP" },
    { id: "6688", name: "SPI1" },
    { id: "6689", name: "SPIB" },
    { id: "121599", name: "SPIC" },
    { id: "152185", name: "SPICE1" },
    { id: "10927", name: "SPIN1" },
    { id: "54466", name: "SPIN2A" },
    { id: "474343", name: "SPIN2B" },
    { id: "169981", name: "SPIN3" },
    { id: "139886", name: "SPIN4" },
    { id: "6690", name: "SPINK1" },
    { id: "153218", name: "SPINK13" },
    { id: "6691", name: "SPINK2" },
    { id: "11005", name: "SPINK5" },
    { id: "404203", name: "SPINK6" },
    { id: "84651", name: "SPINK7" },
    { id: "643394", name: "SPINK9" },
    { id: "6692", name: "SPINT1" },
    { id: "10653", name: "SPINT2" },
    { id: "10816", name: "SPINT3" },
    { id: "56907", name: "SPIRE1" },
    { id: "6693", name: "SPN" },
    { id: "83985", name: "SPNS1" },
    { id: "23626", name: "SPO11" },
    { id: "90853", name: "SPOCD1" },
    { id: "6695", name: "SPOCK1" },
    { id: "9806", name: "SPOCK2" },
    { id: "50859", name: "SPOCK3" },
    { id: "10418", name: "SPON1" },
    { id: "10417", name: "SPON2" },
    { id: "8405", name: "SPOP" },
    { id: "339745", name: "SPOPL" },
    { id: "6696", name: "SPP1" },
    { id: "6694", name: "SPP2" },
    { id: "84888", name: "SPPL2A" },
    { id: "56928", name: "SPPL2B" },
    { id: "6697", name: "SPR" },
    { id: "161742", name: "SPRED1" },
    { id: "200734", name: "SPRED2" },
    { id: "399473", name: "SPRED3" },
    { id: "503542", name: "SPRN" },
    { id: "6698", name: "SPRR1A" },
    { id: "6699", name: "SPRR1B" },
    { id: "6700", name: "SPRR2A" },
    { id: "6703", name: "SPRR2D" },
    { id: "6707", name: "SPRR3" },
    { id: "163778", name: "SPRR4" },
    { id: "83932", name: "SPRTN" },
    { id: "10252", name: "SPRY1" },
    { id: "10253", name: "SPRY2" },
    { id: "10251", name: "SPRY3" },
    { id: "81848", name: "SPRY4" },
    { id: "84926", name: "SPRYD3" },
    { id: "283377", name: "SPRYD4" },
    { id: "57213", name: "SPRYD7" },
    { id: "80176", name: "SPSB1" },
    { id: "84727", name: "SPSB2" },
    { id: "90864", name: "SPSB3" },
    { id: "92369", name: "SPSB4" },
    { id: "6708", name: "SPTA1" },
    { id: "6709", name: "SPTAN1" },
    { id: "6710", name: "SPTB" },
    { id: "6711", name: "SPTBN1" },
    { id: "6712", name: "SPTBN2" },
    { id: "57731", name: "SPTBN4" },
    { id: "10558", name: "SPTLC1" },
    { id: "9517", name: "SPTLC2" },
    { id: "171546", name: "SPTSSA" },
    { id: "144108", name: "SPTY2D1" },
    { id: "84654", name: "SPZ1" },
    { id: "6713", name: "SQLE" },
    { id: "58472", name: "SQRDL" },
    { id: "8878", name: "SQSTM1" },
    { id: "10011", name: "SRA1" },
    { id: "55133", name: "SRBD1" },
    { id: "6714", name: "SRC" },
    { id: "10847", name: "SRCAP" },
    { id: "80725", name: "SRCIN1" },
    { id: "136853", name: "SRCRB4D" },
    { id: "79644", name: "SRD5A3" },
    { id: "6720", name: "SREBF1" },
    { id: "6721", name: "SREBF2" },
    { id: "140890", name: "SREK1" },
    { id: "285672", name: "SREK1IP1" },
    { id: "6722", name: "SRF" },
    { id: "153443", name: "SRFBP1" },
    { id: "57522", name: "SRGAP1" },
    { id: "23380", name: "SRGAP2" },
    { id: "653464", name: "SRGAP2C" },
    { id: "9901", name: "SRGAP3" },
    { id: "5552", name: "SRGN" },
    { id: "6717", name: "SRI" },
    { id: "6723", name: "SRM" },
    { id: "6725", name: "SRMS" },
    { id: "6727", name: "SRP14" },
    { id: "6728", name: "SRP19" },
    { id: "6729", name: "SRP54" },
    { id: "6730", name: "SRP68" },
    { id: "6731", name: "SRP72" },
    { id: "6726", name: "SRP9" },
    { id: "6732", name: "SRPK1" },
    { id: "6733", name: "SRPK2" },
    { id: "26576", name: "SRPK3" },
    { id: "6734", name: "SRPR" },
    { id: "58477", name: "SRPRB" },
    { id: "8406", name: "SRPX" },
    { id: "27286", name: "SRPX2" },
    { id: "63826", name: "SRR" },
    { id: "10250", name: "SRRM1" },
    { id: "23524", name: "SRRM2" },
    { id: "222183", name: "SRRM3" },
    { id: "84530", name: "SRRM4" },
    { id: "51593", name: "SRRT" },
    { id: "6426", name: "SRSF1" },
    { id: "10772", name: "SRSF10" },
    { id: "9295", name: "SRSF11" },
    { id: "135295", name: "SRSF12" },
    { id: "6427", name: "SRSF2" },
    { id: "6428", name: "SRSF3" },
    { id: "6429", name: "SRSF4" },
    { id: "6430", name: "SRSF5" },
    { id: "6431", name: "SRSF6" },
    { id: "6432", name: "SRSF7" },
    { id: "10929", name: "SRSF8" },
    { id: "8683", name: "SRSF9" },
    { id: "140809", name: "SRXN1" },
    { id: "6736", name: "SRY" },
    { id: "6760", name: "SS18" },
    { id: "26039", name: "SS18L1" },
    { id: "51188", name: "SS18L2" },
    { id: "6741", name: "SSB" },
    { id: "6742", name: "SSBP1" },
    { id: "23635", name: "SSBP2" },
    { id: "23648", name: "SSBP3" },
    { id: "170463", name: "SSBP4" },
    { id: "284297", name: "SSC5D" },
    { id: "6744", name: "SSFA2" },
    { id: "54434", name: "SSH1" },
    { id: "85464", name: "SSH2" },
    { id: "54961", name: "SSH3" },
    { id: "8636", name: "SSNA1" },
    { id: "8082", name: "SSPN" },
    { id: "23145", name: "SSPO" },
    { id: "6745", name: "SSR1" },
    { id: "6746", name: "SSR2" },
    { id: "6747", name: "SSR3" },
    { id: "6748", name: "SSR4" },
    { id: "6749", name: "SSRP1" },
    { id: "10534", name: "SSSCA1" },
    { id: "6751", name: "SSTR1" },
    { id: "6752", name: "SSTR2" },
    { id: "6753", name: "SSTR3" },
    { id: "6754", name: "SSTR4" },
    { id: "6755", name: "SSTR5" },
    { id: "29101", name: "SSU72" },
    { id: "51066", name: "SSUH2" },
    { id: "6756", name: "SSX1" },
    { id: "6757", name: "SSX2" },
    { id: "117178", name: "SSX2IP" },
    { id: "10214", name: "SSX3" },
    { id: "6759", name: "SSX4" },
    { id: "548313", name: "SSX4B" },
    { id: "6758", name: "SSX5" },
    { id: "280658", name: "SSX7" },
    { id: "280660", name: "SSX9" },
    { id: "6767", name: "ST13" },
    { id: "145165", name: "ST13P4" },
    { id: "6768", name: "ST14" },
    { id: "6482", name: "ST3GAL1" },
    { id: "6483", name: "ST3GAL2" },
    { id: "6487", name: "ST3GAL3" },
    { id: "6484", name: "ST3GAL4" },
    { id: "10402", name: "ST3GAL6" },
    { id: "6764", name: "ST5" },
    { id: "6480", name: "ST6GAL1" },
    { id: "84620", name: "ST6GAL2" },
    { id: "55808", name: "ST6GALNAC1" },
    { id: "10610", name: "ST6GALNAC2" },
    { id: "256435", name: "ST6GALNAC3" },
    { id: "27090", name: "ST6GALNAC4" },
    { id: "30815", name: "ST6GALNAC6" },
    { id: "7982", name: "ST7" },
    { id: "54879", name: "ST7L" },
    { id: "8128", name: "ST8SIA2" },
    { id: "51046", name: "ST8SIA3" },
    { id: "7903", name: "ST8SIA4" },
    { id: "29906", name: "ST8SIA5" },
    { id: "338596", name: "ST8SIA6" },
    { id: "23166", name: "STAB1" },
    { id: "55576", name: "STAB2" },
    { id: "6769", name: "STAC" },
    { id: "342667", name: "STAC2" },
    { id: "246329", name: "STAC3" },
    { id: "10274", name: "STAG1" },
    { id: "10735", name: "STAG2" },
    { id: "10734", name: "STAG3" },
    { id: "8027", name: "STAM" },
    { id: "10254", name: "STAM2" },
    { id: "10617", name: "STAMBP" },
    { id: "57559", name: "STAMBPL1" },
    { id: "26228", name: "STAP1" },
    { id: "55620", name: "STAP2" },
    { id: "6770", name: "STAR" },
    { id: "10809", name: "STARD10" },
    { id: "90627", name: "STARD13" },
    { id: "10948", name: "STARD3" },
    { id: "83930", name: "STARD3NL" },
    { id: "134429", name: "STARD4" },
    { id: "56910", name: "STARD7" },
    { id: "57519", name: "STARD9" },
    { id: "6772", name: "STAT1" },
    { id: "6773", name: "STAT2" },
    { id: "6774", name: "STAT3" },
    { id: "6775", name: "STAT4" },
    { id: "6776", name: "STAT5A" },
    { id: "6777", name: "STAT5B" },
    { id: "6778", name: "STAT6" },
    { id: "6779", name: "STATH" },
    { id: "6780", name: "STAU1" },
    { id: "27067", name: "STAU2" },
    { id: "6781", name: "STC1" },
    { id: "8614", name: "STC2" },
    { id: "26872", name: "STEAP1" },
    { id: "256227", name: "STEAP1B" },
    { id: "261729", name: "STEAP2" },
    { id: "55240", name: "STEAP3" },
    { id: "79689", name: "STEAP4" },
    { id: "246744", name: "STH" },
    { id: "6491", name: "STIL" },
    { id: "6786", name: "STIM1" },
    { id: "57620", name: "STIM2" },
    { id: "10963", name: "STIP1" },
    { id: "6793", name: "STK10" },
    { id: "6794", name: "STK11" },
    { id: "114790", name: "STK11IP" },
    { id: "8576", name: "STK16" },
    { id: "9263", name: "STK17A" },
    { id: "9262", name: "STK17B" },
    { id: "8859", name: "STK19" },
    { id: "8428", name: "STK24" },
    { id: "10494", name: "STK25" },
    { id: "6788", name: "STK3" },
    { id: "56164", name: "STK31" },
    { id: "202374", name: "STK32A" },
    { id: "55351", name: "STK32B" },
    { id: "282974", name: "STK32C" },
    { id: "65975", name: "STK33" },
    { id: "140901", name: "STK35" },
    { id: "27148", name: "STK36" },
    { id: "11329", name: "STK38" },
    { id: "23012", name: "STK38L" },
    { id: "27347", name: "STK39" },
    { id: "6789", name: "STK4" },
    { id: "83931", name: "STK40" },
    { id: "3925", name: "STMN1" },
    { id: "11075", name: "STMN2" },
    { id: "50861", name: "STMN3" },
    { id: "81551", name: "STMN4" },
    { id: "2040", name: "STOM" },
    { id: "9399", name: "STOML1" },
    { id: "30968", name: "STOML2" },
    { id: "161003", name: "STOML3" },
    { id: "11037", name: "STON1" },
    { id: "85439", name: "STON2" },
    { id: "219736", name: "STOX1" },
    { id: "56977", name: "STOX2" },
    { id: "285555", name: "STPG2" },
    { id: "201254", name: "STRA13" },
    { id: "64220", name: "STRA6" },
    { id: "346673", name: "STRA8" },
    { id: "92335", name: "STRADA" },
    { id: "55437", name: "STRADB" },
    { id: "11171", name: "STRAP" },
    { id: "55342", name: "STRBP" },
    { id: "85369", name: "STRIP1" },
    { id: "57464", name: "STRIP2" },
    { id: "6801", name: "STRN" },
    { id: "29966", name: "STRN3" },
    { id: "29888", name: "STRN4" },
    { id: "412", name: "STS" },
    { id: "3703", name: "STT3A" },
    { id: "201595", name: "STT3B" },
    { id: "10273", name: "STUB1" },
    { id: "8677", name: "STX10" },
    { id: "8676", name: "STX11" },
    { id: "23673", name: "STX12" },
    { id: "8675", name: "STX16" },
    { id: "55014", name: "STX17" },
    { id: "53407", name: "STX18" },
    { id: "415117", name: "STX19" },
    { id: "6804", name: "STX1A" },
    { id: "112755", name: "STX1B" },
    { id: "2054", name: "STX2" },
    { id: "6809", name: "STX3" },
    { id: "6810", name: "STX4" },
    { id: "6811", name: "STX5" },
    { id: "10228", name: "STX6" },
    { id: "8417", name: "STX7" },
    { id: "9482", name: "STX8" },
    { id: "6812", name: "STXBP1" },
    { id: "6813", name: "STXBP2" },
    { id: "6814", name: "STXBP3" },
    { id: "252983", name: "STXBP4" },
    { id: "134957", name: "STXBP5" },
    { id: "9515", name: "STXBP5L" },
    { id: "29091", name: "STXBP6" },
    { id: "55359", name: "STYK1" },
    { id: "51657", name: "STYXL1" },
    { id: "10923", name: "SUB1" },
    { id: "8803", name: "SUCLA2" },
    { id: "8802", name: "SUCLG1" },
    { id: "8801", name: "SUCLG2" },
    { id: "51430", name: "SUCO" },
    { id: "64426", name: "SUDS3" },
    { id: "51684", name: "SUFU" },
    { id: "57794", name: "SUGP1" },
    { id: "10147", name: "SUGP2" },
    { id: "10910", name: "SUGT1" },
    { id: "23213", name: "SULF1" },
    { id: "55959", name: "SULF2" },
    { id: "6817", name: "SULT1A1" },
    { id: "6818", name: "SULT1A3" },
    { id: "27284", name: "SULT1B1" },
    { id: "6819", name: "SULT1C2" },
    { id: "6783", name: "SULT1E1" },
    { id: "6822", name: "SULT2A1" },
    { id: "6820", name: "SULT2B1" },
    { id: "25830", name: "SULT4A1" },
    { id: "285362", name: "SUMF1" },
    { id: "25870", name: "SUMF2" },
    { id: "7341", name: "SUMO1" },
    { id: "391257", name: "SUMO1P1" },
    { id: "6613", name: "SUMO2" },
    { id: "6612", name: "SUMO3" },
    { id: "387082", name: "SUMO4" },
    { id: "23353", name: "SUN1" },
    { id: "25777", name: "SUN2" },
    { id: "140732", name: "SUN5" },
    { id: "6821", name: "SUOX" },
    { id: "11198", name: "SUPT16H" },
    { id: "55578", name: "SUPT20H" },
    { id: "8464", name: "SUPT3H" },
    { id: "6827", name: "SUPT4H1" },
    { id: "6829", name: "SUPT5H" },
    { id: "6830", name: "SUPT6H" },
    { id: "9913", name: "SUPT7L" },
    { id: "6832", name: "SUPV3L1" },
    { id: "6834", name: "SURF1" },
    { id: "6835", name: "SURF2" },
    { id: "6836", name: "SURF4" },
    { id: "6838", name: "SURF6" },
    { id: "64420", name: "SUSD1" },
    { id: "56241", name: "SUSD2" },
    { id: "203328", name: "SUSD3" },
    { id: "55061", name: "SUSD4" },
    { id: "6839", name: "SUV39H1" },
    { id: "79723", name: "SUV39H2" },
    { id: "51111", name: "SUV420H1" },
    { id: "84787", name: "SUV420H2" },
    { id: "23512", name: "SUZ12" },
    { id: "9900", name: "SV2A" },
    { id: "9899", name: "SV2B" },
    { id: "22987", name: "SV2C" },
    { id: "79987", name: "SVEP1" },
    { id: "6840", name: "SVIL" },
    { id: "258010", name: "SVIP" },
    { id: "55530", name: "SVOP" },
    { id: "136306", name: "SVOPL" },
    { id: "23075", name: "SWAP70" },
    { id: "375757", name: "SWI5" },
    { id: "126074", name: "SWSAP1" },
    { id: "94056", name: "SYAP1" },
    { id: "55638", name: "SYBU" },
    { id: "93426", name: "SYCE1" },
    { id: "256126", name: "SYCE2" },
    { id: "644186", name: "SYCE3" },
    { id: "221711", name: "SYCP2L" },
    { id: "50511", name: "SYCP3" },
    { id: "85360", name: "SYDE1" },
    { id: "84144", name: "SYDE2" },
    { id: "25949", name: "SYF2" },
    { id: "6850", name: "SYK" },
    { id: "8189", name: "SYMPK" },
    { id: "6853", name: "SYN1" },
    { id: "6854", name: "SYN2" },
    { id: "8224", name: "SYN3" },
    { id: "81493", name: "SYNC" },
    { id: "10492", name: "SYNCRIP" },
    { id: "79953", name: "SYNDIG1" },
    { id: "23345", name: "SYNE1" },
    { id: "23224", name: "SYNE2" },
    { id: "161176", name: "SYNE3" },
    { id: "163183", name: "SYNE4" },
    { id: "8831", name: "SYNGAP1" },
    { id: "9145", name: "SYNGR1" },
    { id: "9144", name: "SYNGR2" },
    { id: "9143", name: "SYNGR3" },
    { id: "23546", name: "SYNGR4" },
    { id: "8867", name: "SYNJ1" },
    { id: "8871", name: "SYNJ2" },
    { id: "55333", name: "SYNJ2BP" },
    { id: "23336", name: "SYNM" },
    { id: "11346", name: "SYNPO" },
    { id: "171024", name: "SYNPO2" },
    { id: "79933", name: "SYNPO2L" },
    { id: "132204", name: "SYNPR" },
    { id: "11276", name: "SYNRG" },
    { id: "6855", name: "SYP" },
    { id: "6856", name: "SYPL1" },
    { id: "284612", name: "SYPL2" },
    { id: "90196", name: "SYS1" },
    { id: "6857", name: "SYT1" },
    { id: "23208", name: "SYT11" },
    { id: "91683", name: "SYT12" },
    { id: "57586", name: "SYT13" },
    { id: "255928", name: "SYT14" },
    { id: "83849", name: "SYT15" },
    { id: "83851", name: "SYT16" },
    { id: "51760", name: "SYT17" },
    { id: "127833", name: "SYT2" },
    { id: "84258", name: "SYT3" },
    { id: "6860", name: "SYT4" },
    { id: "6861", name: "SYT5" },
    { id: "148281", name: "SYT6" },
    { id: "9066", name: "SYT7" },
    { id: "90019", name: "SYT8" },
    { id: "143425", name: "SYT9" },
    { id: "84958", name: "SYTL1" },
    { id: "54843", name: "SYTL2" },
    { id: "94120", name: "SYTL3" },
    { id: "94121", name: "SYTL4" },
    { id: "94122", name: "SYTL5" },
    { id: "84447", name: "SYVN1" },
    { id: "26099", name: "SZRD1" },
    { id: "23334", name: "SZT2" },
    { id: "83551", name: "TAAR8" },
    { id: "10454", name: "TAB1" },
    { id: "23118", name: "TAB2" },
    { id: "257397", name: "TAB3" },
    { id: "6863", name: "TAC1" },
    { id: "6866", name: "TAC3" },
    { id: "255061", name: "TAC4" },
    { id: "6867", name: "TACC1" },
    { id: "10579", name: "TACC2" },
    { id: "10460", name: "TACC3" },
    { id: "51204", name: "TACO1" },
    { id: "6869", name: "TACR1" },
    { id: "6865", name: "TACR2" },
    { id: "6870", name: "TACR3" },
    { id: "4070", name: "TACSTD2" },
    { id: "117143", name: "TADA1" },
    { id: "6871", name: "TADA2A" },
    { id: "93624", name: "TADA2B" },
    { id: "10474", name: "TADA3" },
    { id: "6872", name: "TAF1" },
    { id: "6881", name: "TAF10" },
    { id: "6882", name: "TAF11" },
    { id: "6883", name: "TAF12" },
    { id: "6884", name: "TAF13" },
    { id: "8148", name: "TAF15" },
    { id: "9015", name: "TAF1A" },
    { id: "9014", name: "TAF1B" },
    { id: "9013", name: "TAF1C" },
    { id: "79101", name: "TAF1D" },
    { id: "138474", name: "TAF1L" },
    { id: "6873", name: "TAF2" },
    { id: "83860", name: "TAF3" },
    { id: "6874", name: "TAF4" },
    { id: "6875", name: "TAF4B" },
    { id: "6877", name: "TAF5" },
    { id: "27097", name: "TAF5L" },
    { id: "6878", name: "TAF6" },
    { id: "10629", name: "TAF6L" },
    { id: "6879", name: "TAF7" },
    { id: "54457", name: "TAF7L" },
    { id: "129685", name: "TAF8" },
    { id: "6880", name: "TAF9" },
    { id: "51616", name: "TAF9B" },
    { id: "117289", name: "TAGAP" },
    { id: "6876", name: "TAGLN" },
    { id: "8407", name: "TAGLN2" },
    { id: "29114", name: "TAGLN3" },
    { id: "6886", name: "TAL1" },
    { id: "6887", name: "TAL2" },
    { id: "6888", name: "TALDO1" },
    { id: "85461", name: "TANC1" },
    { id: "26115", name: "TANC2" },
    { id: "79613", name: "TANGO6" },
    { id: "10010", name: "TANK" },
    { id: "57551", name: "TAOK1" },
    { id: "9344", name: "TAOK2" },
    { id: "51347", name: "TAOK3" },
    { id: "6890", name: "TAP1" },
    { id: "6891", name: "TAP2" },
    { id: "6892", name: "TAPBP" },
    { id: "55080", name: "TAPBPL" },
    { id: "202018", name: "TAPT1" },
    { id: "6894", name: "TARBP1" },
    { id: "6895", name: "TARBP2" },
    { id: "23435", name: "TARDBP" },
    { id: "445347", name: "TARP" },
    { id: "6897", name: "TARS" },
    { id: "80222", name: "TARS2" },
    { id: "123283", name: "TARSL2" },
    { id: "83756", name: "TAS1R3" },
    { id: "50838", name: "TAS2R13" },
    { id: "259287", name: "TAS2R41" },
    { id: "259289", name: "TAS2R43" },
    { id: "50835", name: "TAS2R9" },
    { id: "55617", name: "TASP1" },
    { id: "6898", name: "TAT" },
    { id: "83940", name: "TATDN1" },
    { id: "9797", name: "TATDN2" },
    { id: "8887", name: "TAX1BP1" },
    { id: "30851", name: "TAX1BP3" },
    { id: "6901", name: "TAZ" },
    { id: "23216", name: "TBC1D1" },
    { id: "83874", name: "TBC1D10A" },
    { id: "26000", name: "TBC1D10B" },
    { id: "374403", name: "TBC1D10C" },
    { id: "54662", name: "TBC1D13" },
    { id: "57533", name: "TBC1D14" },
    { id: "64786", name: "TBC1D15" },
    { id: "125058", name: "TBC1D16" },
    { id: "79735", name: "TBC1D17" },
    { id: "55296", name: "TBC1D19" },
    { id: "55357", name: "TBC1D2" },
    { id: "128637", name: "TBC1D20" },
    { id: "161514", name: "TBC1D21" },
    { id: "25771", name: "TBC1D22A" },
    { id: "55633", name: "TBC1D22B" },
    { id: "55773", name: "TBC1D23" },
    { id: "57465", name: "TBC1D24" },
    { id: "4943", name: "TBC1D25" },
    { id: "96597", name: "TBC1D27" },
    { id: "23102", name: "TBC1D2B" },
    { id: "729873", name: "TBC1D3" },
    { id: "23329", name: "TBC1D30" },
    { id: "414059", name: "TBC1D3B" },
    { id: "414060", name: "TBC1D3C" },
    { id: "84218", name: "TBC1D3F" },
    { id: "9882", name: "TBC1D4" },
    { id: "9779", name: "TBC1D5" },
    { id: "51256", name: "TBC1D7" },
    { id: "11138", name: "TBC1D8" },
    { id: "54885", name: "TBC1D8B" },
    { id: "23158", name: "TBC1D9" },
    { id: "23061", name: "TBC1D9B" },
    { id: "6902", name: "TBCA" },
    { id: "1155", name: "TBCB" },
    { id: "6903", name: "TBCC" },
    { id: "55171", name: "TBCCD1" },
    { id: "6904", name: "TBCD" },
    { id: "6905", name: "TBCE" },
    { id: "219899", name: "TBCEL" },
    { id: "93627", name: "TBCK" },
    { id: "29110", name: "TBK1" },
    { id: "9755", name: "TBKBP1" },
    { id: "6907", name: "TBL1X" },
    { id: "79718", name: "TBL1XR1" },
    { id: "90665", name: "TBL1Y" },
    { id: "26608", name: "TBL2" },
    { id: "10607", name: "TBL3" },
    { id: "6908", name: "TBP" },
    { id: "9519", name: "TBPL1" },
    { id: "387332", name: "TBPL2" },
    { id: "10716", name: "TBR1" },
    { id: "84897", name: "TBRG1" },
    { id: "9238", name: "TBRG4" },
    { id: "6913", name: "TBX15" },
    { id: "9096", name: "TBX18" },
    { id: "9095", name: "TBX19" },
    { id: "6909", name: "TBX2" },
    { id: "57057", name: "TBX20" },
    { id: "30009", name: "TBX21" },
    { id: "50945", name: "TBX22" },
    { id: "6926", name: "TBX3" },
    { id: "6910", name: "TBX5" },
    { id: "6911", name: "TBX6" },
    { id: "6915", name: "TBXA2R" },
    { id: "285343", name: "TCAIM" },
    { id: "8557", name: "TCAP" },
    { id: "6917", name: "TCEA1" },
    { id: "6919", name: "TCEA2" },
    { id: "9338", name: "TCEAL1" },
    { id: "140597", name: "TCEAL2" },
    { id: "85012", name: "TCEAL3" },
    { id: "79921", name: "TCEAL4" },
    { id: "340543", name: "TCEAL5" },
    { id: "158931", name: "TCEAL6" },
    { id: "56849", name: "TCEAL7" },
    { id: "90843", name: "TCEAL8" },
    { id: "170082", name: "TCEANC" },
    { id: "127428", name: "TCEANC2" },
    { id: "6921", name: "TCEB1" },
    { id: "6923", name: "TCEB2" },
    { id: "6924", name: "TCEB3" },
    { id: "51224", name: "TCEB3B" },
    { id: "162699", name: "TCEB3C" },
    { id: "10915", name: "TCERG1" },
    { id: "256536", name: "TCERG1L" },
    { id: "6938", name: "TCF12" },
    { id: "6939", name: "TCF15" },
    { id: "6941", name: "TCF19" },
    { id: "6942", name: "TCF20" },
    { id: "6943", name: "TCF21" },
    { id: "150921", name: "TCF23" },
    { id: "100129654", name: "TCF24" },
    { id: "22980", name: "TCF25" },
    { id: "6929", name: "TCF3" },
    { id: "6925", name: "TCF4" },
    { id: "6932", name: "TCF7" },
    { id: "83439", name: "TCF7L1" },
    { id: "6934", name: "TCF7L2" },
    { id: "10732", name: "TCFL5" },
    { id: "126637", name: "TCHHL1" },
    { id: "84260", name: "TCHP" },
    { id: "10312", name: "TCIRG1" },
    { id: "8115", name: "TCL1A" },
    { id: "9623", name: "TCL1B" },
    { id: "27004", name: "TCL6" },
    { id: "6947", name: "TCN1" },
    { id: "6948", name: "TCN2" },
    { id: "6949", name: "TCOF1" },
    { id: "6950", name: "TCP1" },
    { id: "6953", name: "TCP10" },
    { id: "140290", name: "TCP10L" },
    { id: "6954", name: "TCP11" },
    { id: "55346", name: "TCP11L1" },
    { id: "255394", name: "TCP11L2" },
    { id: "6988", name: "TCTA" },
    { id: "202500", name: "TCTE1" },
    { id: "200132", name: "TCTEX1D1" },
    { id: "255758", name: "TCTEX1D2" },
    { id: "343521", name: "TCTEX1D4" },
    { id: "79600", name: "TCTN1" },
    { id: "79867", name: "TCTN2" },
    { id: "26123", name: "TCTN3" },
    { id: "6996", name: "TDG" },
    { id: "6997", name: "TDGF1" },
    { id: "6999", name: "TDO2" },
    { id: "55775", name: "TDP1" },
    { id: "51567", name: "TDP2" },
    { id: "56165", name: "TDRD1" },
    { id: "91646", name: "TDRD12" },
    { id: "81550", name: "TDRD3" },
    { id: "221400", name: "TDRD6" },
    { id: "23424", name: "TDRD7" },
    { id: "122402", name: "TDRD9" },
    { id: "11022", name: "TDRKH" },
    { id: "157695", name: "TDRP" },
    { id: "7003", name: "TEAD1" },
    { id: "8463", name: "TEAD2" },
    { id: "7005", name: "TEAD3" },
    { id: "7004", name: "TEAD4" },
    { id: "7006", name: "TEC" },
    { id: "25851", name: "TECPR1" },
    { id: "9524", name: "TECR" },
    { id: "7008", name: "TEF" },
    { id: "79736", name: "TEFM" },
    { id: "7010", name: "TEK" },
    { id: "83659", name: "TEKT1" },
    { id: "64518", name: "TEKT3" },
    { id: "150483", name: "TEKT4" },
    { id: "146279", name: "TEKT5" },
    { id: "9894", name: "TELO2" },
    { id: "100134934", name: "TEN1" },
    { id: "23371", name: "TENC1" },
    { id: "10178", name: "TENM1" },
    { id: "57451", name: "TENM2" },
    { id: "55714", name: "TENM3" },
    { id: "26011", name: "TENM4" },
    { id: "7011", name: "TEP1" },
    { id: "7012", name: "TERC" },
    { id: "7013", name: "TERF1" },
    { id: "7014", name: "TERF2" },
    { id: "54386", name: "TERF2IP" },
    { id: "7015", name: "TERT" },
    { id: "26136", name: "TES" },
    { id: "54997", name: "TESC" },
    { id: "7016", name: "TESK1" },
    { id: "10420", name: "TESK2" },
    { id: "9840", name: "TESPA1" },
    { id: "80312", name: "TET1" },
    { id: "54790", name: "TET2" },
    { id: "200424", name: "TET3" },
    { id: "54881", name: "TEX10" },
    { id: "83639", name: "TEX101" },
    { id: "56159", name: "TEX11" },
    { id: "56158", name: "TEX12" },
    { id: "56157", name: "TEX13A" },
    { id: "56156", name: "TEX13B" },
    { id: "56155", name: "TEX14" },
    { id: "56154", name: "TEX15" },
    { id: "400629", name: "TEX19" },
    { id: "55852", name: "TEX2" },
    { id: "51368", name: "TEX264" },
    { id: "1527", name: "TEX28" },
    { id: "93081", name: "TEX30" },
    { id: "339669", name: "TEX33" },
    { id: "84066", name: "TEX35" },
    { id: "387718", name: "TEX36" },
    { id: "200523", name: "TEX37" },
    { id: "25858", name: "TEX40" },
    { id: "374618", name: "TEX9" },
    { id: "7018", name: "TF" },
    { id: "7019", name: "TFAM" },
    { id: "7020", name: "TFAP2A" },
    { id: "7021", name: "TFAP2B" },
    { id: "7022", name: "TFAP2C" },
    { id: "83741", name: "TFAP2D" },
    { id: "339488", name: "TFAP2E" },
    { id: "7023", name: "TFAP4" },
    { id: "51106", name: "TFB1M" },
    { id: "64216", name: "TFB2M" },
    { id: "7024", name: "TFCP2" },
    { id: "29842", name: "TFCP2L1" },
    { id: "7027", name: "TFDP1" },
    { id: "7029", name: "TFDP2" },
    { id: "51270", name: "TFDP3" },
    { id: "7030", name: "TFE3" },
    { id: "7942", name: "TFEB" },
    { id: "22797", name: "TFEC" },
    { id: "7031", name: "TFF1" },
    { id: "7032", name: "TFF2" },
    { id: "7033", name: "TFF3" },
    { id: "10342", name: "TFG" },
    { id: "24144", name: "TFIP11" },
    { id: "7035", name: "TFPI" },
    { id: "7980", name: "TFPI2" },
    { id: "29844", name: "TFPT" },
    { id: "7036", name: "TFR2" },
    { id: "7037", name: "TFRC" },
    { id: "7038", name: "TG" },
    { id: "23483", name: "TGDS" },
    { id: "7039", name: "TGFA" },
    { id: "7040", name: "TGFB1" },
    { id: "7041", name: "TGFB1I1" },
    { id: "7042", name: "TGFB2" },
    { id: "7043", name: "TGFB3" },
    { id: "7045", name: "TGFBI" },
    { id: "7046", name: "TGFBR1" },
    { id: "7048", name: "TGFBR2" },
    { id: "7049", name: "TGFBR3" },
    { id: "9392", name: "TGFBRAP1" },
    { id: "7050", name: "TGIF1" },
    { id: "60436", name: "TGIF2" },
    { id: "90655", name: "TGIF2LY" },
    { id: "7051", name: "TGM1" },
    { id: "7052", name: "TGM2" },
    { id: "7053", name: "TGM3" },
    { id: "9333", name: "TGM5" },
    { id: "116179", name: "TGM7" },
    { id: "10618", name: "TGOLN2" },
    { id: "96764", name: "TGS1" },
    { id: "7054", name: "TH" },
    { id: "63892", name: "THADA" },
    { id: "55145", name: "THAP1" },
    { id: "56906", name: "THAP10" },
    { id: "57215", name: "THAP11" },
    { id: "83591", name: "THAP2" },
    { id: "90326", name: "THAP3" },
    { id: "51078", name: "THAP4" },
    { id: "168451", name: "THAP5" },
    { id: "152815", name: "THAP6" },
    { id: "80764", name: "THAP7" },
    { id: "199745", name: "THAP8" },
    { id: "79725", name: "THAP9" },
    { id: "7056", name: "THBD" },
    { id: "7057", name: "THBS1" },
    { id: "7058", name: "THBS2" },
    { id: "7059", name: "THBS3" },
    { id: "51298", name: "THEG" },
    { id: "117145", name: "THEM4" },
    { id: "284486", name: "THEM5" },
    { id: "51337", name: "THEM6" },
    { id: "387357", name: "THEMIS" },
    { id: "54974", name: "THG1L" },
    { id: "79896", name: "THNSL1" },
    { id: "55258", name: "THNSL2" },
    { id: "9984", name: "THOC1" },
    { id: "57187", name: "THOC2" },
    { id: "84321", name: "THOC3" },
    { id: "8563", name: "THOC5" },
    { id: "79228", name: "THOC6" },
    { id: "80145", name: "THOC7" },
    { id: "7064", name: "THOP1" },
    { id: "7066", name: "THPO" },
    { id: "7067", name: "THRA" },
    { id: "9967", name: "THRAP3" },
    { id: "7068", name: "THRB" },
    { id: "7069", name: "THRSP" },
    { id: "221981", name: "THSD7A" },
    { id: "80731", name: "THSD7B" },
    { id: "79178", name: "THTPA" },
    { id: "55623", name: "THUMPD1" },
    { id: "80745", name: "THUMPD2" },
    { id: "25917", name: "THUMPD3" },
    { id: "7070", name: "THY1" },
    { id: "29087", name: "THYN1" },
    { id: "7072", name: "TIA1" },
    { id: "9220", name: "TIAF1" },
    { id: "7073", name: "TIAL1" },
    { id: "7074", name: "TIAM1" },
    { id: "26230", name: "TIAM2" },
    { id: "148022", name: "TICAM1" },
    { id: "353376", name: "TICAM2" },
    { id: "90381", name: "TICRR" },
    { id: "7075", name: "TIE1" },
    { id: "92610", name: "TIFA" },
    { id: "497189", name: "TIFAB" },
    { id: "200765", name: "TIGD1" },
    { id: "166815", name: "TIGD2" },
    { id: "220359", name: "TIGD3" },
    { id: "201798", name: "TIGD4" },
    { id: "84948", name: "TIGD5" },
    { id: "81789", name: "TIGD6" },
    { id: "91151", name: "TIGD7" },
    { id: "201633", name: "TIGIT" },
    { id: "8914", name: "TIMELESS" },
    { id: "26519", name: "TIMM10" },
    { id: "26517", name: "TIMM13" },
    { id: "10440", name: "TIMM17A" },
    { id: "10245", name: "TIMM17B" },
    { id: "29090", name: "TIMM21" },
    { id: "29928", name: "TIMM22" },
    { id: "100287932", name: "TIMM23" },
    { id: "10469", name: "TIMM44" },
    { id: "92609", name: "TIMM50" },
    { id: "26521", name: "TIMM8B" },
    { id: "26520", name: "TIMM9" },
    { id: "51300", name: "TIMMDC1" },
    { id: "7076", name: "TIMP1" },
    { id: "7077", name: "TIMP2" },
    { id: "7078", name: "TIMP3" },
    { id: "7079", name: "TIMP4" },
    { id: "27283", name: "TINAG" },
    { id: "64129", name: "TINAGL1" },
    { id: "257000", name: "TINCR" },
    { id: "26277", name: "TINF2" },
    { id: "25976", name: "TIPARP" },
    { id: "54962", name: "TIPIN" },
    { id: "261726", name: "TIPRL" },
    { id: "114609", name: "TIRAP" },
    { id: "93643", name: "TJAP1" },
    { id: "7082", name: "TJP1" },
    { id: "9414", name: "TJP2" },
    { id: "27134", name: "TJP3" },
    { id: "7083", name: "TK1" },
    { id: "7086", name: "TKT" },
    { id: "84076", name: "TKTL2" },
    { id: "116238", name: "TLCD1" },
    { id: "727910", name: "TLCD2" },
    { id: "57707", name: "TLDC1" },
    { id: "7088", name: "TLE1" },
    { id: "7089", name: "TLE2" },
    { id: "7090", name: "TLE3" },
    { id: "7091", name: "TLE4" },
    { id: "79816", name: "TLE6" },
    { id: "9874", name: "TLK1" },
    { id: "11011", name: "TLK2" },
    { id: "7092", name: "TLL1" },
    { id: "7094", name: "TLN1" },
    { id: "83660", name: "TLN2" },
    { id: "7096", name: "TLR1" },
    { id: "81793", name: "TLR10" },
    { id: "7097", name: "TLR2" },
    { id: "7098", name: "TLR3" },
    { id: "7099", name: "TLR4" },
    { id: "7100", name: "TLR5" },
    { id: "10333", name: "TLR6" },
    { id: "51284", name: "TLR7" },
    { id: "51311", name: "TLR8" },
    { id: "54106", name: "TLR9" },
    { id: "3195", name: "TLX1" },
    { id: "30012", name: "TLX3" },
    { id: "83941", name: "TM2D1" },
    { id: "83877", name: "TM2D2" },
    { id: "80213", name: "TM2D3" },
    { id: "4071", name: "TM4SF1" },
    { id: "116211", name: "TM4SF19" },
    { id: "79853", name: "TM4SF20" },
    { id: "7104", name: "TM4SF4" },
    { id: "53346", name: "TM6SF1" },
    { id: "53345", name: "TM6SF2" },
    { id: "7108", name: "TM7SF2" },
    { id: "51768", name: "TM7SF3" },
    { id: "10548", name: "TM9SF1" },
    { id: "9375", name: "TM9SF2" },
    { id: "56889", name: "TM9SF3" },
    { id: "9777", name: "TM9SF4" },
    { id: "55319", name: "TMA16" },
    { id: "51372", name: "TMA7" },
    { id: "64114", name: "TMBIM1" },
    { id: "51643", name: "TMBIM4" },
    { id: "7009", name: "TMBIM6" },
    { id: "117531", name: "TMC1" },
    { id: "79838", name: "TMC5" },
    { id: "11322", name: "TMC6" },
    { id: "79905", name: "TMC7" },
    { id: "23023", name: "TMCC1" },
    { id: "9911", name: "TMCC2" },
    { id: "57458", name: "TMCC3" },
    { id: "54499", name: "TMCO1" },
    { id: "127391", name: "TMCO2" },
    { id: "55002", name: "TMCO3" },
    { id: "255104", name: "TMCO4" },
    { id: "145942", name: "TMCO5A" },
    { id: "55374", name: "TMCO6" },
    { id: "11018", name: "TMED1" },
    { id: "10972", name: "TMED10" },
    { id: "10959", name: "TMED2" },
    { id: "23423", name: "TMED3" },
    { id: "222068", name: "TMED4" },
    { id: "50999", name: "TMED5" },
    { id: "51014", name: "TMED7" },
    { id: "283578", name: "TMED8" },
    { id: "54732", name: "TMED9" },
    { id: "8577", name: "TMEFF1" },
    { id: "23671", name: "TMEFF2" },
    { id: "55273", name: "TMEM100" },
    { id: "84336", name: "TMEM101" },
    { id: "284114", name: "TMEM102" },
    { id: "54868", name: "TMEM104" },
    { id: "113277", name: "TMEM106A" },
    { id: "54664", name: "TMEM106B" },
    { id: "79022", name: "TMEM106C" },
    { id: "84314", name: "TMEM107" },
    { id: "66000", name: "TMEM108" },
    { id: "79073", name: "TMEM109" },
    { id: "8834", name: "TMEM11" },
    { id: "11070", name: "TMEM115" },
    { id: "89894", name: "TMEM116" },
    { id: "83862", name: "TMEM120A" },
    { id: "144404", name: "TMEM120B" },
    { id: "80757", name: "TMEM121" },
    { id: "114908", name: "TMEM123" },
    { id: "84233", name: "TMEM126A" },
    { id: "55863", name: "TMEM126B" },
    { id: "55654", name: "TMEM127" },
    { id: "85013", name: "TMEM128" },
    { id: "92305", name: "TMEM129" },
    { id: "23505", name: "TMEM131" },
    { id: "54972", name: "TMEM132A" },
    { id: "92293", name: "TMEM132C" },
    { id: "121256", name: "TMEM132D" },
    { id: "65084", name: "TMEM135" },
    { id: "135932", name: "TMEM139" },
    { id: "55281", name: "TMEM140" },
    { id: "85014", name: "TMEM141" },
    { id: "55260", name: "TMEM143" },
    { id: "284339", name: "TMEM145" },
    { id: "10430", name: "TMEM147" },
    { id: "28978", name: "TMEM14A" },
    { id: "81853", name: "TMEM14B" },
    { id: "51522", name: "TMEM14C" },
    { id: "129303", name: "TMEM150A" },
    { id: "441027", name: "TMEM150C" },
    { id: "201799", name: "TMEM154" },
    { id: "57146", name: "TMEM159" },
    { id: "54958", name: "TMEM160" },
    { id: "54929", name: "TMEM161A" },
    { id: "153396", name: "TMEM161B" },
    { id: "84187", name: "TMEM164" },
    { id: "55858", name: "TMEM165" },
    { id: "64418", name: "TMEM168" },
    { id: "92691", name: "TMEM169" },
    { id: "200728", name: "TMEM17" },
    { id: "124491", name: "TMEM170A" },
    { id: "100113407", name: "TMEM170B" },
    { id: "134285", name: "TMEM171" },
    { id: "340061", name: "TMEM173" },
    { id: "134288", name: "TMEM174" },
    { id: "84286", name: "TMEM175" },
    { id: "55365", name: "TMEM176A" },
    { id: "80775", name: "TMEM177" },
    { id: "374395", name: "TMEM179B" },
    { id: "129787", name: "TMEM18" },
    { id: "79847", name: "TMEM180" },
    { id: "130827", name: "TMEM182" },
    { id: "92703", name: "TMEM183A" },
    { id: "653659", name: "TMEM183B" },
    { id: "202915", name: "TMEM184A" },
    { id: "25829", name: "TMEM184B" },
    { id: "55751", name: "TMEM184C" },
    { id: "79134", name: "TMEM185B" },
    { id: "25880", name: "TMEM186" },
    { id: "8269", name: "TMEM187" },
    { id: "387521", name: "TMEM189" },
    { id: "387522", name: "TMEM189-UBE2V1" },
    { id: "55266", name: "TMEM19" },
    { id: "147744", name: "TMEM190" },
    { id: "201931", name: "TMEM192" },
    { id: "23306", name: "TMEM194A" },
    { id: "100131211", name: "TMEM194B" },
    { id: "256130", name: "TMEM196" },
    { id: "147007", name: "TMEM199" },
    { id: "23670", name: "TMEM2" },
    { id: "199953", name: "TMEM201" },
    { id: "94107", name: "TMEM203" },
    { id: "374882", name: "TMEM205" },
    { id: "55248", name: "TMEM206" },
    { id: "131920", name: "TMEM207" },
    { id: "29100", name: "TMEM208" },
    { id: "84928", name: "TMEM209" },
    { id: "155006", name: "TMEM213" },
    { id: "54867", name: "TMEM214" },
    { id: "51259", name: "TMEM216" },
    { id: "219854", name: "TMEM218" },
    { id: "124446", name: "TMEM219" },
    { id: "84065", name: "TMEM222" },
    { id: "79064", name: "TMEM223" },
    { id: "338661", name: "TMEM225" },
    { id: "161145", name: "TMEM229B" },
    { id: "29058", name: "TMEM230" },
    { id: "79583", name: "TMEM231" },
    { id: "387890", name: "TMEM233" },
    { id: "56063", name: "TMEM234" },
    { id: "653567", name: "TMEM236" },
    { id: "65062", name: "TMEM237" },
    { id: "100288797", name: "TMEM239" },
    { id: "85019", name: "TMEM241" },
    { id: "729515", name: "TMEM242" },
    { id: "79161", name: "TMEM243" },
    { id: "23731", name: "TMEM245" },
    { id: "84302", name: "TMEM246" },
    { id: "55069", name: "TMEM248" },
    { id: "84866", name: "TMEM25" },
    { id: "169693", name: "TMEM252" },
    { id: "643382", name: "TMEM253" },
    { id: "80195", name: "TMEM254" },
    { id: "55026", name: "TMEM255A" },
    { id: "348013", name: "TMEM255B" },
    { id: "254863", name: "TMEM256" },
    { id: "91304", name: "TMEM259" },
    { id: "55754", name: "TMEM30A" },
    { id: "161291", name: "TMEM30B" },
    { id: "203562", name: "TMEM31" },
    { id: "55161", name: "TMEM33" },
    { id: "59353", name: "TMEM35" },
    { id: "140738", name: "TMEM37" },
    { id: "55151", name: "TMEM38B" },
    { id: "55254", name: "TMEM39A" },
    { id: "55116", name: "TMEM39B" },
    { id: "90407", name: "TMEM41A" },
    { id: "440026", name: "TMEM41B" },
    { id: "131616", name: "TMEM42" },
    { id: "79188", name: "TMEM43" },
    { id: "93109", name: "TMEM44" },
    { id: "55076", name: "TMEM45A" },
    { id: "120224", name: "TMEM45B" },
    { id: "10329", name: "TMEM5" },
    { id: "23585", name: "TMEM50A" },
    { id: "55092", name: "TMEM51" },
    { id: "339456", name: "TMEM52" },
    { id: "79639", name: "TMEM53" },
    { id: "113452", name: "TMEM54" },
    { id: "55529", name: "TMEM55A" },
    { id: "90809", name: "TMEM55B" },
    { id: "148534", name: "TMEM56" },
    { id: "55219", name: "TMEM57" },
    { id: "9528", name: "TMEM59" },
    { id: "25789", name: "TMEM59L" },
    { id: "85025", name: "TMEM60" },
    { id: "80021", name: "TMEM62" },
    { id: "9725", name: "TMEM63A" },
    { id: "55362", name: "TMEM63B" },
    { id: "169200", name: "TMEM64" },
    { id: "157378", name: "TMEM65" },
    { id: "51669", name: "TMEM66" },
    { id: "91147", name: "TMEM67" },
    { id: "137695", name: "TMEM68" },
    { id: "54968", name: "TMEM70" },
    { id: "643236", name: "TMEM72" },
    { id: "157753", name: "TMEM74" },
    { id: "55321", name: "TMEM74B" },
    { id: "84283", name: "TMEM79" },
    { id: "144110", name: "TMEM86A" },
    { id: "255043", name: "TMEM86B" },
    { id: "25963", name: "TMEM87A" },
    { id: "440955", name: "TMEM89" },
    { id: "58986", name: "TMEM8A" },
    { id: "51754", name: "TMEM8B" },
    { id: "252839", name: "TMEM9" },
    { id: "641649", name: "TMEM91" },
    { id: "162461", name: "TMEM92" },
    { id: "339168", name: "TMEM95" },
    { id: "27346", name: "TMEM97" },
    { id: "26022", name: "TMEM98" },
    { id: "56674", name: "TMEM9B" },
    { id: "7110", name: "TMF1" },
    { id: "259236", name: "TMIE" },
    { id: "126259", name: "TMIGD2" },
    { id: "55217", name: "TMLHE" },
    { id: "7111", name: "TMOD1" },
    { id: "29767", name: "TMOD2" },
    { id: "29766", name: "TMOD3" },
    { id: "29765", name: "TMOD4" },
    { id: "7112", name: "TMPO" },
    { id: "643853", name: "TMPPE" },
    { id: "339967", name: "TMPRSS11A" },
    { id: "9407", name: "TMPRSS11D" },
    { id: "28983", name: "TMPRSS11E" },
    { id: "84000", name: "TMPRSS13" },
    { id: "7113", name: "TMPRSS2" },
    { id: "64699", name: "TMPRSS3" },
    { id: "56649", name: "TMPRSS4" },
    { id: "164656", name: "TMPRSS6" },
    { id: "9168", name: "TMSB10" },
    { id: "7114", name: "TMSB4X" },
    { id: "9087", name: "TMSB4Y" },
    { id: "83857", name: "TMTC1" },
    { id: "160335", name: "TMTC2" },
    { id: "160418", name: "TMTC3" },
    { id: "83590", name: "TMUB1" },
    { id: "79089", name: "TMUB2" },
    { id: "81542", name: "TMX1" },
    { id: "51075", name: "TMX2" },
    { id: "54495", name: "TMX3" },
    { id: "56255", name: "TMX4" },
    { id: "445341", name: "TNAP" },
    { id: "3371", name: "TNC" },
    { id: "7124", name: "TNF" },
    { id: "7126", name: "TNFAIP1" },
    { id: "7127", name: "TNFAIP2" },
    { id: "7128", name: "TNFAIP3" },
    { id: "7130", name: "TNFAIP6" },
    { id: "25816", name: "TNFAIP8" },
    { id: "126282", name: "TNFAIP8L1" },
    { id: "79626", name: "TNFAIP8L2" },
    { id: "388121", name: "TNFAIP8L3" },
    { id: "8797", name: "TNFRSF10A" },
    { id: "8795", name: "TNFRSF10B" },
    { id: "8794", name: "TNFRSF10C" },
    { id: "8793", name: "TNFRSF10D" },
    { id: "8792", name: "TNFRSF11A" },
    { id: "4982", name: "TNFRSF11B" },
    { id: "51330", name: "TNFRSF12A" },
    { id: "23495", name: "TNFRSF13B" },
    { id: "115650", name: "TNFRSF13C" },
    { id: "8764", name: "TNFRSF14" },
    { id: "8784", name: "TNFRSF18" },
    { id: "55504", name: "TNFRSF19" },
    { id: "7132", name: "TNFRSF1A" },
    { id: "7133", name: "TNFRSF1B" },
    { id: "27242", name: "TNFRSF21" },
    { id: "8718", name: "TNFRSF25" },
    { id: "7293", name: "TNFRSF4" },
    { id: "8771", name: "TNFRSF6B" },
    { id: "943", name: "TNFRSF8" },
    { id: "8743", name: "TNFSF10" },
    { id: "8600", name: "TNFSF11" },
    { id: "8742", name: "TNFSF12" },
    { id: "8741", name: "TNFSF13" },
    { id: "10673", name: "TNFSF13B" },
    { id: "8740", name: "TNFSF14" },
    { id: "9966", name: "TNFSF15" },
    { id: "8995", name: "TNFSF18" },
    { id: "7292", name: "TNFSF4" },
    { id: "944", name: "TNFSF8" },
    { id: "8744", name: "TNFSF9" },
    { id: "23043", name: "TNIK" },
    { id: "10318", name: "TNIP1" },
    { id: "79155", name: "TNIP2" },
    { id: "79931", name: "TNIP3" },
    { id: "8711", name: "TNK1" },
    { id: "10188", name: "TNK2" },
    { id: "8658", name: "TNKS" },
    { id: "85456", name: "TNKS1BP1" },
    { id: "80351", name: "TNKS2" },
    { id: "64102", name: "TNMD" },
    { id: "63923", name: "TNN" },
    { id: "7134", name: "TNNC1" },
    { id: "7135", name: "TNNI1" },
    { id: "7136", name: "TNNI2" },
    { id: "7137", name: "TNNI3" },
    { id: "51086", name: "TNNI3K" },
    { id: "7138", name: "TNNT1" },
    { id: "7139", name: "TNNT2" },
    { id: "7140", name: "TNNT3" },
    { id: "7141", name: "TNP1" },
    { id: "7142", name: "TNP2" },
    { id: "3842", name: "TNPO1" },
    { id: "30000", name: "TNPO2" },
    { id: "23534", name: "TNPO3" },
    { id: "7143", name: "TNR" },
    { id: "84629", name: "TNRC18" },
    { id: "27327", name: "TNRC6A" },
    { id: "23112", name: "TNRC6B" },
    { id: "57690", name: "TNRC6C" },
    { id: "7145", name: "TNS1" },
    { id: "64759", name: "TNS3" },
    { id: "84951", name: "TNS4" },
    { id: "7148", name: "TNXB" },
    { id: "10140", name: "TOB1" },
    { id: "10766", name: "TOB2" },
    { id: "114034", name: "TOE1" },
    { id: "54472", name: "TOLLIP" },
    { id: "10043", name: "TOM1" },
    { id: "10040", name: "TOM1L1" },
    { id: "146691", name: "TOM1L2" },
    { id: "9804", name: "TOMM20" },
    { id: "387990", name: "TOMM20L" },
    { id: "56993", name: "TOMM22" },
    { id: "10953", name: "TOMM34" },
    { id: "10452", name: "TOMM40" },
    { id: "84134", name: "TOMM40L" },
    { id: "401505", name: "TOMM5" },
    { id: "100188893", name: "TOMM6" },
    { id: "54543", name: "TOMM7" },
    { id: "9868", name: "TOMM70A" },
    { id: "4796", name: "TONSL" },
    { id: "7150", name: "TOP1" },
    { id: "116447", name: "TOP1MT" },
    { id: "7153", name: "TOP2A" },
    { id: "7155", name: "TOP2B" },
    { id: "7156", name: "TOP3A" },
    { id: "8940", name: "TOP3B" },
    { id: "375337", name: "TOPAZ1" },
    { id: "11073", name: "TOPBP1" },
    { id: "10210", name: "TOPORS" },
    { id: "1861", name: "TOR1A" },
    { id: "26092", name: "TOR1AIP1" },
    { id: "163590", name: "TOR1AIP2" },
    { id: "27348", name: "TOR1B" },
    { id: "64222", name: "TOR3A" },
    { id: "54863", name: "TOR4A" },
    { id: "9760", name: "TOX" },
    { id: "84969", name: "TOX2" },
    { id: "27324", name: "TOX3" },
    { id: "9878", name: "TOX4" },
    { id: "7157", name: "TP53" },
    { id: "63970", name: "TP53AIP1" },
    { id: "7158", name: "TP53BP1" },
    { id: "7159", name: "TP53BP2" },
    { id: "9537", name: "TP53I11" },
    { id: "90313", name: "TP53I13" },
    { id: "9540", name: "TP53I3" },
    { id: "94241", name: "TP53INP1" },
    { id: "58476", name: "TP53INP2" },
    { id: "112858", name: "TP53RK" },
    { id: "11257", name: "TP53TG1" },
    { id: "27296", name: "TP53TG5" },
    { id: "8626", name: "TP63" },
    { id: "7161", name: "TP73" },
    { id: "57212", name: "TP73-AS1" },
    { id: "7162", name: "TPBG" },
    { id: "100507050", name: "TPBGL" },
    { id: "53373", name: "TPCN1" },
    { id: "219931", name: "TPCN2" },
    { id: "7163", name: "TPD52" },
    { id: "7164", name: "TPD52L1" },
    { id: "7165", name: "TPD52L2" },
    { id: "89882", name: "TPD52L3" },
    { id: "91978", name: "TPGS1" },
    { id: "25941", name: "TPGS2" },
    { id: "7166", name: "TPH1" },
    { id: "121278", name: "TPH2" },
    { id: "7167", name: "TPI1" },
    { id: "27010", name: "TPK1" },
    { id: "7168", name: "TPM1" },
    { id: "7169", name: "TPM2" },
    { id: "7170", name: "TPM3" },
    { id: "7171", name: "TPM4" },
    { id: "7172", name: "TPMT" },
    { id: "1200", name: "TPP1" },
    { id: "7174", name: "TPP2" },
    { id: "11076", name: "TPPP" },
    { id: "51673", name: "TPPP3" },
    { id: "7175", name: "TPR" },
    { id: "131601", name: "TPRA1" },
    { id: "127262", name: "TPRG1L" },
    { id: "51002", name: "TPRKB" },
    { id: "286262", name: "TPRN" },
    { id: "284355", name: "TPRX1" },
    { id: "7177", name: "TPSAB1" },
    { id: "64499", name: "TPSB2" },
    { id: "8460", name: "TPST1" },
    { id: "8459", name: "TPST2" },
    { id: "7178", name: "TPT1" },
    { id: "7179", name: "TPTE" },
    { id: "93492", name: "TPTE2" },
    { id: "22974", name: "TPX2" },
    { id: "6955", name: "TRA" },
    { id: "29896", name: "TRA2A" },
    { id: "6434", name: "TRA2B" },
    { id: "80305", name: "TRABD" },
    { id: "129293", name: "TRABD2A" },
    { id: "388630", name: "TRABD2B" },
    { id: "28755", name: "TRAC" },
    { id: "8717", name: "TRADD" },
    { id: "7185", name: "TRAF1" },
    { id: "7186", name: "TRAF2" },
    { id: "7187", name: "TRAF3" },
    { id: "26146", name: "TRAF3IP1" },
    { id: "10758", name: "TRAF3IP2" },
    { id: "80342", name: "TRAF3IP3" },
    { id: "9618", name: "TRAF4" },
    { id: "7188", name: "TRAF5" },
    { id: "7189", name: "TRAF6" },
    { id: "84231", name: "TRAF7" },
    { id: "10906", name: "TRAFD1" },
    { id: "10293", name: "TRAIP" },
    { id: "22906", name: "TRAK1" },
    { id: "66008", name: "TRAK2" },
    { id: "23471", name: "TRAM1" },
    { id: "133022", name: "TRAM1L1" },
    { id: "9697", name: "TRAM2" },
    { id: "9881", name: "TRANK1" },
    { id: "10131", name: "TRAP1" },
    { id: "58485", name: "TRAPPC1" },
    { id: "7109", name: "TRAPPC10" },
    { id: "60684", name: "TRAPPC11" },
    { id: "51112", name: "TRAPPC12" },
    { id: "80006", name: "TRAPPC13" },
    { id: "6399", name: "TRAPPC2" },
    { id: "51693", name: "TRAPPC2L" },
    { id: "10597", name: "TRAPPC2P1" },
    { id: "27095", name: "TRAPPC3" },
    { id: "100128327", name: "TRAPPC3L" },
    { id: "51399", name: "TRAPPC4" },
    { id: "126003", name: "TRAPPC5" },
    { id: "79090", name: "TRAPPC6A" },
    { id: "122553", name: "TRAPPC6B" },
    { id: "22878", name: "TRAPPC8" },
    { id: "83696", name: "TRAPPC9" },
    { id: "50852", name: "TRAT1" },
    { id: "6957", name: "TRB" },
    { id: "6964", name: "TRD" },
    { id: "1787", name: "TRDMT1" },
    { id: "10345", name: "TRDN" },
    { id: "54210", name: "TREM1" },
    { id: "54209", name: "TREM2" },
    { id: "340205", name: "TREML1" },
    { id: "55809", name: "TRERF1" },
    { id: "11277", name: "TREX1" },
    { id: "11219", name: "TREX2" },
    { id: "6965", name: "TRG" },
    { id: "7200", name: "TRH" },
    { id: "29953", name: "TRHDE" },
    { id: "7201", name: "TRHR" },
    { id: "51499", name: "TRIAP1" },
    { id: "10221", name: "TRIB1" },
    { id: "28951", name: "TRIB2" },
    { id: "57761", name: "TRIB3" },
    { id: "9865", name: "TRIL" },
    { id: "10107", name: "TRIM10" },
    { id: "81559", name: "TRIM11" },
    { id: "10206", name: "TRIM13" },
    { id: "9830", name: "TRIM14" },
    { id: "89870", name: "TRIM15" },
    { id: "10626", name: "TRIM16" },
    { id: "147166", name: "TRIM16L" },
    { id: "51127", name: "TRIM17" },
    { id: "23321", name: "TRIM2" },
    { id: "6737", name: "TRIM21" },
    { id: "10346", name: "TRIM22" },
    { id: "373", name: "TRIM23" },
    { id: "8805", name: "TRIM24" },
    { id: "7706", name: "TRIM25" },
    { id: "7726", name: "TRIM26" },
    { id: "5987", name: "TRIM27" },
    { id: "10155", name: "TRIM28" },
    { id: "23650", name: "TRIM29" },
    { id: "10612", name: "TRIM3" },
    { id: "11074", name: "TRIM31" },
    { id: "22954", name: "TRIM32" },
    { id: "51592", name: "TRIM33" },
    { id: "53840", name: "TRIM34" },
    { id: "23087", name: "TRIM35" },
    { id: "55521", name: "TRIM36" },
    { id: "4591", name: "TRIM37" },
    { id: "10475", name: "TRIM38" },
    { id: "56658", name: "TRIM39" },
    { id: "89122", name: "TRIM4" },
    { id: "135644", name: "TRIM40" },
    { id: "90933", name: "TRIM41" },
    { id: "287015", name: "TRIM42" },
    { id: "129868", name: "TRIM43" },
    { id: "54765", name: "TRIM44" },
    { id: "80263", name: "TRIM45" },
    { id: "80128", name: "TRIM46" },
    { id: "91107", name: "TRIM47" },
    { id: "79097", name: "TRIM48" },
    { id: "57093", name: "TRIM49" },
    { id: "283116", name: "TRIM49B" },
    { id: "642612", name: "TRIM49C" },
    { id: "85363", name: "TRIM5" },
    { id: "135892", name: "TRIM50" },
    { id: "84767", name: "TRIM51" },
    { id: "84851", name: "TRIM52" },
    { id: "57159", name: "TRIM54" },
    { id: "84675", name: "TRIM55" },
    { id: "81844", name: "TRIM56" },
    { id: "25893", name: "TRIM58" },
    { id: "286827", name: "TRIM59" },
    { id: "117854", name: "TRIM6" },
    { id: "166655", name: "TRIM60" },
    { id: "55223", name: "TRIM62" },
    { id: "84676", name: "TRIM63" },
    { id: "646754", name: "TRIM64C" },
    { id: "201292", name: "TRIM65" },
    { id: "55128", name: "TRIM68" },
    { id: "140691", name: "TRIM69" },
    { id: "445372", name: "TRIM6-TRIM34" },
    { id: "81786", name: "TRIM7" },
    { id: "131405", name: "TRIM71" },
    { id: "493829", name: "TRIM72" },
    { id: "375593", name: "TRIM73" },
    { id: "378108", name: "TRIM74" },
    { id: "81603", name: "TRIM8" },
    { id: "114088", name: "TRIM9" },
    { id: "339976", name: "TRIML1" },
    { id: "205860", name: "TRIML2" },
    { id: "7204", name: "TRIO" },
    { id: "11078", name: "TRIOBP" },
    { id: "9322", name: "TRIP10" },
    { id: "9321", name: "TRIP11" },
    { id: "9320", name: "TRIP12" },
    { id: "9319", name: "TRIP13" },
    { id: "9325", name: "TRIP4" },
    { id: "7205", name: "TRIP6" },
    { id: "54802", name: "TRIT1" },
    { id: "55621", name: "TRMT1" },
    { id: "93587", name: "TRMT10A" },
    { id: "158234", name: "TRMT10B" },
    { id: "54931", name: "TRMT10C" },
    { id: "60487", name: "TRMT11" },
    { id: "51504", name: "TRMT112" },
    { id: "54482", name: "TRMT13" },
    { id: "81627", name: "TRMT1L" },
    { id: "27037", name: "TRMT2A" },
    { id: "79979", name: "TRMT2B" },
    { id: "152992", name: "TRMT44" },
    { id: "57570", name: "TRMT5" },
    { id: "51605", name: "TRMT6" },
    { id: "115708", name: "TRMT61A" },
    { id: "55006", name: "TRMT61B" },
    { id: "55687", name: "TRMU" },
    { id: "54952", name: "TRNAU1AP" },
    { id: "51095", name: "TRNT1" },
    { id: "7216", name: "TRO" },
    { id: "10024", name: "TROAP" },
    { id: "6738", name: "TROVE2" },
    { id: "8989", name: "TRPA1" },
    { id: "7220", name: "TRPC1" },
    { id: "7222", name: "TRPC3" },
    { id: "7223", name: "TRPC4" },
    { id: "26133", name: "TRPC4AP" },
    { id: "7224", name: "TRPC5" },
    { id: "7225", name: "TRPC6" },
    { id: "57113", name: "TRPC7" },
    { id: "4308", name: "TRPM1" },
    { id: "7226", name: "TRPM2" },
    { id: "54795", name: "TRPM4" },
    { id: "140803", name: "TRPM6" },
    { id: "54822", name: "TRPM7" },
    { id: "79054", name: "TRPM8" },
    { id: "7227", name: "TRPS1" },
    { id: "83707", name: "TRPT1" },
    { id: "7442", name: "TRPV1" },
    { id: "51393", name: "TRPV2" },
    { id: "162514", name: "TRPV3" },
    { id: "59341", name: "TRPV4" },
    { id: "56302", name: "TRPV5" },
    { id: "55503", name: "TRPV6" },
    { id: "8295", name: "TRRAP" },
    { id: "142940", name: "TRUB1" },
    { id: "26995", name: "TRUB2" },
    { id: "128229", name: "TSACC" },
    { id: "7248", name: "TSC1" },
    { id: "7249", name: "TSC2" },
    { id: "8848", name: "TSC22D1" },
    { id: "9819", name: "TSC22D2" },
    { id: "1831", name: "TSC22D3" },
    { id: "81628", name: "TSC22D4" },
    { id: "116461", name: "TSEN15" },
    { id: "80746", name: "TSEN2" },
    { id: "79042", name: "TSEN34" },
    { id: "283989", name: "TSEN54" },
    { id: "10102", name: "TSFM" },
    { id: "7251", name: "TSG101" },
    { id: "80705", name: "TSGA10" },
    { id: "254187", name: "TSGA10IP" },
    { id: "114960", name: "TSGA13" },
    { id: "7252", name: "TSHB" },
    { id: "7253", name: "TSHR" },
    { id: "10194", name: "TSHZ1" },
    { id: "128553", name: "TSHZ2" },
    { id: "57616", name: "TSHZ3" },
    { id: "60385", name: "TSKS" },
    { id: "25987", name: "TSKU" },
    { id: "85480", name: "TSLP" },
    { id: "7247", name: "TSN" },
    { id: "203062", name: "TSNARE1" },
    { id: "7257", name: "TSNAX" },
    { id: "55815", name: "TSNAXIP1" },
    { id: "10103", name: "TSPAN1" },
    { id: "83882", name: "TSPAN10" },
    { id: "23554", name: "TSPAN12" },
    { id: "27075", name: "TSPAN13" },
    { id: "81619", name: "TSPAN14" },
    { id: "23555", name: "TSPAN15" },
    { id: "26262", name: "TSPAN17" },
    { id: "90139", name: "TSPAN18" },
    { id: "10100", name: "TSPAN2" },
    { id: "10099", name: "TSPAN3" },
    { id: "340348", name: "TSPAN33" },
    { id: "7106", name: "TSPAN4" },
    { id: "10098", name: "TSPAN5" },
    { id: "7105", name: "TSPAN6" },
    { id: "7102", name: "TSPAN7" },
    { id: "10867", name: "TSPAN9" },
    { id: "222642", name: "TSPO2" },
    { id: "7258", name: "TSPY1" },
    { id: "64591", name: "TSPY2" },
    { id: "728137", name: "TSPY3" },
    { id: "728395", name: "TSPY4" },
    { id: "728403", name: "TSPY8" },
    { id: "7259", name: "TSPYL1" },
    { id: "64061", name: "TSPYL2" },
    { id: "23270", name: "TSPYL4" },
    { id: "85453", name: "TSPYL5" },
    { id: "388951", name: "TSPYL6" },
    { id: "55720", name: "TSR1" },
    { id: "90121", name: "TSR2" },
    { id: "115939", name: "TSR3" },
    { id: "7260", name: "TSSC1" },
    { id: "10078", name: "TSSC4" },
    { id: "83942", name: "TSSK1B" },
    { id: "23617", name: "TSSK2" },
    { id: "81629", name: "TSSK3" },
    { id: "283629", name: "TSSK4" },
    { id: "83983", name: "TSSK6" },
    { id: "7263", name: "TST" },
    { id: "7264", name: "TSTA3" },
    { id: "158427", name: "TSTD2" },
    { id: "84630", name: "TTBK1" },
    { id: "146057", name: "TTBK2" },
    { id: "7265", name: "TTC1" },
    { id: "54970", name: "TTC12" },
    { id: "79573", name: "TTC13" },
    { id: "151613", name: "TTC14" },
    { id: "55761", name: "TTC17" },
    { id: "54902", name: "TTC19" },
    { id: "199223", name: "TTC21A" },
    { id: "79809", name: "TTC21B" },
    { id: "64927", name: "TTC23" },
    { id: "153657", name: "TTC23L" },
    { id: "83538", name: "TTC25" },
    { id: "79989", name: "TTC26" },
    { id: "55622", name: "TTC27" },
    { id: "23331", name: "TTC28" },
    { id: "7267", name: "TTC3" },
    { id: "92104", name: "TTC30A" },
    { id: "150737", name: "TTC30B" },
    { id: "130502", name: "TTC32" },
    { id: "23548", name: "TTC33" },
    { id: "100287898", name: "TTC34" },
    { id: "9652", name: "TTC37" },
    { id: "55020", name: "TTC38" },
    { id: "22996", name: "TTC39A" },
    { id: "158219", name: "TTC39B" },
    { id: "125488", name: "TTC39C" },
    { id: "7268", name: "TTC4" },
    { id: "91875", name: "TTC5" },
    { id: "57217", name: "TTC7A" },
    { id: "145567", name: "TTC7B" },
    { id: "123016", name: "TTC8" },
    { id: "148014", name: "TTC9B" },
    { id: "283237", name: "TTC9C" },
    { id: "7270", name: "TTF1" },
    { id: "8458", name: "TTF2" },
    { id: "9675", name: "TTI1" },
    { id: "80185", name: "TTI2" },
    { id: "7272", name: "TTK" },
    { id: "254173", name: "TTLL10" },
    { id: "23170", name: "TTLL12" },
    { id: "9654", name: "TTLL4" },
    { id: "23093", name: "TTLL5" },
    { id: "284076", name: "TTLL6" },
    { id: "79739", name: "TTLL7" },
    { id: "164395", name: "TTLL9" },
    { id: "7273", name: "TTN" },
    { id: "7274", name: "TTPA" },
    { id: "79183", name: "TTPAL" },
    { id: "7276", name: "TTR" },
    { id: "57348", name: "TTYH1" },
    { id: "94015", name: "TTYH2" },
    { id: "80727", name: "TTYH3" },
    { id: "7275", name: "TUB" },
    { id: "7846", name: "TUBA1A" },
    { id: "10376", name: "TUBA1B" },
    { id: "84790", name: "TUBA1C" },
    { id: "7278", name: "TUBA3C" },
    { id: "112714", name: "TUBA3E" },
    { id: "7277", name: "TUBA4A" },
    { id: "80086", name: "TUBA4B" },
    { id: "51807", name: "TUBA8" },
    { id: "79861", name: "TUBAL3" },
    { id: "203068", name: "TUBB" },
    { id: "81027", name: "TUBB1" },
    { id: "7280", name: "TUBB2A" },
    { id: "347733", name: "TUBB2B" },
    { id: "10381", name: "TUBB3" },
    { id: "10382", name: "TUBB4A" },
    { id: "10383", name: "TUBB4B" },
    { id: "84617", name: "TUBB6" },
    { id: "347688", name: "TUBB8" },
    { id: "51174", name: "TUBD1" },
    { id: "51175", name: "TUBE1" },
    { id: "7283", name: "TUBG1" },
    { id: "27175", name: "TUBG2" },
    { id: "10844", name: "TUBGCP2" },
    { id: "10426", name: "TUBGCP3" },
    { id: "27229", name: "TUBGCP4" },
    { id: "114791", name: "TUBGCP5" },
    { id: "85378", name: "TUBGCP6" },
    { id: "7284", name: "TUFM" },
    { id: "7286", name: "TUFT1" },
    { id: "55000", name: "TUG1" },
    { id: "7287", name: "TULP1" },
    { id: "7288", name: "TULP2" },
    { id: "7289", name: "TULP3" },
    { id: "56995", name: "TULP4" },
    { id: "11334", name: "TUSC2" },
    { id: "7991", name: "TUSC3" },
    { id: "286753", name: "TUSC5" },
    { id: "64852", name: "TUT1" },
    { id: "780776", name: "TVP23A" },
    { id: "51030", name: "TVP23B" },
    { id: "5756", name: "TWF1" },
    { id: "11344", name: "TWF2" },
    { id: "7291", name: "TWIST1" },
    { id: "117581", name: "TWIST2" },
    { id: "221830", name: "TWISTNB" },
    { id: "57045", name: "TWSG1" },
    { id: "7294", name: "TXK" },
    { id: "200081", name: "TXLNA" },
    { id: "167838", name: "TXLNB" },
    { id: "55787", name: "TXLNG" },
    { id: "7295", name: "TXN" },
    { id: "25828", name: "TXN2" },
    { id: "51061", name: "TXNDC11" },
    { id: "51060", name: "TXNDC12" },
    { id: "79770", name: "TXNDC15" },
    { id: "57544", name: "TXNDC16" },
    { id: "84817", name: "TXNDC17" },
    { id: "84203", name: "TXNDC2" },
    { id: "81567", name: "TXNDC5" },
    { id: "10190", name: "TXNDC9" },
    { id: "10628", name: "TXNIP" },
    { id: "9352", name: "TXNL1" },
    { id: "10907", name: "TXNL4A" },
    { id: "54957", name: "TXNL4B" },
    { id: "7296", name: "TXNRD1" },
    { id: "10587", name: "TXNRD2" },
    { id: "114112", name: "TXNRD3" },
    { id: "7297", name: "TYK2" },
    { id: "1890", name: "TYMP" },
    { id: "7298", name: "TYMS" },
    { id: "7299", name: "TYR" },
    { id: "7301", name: "TYRO3" },
    { id: "7305", name: "TYROBP" },
    { id: "7306", name: "TYRP1" },
    { id: "219743", name: "TYSND1" },
    { id: "55253", name: "TYW1" },
    { id: "441250", name: "TYW1B" },
    { id: "127253", name: "TYW3" },
    { id: "7307", name: "U2AF1" },
    { id: "199746", name: "U2AF1L4" },
    { id: "11338", name: "U2AF2" },
    { id: "23350", name: "U2SURP" },
    { id: "55075", name: "UACA" },
    { id: "6675", name: "UAP1" },
    { id: "7317", name: "UBA1" },
    { id: "10054", name: "UBA2" },
    { id: "9039", name: "UBA3" },
    { id: "79876", name: "UBA5" },
    { id: "7311", name: "UBA52" },
    { id: "55236", name: "UBA6" },
    { id: "7318", name: "UBA7" },
    { id: "10422", name: "UBAC1" },
    { id: "337867", name: "UBAC2" },
    { id: "124402", name: "UBALD1" },
    { id: "51271", name: "UBAP1" },
    { id: "55833", name: "UBAP2" },
    { id: "9898", name: "UBAP2L" },
    { id: "53347", name: "UBASH3A" },
    { id: "84959", name: "UBASH3B" },
    { id: "7314", name: "UBB" },
    { id: "7316", name: "UBC" },
    { id: "10537", name: "UBD" },
    { id: "7319", name: "UBE2A" },
    { id: "7320", name: "UBE2B" },
    { id: "11065", name: "UBE2C" },
    { id: "7321", name: "UBE2D1" },
    { id: "7322", name: "UBE2D2" },
    { id: "7323", name: "UBE2D3" },
    { id: "51619", name: "UBE2D4" },
    { id: "100131816", name: "UBE2DNL" },
    { id: "7324", name: "UBE2E1" },
    { id: "7325", name: "UBE2E2" },
    { id: "10477", name: "UBE2E3" },
    { id: "140739", name: "UBE2F" },
    { id: "7326", name: "UBE2G1" },
    { id: "7327", name: "UBE2G2" },
    { id: "7328", name: "UBE2H" },
    { id: "7329", name: "UBE2I" },
    { id: "51465", name: "UBE2J1" },
    { id: "118424", name: "UBE2J2" },
    { id: "3093", name: "UBE2K" },
    { id: "7332", name: "UBE2L3" },
    { id: "9246", name: "UBE2L6" },
    { id: "9040", name: "UBE2M" },
    { id: "7334", name: "UBE2N" },
    { id: "389898", name: "UBE2NL" },
    { id: "63893", name: "UBE2O" },
    { id: "55585", name: "UBE2Q1" },
    { id: "92912", name: "UBE2Q2" },
    { id: "134111", name: "UBE2QL1" },
    { id: "54926", name: "UBE2R2" },
    { id: "27338", name: "UBE2S" },
    { id: "29089", name: "UBE2T" },
    { id: "148581", name: "UBE2U" },
    { id: "7335", name: "UBE2V1" },
    { id: "7336", name: "UBE2V2" },
    { id: "55284", name: "UBE2W" },
    { id: "65264", name: "UBE2Z" },
    { id: "7337", name: "UBE3A" },
    { id: "89910", name: "UBE3B" },
    { id: "9690", name: "UBE3C" },
    { id: "90025", name: "UBE3D" },
    { id: "9354", name: "UBE4A" },
    { id: "10277", name: "UBE4B" },
    { id: "56061", name: "UBFD1" },
    { id: "29914", name: "UBIAD1" },
    { id: "5412", name: "UBL3" },
    { id: "8266", name: "UBL4A" },
    { id: "164153", name: "UBL4B" },
    { id: "59286", name: "UBL5" },
    { id: "84993", name: "UBL7" },
    { id: "134510", name: "UBLCP1" },
    { id: "29855", name: "UBN1" },
    { id: "254048", name: "UBN2" },
    { id: "22888", name: "UBOX5" },
    { id: "7342", name: "UBP1" },
    { id: "29979", name: "UBQLN1" },
    { id: "29978", name: "UBQLN2" },
    { id: "56893", name: "UBQLN4" },
    { id: "143630", name: "UBQLNL" },
    { id: "197131", name: "UBR1" },
    { id: "23304", name: "UBR2" },
    { id: "130507", name: "UBR3" },
    { id: "23352", name: "UBR4" },
    { id: "51366", name: "UBR5" },
    { id: "55148", name: "UBR7" },
    { id: "80019", name: "UBTD1" },
    { id: "92181", name: "UBTD2" },
    { id: "7343", name: "UBTF" },
    { id: "51035", name: "UBXN1" },
    { id: "127733", name: "UBXN10" },
    { id: "91544", name: "UBXN11" },
    { id: "165324", name: "UBXN2A" },
    { id: "137886", name: "UBXN2B" },
    { id: "23190", name: "UBXN4" },
    { id: "80700", name: "UBXN6" },
    { id: "26043", name: "UBXN7" },
    { id: "7993", name: "UBXN8" },
    { id: "7345", name: "UCHL1" },
    { id: "7347", name: "UCHL3" },
    { id: "51377", name: "UCHL5" },
    { id: "83549", name: "UCK1" },
    { id: "7371", name: "UCK2" },
    { id: "54963", name: "UCKL1" },
    { id: "221044", name: "UCMA" },
    { id: "7349", name: "UCN" },
    { id: "90226", name: "UCN2" },
    { id: "114131", name: "UCN3" },
    { id: "7350", name: "UCP1" },
    { id: "7352", name: "UCP3" },
    { id: "55293", name: "UEVLD" },
    { id: "51506", name: "UFC1" },
    { id: "7353", name: "UFD1L" },
    { id: "23376", name: "UFL1" },
    { id: "51569", name: "UFM1" },
    { id: "402682", name: "UFSP1" },
    { id: "55325", name: "UFSP2" },
    { id: "7357", name: "UGCG" },
    { id: "7358", name: "UGDH" },
    { id: "56886", name: "UGGT1" },
    { id: "55757", name: "UGGT2" },
    { id: "7360", name: "UGP2" },
    { id: "54658", name: "UGT1A1" },
    { id: "54575", name: "UGT1A10" },
    { id: "54657", name: "UGT1A4" },
    { id: "54577", name: "UGT1A7" },
    { id: "7365", name: "UGT2B10" },
    { id: "10720", name: "UGT2B11" },
    { id: "7363", name: "UGT2B4" },
    { id: "7364", name: "UGT2B7" },
    { id: "127933", name: "UHMK1" },
    { id: "29128", name: "UHRF1" },
    { id: "54887", name: "UHRF1BP1" },
    { id: "23074", name: "UHRF1BP1L" },
    { id: "115426", name: "UHRF2" },
    { id: "51720", name: "UIMC1" },
    { id: "80329", name: "ULBP1" },
    { id: "80328", name: "ULBP2" },
    { id: "79465", name: "ULBP3" },
    { id: "8408", name: "ULK1" },
    { id: "9706", name: "ULK2" },
    { id: "25989", name: "ULK3" },
    { id: "54986", name: "ULK4" },
    { id: "7369", name: "UMOD" },
    { id: "7372", name: "UMPS" },
    { id: "9094", name: "UNC119" },
    { id: "84747", name: "UNC119B" },
    { id: "23025", name: "UNC13A" },
    { id: "10497", name: "UNC13B" },
    { id: "201294", name: "UNC13D" },
    { id: "55898", name: "UNC45A" },
    { id: "146862", name: "UNC45B" },
    { id: "25972", name: "UNC50" },
    { id: "90249", name: "UNC5A" },
    { id: "219699", name: "UNC5B" },
    { id: "8633", name: "UNC5C" },
    { id: "222643", name: "UNC5CL" },
    { id: "137970", name: "UNC5D" },
    { id: "57578", name: "UNC79" },
    { id: "285175", name: "UNC80" },
    { id: "81622", name: "UNC93B1" },
    { id: "7374", name: "UNG" },
    { id: "85451", name: "UNK" },
    { id: "64718", name: "UNKL" },
    { id: "5976", name: "UPF1" },
    { id: "26019", name: "UPF2" },
    { id: "65110", name: "UPF3A" },
    { id: "65109", name: "UPF3B" },
    { id: "11045", name: "UPK1A" },
    { id: "7348", name: "UPK1B" },
    { id: "7379", name: "UPK2" },
    { id: "7380", name: "UPK3A" },
    { id: "80761", name: "UPK3B" },
    { id: "100134938", name: "UPK3BL" },
    { id: "7378", name: "UPP1" },
    { id: "151531", name: "UPP2" },
    { id: "139596", name: "UPRT" },
    { id: "55245", name: "UQCC" },
    { id: "29796", name: "UQCR10" },
    { id: "10975", name: "UQCR11" },
    { id: "7381", name: "UQCRB" },
    { id: "7384", name: "UQCRC1" },
    { id: "7385", name: "UQCRC2" },
    { id: "7386", name: "UQCRFS1" },
    { id: "100128525", name: "UQCRFS1P1" },
    { id: "7388", name: "UQCRH" },
    { id: "27089", name: "UQCRQ" },
    { id: "9875", name: "URB1" },
    { id: "9816", name: "URB2" },
    { id: "55665", name: "URGCP" },
    { id: "8725", name: "URI1" },
    { id: "81605", name: "URM1" },
    { id: "131669", name: "UROC1" },
    { id: "7389", name: "UROD" },
    { id: "7390", name: "UROS" },
    { id: "79650", name: "USB1" },
    { id: "55850", name: "USE1" },
    { id: "7391", name: "USF1" },
    { id: "7392", name: "USF2" },
    { id: "10083", name: "USH1C" },
    { id: "124590", name: "USH1G" },
    { id: "7399", name: "USH2A" },
    { id: "83878", name: "USHBP1" },
    { id: "84833", name: "USMG5" },
    { id: "8615", name: "USO1" },
    { id: "7398", name: "USP1" },
    { id: "9100", name: "USP10" },
    { id: "8237", name: "USP11" },
    { id: "219333", name: "USP12" },
    { id: "8975", name: "USP13" },
    { id: "9097", name: "USP14" },
    { id: "9958", name: "USP15" },
    { id: "10600", name: "USP16" },
    { id: "401447", name: "USP17L1P" },
    { id: "377630", name: "USP17L2" },
    { id: "728369", name: "USP17L24" },
    { id: "392197", name: "USP17L7" },
    { id: "11274", name: "USP18" },
    { id: "10869", name: "USP19" },
    { id: "9099", name: "USP2" },
    { id: "10868", name: "USP20" },
    { id: "27005", name: "USP21" },
    { id: "23326", name: "USP22" },
    { id: "23358", name: "USP24" },
    { id: "29761", name: "USP25" },
    { id: "83844", name: "USP26" },
    { id: "389856", name: "USP27X" },
    { id: "57646", name: "USP28" },
    { id: "57663", name: "USP29" },
    { id: "9960", name: "USP3" },
    { id: "84749", name: "USP30" },
    { id: "57478", name: "USP31" },
    { id: "84669", name: "USP32" },
    { id: "23032", name: "USP33" },
    { id: "9736", name: "USP34" },
    { id: "57558", name: "USP35" },
    { id: "57602", name: "USP36" },
    { id: "57695", name: "USP37" },
    { id: "84640", name: "USP38" },
    { id: "10713", name: "USP39" },
    { id: "7375", name: "USP4" },
    { id: "55230", name: "USP40" },
    { id: "373856", name: "USP41" },
    { id: "84132", name: "USP42" },
    { id: "124739", name: "USP43" },
    { id: "84101", name: "USP44" },
    { id: "85015", name: "USP45" },
    { id: "64854", name: "USP46" },
    { id: "55031", name: "USP47" },
    { id: "84196", name: "USP48" },
    { id: "25862", name: "USP49" },
    { id: "8078", name: "USP5" },
    { id: "373509", name: "USP50" },
    { id: "158880", name: "USP51" },
    { id: "54532", name: "USP53" },
    { id: "159195", name: "USP54" },
    { id: "9098", name: "USP6" },
    { id: "9712", name: "USP6NL" },
    { id: "7874", name: "USP7" },
    { id: "9101", name: "USP8" },
    { id: "8239", name: "USP9X" },
    { id: "8287", name: "USP9Y" },
    { id: "10208", name: "USPL1" },
    { id: "10090", name: "UST" },
    { id: "8433", name: "UTF1" },
    { id: "51118", name: "UTP11L" },
    { id: "10813", name: "UTP14A" },
    { id: "9724", name: "UTP14C" },
    { id: "84135", name: "UTP15" },
    { id: "51096", name: "UTP18" },
    { id: "27340", name: "UTP20" },
    { id: "84294", name: "UTP23" },
    { id: "57050", name: "UTP3" },
    { id: "55813", name: "UTP6" },
    { id: "7402", name: "UTRN" },
    { id: "10911", name: "UTS2" },
    { id: "7404", name: "UTY" },
    { id: "7405", name: "UVRAG" },
    { id: "57654", name: "UVSSA" },
    { id: "80146", name: "UXS1" },
    { id: "8409", name: "UXT" },
    { id: "55697", name: "VAC14" },
    { id: "6843", name: "VAMP1" },
    { id: "6844", name: "VAMP2" },
    { id: "9341", name: "VAMP3" },
    { id: "8674", name: "VAMP4" },
    { id: "10791", name: "VAMP5" },
    { id: "6845", name: "VAMP7" },
    { id: "8673", name: "VAMP8" },
    { id: "81839", name: "VANGL1" },
    { id: "57216", name: "VANGL2" },
    { id: "9218", name: "VAPA" },
    { id: "9217", name: "VAPB" },
    { id: "7407", name: "VARS" },
    { id: "57176", name: "VARS2" },
    { id: "22846", name: "VASH1" },
    { id: "79805", name: "VASH2" },
    { id: "114990", name: "VASN" },
    { id: "7408", name: "VASP" },
    { id: "10493", name: "VAT1" },
    { id: "57687", name: "VAT1L" },
    { id: "7409", name: "VAV1" },
    { id: "7410", name: "VAV2" },
    { id: "10451", name: "VAV3" },
    { id: "11023", name: "VAX1" },
    { id: "25806", name: "VAX2" },
    { id: "7411", name: "VBP1" },
    { id: "7412", name: "VCAM1" },
    { id: "1462", name: "VCAN" },
    { id: "7414", name: "VCL" },
    { id: "7415", name: "VCP" },
    { id: "80124", name: "VCPIP1" },
    { id: "26609", name: "VCX" },
    { id: "51480", name: "VCX2" },
    { id: "9084", name: "VCY" },
    { id: "7416", name: "VDAC1" },
    { id: "7417", name: "VDAC2" },
    { id: "7419", name: "VDAC3" },
    { id: "7421", name: "VDR" },
    { id: "7422", name: "VEGFA" },
    { id: "7423", name: "VEGFB" },
    { id: "7424", name: "VEGFC" },
    { id: "27287", name: "VENTX" },
    { id: "79674", name: "VEPH1" },
    { id: "7716", name: "VEZF1" },
    { id: "55591", name: "VEZT" },
    { id: "7425", name: "VGF" },
    { id: "51442", name: "VGLL1" },
    { id: "245806", name: "VGLL2" },
    { id: "389136", name: "VGLL3" },
    { id: "9686", name: "VGLL4" },
    { id: "7428", name: "VHL" },
    { id: "391104", name: "VHLL" },
    { id: "7429", name: "VIL1" },
    { id: "50853", name: "VILL" },
    { id: "7431", name: "VIM" },
    { id: "55829", name: "VIMP" },
    { id: "7432", name: "VIP" },
    { id: "63894", name: "VIPAS39" },
    { id: "7433", name: "VIPR1" },
    { id: "7434", name: "VIPR2" },
    { id: "79001", name: "VKORC1" },
    { id: "154807", name: "VKORC1L1" },
    { id: "7436", name: "VLDLR" },
    { id: "203547", name: "VMA21" },
    { id: "400673", name: "VMAC" },
    { id: "81671", name: "VMP1" },
    { id: "317703", name: "VN1R4" },
    { id: "317705", name: "VN1R5" },
    { id: "8875", name: "VNN2" },
    { id: "81552", name: "VOPP1" },
    { id: "9730", name: "VPRBP" },
    { id: "7441", name: "VPREB1" },
    { id: "29802", name: "VPREB3" },
    { id: "55823", name: "VPS11" },
    { id: "23230", name: "VPS13A" },
    { id: "157680", name: "VPS13B" },
    { id: "54832", name: "VPS13C" },
    { id: "55187", name: "VPS13D" },
    { id: "64601", name: "VPS16" },
    { id: "57617", name: "VPS18" },
    { id: "84313", name: "VPS25" },
    { id: "9559", name: "VPS26A" },
    { id: "112936", name: "VPS26B" },
    { id: "51160", name: "VPS28" },
    { id: "51699", name: "VPS29" },
    { id: "65082", name: "VPS33A" },
    { id: "26276", name: "VPS33B" },
    { id: "55737", name: "VPS35" },
    { id: "51028", name: "VPS36" },
    { id: "137492", name: "VPS37A" },
    { id: "79720", name: "VPS37B" },
    { id: "55048", name: "VPS37C" },
    { id: "155382", name: "VPS37D" },
    { id: "23339", name: "VPS39" },
    { id: "27072", name: "VPS41" },
    { id: "11311", name: "VPS45" },
    { id: "27183", name: "VPS4A" },
    { id: "9525", name: "VPS4B" },
    { id: "6293", name: "VPS52" },
    { id: "55275", name: "VPS53" },
    { id: "51542", name: "VPS54" },
    { id: "6944", name: "VPS72" },
    { id: "23355", name: "VPS8" },
    { id: "9605", name: "VPS9D1" },
    { id: "7443", name: "VRK1" },
    { id: "7444", name: "VRK2" },
    { id: "51231", name: "VRK3" },
    { id: "55237", name: "VRTN" },
    { id: "54621", name: "VSIG10" },
    { id: "11326", name: "VSIG4" },
    { id: "391123", name: "VSIG8" },
    { id: "7447", name: "VSNL1" },
    { id: "284415", name: "VSTM1" },
    { id: "222008", name: "VSTM2A" },
    { id: "128434", name: "VSTM2L" },
    { id: "196740", name: "VSTM4" },
    { id: "338917", name: "VSX2" },
    { id: "51534", name: "VTA1" },
    { id: "143187", name: "VTI1A" },
    { id: "10490", name: "VTI1B" },
    { id: "7448", name: "VTN" },
    { id: "64856", name: "VWA1" },
    { id: "340706", name: "VWA2" },
    { id: "4013", name: "VWA5A" },
    { id: "90113", name: "VWA5B2" },
    { id: "80737", name: "VWA7" },
    { id: "23078", name: "VWA8" },
    { id: "81556", name: "VWA9" },
    { id: "375567", name: "VWC2" },
    { id: "402117", name: "VWC2L" },
    { id: "220001", name: "VWCE" },
    { id: "221806", name: "VWDE" },
    { id: "7450", name: "VWF" },
    { id: "51322", name: "WAC" },
    { id: "23063", name: "WAPAL" },
    { id: "7453", name: "WARS" },
    { id: "10352", name: "WARS2" },
    { id: "7454", name: "WAS" },
    { id: "8936", name: "WASF1" },
    { id: "10163", name: "WASF2" },
    { id: "10810", name: "WASF3" },
    { id: "100287171", name: "WASH1" },
    { id: "375260", name: "WASH2P" },
    { id: "374666", name: "WASH3P" },
    { id: "653440", name: "WASH6P" },
    { id: "8976", name: "WASL" },
    { id: "23559", name: "WBP1" },
    { id: "51729", name: "WBP11" },
    { id: "54838", name: "WBP1L" },
    { id: "23558", name: "WBP2" },
    { id: "11193", name: "WBP4" },
    { id: "51186", name: "WBP5" },
    { id: "81554", name: "WBSCR16" },
    { id: "114049", name: "WBSCR22" },
    { id: "155368", name: "WBSCR27" },
    { id: "135886", name: "WBSCR28" },
    { id: "115825", name: "WDFY2" },
    { id: "23001", name: "WDFY3" },
    { id: "57705", name: "WDFY4" },
    { id: "11169", name: "WDHD1" },
    { id: "9948", name: "WDR1" },
    { id: "55717", name: "WDR11" },
    { id: "55759", name: "WDR12" },
    { id: "64743", name: "WDR13" },
    { id: "146845", name: "WDR16" },
    { id: "116966", name: "WDR17" },
    { id: "57418", name: "WDR18" },
    { id: "57728", name: "WDR19" },
    { id: "91833", name: "WDR20" },
    { id: "84219", name: "WDR24" },
    { id: "79446", name: "WDR25" },
    { id: "80232", name: "WDR26" },
    { id: "253769", name: "WDR27" },
    { id: "10885", name: "WDR3" },
    { id: "114987", name: "WDR31" },
    { id: "55339", name: "WDR33" },
    { id: "89891", name: "WDR34" },
    { id: "57539", name: "WDR35" },
    { id: "134430", name: "WDR36" },
    { id: "22884", name: "WDR37" },
    { id: "10785", name: "WDR4" },
    { id: "55255", name: "WDR41" },
    { id: "23160", name: "WDR43" },
    { id: "54521", name: "WDR44" },
    { id: "11152", name: "WDR45" },
    { id: "56270", name: "WDR45B" },
    { id: "9277", name: "WDR46" },
    { id: "22911", name: "WDR47" },
    { id: "57599", name: "WDR48" },
    { id: "11091", name: "WDR5" },
    { id: "348793", name: "WDR53" },
    { id: "84058", name: "WDR54" },
    { id: "54853", name: "WDR55" },
    { id: "79726", name: "WDR59" },
    { id: "54554", name: "WDR5B" },
    { id: "11180", name: "WDR6" },
    { id: "55112", name: "WDR60" },
    { id: "80349", name: "WDR61" },
    { id: "284403", name: "WDR62" },
    { id: "126820", name: "WDR63" },
    { id: "149465", name: "WDR65" },
    { id: "144406", name: "WDR66" },
    { id: "93594", name: "WDR67" },
    { id: "23335", name: "WDR7" },
    { id: "55100", name: "WDR70" },
    { id: "84942", name: "WDR73" },
    { id: "54663", name: "WDR74" },
    { id: "84128", name: "WDR75" },
    { id: "79968", name: "WDR76" },
    { id: "79084", name: "WDR77" },
    { id: "79819", name: "WDR78" },
    { id: "124997", name: "WDR81" },
    { id: "80335", name: "WDR82" },
    { id: "728505", name: "WDR82P1" },
    { id: "84292", name: "WDR83" },
    { id: "51398", name: "WDR83OS" },
    { id: "83889", name: "WDR87" },
    { id: "126248", name: "WDR88" },
    { id: "112840", name: "WDR89" },
    { id: "197335", name: "WDR90" },
    { id: "29062", name: "WDR91" },
    { id: "116143", name: "WDR92" },
    { id: "80217", name: "WDR96" },
    { id: "151525", name: "WDSUB1" },
    { id: "23038", name: "WDTC1" },
    { id: "55093", name: "WDYHV1" },
    { id: "7465", name: "WEE1" },
    { id: "58189", name: "WFDC1" },
    { id: "280664", name: "WFDC10B" },
    { id: "128488", name: "WFDC12" },
    { id: "164237", name: "WFDC13" },
    { id: "10406", name: "WFDC2" },
    { id: "149708", name: "WFDC5" },
    { id: "140870", name: "WFDC6" },
    { id: "90199", name: "WFDC8" },
    { id: "259240", name: "WFDC9" },
    { id: "117166", name: "WFIKKN1" },
    { id: "124857", name: "WFIKKN2" },
    { id: "7466", name: "WFS1" },
    { id: "7468", name: "WHSC1" },
    { id: "54904", name: "WHSC1L1" },
    { id: "84305", name: "WIBG" },
    { id: "11197", name: "WIF1" },
    { id: "7456", name: "WIPF1" },
    { id: "147179", name: "WIPF2" },
    { id: "644150", name: "WIPF3" },
    { id: "55062", name: "WIPI1" },
    { id: "26100", name: "WIPI2" },
    { id: "8840", name: "WISP1" },
    { id: "8839", name: "WISP2" },
    { id: "58525", name: "WIZ" },
    { id: "79971", name: "WLS" },
    { id: "65125", name: "WNK1" },
    { id: "65268", name: "WNK2" },
    { id: "65267", name: "WNK3" },
    { id: "65266", name: "WNK4" },
    { id: "7471", name: "WNT1" },
    { id: "7480", name: "WNT10B" },
    { id: "7481", name: "WNT11" },
    { id: "51384", name: "WNT16" },
    { id: "7472", name: "WNT2" },
    { id: "7482", name: "WNT2B" },
    { id: "89780", name: "WNT3A" },
    { id: "54361", name: "WNT4" },
    { id: "7474", name: "WNT5A" },
    { id: "81029", name: "WNT5B" },
    { id: "7477", name: "WNT7B" },
    { id: "7478", name: "WNT8A" },
    { id: "7484", name: "WNT9B" },
    { id: "55135", name: "WRAP53" },
    { id: "49856", name: "WRAP73" },
    { id: "7485", name: "WRB" },
    { id: "7486", name: "WRN" },
    { id: "56897", name: "WRNIP1" },
    { id: "26118", name: "WSB1" },
    { id: "55884", name: "WSB2" },
    { id: "7490", name: "WT1" },
    { id: "51352", name: "WT1-AS" },
    { id: "9589", name: "WTAP" },
    { id: "126374", name: "WTIP" },
    { id: "23286", name: "WWC1" },
    { id: "80014", name: "WWC2" },
    { id: "55841", name: "WWC3" },
    { id: "51741", name: "WWOX" },
    { id: "11059", name: "WWP1" },
    { id: "11060", name: "WWP2" },
    { id: "25937", name: "WWTR1" },
    { id: "56949", name: "XAB2" },
    { id: "54739", name: "XAF1" },
    { id: "9502", name: "XAGE2" },
    { id: "170626", name: "XAGE3" },
    { id: "7494", name: "XBP1" },
    { id: "6375", name: "XCL1" },
    { id: "6846", name: "XCL2" },
    { id: "2829", name: "XCR1" },
    { id: "7498", name: "XDH" },
    { id: "331", name: "XIAP" },
    { id: "165904", name: "XIRP1" },
    { id: "129446", name: "XIRP2" },
    { id: "7503", name: "XIST" },
    { id: "7504", name: "XK" },
    { id: "150165", name: "XKR3" },
    { id: "114786", name: "XKR4" },
    { id: "402415", name: "XKRX" },
    { id: "353515", name: "XKRY2" },
    { id: "7507", name: "XPA" },
    { id: "7508", name: "XPC" },
    { id: "7511", name: "XPNPEP1" },
    { id: "63929", name: "XPNPEP3" },
    { id: "7514", name: "XPO1" },
    { id: "64328", name: "XPO4" },
    { id: "57510", name: "XPO5" },
    { id: "23214", name: "XPO6" },
    { id: "23039", name: "XPO7" },
    { id: "11260", name: "XPOT" },
    { id: "9213", name: "XPR1" },
    { id: "7515", name: "XRCC1" },
    { id: "7516", name: "XRCC2" },
    { id: "7517", name: "XRCC3" },
    { id: "7518", name: "XRCC4" },
    { id: "7520", name: "XRCC5" },
    { id: "2547", name: "XRCC6" },
    { id: "91419", name: "XRCC6BP1" },
    { id: "54464", name: "XRN1" },
    { id: "22803", name: "XRN2" },
    { id: "152002", name: "XXYLT1" },
    { id: "9942", name: "XYLB" },
    { id: "64131", name: "XYLT1" },
    { id: "64132", name: "XYLT2" },
    { id: "57002", name: "YAE1D1" },
    { id: "10138", name: "YAF2" },
    { id: "10413", name: "YAP1" },
    { id: "8565", name: "YARS" },
    { id: "51067", name: "YARS2" },
    { id: "54059", name: "YBEY" },
    { id: "4904", name: "YBX1" },
    { id: "51087", name: "YBX2" },
    { id: "8531", name: "YBX3" },
    { id: "150223", name: "YDJC" },
    { id: "55689", name: "YEATS2" },
    { id: "8089", name: "YEATS4" },
    { id: "7525", name: "YES1" },
    { id: "10897", name: "YIF1A" },
    { id: "90522", name: "YIF1B" },
    { id: "54432", name: "YIPF1" },
    { id: "78992", name: "YIPF2" },
    { id: "25844", name: "YIPF3" },
    { id: "84272", name: "YIPF4" },
    { id: "81555", name: "YIPF5" },
    { id: "286451", name: "YIPF6" },
    { id: "10652", name: "YKT6" },
    { id: "56252", name: "YLPM1" },
    { id: "10730", name: "YME1L1" },
    { id: "55432", name: "YOD1" },
    { id: "388403", name: "YPEL2" },
    { id: "83719", name: "YPEL3" },
    { id: "219539", name: "YPEL4" },
    { id: "51646", name: "YPEL5" },
    { id: "79693", name: "YRDC" },
    { id: "91746", name: "YTHDC1" },
    { id: "64848", name: "YTHDC2" },
    { id: "54915", name: "YTHDF1" },
    { id: "51441", name: "YTHDF2" },
    { id: "253943", name: "YTHDF3" },
    { id: "7529", name: "YWHAB" },
    { id: "7531", name: "YWHAE" },
    { id: "7532", name: "YWHAG" },
    { id: "7533", name: "YWHAH" },
    { id: "10971", name: "YWHAQ" },
    { id: "7534", name: "YWHAZ" },
    { id: "7528", name: "YY1" },
    { id: "55249", name: "YY1AP1" },
    { id: "404281", name: "YY2" },
    { id: "284273", name: "ZADH2" },
    { id: "51776", name: "ZAK" },
    { id: "7535", name: "ZAP70" },
    { id: "79740", name: "ZBBX" },
    { id: "9189", name: "ZBED1" },
    { id: "79413", name: "ZBED2" },
    { id: "9889", name: "ZBED4" },
    { id: "58486", name: "ZBED5" },
    { id: "100381270", name: "ZBED6" },
    { id: "113763", name: "ZBED6CL" },
    { id: "81030", name: "ZBP1" },
    { id: "22890", name: "ZBTB1" },
    { id: "65986", name: "ZBTB10" },
    { id: "27107", name: "ZBTB11" },
    { id: "221527", name: "ZBTB12" },
    { id: "7541", name: "ZBTB14" },
    { id: "7704", name: "ZBTB16" },
    { id: "7709", name: "ZBTB17" },
    { id: "10472", name: "ZBTB18" },
    { id: "57621", name: "ZBTB2" },
    { id: "26137", name: "ZBTB20" },
    { id: "49854", name: "ZBTB21" },
    { id: "9278", name: "ZBTB22" },
    { id: "9841", name: "ZBTB24" },
    { id: "7597", name: "ZBTB25" },
    { id: "57684", name: "ZBTB26" },
    { id: "79842", name: "ZBTB3" },
    { id: "27033", name: "ZBTB32" },
    { id: "10009", name: "ZBTB33" },
    { id: "403341", name: "ZBTB34" },
    { id: "84614", name: "ZBTB37" },
    { id: "253461", name: "ZBTB38" },
    { id: "9880", name: "ZBTB39" },
    { id: "57659", name: "ZBTB4" },
    { id: "9923", name: "ZBTB40" },
    { id: "360023", name: "ZBTB41" },
    { id: "100128927", name: "ZBTB42" },
    { id: "23099", name: "ZBTB43" },
    { id: "29068", name: "ZBTB44" },
    { id: "84878", name: "ZBTB45" },
    { id: "140685", name: "ZBTB46" },
    { id: "92999", name: "ZBTB47" },
    { id: "3104", name: "ZBTB48" },
    { id: "166793", name: "ZBTB49" },
    { id: "9925", name: "ZBTB5" },
    { id: "10773", name: "ZBTB6" },
    { id: "51341", name: "ZBTB7A" },
    { id: "51043", name: "ZBTB7B" },
    { id: "201501", name: "ZBTB7C" },
    { id: "653121", name: "ZBTB8A" },
    { id: "728116", name: "ZBTB8B" },
    { id: "339487", name: "ZBTB8OS" },
    { id: "221504", name: "ZBTB9" },
    { id: "51101", name: "ZC2HC1A" },
    { id: "153918", name: "ZC2HC1B" },
    { id: "79696", name: "ZC2HC1C" },
    { id: "84872", name: "ZC3H10" },
    { id: "9877", name: "ZC3H11A" },
    { id: "80149", name: "ZC3H12A" },
    { id: "85463", name: "ZC3H12C" },
    { id: "23091", name: "ZC3H13" },
    { id: "79882", name: "ZC3H14" },
    { id: "55854", name: "ZC3H15" },
    { id: "124245", name: "ZC3H18" },
    { id: "23144", name: "ZC3H3" },
    { id: "23211", name: "ZC3H4" },
    { id: "376940", name: "ZC3H6" },
    { id: "29066", name: "ZC3H7A" },
    { id: "23264", name: "ZC3H7B" },
    { id: "84524", name: "ZC3H8" },
    { id: "56829", name: "ZC3HAV1" },
    { id: "92092", name: "ZC3HAV1L" },
    { id: "51530", name: "ZC3HC1" },
    { id: "55906", name: "ZC4H2" },
    { id: "54819", name: "ZCCHC10" },
    { id: "23318", name: "ZCCHC11" },
    { id: "170261", name: "ZCCHC12" },
    { id: "389874", name: "ZCCHC13" },
    { id: "23174", name: "ZCCHC14" },
    { id: "51538", name: "ZCCHC17" },
    { id: "54877", name: "ZCCHC2" },
    { id: "219654", name: "ZCCHC24" },
    { id: "85364", name: "ZCCHC3" },
    { id: "79670", name: "ZCCHC6" },
    { id: "84186", name: "ZCCHC7" },
    { id: "55596", name: "ZCCHC8" },
    { id: "84240", name: "ZCCHC9" },
    { id: "85437", name: "ZCRB1" },
    { id: "55063", name: "ZCWPW1" },
    { id: "152098", name: "ZCWPW2" },
    { id: "57683", name: "ZDBF2" },
    { id: "29800", name: "ZDHHC1" },
    { id: "79844", name: "ZDHHC11" },
    { id: "84885", name: "ZDHHC12" },
    { id: "54503", name: "ZDHHC13" },
    { id: "158866", name: "ZDHHC15" },
    { id: "84287", name: "ZDHHC16" },
    { id: "23390", name: "ZDHHC17" },
    { id: "51201", name: "ZDHHC2" },
    { id: "253832", name: "ZDHHC20" },
    { id: "340481", name: "ZDHHC21" },
    { id: "283576", name: "ZDHHC22" },
    { id: "254887", name: "ZDHHC23" },
    { id: "254359", name: "ZDHHC24" },
    { id: "51304", name: "ZDHHC3" },
    { id: "55146", name: "ZDHHC4" },
    { id: "25921", name: "ZDHHC5" },
    { id: "64429", name: "ZDHHC6" },
    { id: "55625", name: "ZDHHC7" },
    { id: "29801", name: "ZDHHC8" },
    { id: "6935", name: "ZEB1" },
    { id: "9839", name: "ZEB2" },
    { id: "10444", name: "ZER1" },
    { id: "90637", name: "ZFAND2A" },
    { id: "130617", name: "ZFAND2B" },
    { id: "60685", name: "ZFAND3" },
    { id: "93550", name: "ZFAND4" },
    { id: "7763", name: "ZFAND5" },
    { id: "54469", name: "ZFAND6" },
    { id: "57623", name: "ZFAT" },
    { id: "196441", name: "ZFC3H1" },
    { id: "85446", name: "ZFHX2" },
    { id: "463", name: "ZFHX3" },
    { id: "79776", name: "ZFHX4" },
    { id: "162239", name: "ZFP1" },
    { id: "57677", name: "ZFP14" },
    { id: "80108", name: "ZFP2" },
    { id: "140612", name: "ZFP28" },
    { id: "124961", name: "ZFP3" },
    { id: "22835", name: "ZFP30" },
    { id: "7538", name: "ZFP36" },
    { id: "677", name: "ZFP36L1" },
    { id: "286128", name: "ZFP41" },
    { id: "132625", name: "ZFP42" },
    { id: "346171", name: "ZFP57" },
    { id: "643836", name: "ZFP62" },
    { id: "55734", name: "ZFP64" },
    { id: "65243", name: "ZFP69B" },
    { id: "284406", name: "ZFP82" },
    { id: "80829", name: "ZFP91" },
    { id: "7542", name: "ZFPL1" },
    { id: "161882", name: "ZFPM1" },
    { id: "23414", name: "ZFPM2" },
    { id: "51663", name: "ZFR" },
    { id: "7543", name: "ZFX" },
    { id: "7544", name: "ZFY" },
    { id: "53349", name: "ZFYVE1" },
    { id: "9765", name: "ZFYVE16" },
    { id: "84936", name: "ZFYVE19" },
    { id: "64145", name: "ZFYVE20" },
    { id: "79038", name: "ZFYVE21" },
    { id: "23503", name: "ZFYVE26" },
    { id: "118813", name: "ZFYVE27" },
    { id: "57732", name: "ZFYVE28" },
    { id: "9372", name: "ZFYVE9" },
    { id: "653808", name: "ZG16" },
    { id: "124220", name: "ZG16B" },
    { id: "84619", name: "ZGPAT" },
    { id: "11244", name: "ZHX1" },
    { id: "100533106", name: "ZHX1-C8ORF76" },
    { id: "22882", name: "ZHX2" },
    { id: "23051", name: "ZHX3" },
    { id: "7545", name: "ZIC1" },
    { id: "7546", name: "ZIC2" },
    { id: "7547", name: "ZIC3" },
    { id: "84107", name: "ZIC4" },
    { id: "284307", name: "ZIK1" },
    { id: "23619", name: "ZIM2" },
    { id: "114026", name: "ZIM3" },
    { id: "7586", name: "ZKSCAN1" },
    { id: "342357", name: "ZKSCAN2" },
    { id: "80317", name: "ZKSCAN3" },
    { id: "387032", name: "ZKSCAN4" },
    { id: "23660", name: "ZKSCAN5" },
    { id: "55888", name: "ZKSCAN7" },
    { id: "7745", name: "ZKSCAN8" },
    { id: "84460", name: "ZMAT1" },
    { id: "153527", name: "ZMAT2" },
    { id: "64393", name: "ZMAT3" },
    { id: "79698", name: "ZMAT4" },
    { id: "55954", name: "ZMAT5" },
    { id: "57178", name: "ZMIZ1" },
    { id: "83637", name: "ZMIZ2" },
    { id: "10269", name: "ZMPSTE24" },
    { id: "79830", name: "ZMYM1" },
    { id: "7750", name: "ZMYM2" },
    { id: "9203", name: "ZMYM3" },
    { id: "9202", name: "ZMYM4" },
    { id: "9205", name: "ZMYM5" },
    { id: "9204", name: "ZMYM6" },
    { id: "100506144", name: "ZMYM6NB" },
    { id: "51364", name: "ZMYND10" },
    { id: "10771", name: "ZMYND11" },
    { id: "84217", name: "ZMYND12" },
    { id: "116225", name: "ZMYND19" },
    { id: "23613", name: "ZMYND8" },
    { id: "7556", name: "ZNF10" },
    { id: "163227", name: "ZNF100" },
    { id: "94039", name: "ZNF101" },
    { id: "64397", name: "ZNF106" },
    { id: "51427", name: "ZNF107" },
    { id: "163071", name: "ZNF114" },
    { id: "51351", name: "ZNF117" },
    { id: "7559", name: "ZNF12" },
    { id: "7675", name: "ZNF121" },
    { id: "7678", name: "ZNF124" },
    { id: "7690", name: "ZNF131" },
    { id: "7692", name: "ZNF133" },
    { id: "7694", name: "ZNF135" },
    { id: "7695", name: "ZNF136" },
    { id: "7697", name: "ZNF138" },
    { id: "7561", name: "ZNF14" },
    { id: "7700", name: "ZNF141" },
    { id: "7701", name: "ZNF142" },
    { id: "7702", name: "ZNF143" },
    { id: "7705", name: "ZNF146" },
    { id: "7707", name: "ZNF148" },
    { id: "7711", name: "ZNF155" },
    { id: "7564", name: "ZNF16" },
    { id: "90338", name: "ZNF160" },
    { id: "7718", name: "ZNF165" },
    { id: "169841", name: "ZNF169" },
    { id: "7565", name: "ZNF17" },
    { id: "7727", name: "ZNF174" },
    { id: "7728", name: "ZNF175" },
    { id: "7730", name: "ZNF177" },
    { id: "7566", name: "ZNF18" },
    { id: "7733", name: "ZNF180" },
    { id: "339318", name: "ZNF181" },
    { id: "7569", name: "ZNF182" },
    { id: "7738", name: "ZNF184" },
    { id: "7739", name: "ZNF185" },
    { id: "7743", name: "ZNF189" },
    { id: "7567", name: "ZNF19" },
    { id: "7748", name: "ZNF195" },
    { id: "10168", name: "ZNF197" },
    { id: "7549", name: "ZNF2" },
    { id: "7568", name: "ZNF20" },
    { id: "7752", name: "ZNF200" },
    { id: "7753", name: "ZNF202" },
    { id: "7755", name: "ZNF205" },
    { id: "7756", name: "ZNF207" },
    { id: "7757", name: "ZNF208" },
    { id: "7988", name: "ZNF212" },
    { id: "7760", name: "ZNF213" },
    { id: "7762", name: "ZNF215" },
    { id: "7764", name: "ZNF217" },
    { id: "51222", name: "ZNF219" },
    { id: "7570", name: "ZNF22" },
    { id: "7638", name: "ZNF221" },
    { id: "7673", name: "ZNF222" },
    { id: "7766", name: "ZNF223" },
    { id: "7767", name: "ZNF224" },
    { id: "7769", name: "ZNF226" },
    { id: "7770", name: "ZNF227" },
    { id: "7772", name: "ZNF229" },
    { id: "7571", name: "ZNF23" },
    { id: "7773", name: "ZNF230" },
    { id: "7775", name: "ZNF232" },
    { id: "353355", name: "ZNF233" },
    { id: "10780", name: "ZNF234" },
    { id: "9310", name: "ZNF235" },
    { id: "7776", name: "ZNF236" },
    { id: "8187", name: "ZNF239" },
    { id: "7572", name: "ZNF24" },
    { id: "57209", name: "ZNF248" },
    { id: "219749", name: "ZNF25" },
    { id: "58500", name: "ZNF250" },
    { id: "90987", name: "ZNF251" },
    { id: "56242", name: "ZNF253" },
    { id: "9534", name: "ZNF254" },
    { id: "10172", name: "ZNF256" },
    { id: "113835", name: "ZNF257" },
    { id: "8882", name: "ZNF259" },
    { id: "7574", name: "ZNF26" },
    { id: "339324", name: "ZNF260" },
    { id: "10127", name: "ZNF263" },
    { id: "9422", name: "ZNF264" },
    { id: "10781", name: "ZNF266" },
    { id: "10308", name: "ZNF267" },
    { id: "10793", name: "ZNF273" },
    { id: "10782", name: "ZNF274" },
    { id: "92822", name: "ZNF276" },
    { id: "11179", name: "ZNF277" },
    { id: "7576", name: "ZNF28" },
    { id: "129025", name: "ZNF280A" },
    { id: "55609", name: "ZNF280C" },
    { id: "54816", name: "ZNF280D" },
    { id: "23528", name: "ZNF281" },
    { id: "8427", name: "ZNF282" },
    { id: "284349", name: "ZNF283" },
    { id: "342909", name: "ZNF284" },
    { id: "26974", name: "ZNF285" },
    { id: "57335", name: "ZNF286A" },
    { id: "729288", name: "ZNF286B" },
    { id: "57336", name: "ZNF287" },
    { id: "23036", name: "ZNF292" },
    { id: "7551", name: "ZNF3" },
    { id: "90075", name: "ZNF30" },
    { id: "91975", name: "ZNF300" },
    { id: "55900", name: "ZNF302" },
    { id: "282890", name: "ZNF311" },
    { id: "100131017", name: "ZNF316" },
    { id: "57693", name: "ZNF317" },
    { id: "24149", name: "ZNF318" },
    { id: "57567", name: "ZNF319" },
    { id: "7580", name: "ZNF32" },
    { id: "162967", name: "ZNF320" },
    { id: "399669", name: "ZNF321P" },
    { id: "79692", name: "ZNF322" },
    { id: "25799", name: "ZNF324" },
    { id: "388569", name: "ZNF324B" },
    { id: "284695", name: "ZNF326" },
    { id: "79673", name: "ZNF329" },
    { id: "27309", name: "ZNF330" },
    { id: "55422", name: "ZNF331" },
    { id: "84449", name: "ZNF333" },
    { id: "55713", name: "ZNF334" },
    { id: "63925", name: "ZNF335" },
    { id: "26152", name: "ZNF337" },
    { id: "7581", name: "ZNF33A" },
    { id: "7582", name: "ZNF33B" },
    { id: "80778", name: "ZNF34" },
    { id: "84905", name: "ZNF341" },
    { id: "79175", name: "ZNF343" },
    { id: "25850", name: "ZNF345" },
    { id: "23567", name: "ZNF346" },
    { id: "84671", name: "ZNF347" },
    { id: "7584", name: "ZNF35" },
    { id: "59348", name: "ZNF350" },
    { id: "117608", name: "ZNF354B" },
    { id: "30832", name: "ZNF354C" },
    { id: "140467", name: "ZNF358" },
    { id: "149076", name: "ZNF362" },
    { id: "22891", name: "ZNF365" },
    { id: "167465", name: "ZNF366" },
    { id: "84911", name: "ZNF382" },
    { id: "163087", name: "ZNF383" },
    { id: "171017", name: "ZNF384" },
    { id: "25946", name: "ZNF385A" },
    { id: "151126", name: "ZNF385B" },
    { id: "201181", name: "ZNF385C" },
    { id: "346157", name: "ZNF391" },
    { id: "84124", name: "ZNF394" },
    { id: "55893", name: "ZNF395" },
    { id: "252884", name: "ZNF396" },
    { id: "84307", name: "ZNF397" },
    { id: "57541", name: "ZNF398" },
    { id: "342908", name: "ZNF404" },
    { id: "55628", name: "ZNF407" },
    { id: "79797", name: "ZNF408" },
    { id: "7592", name: "ZNF41" },
    { id: "57862", name: "ZNF410" },
    { id: "84330", name: "ZNF414" },
    { id: "55786", name: "ZNF415" },
    { id: "147687", name: "ZNF417" },
    { id: "147686", name: "ZNF418" },
    { id: "79744", name: "ZNF419" },
    { id: "147923", name: "ZNF420" },
    { id: "23090", name: "ZNF423" },
    { id: "79088", name: "ZNF426" },
    { id: "126299", name: "ZNF428" },
    { id: "353088", name: "ZNF429" },
    { id: "7594", name: "ZNF43" },
    { id: "80264", name: "ZNF430" },
    { id: "170959", name: "ZNF431" },
    { id: "163059", name: "ZNF433" },
    { id: "80818", name: "ZNF436" },
    { id: "220929", name: "ZNF438" },
    { id: "90594", name: "ZNF439" },
    { id: "51710", name: "ZNF44" },
    { id: "126070", name: "ZNF440" },
    { id: "126068", name: "ZNF441" },
    { id: "79973", name: "ZNF442" },
    { id: "10224", name: "ZNF443" },
    { id: "55311", name: "ZNF444" },
    { id: "353274", name: "ZNF445" },
    { id: "55663", name: "ZNF446" },
    { id: "203523", name: "ZNF449" },
    { id: "7596", name: "ZNF45" },
    { id: "26036", name: "ZNF451" },
    { id: "285676", name: "ZNF454" },
    { id: "10794", name: "ZNF460" },
    { id: "92283", name: "ZNF461" },
    { id: "58499", name: "ZNF462" },
    { id: "168544", name: "ZNF467" },
    { id: "90333", name: "ZNF468" },
    { id: "57573", name: "ZNF471" },
    { id: "25888", name: "ZNF473" },
    { id: "133923", name: "ZNF474" },
    { id: "90827", name: "ZNF479" },
    { id: "197407", name: "ZNF48" },
    { id: "147657", name: "ZNF480" },
    { id: "158399", name: "ZNF483" },
    { id: "83744", name: "ZNF484" },
    { id: "220992", name: "ZNF485" },
    { id: "90649", name: "ZNF486" },
    { id: "118738", name: "ZNF488" },
    { id: "57474", name: "ZNF490" },
    { id: "126069", name: "ZNF491" },
    { id: "57615", name: "ZNF492" },
    { id: "284443", name: "ZNF493" },
    { id: "84838", name: "ZNF496" },
    { id: "162968", name: "ZNF497" },
    { id: "115560", name: "ZNF501" },
    { id: "91392", name: "ZNF502" },
    { id: "84858", name: "ZNF503" },
    { id: "440515", name: "ZNF506" },
    { id: "22847", name: "ZNF507" },
    { id: "22869", name: "ZNF510" },
    { id: "118472", name: "ZNF511" },
    { id: "84450", name: "ZNF512" },
    { id: "57473", name: "ZNF512B" },
    { id: "130557", name: "ZNF513" },
    { id: "84874", name: "ZNF514" },
    { id: "9658", name: "ZNF516" },
    { id: "9849", name: "ZNF518A" },
    { id: "85460", name: "ZNF518B" },
    { id: "162655", name: "ZNF519" },
    { id: "25925", name: "ZNF521" },
    { id: "147807", name: "ZNF524" },
    { id: "116115", name: "ZNF526" },
    { id: "84503", name: "ZNF527" },
    { id: "55205", name: "ZNF532" },
    { id: "147658", name: "ZNF534" },
    { id: "9745", name: "ZNF536" },
    { id: "163255", name: "ZNF540" },
    { id: "84215", name: "ZNF541" },
    { id: "125919", name: "ZNF543" },
    { id: "27300", name: "ZNF544" },
    { id: "339327", name: "ZNF546" },
    { id: "284306", name: "ZNF547" },
    { id: "147694", name: "ZNF548" },
    { id: "256051", name: "ZNF549" },
    { id: "162972", name: "ZNF550" },
    { id: "90233", name: "ZNF551" },
    { id: "79818", name: "ZNF552" },
    { id: "148254", name: "ZNF555" },
    { id: "80032", name: "ZNF556" },
    { id: "79230", name: "ZNF557" },
    { id: "148156", name: "ZNF558" },
    { id: "84527", name: "ZNF559" },
    { id: "147741", name: "ZNF560" },
    { id: "147837", name: "ZNF563" },
    { id: "163050", name: "ZNF564" },
    { id: "147929", name: "ZNF565" },
    { id: "84924", name: "ZNF566" },
    { id: "163081", name: "ZNF567" },
    { id: "374900", name: "ZNF568" },
    { id: "148266", name: "ZNF569" },
    { id: "126295", name: "ZNF57" },
    { id: "148268", name: "ZNF570" },
    { id: "51276", name: "ZNF571" },
    { id: "137209", name: "ZNF572" },
    { id: "64763", name: "ZNF574" },
    { id: "284346", name: "ZNF575" },
    { id: "79177", name: "ZNF576" },
    { id: "84765", name: "ZNF577" },
    { id: "147660", name: "ZNF578" },
    { id: "163033", name: "ZNF579" },
    { id: "51157", name: "ZNF580" },
    { id: "51545", name: "ZNF581" },
    { id: "147948", name: "ZNF582" },
    { id: "147949", name: "ZNF583" },
    { id: "201514", name: "ZNF584" },
    { id: "199704", name: "ZNF585A" },
    { id: "92285", name: "ZNF585B" },
    { id: "54807", name: "ZNF586" },
    { id: "84914", name: "ZNF587" },
    { id: "100293516", name: "ZNF587B" },
    { id: "9640", name: "ZNF592" },
    { id: "51042", name: "ZNF593" },
    { id: "84622", name: "ZNF594" },
    { id: "152687", name: "ZNF595" },
    { id: "169270", name: "ZNF596" },
    { id: "146434", name: "ZNF597" },
    { id: "90850", name: "ZNF598" },
    { id: "148103", name: "ZNF599" },
    { id: "162966", name: "ZNF600" },
    { id: "80095", name: "ZNF606" },
    { id: "84775", name: "ZNF607" },
    { id: "57507", name: "ZNF608" },
    { id: "23060", name: "ZNF609" },
    { id: "162963", name: "ZNF610" },
    { id: "81856", name: "ZNF611" },
    { id: "79898", name: "ZNF613" },
    { id: "80110", name: "ZNF614" },
    { id: "284370", name: "ZNF615" },
    { id: "90317", name: "ZNF616" },
    { id: "114991", name: "ZNF618" },
    { id: "285267", name: "ZNF619" },
    { id: "253639", name: "ZNF620" },
    { id: "90441", name: "ZNF622" },
    { id: "57547", name: "ZNF624" },
    { id: "90589", name: "ZNF625" },
    { id: "199777", name: "ZNF626" },
    { id: "199692", name: "ZNF627" },
    { id: "23361", name: "ZNF629" },
    { id: "27332", name: "ZNF638" },
    { id: "51193", name: "ZNF639" },
    { id: "121274", name: "ZNF641" },
    { id: "84146", name: "ZNF644" },
    { id: "158506", name: "ZNF645" },
    { id: "9726", name: "ZNF646" },
    { id: "127665", name: "ZNF648" },
    { id: "65251", name: "ZNF649" },
    { id: "22834", name: "ZNF652" },
    { id: "115950", name: "ZNF653" },
    { id: "79027", name: "ZNF655" },
    { id: "26149", name: "ZNF658" },
    { id: "401509", name: "ZNF658B" },
    { id: "285349", name: "ZNF660" },
    { id: "389114", name: "ZNF662" },
    { id: "79788", name: "ZNF665" },
    { id: "63934", name: "ZNF667" },
    { id: "79759", name: "ZNF668" },
    { id: "79862", name: "ZNF669" },
    { id: "93474", name: "ZNF670" },
    { id: "79891", name: "ZNF671" },
    { id: "641339", name: "ZNF674" },
    { id: "171392", name: "ZNF675" },
    { id: "163223", name: "ZNF676" },
    { id: "342926", name: "ZNF677" },
    { id: "339500", name: "ZNF678" },
    { id: "168417", name: "ZNF679" },
    { id: "340252", name: "ZNF680" },
    { id: "148213", name: "ZNF681" },
    { id: "91120", name: "ZNF682" },
    { id: "257101", name: "ZNF683" },
    { id: "127396", name: "ZNF684" },
    { id: "57592", name: "ZNF687" },
    { id: "146542", name: "ZNF688" },
    { id: "115509", name: "ZNF689" },
    { id: "7620", name: "ZNF69" },
    { id: "51058", name: "ZNF691" },
    { id: "55657", name: "ZNF692" },
    { id: "57116", name: "ZNF695" },
    { id: "79943", name: "ZNF696" },
    { id: "90874", name: "ZNF697" },
    { id: "374879", name: "ZNF699" },
    { id: "7553", name: "ZNF7" },
    { id: "90592", name: "ZNF700" },
    { id: "55762", name: "ZNF701" },
    { id: "80139", name: "ZNF703" },
    { id: "619279", name: "ZNF704" },
    { id: "728957", name: "ZNF705D" },
    { id: "100131980", name: "ZNF705G" },
    { id: "51123", name: "ZNF706" },
    { id: "286075", name: "ZNF707" },
    { id: "7562", name: "ZNF708" },
    { id: "163051", name: "ZNF709" },
    { id: "58491", name: "ZNF71" },
    { id: "7552", name: "ZNF711" },
    { id: "349075", name: "ZNF713" },
    { id: "148206", name: "ZNF714" },
    { id: "441234", name: "ZNF716" },
    { id: "100131827", name: "ZNF717" },
    { id: "255403", name: "ZNF718" },
    { id: "124411", name: "ZNF720" },
    { id: "170960", name: "ZNF721" },
    { id: "442319", name: "ZNF727" },
    { id: "100129543", name: "ZNF730" },
    { id: "654254", name: "ZNF732" },
    { id: "730291", name: "ZNF735" },
    { id: "728927", name: "ZNF736" },
    { id: "100129842", name: "ZNF737" },
    { id: "7625", name: "ZNF74" },
    { id: "283337", name: "ZNF740" },
    { id: "155061", name: "ZNF746" },
    { id: "65988", name: "ZNF747" },
    { id: "388567", name: "ZNF749" },
    { id: "79755", name: "ZNF750" },
    { id: "7627", name: "ZNF75A" },
    { id: "7626", name: "ZNF75D" },
    { id: "7629", name: "ZNF76" },
    { id: "388561", name: "ZNF761" },
    { id: "284390", name: "ZNF763" },
    { id: "92595", name: "ZNF764" },
    { id: "91661", name: "ZNF765" },
    { id: "90321", name: "ZNF766" },
    { id: "79970", name: "ZNF767" },
    { id: "79724", name: "ZNF768" },
    { id: "58492", name: "ZNF77" },
    { id: "54989", name: "ZNF770" },
    { id: "51333", name: "ZNF771" },
    { id: "400720", name: "ZNF772" },
    { id: "374928", name: "ZNF773" },
    { id: "342132", name: "ZNF774" },
    { id: "285971", name: "ZNF775" },
    { id: "27153", name: "ZNF777" },
    { id: "197320", name: "ZNF778" },
    { id: "163115", name: "ZNF781" },
    { id: "158431", name: "ZNF782" },
    { id: "100289678", name: "ZNF783" },
    { id: "147808", name: "ZNF784" },
    { id: "146540", name: "ZNF785" },
    { id: "136051", name: "ZNF786" },
    { id: "126208", name: "ZNF787" },
    { id: "285989", name: "ZNF789" },
    { id: "7633", name: "ZNF79" },
    { id: "388536", name: "ZNF790" },
    { id: "163049", name: "ZNF791" },
    { id: "126375", name: "ZNF792" },
    { id: "390927", name: "ZNF793" },
    { id: "90576", name: "ZNF799" },
    { id: "7554", name: "ZNF8" },
    { id: "7634", name: "ZNF80" },
    { id: "168850", name: "ZNF800" },
    { id: "91752", name: "ZNF804A" },
    { id: "388558", name: "ZNF808" },
    { id: "347344", name: "ZNF81" },
    { id: "126017", name: "ZNF813" },
    { id: "125893", name: "ZNF816" },
    { id: "55565", name: "ZNF821" },
    { id: "55552", name: "ZNF823" },
    { id: "152485", name: "ZNF827" },
    { id: "374899", name: "ZNF829" },
    { id: "55769", name: "ZNF83" },
    { id: "91603", name: "ZNF830" },
    { id: "90485", name: "ZNF835" },
    { id: "162962", name: "ZNF836" },
    { id: "116412", name: "ZNF837" },
    { id: "55778", name: "ZNF839" },
    { id: "7637", name: "ZNF84" },
    { id: "284371", name: "ZNF841" },
    { id: "283933", name: "ZNF843" },
    { id: "284391", name: "ZNF844" },
    { id: "91664", name: "ZNF845" },
    { id: "162993", name: "ZNF846" },
    { id: "7639", name: "ZNF85" },
    { id: "342892", name: "ZNF850" },
    { id: "344787", name: "ZNF860" },
    { id: "643641", name: "ZNF862" },
    { id: "100507290", name: "ZNF865" },
    { id: "729747", name: "ZNF878" },
    { id: "345462", name: "ZNF879" },
    { id: "400713", name: "ZNF880" },
    { id: "169834", name: "ZNF883" },
    { id: "7643", name: "ZNF90" },
    { id: "7644", name: "ZNF91" },
    { id: "168374", name: "ZNF92" },
    { id: "81931", name: "ZNF93" },
    { id: "148198", name: "ZNF98" },
    { id: "57169", name: "ZNFX1" },
    { id: "10467", name: "ZNHIT1" },
    { id: "9326", name: "ZNHIT3" },
    { id: "54680", name: "ZNHIT6" },
    { id: "30834", name: "ZNRD1" },
    { id: "84937", name: "ZNRF1" },
    { id: "223082", name: "ZNRF2" },
    { id: "84133", name: "ZNRF3" },
    { id: "148066", name: "ZNRF4" },
    { id: "7783", name: "ZP2" },
    { id: "7784", name: "ZP3" },
    { id: "57829", name: "ZP4" },
    { id: "11055", name: "ZPBP" },
    { id: "54764", name: "ZRANB1" },
    { id: "9406", name: "ZRANB2" },
    { id: "84083", name: "ZRANB3" },
    { id: "8233", name: "ZRSR2" },
    { id: "284312", name: "ZSCAN1" },
    { id: "9753", name: "ZSCAN12" },
    { id: "80345", name: "ZSCAN16" },
    { id: "65982", name: "ZSCAN18" },
    { id: "54993", name: "ZSCAN2" },
    { id: "7579", name: "ZSCAN20" },
    { id: "7589", name: "ZSCAN21" },
    { id: "342945", name: "ZSCAN22" },
    { id: "222696", name: "ZSCAN23" },
    { id: "221785", name: "ZSCAN25" },
    { id: "7741", name: "ZSCAN26" },
    { id: "146050", name: "ZSCAN29" },
    { id: "100101467", name: "ZSCAN30" },
    { id: "54925", name: "ZSCAN32" },
    { id: "201516", name: "ZSCAN4" },
    { id: "79149", name: "ZSCAN5A" },
    { id: "7746", name: "ZSCAN9" },
    { id: "90204", name: "ZSWIM1" },
    { id: "151112", name: "ZSWIM2" },
    { id: "140831", name: "ZSWIM3" },
    { id: "57688", name: "ZSWIM6" },
    { id: "125150", name: "ZSWIM7" },
    { id: "23053", name: "ZSWIM8" },
    { id: "221302", name: "ZUFSP" },
    { id: "9183", name: "ZW10" },
    { id: "55055", name: "ZWILCH" },
    { id: "11130", name: "ZWINT" },
    { id: "7789", name: "ZXDA" },
    { id: "79364", name: "ZXDC" },
    { id: "440590", name: "ZYG11A" },
    { id: "79699", name: "ZYG11B" },
    { id: "7791", name: "ZYX" },
    { id: "23140", name: "ZZEF1" },
    { id: "26009", name: "ZZZ3" },
  ],
  KGMTL_target: [
    {
        "id": "BE0004014",
        "name": "1a"
    },
    {
        "id": "BE0004030",
        "name": "A1"
    },
    {
        "id": "1",
        "name": "A1BG"
    },
    {
        "id": "2",
        "name": "A2M"
    },
    {
        "id": "344752",
        "name": "AADACL2"
    },
    {
        "id": "51166",
        "name": "AADAT"
    },
    {
        "id": "22848",
        "name": "AAK1"
    },
    {
        "id": "16",
        "name": "AARS1"
    },
    {
        "id": "57505",
        "name": "AARS2"
    },
    {
        "id": "10157",
        "name": "AASS"
    },
    {
        "id": "18",
        "name": "ABAT"
    },
    {
        "id": "19",
        "name": "ABCA1"
    },
    {
        "id": "5243",
        "name": "ABCB1"
    },
    {
        "id": "8647",
        "name": "ABCB11"
    },
    {
        "id": "340273",
        "name": "ABCB5"
    },
    {
        "id": "4363",
        "name": "ABCC1"
    },
    {
        "id": "1244",
        "name": "ABCC2"
    },
    {
        "id": "10257",
        "name": "ABCC4"
    },
    {
        "id": "10057",
        "name": "ABCC5"
    },
    {
        "id": "368",
        "name": "ABCC6"
    },
    {
        "id": "6833",
        "name": "ABCC8"
    },
    {
        "id": "10060",
        "name": "ABCC9"
    },
    {
        "id": "9619",
        "name": "ABCG1"
    },
    {
        "id": "9429",
        "name": "ABCG2"
    },
    {
        "id": "BE0001902",
        "name": "ABL"
    },
    {
        "id": "25",
        "name": "ABL1"
    },
    {
        "id": "27",
        "name": "ABL2"
    },
    {
        "id": "28",
        "name": "ABO"
    },
    {
        "id": "30",
        "name": "ACAA1"
    },
    {
        "id": "31",
        "name": "ACACA"
    },
    {
        "id": "32",
        "name": "ACACB"
    },
    {
        "id": "27034",
        "name": "ACAD8"
    },
    {
        "id": "34",
        "name": "ACADM"
    },
    {
        "id": "35",
        "name": "ACADS"
    },
    {
        "id": "36",
        "name": "ACADSB"
    },
    {
        "id": "176",
        "name": "ACAN"
    },
    {
        "id": "38",
        "name": "ACAT1"
    },
    {
        "id": "39",
        "name": "ACAT2"
    },
    {
        "id": "84680",
        "name": "ACCS"
    },
    {
        "id": "1636",
        "name": "ACE"
    },
    {
        "id": "59272",
        "name": "ACE2"
    },
    {
        "id": "43",
        "name": "ACHE"
    },
    {
        "id": "47",
        "name": "ACLY"
    },
    {
        "id": "50",
        "name": "ACO2"
    },
    {
        "id": "55856",
        "name": "ACOT13"
    },
    {
        "id": "51",
        "name": "ACOX1"
    },
    {
        "id": "52",
        "name": "ACP1"
    },
    {
        "id": "55",
        "name": "ACP3"
    },
    {
        "id": "54",
        "name": "ACP5"
    },
    {
        "id": "23205",
        "name": "ACSBG1"
    },
    {
        "id": "2180",
        "name": "ACSL1"
    },
    {
        "id": "2181",
        "name": "ACSL3"
    },
    {
        "id": "2182",
        "name": "ACSL4"
    },
    {
        "id": "84532",
        "name": "ACSS1"
    },
    {
        "id": "55902",
        "name": "ACSS2"
    },
    {
        "id": "BE0009170",
        "name": "ACT1"
    },
    {
        "id": "58",
        "name": "ACTA1"
    },
    {
        "id": "59",
        "name": "ACTA2"
    },
    {
        "id": "60",
        "name": "ACTB"
    },
    {
        "id": "71",
        "name": "ACTG1"
    },
    {
        "id": "87",
        "name": "ACTN1"
    },
    {
        "id": "10097",
        "name": "ACTR2"
    },
    {
        "id": "10096",
        "name": "ACTR3"
    },
    {
        "id": "90",
        "name": "ACVR1"
    },
    {
        "id": "91",
        "name": "ACVR1B"
    },
    {
        "id": "92",
        "name": "ACVR2A"
    },
    {
        "id": "94",
        "name": "ACVRL1"
    },
    {
        "id": "95",
        "name": "ACY1"
    },
    {
        "id": "91703",
        "name": "ACY3"
    },
    {
        "id": "98",
        "name": "ACYP2"
    },
    {
        "id": "100",
        "name": "ADA"
    },
    {
        "id": "102",
        "name": "ADAM10"
    },
    {
        "id": "6868",
        "name": "ADAM17"
    },
    {
        "id": "10863",
        "name": "ADAM28"
    },
    {
        "id": "8754",
        "name": "ADAM9"
    },
    {
        "id": "11096",
        "name": "ADAMTS5"
    },
    {
        "id": "107",
        "name": "ADCY1"
    },
    {
        "id": "108",
        "name": "ADCY2"
    },
    {
        "id": "111",
        "name": "ADCY5"
    },
    {
        "id": "116",
        "name": "ADCYAP1"
    },
    {
        "id": "283383",
        "name": "ADGRD1"
    },
    {
        "id": "266977",
        "name": "ADGRF1"
    },
    {
        "id": "124",
        "name": "ADH1A"
    },
    {
        "id": "125",
        "name": "ADH1B"
    },
    {
        "id": "126",
        "name": "ADH1C"
    },
    {
        "id": "127",
        "name": "ADH4"
    },
    {
        "id": "128",
        "name": "ADH5"
    },
    {
        "id": "131",
        "name": "ADH7"
    },
    {
        "id": "55256",
        "name": "ADI1"
    },
    {
        "id": "132",
        "name": "ADK"
    },
    {
        "id": "134",
        "name": "ADORA1"
    },
    {
        "id": "135",
        "name": "ADORA2A"
    },
    {
        "id": "136",
        "name": "ADORA2B"
    },
    {
        "id": "140",
        "name": "ADORA3"
    },
    {
        "id": "148",
        "name": "ADRA1A"
    },
    {
        "id": "147",
        "name": "ADRA1B"
    },
    {
        "id": "146",
        "name": "ADRA1D"
    },
    {
        "id": "150",
        "name": "ADRA2A"
    },
    {
        "id": "151",
        "name": "ADRA2B"
    },
    {
        "id": "152",
        "name": "ADRA2C"
    },
    {
        "id": "153",
        "name": "ADRB1"
    },
    {
        "id": "154",
        "name": "ADRB2"
    },
    {
        "id": "155",
        "name": "ADRB3"
    },
    {
        "id": "122622",
        "name": "ADSS1"
    },
    {
        "id": "159",
        "name": "ADSS2"
    },
    {
        "id": "4301",
        "name": "AFDN"
    },
    {
        "id": "BE0004306",
        "name": "AFE_1514"
    },
    {
        "id": "BE0004026",
        "name": "AFE_1792"
    },
    {
        "id": "10939",
        "name": "AFG3L2"
    },
    {
        "id": "173",
        "name": "AFM"
    },
    {
        "id": "183",
        "name": "AGT"
    },
    {
        "id": "185",
        "name": "AGTR1"
    },
    {
        "id": "186",
        "name": "AGTR2"
    },
    {
        "id": "189",
        "name": "AGXT"
    },
    {
        "id": "64902",
        "name": "AGXT2"
    },
    {
        "id": "191",
        "name": "AHCY"
    },
    {
        "id": "196",
        "name": "AHR"
    },
    {
        "id": "197",
        "name": "AHSG"
    },
    {
        "id": "51327",
        "name": "AHSP"
    },
    {
        "id": "9131",
        "name": "AIFM1"
    },
    {
        "id": "203",
        "name": "AK1"
    },
    {
        "id": "204",
        "name": "AK2"
    },
    {
        "id": "158067",
        "name": "AK8"
    },
    {
        "id": "10327",
        "name": "AKR1A1"
    },
    {
        "id": "231",
        "name": "AKR1B1"
    },
    {
        "id": "57016",
        "name": "AKR1B10"
    },
    {
        "id": "1645",
        "name": "AKR1C1"
    },
    {
        "id": "1646",
        "name": "AKR1C2"
    },
    {
        "id": "8644",
        "name": "AKR1C3"
    },
    {
        "id": "1109",
        "name": "AKR1C4"
    },
    {
        "id": "6718",
        "name": "AKR1D1"
    },
    {
        "id": "207",
        "name": "AKT1"
    },
    {
        "id": "208",
        "name": "AKT2"
    },
    {
        "id": "210",
        "name": "ALAD"
    },
    {
        "id": "212",
        "name": "ALAS2"
    },
    {
        "id": "213",
        "name": "ALB"
    },
    {
        "id": "5832",
        "name": "ALDH18A1"
    },
    {
        "id": "216",
        "name": "ALDH1A1"
    },
    {
        "id": "8854",
        "name": "ALDH1A2"
    },
    {
        "id": "220",
        "name": "ALDH1A3"
    },
    {
        "id": "219",
        "name": "ALDH1B1"
    },
    {
        "id": "10840",
        "name": "ALDH1L1"
    },
    {
        "id": "217",
        "name": "ALDH2"
    },
    {
        "id": "218",
        "name": "ALDH3A1"
    },
    {
        "id": "224",
        "name": "ALDH3A2"
    },
    {
        "id": "221",
        "name": "ALDH3B1"
    },
    {
        "id": "8659",
        "name": "ALDH4A1"
    },
    {
        "id": "7915",
        "name": "ALDH5A1"
    },
    {
        "id": "4329",
        "name": "ALDH6A1"
    },
    {
        "id": "501",
        "name": "ALDH7A1"
    },
    {
        "id": "223",
        "name": "ALDH9A1"
    },
    {
        "id": "226",
        "name": "ALDOA"
    },
    {
        "id": "229",
        "name": "ALDOB"
    },
    {
        "id": "238",
        "name": "ALK"
    },
    {
        "id": "121642",
        "name": "ALKBH2"
    },
    {
        "id": "221120",
        "name": "ALKBH3"
    },
    {
        "id": "246",
        "name": "ALOX15"
    },
    {
        "id": "240",
        "name": "ALOX5"
    },
    {
        "id": "241",
        "name": "ALOX5AP"
    },
    {
        "id": "251",
        "name": "ALPG"
    },
    {
        "id": "249",
        "name": "ALPL"
    },
    {
        "id": "250",
        "name": "ALPP"
    },
    {
        "id": "262",
        "name": "AMD\u00a01.00"
    },
    {
        "id": "269",
        "name": "AMHR2"
    },
    {
        "id": "81693",
        "name": "AMN"
    },
    {
        "id": "275",
        "name": "AMT"
    },
    {
        "id": "278",
        "name": "AMY1A"
    },
    {
        "id": "279",
        "name": "AMY2A"
    },
    {
        "id": "280",
        "name": "AMY2B"
    },
    {
        "id": "283",
        "name": "ANG"
    },
    {
        "id": "255239",
        "name": "ANKK1"
    },
    {
        "id": "55107",
        "name": "ANO1"
    },
    {
        "id": "290",
        "name": "ANPEP"
    },
    {
        "id": "84168",
        "name": "ANTXR1"
    },
    {
        "id": "118429",
        "name": "ANTXR2"
    },
    {
        "id": "301",
        "name": "ANXA1"
    },
    {
        "id": "302",
        "name": "ANXA2"
    },
    {
        "id": "306",
        "name": "ANXA3"
    },
    {
        "id": "307",
        "name": "ANXA4"
    },
    {
        "id": "308",
        "name": "ANXA5"
    },
    {
        "id": "26",
        "name": "AOC1"
    },
    {
        "id": "8639",
        "name": "AOC3"
    },
    {
        "id": "316",
        "name": "AOX1"
    },
    {
        "id": "317",
        "name": "APAF1"
    },
    {
        "id": "325",
        "name": "APCS"
    },
    {
        "id": "328",
        "name": "APEX1"
    },
    {
        "id": "51107",
        "name": "APH1A"
    },
    {
        "id": "333",
        "name": "APLP1"
    },
    {
        "id": "334",
        "name": "APLP2"
    },
    {
        "id": "335",
        "name": "APOA1"
    },
    {
        "id": "336",
        "name": "APOA2"
    },
    {
        "id": "338",
        "name": "APOB"
    },
    {
        "id": "339",
        "name": "APOBEC1"
    },
    {
        "id": "55911",
        "name": "APOBR"
    },
    {
        "id": "344",
        "name": "APOC2"
    },
    {
        "id": "345",
        "name": "APOC3"
    },
    {
        "id": "347",
        "name": "APOD"
    },
    {
        "id": "348",
        "name": "APOE"
    },
    {
        "id": "350",
        "name": "APOH"
    },
    {
        "id": "8542",
        "name": "APOL1"
    },
    {
        "id": "351",
        "name": "APP"
    },
    {
        "id": "353",
        "name": "APRT"
    },
    {
        "id": "358",
        "name": "AQP1"
    },
    {
        "id": "360",
        "name": "AQP3"
    },
    {
        "id": "367",
        "name": "AR"
    },
    {
        "id": "369",
        "name": "ARAF"
    },
    {
        "id": "375",
        "name": "ARF1"
    },
    {
        "id": "378",
        "name": "ARF4"
    },
    {
        "id": "382",
        "name": "ARF6"
    },
    {
        "id": "383",
        "name": "ARG1"
    },
    {
        "id": "384",
        "name": "ARG2"
    },
    {
        "id": "26225",
        "name": "ARL5A"
    },
    {
        "id": "221079",
        "name": "ARL5B"
    },
    {
        "id": "400",
        "name": "ARL\u00a01.00"
    },
    {
        "id": "402",
        "name": "ARL\u00a02.00"
    },
    {
        "id": "403",
        "name": "ARL\u00a03.00"
    },
    {
        "id": "10095",
        "name": "ARPC1B"
    },
    {
        "id": "10109",
        "name": "ARPC2"
    },
    {
        "id": "10094",
        "name": "ARPC3"
    },
    {
        "id": "10093",
        "name": "ARPC4"
    },
    {
        "id": "10092",
        "name": "ARPC5"
    },
    {
        "id": "410",
        "name": "ARSA"
    },
    {
        "id": "417",
        "name": "ART1"
    },
    {
        "id": "57412",
        "name": "AS3MT"
    },
    {
        "id": "433",
        "name": "ASGR2"
    },
    {
        "id": "41",
        "name": "ASIC1"
    },
    {
        "id": "40",
        "name": "ASIC2"
    },
    {
        "id": "9311",
        "name": "ASIC3"
    },
    {
        "id": "435",
        "name": "ASL"
    },
    {
        "id": "438",
        "name": "ASMT"
    },
    {
        "id": "440",
        "name": "ASNS"
    },
    {
        "id": "443",
        "name": "ASPA"
    },
    {
        "id": "374569",
        "name": "ASPG"
    },
    {
        "id": "444",
        "name": "ASPH"
    },
    {
        "id": "80150",
        "name": "ASRGL1"
    },
    {
        "id": "466",
        "name": "ATF1"
    },
    {
        "id": "1386",
        "name": "ATF2"
    },
    {
        "id": "467",
        "name": "ATF3"
    },
    {
        "id": "468",
        "name": "ATF4"
    },
    {
        "id": "22809",
        "name": "ATF5"
    },
    {
        "id": "22926",
        "name": "ATF6"
    },
    {
        "id": "11016",
        "name": "ATF7"
    },
    {
        "id": "471",
        "name": "ATIC"
    },
    {
        "id": "472",
        "name": "ATM"
    },
    {
        "id": "475",
        "name": "ATOX1"
    },
    {
        "id": "476",
        "name": "ATP1A1"
    },
    {
        "id": "477",
        "name": "ATP1A2"
    },
    {
        "id": "478",
        "name": "ATP1A3"
    },
    {
        "id": "487",
        "name": "ATP2A1"
    },
    {
        "id": "488",
        "name": "ATP2A2"
    },
    {
        "id": "27032",
        "name": "ATP2C1"
    },
    {
        "id": "495",
        "name": "ATP4A"
    },
    {
        "id": "496",
        "name": "ATP4B"
    },
    {
        "id": "498",
        "name": "ATP5F1A"
    },
    {
        "id": "506",
        "name": "ATP5F1B"
    },
    {
        "id": "509",
        "name": "ATP5F1C"
    },
    {
        "id": "513",
        "name": "ATP5F1D"
    },
    {
        "id": "521",
        "name": "ATP5ME"
    },
    {
        "id": "10632",
        "name": "ATP5MG"
    },
    {
        "id": "539",
        "name": "ATP5PO"
    },
    {
        "id": "BE0009199",
        "name": "ATP6"
    },
    {
        "id": "523",
        "name": "ATP6V1A"
    },
    {
        "id": "526",
        "name": "ATP6V1B2"
    },
    {
        "id": "528",
        "name": "ATP6V1C1"
    },
    {
        "id": "10396",
        "name": "ATP8A1"
    },
    {
        "id": "6790",
        "name": "AURKA"
    },
    {
        "id": "9212",
        "name": "AURKB"
    },
    {
        "id": "6795",
        "name": "AURKC"
    },
    {
        "id": "552",
        "name": "AVPR1A"
    },
    {
        "id": "553",
        "name": "AVPR1B"
    },
    {
        "id": "554",
        "name": "AVPR2"
    },
    {
        "id": "8312",
        "name": "AXIN1"
    },
    {
        "id": "558",
        "name": "AXL"
    },
    {
        "id": "563",
        "name": "AZGP1"
    },
    {
        "id": "113451",
        "name": "AZIN2"
    },
    {
        "id": "BE0002494",
        "name": "Aaar"
    },
    {
        "id": "BE0003241",
        "name": "Acel_0614"
    },
    {
        "id": "BE0002700",
        "name": "Adss"
    },
    {
        "id": "BE0004029",
        "name": "B1"
    },
    {
        "id": "567",
        "name": "B2M"
    },
    {
        "id": "27087",
        "name": "B3GAT1"
    },
    {
        "id": "26229",
        "name": "B3GAT3"
    },
    {
        "id": "2683",
        "name": "B4GALT1"
    },
    {
        "id": "8704",
        "name": "B4GALT2"
    },
    {
        "id": "8703",
        "name": "B4GALT3"
    },
    {
        "id": "8702",
        "name": "B4GALT4"
    },
    {
        "id": "570",
        "name": "BAAT"
    },
    {
        "id": "23621",
        "name": "BACE1"
    },
    {
        "id": "572",
        "name": "BAD"
    },
    {
        "id": "573",
        "name": "BAG1"
    },
    {
        "id": "8424",
        "name": "BBOX1"
    },
    {
        "id": "586",
        "name": "BCAT1"
    },
    {
        "id": "587",
        "name": "BCAT2"
    },
    {
        "id": "BE0004299",
        "name": "BCE_3424"
    },
    {
        "id": "590",
        "name": "BCHE"
    },
    {
        "id": "10295",
        "name": "BCKDK"
    },
    {
        "id": "596",
        "name": "BCL2"
    },
    {
        "id": "598",
        "name": "BCL2L1"
    },
    {
        "id": "599",
        "name": "BCL2L2"
    },
    {
        "id": "613",
        "name": "BCR"
    },
    {
        "id": "BE0003406",
        "name": "BCR/ABL fusion"
    },
    {
        "id": "622",
        "name": "BDH1"
    },
    {
        "id": "623",
        "name": "BDKRB1"
    },
    {
        "id": "624",
        "name": "BDKRB2"
    },
    {
        "id": "627",
        "name": "BDNF"
    },
    {
        "id": "8678",
        "name": "BECN1"
    },
    {
        "id": "632",
        "name": "BGLAP"
    },
    {
        "id": "BE0002737",
        "name": "BH0236"
    },
    {
        "id": "BE0004371",
        "name": "BH0413"
    },
    {
        "id": "BE0002682",
        "name": "BH0798"
    },
    {
        "id": "BE0003069",
        "name": "BH2105"
    },
    {
        "id": "635",
        "name": "BHMT"
    },
    {
        "id": "23743",
        "name": "BHMT2"
    },
    {
        "id": "332",
        "name": "BIRC5"
    },
    {
        "id": "640",
        "name": "BLK"
    },
    {
        "id": "BE0000712",
        "name": "BLLF1"
    },
    {
        "id": "644",
        "name": "BLVRA"
    },
    {
        "id": "645",
        "name": "BLVRB"
    },
    {
        "id": "55589",
        "name": "BMP2K"
    },
    {
        "id": "652",
        "name": "BMP4"
    },
    {
        "id": "657",
        "name": "BMPR1A"
    },
    {
        "id": "658",
        "name": "BMPR1B"
    },
    {
        "id": "659",
        "name": "BMPR2"
    },
    {
        "id": "660",
        "name": "BMX"
    },
    {
        "id": "670",
        "name": "BPHL"
    },
    {
        "id": "671",
        "name": "BPI"
    },
    {
        "id": "673",
        "name": "BRAF"
    },
    {
        "id": "79184",
        "name": "BRCC3"
    },
    {
        "id": "683",
        "name": "BST1"
    },
    {
        "id": "695",
        "name": "BTK"
    },
    {
        "id": "BE0004375",
        "name": "BURPS1710b_3237"
    },
    {
        "id": "712",
        "name": "C1QA"
    },
    {
        "id": "713",
        "name": "C1QB"
    },
    {
        "id": "708",
        "name": "C1QBP"
    },
    {
        "id": "714",
        "name": "C1QC"
    },
    {
        "id": "715",
        "name": "C1R"
    },
    {
        "id": "716",
        "name": "C1S"
    },
    {
        "id": "BE0001472",
        "name": "C3"
    },
    {
        "id": "718",
        "name": "C3"
    },
    {
        "id": "722",
        "name": "C4BPA"
    },
    {
        "id": "725",
        "name": "C4BPB"
    },
    {
        "id": "727",
        "name": "C5"
    },
    {
        "id": "731",
        "name": "C8A"
    },
    {
        "id": "732",
        "name": "C8B"
    },
    {
        "id": "733",
        "name": "C8G"
    },
    {
        "id": "735",
        "name": "C9"
    },
    {
        "id": "759",
        "name": "CA1"
    },
    {
        "id": "56934",
        "name": "CA10"
    },
    {
        "id": "770",
        "name": "CA11"
    },
    {
        "id": "771",
        "name": "CA12"
    },
    {
        "id": "377677",
        "name": "CA13"
    },
    {
        "id": "23632",
        "name": "CA14"
    },
    {
        "id": "760",
        "name": "CA2"
    },
    {
        "id": "762",
        "name": "CA4"
    },
    {
        "id": "763",
        "name": "CA5A"
    },
    {
        "id": "11238",
        "name": "CA5B"
    },
    {
        "id": "765",
        "name": "CA6"
    },
    {
        "id": "766",
        "name": "CA7"
    },
    {
        "id": "767",
        "name": "CA8"
    },
    {
        "id": "768",
        "name": "CA9"
    },
    {
        "id": "773",
        "name": "CACNA1A"
    },
    {
        "id": "BE0009351",
        "name": "CACNA1B"
    },
    {
        "id": "774",
        "name": "CACNA1B"
    },
    {
        "id": "775",
        "name": "CACNA1C"
    },
    {
        "id": "776",
        "name": "CACNA1D"
    },
    {
        "id": "777",
        "name": "CACNA1E"
    },
    {
        "id": "778",
        "name": "CACNA1F"
    },
    {
        "id": "8913",
        "name": "CACNA1G"
    },
    {
        "id": "8912",
        "name": "CACNA1H"
    },
    {
        "id": "8911",
        "name": "CACNA1I"
    },
    {
        "id": "779",
        "name": "CACNA1S"
    },
    {
        "id": "781",
        "name": "CACNA2D1"
    },
    {
        "id": "9254",
        "name": "CACNA2D2"
    },
    {
        "id": "55799",
        "name": "CACNA2D3"
    },
    {
        "id": "93589",
        "name": "CACNA2D4"
    },
    {
        "id": "782",
        "name": "CACNB1"
    },
    {
        "id": "783",
        "name": "CACNB2"
    },
    {
        "id": "784",
        "name": "CACNB3"
    },
    {
        "id": "785",
        "name": "CACNB4"
    },
    {
        "id": "786",
        "name": "CACNG1"
    },
    {
        "id": "10369",
        "name": "CACNG2"
    },
    {
        "id": "10368",
        "name": "CACNG3"
    },
    {
        "id": "27092",
        "name": "CACNG4"
    },
    {
        "id": "27091",
        "name": "CACNG5"
    },
    {
        "id": "59285",
        "name": "CACNG6"
    },
    {
        "id": "59284",
        "name": "CACNG7"
    },
    {
        "id": "59283",
        "name": "CACNG8"
    },
    {
        "id": "790",
        "name": "CAD"
    },
    {
        "id": "8618",
        "name": "CADPS"
    },
    {
        "id": "93664",
        "name": "CADPS2"
    },
    {
        "id": "794",
        "name": "CALB2"
    },
    {
        "id": "796",
        "name": "CALCA"
    },
    {
        "id": "797",
        "name": "CALCB"
    },
    {
        "id": "799",
        "name": "CALCR"
    },
    {
        "id": "10203",
        "name": "CALCRL"
    },
    {
        "id": "811",
        "name": "CALR"
    },
    {
        "id": "125972",
        "name": "CALR3"
    },
    {
        "id": "50632",
        "name": "CALY"
    },
    {
        "id": "8536",
        "name": "CAMK1"
    },
    {
        "id": "57118",
        "name": "CAMK1D"
    },
    {
        "id": "57172",
        "name": "CAMK1G"
    },
    {
        "id": "815",
        "name": "CAMK2A"
    },
    {
        "id": "816",
        "name": "CAMK2B"
    },
    {
        "id": "817",
        "name": "CAMK2D"
    },
    {
        "id": "818",
        "name": "CAMK2G"
    },
    {
        "id": "814",
        "name": "CAMK4"
    },
    {
        "id": "84254",
        "name": "CAMKK1"
    },
    {
        "id": "10645",
        "name": "CAMKK2"
    },
    {
        "id": "819",
        "name": "CAMLG"
    },
    {
        "id": "820",
        "name": "CAMP"
    },
    {
        "id": "124583",
        "name": "CANT1"
    },
    {
        "id": "821",
        "name": "CANX"
    },
    {
        "id": "823",
        "name": "CAPN1"
    },
    {
        "id": "825",
        "name": "CAPN3"
    },
    {
        "id": "826",
        "name": "CAPNS1"
    },
    {
        "id": "828",
        "name": "CAPS"
    },
    {
        "id": "833",
        "name": "CARS1"
    },
    {
        "id": "79587",
        "name": "CARS2"
    },
    {
        "id": "9607",
        "name": "CARTPT"
    },
    {
        "id": "8573",
        "name": "CASK"
    },
    {
        "id": "834",
        "name": "CASP1"
    },
    {
        "id": "836",
        "name": "CASP3"
    },
    {
        "id": "840",
        "name": "CASP7"
    },
    {
        "id": "841",
        "name": "CASP8"
    },
    {
        "id": "844",
        "name": "CASQ1"
    },
    {
        "id": "845",
        "name": "CASQ2"
    },
    {
        "id": "846",
        "name": "CASR"
    },
    {
        "id": "831",
        "name": "CAST"
    },
    {
        "id": "847",
        "name": "CAT"
    },
    {
        "id": "873",
        "name": "CBR1"
    },
    {
        "id": "875",
        "name": "CBS"
    },
    {
        "id": "23468",
        "name": "CBX5"
    },
    {
        "id": "886",
        "name": "CCKAR"
    },
    {
        "id": "887",
        "name": "CCKBR"
    },
    {
        "id": "6356",
        "name": "CCL11"
    },
    {
        "id": "6347",
        "name": "CCL2"
    },
    {
        "id": "6348",
        "name": "CCL3"
    },
    {
        "id": "6352",
        "name": "CCL5"
    },
    {
        "id": "6355",
        "name": "CCL8"
    },
    {
        "id": "4856",
        "name": "CCN3"
    },
    {
        "id": "890",
        "name": "CCNA2"
    },
    {
        "id": "595",
        "name": "CCND1"
    },
    {
        "id": "729230",
        "name": "CCR2"
    },
    {
        "id": "1233",
        "name": "CCR4"
    },
    {
        "id": "1234",
        "name": "CCR5"
    },
    {
        "id": "10803",
        "name": "CCR9"
    },
    {
        "id": "9973",
        "name": "CCS"
    },
    {
        "id": "7203",
        "name": "CCT3"
    },
    {
        "id": "9332",
        "name": "CD163"
    },
    {
        "id": "930",
        "name": "CD19"
    },
    {
        "id": "909",
        "name": "CD1A"
    },
    {
        "id": "912",
        "name": "CD1D"
    },
    {
        "id": "914",
        "name": "CD2"
    },
    {
        "id": "933",
        "name": "CD22"
    },
    {
        "id": "919",
        "name": "CD247"
    },
    {
        "id": "29126",
        "name": "CD274"
    },
    {
        "id": "945",
        "name": "CD33"
    },
    {
        "id": "952",
        "name": "CD38"
    },
    {
        "id": "915",
        "name": "CD3D"
    },
    {
        "id": "916",
        "name": "CD3E"
    },
    {
        "id": "917",
        "name": "CD3G"
    },
    {
        "id": "920",
        "name": "CD4"
    },
    {
        "id": "958",
        "name": "CD40"
    },
    {
        "id": "959",
        "name": "CD40LG"
    },
    {
        "id": "960",
        "name": "CD44"
    },
    {
        "id": "1043",
        "name": "CD52"
    },
    {
        "id": "1604",
        "name": "CD55"
    },
    {
        "id": "974",
        "name": "CD79B"
    },
    {
        "id": "941",
        "name": "CD80"
    },
    {
        "id": "942",
        "name": "CD86"
    },
    {
        "id": "928",
        "name": "CD9"
    },
    {
        "id": "978",
        "name": "CDA"
    },
    {
        "id": "994",
        "name": "CDC25B"
    },
    {
        "id": "998",
        "name": "CDC42"
    },
    {
        "id": "55561",
        "name": "CDC42BPG"
    },
    {
        "id": "BE0002395",
        "name": "CDC60"
    },
    {
        "id": "1009",
        "name": "CDH11"
    },
    {
        "id": "1003",
        "name": "CDH5"
    },
    {
        "id": "10423",
        "name": "CDIPT"
    },
    {
        "id": "983",
        "name": "CDK1"
    },
    {
        "id": "65061",
        "name": "CDK15"
    },
    {
        "id": "5127",
        "name": "CDK16"
    },
    {
        "id": "5128",
        "name": "CDK17"
    },
    {
        "id": "1017",
        "name": "CDK2"
    },
    {
        "id": "1019",
        "name": "CDK4"
    },
    {
        "id": "1020",
        "name": "CDK5"
    },
    {
        "id": "8851",
        "name": "CDK5R1"
    },
    {
        "id": "1021",
        "name": "CDK6"
    },
    {
        "id": "1022",
        "name": "CDK7"
    },
    {
        "id": "1024",
        "name": "CDK8"
    },
    {
        "id": "1025",
        "name": "CDK9"
    },
    {
        "id": "8814",
        "name": "CDKL1"
    },
    {
        "id": "8999",
        "name": "CDKL2"
    },
    {
        "id": "1026",
        "name": "CDKN1A"
    },
    {
        "id": "1036",
        "name": "CDO1"
    },
    {
        "id": "634",
        "name": "CEACAM1"
    },
    {
        "id": "1048",
        "name": "CEACAM5"
    },
    {
        "id": "1051",
        "name": "CEBPB"
    },
    {
        "id": "1990",
        "name": "CELA1"
    },
    {
        "id": "63036",
        "name": "CELA2A"
    },
    {
        "id": "1062",
        "name": "CENPE"
    },
    {
        "id": "1066",
        "name": "CES1"
    },
    {
        "id": "1071",
        "name": "CETP"
    },
    {
        "id": "629",
        "name": "CFB"
    },
    {
        "id": "1675",
        "name": "CFD"
    },
    {
        "id": "3075",
        "name": "CFH"
    },
    {
        "id": "3426",
        "name": "CFI"
    },
    {
        "id": "1072",
        "name": "CFL1"
    },
    {
        "id": "1080",
        "name": "CFTR"
    },
    {
        "id": "1103",
        "name": "CHAT"
    },
    {
        "id": "1105",
        "name": "CHD1"
    },
    {
        "id": "1111",
        "name": "CHEK1"
    },
    {
        "id": "11200",
        "name": "CHEK2"
    },
    {
        "id": "1118",
        "name": "CHIT1"
    },
    {
        "id": "11261",
        "name": "CHP1"
    },
    {
        "id": "1128",
        "name": "CHRM1"
    },
    {
        "id": "1129",
        "name": "CHRM2"
    },
    {
        "id": "1131",
        "name": "CHRM3"
    },
    {
        "id": "1132",
        "name": "CHRM4"
    },
    {
        "id": "1133",
        "name": "CHRM5"
    },
    {
        "id": "1134",
        "name": "CHRNA1"
    },
    {
        "id": "57053",
        "name": "CHRNA10"
    },
    {
        "id": "1135",
        "name": "CHRNA2"
    },
    {
        "id": "1136",
        "name": "CHRNA3"
    },
    {
        "id": "1137",
        "name": "CHRNA4"
    },
    {
        "id": "1138",
        "name": "CHRNA5"
    },
    {
        "id": "8973",
        "name": "CHRNA6"
    },
    {
        "id": "BE0003438",
        "name": "CHRNA7"
    },
    {
        "id": "55584",
        "name": "CHRNA9"
    },
    {
        "id": "1140",
        "name": "CHRNB1"
    },
    {
        "id": "1141",
        "name": "CHRNB2"
    },
    {
        "id": "1142",
        "name": "CHRNB3"
    },
    {
        "id": "1143",
        "name": "CHRNB4"
    },
    {
        "id": "1144",
        "name": "CHRND"
    },
    {
        "id": "1145",
        "name": "CHRNE"
    },
    {
        "id": "1146",
        "name": "CHRNG"
    },
    {
        "id": "1147",
        "name": "CHUK"
    },
    {
        "id": "10519",
        "name": "CIB1"
    },
    {
        "id": "10518",
        "name": "CIB2"
    },
    {
        "id": "22858",
        "name": "CILK1"
    },
    {
        "id": "11113",
        "name": "CIT"
    },
    {
        "id": "1152",
        "name": "CKB"
    },
    {
        "id": "1158",
        "name": "CKM"
    },
    {
        "id": "548596",
        "name": "CKMT1A"
    },
    {
        "id": "1160",
        "name": "CKMT2"
    },
    {
        "id": "1163",
        "name": "CKS1B"
    },
    {
        "id": "23332",
        "name": "CLASP1"
    },
    {
        "id": "1178",
        "name": "CLC"
    },
    {
        "id": "1181",
        "name": "CLCN2"
    },
    {
        "id": "1187",
        "name": "CLCNKA"
    },
    {
        "id": "161198",
        "name": "CLEC14A"
    },
    {
        "id": "7123",
        "name": "CLEC3B"
    },
    {
        "id": "26253",
        "name": "CLEC4E"
    },
    {
        "id": "1192",
        "name": "CLIC1"
    },
    {
        "id": "1195",
        "name": "CLK1"
    },
    {
        "id": "1196",
        "name": "CLK2"
    },
    {
        "id": "1198",
        "name": "CLK3"
    },
    {
        "id": "57396",
        "name": "CLK4"
    },
    {
        "id": "8192",
        "name": "CLPP"
    },
    {
        "id": "1208",
        "name": "CLPS"
    },
    {
        "id": "1191",
        "name": "CLU"
    },
    {
        "id": "1215",
        "name": "CMA1"
    },
    {
        "id": "55907",
        "name": "CMAS"
    },
    {
        "id": "51727",
        "name": "CMPK1"
    },
    {
        "id": "23070",
        "name": "CMTR1"
    },
    {
        "id": "1259",
        "name": "CNGA1"
    },
    {
        "id": "1268",
        "name": "CNR1"
    },
    {
        "id": "1269",
        "name": "CNR2"
    },
    {
        "id": "1277",
        "name": "COL1A1"
    },
    {
        "id": "1278",
        "name": "COL1A2"
    },
    {
        "id": "1280",
        "name": "COL2A1"
    },
    {
        "id": "1281",
        "name": "COL3A1"
    },
    {
        "id": "1286",
        "name": "COL4A4"
    },
    {
        "id": "1311",
        "name": "COMP"
    },
    {
        "id": "1312",
        "name": "COMT"
    },
    {
        "id": "118881",
        "name": "COMTD1"
    },
    {
        "id": "22820",
        "name": "COPG1"
    },
    {
        "id": "56997",
        "name": "COQ8A"
    },
    {
        "id": "79934",
        "name": "COQ8B"
    },
    {
        "id": "442942",
        "name": "COX-2"
    },
    {
        "id": "1327",
        "name": "COX4I1"
    },
    {
        "id": "9377",
        "name": "COX5A"
    },
    {
        "id": "1329",
        "name": "COX5B"
    },
    {
        "id": "1339",
        "name": "COX6A2"
    },
    {
        "id": "1340",
        "name": "COX6B1"
    },
    {
        "id": "1345",
        "name": "COX6C"
    },
    {
        "id": "1346",
        "name": "COX7A1"
    },
    {
        "id": "1349",
        "name": "COX7B"
    },
    {
        "id": "1350",
        "name": "COX7C"
    },
    {
        "id": "1351",
        "name": "COX8A"
    },
    {
        "id": "1356",
        "name": "CP"
    },
    {
        "id": "1357",
        "name": "CPA1"
    },
    {
        "id": "1360",
        "name": "CPB1"
    },
    {
        "id": "1361",
        "name": "CPB2"
    },
    {
        "id": "1362",
        "name": "CPD"
    },
    {
        "id": "1363",
        "name": "CPE"
    },
    {
        "id": "1369",
        "name": "CPN1"
    },
    {
        "id": "1370",
        "name": "CPN2"
    },
    {
        "id": "8904",
        "name": "CPNE1"
    },
    {
        "id": "10404",
        "name": "CPQ"
    },
    {
        "id": "1373",
        "name": "CPS1"
    },
    {
        "id": "1374",
        "name": "CPT1A"
    },
    {
        "id": "1376",
        "name": "CPT2"
    },
    {
        "id": "1384",
        "name": "CRAT"
    },
    {
        "id": "51185",
        "name": "CRBN"
    },
    {
        "id": "1385",
        "name": "CREB1"
    },
    {
        "id": "1387",
        "name": "CREBBP"
    },
    {
        "id": "1392",
        "name": "CRH"
    },
    {
        "id": "1394",
        "name": "CRHR1"
    },
    {
        "id": "BE0001827",
        "name": "CRK2"
    },
    {
        "id": "54677",
        "name": "CROT"
    },
    {
        "id": "1401",
        "name": "CRP"
    },
    {
        "id": "BE0009205",
        "name": "CRT"
    },
    {
        "id": "1428",
        "name": "CRYM"
    },
    {
        "id": "1429",
        "name": "CRYZ"
    },
    {
        "id": "1431",
        "name": "CS"
    },
    {
        "id": "51380",
        "name": "CSAD"
    },
    {
        "id": "1436",
        "name": "CSF1R"
    },
    {
        "id": "1437",
        "name": "CSF2"
    },
    {
        "id": "BE0002482",
        "name": "CSF2RA"
    },
    {
        "id": "1438",
        "name": "CSF2RA"
    },
    {
        "id": "1439",
        "name": "CSF2RB"
    },
    {
        "id": "1441",
        "name": "CSF3R"
    },
    {
        "id": "1445",
        "name": "CSK"
    },
    {
        "id": "1448",
        "name": "CSN3"
    },
    {
        "id": "1452",
        "name": "CSNK1A1"
    },
    {
        "id": "1454",
        "name": "CSNK1E"
    },
    {
        "id": "53944",
        "name": "CSNK1G1"
    },
    {
        "id": "1455",
        "name": "CSNK1G2"
    },
    {
        "id": "1456",
        "name": "CSNK1G3"
    },
    {
        "id": "1457",
        "name": "CSNK2A1"
    },
    {
        "id": "1459",
        "name": "CSNK2A2"
    },
    {
        "id": "1460",
        "name": "CSNK2B"
    },
    {
        "id": "1465",
        "name": "CSRP1"
    },
    {
        "id": "1476",
        "name": "CSTB"
    },
    {
        "id": "1487",
        "name": "CTBP1"
    },
    {
        "id": "58190",
        "name": "CTDSP1"
    },
    {
        "id": "1491",
        "name": "CTH"
    },
    {
        "id": "1493",
        "name": "CTLA4"
    },
    {
        "id": "1499",
        "name": "CTNNB1"
    },
    {
        "id": "1497",
        "name": "CTNS"
    },
    {
        "id": "1503",
        "name": "CTPS1"
    },
    {
        "id": "1504",
        "name": "CTRB1"
    },
    {
        "id": "1508",
        "name": "CTSB"
    },
    {
        "id": "1509",
        "name": "CTSD"
    },
    {
        "id": "8722",
        "name": "CTSF"
    },
    {
        "id": "1511",
        "name": "CTSG"
    },
    {
        "id": "1512",
        "name": "CTSH"
    },
    {
        "id": "1513",
        "name": "CTSK"
    },
    {
        "id": "1514",
        "name": "CTSL"
    },
    {
        "id": "1520",
        "name": "CTSS"
    },
    {
        "id": "1515",
        "name": "CTSV"
    },
    {
        "id": "8029",
        "name": "CUBN"
    },
    {
        "id": "3627",
        "name": "CXCL10"
    },
    {
        "id": "6387",
        "name": "CXCL12"
    },
    {
        "id": "3576",
        "name": "CXCL8"
    },
    {
        "id": "3577",
        "name": "CXCR1"
    },
    {
        "id": "7852",
        "name": "CXCR4"
    },
    {
        "id": "1528",
        "name": "CYB5A"
    },
    {
        "id": "80777",
        "name": "CYB5B"
    },
    {
        "id": "51706",
        "name": "CYB5R1"
    },
    {
        "id": "1727",
        "name": "CYB5R3"
    },
    {
        "id": "1535",
        "name": "CYBA"
    },
    {
        "id": "1536",
        "name": "CYBB"
    },
    {
        "id": "1537",
        "name": "CYC1"
    },
    {
        "id": "54205",
        "name": "CYCS"
    },
    {
        "id": "1583",
        "name": "CYP11A1"
    },
    {
        "id": "1584",
        "name": "CYP11B1"
    },
    {
        "id": "1585",
        "name": "CYP11B2"
    },
    {
        "id": "1586",
        "name": "CYP17A1"
    },
    {
        "id": "1588",
        "name": "CYP19A1"
    },
    {
        "id": "1543",
        "name": "CYP1A1"
    },
    {
        "id": "1544",
        "name": "CYP1A2"
    },
    {
        "id": "1545",
        "name": "CYP1B1"
    },
    {
        "id": "1589",
        "name": "CYP21A2"
    },
    {
        "id": "1591",
        "name": "CYP24A1"
    },
    {
        "id": "1592",
        "name": "CYP26A1"
    },
    {
        "id": "56603",
        "name": "CYP26B1"
    },
    {
        "id": "340665",
        "name": "CYP26C1"
    },
    {
        "id": "1594",
        "name": "CYP27B1"
    },
    {
        "id": "1548",
        "name": "CYP2A6"
    },
    {
        "id": "1555",
        "name": "CYP2B6"
    },
    {
        "id": "1562",
        "name": "CYP2C18"
    },
    {
        "id": "1557",
        "name": "CYP2C19"
    },
    {
        "id": "1558",
        "name": "CYP2C8"
    },
    {
        "id": "1559",
        "name": "CYP2C9"
    },
    {
        "id": "1565",
        "name": "CYP2D6"
    },
    {
        "id": "1576",
        "name": "CYP3A4"
    },
    {
        "id": "64816",
        "name": "CYP3A43"
    },
    {
        "id": "1577",
        "name": "CYP3A5"
    },
    {
        "id": "1551",
        "name": "CYP3A7"
    },
    {
        "id": "1579",
        "name": "CYP4A11"
    },
    {
        "id": "1595",
        "name": "CYP51A1"
    },
    {
        "id": "1571",
        "name": "CYP\u00a020.00"
    },
    {
        "id": "10800",
        "name": "CYSLTR1"
    },
    {
        "id": "57105",
        "name": "CYSLTR2"
    },
    {
        "id": "9266",
        "name": "CYTH2"
    },
    {
        "id": "9265",
        "name": "CYTH3"
    },
    {
        "id": "199699",
        "name": "DAND5"
    },
    {
        "id": "1610",
        "name": "DAO"
    },
    {
        "id": "1612",
        "name": "DAPK1"
    },
    {
        "id": "23604",
        "name": "DAPK2"
    },
    {
        "id": "1613",
        "name": "DAPK3"
    },
    {
        "id": "27071",
        "name": "DAPP1"
    },
    {
        "id": "1615",
        "name": "DARS1"
    },
    {
        "id": "55157",
        "name": "DARS2"
    },
    {
        "id": "1621",
        "name": "DBH"
    },
    {
        "id": "1622",
        "name": "DBI"
    },
    {
        "id": "117159",
        "name": "DCD"
    },
    {
        "id": "1633",
        "name": "DCK"
    },
    {
        "id": "9201",
        "name": "DCLK1"
    },
    {
        "id": "166614",
        "name": "DCLK2"
    },
    {
        "id": "85443",
        "name": "DCLK3"
    },
    {
        "id": "1634",
        "name": "DCN"
    },
    {
        "id": "28960",
        "name": "DCPS"
    },
    {
        "id": "51181",
        "name": "DCXR"
    },
    {
        "id": "23576",
        "name": "DDAH1"
    },
    {
        "id": "23564",
        "name": "DDAH2"
    },
    {
        "id": "BE0002160",
        "name": "DDC"
    },
    {
        "id": "1644",
        "name": "DDC"
    },
    {
        "id": "780",
        "name": "DDR1"
    },
    {
        "id": "4921",
        "name": "DDR2"
    },
    {
        "id": "7919",
        "name": "DDX39B"
    },
    {
        "id": "1655",
        "name": "DDX5"
    },
    {
        "id": "1656",
        "name": "DDX6"
    },
    {
        "id": "1666",
        "name": "DECR1"
    },
    {
        "id": "BE0001302",
        "name": "DFR1"
    },
    {
        "id": "84649",
        "name": "DGAT2"
    },
    {
        "id": "1606",
        "name": "DGKA"
    },
    {
        "id": "8527",
        "name": "DGKD"
    },
    {
        "id": "1608",
        "name": "DGKG"
    },
    {
        "id": "1717",
        "name": "DHCR7"
    },
    {
        "id": "1719",
        "name": "DHFR"
    },
    {
        "id": "200895",
        "name": "DHFR2"
    },
    {
        "id": "1723",
        "name": "DHODH"
    },
    {
        "id": "1725",
        "name": "DHPS"
    },
    {
        "id": "79154",
        "name": "DHRS11"
    },
    {
        "id": "9249",
        "name": "DHRS3"
    },
    {
        "id": "10901",
        "name": "DHRS4"
    },
    {
        "id": "56616",
        "name": "DIABLO"
    },
    {
        "id": "1735",
        "name": "DIO3"
    },
    {
        "id": "1737",
        "name": "DLAT"
    },
    {
        "id": "1738",
        "name": "DLD"
    },
    {
        "id": "1742",
        "name": "DLG4"
    },
    {
        "id": "11144",
        "name": "DMC1"
    },
    {
        "id": "1756",
        "name": "DMD"
    },
    {
        "id": "1760",
        "name": "DMPK"
    },
    {
        "id": "BE0009350",
        "name": "DNApol"
    },
    {
        "id": "1786",
        "name": "DNMT1"
    },
    {
        "id": "1788",
        "name": "DNMT3A"
    },
    {
        "id": "23549",
        "name": "DNPEP"
    },
    {
        "id": "1791",
        "name": "DNTT"
    },
    {
        "id": "1800",
        "name": "DPEP1"
    },
    {
        "id": "51611",
        "name": "DPH5"
    },
    {
        "id": "1803",
        "name": "DPP4"
    },
    {
        "id": "1806",
        "name": "DPYD"
    },
    {
        "id": "1808",
        "name": "DPYSL2"
    },
    {
        "id": "1812",
        "name": "DRD1"
    },
    {
        "id": "1813",
        "name": "DRD2"
    },
    {
        "id": "1814",
        "name": "DRD3"
    },
    {
        "id": "1815",
        "name": "DRD4"
    },
    {
        "id": "1816",
        "name": "DRD5"
    },
    {
        "id": "1832",
        "name": "DSP"
    },
    {
        "id": "11034",
        "name": "DSTN"
    },
    {
        "id": "1841",
        "name": "DTYMK"
    },
    {
        "id": "54935",
        "name": "DUSP23"
    },
    {
        "id": "1854",
        "name": "DUT"
    },
    {
        "id": "1859",
        "name": "DYRK1A"
    },
    {
        "id": "9149",
        "name": "DYRK1B"
    },
    {
        "id": "BE0002872",
        "name": "Ddes_2038"
    },
    {
        "id": "BE0003055",
        "name": "Ddes_2150"
    },
    {
        "id": "BE0003041",
        "name": "DvMF_2023"
    },
    {
        "id": "BE0002886",
        "name": "DvMF_2499"
    },
    {
        "id": "BE0001685",
        "name": "E2"
    },
    {
        "id": "124454",
        "name": "EARS2"
    },
    {
        "id": "10682",
        "name": "EBP"
    },
    {
        "id": "1889",
        "name": "ECE1"
    },
    {
        "id": "1892",
        "name": "ECHS1"
    },
    {
        "id": "1632",
        "name": "ECI1"
    },
    {
        "id": "10455",
        "name": "ECI2"
    },
    {
        "id": "1906",
        "name": "EDN1"
    },
    {
        "id": "1909",
        "name": "EDNRA"
    },
    {
        "id": "1910",
        "name": "EDNRB"
    },
    {
        "id": "8411",
        "name": "EEA1"
    },
    {
        "id": "1915",
        "name": "EEF1A1"
    },
    {
        "id": "BE0008971",
        "name": "EEF1A1L14"
    },
    {
        "id": "1938",
        "name": "EEF2"
    },
    {
        "id": "79631",
        "name": "EFL1"
    },
    {
        "id": "1950",
        "name": "EGF"
    },
    {
        "id": "1956",
        "name": "EGFR"
    },
    {
        "id": "54583",
        "name": "EGLN1"
    },
    {
        "id": "112398",
        "name": "EGLN2"
    },
    {
        "id": "112399",
        "name": "EGLN3"
    },
    {
        "id": "1962",
        "name": "EHHADH"
    },
    {
        "id": "27102",
        "name": "EIF2AK1"
    },
    {
        "id": "5610",
        "name": "EIF2AK2"
    },
    {
        "id": "440275",
        "name": "EIF2AK4"
    },
    {
        "id": "1968",
        "name": "EIF2S3"
    },
    {
        "id": "8665",
        "name": "EIF3F"
    },
    {
        "id": "1973",
        "name": "EIF4A1"
    },
    {
        "id": "1977",
        "name": "EIF4E"
    },
    {
        "id": "3692",
        "name": "EIF6"
    },
    {
        "id": "1991",
        "name": "ELANE"
    },
    {
        "id": "2006",
        "name": "ELN"
    },
    {
        "id": "6785",
        "name": "ELOVL4"
    },
    {
        "id": "64100",
        "name": "ELSPBP1"
    },
    {
        "id": "2020",
        "name": "EN2"
    },
    {
        "id": "2022",
        "name": "ENG"
    },
    {
        "id": "2023",
        "name": "ENO1"
    },
    {
        "id": "2026",
        "name": "ENO2"
    },
    {
        "id": "2027",
        "name": "ENO3"
    },
    {
        "id": "58478",
        "name": "ENOPH1"
    },
    {
        "id": "10495",
        "name": "ENOX2"
    },
    {
        "id": "2028",
        "name": "ENPEP"
    },
    {
        "id": "5167",
        "name": "ENPP1"
    },
    {
        "id": "2034",
        "name": "EPAS1"
    },
    {
        "id": "4072",
        "name": "EPCAM"
    },
    {
        "id": "2041",
        "name": "EPHA1"
    },
    {
        "id": "1969",
        "name": "EPHA2"
    },
    {
        "id": "2042",
        "name": "EPHA3"
    },
    {
        "id": "2043",
        "name": "EPHA4"
    },
    {
        "id": "2044",
        "name": "EPHA5"
    },
    {
        "id": "285220",
        "name": "EPHA6"
    },
    {
        "id": "2045",
        "name": "EPHA7"
    },
    {
        "id": "2046",
        "name": "EPHA8"
    },
    {
        "id": "2047",
        "name": "EPHB1"
    },
    {
        "id": "2048",
        "name": "EPHB2"
    },
    {
        "id": "2050",
        "name": "EPHB4"
    },
    {
        "id": "2051",
        "name": "EPHB6"
    },
    {
        "id": "2053",
        "name": "EPHX2"
    },
    {
        "id": "29924",
        "name": "EPN1"
    },
    {
        "id": "2057",
        "name": "EPOR"
    },
    {
        "id": "2058",
        "name": "EPRS1"
    },
    {
        "id": "8288",
        "name": "EPX"
    },
    {
        "id": "2064",
        "name": "ERBB2"
    },
    {
        "id": "2065",
        "name": "ERBB3"
    },
    {
        "id": "2066",
        "name": "ERBB4"
    },
    {
        "id": "856398",
        "name": "ERG11"
    },
    {
        "id": "2081",
        "name": "ERN\u00a01.00"
    },
    {
        "id": "56605",
        "name": "ERO1B"
    },
    {
        "id": "2098",
        "name": "ESD"
    },
    {
        "id": "2099",
        "name": "ESR1"
    },
    {
        "id": "2100",
        "name": "ESR2"
    },
    {
        "id": "2101",
        "name": "ESRRA"
    },
    {
        "id": "2103",
        "name": "ESRRB"
    },
    {
        "id": "2104",
        "name": "ESRRG"
    },
    {
        "id": "2110",
        "name": "ETFDH"
    },
    {
        "id": "BE0008642",
        "name": "EXP-1"
    },
    {
        "id": "2135",
        "name": "EXTL2"
    },
    {
        "id": "2145",
        "name": "EZH1"
    },
    {
        "id": "2146",
        "name": "EZH2"
    },
    {
        "id": "BE0000686",
        "name": "F"
    },
    {
        "id": "2159",
        "name": "F10"
    },
    {
        "id": "2160",
        "name": "F11"
    },
    {
        "id": "2161",
        "name": "F12"
    },
    {
        "id": "2162",
        "name": "F13A1"
    },
    {
        "id": "2165",
        "name": "F13B"
    },
    {
        "id": "2147",
        "name": "F2"
    },
    {
        "id": "2149",
        "name": "F2R"
    },
    {
        "id": "9002",
        "name": "F2RL3"
    },
    {
        "id": "2152",
        "name": "F3"
    },
    {
        "id": "2153",
        "name": "F5"
    },
    {
        "id": "2155",
        "name": "F7"
    },
    {
        "id": "2157",
        "name": "F8"
    },
    {
        "id": "2158",
        "name": "F9"
    },
    {
        "id": "2166",
        "name": "FAAH"
    },
    {
        "id": "2169",
        "name": "FABP2"
    },
    {
        "id": "2172",
        "name": "FABP6"
    },
    {
        "id": "3992",
        "name": "FADS1"
    },
    {
        "id": "9415",
        "name": "FADS2"
    },
    {
        "id": "2184",
        "name": "FAH"
    },
    {
        "id": "2191",
        "name": "FAP"
    },
    {
        "id": "10667",
        "name": "FARS2"
    },
    {
        "id": "2193",
        "name": "FARSA"
    },
    {
        "id": "10056",
        "name": "FARSB"
    },
    {
        "id": "2194",
        "name": "FASN"
    },
    {
        "id": "2201",
        "name": "FBN2"
    },
    {
        "id": "84467",
        "name": "FBN3"
    },
    {
        "id": "2203",
        "name": "FBP1"
    },
    {
        "id": "BE0009172",
        "name": "FBPA"
    },
    {
        "id": "150726",
        "name": "FBXO41"
    },
    {
        "id": "2205",
        "name": "FCER1A"
    },
    {
        "id": "2207",
        "name": "FCER1G"
    },
    {
        "id": "2208",
        "name": "FCER2"
    },
    {
        "id": "2209",
        "name": "FCGR1A"
    },
    {
        "id": "2212",
        "name": "FCGR2A"
    },
    {
        "id": "2213",
        "name": "FCGR2B"
    },
    {
        "id": "9103",
        "name": "FCGR2C"
    },
    {
        "id": "2214",
        "name": "FCGR3A"
    },
    {
        "id": "2215",
        "name": "FCGR3B"
    },
    {
        "id": "8547",
        "name": "FCN3"
    },
    {
        "id": "2222",
        "name": "FDFT1"
    },
    {
        "id": "2224",
        "name": "FDPS"
    },
    {
        "id": "2230",
        "name": "FDX1"
    },
    {
        "id": "2232",
        "name": "FDXR"
    },
    {
        "id": "2237",
        "name": "FEN1"
    },
    {
        "id": "2241",
        "name": "FER"
    },
    {
        "id": "2242",
        "name": "FES"
    },
    {
        "id": "2864",
        "name": "FFAR1"
    },
    {
        "id": "338557",
        "name": "FFAR4"
    },
    {
        "id": "2243",
        "name": "FGA"
    },
    {
        "id": "2244",
        "name": "FGB"
    },
    {
        "id": "2246",
        "name": "FGF1"
    },
    {
        "id": "9965",
        "name": "FGF19"
    },
    {
        "id": "2247",
        "name": "FGF2"
    },
    {
        "id": "BE0009345",
        "name": "FGF23"
    },
    {
        "id": "2249",
        "name": "FGF4"
    },
    {
        "id": "2260",
        "name": "FGFR1"
    },
    {
        "id": "2263",
        "name": "FGFR2"
    },
    {
        "id": "2261",
        "name": "FGFR3"
    },
    {
        "id": "2264",
        "name": "FGFR4"
    },
    {
        "id": "2266",
        "name": "FGG"
    },
    {
        "id": "2268",
        "name": "FGR"
    },
    {
        "id": "2272",
        "name": "FHIT"
    },
    {
        "id": "2280",
        "name": "FKBP1A"
    },
    {
        "id": "2316",
        "name": "FLNA"
    },
    {
        "id": "2321",
        "name": "FLT1"
    },
    {
        "id": "2322",
        "name": "FLT3"
    },
    {
        "id": "2324",
        "name": "FLT4"
    },
    {
        "id": "2335",
        "name": "FN1"
    },
    {
        "id": "2339",
        "name": "FNTA"
    },
    {
        "id": "2342",
        "name": "FNTB"
    },
    {
        "id": "2346",
        "name": "FOLH1"
    },
    {
        "id": "2348",
        "name": "FOLR1"
    },
    {
        "id": "2350",
        "name": "FOLR2"
    },
    {
        "id": "2352",
        "name": "FOLR3"
    },
    {
        "id": "2353",
        "name": "FOS"
    },
    {
        "id": "2356",
        "name": "FPGS"
    },
    {
        "id": "2357",
        "name": "FPR1"
    },
    {
        "id": "2444",
        "name": "FRK"
    },
    {
        "id": "2492",
        "name": "FSHR"
    },
    {
        "id": "10468",
        "name": "FST"
    },
    {
        "id": "10841",
        "name": "FTCD"
    },
    {
        "id": "2495",
        "name": "FTH1"
    },
    {
        "id": "2512",
        "name": "FTL"
    },
    {
        "id": "79068",
        "name": "FTO"
    },
    {
        "id": "8880",
        "name": "FUBP1"
    },
    {
        "id": "5045",
        "name": "FURIN"
    },
    {
        "id": "2395",
        "name": "FXN"
    },
    {
        "id": "486",
        "name": "FXYD2"
    },
    {
        "id": "2534",
        "name": "FYN"
    },
    {
        "id": "2539",
        "name": "G6PD"
    },
    {
        "id": "2548",
        "name": "GAA"
    },
    {
        "id": "2550",
        "name": "GABBR1"
    },
    {
        "id": "9568",
        "name": "GABBR2"
    },
    {
        "id": "2554",
        "name": "GABRA1"
    },
    {
        "id": "2555",
        "name": "GABRA2"
    },
    {
        "id": "2556",
        "name": "GABRA3"
    },
    {
        "id": "2557",
        "name": "GABRA4"
    },
    {
        "id": "2558",
        "name": "GABRA5"
    },
    {
        "id": "2559",
        "name": "GABRA6"
    },
    {
        "id": "2560",
        "name": "GABRB1"
    },
    {
        "id": "2561",
        "name": "GABRB2"
    },
    {
        "id": "2562",
        "name": "GABRB3"
    },
    {
        "id": "2563",
        "name": "GABRD"
    },
    {
        "id": "2564",
        "name": "GABRE"
    },
    {
        "id": "2565",
        "name": "GABRG1"
    },
    {
        "id": "2566",
        "name": "GABRG2"
    },
    {
        "id": "2567",
        "name": "GABRG3"
    },
    {
        "id": "2568",
        "name": "GABRP"
    },
    {
        "id": "55879",
        "name": "GABRQ"
    },
    {
        "id": "2569",
        "name": "GABRR1"
    },
    {
        "id": "2570",
        "name": "GABRR2"
    },
    {
        "id": "200959",
        "name": "GABRR3"
    },
    {
        "id": "BE0002173",
        "name": "GAD65"
    },
    {
        "id": "339896",
        "name": "GADL1"
    },
    {
        "id": "2580",
        "name": "GAK"
    },
    {
        "id": "2582",
        "name": "GALE"
    },
    {
        "id": "2584",
        "name": "GALK1"
    },
    {
        "id": "2593",
        "name": "GAMT"
    },
    {
        "id": "23193",
        "name": "GANAB"
    },
    {
        "id": "2595",
        "name": "GANC"
    },
    {
        "id": "2597",
        "name": "GAPDH"
    },
    {
        "id": "26330",
        "name": "GAPDHS"
    },
    {
        "id": "2617",
        "name": "GARS1"
    },
    {
        "id": "2618",
        "name": "GART"
    },
    {
        "id": "2520",
        "name": "GAST"
    },
    {
        "id": "5188",
        "name": "GATB"
    },
    {
        "id": "2628",
        "name": "GATM"
    },
    {
        "id": "2629",
        "name": "GBA1"
    },
    {
        "id": "BE0004005",
        "name": "GBAA_4783"
    },
    {
        "id": "2638",
        "name": "GC"
    },
    {
        "id": "25801",
        "name": "GCA"
    },
    {
        "id": "23464",
        "name": "GCAT"
    },
    {
        "id": "2639",
        "name": "GCDH"
    },
    {
        "id": "2642",
        "name": "GCGR"
    },
    {
        "id": "2643",
        "name": "GCH1"
    },
    {
        "id": "2645",
        "name": "GCK"
    },
    {
        "id": "2729",
        "name": "GCLC"
    },
    {
        "id": "2730",
        "name": "GCLM"
    },
    {
        "id": "2653",
        "name": "GCSH"
    },
    {
        "id": "8200",
        "name": "GDF5"
    },
    {
        "id": "2668",
        "name": "GDNF"
    },
    {
        "id": "439",
        "name": "GET3"
    },
    {
        "id": "2671",
        "name": "GFER"
    },
    {
        "id": "7264",
        "name": "GFUS"
    },
    {
        "id": "87769",
        "name": "GGACT"
    },
    {
        "id": "2677",
        "name": "GGCX"
    },
    {
        "id": "9453",
        "name": "GGPS1"
    },
    {
        "id": "2678",
        "name": "GGT1"
    },
    {
        "id": "2690",
        "name": "GHR"
    },
    {
        "id": "2692",
        "name": "GHRHR"
    },
    {
        "id": "2693",
        "name": "GHSR"
    },
    {
        "id": "BE0002152",
        "name": "GIG18"
    },
    {
        "id": "2697",
        "name": "GJA1"
    },
    {
        "id": "BE0000620",
        "name": "GK0582"
    },
    {
        "id": "2717",
        "name": "GLA"
    },
    {
        "id": "2731",
        "name": "GLDC"
    },
    {
        "id": "11010",
        "name": "GLIPR1"
    },
    {
        "id": "152007",
        "name": "GLIPR2"
    },
    {
        "id": "2739",
        "name": "GLO1"
    },
    {
        "id": "2740",
        "name": "GLP1R"
    },
    {
        "id": "9340",
        "name": "GLP2R"
    },
    {
        "id": "2741",
        "name": "GLRA1"
    },
    {
        "id": "2742",
        "name": "GLRA2"
    },
    {
        "id": "8001",
        "name": "GLRA3"
    },
    {
        "id": "2743",
        "name": "GLRB"
    },
    {
        "id": "2745",
        "name": "GLRX"
    },
    {
        "id": "51022",
        "name": "GLRX2"
    },
    {
        "id": "2744",
        "name": "GLS"
    },
    {
        "id": "27165",
        "name": "GLS2"
    },
    {
        "id": "360203",
        "name": "GLT6D1"
    },
    {
        "id": "51228",
        "name": "GLTP"
    },
    {
        "id": "2746",
        "name": "GLUD1"
    },
    {
        "id": "2747",
        "name": "GLUD2"
    },
    {
        "id": "2752",
        "name": "GLUL"
    },
    {
        "id": "10249",
        "name": "GLYAT"
    },
    {
        "id": "92292",
        "name": "GLYATL1"
    },
    {
        "id": "219970",
        "name": "GLYATL2"
    },
    {
        "id": "2760",
        "name": "GM2A"
    },
    {
        "id": "2762",
        "name": "GMDS"
    },
    {
        "id": "2766",
        "name": "GMPR"
    },
    {
        "id": "8833",
        "name": "GMPS"
    },
    {
        "id": "2770",
        "name": "GNAI1"
    },
    {
        "id": "2778",
        "name": "GNAS"
    },
    {
        "id": "2779",
        "name": "GNAT1"
    },
    {
        "id": "54331",
        "name": "GNG2"
    },
    {
        "id": "10578",
        "name": "GNLY"
    },
    {
        "id": "27232",
        "name": "GNMT"
    },
    {
        "id": "10007",
        "name": "GNPDA1"
    },
    {
        "id": "2798",
        "name": "GNRHR"
    },
    {
        "id": "2805",
        "name": "GOT1"
    },
    {
        "id": "2806",
        "name": "GOT2"
    },
    {
        "id": "2811",
        "name": "GP1BA"
    },
    {
        "id": "2815",
        "name": "GP9"
    },
    {
        "id": "151306",
        "name": "GPBAR1"
    },
    {
        "id": "2819",
        "name": "GPD1"
    },
    {
        "id": "2852",
        "name": "GPER1"
    },
    {
        "id": "10243",
        "name": "GPHN"
    },
    {
        "id": "2821",
        "name": "GPI"
    },
    {
        "id": "10457",
        "name": "GPNMB"
    },
    {
        "id": "139760",
        "name": "GPR119"
    },
    {
        "id": "2835",
        "name": "GPR12"
    },
    {
        "id": "2841",
        "name": "GPR18"
    },
    {
        "id": "2859",
        "name": "GPR35"
    },
    {
        "id": "9290",
        "name": "GPR55"
    },
    {
        "id": "9052",
        "name": "GPRC5A"
    },
    {
        "id": "2875",
        "name": "GPT"
    },
    {
        "id": "84706",
        "name": "GPT2"
    },
    {
        "id": "2876",
        "name": "GPX1"
    },
    {
        "id": "BE0002287",
        "name": "GPX1"
    },
    {
        "id": "2877",
        "name": "GPX2"
    },
    {
        "id": "2878",
        "name": "GPX3"
    },
    {
        "id": "2879",
        "name": "GPX4"
    },
    {
        "id": "2880",
        "name": "GPX5"
    },
    {
        "id": "257202",
        "name": "GPX6"
    },
    {
        "id": "2882",
        "name": "GPX7"
    },
    {
        "id": "493869",
        "name": "GPX8"
    },
    {
        "id": "BE0002981",
        "name": "GR"
    },
    {
        "id": "2885",
        "name": "GRB2"
    },
    {
        "id": "2890",
        "name": "GRIA1"
    },
    {
        "id": "2891",
        "name": "GRIA2"
    },
    {
        "id": "2892",
        "name": "GRIA3"
    },
    {
        "id": "2893",
        "name": "GRIA4"
    },
    {
        "id": "2894",
        "name": "GRID1"
    },
    {
        "id": "2895",
        "name": "GRID2"
    },
    {
        "id": "2897",
        "name": "GRIK1"
    },
    {
        "id": "2898",
        "name": "GRIK2"
    },
    {
        "id": "2899",
        "name": "GRIK3"
    },
    {
        "id": "2900",
        "name": "GRIK4"
    },
    {
        "id": "2901",
        "name": "GRIK5"
    },
    {
        "id": "2902",
        "name": "GRIN1"
    },
    {
        "id": "2903",
        "name": "GRIN2A"
    },
    {
        "id": "2904",
        "name": "GRIN2B"
    },
    {
        "id": "2905",
        "name": "GRIN2C"
    },
    {
        "id": "2906",
        "name": "GRIN2D"
    },
    {
        "id": "116443",
        "name": "GRIN3A"
    },
    {
        "id": "116444",
        "name": "GRIN3B"
    },
    {
        "id": "156",
        "name": "GRK2"
    },
    {
        "id": "157",
        "name": "GRK3"
    },
    {
        "id": "2911",
        "name": "GRM1"
    },
    {
        "id": "2912",
        "name": "GRM2"
    },
    {
        "id": "2913",
        "name": "GRM3"
    },
    {
        "id": "2914",
        "name": "GRM4"
    },
    {
        "id": "2915",
        "name": "GRM5"
    },
    {
        "id": "2917",
        "name": "GRM7"
    },
    {
        "id": "2918",
        "name": "GRM8"
    },
    {
        "id": "2931",
        "name": "GSK3A"
    },
    {
        "id": "2932",
        "name": "GSK3B"
    },
    {
        "id": "2934",
        "name": "GSN"
    },
    {
        "id": "2935",
        "name": "GSPT1"
    },
    {
        "id": "2936",
        "name": "GSR"
    },
    {
        "id": "2937",
        "name": "GSS"
    },
    {
        "id": "BE0002659",
        "name": "GST"
    },
    {
        "id": "2938",
        "name": "GSTA1"
    },
    {
        "id": "2939",
        "name": "GSTA2"
    },
    {
        "id": "2940",
        "name": "GSTA3"
    },
    {
        "id": "2941",
        "name": "GSTA4"
    },
    {
        "id": "221357",
        "name": "GSTA5"
    },
    {
        "id": "373156",
        "name": "GSTK1"
    },
    {
        "id": "2944",
        "name": "GSTM1"
    },
    {
        "id": "2946",
        "name": "GSTM2"
    },
    {
        "id": "2947",
        "name": "GSTM3"
    },
    {
        "id": "2948",
        "name": "GSTM4"
    },
    {
        "id": "2949",
        "name": "GSTM5"
    },
    {
        "id": "9446",
        "name": "GSTO1"
    },
    {
        "id": "119391",
        "name": "GSTO2"
    },
    {
        "id": "2950",
        "name": "GSTP1"
    },
    {
        "id": "2952",
        "name": "GSTT1"
    },
    {
        "id": "2953",
        "name": "GSTT2"
    },
    {
        "id": "2954",
        "name": "GSTZ1"
    },
    {
        "id": "2978",
        "name": "GUCA1A"
    },
    {
        "id": "2977",
        "name": "GUCY1A2"
    },
    {
        "id": "2984",
        "name": "GUCY2C"
    },
    {
        "id": "2987",
        "name": "GUK1"
    },
    {
        "id": "2992",
        "name": "GYG1"
    },
    {
        "id": "15",
        "name": "GeneID\n15    AANAT\n15    AANAT\nName: GeneName, dtype: object"
    },
    {
        "id": "211",
        "name": "GeneID\n211    ALAS1\n211    ALAS1\nName: GeneName, dtype: object"
    },
    {
        "id": "2235",
        "name": "GeneID\n2235              FECH\n2235    DKFZp686P18130\nName: GeneName, dtype: object"
    },
    {
        "id": "2571",
        "name": "GeneID\n2571    GAD1\n2571    GAD1\nName: GeneName, dtype: object"
    },
    {
        "id": "2572",
        "name": "GeneID\n2572    GAD2\n2572    GAD2\nName: GeneName, dtype: object"
    },
    {
        "id": "3106",
        "name": "GeneID\n3106    HLA-B\n3106    HLA-B\nName: GeneName, dtype: object"
    },
    {
        "id": "3123",
        "name": "GeneID\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\n3123    HLA-DRB1\nName: GeneName, dtype: object"
    },
    {
        "id": "445",
        "name": "GeneID\n445    ASS1\n445     ASS\nName: GeneName, dtype: object"
    },
    {
        "id": "5138",
        "name": "GeneID\n5138    PDE2A\n5138    PDE2A\nName: GeneName, dtype: object"
    },
    {
        "id": "51540",
        "name": "GeneID\n51540     NaN\n51540    SCLY\nName: GeneName, dtype: object"
    },
    {
        "id": "5621",
        "name": "GeneID\n5621    PRNP\n5621    PRNP\nName: GeneName, dtype: object"
    },
    {
        "id": "7177",
        "name": "GeneID\n7177     TPSB2\n7177    TPSAB1\nName: GeneName, dtype: object"
    },
    {
        "id": "721",
        "name": "GeneID\n721    C4B\n721    C4A\nName: GeneName, dtype: object"
    },
    {
        "id": "808",
        "name": "GeneID\n808    CALM3\n808    CALM2\n808    CALM1\nName: GeneName, dtype: object"
    },
    {
        "id": "89832",
        "name": "GeneID\n89832      CHRNA7\n89832    CHRFAM7A\nName: GeneName, dtype: object"
    },
    {
        "id": "BE0002831",
        "name": "GeneID\nBE0002831            NaN\nBE0002831    blaCTX-M-27\nName: GeneName, dtype: object"
    },
    {
        "id": "BE0009630",
        "name": "GeneID\nBE0009630      NaN\nBE0009630      NaN\nBE0009630      NaN\nBE0009630      NaN\nBE0009630      NaN\nBE0009630      ND3\nBE0009630      ENO\nBE0009630    Cat-1\nName: GeneName, dtype: object"
    },
    {
        "id": "BE0009716",
        "name": "GeneID\nBE0009716     NaN\nBE0009716     NaN\nBE0009716    cydB\nBE0009716    cydA\nName: GeneName, dtype: object"
    },
    {
        "id": "BE0009555",
        "name": "GluClX"
    },
    {
        "id": "3008",
        "name": "H1-4"
    },
    {
        "id": "8347",
        "name": "H2BC10"
    },
    {
        "id": "9563",
        "name": "H6PD"
    },
    {
        "id": "BE0002868",
        "name": "HA"
    },
    {
        "id": "BE0004063",
        "name": "HA"
    },
    {
        "id": "3026",
        "name": "HABP2"
    },
    {
        "id": "22927",
        "name": "HABP4"
    },
    {
        "id": "3033",
        "name": "HADH"
    },
    {
        "id": "3030",
        "name": "HADHA"
    },
    {
        "id": "3029",
        "name": "HAGH"
    },
    {
        "id": "3034",
        "name": "HAL"
    },
    {
        "id": "54363",
        "name": "HAO1"
    },
    {
        "id": "51179",
        "name": "HAO2"
    },
    {
        "id": "1404",
        "name": "HAPLN1"
    },
    {
        "id": "145864",
        "name": "HAPLN3"
    },
    {
        "id": "404037",
        "name": "HAPLN4"
    },
    {
        "id": "3035",
        "name": "HARS1"
    },
    {
        "id": "83903",
        "name": "HASPIN"
    },
    {
        "id": "3040",
        "name": "HBA1"
    },
    {
        "id": "3043",
        "name": "HBB"
    },
    {
        "id": "338442",
        "name": "HCAR2"
    },
    {
        "id": "8843",
        "name": "HCAR3"
    },
    {
        "id": "3055",
        "name": "HCK"
    },
    {
        "id": "610",
        "name": "HCN2"
    },
    {
        "id": "3060",
        "name": "HCRT"
    },
    {
        "id": "3061",
        "name": "HCRTR1"
    },
    {
        "id": "3062",
        "name": "HCRTR2"
    },
    {
        "id": "3065",
        "name": "HDAC1"
    },
    {
        "id": "83933",
        "name": "HDAC10"
    },
    {
        "id": "79885",
        "name": "HDAC11"
    },
    {
        "id": "3066",
        "name": "HDAC2"
    },
    {
        "id": "8841",
        "name": "HDAC3"
    },
    {
        "id": "9759",
        "name": "HDAC4"
    },
    {
        "id": "10014",
        "name": "HDAC5"
    },
    {
        "id": "10013",
        "name": "HDAC6"
    },
    {
        "id": "51564",
        "name": "HDAC7"
    },
    {
        "id": "55869",
        "name": "HDAC8"
    },
    {
        "id": "9734",
        "name": "HDAC9"
    },
    {
        "id": "3067",
        "name": "HDC"
    },
    {
        "id": "3068",
        "name": "HDGF"
    },
    {
        "id": "341208",
        "name": "HEPHL1"
    },
    {
        "id": "3074",
        "name": "HEXB"
    },
    {
        "id": "3082",
        "name": "HGF"
    },
    {
        "id": "BE0002488",
        "name": "HGPRTase"
    },
    {
        "id": "9146",
        "name": "HGS"
    },
    {
        "id": "BE0009678",
        "name": "HHVG_00031"
    },
    {
        "id": "11112",
        "name": "HIBADH"
    },
    {
        "id": "26275",
        "name": "HIBCH"
    },
    {
        "id": "3091",
        "name": "HIF1A"
    },
    {
        "id": "55662",
        "name": "HIF1AN"
    },
    {
        "id": "3094",
        "name": "HINT1"
    },
    {
        "id": "28996",
        "name": "HIPK2"
    },
    {
        "id": "10114",
        "name": "HIPK3"
    },
    {
        "id": "BE0000664",
        "name": "HIV-1 protease"
    },
    {
        "id": "BE0009233",
        "name": "HK"
    },
    {
        "id": "3098",
        "name": "HK1"
    },
    {
        "id": "3105",
        "name": "HLA-A"
    },
    {
        "id": "3118",
        "name": "HLA-DQA2"
    },
    {
        "id": "3119",
        "name": "HLA-DQB1"
    },
    {
        "id": "3122",
        "name": "HLA-DRA"
    },
    {
        "id": "3125",
        "name": "HLA-DRB3"
    },
    {
        "id": "3126",
        "name": "HLA-DRB4"
    },
    {
        "id": "3127",
        "name": "HLA-DRB5"
    },
    {
        "id": "3141",
        "name": "HLCS"
    },
    {
        "id": "81502",
        "name": "HM13"
    },
    {
        "id": "3146",
        "name": "HMGB1"
    },
    {
        "id": "3155",
        "name": "HMGCL"
    },
    {
        "id": "3156",
        "name": "HMGCR"
    },
    {
        "id": "3157",
        "name": "HMGCS1"
    },
    {
        "id": "3161",
        "name": "HMMR"
    },
    {
        "id": "3162",
        "name": "HMOX1"
    },
    {
        "id": "3163",
        "name": "HMOX2"
    },
    {
        "id": "BE0001978",
        "name": "HN"
    },
    {
        "id": "BE0001724",
        "name": "HN"
    },
    {
        "id": "6927",
        "name": "HNF1A"
    },
    {
        "id": "3172",
        "name": "HNF4A"
    },
    {
        "id": "3174",
        "name": "HNF4G"
    },
    {
        "id": "3176",
        "name": "HNMT"
    },
    {
        "id": "3181",
        "name": "HNRNPA2B1"
    },
    {
        "id": "3184",
        "name": "HNRNPD"
    },
    {
        "id": "3185",
        "name": "HNRNPF"
    },
    {
        "id": "3187",
        "name": "HNRNPH1"
    },
    {
        "id": "3189",
        "name": "HNRNPH3"
    },
    {
        "id": "3190",
        "name": "HNRNPK"
    },
    {
        "id": "3191",
        "name": "HNRNPL"
    },
    {
        "id": "3206",
        "name": "HOXA10"
    },
    {
        "id": "3240",
        "name": "HP"
    },
    {
        "id": "50809",
        "name": "HP1BP3"
    },
    {
        "id": "3242",
        "name": "HPD"
    },
    {
        "id": "3248",
        "name": "HPGD"
    },
    {
        "id": "27306",
        "name": "HPGDS"
    },
    {
        "id": "3249",
        "name": "HPN"
    },
    {
        "id": "3250",
        "name": "HPR"
    },
    {
        "id": "3251",
        "name": "HPRT1"
    },
    {
        "id": "3263",
        "name": "HPX"
    },
    {
        "id": "3265",
        "name": "HRAS"
    },
    {
        "id": "3273",
        "name": "HRG"
    },
    {
        "id": "3269",
        "name": "HRH1"
    },
    {
        "id": "3274",
        "name": "HRH2"
    },
    {
        "id": "11255",
        "name": "HRH3"
    },
    {
        "id": "59340",
        "name": "HRH4"
    },
    {
        "id": "388697",
        "name": "HRNR"
    },
    {
        "id": "9957",
        "name": "HS3ST1"
    },
    {
        "id": "9955",
        "name": "HS3ST3A1"
    },
    {
        "id": "3290",
        "name": "HSD11B1"
    },
    {
        "id": "3291",
        "name": "HSD11B2"
    },
    {
        "id": "3292",
        "name": "HSD17B1"
    },
    {
        "id": "3028",
        "name": "HSD17B10"
    },
    {
        "id": "51170",
        "name": "HSD17B11"
    },
    {
        "id": "3294",
        "name": "HSD17B2"
    },
    {
        "id": "3293",
        "name": "HSD17B3"
    },
    {
        "id": "3295",
        "name": "HSD17B4"
    },
    {
        "id": "8630",
        "name": "HSD17B6"
    },
    {
        "id": "51478",
        "name": "HSD17B7"
    },
    {
        "id": "7923",
        "name": "HSD17B8"
    },
    {
        "id": "3283",
        "name": "HSD3B1"
    },
    {
        "id": "3284",
        "name": "HSD3B2"
    },
    {
        "id": "3297",
        "name": "HSF1"
    },
    {
        "id": "3320",
        "name": "HSP90AA1"
    },
    {
        "id": "3326",
        "name": "HSP90AB1"
    },
    {
        "id": "7184",
        "name": "HSP90B1"
    },
    {
        "id": "6782",
        "name": "HSPA13"
    },
    {
        "id": "3306",
        "name": "HSPA2"
    },
    {
        "id": "3308",
        "name": "HSPA4"
    },
    {
        "id": "3309",
        "name": "HSPA5"
    },
    {
        "id": "3312",
        "name": "HSPA8"
    },
    {
        "id": "3315",
        "name": "HSPB1"
    },
    {
        "id": "3329",
        "name": "HSPD1"
    },
    {
        "id": "3336",
        "name": "HSPE1"
    },
    {
        "id": "3339",
        "name": "HSPG2"
    },
    {
        "id": "10808",
        "name": "HSPH1"
    },
    {
        "id": "10553",
        "name": "HTATIP2"
    },
    {
        "id": "3350",
        "name": "HTR1A"
    },
    {
        "id": "3351",
        "name": "HTR1B"
    },
    {
        "id": "3352",
        "name": "HTR1D"
    },
    {
        "id": "3354",
        "name": "HTR1E"
    },
    {
        "id": "3355",
        "name": "HTR1F"
    },
    {
        "id": "3356",
        "name": "HTR2A"
    },
    {
        "id": "3357",
        "name": "HTR2B"
    },
    {
        "id": "3358",
        "name": "HTR2C"
    },
    {
        "id": "3359",
        "name": "HTR3A"
    },
    {
        "id": "9177",
        "name": "HTR3B"
    },
    {
        "id": "170572",
        "name": "HTR3C"
    },
    {
        "id": "200909",
        "name": "HTR3D"
    },
    {
        "id": "285242",
        "name": "HTR3E"
    },
    {
        "id": "3360",
        "name": "HTR4"
    },
    {
        "id": "3361",
        "name": "HTR5A"
    },
    {
        "id": "3362",
        "name": "HTR6"
    },
    {
        "id": "3363",
        "name": "HTR7"
    },
    {
        "id": "3064",
        "name": "HTT"
    },
    {
        "id": "15465",
        "name": "Hrh1"
    },
    {
        "id": "25187",
        "name": "Htr2c"
    },
    {
        "id": "3375",
        "name": "IAPP"
    },
    {
        "id": "3376",
        "name": "IARS1"
    },
    {
        "id": "55699",
        "name": "IARS2"
    },
    {
        "id": "3383",
        "name": "ICAM1"
    },
    {
        "id": "3416",
        "name": "IDE"
    },
    {
        "id": "3417",
        "name": "IDH1"
    },
    {
        "id": "3418",
        "name": "IDH2"
    },
    {
        "id": "3419",
        "name": "IDH3A"
    },
    {
        "id": "3420",
        "name": "IDH3B"
    },
    {
        "id": "3421",
        "name": "IDH3G"
    },
    {
        "id": "3422",
        "name": "IDI1"
    },
    {
        "id": "3620",
        "name": "IDO1"
    },
    {
        "id": "3440",
        "name": "IFNA2"
    },
    {
        "id": "3454",
        "name": "IFNAR1"
    },
    {
        "id": "3455",
        "name": "IFNAR2"
    },
    {
        "id": "3456",
        "name": "IFNB1"
    },
    {
        "id": "3458",
        "name": "IFNG"
    },
    {
        "id": "3459",
        "name": "IFNGR1"
    },
    {
        "id": "3460",
        "name": "IFNGR2"
    },
    {
        "id": "3479",
        "name": "IGF1"
    },
    {
        "id": "3480",
        "name": "IGF1R"
    },
    {
        "id": "3482",
        "name": "IGF2R"
    },
    {
        "id": "3483",
        "name": "IGFALS"
    },
    {
        "id": "3486",
        "name": "IGFBP3"
    },
    {
        "id": "3490",
        "name": "IGFBP7"
    },
    {
        "id": "BE0004164",
        "name": "IGKV1D-33"
    },
    {
        "id": "BE0003837",
        "name": "IGKV2-30"
    },
    {
        "id": "BE0000616",
        "name": "IGKV3-20"
    },
    {
        "id": "BE0002724",
        "name": "IGKV4-1"
    },
    {
        "id": "3543",
        "name": "IGLL1"
    },
    {
        "id": "BE0002939",
        "name": "IGLV2-8"
    },
    {
        "id": "BE0004265",
        "name": "IGLV7-43"
    },
    {
        "id": "285313",
        "name": "IGSF10"
    },
    {
        "id": "3551",
        "name": "IKBKB"
    },
    {
        "id": "9641",
        "name": "IKBKE"
    },
    {
        "id": "8517",
        "name": "IKBKG"
    },
    {
        "id": "3586",
        "name": "IL10"
    },
    {
        "id": "3590",
        "name": "IL11RA"
    },
    {
        "id": "3593",
        "name": "IL12B"
    },
    {
        "id": "3596",
        "name": "IL13"
    },
    {
        "id": "BE0003464",
        "name": "IL13"
    },
    {
        "id": "3598",
        "name": "IL13RA2"
    },
    {
        "id": "3600",
        "name": "IL15"
    },
    {
        "id": "3605",
        "name": "IL17A"
    },
    {
        "id": "3552",
        "name": "IL1A"
    },
    {
        "id": "3553",
        "name": "IL1B"
    },
    {
        "id": "3554",
        "name": "IL1R1"
    },
    {
        "id": "3557",
        "name": "IL1RN"
    },
    {
        "id": "3558",
        "name": "IL2"
    },
    {
        "id": "51561",
        "name": "IL23A"
    },
    {
        "id": "3559",
        "name": "IL2RA"
    },
    {
        "id": "3560",
        "name": "IL2RB"
    },
    {
        "id": "3561",
        "name": "IL2RG"
    },
    {
        "id": "3562",
        "name": "IL3"
    },
    {
        "id": "3563",
        "name": "IL3RA"
    },
    {
        "id": "3565",
        "name": "IL4"
    },
    {
        "id": "259307",
        "name": "IL4I1"
    },
    {
        "id": "3566",
        "name": "IL4R"
    },
    {
        "id": "3567",
        "name": "IL5"
    },
    {
        "id": "3568",
        "name": "IL5RA"
    },
    {
        "id": "3569",
        "name": "IL6"
    },
    {
        "id": "3570",
        "name": "IL6R"
    },
    {
        "id": "3612",
        "name": "IMPA1"
    },
    {
        "id": "3613",
        "name": "IMPA2"
    },
    {
        "id": "3614",
        "name": "IMPDH1"
    },
    {
        "id": "3615",
        "name": "IMPDH2"
    },
    {
        "id": "50939",
        "name": "IMPG2"
    },
    {
        "id": "3619",
        "name": "INCENP"
    },
    {
        "id": "3633",
        "name": "INPP5B"
    },
    {
        "id": "3630",
        "name": "INS"
    },
    {
        "id": "3643",
        "name": "INSR"
    },
    {
        "id": "3645",
        "name": "INSRR"
    },
    {
        "id": "8826",
        "name": "IQGAP1"
    },
    {
        "id": "3654",
        "name": "IRAK1"
    },
    {
        "id": "11213",
        "name": "IRAK3"
    },
    {
        "id": "51135",
        "name": "IRAK4"
    },
    {
        "id": "56269",
        "name": "IRGC"
    },
    {
        "id": "3667",
        "name": "IRS1"
    },
    {
        "id": "3669",
        "name": "ISG20"
    },
    {
        "id": "51477",
        "name": "ISYNA1"
    },
    {
        "id": "3674",
        "name": "ITGA2B"
    },
    {
        "id": "3676",
        "name": "ITGA4"
    },
    {
        "id": "3678",
        "name": "ITGA5"
    },
    {
        "id": "3683",
        "name": "ITGAL"
    },
    {
        "id": "3685",
        "name": "ITGAV"
    },
    {
        "id": "3688",
        "name": "ITGB1"
    },
    {
        "id": "3690",
        "name": "ITGB3"
    },
    {
        "id": "3691",
        "name": "ITGB4"
    },
    {
        "id": "3695",
        "name": "ITGB7"
    },
    {
        "id": "3697",
        "name": "ITIH1"
    },
    {
        "id": "3698",
        "name": "ITIH2"
    },
    {
        "id": "3699",
        "name": "ITIH3"
    },
    {
        "id": "3700",
        "name": "ITIH4"
    },
    {
        "id": "3702",
        "name": "ITK"
    },
    {
        "id": "3704",
        "name": "ITPA"
    },
    {
        "id": "3706",
        "name": "ITPKA"
    },
    {
        "id": "3708",
        "name": "ITPR1"
    },
    {
        "id": "3709",
        "name": "ITPR2"
    },
    {
        "id": "3710",
        "name": "ITPR3"
    },
    {
        "id": "3712",
        "name": "IVD"
    },
    {
        "id": "389434",
        "name": "IYD"
    },
    {
        "id": "3716",
        "name": "JAK1"
    },
    {
        "id": "3717",
        "name": "JAK2"
    },
    {
        "id": "3718",
        "name": "JAK3"
    },
    {
        "id": "3512",
        "name": "JCHAIN"
    },
    {
        "id": "122953",
        "name": "JDP2"
    },
    {
        "id": "3725",
        "name": "JUN"
    },
    {
        "id": "3728",
        "name": "JUP"
    },
    {
        "id": "55683",
        "name": "KANSL3"
    },
    {
        "id": "3735",
        "name": "KARS1"
    },
    {
        "id": "2648",
        "name": "KAT2A"
    },
    {
        "id": "8850",
        "name": "KAT2B"
    },
    {
        "id": "10524",
        "name": "KAT5"
    },
    {
        "id": "3736",
        "name": "KCNA1"
    },
    {
        "id": "3744",
        "name": "KCNA10"
    },
    {
        "id": "3737",
        "name": "KCNA2"
    },
    {
        "id": "3738",
        "name": "KCNA3"
    },
    {
        "id": "3739",
        "name": "KCNA4"
    },
    {
        "id": "3741",
        "name": "KCNA5"
    },
    {
        "id": "3742",
        "name": "KCNA6"
    },
    {
        "id": "3743",
        "name": "KCNA7"
    },
    {
        "id": "7881",
        "name": "KCNAB1"
    },
    {
        "id": "8514",
        "name": "KCNAB2"
    },
    {
        "id": "3745",
        "name": "KCNB1"
    },
    {
        "id": "9312",
        "name": "KCNB2"
    },
    {
        "id": "3746",
        "name": "KCNC1"
    },
    {
        "id": "3747",
        "name": "KCNC2"
    },
    {
        "id": "3748",
        "name": "KCNC3"
    },
    {
        "id": "3749",
        "name": "KCNC4"
    },
    {
        "id": "3750",
        "name": "KCND1"
    },
    {
        "id": "3751",
        "name": "KCND2"
    },
    {
        "id": "3752",
        "name": "KCND3"
    },
    {
        "id": "3753",
        "name": "KCNE1"
    },
    {
        "id": "9992",
        "name": "KCNE2"
    },
    {
        "id": "10008",
        "name": "KCNE3"
    },
    {
        "id": "23704",
        "name": "KCNE4"
    },
    {
        "id": "23630",
        "name": "KCNE5"
    },
    {
        "id": "3754",
        "name": "KCNF1"
    },
    {
        "id": "3755",
        "name": "KCNG1"
    },
    {
        "id": "26251",
        "name": "KCNG2"
    },
    {
        "id": "170850",
        "name": "KCNG3"
    },
    {
        "id": "93107",
        "name": "KCNG4"
    },
    {
        "id": "3756",
        "name": "KCNH1"
    },
    {
        "id": "3757",
        "name": "KCNH2"
    },
    {
        "id": "23416",
        "name": "KCNH3"
    },
    {
        "id": "23415",
        "name": "KCNH4"
    },
    {
        "id": "27133",
        "name": "KCNH5"
    },
    {
        "id": "81033",
        "name": "KCNH6"
    },
    {
        "id": "90134",
        "name": "KCNH7"
    },
    {
        "id": "131096",
        "name": "KCNH8"
    },
    {
        "id": "3758",
        "name": "KCNJ1"
    },
    {
        "id": "3766",
        "name": "KCNJ10"
    },
    {
        "id": "3767",
        "name": "KCNJ11"
    },
    {
        "id": "3768",
        "name": "KCNJ12"
    },
    {
        "id": "3770",
        "name": "KCNJ14"
    },
    {
        "id": "3772",
        "name": "KCNJ15"
    },
    {
        "id": "3759",
        "name": "KCNJ2"
    },
    {
        "id": "3760",
        "name": "KCNJ3"
    },
    {
        "id": "3761",
        "name": "KCNJ4"
    },
    {
        "id": "3762",
        "name": "KCNJ5"
    },
    {
        "id": "3763",
        "name": "KCNJ6"
    },
    {
        "id": "3764",
        "name": "KCNJ8"
    },
    {
        "id": "3765",
        "name": "KCNJ9"
    },
    {
        "id": "3775",
        "name": "KCNK1"
    },
    {
        "id": "3776",
        "name": "KCNK2"
    },
    {
        "id": "3777",
        "name": "KCNK3"
    },
    {
        "id": "9424",
        "name": "KCNK6"
    },
    {
        "id": "51305",
        "name": "KCNK9"
    },
    {
        "id": "3778",
        "name": "KCNMA1"
    },
    {
        "id": "3779",
        "name": "KCNMB1"
    },
    {
        "id": "10242",
        "name": "KCNMB2"
    },
    {
        "id": "27094",
        "name": "KCNMB3"
    },
    {
        "id": "27345",
        "name": "KCNMB4"
    },
    {
        "id": "3780",
        "name": "KCNN1"
    },
    {
        "id": "3781",
        "name": "KCNN2"
    },
    {
        "id": "3782",
        "name": "KCNN3"
    },
    {
        "id": "3783",
        "name": "KCNN4"
    },
    {
        "id": "3784",
        "name": "KCNQ1"
    },
    {
        "id": "3785",
        "name": "KCNQ2"
    },
    {
        "id": "3786",
        "name": "KCNQ3"
    },
    {
        "id": "9132",
        "name": "KCNQ4"
    },
    {
        "id": "56479",
        "name": "KCNQ5"
    },
    {
        "id": "3787",
        "name": "KCNS1"
    },
    {
        "id": "3790",
        "name": "KCNS3"
    },
    {
        "id": "27012",
        "name": "KCNV1"
    },
    {
        "id": "169522",
        "name": "KCNV2"
    },
    {
        "id": "8284",
        "name": "KDM5D"
    },
    {
        "id": "3791",
        "name": "KDR"
    },
    {
        "id": "9817",
        "name": "KEAP1"
    },
    {
        "id": "3792",
        "name": "KEL"
    },
    {
        "id": "8570",
        "name": "KHSRP"
    },
    {
        "id": "3832",
        "name": "KIF11"
    },
    {
        "id": "547",
        "name": "KIF1A"
    },
    {
        "id": "11004",
        "name": "KIF2C"
    },
    {
        "id": "84634",
        "name": "KISS1R"
    },
    {
        "id": "3815",
        "name": "KIT"
    },
    {
        "id": "3816",
        "name": "KLK1"
    },
    {
        "id": "354",
        "name": "KLK3"
    },
    {
        "id": "5653",
        "name": "KLK6"
    },
    {
        "id": "5650",
        "name": "KLK7"
    },
    {
        "id": "3818",
        "name": "KLKB1"
    },
    {
        "id": "3827",
        "name": "KNG1"
    },
    {
        "id": "3845",
        "name": "KRAS"
    },
    {
        "id": "3848",
        "name": "KRT1"
    },
    {
        "id": "3858",
        "name": "KRT10"
    },
    {
        "id": "3859",
        "name": "KRT12"
    },
    {
        "id": "3861",
        "name": "KRT14"
    },
    {
        "id": "3868",
        "name": "KRT16"
    },
    {
        "id": "3849",
        "name": "KRT2"
    },
    {
        "id": "3852",
        "name": "KRT5"
    },
    {
        "id": "3853",
        "name": "KRT6A"
    },
    {
        "id": "3855",
        "name": "KRT7"
    },
    {
        "id": "3856",
        "name": "KRT8"
    },
    {
        "id": "3857",
        "name": "KRT9"
    },
    {
        "id": "440021",
        "name": "KRTAP5-2"
    },
    {
        "id": "387266",
        "name": "KRTAP5-3"
    },
    {
        "id": "883",
        "name": "KYAT1"
    },
    {
        "id": "56267",
        "name": "KYAT3"
    },
    {
        "id": "8942",
        "name": "KYNU"
    },
    {
        "id": "16531",
        "name": "Kcnma1"
    },
    {
        "id": "BE0000681",
        "name": "L"
    },
    {
        "id": "BE0004792",
        "name": "L1"
    },
    {
        "id": "3897",
        "name": "L1CAM"
    },
    {
        "id": "112849",
        "name": "L3HYPDH"
    },
    {
        "id": "26013",
        "name": "L3MBTL1"
    },
    {
        "id": "BE0003168",
        "name": "L5"
    },
    {
        "id": "BE0003170",
        "name": "L5"
    },
    {
        "id": "BE0001516",
        "name": "LACZ"
    },
    {
        "id": "3906",
        "name": "LALBA"
    },
    {
        "id": "284217",
        "name": "LAMA1"
    },
    {
        "id": "3909",
        "name": "LAMA3"
    },
    {
        "id": "3911",
        "name": "LAMA5"
    },
    {
        "id": "3912",
        "name": "LAMB1"
    },
    {
        "id": "3915",
        "name": "LAMC1"
    },
    {
        "id": "51056",
        "name": "LAP3"
    },
    {
        "id": "51520",
        "name": "LARS1"
    },
    {
        "id": "23395",
        "name": "LARS2"
    },
    {
        "id": "9113",
        "name": "LATS1"
    },
    {
        "id": "143903",
        "name": "LAYN"
    },
    {
        "id": "3932",
        "name": "LCK"
    },
    {
        "id": "51451",
        "name": "LCMT1"
    },
    {
        "id": "9836",
        "name": "LCMT2"
    },
    {
        "id": "3933",
        "name": "LCN1"
    },
    {
        "id": "3934",
        "name": "LCN2"
    },
    {
        "id": "392399",
        "name": "LCN9"
    },
    {
        "id": "3938",
        "name": "LCT"
    },
    {
        "id": "197021",
        "name": "LCTL"
    },
    {
        "id": "3939",
        "name": "LDHA"
    },
    {
        "id": "160287",
        "name": "LDHAL6A"
    },
    {
        "id": "92483",
        "name": "LDHAL6B"
    },
    {
        "id": "3945",
        "name": "LDHB"
    },
    {
        "id": "3948",
        "name": "LDHC"
    },
    {
        "id": "3949",
        "name": "LDLR"
    },
    {
        "id": "51176",
        "name": "LEF1"
    },
    {
        "id": "3953",
        "name": "LEPR"
    },
    {
        "id": "3956",
        "name": "LGALS1"
    },
    {
        "id": "3957",
        "name": "LGALS2"
    },
    {
        "id": "3958",
        "name": "LGALS3"
    },
    {
        "id": "653499",
        "name": "LGALS7"
    },
    {
        "id": "3965",
        "name": "LGALS9"
    },
    {
        "id": "51557",
        "name": "LGSN"
    },
    {
        "id": "3973",
        "name": "LHCGR"
    },
    {
        "id": "11019",
        "name": "LIAS"
    },
    {
        "id": "3978",
        "name": "LIG1"
    },
    {
        "id": "3980",
        "name": "LIG3"
    },
    {
        "id": "3984",
        "name": "LIMK1"
    },
    {
        "id": "3985",
        "name": "LIMK2"
    },
    {
        "id": "BE0001696",
        "name": "LIP1"
    },
    {
        "id": "BE0001503",
        "name": "LIP3"
    },
    {
        "id": "8513",
        "name": "LIPF"
    },
    {
        "id": "51601",
        "name": "LIPT1"
    },
    {
        "id": "3998",
        "name": "LMAN1"
    },
    {
        "id": "2846",
        "name": "LPAR4"
    },
    {
        "id": "10161",
        "name": "LPAR6"
    },
    {
        "id": "4023",
        "name": "LPL"
    },
    {
        "id": "9227",
        "name": "LRAT"
    },
    {
        "id": "116844",
        "name": "LRG1"
    },
    {
        "id": "4035",
        "name": "LRP1"
    },
    {
        "id": "4036",
        "name": "LRP2"
    },
    {
        "id": "120892",
        "name": "LRRK2"
    },
    {
        "id": "11157",
        "name": "LSM\u00a06.00"
    },
    {
        "id": "4047",
        "name": "LSS"
    },
    {
        "id": "4049",
        "name": "LTA"
    },
    {
        "id": "4048",
        "name": "LTA4H"
    },
    {
        "id": "1241",
        "name": "LTB4R"
    },
    {
        "id": "56413",
        "name": "LTB4R2"
    },
    {
        "id": "4056",
        "name": "LTC4S"
    },
    {
        "id": "4057",
        "name": "LTF"
    },
    {
        "id": "4058",
        "name": "LTK"
    },
    {
        "id": "4060",
        "name": "LUM"
    },
    {
        "id": "23643",
        "name": "LY96"
    },
    {
        "id": "4067",
        "name": "LYN"
    },
    {
        "id": "4069",
        "name": "LYZ"
    },
    {
        "id": "BE0000630",
        "name": "M"
    },
    {
        "id": "4074",
        "name": "M6PR"
    },
    {
        "id": "10296",
        "name": "MAEA"
    },
    {
        "id": "11253",
        "name": "MAN1B1"
    },
    {
        "id": "4124",
        "name": "MAN2A1"
    },
    {
        "id": "4128",
        "name": "MAOA"
    },
    {
        "id": "4129",
        "name": "MAOB"
    },
    {
        "id": "4130",
        "name": "MAP1A"
    },
    {
        "id": "4133",
        "name": "MAP2"
    },
    {
        "id": "5604",
        "name": "MAP2K1"
    },
    {
        "id": "5605",
        "name": "MAP2K2"
    },
    {
        "id": "5606",
        "name": "MAP2K3"
    },
    {
        "id": "5607",
        "name": "MAP2K5"
    },
    {
        "id": "5608",
        "name": "MAP2K6"
    },
    {
        "id": "4214",
        "name": "MAP3K1"
    },
    {
        "id": "4294",
        "name": "MAP3K10"
    },
    {
        "id": "4296",
        "name": "MAP3K11"
    },
    {
        "id": "7786",
        "name": "MAP3K12"
    },
    {
        "id": "9175",
        "name": "MAP3K13"
    },
    {
        "id": "389840",
        "name": "MAP3K15"
    },
    {
        "id": "80122",
        "name": "MAP3K19"
    },
    {
        "id": "10746",
        "name": "MAP3K2"
    },
    {
        "id": "51776",
        "name": "MAP3K20"
    },
    {
        "id": "4215",
        "name": "MAP3K3"
    },
    {
        "id": "4216",
        "name": "MAP3K4"
    },
    {
        "id": "9064",
        "name": "MAP3K6"
    },
    {
        "id": "4293",
        "name": "MAP3K9"
    },
    {
        "id": "4134",
        "name": "MAP4"
    },
    {
        "id": "11184",
        "name": "MAP4K1"
    },
    {
        "id": "5871",
        "name": "MAP4K2"
    },
    {
        "id": "8491",
        "name": "MAP4K3"
    },
    {
        "id": "9448",
        "name": "MAP4K4"
    },
    {
        "id": "11183",
        "name": "MAP4K5"
    },
    {
        "id": "5594",
        "name": "MAPK1"
    },
    {
        "id": "5602",
        "name": "MAPK10"
    },
    {
        "id": "5600",
        "name": "MAPK11"
    },
    {
        "id": "6300",
        "name": "MAPK12"
    },
    {
        "id": "5603",
        "name": "MAPK13"
    },
    {
        "id": "1432",
        "name": "MAPK14"
    },
    {
        "id": "225689",
        "name": "MAPK15"
    },
    {
        "id": "5595",
        "name": "MAPK3"
    },
    {
        "id": "5596",
        "name": "MAPK4"
    },
    {
        "id": "5597",
        "name": "MAPK6"
    },
    {
        "id": "5598",
        "name": "MAPK7"
    },
    {
        "id": "5599",
        "name": "MAPK8"
    },
    {
        "id": "9479",
        "name": "MAPK8IP1"
    },
    {
        "id": "5601",
        "name": "MAPK9"
    },
    {
        "id": "9261",
        "name": "MAPKAPK2"
    },
    {
        "id": "7867",
        "name": "MAPKAPK3"
    },
    {
        "id": "8550",
        "name": "MAPKAPK5"
    },
    {
        "id": "4137",
        "name": "MAPT"
    },
    {
        "id": "8685",
        "name": "MARCO"
    },
    {
        "id": "4139",
        "name": "MARK1"
    },
    {
        "id": "2011",
        "name": "MARK2"
    },
    {
        "id": "4140",
        "name": "MARK3"
    },
    {
        "id": "57787",
        "name": "MARK4"
    },
    {
        "id": "10747",
        "name": "MASP2"
    },
    {
        "id": "22983",
        "name": "MAST1"
    },
    {
        "id": "4143",
        "name": "MAT1A"
    },
    {
        "id": "4144",
        "name": "MAT2A"
    },
    {
        "id": "4145",
        "name": "MATK"
    },
    {
        "id": "4151",
        "name": "MB"
    },
    {
        "id": "4153",
        "name": "MBL2"
    },
    {
        "id": "4157",
        "name": "MC1R"
    },
    {
        "id": "4158",
        "name": "MC2R"
    },
    {
        "id": "4159",
        "name": "MC3R"
    },
    {
        "id": "4160",
        "name": "MC4R"
    },
    {
        "id": "4161",
        "name": "MC5R"
    },
    {
        "id": "27349",
        "name": "MCAT"
    },
    {
        "id": "56922",
        "name": "MCCC1"
    },
    {
        "id": "64087",
        "name": "MCCC2"
    },
    {
        "id": "90411",
        "name": "MCFD2"
    },
    {
        "id": "2847",
        "name": "MCHR1"
    },
    {
        "id": "4170",
        "name": "MCL1"
    },
    {
        "id": "4190",
        "name": "MDH1"
    },
    {
        "id": "4191",
        "name": "MDH2"
    },
    {
        "id": "4193",
        "name": "MDM2"
    },
    {
        "id": "BE0009167",
        "name": "MDR1"
    },
    {
        "id": "4199",
        "name": "ME1"
    },
    {
        "id": "4200",
        "name": "ME2"
    },
    {
        "id": "10873",
        "name": "ME3"
    },
    {
        "id": "5469",
        "name": "MED1"
    },
    {
        "id": "BE0009166",
        "name": "MEF-1"
    },
    {
        "id": "9833",
        "name": "MELK"
    },
    {
        "id": "10461",
        "name": "MERTK"
    },
    {
        "id": "4233",
        "name": "MET"
    },
    {
        "id": "23173",
        "name": "METAP1"
    },
    {
        "id": "10988",
        "name": "METAP2"
    },
    {
        "id": "8972",
        "name": "MGAM"
    },
    {
        "id": "4245",
        "name": "MGAT1"
    },
    {
        "id": "4255",
        "name": "MGMT"
    },
    {
        "id": "4256",
        "name": "MGP"
    },
    {
        "id": "4257",
        "name": "MGST1"
    },
    {
        "id": "4258",
        "name": "MGST2"
    },
    {
        "id": "4259",
        "name": "MGST3"
    },
    {
        "id": "4282",
        "name": "MIF"
    },
    {
        "id": "50488",
        "name": "MINK1"
    },
    {
        "id": "4284",
        "name": "MIP"
    },
    {
        "id": "BE0009745",
        "name": "MIR-1"
    },
    {
        "id": "8569",
        "name": "MKNK1"
    },
    {
        "id": "2872",
        "name": "MKNK2"
    },
    {
        "id": "2862",
        "name": "MLNR"
    },
    {
        "id": "166785",
        "name": "MMAA"
    },
    {
        "id": "326625",
        "name": "MMAB"
    },
    {
        "id": "25974",
        "name": "MMACHC"
    },
    {
        "id": "4311",
        "name": "MME"
    },
    {
        "id": "4312",
        "name": "MMP1"
    },
    {
        "id": "4319",
        "name": "MMP10"
    },
    {
        "id": "4320",
        "name": "MMP11"
    },
    {
        "id": "4321",
        "name": "MMP12"
    },
    {
        "id": "4322",
        "name": "MMP13"
    },
    {
        "id": "4323",
        "name": "MMP14"
    },
    {
        "id": "4324",
        "name": "MMP15"
    },
    {
        "id": "4325",
        "name": "MMP16"
    },
    {
        "id": "4326",
        "name": "MMP17"
    },
    {
        "id": "4327",
        "name": "MMP19"
    },
    {
        "id": "4313",
        "name": "MMP2"
    },
    {
        "id": "9313",
        "name": "MMP20"
    },
    {
        "id": "118856",
        "name": "MMP21"
    },
    {
        "id": "8510",
        "name": "MMP23B"
    },
    {
        "id": "10893",
        "name": "MMP24"
    },
    {
        "id": "64386",
        "name": "MMP25"
    },
    {
        "id": "56547",
        "name": "MMP26"
    },
    {
        "id": "64066",
        "name": "MMP27"
    },
    {
        "id": "79148",
        "name": "MMP28"
    },
    {
        "id": "4314",
        "name": "MMP3"
    },
    {
        "id": "4316",
        "name": "MMP7"
    },
    {
        "id": "4317",
        "name": "MMP8"
    },
    {
        "id": "4318",
        "name": "MMP9"
    },
    {
        "id": "4594",
        "name": "MMUT"
    },
    {
        "id": "55034",
        "name": "MOCOS"
    },
    {
        "id": "BE0003049",
        "name": "MOD"
    },
    {
        "id": "4350",
        "name": "MPG"
    },
    {
        "id": "4352",
        "name": "MPL"
    },
    {
        "id": "4353",
        "name": "MPO"
    },
    {
        "id": "3140",
        "name": "MR1"
    },
    {
        "id": "4360",
        "name": "MRC1"
    },
    {
        "id": "57591",
        "name": "MRTFA"
    },
    {
        "id": "931",
        "name": "MS4A1"
    },
    {
        "id": "2206",
        "name": "MS4A2"
    },
    {
        "id": "BE0001789",
        "name": "MSDC"
    },
    {
        "id": "10232",
        "name": "MSLN"
    },
    {
        "id": "6307",
        "name": "MSMO1"
    },
    {
        "id": "BE0009218",
        "name": "MSP-1"
    },
    {
        "id": "4482",
        "name": "MSRA"
    },
    {
        "id": "4486",
        "name": "MST1R"
    },
    {
        "id": "2660",
        "name": "MSTN"
    },
    {
        "id": "4508",
        "name": "MT-ATP6"
    },
    {
        "id": "4512",
        "name": "MT-CO1"
    },
    {
        "id": "4513",
        "name": "MT-CO2"
    },
    {
        "id": "4514",
        "name": "MT-CO3"
    },
    {
        "id": "BE0004428",
        "name": "MT-CYB"
    },
    {
        "id": "4519",
        "name": "MT-CYB"
    },
    {
        "id": "4535",
        "name": "MT-ND1"
    },
    {
        "id": "4536",
        "name": "MT-ND2"
    },
    {
        "id": "4537",
        "name": "MT-ND3"
    },
    {
        "id": "4538",
        "name": "MT-ND4"
    },
    {
        "id": "4539",
        "name": "MT-ND4L"
    },
    {
        "id": "4540",
        "name": "MT-ND5"
    },
    {
        "id": "4541",
        "name": "MT-ND6"
    },
    {
        "id": "BE0002669",
        "name": "MT1739"
    },
    {
        "id": "BE0003964",
        "name": "MT1988"
    },
    {
        "id": "4489",
        "name": "MT1A"
    },
    {
        "id": "4490",
        "name": "MT1B"
    },
    {
        "id": "4493",
        "name": "MT1E"
    },
    {
        "id": "4494",
        "name": "MT1F"
    },
    {
        "id": "4495",
        "name": "MT1G"
    },
    {
        "id": "4496",
        "name": "MT1H"
    },
    {
        "id": "645745",
        "name": "MT1HL1"
    },
    {
        "id": "4499",
        "name": "MT1M"
    },
    {
        "id": "4501",
        "name": "MT1X"
    },
    {
        "id": "4502",
        "name": "MT2A"
    },
    {
        "id": "4504",
        "name": "MT3"
    },
    {
        "id": "84560",
        "name": "MT4"
    },
    {
        "id": "4507",
        "name": "MTAP"
    },
    {
        "id": "4520",
        "name": "MTF1"
    },
    {
        "id": "123263",
        "name": "MTFMT"
    },
    {
        "id": "4522",
        "name": "MTHFD1"
    },
    {
        "id": "10797",
        "name": "MTHFD2"
    },
    {
        "id": "4524",
        "name": "MTHFR"
    },
    {
        "id": "4543",
        "name": "MTNR1A"
    },
    {
        "id": "4544",
        "name": "MTNR1B"
    },
    {
        "id": "2475",
        "name": "MTOR"
    },
    {
        "id": "4548",
        "name": "MTR"
    },
    {
        "id": "4552",
        "name": "MTRR"
    },
    {
        "id": "4547",
        "name": "MTTP"
    },
    {
        "id": "4582",
        "name": "MUC1"
    },
    {
        "id": "94025",
        "name": "MUC16"
    },
    {
        "id": "4593",
        "name": "MUSK"
    },
    {
        "id": "4598",
        "name": "MVK"
    },
    {
        "id": "4609",
        "name": "MYC"
    },
    {
        "id": "4629",
        "name": "MYH11"
    },
    {
        "id": "79784",
        "name": "MYH14"
    },
    {
        "id": "4625",
        "name": "MYH7"
    },
    {
        "id": "4627",
        "name": "MYH9"
    },
    {
        "id": "10627",
        "name": "MYL12A"
    },
    {
        "id": "140465",
        "name": "MYL6B"
    },
    {
        "id": "4638",
        "name": "MYLK"
    },
    {
        "id": "85366",
        "name": "MYLK2"
    },
    {
        "id": "91807",
        "name": "MYLK3"
    },
    {
        "id": "340156",
        "name": "MYLK4"
    },
    {
        "id": "4643",
        "name": "MYO1E"
    },
    {
        "id": "53904",
        "name": "MYO3A"
    },
    {
        "id": "27163",
        "name": "NAAA"
    },
    {
        "id": "55191",
        "name": "NADSYN1"
    },
    {
        "id": "8883",
        "name": "NAE1"
    },
    {
        "id": "4668",
        "name": "NAGA"
    },
    {
        "id": "55577",
        "name": "NAGK"
    },
    {
        "id": "4669",
        "name": "NAGLU"
    },
    {
        "id": "51172",
        "name": "NAGPA"
    },
    {
        "id": "162417",
        "name": "NAGS"
    },
    {
        "id": "10135",
        "name": "NAMPT"
    },
    {
        "id": "222236",
        "name": "NAPEPLD"
    },
    {
        "id": "4677",
        "name": "NARS1"
    },
    {
        "id": "79731",
        "name": "NARS2"
    },
    {
        "id": "1463",
        "name": "NCAN"
    },
    {
        "id": "653361",
        "name": "NCF1"
    },
    {
        "id": "4688",
        "name": "NCF2"
    },
    {
        "id": "4689",
        "name": "NCF4"
    },
    {
        "id": "8648",
        "name": "NCOA1"
    },
    {
        "id": "10499",
        "name": "NCOA2"
    },
    {
        "id": "57727",
        "name": "NCOA5"
    },
    {
        "id": "23413",
        "name": "NCS1"
    },
    {
        "id": "3340",
        "name": "NDST1"
    },
    {
        "id": "4694",
        "name": "NDUFA1"
    },
    {
        "id": "4705",
        "name": "NDUFA10"
    },
    {
        "id": "126328",
        "name": "NDUFA11"
    },
    {
        "id": "55967",
        "name": "NDUFA12"
    },
    {
        "id": "51079",
        "name": "NDUFA13"
    },
    {
        "id": "4695",
        "name": "NDUFA2"
    },
    {
        "id": "4696",
        "name": "NDUFA3"
    },
    {
        "id": "4697",
        "name": "NDUFA4"
    },
    {
        "id": "56901",
        "name": "NDUFA4L2"
    },
    {
        "id": "4698",
        "name": "NDUFA5"
    },
    {
        "id": "4700",
        "name": "NDUFA6"
    },
    {
        "id": "4701",
        "name": "NDUFA7"
    },
    {
        "id": "4702",
        "name": "NDUFA8"
    },
    {
        "id": "4704",
        "name": "NDUFA9"
    },
    {
        "id": "4706",
        "name": "NDUFAB1"
    },
    {
        "id": "4707",
        "name": "NDUFB1"
    },
    {
        "id": "4716",
        "name": "NDUFB10"
    },
    {
        "id": "4708",
        "name": "NDUFB2"
    },
    {
        "id": "4709",
        "name": "NDUFB3"
    },
    {
        "id": "4710",
        "name": "NDUFB4"
    },
    {
        "id": "4711",
        "name": "NDUFB5"
    },
    {
        "id": "4712",
        "name": "NDUFB6"
    },
    {
        "id": "4713",
        "name": "NDUFB7"
    },
    {
        "id": "4714",
        "name": "NDUFB8"
    },
    {
        "id": "4715",
        "name": "NDUFB9"
    },
    {
        "id": "4717",
        "name": "NDUFC1"
    },
    {
        "id": "4718",
        "name": "NDUFC2"
    },
    {
        "id": "4719",
        "name": "NDUFS1"
    },
    {
        "id": "4720",
        "name": "NDUFS2"
    },
    {
        "id": "4722",
        "name": "NDUFS3"
    },
    {
        "id": "4724",
        "name": "NDUFS4"
    },
    {
        "id": "4725",
        "name": "NDUFS5"
    },
    {
        "id": "4726",
        "name": "NDUFS6"
    },
    {
        "id": "374291",
        "name": "NDUFS7"
    },
    {
        "id": "4728",
        "name": "NDUFS8"
    },
    {
        "id": "4723",
        "name": "NDUFV1"
    },
    {
        "id": "4729",
        "name": "NDUFV2"
    },
    {
        "id": "4731",
        "name": "NDUFV3"
    },
    {
        "id": "BE0002711",
        "name": "NE0143"
    },
    {
        "id": "BE0003386",
        "name": "NE1940"
    },
    {
        "id": "81607",
        "name": "NECTIN4"
    },
    {
        "id": "79661",
        "name": "NEIL1"
    },
    {
        "id": "252969",
        "name": "NEIL2"
    },
    {
        "id": "4750",
        "name": "NEK1"
    },
    {
        "id": "79858",
        "name": "NEK11"
    },
    {
        "id": "4751",
        "name": "NEK2"
    },
    {
        "id": "4752",
        "name": "NEK3"
    },
    {
        "id": "6787",
        "name": "NEK4"
    },
    {
        "id": "341676",
        "name": "NEK5"
    },
    {
        "id": "91754",
        "name": "NEK9"
    },
    {
        "id": "4758",
        "name": "NEU1"
    },
    {
        "id": "4759",
        "name": "NEU2"
    },
    {
        "id": "4772",
        "name": "NFATC1"
    },
    {
        "id": "4779",
        "name": "NFE2L1"
    },
    {
        "id": "4790",
        "name": "NFKB1"
    },
    {
        "id": "4791",
        "name": "NFKB2"
    },
    {
        "id": "4792",
        "name": "NFKBIA"
    },
    {
        "id": "9054",
        "name": "NFS1"
    },
    {
        "id": "4803",
        "name": "NGF"
    },
    {
        "id": "4811",
        "name": "NID1"
    },
    {
        "id": "167359",
        "name": "NIM1K"
    },
    {
        "id": "51388",
        "name": "NIP7"
    },
    {
        "id": "11188",
        "name": "NISCH"
    },
    {
        "id": "4830",
        "name": "NME1"
    },
    {
        "id": "654364",
        "name": "NME2"
    },
    {
        "id": "64802",
        "name": "NMNAT1"
    },
    {
        "id": "349565",
        "name": "NMNAT3"
    },
    {
        "id": "57407",
        "name": "NMRAL1"
    },
    {
        "id": "4836",
        "name": "NMT1"
    },
    {
        "id": "9397",
        "name": "NMT2"
    },
    {
        "id": "4837",
        "name": "NNMT"
    },
    {
        "id": "23530",
        "name": "NNT"
    },
    {
        "id": "64127",
        "name": "NOD2"
    },
    {
        "id": "9221",
        "name": "NOLC1"
    },
    {
        "id": "23420",
        "name": "NOMO1"
    },
    {
        "id": "4842",
        "name": "NOS1"
    },
    {
        "id": "4843",
        "name": "NOS2"
    },
    {
        "id": "4846",
        "name": "NOS3"
    },
    {
        "id": "27035",
        "name": "NOX1"
    },
    {
        "id": "124056",
        "name": "NOXO1"
    },
    {
        "id": "29881",
        "name": "NPC1L1"
    },
    {
        "id": "9520",
        "name": "NPEPPS"
    },
    {
        "id": "4869",
        "name": "NPM1"
    },
    {
        "id": "4879",
        "name": "NPPB"
    },
    {
        "id": "4881",
        "name": "NPR\u00a01.00"
    },
    {
        "id": "4882",
        "name": "NPR\u00a02.00"
    },
    {
        "id": "4883",
        "name": "NPR\u00a03.00"
    },
    {
        "id": "387129",
        "name": "NPSR1"
    },
    {
        "id": "4852",
        "name": "NPY"
    },
    {
        "id": "4886",
        "name": "NPY1R"
    },
    {
        "id": "4887",
        "name": "NPY2R"
    },
    {
        "id": "5540",
        "name": "NPY4R"
    },
    {
        "id": "4889",
        "name": "NPY5R"
    },
    {
        "id": "1728",
        "name": "NQO1"
    },
    {
        "id": "4835",
        "name": "NQO2"
    },
    {
        "id": "190",
        "name": "NR0B1"
    },
    {
        "id": "9572",
        "name": "NR1D1"
    },
    {
        "id": "9975",
        "name": "NR1D2"
    },
    {
        "id": "7376",
        "name": "NR1H2"
    },
    {
        "id": "10062",
        "name": "NR1H3"
    },
    {
        "id": "9971",
        "name": "NR1H4"
    },
    {
        "id": "8856",
        "name": "NR1I2"
    },
    {
        "id": "9970",
        "name": "NR1I3"
    },
    {
        "id": "7025",
        "name": "NR2F1"
    },
    {
        "id": "2908",
        "name": "NR3C1"
    },
    {
        "id": "4306",
        "name": "NR3C2"
    },
    {
        "id": "8013",
        "name": "NR4A3"
    },
    {
        "id": "2516",
        "name": "NR5A1"
    },
    {
        "id": "8204",
        "name": "NRIP1"
    },
    {
        "id": "8829",
        "name": "NRP1"
    },
    {
        "id": "9378",
        "name": "NRXN1"
    },
    {
        "id": "BE0004701",
        "name": "NS3/4A"
    },
    {
        "id": "BE0008651",
        "name": "NS5A"
    },
    {
        "id": "BE0008655",
        "name": "NS5b"
    },
    {
        "id": "BE0002403",
        "name": "NS5b"
    },
    {
        "id": "50814",
        "name": "NSDHL"
    },
    {
        "id": "4905",
        "name": "NSF"
    },
    {
        "id": "22978",
        "name": "NT5C2"
    },
    {
        "id": "4907",
        "name": "NT5E"
    },
    {
        "id": "56953",
        "name": "NT5M"
    },
    {
        "id": "4914",
        "name": "NTRK1"
    },
    {
        "id": "4915",
        "name": "NTRK2"
    },
    {
        "id": "4916",
        "name": "NTRK3"
    },
    {
        "id": "23620",
        "name": "NTSR2"
    },
    {
        "id": "9891",
        "name": "NUAK1"
    },
    {
        "id": "81788",
        "name": "NUAK2"
    },
    {
        "id": "4924",
        "name": "NUCB1"
    },
    {
        "id": "4925",
        "name": "NUCB2"
    },
    {
        "id": "10726",
        "name": "NUDC"
    },
    {
        "id": "53343",
        "name": "NUDT9"
    },
    {
        "id": "4938",
        "name": "OAS1"
    },
    {
        "id": "4942",
        "name": "OAT"
    },
    {
        "id": "4946",
        "name": "OAZ1"
    },
    {
        "id": "4947",
        "name": "OAZ2"
    },
    {
        "id": "51686",
        "name": "OAZ3"
    },
    {
        "id": "BE0009570",
        "name": "OBP-1"
    },
    {
        "id": "29991",
        "name": "OBP2A"
    },
    {
        "id": "4953",
        "name": "ODC1"
    },
    {
        "id": "10724",
        "name": "OGA"
    },
    {
        "id": "4967",
        "name": "OGDH"
    },
    {
        "id": "55239",
        "name": "OGFOD1"
    },
    {
        "id": "79676",
        "name": "OGFOD2"
    },
    {
        "id": "26873",
        "name": "OPLAH"
    },
    {
        "id": "4985",
        "name": "OPRD1"
    },
    {
        "id": "4986",
        "name": "OPRK1"
    },
    {
        "id": "4987",
        "name": "OPRL1"
    },
    {
        "id": "4988",
        "name": "OPRM1"
    },
    {
        "id": "BE0003328",
        "name": "ORF"
    },
    {
        "id": "BE0000633",
        "name": "ORF28"
    },
    {
        "id": "5004",
        "name": "ORM1"
    },
    {
        "id": "5005",
        "name": "ORM2"
    },
    {
        "id": "5009",
        "name": "OTC"
    },
    {
        "id": "BE0008648",
        "name": "OXA-48"
    },
    {
        "id": "5019",
        "name": "OXCT1"
    },
    {
        "id": "64064",
        "name": "OXCT2"
    },
    {
        "id": "9943",
        "name": "OXSR1"
    },
    {
        "id": "5020",
        "name": "OXT"
    },
    {
        "id": "5021",
        "name": "OXTR"
    },
    {
        "id": "BE0002424",
        "name": "P"
    },
    {
        "id": "BE0000555",
        "name": "P"
    },
    {
        "id": "BE0000312",
        "name": "P"
    },
    {
        "id": "BE0003050",
        "name": "P/V"
    },
    {
        "id": "5023",
        "name": "P2RX1"
    },
    {
        "id": "22953",
        "name": "P2RX2"
    },
    {
        "id": "5024",
        "name": "P2RX3"
    },
    {
        "id": "5025",
        "name": "P2RX4"
    },
    {
        "id": "5026",
        "name": "P2RX5"
    },
    {
        "id": "9127",
        "name": "P2RX6"
    },
    {
        "id": "5027",
        "name": "P2RX7"
    },
    {
        "id": "5028",
        "name": "P2RY1"
    },
    {
        "id": "27334",
        "name": "P2RY10"
    },
    {
        "id": "5032",
        "name": "P2RY11"
    },
    {
        "id": "64805",
        "name": "P2RY12"
    },
    {
        "id": "53829",
        "name": "P2RY13"
    },
    {
        "id": "9934",
        "name": "P2RY14"
    },
    {
        "id": "5029",
        "name": "P2RY2"
    },
    {
        "id": "5030",
        "name": "P2RY4"
    },
    {
        "id": "286530",
        "name": "P2RY8"
    },
    {
        "id": "64175",
        "name": "P3H1"
    },
    {
        "id": "55214",
        "name": "P3H2"
    },
    {
        "id": "10536",
        "name": "P3H3"
    },
    {
        "id": "5033",
        "name": "P4HA1"
    },
    {
        "id": "8974",
        "name": "P4HA2"
    },
    {
        "id": "5034",
        "name": "P4HB"
    },
    {
        "id": "54681",
        "name": "P4HTM"
    },
    {
        "id": "BE0002335",
        "name": "P5CR2"
    },
    {
        "id": "29943",
        "name": "PADI1"
    },
    {
        "id": "11240",
        "name": "PADI2"
    },
    {
        "id": "51702",
        "name": "PADI3"
    },
    {
        "id": "23569",
        "name": "PADI4"
    },
    {
        "id": "353238",
        "name": "PADI6"
    },
    {
        "id": "5047",
        "name": "PAEP"
    },
    {
        "id": "5050",
        "name": "PAFAH1B3"
    },
    {
        "id": "5053",
        "name": "PAH"
    },
    {
        "id": "10606",
        "name": "PAICS"
    },
    {
        "id": "5058",
        "name": "PAK1"
    },
    {
        "id": "5062",
        "name": "PAK2"
    },
    {
        "id": "5063",
        "name": "PAK3"
    },
    {
        "id": "10298",
        "name": "PAK4"
    },
    {
        "id": "57144",
        "name": "PAK5"
    },
    {
        "id": "56924",
        "name": "PAK6"
    },
    {
        "id": "5066",
        "name": "PAM"
    },
    {
        "id": "24145",
        "name": "PANX1"
    },
    {
        "id": "10914",
        "name": "PAPOLA"
    },
    {
        "id": "5069",
        "name": "PAPPA"
    },
    {
        "id": "9061",
        "name": "PAPSS1"
    },
    {
        "id": "11315",
        "name": "PARK7"
    },
    {
        "id": "142",
        "name": "PARP1"
    },
    {
        "id": "165631",
        "name": "PARP15"
    },
    {
        "id": "10038",
        "name": "PARP2"
    },
    {
        "id": "10039",
        "name": "PARP3"
    },
    {
        "id": "25973",
        "name": "PARS2"
    },
    {
        "id": "BE0000282",
        "name": "PB1"
    },
    {
        "id": "5091",
        "name": "PC"
    },
    {
        "id": "5092",
        "name": "PCBD1"
    },
    {
        "id": "5095",
        "name": "PCCA"
    },
    {
        "id": "5096",
        "name": "PCCB"
    },
    {
        "id": "57526",
        "name": "PCDH19"
    },
    {
        "id": "5105",
        "name": "PCK1"
    },
    {
        "id": "5110",
        "name": "PCMT1"
    },
    {
        "id": "5111",
        "name": "PCNA"
    },
    {
        "id": "5122",
        "name": "PCSK1"
    },
    {
        "id": "5126",
        "name": "PCSK2"
    },
    {
        "id": "255738",
        "name": "PCSK9"
    },
    {
        "id": "58488",
        "name": "PCTP"
    },
    {
        "id": "5130",
        "name": "PCYT1A"
    },
    {
        "id": "9468",
        "name": "PCYT1B"
    },
    {
        "id": "5133",
        "name": "PDCD1"
    },
    {
        "id": "10016",
        "name": "PDCD6"
    },
    {
        "id": "10846",
        "name": "PDE10A"
    },
    {
        "id": "50940",
        "name": "PDE11A"
    },
    {
        "id": "5136",
        "name": "PDE1A"
    },
    {
        "id": "5153",
        "name": "PDE1B"
    },
    {
        "id": "5137",
        "name": "PDE1C"
    },
    {
        "id": "5139",
        "name": "PDE3A"
    },
    {
        "id": "5140",
        "name": "PDE3B"
    },
    {
        "id": "BE0009697",
        "name": "PDE4"
    },
    {
        "id": "BE0003429",
        "name": "PDE4"
    },
    {
        "id": "5141",
        "name": "PDE4A"
    },
    {
        "id": "5142",
        "name": "PDE4B"
    },
    {
        "id": "5143",
        "name": "PDE4C"
    },
    {
        "id": "5144",
        "name": "PDE4D"
    },
    {
        "id": "8654",
        "name": "PDE5A"
    },
    {
        "id": "5145",
        "name": "PDE6A"
    },
    {
        "id": "5158",
        "name": "PDE6B"
    },
    {
        "id": "5146",
        "name": "PDE6C"
    },
    {
        "id": "5148",
        "name": "PDE6G"
    },
    {
        "id": "5149",
        "name": "PDE6H"
    },
    {
        "id": "5150",
        "name": "PDE7A"
    },
    {
        "id": "27115",
        "name": "PDE7B"
    },
    {
        "id": "5151",
        "name": "PDE8A"
    },
    {
        "id": "8622",
        "name": "PDE8B"
    },
    {
        "id": "5152",
        "name": "PDE9A"
    },
    {
        "id": "64146",
        "name": "PDF"
    },
    {
        "id": "5155",
        "name": "PDGFB"
    },
    {
        "id": "80310",
        "name": "PDGFD"
    },
    {
        "id": "5156",
        "name": "PDGFRA"
    },
    {
        "id": "5159",
        "name": "PDGFRB"
    },
    {
        "id": "5160",
        "name": "PDHA1"
    },
    {
        "id": "5161",
        "name": "PDHA2"
    },
    {
        "id": "5162",
        "name": "PDHB"
    },
    {
        "id": "2923",
        "name": "PDIA3"
    },
    {
        "id": "5163",
        "name": "PDK1"
    },
    {
        "id": "5164",
        "name": "PDK2"
    },
    {
        "id": "5165",
        "name": "PDK3"
    },
    {
        "id": "5166",
        "name": "PDK4"
    },
    {
        "id": "5170",
        "name": "PDPK1"
    },
    {
        "id": "23042",
        "name": "PDXDC1"
    },
    {
        "id": "8566",
        "name": "PDXK"
    },
    {
        "id": "57026",
        "name": "PDXP"
    },
    {
        "id": "5037",
        "name": "PEBP1"
    },
    {
        "id": "55825",
        "name": "PECR"
    },
    {
        "id": "553115",
        "name": "PEF1"
    },
    {
        "id": "BE0009185",
        "name": "PF3D7_1211900"
    },
    {
        "id": "5196",
        "name": "PF4"
    },
    {
        "id": "5198",
        "name": "PFAS"
    },
    {
        "id": "5207",
        "name": "PFKFB1"
    },
    {
        "id": "5210",
        "name": "PFKFB4"
    },
    {
        "id": "BE0004184",
        "name": "PFL_1329"
    },
    {
        "id": "5216",
        "name": "PFN1"
    },
    {
        "id": "5217",
        "name": "PFN2"
    },
    {
        "id": "5222",
        "name": "PGA5"
    },
    {
        "id": "5223",
        "name": "PGAM1"
    },
    {
        "id": "5224",
        "name": "PGAM2"
    },
    {
        "id": "5226",
        "name": "PGD"
    },
    {
        "id": "5228",
        "name": "PGF"
    },
    {
        "id": "5229",
        "name": "PGGT1B"
    },
    {
        "id": "5230",
        "name": "PGK\u00a01.00"
    },
    {
        "id": "114770",
        "name": "PGLYRP2"
    },
    {
        "id": "114771",
        "name": "PGLYRP3"
    },
    {
        "id": "5236",
        "name": "PGM1"
    },
    {
        "id": "5241",
        "name": "PGR"
    },
    {
        "id": "10857",
        "name": "PGRMC1"
    },
    {
        "id": "5245",
        "name": "PHB1"
    },
    {
        "id": "11331",
        "name": "PHB2"
    },
    {
        "id": "26227",
        "name": "PHGDH"
    },
    {
        "id": "5260",
        "name": "PHKG1"
    },
    {
        "id": "162466",
        "name": "PHOSPHO1"
    },
    {
        "id": "5264",
        "name": "PHYH"
    },
    {
        "id": "85007",
        "name": "PHYKPL"
    },
    {
        "id": "55300",
        "name": "PI4K2B"
    },
    {
        "id": "5298",
        "name": "PI4KB"
    },
    {
        "id": "5287",
        "name": "PIK3C2B"
    },
    {
        "id": "5288",
        "name": "PIK3C2G"
    },
    {
        "id": "5289",
        "name": "PIK3C3"
    },
    {
        "id": "5290",
        "name": "PIK3CA"
    },
    {
        "id": "5291",
        "name": "PIK3CB"
    },
    {
        "id": "5293",
        "name": "PIK3CD"
    },
    {
        "id": "5294",
        "name": "PIK3CG"
    },
    {
        "id": "5295",
        "name": "PIK3R1"
    },
    {
        "id": "5296",
        "name": "PIK3R2"
    },
    {
        "id": "8503",
        "name": "PIK3R3"
    },
    {
        "id": "5292",
        "name": "PIM1"
    },
    {
        "id": "415116",
        "name": "PIM3"
    },
    {
        "id": "5300",
        "name": "PIN1"
    },
    {
        "id": "8396",
        "name": "PIP4K2B"
    },
    {
        "id": "79837",
        "name": "PIP4K2C"
    },
    {
        "id": "51268",
        "name": "PIPOX"
    },
    {
        "id": "23761",
        "name": "PISD"
    },
    {
        "id": "5306",
        "name": "PITPNA"
    },
    {
        "id": "5569",
        "name": "PKIA"
    },
    {
        "id": "5313",
        "name": "PKLR"
    },
    {
        "id": "5315",
        "name": "PKM"
    },
    {
        "id": "9088",
        "name": "PKMYT1"
    },
    {
        "id": "5585",
        "name": "PKN1"
    },
    {
        "id": "5586",
        "name": "PKN2"
    },
    {
        "id": "8399",
        "name": "PLA2G10"
    },
    {
        "id": "5319",
        "name": "PLA2G1B"
    },
    {
        "id": "5320",
        "name": "PLA2G2A"
    },
    {
        "id": "26279",
        "name": "PLA2G2D"
    },
    {
        "id": "30814",
        "name": "PLA2G2E"
    },
    {
        "id": "5321",
        "name": "PLA2G4A"
    },
    {
        "id": "8398",
        "name": "PLA2G6"
    },
    {
        "id": "7941",
        "name": "PLA2G7"
    },
    {
        "id": "5327",
        "name": "PLAT"
    },
    {
        "id": "5328",
        "name": "PLAU"
    },
    {
        "id": "5329",
        "name": "PLAUR"
    },
    {
        "id": "5333",
        "name": "PLCD1"
    },
    {
        "id": "5334",
        "name": "PLCL1"
    },
    {
        "id": "5337",
        "name": "PLD1"
    },
    {
        "id": "5338",
        "name": "PLD2"
    },
    {
        "id": "59338",
        "name": "PLEKHA1"
    },
    {
        "id": "57664",
        "name": "PLEKHA4"
    },
    {
        "id": "5340",
        "name": "PLG"
    },
    {
        "id": "10226",
        "name": "PLIN3"
    },
    {
        "id": "5347",
        "name": "PLK1"
    },
    {
        "id": "10769",
        "name": "PLK2"
    },
    {
        "id": "1263",
        "name": "PLK3"
    },
    {
        "id": "10733",
        "name": "PLK4"
    },
    {
        "id": "5351",
        "name": "PLOD1"
    },
    {
        "id": "5352",
        "name": "PLOD2"
    },
    {
        "id": "8985",
        "name": "PLOD3"
    },
    {
        "id": "11212",
        "name": "PLPBP"
    },
    {
        "id": "BE0001694",
        "name": "PMII"
    },
    {
        "id": "5371",
        "name": "PML"
    },
    {
        "id": "5375",
        "name": "PMP2"
    },
    {
        "id": "5395",
        "name": "PMS2"
    },
    {
        "id": "5406",
        "name": "PNLIP"
    },
    {
        "id": "5408",
        "name": "PNLIPRP2"
    },
    {
        "id": "5409",
        "name": "PNMT"
    },
    {
        "id": "4860",
        "name": "PNP"
    },
    {
        "id": "55163",
        "name": "PNPO"
    },
    {
        "id": "5422",
        "name": "POLA1"
    },
    {
        "id": "23649",
        "name": "POLA2"
    },
    {
        "id": "5423",
        "name": "POLB"
    },
    {
        "id": "5426",
        "name": "POLE"
    },
    {
        "id": "5427",
        "name": "POLE2"
    },
    {
        "id": "54107",
        "name": "POLE3"
    },
    {
        "id": "56655",
        "name": "POLE4"
    },
    {
        "id": "51426",
        "name": "POLK"
    },
    {
        "id": "5443",
        "name": "POMC"
    },
    {
        "id": "5444",
        "name": "PON1"
    },
    {
        "id": "5447",
        "name": "POR"
    },
    {
        "id": "5465",
        "name": "PPARA"
    },
    {
        "id": "5467",
        "name": "PPARD"
    },
    {
        "id": "5468",
        "name": "PPARG"
    },
    {
        "id": "133522",
        "name": "PPARGC1B"
    },
    {
        "id": "5471",
        "name": "PPAT"
    },
    {
        "id": "5473",
        "name": "PPBP"
    },
    {
        "id": "60490",
        "name": "PPCDC"
    },
    {
        "id": "BE0003003",
        "name": "PPI"
    },
    {
        "id": "5478",
        "name": "PPIA"
    },
    {
        "id": "5479",
        "name": "PPIB"
    },
    {
        "id": "5480",
        "name": "PPIC"
    },
    {
        "id": "10105",
        "name": "PPIF"
    },
    {
        "id": "9360",
        "name": "PPIG"
    },
    {
        "id": "10465",
        "name": "PPIH"
    },
    {
        "id": "5499",
        "name": "PPP1CA"
    },
    {
        "id": "5501",
        "name": "PPP1CC"
    },
    {
        "id": "5515",
        "name": "PPP2CA"
    },
    {
        "id": "5516",
        "name": "PPP2CB"
    },
    {
        "id": "5518",
        "name": "PPP2R1A"
    },
    {
        "id": "5520",
        "name": "PPP2R2A"
    },
    {
        "id": "5527",
        "name": "PPP2R5C"
    },
    {
        "id": "5530",
        "name": "PPP3CA"
    },
    {
        "id": "5534",
        "name": "PPP3R1"
    },
    {
        "id": "5535",
        "name": "PPP3R2"
    },
    {
        "id": "5536",
        "name": "PPP5C"
    },
    {
        "id": "5538",
        "name": "PPT1"
    },
    {
        "id": "5052",
        "name": "PRDX1"
    },
    {
        "id": "7001",
        "name": "PRDX2"
    },
    {
        "id": "25824",
        "name": "PRDX5"
    },
    {
        "id": "9588",
        "name": "PRDX6"
    },
    {
        "id": "5550",
        "name": "PREP"
    },
    {
        "id": "5553",
        "name": "PRG2"
    },
    {
        "id": "5562",
        "name": "PRKAA1"
    },
    {
        "id": "5563",
        "name": "PRKAA2"
    },
    {
        "id": "5564",
        "name": "PRKAB1"
    },
    {
        "id": "5565",
        "name": "PRKAB2"
    },
    {
        "id": "5566",
        "name": "PRKACA"
    },
    {
        "id": "5567",
        "name": "PRKACB"
    },
    {
        "id": "5571",
        "name": "PRKAG1"
    },
    {
        "id": "51422",
        "name": "PRKAG2"
    },
    {
        "id": "53632",
        "name": "PRKAG3"
    },
    {
        "id": "5573",
        "name": "PRKAR1A"
    },
    {
        "id": "5576",
        "name": "PRKAR2A"
    },
    {
        "id": "5577",
        "name": "PRKAR2B"
    },
    {
        "id": "5578",
        "name": "PRKCA"
    },
    {
        "id": "5579",
        "name": "PRKCB"
    },
    {
        "id": "5580",
        "name": "PRKCD"
    },
    {
        "id": "5581",
        "name": "PRKCE"
    },
    {
        "id": "5582",
        "name": "PRKCG"
    },
    {
        "id": "5584",
        "name": "PRKCI"
    },
    {
        "id": "5588",
        "name": "PRKCQ"
    },
    {
        "id": "5590",
        "name": "PRKCZ"
    },
    {
        "id": "5587",
        "name": "PRKD1"
    },
    {
        "id": "5591",
        "name": "PRKDC"
    },
    {
        "id": "5593",
        "name": "PRKG2"
    },
    {
        "id": "5618",
        "name": "PRLR"
    },
    {
        "id": "3276",
        "name": "PRMT1"
    },
    {
        "id": "10196",
        "name": "PRMT3"
    },
    {
        "id": "5624",
        "name": "PROC"
    },
    {
        "id": "10544",
        "name": "PROCR"
    },
    {
        "id": "5625",
        "name": "PRODH"
    },
    {
        "id": "BE0002714",
        "name": "PROF"
    },
    {
        "id": "5627",
        "name": "PROS1"
    },
    {
        "id": "8858",
        "name": "PROZ"
    },
    {
        "id": "8899",
        "name": "PRPF4B"
    },
    {
        "id": "5644",
        "name": "PRSS1"
    },
    {
        "id": "5645",
        "name": "PRSS2"
    },
    {
        "id": "5646",
        "name": "PRSS3"
    },
    {
        "id": "5652",
        "name": "PRSS8"
    },
    {
        "id": "5657",
        "name": "PRTN3"
    },
    {
        "id": "5660",
        "name": "PSAP"
    },
    {
        "id": "29968",
        "name": "PSAT1"
    },
    {
        "id": "55851",
        "name": "PSENEN"
    },
    {
        "id": "5673",
        "name": "PSG5"
    },
    {
        "id": "5682",
        "name": "PSMA1"
    },
    {
        "id": "5683",
        "name": "PSMA2"
    },
    {
        "id": "5684",
        "name": "PSMA3"
    },
    {
        "id": "5685",
        "name": "PSMA4"
    },
    {
        "id": "5686",
        "name": "PSMA5"
    },
    {
        "id": "5687",
        "name": "PSMA6"
    },
    {
        "id": "5688",
        "name": "PSMA7"
    },
    {
        "id": "5689",
        "name": "PSMB1"
    },
    {
        "id": "5699",
        "name": "PSMB10"
    },
    {
        "id": "5690",
        "name": "PSMB2"
    },
    {
        "id": "5691",
        "name": "PSMB3"
    },
    {
        "id": "5692",
        "name": "PSMB4"
    },
    {
        "id": "5693",
        "name": "PSMB5"
    },
    {
        "id": "5694",
        "name": "PSMB6"
    },
    {
        "id": "5695",
        "name": "PSMB7"
    },
    {
        "id": "5696",
        "name": "PSMB8"
    },
    {
        "id": "5698",
        "name": "PSMB9"
    },
    {
        "id": "5702",
        "name": "PSMC3"
    },
    {
        "id": "5720",
        "name": "PSME1"
    },
    {
        "id": "5723",
        "name": "PSPH"
    },
    {
        "id": "5724",
        "name": "PTAFR"
    },
    {
        "id": "9791",
        "name": "PTDSS1"
    },
    {
        "id": "81490",
        "name": "PTDSS2"
    },
    {
        "id": "5728",
        "name": "PTEN"
    },
    {
        "id": "5729",
        "name": "PTGDR"
    },
    {
        "id": "11251",
        "name": "PTGDR2"
    },
    {
        "id": "5730",
        "name": "PTGDS"
    },
    {
        "id": "5731",
        "name": "PTGER1"
    },
    {
        "id": "5732",
        "name": "PTGER2"
    },
    {
        "id": "5733",
        "name": "PTGER3"
    },
    {
        "id": "5734",
        "name": "PTGER4"
    },
    {
        "id": "10728",
        "name": "PTGES3"
    },
    {
        "id": "5737",
        "name": "PTGFR"
    },
    {
        "id": "5739",
        "name": "PTGIR"
    },
    {
        "id": "5740",
        "name": "PTGIS"
    },
    {
        "id": "22949",
        "name": "PTGR1"
    },
    {
        "id": "145482",
        "name": "PTGR2"
    },
    {
        "id": "5742",
        "name": "PTGS1"
    },
    {
        "id": "5743",
        "name": "PTGS2"
    },
    {
        "id": "5741",
        "name": "PTH"
    },
    {
        "id": "5745",
        "name": "PTH1R"
    },
    {
        "id": "5746",
        "name": "PTH2R"
    },
    {
        "id": "5747",
        "name": "PTK2"
    },
    {
        "id": "2185",
        "name": "PTK2B"
    },
    {
        "id": "5753",
        "name": "PTK6"
    },
    {
        "id": "5770",
        "name": "PTPN1"
    },
    {
        "id": "5781",
        "name": "PTPN11"
    },
    {
        "id": "5775",
        "name": "PTPN4"
    },
    {
        "id": "5787",
        "name": "PTPRB"
    },
    {
        "id": "5791",
        "name": "PTPRE"
    },
    {
        "id": "5802",
        "name": "PTPRS"
    },
    {
        "id": "5805",
        "name": "PTS"
    },
    {
        "id": "5816",
        "name": "PVALB"
    },
    {
        "id": "5817",
        "name": "PVR"
    },
    {
        "id": "5831",
        "name": "PYCR1"
    },
    {
        "id": "29920",
        "name": "PYCR2"
    },
    {
        "id": "65263",
        "name": "PYCR3"
    },
    {
        "id": "5834",
        "name": "PYGB"
    },
    {
        "id": "5836",
        "name": "PYGL"
    },
    {
        "id": "5837",
        "name": "PYGM"
    },
    {
        "id": "BE0003979",
        "name": "Pput_2883"
    },
    {
        "id": "5860",
        "name": "QDPR"
    },
    {
        "id": "25797",
        "name": "QPCT"
    },
    {
        "id": "23475",
        "name": "QPRT"
    },
    {
        "id": "8766",
        "name": "RAB11A"
    },
    {
        "id": "5868",
        "name": "RAB5A"
    },
    {
        "id": "7879",
        "name": "RAB7A"
    },
    {
        "id": "51762",
        "name": "RAB8B"
    },
    {
        "id": "9367",
        "name": "RAB9A"
    },
    {
        "id": "5875",
        "name": "RABGGTA"
    },
    {
        "id": "5876",
        "name": "RABGGTB"
    },
    {
        "id": "5879",
        "name": "RAC1"
    },
    {
        "id": "5880",
        "name": "RAC2"
    },
    {
        "id": "10399",
        "name": "RACK1"
    },
    {
        "id": "5888",
        "name": "RAD51"
    },
    {
        "id": "5894",
        "name": "RAF1"
    },
    {
        "id": "5898",
        "name": "RALA"
    },
    {
        "id": "10267",
        "name": "RAMP1"
    },
    {
        "id": "10266",
        "name": "RAMP2"
    },
    {
        "id": "10268",
        "name": "RAMP3"
    },
    {
        "id": "5901",
        "name": "RAN"
    },
    {
        "id": "5902",
        "name": "RANBP1"
    },
    {
        "id": "5911",
        "name": "RAP2A"
    },
    {
        "id": "5914",
        "name": "RARA"
    },
    {
        "id": "5915",
        "name": "RARB"
    },
    {
        "id": "5916",
        "name": "RARG"
    },
    {
        "id": "5918",
        "name": "RARRES1"
    },
    {
        "id": "5925",
        "name": "RB1"
    },
    {
        "id": "5947",
        "name": "RBP1"
    },
    {
        "id": "5949",
        "name": "RBP3"
    },
    {
        "id": "5950",
        "name": "RBP4"
    },
    {
        "id": "1827",
        "name": "RCAN1"
    },
    {
        "id": "5957",
        "name": "RCVRN"
    },
    {
        "id": "51109",
        "name": "RDH11"
    },
    {
        "id": "145226",
        "name": "RDH12"
    },
    {
        "id": "112724",
        "name": "RDH13"
    },
    {
        "id": "57665",
        "name": "RDH14"
    },
    {
        "id": "5959",
        "name": "RDH5"
    },
    {
        "id": "50700",
        "name": "RDH8"
    },
    {
        "id": "5962",
        "name": "RDX"
    },
    {
        "id": "5967",
        "name": "REG1A"
    },
    {
        "id": "5970",
        "name": "RELA"
    },
    {
        "id": "5972",
        "name": "REN"
    },
    {
        "id": "5973",
        "name": "RENBP"
    },
    {
        "id": "BE0009254",
        "name": "RESA"
    },
    {
        "id": "5979",
        "name": "RET"
    },
    {
        "id": "BE0001104",
        "name": "RET"
    },
    {
        "id": "54884",
        "name": "RETSAT"
    },
    {
        "id": "55312",
        "name": "RFK"
    },
    {
        "id": "9104",
        "name": "RGN"
    },
    {
        "id": "BE0002389",
        "name": "RHD"
    },
    {
        "id": "6009",
        "name": "RHEB"
    },
    {
        "id": "6010",
        "name": "RHO"
    },
    {
        "id": "387",
        "name": "RHOA"
    },
    {
        "id": "388",
        "name": "RHOB"
    },
    {
        "id": "79608",
        "name": "RIC3"
    },
    {
        "id": "10247",
        "name": "RIDA"
    },
    {
        "id": "83732",
        "name": "RIOK1"
    },
    {
        "id": "55781",
        "name": "RIOK2"
    },
    {
        "id": "8780",
        "name": "RIOK3"
    },
    {
        "id": "8737",
        "name": "RIPK1"
    },
    {
        "id": "8767",
        "name": "RIPK2"
    },
    {
        "id": "6035",
        "name": "RNASE1"
    },
    {
        "id": "6036",
        "name": "RNASE2"
    },
    {
        "id": "6037",
        "name": "RNASE3"
    },
    {
        "id": "6038",
        "name": "RNASE4"
    },
    {
        "id": "390",
        "name": "RND3"
    },
    {
        "id": "8732",
        "name": "RNGTT"
    },
    {
        "id": "6093",
        "name": "ROCK1"
    },
    {
        "id": "9475",
        "name": "ROCK2"
    },
    {
        "id": "6095",
        "name": "RORA"
    },
    {
        "id": "6098",
        "name": "ROS1"
    },
    {
        "id": "6121",
        "name": "RPE65"
    },
    {
        "id": "22934",
        "name": "RPIA"
    },
    {
        "id": "6134",
        "name": "RPL10"
    },
    {
        "id": "140801",
        "name": "RPL10L"
    },
    {
        "id": "6135",
        "name": "RPL11"
    },
    {
        "id": "23521",
        "name": "RPL13A"
    },
    {
        "id": "9045",
        "name": "RPL14"
    },
    {
        "id": "6138",
        "name": "RPL15"
    },
    {
        "id": "6141",
        "name": "RPL18"
    },
    {
        "id": "6143",
        "name": "RPL19"
    },
    {
        "id": "9349",
        "name": "RPL23"
    },
    {
        "id": "6147",
        "name": "RPL23A"
    },
    {
        "id": "51121",
        "name": "RPL26L1"
    },
    {
        "id": "6122",
        "name": "RPL3"
    },
    {
        "id": "11224",
        "name": "RPL35"
    },
    {
        "id": "6167",
        "name": "RPL37"
    },
    {
        "id": "6124",
        "name": "RPL4"
    },
    {
        "id": "6132",
        "name": "RPL8"
    },
    {
        "id": "6207",
        "name": "RPS13"
    },
    {
        "id": "6218",
        "name": "RPS17"
    },
    {
        "id": "6222",
        "name": "RPS18"
    },
    {
        "id": "6223",
        "name": "RPS19"
    },
    {
        "id": "6187",
        "name": "RPS2"
    },
    {
        "id": "6234",
        "name": "RPS28"
    },
    {
        "id": "6193",
        "name": "RPS5"
    },
    {
        "id": "6194",
        "name": "RPS6"
    },
    {
        "id": "6195",
        "name": "RPS6KA1"
    },
    {
        "id": "6197",
        "name": "RPS6KA3"
    },
    {
        "id": "8986",
        "name": "RPS6KA4"
    },
    {
        "id": "27330",
        "name": "RPS6KA6"
    },
    {
        "id": "6202",
        "name": "RPS8"
    },
    {
        "id": "6203",
        "name": "RPS9"
    },
    {
        "id": "3921",
        "name": "RPSA"
    },
    {
        "id": "6238",
        "name": "RRBP1"
    },
    {
        "id": "6240",
        "name": "RRM1"
    },
    {
        "id": "6241",
        "name": "RRM2"
    },
    {
        "id": "50484",
        "name": "RRM2B"
    },
    {
        "id": "51187",
        "name": "RSL24D1"
    },
    {
        "id": "51493",
        "name": "RTCB"
    },
    {
        "id": "BE0004310",
        "name": "RUMGNA_03254"
    },
    {
        "id": "10856",
        "name": "RUVBL2"
    },
    {
        "id": "6256",
        "name": "RXRA"
    },
    {
        "id": "6257",
        "name": "RXRB"
    },
    {
        "id": "6258",
        "name": "RXRG"
    },
    {
        "id": "6261",
        "name": "RYR1"
    },
    {
        "id": "6262",
        "name": "RYR2"
    },
    {
        "id": "944569",
        "name": "S gene"
    },
    {
        "id": "6271",
        "name": "S100A1"
    },
    {
        "id": "6281",
        "name": "S100A10"
    },
    {
        "id": "6282",
        "name": "S100A11"
    },
    {
        "id": "6283",
        "name": "S100A12"
    },
    {
        "id": "6284",
        "name": "S100A13"
    },
    {
        "id": "140576",
        "name": "S100A16"
    },
    {
        "id": "6273",
        "name": "S100A2"
    },
    {
        "id": "6275",
        "name": "S100A4"
    },
    {
        "id": "6277",
        "name": "S100A6"
    },
    {
        "id": "6278",
        "name": "S100A7"
    },
    {
        "id": "6279",
        "name": "S100A8"
    },
    {
        "id": "6280",
        "name": "S100A9"
    },
    {
        "id": "6285",
        "name": "S100B"
    },
    {
        "id": "795",
        "name": "S100G"
    },
    {
        "id": "6286",
        "name": "S100P"
    },
    {
        "id": "1901",
        "name": "S1PR1"
    },
    {
        "id": "1903",
        "name": "S1PR3"
    },
    {
        "id": "8698",
        "name": "S1PR4"
    },
    {
        "id": "53637",
        "name": "S1PR5"
    },
    {
        "id": "51128",
        "name": "SAR1B"
    },
    {
        "id": "6301",
        "name": "SARS1"
    },
    {
        "id": "6303",
        "name": "SAT1"
    },
    {
        "id": "BE0004304",
        "name": "SAVERM_4671"
    },
    {
        "id": "388228",
        "name": "SBK1"
    },
    {
        "id": "100130827",
        "name": "SBK3"
    },
    {
        "id": "949",
        "name": "SCARB1"
    },
    {
        "id": "7356",
        "name": "SCGB1A1"
    },
    {
        "id": "22955",
        "name": "SCMH1"
    },
    {
        "id": "6336",
        "name": "SCN10A"
    },
    {
        "id": "11280",
        "name": "SCN11A"
    },
    {
        "id": "6323",
        "name": "SCN1A"
    },
    {
        "id": "6324",
        "name": "SCN1B"
    },
    {
        "id": "6326",
        "name": "SCN2A"
    },
    {
        "id": "6327",
        "name": "SCN2B"
    },
    {
        "id": "6328",
        "name": "SCN3A"
    },
    {
        "id": "55800",
        "name": "SCN3B"
    },
    {
        "id": "6329",
        "name": "SCN4A"
    },
    {
        "id": "6330",
        "name": "SCN4B"
    },
    {
        "id": "6331",
        "name": "SCN5A"
    },
    {
        "id": "6332",
        "name": "SCN7A"
    },
    {
        "id": "6334",
        "name": "SCN8A"
    },
    {
        "id": "6335",
        "name": "SCN9A"
    },
    {
        "id": "6337",
        "name": "SCNN1A"
    },
    {
        "id": "6338",
        "name": "SCNN1B"
    },
    {
        "id": "6339",
        "name": "SCNN1D"
    },
    {
        "id": "6340",
        "name": "SCNN1G"
    },
    {
        "id": "6341",
        "name": "SCO1"
    },
    {
        "id": "59342",
        "name": "SCPEP1"
    },
    {
        "id": "6344",
        "name": "SCTR"
    },
    {
        "id": "57410",
        "name": "SCYL1"
    },
    {
        "id": "6383",
        "name": "SDC2"
    },
    {
        "id": "6388",
        "name": "SDF2"
    },
    {
        "id": "6389",
        "name": "SDHA"
    },
    {
        "id": "6390",
        "name": "SDHB"
    },
    {
        "id": "6391",
        "name": "SDHC"
    },
    {
        "id": "6392",
        "name": "SDHD"
    },
    {
        "id": "10993",
        "name": "SDS"
    },
    {
        "id": "113675",
        "name": "SDSL"
    },
    {
        "id": "23541",
        "name": "SEC14L2"
    },
    {
        "id": "266629",
        "name": "SEC14L3"
    },
    {
        "id": "284904",
        "name": "SEC14L4"
    },
    {
        "id": "6401",
        "name": "SELE"
    },
    {
        "id": "6414",
        "name": "SELENOP"
    },
    {
        "id": "6403",
        "name": "SELP"
    },
    {
        "id": "6406",
        "name": "SEMG1"
    },
    {
        "id": "6407",
        "name": "SEMG2"
    },
    {
        "id": "51091",
        "name": "SEPSECS"
    },
    {
        "id": "BE0009196",
        "name": "SERA5"
    },
    {
        "id": "5265",
        "name": "SERPINA1"
    },
    {
        "id": "12",
        "name": "SERPINA3"
    },
    {
        "id": "5267",
        "name": "SERPINA4"
    },
    {
        "id": "5104",
        "name": "SERPINA5"
    },
    {
        "id": "866",
        "name": "SERPINA6"
    },
    {
        "id": "6906",
        "name": "SERPINA7"
    },
    {
        "id": "5055",
        "name": "SERPINB2"
    },
    {
        "id": "6317",
        "name": "SERPINB3"
    },
    {
        "id": "5268",
        "name": "SERPINB5"
    },
    {
        "id": "5269",
        "name": "SERPINB6"
    },
    {
        "id": "5272",
        "name": "SERPINB9"
    },
    {
        "id": "462",
        "name": "SERPINC1"
    },
    {
        "id": "3053",
        "name": "SERPIND1"
    },
    {
        "id": "5054",
        "name": "SERPINE1"
    },
    {
        "id": "5176",
        "name": "SERPINF1"
    },
    {
        "id": "5345",
        "name": "SERPINF2"
    },
    {
        "id": "710",
        "name": "SERPING1"
    },
    {
        "id": "80854",
        "name": "SETD7"
    },
    {
        "id": "7536",
        "name": "SF1"
    },
    {
        "id": "8175",
        "name": "SF3A2"
    },
    {
        "id": "10946",
        "name": "SF3A3"
    },
    {
        "id": "23451",
        "name": "SF3B1"
    },
    {
        "id": "23450",
        "name": "SF3B3"
    },
    {
        "id": "6421",
        "name": "SFPQ"
    },
    {
        "id": "6424",
        "name": "SFRP4"
    },
    {
        "id": "653509",
        "name": "SFTPA1"
    },
    {
        "id": "6441",
        "name": "SFTPD"
    },
    {
        "id": "6446",
        "name": "SGK1"
    },
    {
        "id": "23678",
        "name": "SGK3"
    },
    {
        "id": "8879",
        "name": "SGPL1"
    },
    {
        "id": "10019",
        "name": "SH2B3"
    },
    {
        "id": "6462",
        "name": "SHBG"
    },
    {
        "id": "6470",
        "name": "SHMT1"
    },
    {
        "id": "6472",
        "name": "SHMT2"
    },
    {
        "id": "6476",
        "name": "SI"
    },
    {
        "id": "6614",
        "name": "SIGLEC1"
    },
    {
        "id": "27036",
        "name": "SIGLEC7"
    },
    {
        "id": "10280",
        "name": "SIGMAR1"
    },
    {
        "id": "150094",
        "name": "SIK1"
    },
    {
        "id": "23235",
        "name": "SIK2"
    },
    {
        "id": "23387",
        "name": "SIK3"
    },
    {
        "id": "23411",
        "name": "SIRT1"
    },
    {
        "id": "22933",
        "name": "SIRT2"
    },
    {
        "id": "23410",
        "name": "SIRT3"
    },
    {
        "id": "23408",
        "name": "SIRT5"
    },
    {
        "id": "10572",
        "name": "SIVA1"
    },
    {
        "id": "6500",
        "name": "SKP1"
    },
    {
        "id": "57823",
        "name": "SLAMF7"
    },
    {
        "id": "6555",
        "name": "SLC10A2"
    },
    {
        "id": "4891",
        "name": "SLC11A2"
    },
    {
        "id": "6557",
        "name": "SLC12A1"
    },
    {
        "id": "6558",
        "name": "SLC12A2"
    },
    {
        "id": "6559",
        "name": "SLC12A3"
    },
    {
        "id": "6560",
        "name": "SLC12A4"
    },
    {
        "id": "57468",
        "name": "SLC12A5"
    },
    {
        "id": "9990",
        "name": "SLC12A6"
    },
    {
        "id": "10723",
        "name": "SLC12A7"
    },
    {
        "id": "6561",
        "name": "SLC13A1"
    },
    {
        "id": "9058",
        "name": "SLC13A2"
    },
    {
        "id": "64849",
        "name": "SLC13A3"
    },
    {
        "id": "6564",
        "name": "SLC15A1"
    },
    {
        "id": "6565",
        "name": "SLC15A2"
    },
    {
        "id": "6566",
        "name": "SLC16A1"
    },
    {
        "id": "6567",
        "name": "SLC16A2"
    },
    {
        "id": "9123",
        "name": "SLC16A3"
    },
    {
        "id": "9122",
        "name": "SLC16A4"
    },
    {
        "id": "9121",
        "name": "SLC16A5"
    },
    {
        "id": "9120",
        "name": "SLC16A6"
    },
    {
        "id": "9194",
        "name": "SLC16A7"
    },
    {
        "id": "23539",
        "name": "SLC16A8"
    },
    {
        "id": "6570",
        "name": "SLC18A1"
    },
    {
        "id": "6571",
        "name": "SLC18A2"
    },
    {
        "id": "80704",
        "name": "SLC19A3"
    },
    {
        "id": "6505",
        "name": "SLC1A1"
    },
    {
        "id": "6506",
        "name": "SLC1A2"
    },
    {
        "id": "6507",
        "name": "SLC1A3"
    },
    {
        "id": "6509",
        "name": "SLC1A4"
    },
    {
        "id": "6510",
        "name": "SLC1A5"
    },
    {
        "id": "6511",
        "name": "SLC1A6"
    },
    {
        "id": "6512",
        "name": "SLC1A7"
    },
    {
        "id": "55867",
        "name": "SLC22A11"
    },
    {
        "id": "116085",
        "name": "SLC22A12"
    },
    {
        "id": "6583",
        "name": "SLC22A4"
    },
    {
        "id": "6584",
        "name": "SLC22A5"
    },
    {
        "id": "9356",
        "name": "SLC22A6"
    },
    {
        "id": "10864",
        "name": "SLC22A7"
    },
    {
        "id": "9376",
        "name": "SLC22A8"
    },
    {
        "id": "1468",
        "name": "SLC25A10"
    },
    {
        "id": "8604",
        "name": "SLC25A12"
    },
    {
        "id": "10165",
        "name": "SLC25A13"
    },
    {
        "id": "10166",
        "name": "SLC25A15"
    },
    {
        "id": "83733",
        "name": "SLC25A18"
    },
    {
        "id": "83884",
        "name": "SLC25A2"
    },
    {
        "id": "788",
        "name": "SLC25A20"
    },
    {
        "id": "79751",
        "name": "SLC25A22"
    },
    {
        "id": "123096",
        "name": "SLC25A29"
    },
    {
        "id": "291",
        "name": "SLC25A4"
    },
    {
        "id": "292",
        "name": "SLC25A5"
    },
    {
        "id": "293",
        "name": "SLC25A6"
    },
    {
        "id": "2030",
        "name": "SLC29A1"
    },
    {
        "id": "3177",
        "name": "SLC29A2"
    },
    {
        "id": "222962",
        "name": "SLC29A4"
    },
    {
        "id": "6513",
        "name": "SLC2A1"
    },
    {
        "id": "6514",
        "name": "SLC2A2"
    },
    {
        "id": "206358",
        "name": "SLC36A1"
    },
    {
        "id": "10991",
        "name": "SLC38A3"
    },
    {
        "id": "6519",
        "name": "SLC3A1"
    },
    {
        "id": "79581",
        "name": "SLC52A2"
    },
    {
        "id": "6524",
        "name": "SLC5A2"
    },
    {
        "id": "6528",
        "name": "SLC5A5"
    },
    {
        "id": "8884",
        "name": "SLC5A6"
    },
    {
        "id": "6529",
        "name": "SLC6A1"
    },
    {
        "id": "6538",
        "name": "SLC6A11"
    },
    {
        "id": "6539",
        "name": "SLC6A12"
    },
    {
        "id": "6540",
        "name": "SLC6A13"
    },
    {
        "id": "11254",
        "name": "SLC6A14"
    },
    {
        "id": "6530",
        "name": "SLC6A2"
    },
    {
        "id": "6531",
        "name": "SLC6A3"
    },
    {
        "id": "6532",
        "name": "SLC6A4"
    },
    {
        "id": "6534",
        "name": "SLC6A7"
    },
    {
        "id": "6535",
        "name": "SLC6A8"
    },
    {
        "id": "6541",
        "name": "SLC7A1"
    },
    {
        "id": "23657",
        "name": "SLC7A11"
    },
    {
        "id": "6542",
        "name": "SLC7A2"
    },
    {
        "id": "84889",
        "name": "SLC7A3"
    },
    {
        "id": "6545",
        "name": "SLC7A4"
    },
    {
        "id": "9056",
        "name": "SLC7A7"
    },
    {
        "id": "23428",
        "name": "SLC7A8"
    },
    {
        "id": "11136",
        "name": "SLC7A9"
    },
    {
        "id": "6546",
        "name": "SLC8A1"
    },
    {
        "id": "6548",
        "name": "SLC9A1"
    },
    {
        "id": "6550",
        "name": "SLC9A3"
    },
    {
        "id": "10599",
        "name": "SLCO1B1"
    },
    {
        "id": "28234",
        "name": "SLCO1B3"
    },
    {
        "id": "11309",
        "name": "SLCO2B1"
    },
    {
        "id": "9748",
        "name": "SLK"
    },
    {
        "id": "4087",
        "name": "SMAD2"
    },
    {
        "id": "8467",
        "name": "SMARCA5"
    },
    {
        "id": "6605",
        "name": "SMARCE1"
    },
    {
        "id": "23293",
        "name": "SMG6"
    },
    {
        "id": "6607",
        "name": "SMN1"
    },
    {
        "id": "6608",
        "name": "SMO"
    },
    {
        "id": "54498",
        "name": "SMOX"
    },
    {
        "id": "6609",
        "name": "SMPD1"
    },
    {
        "id": "55512",
        "name": "SMPD3"
    },
    {
        "id": "55627",
        "name": "SMPD4"
    },
    {
        "id": "6611",
        "name": "SMS"
    },
    {
        "id": "6616",
        "name": "SNAP25"
    },
    {
        "id": "6622",
        "name": "SNCA"
    },
    {
        "id": "54861",
        "name": "SNRK"
    },
    {
        "id": "6626",
        "name": "SNRPA"
    },
    {
        "id": "6633",
        "name": "SNRPD2"
    },
    {
        "id": "4809",
        "name": "SNU13"
    },
    {
        "id": "8724",
        "name": "SNX3"
    },
    {
        "id": "6646",
        "name": "SOAT1"
    },
    {
        "id": "8435",
        "name": "SOAT2"
    },
    {
        "id": "6647",
        "name": "SOD1"
    },
    {
        "id": "6648",
        "name": "SOD2"
    },
    {
        "id": "6652",
        "name": "SORD"
    },
    {
        "id": "50964",
        "name": "SOST"
    },
    {
        "id": "6678",
        "name": "SPARC"
    },
    {
        "id": "404203",
        "name": "SPINK6"
    },
    {
        "id": "84501",
        "name": "SPIRE2"
    },
    {
        "id": "BE0004300",
        "name": "SPO2066"
    },
    {
        "id": "6697",
        "name": "SPR"
    },
    {
        "id": "6711",
        "name": "SPTBN1"
    },
    {
        "id": "10558",
        "name": "SPTLC1"
    },
    {
        "id": "9517",
        "name": "SPTLC2"
    },
    {
        "id": "55304",
        "name": "SPTLC3"
    },
    {
        "id": "BE0001361",
        "name": "SP_0314"
    },
    {
        "id": "BE0002509",
        "name": "SP_1951"
    },
    {
        "id": "6713",
        "name": "SQLE"
    },
    {
        "id": "6714",
        "name": "SRC"
    },
    {
        "id": "6715",
        "name": "SRD5A1"
    },
    {
        "id": "6716",
        "name": "SRD5A2"
    },
    {
        "id": "79644",
        "name": "SRD5A3"
    },
    {
        "id": "6720",
        "name": "SREBF1"
    },
    {
        "id": "6717",
        "name": "SRI"
    },
    {
        "id": "6725",
        "name": "SRMS"
    },
    {
        "id": "6727",
        "name": "SRP14"
    },
    {
        "id": "6733",
        "name": "SRPK2"
    },
    {
        "id": "63826",
        "name": "SRR"
    },
    {
        "id": "6426",
        "name": "SRSF1"
    },
    {
        "id": "6429",
        "name": "SRSF4"
    },
    {
        "id": "6750",
        "name": "SST"
    },
    {
        "id": "6751",
        "name": "SSTR1"
    },
    {
        "id": "6752",
        "name": "SSTR2"
    },
    {
        "id": "6753",
        "name": "SSTR3"
    },
    {
        "id": "6754",
        "name": "SSTR4"
    },
    {
        "id": "6755",
        "name": "SSTR5"
    },
    {
        "id": "6768",
        "name": "ST14"
    },
    {
        "id": "8869",
        "name": "ST3GAL5"
    },
    {
        "id": "55576",
        "name": "STAB2"
    },
    {
        "id": "6774",
        "name": "STAT3"
    },
    {
        "id": "6777",
        "name": "STAT5B"
    },
    {
        "id": "10963",
        "name": "STIP1"
    },
    {
        "id": "6793",
        "name": "STK10"
    },
    {
        "id": "8576",
        "name": "STK16"
    },
    {
        "id": "9263",
        "name": "STK17A"
    },
    {
        "id": "9262",
        "name": "STK17B"
    },
    {
        "id": "8428",
        "name": "STK24"
    },
    {
        "id": "51765",
        "name": "STK26"
    },
    {
        "id": "6788",
        "name": "STK3"
    },
    {
        "id": "202374",
        "name": "STK32A"
    },
    {
        "id": "65975",
        "name": "STK33"
    },
    {
        "id": "140901",
        "name": "STK35"
    },
    {
        "id": "27148",
        "name": "STK36"
    },
    {
        "id": "11329",
        "name": "STK38"
    },
    {
        "id": "23012",
        "name": "STK38L"
    },
    {
        "id": "27347",
        "name": "STK39"
    },
    {
        "id": "81551",
        "name": "STMN4"
    },
    {
        "id": "412",
        "name": "STS"
    },
    {
        "id": "8803",
        "name": "SUCLA2"
    },
    {
        "id": "8802",
        "name": "SUCLG1"
    },
    {
        "id": "8801",
        "name": "SUCLG2"
    },
    {
        "id": "56670",
        "name": "SUCNR1"
    },
    {
        "id": "6817",
        "name": "SULT1A1"
    },
    {
        "id": "27284",
        "name": "SULT1B1"
    },
    {
        "id": "6783",
        "name": "SULT1E1"
    },
    {
        "id": "6822",
        "name": "SULT2A1"
    },
    {
        "id": "6820",
        "name": "SULT2B1"
    },
    {
        "id": "6821",
        "name": "SUOX"
    },
    {
        "id": "9900",
        "name": "SV2A"
    },
    {
        "id": "BE0001493",
        "name": "SVEN_4064"
    },
    {
        "id": "6850",
        "name": "SYK"
    },
    {
        "id": "6853",
        "name": "SYN1"
    },
    {
        "id": "BE0004309",
        "name": "SYN_02013"
    },
    {
        "id": "127833",
        "name": "SYT2"
    },
    {
        "id": "94121",
        "name": "SYTL4"
    },
    {
        "id": "134864",
        "name": "TAAR1"
    },
    {
        "id": "10454",
        "name": "TAB1"
    },
    {
        "id": "6866",
        "name": "TAC3"
    },
    {
        "id": "6869",
        "name": "TACR1"
    },
    {
        "id": "6865",
        "name": "TACR2"
    },
    {
        "id": "6870",
        "name": "TACR3"
    },
    {
        "id": "6876",
        "name": "TAGLN"
    },
    {
        "id": "8407",
        "name": "TAGLN2"
    },
    {
        "id": "57551",
        "name": "TAOK1"
    },
    {
        "id": "9344",
        "name": "TAOK2"
    },
    {
        "id": "51347",
        "name": "TAOK3"
    },
    {
        "id": "6897",
        "name": "TARS1"
    },
    {
        "id": "80222",
        "name": "TARS2"
    },
    {
        "id": "80834",
        "name": "TAS1R2"
    },
    {
        "id": "83756",
        "name": "TAS1R3"
    },
    {
        "id": "50833",
        "name": "TAS2R16"
    },
    {
        "id": "6898",
        "name": "TAT"
    },
    {
        "id": "29110",
        "name": "TBK1"
    },
    {
        "id": "6915",
        "name": "TBXA2R"
    },
    {
        "id": "6916",
        "name": "TBXAS1"
    },
    {
        "id": "6947",
        "name": "TCN1"
    },
    {
        "id": "7006",
        "name": "TEC"
    },
    {
        "id": "7010",
        "name": "TEK"
    },
    {
        "id": "7015",
        "name": "TERT"
    },
    {
        "id": "7016",
        "name": "TESK1"
    },
    {
        "id": "9633",
        "name": "TESMIN"
    },
    {
        "id": "7018",
        "name": "TF"
    },
    {
        "id": "7031",
        "name": "TFF1"
    },
    {
        "id": "7032",
        "name": "TFF2"
    },
    {
        "id": "7035",
        "name": "TFPI"
    },
    {
        "id": "7036",
        "name": "TFR2"
    },
    {
        "id": "7037",
        "name": "TFRC"
    },
    {
        "id": "7040",
        "name": "TGFB1"
    },
    {
        "id": "7043",
        "name": "TGFB3"
    },
    {
        "id": "7046",
        "name": "TGFBR1"
    },
    {
        "id": "7048",
        "name": "TGFBR2"
    },
    {
        "id": "7052",
        "name": "TGM2"
    },
    {
        "id": "7053",
        "name": "TGM3"
    },
    {
        "id": "7054",
        "name": "TH"
    },
    {
        "id": "7056",
        "name": "THBD"
    },
    {
        "id": "79896",
        "name": "THNSL1"
    },
    {
        "id": "7067",
        "name": "THRA"
    },
    {
        "id": "7068",
        "name": "THRB"
    },
    {
        "id": "BE0003128",
        "name": "THYA"
    },
    {
        "id": "7075",
        "name": "TIE1"
    },
    {
        "id": "7078",
        "name": "TIMP3"
    },
    {
        "id": "BE0000458",
        "name": "TK"
    },
    {
        "id": "BE0004499",
        "name": "TK"
    },
    {
        "id": "24271467",
        "name": "TK"
    },
    {
        "id": "BE0003365",
        "name": "TK"
    },
    {
        "id": "BE0001929",
        "name": "TK"
    },
    {
        "id": "BE0000514",
        "name": "TK"
    },
    {
        "id": "7083",
        "name": "TK1"
    },
    {
        "id": "7084",
        "name": "TK2"
    },
    {
        "id": "7086",
        "name": "TKT"
    },
    {
        "id": "BE0002174",
        "name": "TLH6"
    },
    {
        "id": "9874",
        "name": "TLK1"
    },
    {
        "id": "11011",
        "name": "TLK2"
    },
    {
        "id": "7093",
        "name": "TLL2"
    },
    {
        "id": "7097",
        "name": "TLR2"
    },
    {
        "id": "7099",
        "name": "TLR4"
    },
    {
        "id": "51284",
        "name": "TLR7"
    },
    {
        "id": "51311",
        "name": "TLR8"
    },
    {
        "id": "54106",
        "name": "TLR9"
    },
    {
        "id": "55217",
        "name": "TMLHE"
    },
    {
        "id": "7124",
        "name": "TNF"
    },
    {
        "id": "7130",
        "name": "TNFAIP6"
    },
    {
        "id": "8795",
        "name": "TNFRSF10B"
    },
    {
        "id": "8792",
        "name": "TNFRSF11A"
    },
    {
        "id": "7132",
        "name": "TNFRSF1A"
    },
    {
        "id": "7133",
        "name": "TNFRSF1B"
    },
    {
        "id": "943",
        "name": "TNFRSF8"
    },
    {
        "id": "3604",
        "name": "TNFRSF9"
    },
    {
        "id": "8600",
        "name": "TNFSF11"
    },
    {
        "id": "10673",
        "name": "TNFSF13B"
    },
    {
        "id": "23043",
        "name": "TNIK"
    },
    {
        "id": "8711",
        "name": "TNK1"
    },
    {
        "id": "10188",
        "name": "TNK2"
    },
    {
        "id": "7134",
        "name": "TNNC1"
    },
    {
        "id": "7125",
        "name": "TNNC2"
    },
    {
        "id": "7137",
        "name": "TNNI3"
    },
    {
        "id": "51086",
        "name": "TNNI3K"
    },
    {
        "id": "116447",
        "name": "TOP1MT"
    },
    {
        "id": "7153",
        "name": "TOP2A"
    },
    {
        "id": "7155",
        "name": "TOP2B"
    },
    {
        "id": "7150",
        "name": "TOP\u00a01.00"
    },
    {
        "id": "7157",
        "name": "TP53"
    },
    {
        "id": "7161",
        "name": "TP73"
    },
    {
        "id": "7165",
        "name": "TPD52L2"
    },
    {
        "id": "7166",
        "name": "TPH1"
    },
    {
        "id": "121278",
        "name": "TPH2"
    },
    {
        "id": "BE0001256",
        "name": "TPI"
    },
    {
        "id": "7167",
        "name": "TPI1"
    },
    {
        "id": "27010",
        "name": "TPK1"
    },
    {
        "id": "7168",
        "name": "TPM1"
    },
    {
        "id": "7170",
        "name": "TPM3"
    },
    {
        "id": "7171",
        "name": "TPM4"
    },
    {
        "id": "7172",
        "name": "TPMT"
    },
    {
        "id": "7173",
        "name": "TPO"
    },
    {
        "id": "BE0001551",
        "name": "TPR"
    },
    {
        "id": "7178",
        "name": "TPT1"
    },
    {
        "id": "BE0001895",
        "name": "TP_0574"
    },
    {
        "id": "58485",
        "name": "TRAPPC1"
    },
    {
        "id": "27095",
        "name": "TRAPPC3"
    },
    {
        "id": "51399",
        "name": "TRAPPC4"
    },
    {
        "id": "126003",
        "name": "TRAPPC5"
    },
    {
        "id": "79090",
        "name": "TRAPPC6A"
    },
    {
        "id": "122553",
        "name": "TRAPPC6B"
    },
    {
        "id": "1787",
        "name": "TRDMT1"
    },
    {
        "id": "54210",
        "name": "TREM1"
    },
    {
        "id": "7201",
        "name": "TRHR"
    },
    {
        "id": "10206",
        "name": "TRIM13"
    },
    {
        "id": "8989",
        "name": "TRPA1"
    },
    {
        "id": "54795",
        "name": "TRPM4"
    },
    {
        "id": "54822",
        "name": "TRPM7"
    },
    {
        "id": "79054",
        "name": "TRPM8"
    },
    {
        "id": "7442",
        "name": "TRPV1"
    },
    {
        "id": "51393",
        "name": "TRPV2"
    },
    {
        "id": "162514",
        "name": "TRPV3"
    },
    {
        "id": "59341",
        "name": "TRPV4"
    },
    {
        "id": "7253",
        "name": "TSHR"
    },
    {
        "id": "706",
        "name": "TSPO"
    },
    {
        "id": "83942",
        "name": "TSSK1B"
    },
    {
        "id": "7263",
        "name": "TST"
    },
    {
        "id": "7272",
        "name": "TTK"
    },
    {
        "id": "26140",
        "name": "TTLL3"
    },
    {
        "id": "7276",
        "name": "TTR"
    },
    {
        "id": "BE0004381",
        "name": "TT_C0153"
    },
    {
        "id": "BE0004380",
        "name": "TT_C0155"
    },
    {
        "id": "BE0002984",
        "name": "TT_C0779"
    },
    {
        "id": "BE0003981",
        "name": "TT_C0834"
    },
    {
        "id": "BE0003107",
        "name": "TT_P0036"
    },
    {
        "id": "BE0002502",
        "name": "TT_P0059"
    },
    {
        "id": "7275",
        "name": "TUB"
    },
    {
        "id": "BE0000646",
        "name": "TUB1"
    },
    {
        "id": "BE0000283",
        "name": "TUB2"
    },
    {
        "id": "7846",
        "name": "TUBA1A"
    },
    {
        "id": "10376",
        "name": "TUBA1B"
    },
    {
        "id": "84790",
        "name": "TUBA1C"
    },
    {
        "id": "7278",
        "name": "TUBA3C"
    },
    {
        "id": "112714",
        "name": "TUBA3E"
    },
    {
        "id": "7277",
        "name": "TUBA4A"
    },
    {
        "id": "51807",
        "name": "TUBA8"
    },
    {
        "id": "203068",
        "name": "TUBB"
    },
    {
        "id": "81027",
        "name": "TUBB1"
    },
    {
        "id": "7280",
        "name": "TUBB2A"
    },
    {
        "id": "347733",
        "name": "TUBB2B"
    },
    {
        "id": "10381",
        "name": "TUBB3"
    },
    {
        "id": "10382",
        "name": "TUBB4A"
    },
    {
        "id": "10383",
        "name": "TUBB4B"
    },
    {
        "id": "84617",
        "name": "TUBB6"
    },
    {
        "id": "51174",
        "name": "TUBD1"
    },
    {
        "id": "51175",
        "name": "TUBE1"
    },
    {
        "id": "7283",
        "name": "TUBG1"
    },
    {
        "id": "7284",
        "name": "TUFM"
    },
    {
        "id": "7294",
        "name": "TXK"
    },
    {
        "id": "7295",
        "name": "TXN"
    },
    {
        "id": "51060",
        "name": "TXNDC12"
    },
    {
        "id": "7296",
        "name": "TXNRD1"
    },
    {
        "id": "10587",
        "name": "TXNRD2"
    },
    {
        "id": "7297",
        "name": "TYK2"
    },
    {
        "id": "1890",
        "name": "TYMP"
    },
    {
        "id": "7298",
        "name": "TYMS"
    },
    {
        "id": "7299",
        "name": "TYR"
    },
    {
        "id": "7301",
        "name": "TYRO3"
    },
    {
        "id": "BE0003078",
        "name": "Tcdut"
    },
    {
        "id": "BE0003145",
        "name": "Tfu_1933"
    },
    {
        "id": "6675",
        "name": "UAP1"
    },
    {
        "id": "7317",
        "name": "UBA1"
    },
    {
        "id": "7314",
        "name": "UBB"
    },
    {
        "id": "7316",
        "name": "UBC"
    },
    {
        "id": "7322",
        "name": "UBE2D2"
    },
    {
        "id": "23352",
        "name": "UBR4"
    },
    {
        "id": "7345",
        "name": "UCHL1"
    },
    {
        "id": "7371",
        "name": "UCK2"
    },
    {
        "id": "54963",
        "name": "UCKL1"
    },
    {
        "id": "7357",
        "name": "UGCG"
    },
    {
        "id": "7358",
        "name": "UGDH"
    },
    {
        "id": "BE0003063",
        "name": "UGI"
    },
    {
        "id": "54658",
        "name": "UGT1A1"
    },
    {
        "id": "54600",
        "name": "UGT1A9"
    },
    {
        "id": "133688",
        "name": "UGT3A1"
    },
    {
        "id": "BE0009326",
        "name": "UL122"
    },
    {
        "id": "BE0009327",
        "name": "UL122"
    },
    {
        "id": "2703370",
        "name": "UL2"
    },
    {
        "id": "2703462",
        "name": "UL30"
    },
    {
        "id": "BE0000343",
        "name": "UL54"
    },
    {
        "id": "BE0001331",
        "name": "UL80"
    },
    {
        "id": "BE0003485",
        "name": "UL97"
    },
    {
        "id": "BE0003484",
        "name": "UL97"
    },
    {
        "id": "8408",
        "name": "ULK1"
    },
    {
        "id": "9706",
        "name": "ULK2"
    },
    {
        "id": "25989",
        "name": "ULK3"
    },
    {
        "id": "7372",
        "name": "UMPS"
    },
    {
        "id": "7374",
        "name": "UNG"
    },
    {
        "id": "7378",
        "name": "UPP1"
    },
    {
        "id": "29796",
        "name": "UQCR10"
    },
    {
        "id": "10975",
        "name": "UQCR11"
    },
    {
        "id": "7381",
        "name": "UQCRB"
    },
    {
        "id": "7384",
        "name": "UQCRC1"
    },
    {
        "id": "7385",
        "name": "UQCRC2"
    },
    {
        "id": "7386",
        "name": "UQCRFS1"
    },
    {
        "id": "7388",
        "name": "UQCRH"
    },
    {
        "id": "27089",
        "name": "UQCRQ"
    },
    {
        "id": "7389",
        "name": "UROD"
    },
    {
        "id": "9097",
        "name": "USP14"
    },
    {
        "id": "7402",
        "name": "UTRN"
    },
    {
        "id": "BE0001401",
        "name": "V-SRC"
    },
    {
        "id": "BE0004321",
        "name": "V-SRC"
    },
    {
        "id": "6843",
        "name": "VAMP1"
    },
    {
        "id": "6844",
        "name": "VAMP2"
    },
    {
        "id": "7407",
        "name": "VARS1"
    },
    {
        "id": "7412",
        "name": "VCAM1"
    },
    {
        "id": "1462",
        "name": "VCAN"
    },
    {
        "id": "7415",
        "name": "VCP"
    },
    {
        "id": "7416",
        "name": "VDAC1"
    },
    {
        "id": "7417",
        "name": "VDAC2"
    },
    {
        "id": "7419",
        "name": "VDAC3"
    },
    {
        "id": "7421",
        "name": "VDR"
    },
    {
        "id": "7422",
        "name": "VEGFA"
    },
    {
        "id": "7423",
        "name": "VEGFB"
    },
    {
        "id": "7431",
        "name": "VIM"
    },
    {
        "id": "79001",
        "name": "VKORC1"
    },
    {
        "id": "154807",
        "name": "VKORC1L1"
    },
    {
        "id": "7436",
        "name": "VLDLR"
    },
    {
        "id": "BE0008661",
        "name": "VMAT2"
    },
    {
        "id": "7448",
        "name": "VTN"
    },
    {
        "id": "7450",
        "name": "VWF"
    },
    {
        "id": "7453",
        "name": "WARS1"
    },
    {
        "id": "10352",
        "name": "WARS2"
    },
    {
        "id": "7454",
        "name": "WAS"
    },
    {
        "id": "7465",
        "name": "WEE1"
    },
    {
        "id": "7498",
        "name": "XDH"
    },
    {
        "id": "331",
        "name": "XIAP"
    },
    {
        "id": "7514",
        "name": "XPO1"
    },
    {
        "id": "7518",
        "name": "XRCC4"
    },
    {
        "id": "8565",
        "name": "YARS1"
    },
    {
        "id": "51067",
        "name": "YARS2"
    },
    {
        "id": "7525",
        "name": "YES1"
    },
    {
        "id": "BE0003253",
        "name": "YPTB1668"
    },
    {
        "id": "7529",
        "name": "YWHAB"
    },
    {
        "id": "7531",
        "name": "YWHAE"
    },
    {
        "id": "7533",
        "name": "YWHAH"
    },
    {
        "id": "10971",
        "name": "YWHAQ"
    },
    {
        "id": "7534",
        "name": "YWHAZ"
    },
    {
        "id": "7535",
        "name": "ZAP70"
    },
    {
        "id": "7544",
        "name": "ZFY"
    },
    {
        "id": "7791",
        "name": "ZYX"
    },
    {
        "id": "BE0004102",
        "name": "a"
    },
    {
        "id": "BE0002686",
        "name": "aac(6')-Ii"
    },
    {
        "id": "BE0001953",
        "name": "aacC1"
    },
    {
        "id": "BE0004363",
        "name": "aauB"
    },
    {
        "id": "BE0001766",
        "name": "abfA"
    },
    {
        "id": "BE0002977",
        "name": "abfD"
    },
    {
        "id": "BE0001378",
        "name": "acdS"
    },
    {
        "id": "BE0003340",
        "name": "acma"
    },
    {
        "id": "BE0004336",
        "name": "actII"
    },
    {
        "id": "BE0001892",
        "name": "adh"
    },
    {
        "id": "BE0001254",
        "name": "adk"
    },
    {
        "id": "BE0001255",
        "name": "adk"
    },
    {
        "id": "BE0004225",
        "name": "afaE3"
    },
    {
        "id": "BE0002819",
        "name": "aguA"
    },
    {
        "id": "BE0003126",
        "name": "aldH"
    },
    {
        "id": "BE0001369",
        "name": "algC"
    },
    {
        "id": "BE0002671",
        "name": "algQ1"
    },
    {
        "id": "BE0002672",
        "name": "algQ2"
    },
    {
        "id": "BE0001968",
        "name": "alpha-LP"
    },
    {
        "id": "BE0001344",
        "name": "alr"
    },
    {
        "id": "BE0000806",
        "name": "alr"
    },
    {
        "id": "BE0002728",
        "name": "alr1529"
    },
    {
        "id": "BE0003285",
        "name": "alr3165"
    },
    {
        "id": "BE0003326",
        "name": "aly"
    },
    {
        "id": "BE0001430",
        "name": "ampC"
    },
    {
        "id": "BE0001444",
        "name": "ampC"
    },
    {
        "id": "BE0002830",
        "name": "ams"
    },
    {
        "id": "BE0001625",
        "name": "amy"
    },
    {
        "id": "BE0001762",
        "name": "amyA"
    },
    {
        "id": "BE0001639",
        "name": "amyM"
    },
    {
        "id": "BE0001500",
        "name": "amyP"
    },
    {
        "id": "BE0001532",
        "name": "ansB"
    },
    {
        "id": "BE0004015",
        "name": "appA"
    },
    {
        "id": "BE0003743",
        "name": "apr"
    },
    {
        "id": "BE0004340",
        "name": "argF"
    },
    {
        "id": "BE0001759",
        "name": "aroA"
    },
    {
        "id": "BE0001691",
        "name": "aroB"
    },
    {
        "id": "BE0001690",
        "name": "aroD"
    },
    {
        "id": "BE0002709",
        "name": "aroH"
    },
    {
        "id": "BE0001885",
        "name": "arsA"
    },
    {
        "id": "BE0001321",
        "name": "arsC"
    },
    {
        "id": "BE0001325",
        "name": "aspS"
    },
    {
        "id": "BE0003785",
        "name": "assT"
    },
    {
        "id": "BE0001846",
        "name": "atpE"
    },
    {
        "id": "BE0004088",
        "name": "atpH"
    },
    {
        "id": "BE0003185",
        "name": "atsK"
    },
    {
        "id": "BE0003314",
        "name": "bca"
    },
    {
        "id": "BE0002983",
        "name": "benC"
    },
    {
        "id": "BE0001711",
        "name": "bfr"
    },
    {
        "id": "BE0002829",
        "name": "bgl3"
    },
    {
        "id": "BE0001810",
        "name": "bglA"
    },
    {
        "id": "BE0001804",
        "name": "bglA"
    },
    {
        "id": "BE0001755",
        "name": "bla"
    },
    {
        "id": "BE0003189",
        "name": "blaB"
    },
    {
        "id": "BE0002694",
        "name": "blaB1"
    },
    {
        "id": "BE0002693",
        "name": "blaFEZ-1"
    },
    {
        "id": "BE0003881",
        "name": "blaIMP-1"
    },
    {
        "id": "BE0001655",
        "name": "blaR1"
    },
    {
        "id": "BE0009746",
        "name": "blaSHV"
    },
    {
        "id": "14678525",
        "name": "blaVIM-2"
    },
    {
        "id": "BE0001426",
        "name": "blm"
    },
    {
        "id": "BE0001263",
        "name": "botB"
    },
    {
        "id": "BE0009354",
        "name": "botD"
    },
    {
        "id": "BE0009355",
        "name": "botE"
    },
    {
        "id": "BE0009356",
        "name": "botF"
    },
    {
        "id": "BE0009357",
        "name": "botG"
    },
    {
        "id": "BE0002596",
        "name": "bphA4"
    },
    {
        "id": "BE0001308",
        "name": "bphC"
    },
    {
        "id": "BE0001672",
        "name": "bphC"
    },
    {
        "id": "BE0004302",
        "name": "bpuJIR"
    },
    {
        "id": "BE0003364",
        "name": "bsd"
    },
    {
        "id": "BE0001531",
        "name": "budB"
    },
    {
        "id": "BE0002635",
        "name": "budC"
    },
    {
        "id": "BE0002823",
        "name": "c-des"
    },
    {
        "id": "BE0002850",
        "name": "cag-alfa"
    },
    {
        "id": "BE0001994",
        "name": "cagA"
    },
    {
        "id": "BE0002974",
        "name": "camA"
    },
    {
        "id": "BE0001246",
        "name": "camC"
    },
    {
        "id": "BE0002845",
        "name": "camK"
    },
    {
        "id": "BE0002807",
        "name": "carA"
    },
    {
        "id": "BE0003803",
        "name": "carAa"
    },
    {
        "id": "BE0002848",
        "name": "carC"
    },
    {
        "id": "BE0001459",
        "name": "catA"
    },
    {
        "id": "BE0003899",
        "name": "cbaA"
    },
    {
        "id": "BE0003900",
        "name": "cbaB"
    },
    {
        "id": "BE0002818",
        "name": "cbhA"
    },
    {
        "id": "BE0001398",
        "name": "cbiF"
    },
    {
        "id": "BE0001770",
        "name": "cc4"
    },
    {
        "id": "BE0002744",
        "name": "cca"
    },
    {
        "id": "BE0001530",
        "name": "ccpA"
    },
    {
        "id": "BE0001212",
        "name": "ccrA"
    },
    {
        "id": "BE0004169",
        "name": "ceaC"
    },
    {
        "id": "BE0003275",
        "name": "ceaS"
    },
    {
        "id": "BE0001833",
        "name": "cefE"
    },
    {
        "id": "BE0001219",
        "name": "cel5A"
    },
    {
        "id": "BE0002017",
        "name": "celA"
    },
    {
        "id": "BE0002060",
        "name": "celB"
    },
    {
        "id": "BE0002740",
        "name": "celB"
    },
    {
        "id": "BE0003249",
        "name": "celC307"
    },
    {
        "id": "BE0001335",
        "name": "celCCF"
    },
    {
        "id": "BE0001539",
        "name": "celCCG"
    },
    {
        "id": "BE0003251",
        "name": "celD"
    },
    {
        "id": "BE0002059",
        "name": "celS"
    },
    {
        "id": "BE0002741",
        "name": "cenC"
    },
    {
        "id": "BE0001339",
        "name": "cex"
    },
    {
        "id": "BE0002352",
        "name": "cft"
    },
    {
        "id": "BE0002670",
        "name": "cgiA"
    },
    {
        "id": "BE0001371",
        "name": "cgt"
    },
    {
        "id": "BE0001605",
        "name": "chb"
    },
    {
        "id": "BE0001649",
        "name": "chiA"
    },
    {
        "id": "BE0001584",
        "name": "chiB"
    },
    {
        "id": "BE0004374",
        "name": "chiB"
    },
    {
        "id": "BE0003135",
        "name": "chiB1"
    },
    {
        "id": "BE0001550",
        "name": "choA"
    },
    {
        "id": "BE0001230",
        "name": "choB"
    },
    {
        "id": "BE0003177",
        "name": "chqB"
    },
    {
        "id": "BE0004282",
        "name": "chxA"
    },
    {
        "id": "BE0004173",
        "name": "cia"
    },
    {
        "id": "BE0003352",
        "name": "cinA"
    },
    {
        "id": "BE0002685",
        "name": "cit"
    },
    {
        "id": "BE0001941",
        "name": "citA"
    },
    {
        "id": "BE0001976",
        "name": "clcA"
    },
    {
        "id": "BE0001299",
        "name": "clcD"
    },
    {
        "id": "BE0001943",
        "name": "coaD"
    },
    {
        "id": "BE0002528",
        "name": "cobA"
    },
    {
        "id": "BE0001816",
        "name": "cobH"
    },
    {
        "id": "BE0001413",
        "name": "cocE"
    },
    {
        "id": "BE0004195",
        "name": "cphA"
    },
    {
        "id": "BE0003176",
        "name": "cpo"
    },
    {
        "id": "BE0001985",
        "name": "cpo"
    },
    {
        "id": "BE0003866",
        "name": "crtM"
    },
    {
        "id": "BE0002970",
        "name": "cry"
    },
    {
        "id": "BE0001314",
        "name": "cs1"
    },
    {
        "id": "BE0001508",
        "name": "csd"
    },
    {
        "id": "BE0001416",
        "name": "cslA"
    },
    {
        "id": "BE0001329",
        "name": "cslB"
    },
    {
        "id": "BE0001697",
        "name": "csn"
    },
    {
        "id": "BE0002073",
        "name": "cst-II"
    },
    {
        "id": "BE0004163",
        "name": "ctaC"
    },
    {
        "id": "BE0004056",
        "name": "ctaC"
    },
    {
        "id": "BE0004055",
        "name": "ctaD"
    },
    {
        "id": "BE0004162",
        "name": "ctaDII"
    },
    {
        "id": "BE0004186",
        "name": "ctxB"
    },
    {
        "id": "BE0002742",
        "name": "cumD"
    },
    {
        "id": "BE0002794",
        "name": "cyaB2"
    },
    {
        "id": "BE0001998",
        "name": "cybC"
    },
    {
        "id": "BE0001805",
        "name": "cycA"
    },
    {
        "id": "BE0001727",
        "name": "cycA"
    },
    {
        "id": "BE0004167",
        "name": "cycA"
    },
    {
        "id": "BE0003052",
        "name": "cycM"
    },
    {
        "id": "3718637",
        "name": "cycP"
    },
    {
        "id": "BE0003054",
        "name": "cyd"
    },
    {
        "id": "BE0002916",
        "name": "cytc3"
    },
    {
        "id": "BE0004262",
        "name": "daaA"
    },
    {
        "id": "BE0003356",
        "name": "dac"
    },
    {
        "id": "BE0002475",
        "name": "dacA"
    },
    {
        "id": "BE0001297",
        "name": "dapD"
    },
    {
        "id": "BE0001509",
        "name": "dat"
    },
    {
        "id": "BE0004095",
        "name": "ddl"
    },
    {
        "id": "BE0004391",
        "name": "def"
    },
    {
        "id": "BE0003141",
        "name": "def"
    },
    {
        "id": "BE0001651",
        "name": "def"
    },
    {
        "id": "BE0002535",
        "name": "def"
    },
    {
        "id": "BE0003361",
        "name": "dehB"
    },
    {
        "id": "BE0002590",
        "name": "desIV"
    },
    {
        "id": "BE0004248",
        "name": "dfrA"
    },
    {
        "id": "BE0009124",
        "name": "dfrB"
    },
    {
        "id": "BE0001506",
        "name": "dgdA"
    },
    {
        "id": "BE0001311",
        "name": "dhaK"
    },
    {
        "id": "BE0001613",
        "name": "dhlB"
    },
    {
        "id": "BE0002618",
        "name": "dkgA"
    },
    {
        "id": "BE0002961",
        "name": "dmg"
    },
    {
        "id": "BE0001485",
        "name": "dmsA"
    },
    {
        "id": "BE0001712",
        "name": "dnrK"
    },
    {
        "id": "BE0003955",
        "name": "doxG"
    },
    {
        "id": "BE0001544",
        "name": "draA"
    },
    {
        "id": "BE0003956",
        "name": "dszB"
    },
    {
        "id": "BE0001477",
        "name": "eltB"
    },
    {
        "id": "BE0003250",
        "name": "engF"
    },
    {
        "id": "BE0001737",
        "name": "eno"
    },
    {
        "id": "BE0001947",
        "name": "entB"
    },
    {
        "id": "BE0002816",
        "name": "env"
    },
    {
        "id": "BE0002838",
        "name": "env"
    },
    {
        "id": "BE0003344",
        "name": "env"
    },
    {
        "id": "BE0003019",
        "name": "epiD"
    },
    {
        "id": "BE0001638",
        "name": "eryF"
    },
    {
        "id": "BE0001266",
        "name": "est"
    },
    {
        "id": "BE0001897",
        "name": "estA"
    },
    {
        "id": "BE0003048",
        "name": "estB"
    },
    {
        "id": "BE0002680",
        "name": "fabH"
    },
    {
        "id": "BE0009125",
        "name": "fabI"
    },
    {
        "id": "BE0004347",
        "name": "fabI"
    },
    {
        "id": "BE0004368",
        "name": "fabK"
    },
    {
        "id": "BE0003757",
        "name": "fabZ"
    },
    {
        "id": "BE0004257",
        "name": "fadA"
    },
    {
        "id": "BE0004256",
        "name": "fadB"
    },
    {
        "id": "BE0001722",
        "name": "fae"
    },
    {
        "id": "BE0003263",
        "name": "fbpA"
    },
    {
        "id": "BE0002495",
        "name": "fcbB"
    },
    {
        "id": "BE0002575",
        "name": "fcbC"
    },
    {
        "id": "BE0002001",
        "name": "fccA"
    },
    {
        "id": "BE0001792",
        "name": "ffh"
    },
    {
        "id": "BE0002985",
        "name": "fkbI"
    },
    {
        "id": "BE0003167",
        "name": "flA"
    },
    {
        "id": "BE0003030",
        "name": "fldA"
    },
    {
        "id": "BE0003032",
        "name": "fni"
    },
    {
        "id": "BE0004138",
        "name": "folA"
    },
    {
        "id": "BE0001614",
        "name": "folA"
    },
    {
        "id": "BE0001289",
        "name": "folB"
    },
    {
        "id": "BE0003133",
        "name": "folP"
    },
    {
        "id": "BE0001492",
        "name": "fpgS"
    },
    {
        "id": "BE0004132",
        "name": "fpr"
    },
    {
        "id": "BE0002964",
        "name": "fpr"
    },
    {
        "id": "BE0001310",
        "name": "frc"
    },
    {
        "id": "BE0004046",
        "name": "frdA"
    },
    {
        "id": "BE0004047",
        "name": "frdB"
    },
    {
        "id": "BE0004048",
        "name": "frdC"
    },
    {
        "id": "BE0002629",
        "name": "frp"
    },
    {
        "id": "BE0001796",
        "name": "fusA"
    },
    {
        "id": "BE0004243",
        "name": "gag"
    },
    {
        "id": "BE0003437",
        "name": "gag"
    },
    {
        "id": "BE0002726",
        "name": "gag"
    },
    {
        "id": "BE0001210",
        "name": "gag-pol"
    },
    {
        "id": "BE0004120",
        "name": "gag-pol"
    },
    {
        "id": "BE0001732",
        "name": "gag-pol"
    },
    {
        "id": "BE0002050",
        "name": "gag-pol"
    },
    {
        "id": "BE0003804",
        "name": "gag-pol"
    },
    {
        "id": "BE0000787",
        "name": "gag-pol"
    },
    {
        "id": "BE0001342",
        "name": "gag-pol"
    },
    {
        "id": "BE0001781",
        "name": "gag-pol"
    },
    {
        "id": "155348",
        "name": "gag-pol"
    },
    {
        "id": "BE0001265",
        "name": "gag-pol"
    },
    {
        "id": "BE0002617",
        "name": "galE"
    },
    {
        "id": "BE0002844",
        "name": "galM"
    },
    {
        "id": "BE0002606",
        "name": "gap"
    },
    {
        "id": "BE0002655",
        "name": "gcdA"
    },
    {
        "id": "BE0001728",
        "name": "gdhB"
    },
    {
        "id": "BE0001552",
        "name": "gfo"
    },
    {
        "id": "BE0001771",
        "name": "glmS"
    },
    {
        "id": "BE0003075",
        "name": "glmU"
    },
    {
        "id": "BE0002555",
        "name": "glnA1"
    },
    {
        "id": "BE0002690",
        "name": "gltA"
    },
    {
        "id": "BE0002849",
        "name": "gltS"
    },
    {
        "id": "BE0000398",
        "name": "gp41"
    },
    {
        "id": "BE0004307",
        "name": "gpmA"
    },
    {
        "id": "BE0001227",
        "name": "gpmI"
    },
    {
        "id": "BE0002817",
        "name": "gtfA"
    },
    {
        "id": "BE0003321",
        "name": "gtfD"
    },
    {
        "id": "BE0002047",
        "name": "guaB"
    },
    {
        "id": "BE0008385",
        "name": "gyrA"
    },
    {
        "id": "BE0003724",
        "name": "gyrA"
    },
    {
        "id": "BE0003725",
        "name": "gyrB"
    },
    {
        "id": "BE0002388",
        "name": "gyrB"
    },
    {
        "id": "BE0002000",
        "name": "hasB"
    },
    {
        "id": "BE0001799",
        "name": "hcp"
    },
    {
        "id": "BE0003985",
        "name": "hdaH"
    },
    {
        "id": "BE0001981",
        "name": "hemB"
    },
    {
        "id": "BE0001561",
        "name": "hemL"
    },
    {
        "id": "BE0003173",
        "name": "hex"
    },
    {
        "id": "BE0002523",
        "name": "hhaIM"
    },
    {
        "id": "BE0003318",
        "name": "hheC"
    },
    {
        "id": "BE0003972",
        "name": "hia"
    },
    {
        "id": "BE0004086",
        "name": "hld"
    },
    {
        "id": "BE0001964",
        "name": "hlgB"
    },
    {
        "id": "BE0002832",
        "name": "hmuO"
    },
    {
        "id": "BE0001736",
        "name": "hylB"
    },
    {
        "id": "BE0001725",
        "name": "hyuA"
    },
    {
        "id": "BE0001239",
        "name": "icd"
    },
    {
        "id": "BE0000804",
        "name": "ileS"
    },
    {
        "id": "BE0004152",
        "name": "ipdC"
    },
    {
        "id": "BE0003274",
        "name": "ipdC"
    },
    {
        "id": "BE0003021",
        "name": "isiB"
    },
    {
        "id": "BE0003022",
        "name": "isiB"
    },
    {
        "id": "BE0001588",
        "name": "kaiB"
    },
    {
        "id": "BE0001786",
        "name": "katA"
    },
    {
        "id": "BE0001355",
        "name": "kcsA"
    },
    {
        "id": "BE0001522",
        "name": "kpsU"
    },
    {
        "id": "BE0001607",
        "name": "ksi"
    },
    {
        "id": "BE0001236",
        "name": "ksi"
    },
    {
        "id": "BE0002732",
        "name": "kynU"
    },
    {
        "id": "BE0001435",
        "name": "lacG"
    },
    {
        "id": "BE0001699",
        "name": "leuB"
    },
    {
        "id": "BE0002871",
        "name": "lgtC"
    },
    {
        "id": "BE0002699",
        "name": "lgtC"
    },
    {
        "id": "BE0001775",
        "name": "lhyD"
    },
    {
        "id": "BE0003273",
        "name": "licC"
    },
    {
        "id": "BE0001865",
        "name": "limA"
    },
    {
        "id": "BE0003764",
        "name": "lip"
    },
    {
        "id": "BE0001636",
        "name": "lip"
    },
    {
        "id": "BE0002986",
        "name": "lpd"
    },
    {
        "id": "BE0002616",
        "name": "lpdV"
    },
    {
        "id": "BE0004235",
        "name": "luxF"
    },
    {
        "id": "BE0001790",
        "name": "lytA"
    },
    {
        "id": "BE0002980",
        "name": "m-6"
    },
    {
        "id": "BE0003046",
        "name": "malE"
    },
    {
        "id": "BE0001911",
        "name": "manA"
    },
    {
        "id": "BE0001319",
        "name": "map"
    },
    {
        "id": "BE0004187",
        "name": "map"
    },
    {
        "id": "BE0004355",
        "name": "mauA"
    },
    {
        "id": "BE0004354",
        "name": "mauB"
    },
    {
        "id": "BE0002746",
        "name": "mauC"
    },
    {
        "id": "BE0003208",
        "name": "mdeA"
    },
    {
        "id": "BE0008391",
        "name": "mdfA"
    },
    {
        "id": "BE0001891",
        "name": "mdh"
    },
    {
        "id": "BE0001498",
        "name": "mdlA"
    },
    {
        "id": "BE0003028",
        "name": "mdlB"
    },
    {
        "id": "BE0001286",
        "name": "mdlC"
    },
    {
        "id": "BE0008392",
        "name": "mdtK"
    },
    {
        "id": "BE0008389",
        "name": "mecA"
    },
    {
        "id": "BE0003577",
        "name": "mecA"
    },
    {
        "id": "BE0003618",
        "name": "mecA"
    },
    {
        "id": "BE0002391",
        "name": "mecA"
    },
    {
        "id": "BE0004273",
        "name": "menC"
    },
    {
        "id": "BE0002973",
        "name": "metF"
    },
    {
        "id": "BE0002381",
        "name": "metG"
    },
    {
        "id": "BE0002795",
        "name": "mll3241"
    },
    {
        "id": "BE0003331",
        "name": "mmoX"
    },
    {
        "id": "BE0002510",
        "name": "mndD"
    },
    {
        "id": "BE0003313",
        "name": "moaA"
    },
    {
        "id": "BE0001458",
        "name": "mop"
    },
    {
        "id": "BE0003035",
        "name": "morB"
    },
    {
        "id": "BE0004356",
        "name": "moxG"
    },
    {
        "id": "BE0002667",
        "name": "mrsA"
    },
    {
        "id": "BE0002990",
        "name": "mrsD"
    },
    {
        "id": "885251",
        "name": "mshD"
    },
    {
        "id": "BE0003123",
        "name": "mtdA"
    },
    {
        "id": "BE0003741",
        "name": "mtnW"
    },
    {
        "id": "BE0002972",
        "name": "murB"
    },
    {
        "id": "BE0004383",
        "name": "murI"
    },
    {
        "id": "BE0001461",
        "name": "murI"
    },
    {
        "id": "BE0001856",
        "name": "mvaA"
    },
    {
        "id": "BE0002703",
        "name": "mvaS"
    },
    {
        "id": "BE0002695",
        "name": "mvaS"
    },
    {
        "id": "BE0001673",
        "name": "mxiM"
    },
    {
        "id": "BE0003351",
        "name": "nagJ"
    },
    {
        "id": "BE0001621",
        "name": "nanH"
    },
    {
        "id": "BE0001841",
        "name": "ncsA"
    },
    {
        "id": "BE0004080",
        "name": "ncsA"
    },
    {
        "id": "BE0001946",
        "name": "nedA"
    },
    {
        "id": "BE0004237",
        "name": "nef"
    },
    {
        "id": "BE0001622",
        "name": "neuA"
    },
    {
        "id": "BE0003031",
        "name": "nfsB"
    },
    {
        "id": "BE0004303",
        "name": "nir"
    },
    {
        "id": "BE0001495",
        "name": "nirK"
    },
    {
        "id": "BE0003053",
        "name": "nirM"
    },
    {
        "id": "BE0001802",
        "name": "nirS"
    },
    {
        "id": "BE0004092",
        "name": "nisZ"
    },
    {
        "id": "BE0001483",
        "name": "nos"
    },
    {
        "id": "BE0002993",
        "name": "nosZ"
    },
    {
        "id": "BE0003243",
        "name": "nplT"
    },
    {
        "id": "BE0003783",
        "name": "npr"
    },
    {
        "id": "BE0001346",
        "name": "nprS"
    },
    {
        "id": "BE0004379",
        "name": "nrfC"
    },
    {
        "id": "BE0002809",
        "name": "nspA"
    },
    {
        "id": "BE0001676",
        "name": "ntd"
    },
    {
        "id": "BE0001977",
        "name": "nuc"
    },
    {
        "id": "BE0001763",
        "name": "odcI"
    },
    {
        "id": "BE0003941",
        "name": "ompC"
    },
    {
        "id": "BE0002499",
        "name": "onr"
    },
    {
        "id": "BE0001535",
        "name": "opd"
    },
    {
        "id": "BE0004036",
        "name": "opd"
    },
    {
        "id": "BE0003349",
        "name": "opdA"
    },
    {
        "id": "BE0001633",
        "name": "opmA"
    },
    {
        "id": "BE0003357",
        "name": "oxoO"
    },
    {
        "id": "BE0004038",
        "name": "pab"
    },
    {
        "id": "BE0003806",
        "name": "pac"
    },
    {
        "id": "BE0002500",
        "name": "pam"
    },
    {
        "id": "BE0002982",
        "name": "pamO"
    },
    {
        "id": "BE0003977",
        "name": "papC"
    },
    {
        "id": "BE0008383",
        "name": "parC"
    },
    {
        "id": "BE0003726",
        "name": "parC"
    },
    {
        "id": "BE0003727",
        "name": "parE"
    },
    {
        "id": "BE0001904",
        "name": "pbp"
    },
    {
        "id": "BE0004313",
        "name": "pbp1b"
    },
    {
        "id": "BE0004180",
        "name": "pbp2"
    },
    {
        "id": "BE0004654",
        "name": "pbp3"
    },
    {
        "id": "BE0004643",
        "name": "pbp3"
    },
    {
        "id": "BE0003622",
        "name": "pbp3"
    },
    {
        "id": "BE0008485",
        "name": "pbp4"
    },
    {
        "id": "BE0000576",
        "name": "pbpA"
    },
    {
        "id": "BE0003688",
        "name": "pbpA"
    },
    {
        "id": "BE0009126",
        "name": "pbpC"
    },
    {
        "id": "BE0002392",
        "name": "pbpX"
    },
    {
        "id": "BE0003271",
        "name": "pcaB"
    },
    {
        "id": "BE0004034",
        "name": "pcaG"
    },
    {
        "id": "BE0004035",
        "name": "pcaH"
    },
    {
        "id": "BE0002978",
        "name": "pchF"
    },
    {
        "id": "BE0001936",
        "name": "pcp"
    },
    {
        "id": "BE0002380",
        "name": "pcrV"
    },
    {
        "id": "BE0004112",
        "name": "pdh"
    },
    {
        "id": "BE0003081",
        "name": "pdp"
    },
    {
        "id": "BE0002725",
        "name": "pelA"
    },
    {
        "id": "BE0003621",
        "name": "penA"
    },
    {
        "id": "BE0000142",
        "name": "penA"
    },
    {
        "id": "BE0001482",
        "name": "penP"
    },
    {
        "id": "BE0003194",
        "name": "pep"
    },
    {
        "id": "BE0001606",
        "name": "pepV"
    },
    {
        "id": "BE0003905",
        "name": "petA"
    },
    {
        "id": "BE0003885",
        "name": "petA"
    },
    {
        "id": "BE0002472",
        "name": "petB"
    },
    {
        "id": "BE0003884",
        "name": "petB"
    },
    {
        "id": "BE0003886",
        "name": "petC"
    },
    {
        "id": "BE0002474",
        "name": "petC"
    },
    {
        "id": "BE0002473",
        "name": "petD"
    },
    {
        "id": "BE0003906",
        "name": "petG"
    },
    {
        "id": "BE0002962",
        "name": "petH"
    },
    {
        "id": "BE0002988",
        "name": "petH"
    },
    {
        "id": "BE0003907",
        "name": "petL"
    },
    {
        "id": "BE0003908",
        "name": "petM"
    },
    {
        "id": "BE0003909",
        "name": "petN"
    },
    {
        "id": "BE0001818",
        "name": "pfkA"
    },
    {
        "id": "BE0001469",
        "name": "pgi2"
    },
    {
        "id": "BE0001422",
        "name": "phaA"
    },
    {
        "id": "BE0002619",
        "name": "pheA2"
    },
    {
        "id": "BE0002508",
        "name": "phnA"
    },
    {
        "id": "BE0003136",
        "name": "phnX"
    },
    {
        "id": "BE0002968",
        "name": "phr"
    },
    {
        "id": "BE0002497",
        "name": "phyA"
    },
    {
        "id": "BE0001548",
        "name": "phzF"
    },
    {
        "id": "BE0003036",
        "name": "phzG"
    },
    {
        "id": "BE0004058",
        "name": "pikAIV"
    },
    {
        "id": "BE0001713",
        "name": "pilE1"
    },
    {
        "id": "BE0001982",
        "name": "pip"
    },
    {
        "id": "BE0001560",
        "name": "plc"
    },
    {
        "id": "BE0002072",
        "name": "pntB"
    },
    {
        "id": "BE0001523",
        "name": "pobA"
    },
    {
        "id": "BE0004062",
        "name": "pol"
    },
    {
        "id": "BE0004136",
        "name": "pol"
    },
    {
        "id": "BE0001400",
        "name": "pol"
    },
    {
        "id": "BE0003079",
        "name": "pol"
    },
    {
        "id": "BE0004854",
        "name": "pol"
    },
    {
        "id": "BE0004855",
        "name": "pol"
    },
    {
        "id": "BE0003339",
        "name": "pol"
    },
    {
        "id": "BE0003871",
        "name": "polA"
    },
    {
        "id": "BE0001665",
        "name": "polA"
    },
    {
        "id": "BE0003395",
        "name": "ponA"
    },
    {
        "id": "BE0002674",
        "name": "por"
    },
    {
        "id": "BE0004179",
        "name": "porB"
    },
    {
        "id": "BE0003737",
        "name": "ppa"
    },
    {
        "id": "BE0002929",
        "name": "ppcA"
    },
    {
        "id": "BE0001894",
        "name": "ppdK"
    },
    {
        "id": "BE0003248",
        "name": "ppgmk"
    },
    {
        "id": "BE0003205",
        "name": "pph"
    },
    {
        "id": "BE0003016",
        "name": "pqqC"
    },
    {
        "id": "BE0003051",
        "name": "psbV"
    },
    {
        "id": "BE0002706",
        "name": "ptd"
    },
    {
        "id": "BE0004097",
        "name": "ptpA"
    },
    {
        "id": "BE0002691",
        "name": "ptr2"
    },
    {
        "id": "BE0004286",
        "name": "ptsP"
    },
    {
        "id": "BE0003849",
        "name": "pufC"
    },
    {
        "id": "BE0003952",
        "name": "pufL"
    },
    {
        "id": "BE0003851",
        "name": "pufL"
    },
    {
        "id": "BE0003852",
        "name": "pufM"
    },
    {
        "id": "BE0003953",
        "name": "pufM"
    },
    {
        "id": "BE0003850",
        "name": "puhA"
    },
    {
        "id": "BE0003954",
        "name": "puhA"
    },
    {
        "id": "BE0003883",
        "name": "puhA"
    },
    {
        "id": "BE0001623",
        "name": "punA"
    },
    {
        "id": "BE0003255",
        "name": "purE"
    },
    {
        "id": "BE0002519",
        "name": "pvuIIM"
    },
    {
        "id": "BE0003966",
        "name": "pycA"
    },
    {
        "id": "BE0001293",
        "name": "pyp"
    },
    {
        "id": "BE0002008",
        "name": "pyrDA"
    },
    {
        "id": "BE0001268",
        "name": "qacR"
    },
    {
        "id": "BE0002909",
        "name": "qbdA"
    },
    {
        "id": "BE0001870",
        "name": "qheDH"
    },
    {
        "id": "BE0004357",
        "name": "qhnDH"
    },
    {
        "id": "BE0003280",
        "name": "rab6"
    },
    {
        "id": "BE0003337",
        "name": "radh"
    },
    {
        "id": "BE0003800",
        "name": "rbmD"
    },
    {
        "id": "BE0002526",
        "name": "rdmB"
    },
    {
        "id": "BE0002558",
        "name": "rdmC"
    },
    {
        "id": "BE0001706",
        "name": "relA"
    },
    {
        "id": "BE0003801",
        "name": "rep"
    },
    {
        "id": "BE0003178",
        "name": "repA"
    },
    {
        "id": "BE0001937",
        "name": "rhdA"
    },
    {
        "id": "BE0002995",
        "name": "rmlC"
    },
    {
        "id": "BE0001366",
        "name": "rnaSA"
    },
    {
        "id": "BE0004032",
        "name": "rnhA"
    },
    {
        "id": "BE0002994",
        "name": "rocF"
    },
    {
        "id": "BE0003034",
        "name": "roo"
    },
    {
        "id": "BE0003366",
        "name": "rpoA"
    },
    {
        "id": "BE0004216",
        "name": "rpsB"
    },
    {
        "id": "BE0004217",
        "name": "rpsC"
    },
    {
        "id": "BE0004218",
        "name": "rpsI"
    },
    {
        "id": "BE0004214",
        "name": "rpsQ"
    },
    {
        "id": "BE0004215",
        "name": "rpsT"
    },
    {
        "id": "BE0001456",
        "name": "rsrIM"
    },
    {
        "id": "BE0009677",
        "name": "rt"
    },
    {
        "id": "BE0002835",
        "name": "scrY"
    },
    {
        "id": "BE0002731",
        "name": "serC"
    },
    {
        "id": "BE0009164",
        "name": "serca"
    },
    {
        "id": "BE0002687",
        "name": "sfp"
    },
    {
        "id": "BE0001553",
        "name": "shc"
    },
    {
        "id": "BE0003198",
        "name": "shp"
    },
    {
        "id": "BE0002716",
        "name": "sll1541"
    },
    {
        "id": "BE0003784",
        "name": "slr0095"
    },
    {
        "id": "BE0003204",
        "name": "snoaL"
    },
    {
        "id": "BE0001301",
        "name": "soxA"
    },
    {
        "id": "BE0002024",
        "name": "speB"
    },
    {
        "id": "BE0004090",
        "name": "spg"
    },
    {
        "id": "BE0001847",
        "name": "spoII"
    },
    {
        "id": "BE0002557",
        "name": "srtB"
    },
    {
        "id": "BE0003760",
        "name": "staP"
    },
    {
        "id": "BE0003165",
        "name": "stx2eB"
    },
    {
        "id": "BE0001772",
        "name": "stxB"
    },
    {
        "id": "BE0001397",
        "name": "subC"
    },
    {
        "id": "BE0002870",
        "name": "sud"
    },
    {
        "id": "BE0000120",
        "name": "sulI"
    },
    {
        "id": "BE0002562",
        "name": "synC"
    },
    {
        "id": "BE0002531",
        "name": "taqIM"
    },
    {
        "id": "BE0003978",
        "name": "tbuX"
    },
    {
        "id": "BE0008671",
        "name": "tcdB"
    },
    {
        "id": "BE0004040",
        "name": "tcp14"
    },
    {
        "id": "BE0001405",
        "name": "tgt"
    },
    {
        "id": "BE0001923",
        "name": "thrS"
    },
    {
        "id": "BE0001501",
        "name": "thyA"
    },
    {
        "id": "BE0004301",
        "name": "tkt2"
    },
    {
        "id": "BE0003033",
        "name": "tmd"
    },
    {
        "id": "BE0002637",
        "name": "tnp"
    },
    {
        "id": "BE0004950",
        "name": "topA"
    },
    {
        "id": "BE0003315",
        "name": "torD"
    },
    {
        "id": "BE0004325",
        "name": "tpdA"
    },
    {
        "id": "BE0001889",
        "name": "tpiA"
    },
    {
        "id": "BE0001877",
        "name": "tpiA"
    },
    {
        "id": "BE0001702",
        "name": "tpl"
    },
    {
        "id": "BE0004123",
        "name": "tpl"
    },
    {
        "id": "BE0004182",
        "name": "traF"
    },
    {
        "id": "962274",
        "name": "traM"
    },
    {
        "id": "BE0004183",
        "name": "traN"
    },
    {
        "id": "BE0004181",
        "name": "traO"
    },
    {
        "id": "962574",
        "name": "traR"
    },
    {
        "id": "BE0001719",
        "name": "trpA"
    },
    {
        "id": "BE0002489",
        "name": "trpD"
    },
    {
        "id": "BE0001343",
        "name": "trpS"
    },
    {
        "id": "BE0000474",
        "name": "trxB"
    },
    {
        "id": "BE0004096",
        "name": "ttgR"
    },
    {
        "id": "BE0002704",
        "name": "tthHB8IM"
    },
    {
        "id": "BE0003279",
        "name": "tuf"
    },
    {
        "id": "BE0001393",
        "name": "tvaI"
    },
    {
        "id": "BE0001427",
        "name": "tvaII"
    },
    {
        "id": "BE0001389",
        "name": "tyrB"
    },
    {
        "id": "BE0001962",
        "name": "tyrS"
    },
    {
        "id": "BE0001313",
        "name": "uao"
    },
    {
        "id": "BE0003164",
        "name": "ugl"
    },
    {
        "id": "172105",
        "name": "unc-38"
    },
    {
        "id": "BE0001925",
        "name": "ung"
    },
    {
        "id": "BE0004010",
        "name": "ureA"
    },
    {
        "id": "BE0008660",
        "name": "ureB"
    },
    {
        "id": "BE0004009",
        "name": "ureB"
    },
    {
        "id": "BE0004008",
        "name": "ureC"
    },
    {
        "id": "BE0000334",
        "name": "ureC"
    },
    {
        "id": "BE0001238",
        "name": "vatD"
    },
    {
        "id": "BE0002903",
        "name": "vhb"
    },
    {
        "id": "BE0001784",
        "name": "xecC"
    },
    {
        "id": "BE0002766",
        "name": "xlnA"
    },
    {
        "id": "BE0003071",
        "name": "xlnA"
    },
    {
        "id": "BE0003288",
        "name": "xly"
    },
    {
        "id": "BE0003073",
        "name": "xyl"
    },
    {
        "id": "BE0001330",
        "name": "xylA"
    },
    {
        "id": "BE0001225",
        "name": "xylA"
    },
    {
        "id": "BE0001214",
        "name": "xylA"
    },
    {
        "id": "BE0001220",
        "name": "xylA"
    },
    {
        "id": "BE0003068",
        "name": "xyn10C"
    },
    {
        "id": "BE0002739",
        "name": "xynA"
    },
    {
        "id": "BE0002828",
        "name": "xynC"
    },
    {
        "id": "BE0003007",
        "name": "xynY"
    },
    {
        "id": "946960",
        "name": "ydhF"
    },
    {
        "id": "BE0001439",
        "name": "zwf"
    }
],
};
