import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import "./details.css";
import { Table, Descriptions } from "antd";
import * as echarts from "echarts";
import axios from "axios";

function Details(store) {
  const [tableData, setTableData] = useState([]);
  const [detailsData, setDetailsData] = useState("");
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    console.log("start effect");
    let currentData = [];

    for (let i = 0; i < 20; i++) {
      currentData.push({
        top: i + 1,
        sort_drugsimBPnet_list: "",
        sort_drugsimCCnet_list: "",
        sort_drugsimChemicalnet_list: "",
        sort_drugsimMFnet_list: "",
        sort_drugsimTherapeuticnet_list: "",
      });
    }
    if (store.store.detailsResult.data.sort_drugsimBPnet_list) {
      // console.log(store.store.detailsResult.data.sort_drugsimBPnet_list)
      store.store.detailsResult.data.sort_drugsimBPnet_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimBPnet_list = value.simdrug;
          }
        }
      );
    } else if (
      store.store.detailsResult.data.sort_biological_process_drugsimnetwork_list
    ) {
      store.store.detailsResult.data.sort_biological_process_drugsimnetwork_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimBPnet_list = value.simdrug;
          }
        }
      );
    }

    if (store.store.detailsResult.data.sort_drugsimCCnet_list) {
      store.store.detailsResult.data.sort_drugsimCCnet_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimCCnet_list = value.simdrug;
          }
        }
      );
    } else if (
      store.store.detailsResult.data.sort_cellular_component_drugsimnetwork_list
    ) {
      store.store.detailsResult.data.sort_cellular_component_drugsimnetwork_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimCCnet_list = value.simdrug;
          }
        }
      );
    }
    let current_data_sort_drugsimChemicalnet_list = [];
    if (store?.store?.detailsResult?.data?.sort_drugsimChemicalnet_list) {
    }
    if (store.store.detailsResult.data.sort_drugsimChemicalnet_list) {
      store.store.detailsResult.data.sort_drugsimChemicalnet_list.forEach(
        (value, index, array) => {
          console.log(value.simdrug);

          if (index < 20) {
            currentData[index].sort_drugsimChemicalnet_list = value.simdrug;
          }
        }
      );
    } else if (
      store.store.detailsResult.data.sort_chemical_drugsimnetwork_list
    ) {
      store.store.detailsResult.data.sort_chemical_drugsimnetwork_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimChemicalnet_list = value.simdrug;
          }
        }
      );
    }
    if (store.store.detailsResult.data.sort_drugsimMFnet_list) {
      store.store.detailsResult.data.sort_drugsimMFnet_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimMFnet_list = value.simdrug;
          }
        }
      );
    } else if (
      store.store.detailsResult.data.sort_molecular_function_drugsimnetwork_list
    ) {
      store.store.detailsResult.data.sort_molecular_function_drugsimnetwork_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimMFnet_list = value.simdrug;
          }
        }
      );
    }

    if (store.store.detailsResult.data.sort_drugsimTherapeuticnet_list) {
      store.store.detailsResult.data.sort_drugsimTherapeuticnet_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimTherapeuticnet_list = value.simdrug;
          }
        }
      );
    } else if (
      store.store.detailsResult.data.sort_therapeutic_drugsimnetwork_list
    ) {
      store.store.detailsResult.data.sort_therapeutic_drugsimnetwork_list.forEach(
        (value, index, array) => {
          if (index < 20) {
            currentData[index].sort_drugsimTherapeuticnet_list = value.simdrug;
          }
        }
      );
    }

    setTableData(currentData);
    // console.log(store.store.detailsResult.data.details)

    if (store.store.detailsResult.data.details.length > 0) {
      if (store.store.detailsResult.data.details[0].drugBackground) {
        let currentData_details =
          store.store.detailsResult.data.details[0].drugBackground;
        currentData_details = currentData_details.replace(/\b\&alpha\b/g, "α");
        currentData_details = currentData_details.replace(/\b\<sub>\b/g, "");
        currentData_details = currentData_details.replace(/\b\<\/sub>\b/g, "");
        currentData_details = currentData_details.replace(/\b\<\/sub>\b/g, "");
        currentData_details = currentData_details.replace(/\b\_Elavil_\b/g, "");
        currentData_details = currentData_details.replace(/\b\&#39\b/g, "");
        currentData_details = currentData_details.split("");
        for (let i = 0; i < currentData_details.length; i++) {
          switch (currentData_details[i]) {
            case "伪":
              currentData_details[i] = "α";
              break;
            case "尾":
              currentData_details[i] = "β";
              break;
            case "魏":
              currentData_details[i] = "κ";
              break;
            case "渭":
              currentData_details[i] = "μ";
              break;
            case "鈥":
              if (currentData_details[i + 1] === "檚") {
                currentData_details[i + 1] = "";
                currentData_details[i] = "s";
              } else if (currentData_details[i + 1] === "榩") {
                currentData_details[i] = "p";
                currentData_details[i + 1] = "";
              } else if (currentData_details[i + 1] === "?") {
                currentData_details[i] = "-";
                currentData_details[i + 1] = "";
              } else if (currentData_details[i + 1] === "攄") {
                currentData_details[i] = "-d";
                currentData_details[i + 1] = "";
              } else if (currentData_details[i + 1] === "榥") {
                currentData_details[i] = "n";
                currentData_details[i + 1] = "";
              } else {
                currentData_details[i] = "";
              }

              break;
            case "檚":
              currentData_details[i] = "";

              break;
            case "纬":
              currentData_details[i] = "γ";
              break;
            case "鈭":
              currentData_details[i] = "-)";
              currentData_details[i + 1] = "";
              break;

            case "庐":
              currentData_details[i] = "®";
              break;
            case "茅":
              currentData_details[i] = "é";
              break;
            case "猫":
              currentData_details[i] = "è";
              break;
            case "碌":
              currentData_details[i] = "µ";
              break;
            case "颅":
              currentData_details[i] = "";
              break;

            default:
              break;
          }
        }
        // console.log();
        setDetailsData(currentData_details.join(""));
      }
    }

    // let repalceTable = [
    //   ["&#39;", "‘"],
    //   ["伪", "α"],
    //   ["尾", "β"],
    //   ["魏", "κ"],
    //   ["渭", "μ"],
    //   ["鈥檚", "s"],
    //   ["鈥榩", "p"],
    //   ["鈥?", ")"],
    //   ["20鈥?0%", "20-30%"],
    //   ["纬", "γ"],
    //   [" _Elavil_", "Elavil"],
    //   ["<sub>", ""],
    //   ["</sub>", ""],
    //   [
    //     "&alpha;<sub>1</sub>",
    //     <div>
    //       α<sub>1</sub>
    //     </div>,
    //   ],
    //   ["鈭?", "-）"],
    //   ["鈥攄", "-d"],
    //   ["庐", <sup>®</sup>],
    //   ["鈥榥", "n"],
    //   ["茅", "é"],
    //   ["猫", "è"],
    //   ["碌", "µ"],
    //   ["颅", ""],
    // ];
    console.log(store.store.dDRresult);
    if (
      store.store.dDRresult.DataType === "CovKGE" ||
      store.store.dDRresult.DataType === "DeepDTnet" ||
      store.store.dDRresult.DataType === "KGMTL"
    ) {
      try {
        if (
          store.store.detailsResult.data.details.length > 0 &&
          store.store.dDRresult.data.length
        ) {
          let link =
            "https://server.wei-group.net/resource/subgraph_drug_disease_json/" +
            store.store.detailsResult.data.details[0].drugbankId +
            "___" +
            store.store.dDRresult.data[0].c1 +
            ".json";

          if (store.store.dDRresult.DataType === "DeepDTnet") {
            link =
              "https://server.wei-group.net/resource/path_result/DisKG_path/" +
              store.store.detailsResult.data.details[0].drugbankId +
              "___" +
              store.store.dDRresult.data[0].c1 +
              ".json";
            console.log(link);
          } else if (store.store.dDRresult.DataType === "KGMTL") {
            link =
              "https://server.wei-group.net/resource/path_result/KGMTL_path/" +
              store.store.detailsResult.data.details[0].drugbankId +
              "___" +
              store.store.dDRresult.data[0].c1 +
              ".json";
            console.log(link);
          } else if (store.store.dDRresult.DataType === "CovKGE") {
            link =
              "https://server.wei-group.net/resource/path_result/TarKG_path/" +
              store.store.detailsResult.data.details[0].drugbankId +
              "___" +
              store.store.dDRresult.data[0].c1 +
              ".json";
            console.log(link);
          } else {
            link =
              "https://server.wei-group.net/resource/subgraph_drug_disease_json/" +
              store.store.detailsResult.data.details[0].drugbankId +
              "___" +
              store.store.dDRresult.data[0].c1 +
              ".json";
          }

          console.log(link);
          axios.get(link).then((res) => {
            let current_res = res.data.replace(/'/g, '"');
            let c_res = JSON.parse(current_res);
            console.log(c_res);
            try {
              c_res.categories.forEach((v, i, a) => {
                if (v.name === "Side Effect") {
                  c_res.categories[i].name = "Disease\\Side-effect";
                }
              });
            } catch (error) {
              // console.log(error)
            }

            try {
              // console.log()

              var chartDom = document.getElementById("main_graph");
              var myChart = echarts.init(chartDom);
              var option;

              for (let i = 0; i < c_res.nodes.length; i++) {
                c_res.nodes[i].x = Math.random() * (2000 - 400) + 200;
                c_res.nodes[i].y = Math.random() * (1000 - 200) + 100;

                if (c_res.nodes[i].name === store.store.dDRresult.data[0].c1) {
                  c_res.nodes[i].symbolSize = 30;
                  c_res.nodes[i].x = 0;
                  c_res.nodes[i].y = 500;
                } else if (
                  c_res.nodes[i].name ===
                  store.store.detailsResult.data.details[0].drugbankId
                ) {
                  c_res.nodes[i].symbolSize = 30;
                  c_res.nodes[i].x = 2000;
                  c_res.nodes[i].y = 500;
                }
              }

              let maxLine = 0;
              c_res.links.map((value, index, array) => {
                if (maxLine < value.lineStyle.width) {
                  maxLine = value.lineStyle.width;
                }
              });
              maxLine /= 8;
              for (let i = 0; i < c_res.links.length; i++) {
                c_res.links[i].lineStyle.width /= maxLine;
              }

              myChart.hideLoading();
              c_res.nodes.forEach(function (node) {
                node.label = {
                  show: node.symbolSize > 30,
                };
              });
              option = {
                title: {
                  text: "Path map",
                  subtext: "Default layout",
                  top: "bottom",
                  left: "right",
                },
                tooltip: {
                  formatter: function (params) {
                    //连接线上提示文字格式化
                    if (params.data.source) {
                      //注意判断，else是将节点的文字也初始化成想要的格式
                      let currentDiv = "";
                      let currentData =
                        params.data.label.formatter.split("---");
                      currentData.map((value, index, array) => {
                        if (index < 19) {
                          currentDiv += value + "\n";
                        } else if (index === 20) {
                          currentDiv += "...";
                        }
                      });
                      console.log(currentDiv);
                      return currentDiv;
                    } else {
                      return "ID:" + params.name;
                    }
                  },

                  extraCssText:
                    "width:400px;white-space:pre-wrap;font-size:10px;  text-align: justify;          ",
                },
                legend: [
                  {
                    // selectedMode: 'single',
                    // data: test.categories.map(function (a) {
                    //   return a.name;
                    // }),
                  },
                ],
                animationDuration: 1500,
                animationEasingUpdate: "quinticInOut",
                series: [
                  {
                    type: "graph",
                    layout: "none",
                    // edgeSymbol: ['circle', 'arrow'],
                    data: c_res.nodes,
                    links: c_res.links,
                    categories: c_res.categories,
                    roam: true,
                    label: {
                      position: "right",
                      formatter: "{b}",
                    },
                    lineStyle: {
                      color: "source",
                      curveness: 0.3,
                      // width: 0.1,
                      opacity: 0.5,
                    },
                    emphasis: {
                      focus: "adjacency",
                      lineStyle: {
                        // width: 10,
                      },
                    },
                  },
                ],
              };
              myChart.setOption(option);
            } catch (e) {}
          });
        }
      } catch (e) {}
    }
  }, [store.store.detailsResult.data]);

  const columns = [
    {
      title: "Top",
      dataIndex: "top",
      key: "top",
    },
    {
      title: "DrugsimBPnet",
      dataIndex: "sort_drugsimBPnet_list",
      key: "sort_drugsimBPnet_list",
    },
    {
      title: "DrugsimCCnet",
      dataIndex: "sort_drugsimCCnet_list",
      key: "sort_drugsimCCnet_list",
    },
    {
      title: "DrugsimChemicalnet",
      dataIndex: "sort_drugsimChemicalnet_list",
      key: "sort_drugsimChemicalnet_list",
    },
    {
      title: "DrugsimMFnet",
      dataIndex: "sort_drugsimMFnet_list",
      key: "sort_drugsimMFnet_list",
    },
    {
      title: "DrugsimTherapeuticnet",
      dataIndex: "sort_drugsimTherapeuticnet_list",
      key: "sort_drugsimTherapeuticnet_list",
    },
  ];

  const columns_1 = [
    {
      title: "Top",
      dataIndex: "top",
      key: "top",
    },
    {
      title: "Biological process drugsimnetwork",
      dataIndex: "sort_drugsimBPnet_list",
      key: "sort_drugsimBPnet_list",
    },
    {
      title: "Cellular component drugsimnetwork",
      dataIndex: "sort_drugsimCCnet_list",
      key: "sort_drugsimCCnet_list",
    },
    {
      title: "Chemical drugsimnetwork list",
      dataIndex: "sort_drugsimChemicalnet_list",
      key: "sort_drugsimChemicalnet_list",
    },
    {
      title: "Molecular function drugsimnetwork",
      dataIndex: "sort_drugsimMFnet_list",
      key: "sort_drugsimMFnet_list",
    },
    {
      title: "Therapeutic drugsimnetwork",
      dataIndex: "sort_drugsimTherapeuticnet_list",
      key: "sort_drugsimTherapeuticnet_list",
    },
  ];
  return (
    <div className="result-body">
      {/* <Button onClick={() => {}}>12</Button> */}
      {/* <div className="result-body-title">Details</div> */}
      <div className="result-body-DrugId">
        Drug ID:{" "}
        {store.store.detailsResult.data.details.length > 0
          ? store.store.detailsResult.data.details[0].drugbankId
          : ""}
      </div>
      {store.store.detailsResult.data.details.length > 0 ? (
        <div className="result-body-Details-body">
          <Descriptions
            title={<div className="Details-titles-small">Details</div>}
            column={2}
            bordered
          >
            <Descriptions.Item
              label="Drug Name"
              contentStyle={{ paddingRight: "10px" }}
            >
              {store.store.detailsResult.data.details[0].genericName
                ? store.store.detailsResult.data.details[0].genericName
                : "Null"}
            </Descriptions.Item>
            <Descriptions.Item
              label="ATC Codes"
              contentStyle={{ paddingRight: "10px" }}
            >
              {store.store.detailsResult.data.details[0].atcCodes
                ? store.store.detailsResult.data.details[0].atcCodes
                : "Null"}
            </Descriptions.Item>
            <Descriptions.Item
              label="Drug Background"
              contentStyle={{ paddingRight: "10px" }}
            >
              {detailsData}
            </Descriptions.Item>
            <Descriptions.Item
              label="Drug Indication"
              contentStyle={{ paddingRight: "10px", minWidth: "250px" }}
            >
              {store.store.detailsResult.data.details[0].drugIndication
                ? store.store.detailsResult.data.details[0].drugIndication
                : "Null"}
            </Descriptions.Item>
            <Descriptions.Item
              label="Groups Info"
              contentStyle={{ paddingRight: "10px" }}
            >
              {store.store.detailsResult.data.details[0].groupsInfo
                ? store.store.detailsResult.data.details[0].groupsInfo
                : store.store.detailsResult.data.details[0].groups
                ? store.store.detailsResult.data.details[0].groups
                : "Null"}
            </Descriptions.Item>
            {/* <Descriptions.Item
              label="Match Info"
              contentStyle={{ paddingRight: "10px" }}
            >
              {store.store.detailsResult.data.details[0].matchInfo
                ? store.store.detailsResult.data.details[0].matchInfo
                : "Null"}
            </Descriptions.Item> */}
            <Descriptions.Item
              label="Smiles"
              contentStyle={{ paddingRight: "10px" }}
            >
              {store.store.detailsResult.data.smiles.length > 0
                ? store.store.detailsResult.data.smiles[0].smile
                : "Null"}
            </Descriptions.Item>
            <Descriptions.Item
              label="Drug Structure"
              contentStyle={{ paddingRight: "10px" }}
            >
              {store.store.dDRresult.DataType === "KGMTL" ? (
                store.store.detailsResult.data.smiles.length > 0 ? (
                  (
                    <img
                      className="smiles-img"
                      src={
                        "https://server.wei-group.net/resource/graph_png_new/" +
                        store.store.detailsResult.data.details[0].drugbankId +
                        ".png"
                      }
                    />
                  )
                ) : (
                  "Null"
                )
              ) : store.store.detailsResult.data.smiles.length > 0 ? (
                <img
                  className="smiles-img"
                  src={
                    "https://server.wei-group.net/resource/graph/graph_png/" +
                    store.store.detailsResult.data.smiles[0].drugId +
                    ".png"
                  }
                />
              ) : (
                "Null"
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
      ) : (
        ""
      )}
      <div>
        {store.store.dDRresult.DataType === "CovKGE" ||
        store.store.dDRresult.DataType === "KGMTL" ||
        store.store.dDRresult.DataType === "DeepDTnet" ? (
          <div id="main_graph" style={{ width: "100%", height: "400px" }}></div>
        ) : (
          ""
        )}
      </div>
      <div>
        {
          // tableData[0].
        }
        {store.store.detailsResult.data.details.length > 0 ? (
          store.store.detailsResult.data.sort_drugsimTherapeuticnet_list ? (
            store.store.dDRresult.DataType === "CovKGE" ||
            store.store.dDRresult.DataType === "KGMTL" ||
            store.store.dDRresult.DataType === "DeepDTnet" ? (
              ""
            ) : (
              <div>
                <Table columns={columns} dataSource={tableData} />
              </div>
            )
          ) : store.store.dDRresult.DataType === "CovKGE" ||
            store.store.dDRresult.DataType === "KGMTL" ||
            store.store.dDRresult.DataType === "DeepDTnet" ? (
            ""
          ) : (
            <div>
              <Table columns={columns_1} dataSource={tableData} />
            </div>
          )
        ) : (
          ""
        )}
      </div>
      <div className="result-chart">
        <div id="main_1" style={{ width: "50%", height: "400px" }}></div>
        <div id="main_2" style={{ width: "50%", height: "400px" }}></div>
      </div>
    </div>
  );
}

export default inject("store")(observer(Details));
