import { observable, action, makeAutoObservable } from "mobx";
import { FAILURE, REQUEST, SUCCESS, UNSET } from "../../constants/status";
class DetailsResult {
  constructor() {
    makeAutoObservable(this);
  }

  @observable status = UNSET;
  @observable data = {
    details: [],
    sort_drugsimBPnet_list: [],
    sort_drugsimCCnet_list: [],
    sort_drugsimChemicalnet_list: [],
    sort_drugsimMFnet_list: [],
    sort_drugsimTherapeuticnet_list: [],
    // sort_biological_process_drugsimnetwork_list: [],
    // sort_cellular_component_drugsimnetwork_list: [],
    // sort_chemical_drugsimnetwork_list: [],
    // sort_molecular_function_drugsimnetwork_list: [],
    // sort_therapeutic_drugsimnetwork_list: [],
  };

  @action request = () => {
    this.status = REQUEST;
  };
  @action request_success = (dataInject) => {
    this.data = dataInject.data;

    this.status = SUCCESS;
  };
  @action request_fail = () => {
    this.status = FAILURE;
  };
}
export default DetailsResult;
