
import { observable, action, makeAutoObservable } from "mobx";
import { FAILURE, REQUEST, SUCCESS, UNSET } from "../../constants/status";

class MetricsResult {
  constructor() {
    makeAutoObservable(this);
  }

  @observable status = UNSET;
  @observable data = {};
  
  @action request = () => {
    this.status = REQUEST;
  };

  @action request_success = (data) => {
    this.data = data;
    this.status = SUCCESS;
  };

  @action request_fail = () => {
    this.status = FAILURE;
  };
}

export default MetricsResult;