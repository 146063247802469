import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import "./serverHome.css";
import { LoginOutlined } from "@ant-design/icons";
import { Button } from "antd";

import disease_logo from "../../constants/img/Disease-Centric-show.png";
import drug_logo from "../../constants/img/Target-Centric-show.png";
function ServerHome(store) {
  useEffect(() => {
    console.log(store);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="serverhome-body-service">
      <div className="title-serverHome-deeplearn"></div>

      <div className="serverhome-body">
        <div className="function-select">
          <div className="function-select-con-way">
            {/* <div className="function-select-title">Disease-Centric </div> */}
            <div className="function-select-img-outer">
              <img
                src={disease_logo}
                className="function-select-left-img"
                onClick={() => {
                  store.store.servers.changeHomeStatue(5);
                }}
              />
            </div>

            <div className="function-select-context">
              The "Disease-Centric <b>DeepDR</b>" service offers three optional
              models for users: a heterogeneous network-based model, a combined
              heterogeneous network and text mining-based model, and a knowledge
              graph-based model. Users can select their desired model and input
              a disease name/ID of their interest to obtain the ranking of
              recommended drugs.
            </div>
            <div className="function-select-button-out">
              <Button
                onClick={() => {
                  store.store.servers.changeHomeStatue(5);
                }}
                className="function-select-button"
              >
                Try
              </Button>
            </div>
          </div>
          <div className="function-select-con-way">
            {/* <div className="function-select-title">Target-Centric</div> */}
            <div className="function-select-img-outer">
              <img
                src={drug_logo}
                className="function-select-left-img"
                onClick={() => {
                  //gotoPage();
                  store.store.servers.changeHomeStatue(6);
                }}
              />
            </div>

            <div className="function-select-context">
              The "Target-Centric <b>DeepDR</b>" service offers four optional
              models for users: two heterogeneous network-based models, a
              knowledge graph-based model, and a synergistic combination model
              of both knowledge graph and molecule graph. Users can select their
              desired model and input a target (gene) name/ID of their interest
              to obtain the ranking of recommended drugs.
            </div>
            <div className="function-select-button-out">
              <Button
                onClick={() => {
                  //gotoPage();
                  store.store.servers.changeHomeStatue(6);
                }}
                className="function-select-button"
              >
                Try
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject("store")(observer(ServerHome));
