import React, { useEffect } from "react";

const GlobeEmbed = () => {
  useEffect(() => {
    // 创建一个 script 元素
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "clstr_globe";
    script.src = "//clustrmaps.com/globe.js?d=2DaF3ZuwSQhqcx9WiKqlwasugiumeXxw_ZxfOm1Stpw";
    script.async = true;

    // 将 script 插入到指定的 div 中
    const targetDiv = document.getElementById("map-container");
    if (targetDiv) {
      targetDiv.appendChild(script);
    }

    // 清理：组件卸载时移除 script
    return () => {
      if (targetDiv && script) {
        targetDiv.removeChild(script);
      }
    };
  }, []); // 仅在组件加载时运行一次

  return <div id="map-container" style={{ height: "200px", width: "200px" }}></div>;
};

export default GlobeEmbed;



