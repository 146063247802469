import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  BackTop,
  Dropdown,
  Button,
  Space,
} from "antd";
import "./home.css";

import HomePage from "./home/homePage";

import ServerHome from "./server-home/serverHome";
import About from "./about/about";
import Resources from "./resources/resources";
import {
  HomeOutlined,
  DesktopOutlined,
  FileTextOutlined,
  BarChartOutlined,
  ContactsOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import Tutorial from "./tutorial/tutorial";
import GlobeEmbed from "./GlobeEmbed";
import ServerHomeModel from "./server-page/server-home-model/serverHomeModel";
import ServerPageModel from "./server-page/serverPageModel/serverPageModel";
import Publications from "./publications/publications";
import DeepDRresult from "./result/DeepDRresult";
import Details from "./details/details";
import springLogo from "../constants/img/spring-logo.png";
import pytorchLogo from "../constants/img/pytorch-logo.png";
import mysqlLogo from "../constants/img/mysql-logo.png";
import pythonLogo from "../constants/img/python-logo.png";
const { Header, Content, Footer } = Layout;

function Home(store) {
  useEffect(() => {
    setPage(store.store.servers.homeStatus);
  }, [store.store.servers.homeStatus]);
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          store.store.servers.changeHomeStatue(5);
        }}
      >
        Disease based prediction
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          store.store.servers.changeHomeStatue(6);
        }}
      >
        Target based prediction
      </Menu.Item>
    </Menu>
  );
  const headerCon = {
    1: (
      <div>
        <HomeOutlined className="headerCon-tab" style={{ fontSize: "20px" }} />
        Home
      </div>
    ),
    2: (
      <div>
        {/* Services */}
        <Dropdown overlay={menu}>
          <div
            onClick={() => {
              store.store.servers.changeHomeStatue(2);
            }}
            Î
          >
            <BarChartOutlined
              className="headerCon-tab"
              style={{ fontSize: "20px" }}
            />
            Services
          </div>

          {/* Services */}
        </Dropdown>
      </div>
    ),

    4: (
      <div>
        <FileTextOutlined
          className="headerCon-tab"
          style={{ fontSize: "20px" }}
        />
        Publications
      </div>
    ),
    5: (
      <div>
        <ContactsOutlined
          className="headerCon-tab"
          style={{ fontSize: "20px" }}
        />
        Contact
      </div>
    ),
    3: (
      <div>
        <DesktopOutlined
          className="headerCon-tab"
          style={{ fontSize: "20px" }}
        />
        Tutorial
      </div>
    ),
    6: (
      <div>
        <DatabaseOutlined
          className="headerCon-tab"
          style={{ fontSize: "20px" }}
        />
        Resources
      </div>
    ),
  };
  const [page, setPage] = useState(1);

  const PageCon = () => {
    switch (page) {
      case 1:
        return (
          <div className="submit-depart-pages-home">
            <HomePage />
          </div>
        );

      case 3:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item className="Breadcrumb-Item-text">
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item className="Breadcrumb-Item-text">
                  Publications
                </Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(1);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <Publications />
          </div>
        );
      case 4:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Resources</Breadcrumb.Item>
                {/* <Breadcrumb.Item>Model Input</Breadcrumb.Item> */}
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(1);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <Resources />
          </div>
        );
      case 5:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Disease based prediction</Breadcrumb.Item>
                {/* <Breadcrumb.Item>Model Input</Breadcrumb.Item> */}
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(2);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerHomeModel type="Disease" />
          </div>
        );
      case 6:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Target based prediction</Breadcrumb.Item>
                {/* <Breadcrumb.Item>Model Input</Breadcrumb.Item> */}
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(2);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerHomeModel type="Target" />
          </div>
        );
      case 2:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item className="Breadcrumb-Item-text">
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item className="Breadcrumb-Item-text">
                  Service Select
                </Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(1);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerHome />
          </div>
        );
      case 7:
        return (
          <div className="submit-depart-pages-unique">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Result</Breadcrumb.Item>
                <Breadcrumb.Item>Details</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(11);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <Details />
          </div>
        );
      case 8:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Disease based prediction</Breadcrumb.Item>
                <Breadcrumb.Item>Model Input</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(5);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerPageModel type="DeepDR" />
          </div>
        );
      case 9:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Disease based prediction</Breadcrumb.Item>
                <Breadcrumb.Item>Model Input</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(5);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerPageModel type="HetDR" />
          </div>
        );

      case 10:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Disease based prediction</Breadcrumb.Item>
                <Breadcrumb.Item>Model Input</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(5);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerPageModel type="DeepDTnet" />
          </div>
        );
      case 11:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Result</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    if (
                      store.store.dDRresult.DataType !== "DeepDTnet" ||
                      store.store.dDRresult.DataType !== "HetDR" ||
                      store.store.dDRresult.DataType !== "DeepDR"
                    ) {
                      store.store.servers.changeHomeStatue(5);
                    } else {
                      store.store.servers.changeHomeStatue(6);
                    }
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <DeepDRresult />
          </div>
        );
      case 20:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Target based prediction</Breadcrumb.Item>
                <Breadcrumb.Item>Model Input</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(6);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerPageModel type="t_DeepDTnet" />
          </div>
        );
      case 21:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Target based prediction</Breadcrumb.Item>
                <Breadcrumb.Item>Model Input</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(6);
                  }}
                >
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerPageModel type="CovKGE" />
          </div>
        );
      case 90: {
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Target based prediction</Breadcrumb.Item>
                <Breadcrumb.Item>Model Input</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(6);
                  }}
                >
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerPageModel type="KGMTL" />
          </div>
        );
      }
      case 22:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Target based prediction</Breadcrumb.Item>
                <Breadcrumb.Item>Model Input</Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(6);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <ServerPageModel type="AOPEDF" />
          </div>
        );
      case 12:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item className="Breadcrumb-Item-text">
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item className="Breadcrumb-Item-text">
                  Contact
                </Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(1);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <About />
          </div>
        );
      case 13:
        return (
          <div className="submit-depart-pages">
            <div className="Result-Result-body-Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item className="Breadcrumb-Item-text">
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item className="Breadcrumb-Item-text">
                  Tutorial
                </Breadcrumb.Item>
                <Button
                  className="Result-Result-body-Breadcrumb-button"
                  type="link"
                  onClick={() => {
                    store.store.servers.changeHomeStatue(1);
                  }}
                >
                  {" "}
                  Back
                </Button>
              </Breadcrumb>
            </div>
            <Tutorial />
          </div>
        );
      default:
        window.location.href = "http://wei-group.net/contact";
        break;
    }
  };

  return (
    <Layout>
      <Header className="Menu-header-home-outer-inite">
        <div className="Menu-header-home-outer-in-item">
          <div className="Menu-header-home">
            <div className="logo-welab">
              DeepDR
              {/* <img src={logo} className="logo-welab-img" /> */}
            </div>

            <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={["1"]}
              className="Menu-header-home-item"
              triggerSubMenuAction="click"
            >
              {new Array(6).fill(null).map((_, index) => {
                const keyValue = index + 1;
                if (keyValue === 2) {
                  return (
                    <Menu.Item key={keyValue} className="commen-Menu-Item">
                      {headerCon[keyValue]}
                    </Menu.Item>
                  );
                } else if (keyValue === 4) {
                  return (
                    <Menu.Item
                      key={keyValue}
                      onClick={() => {
                        store.store.servers.changeHomeStatue(3);
                      }}
                      className="commen-Menu-Item"
                    >
                      {headerCon[keyValue]}
                    </Menu.Item>
                  );
                } else if (keyValue === 5) {
                  return (
                    <Menu.Item
                      key={keyValue}
                      onClick={() => {
                        store.store.servers.changeHomeStatue(12);
                      }}
                      className="commen-Menu-Item"
                    >
                      {headerCon[keyValue]}
                    </Menu.Item>
                  );
                } else if (keyValue === 6) {
                  return (
                    <Menu.Item
                      key={keyValue}
                      onClick={() => {
                        store.store.servers.changeHomeStatue(4);
                      }}
                      className="commen-Menu-Item"
                    >
                      {headerCon[keyValue]}
                    </Menu.Item>
                  );
                } else if (keyValue === 3) {
                  return (
                    <Menu.Item
                      key={keyValue}
                      onClick={() => {
                        store.store.servers.changeHomeStatue(13);
                      }}
                      className="commen-Menu-Item"
                    >
                      {headerCon[keyValue]}
                    </Menu.Item>
                  );
                } else {
                  return (
                    <Menu.Item
                      key={keyValue}
                      onClick={() => {
                        store.store.servers.changeHomeStatue(keyValue);
                      }}
                      className="commen-Menu-Item"
                    >
                      {headerCon[keyValue]}
                    </Menu.Item>
                  );
                }
              })}
            </Menu>
          </div>
        </div>
      </Header>
      <Content style={{ padding: "0 0px", backgroundColor: "white" }}>
        <BackTop />
        <PageCon />
      </Content>
      <Footer className="footer-home">
        <div className="footer-home-inner">
          <div className="context-us-footer">
            <div className="context-us-footer-quick-link">
              <div>
                <b>Quick Link</b>
              </div>
              <div
                onClick={() => {
                  store.store.servers.changeHomeStatue(12);
                }}
              >
                Contact us
              </div>
              <p />
              <div>
                <b>DEV Tool</b>
              </div>{" "}
              <div className="home-logo">
                <img src={pythonLogo} />
                <img src={springLogo} />
                <div></div>
                <img src={pytorchLogo} style={{ width: "30%", margin: "0" }} />
                <img src={mysqlLogo} style={{ width: "40%" }} />
              </div>
            </div>{" "}
            <div></div>
          </div>

          <div className="Global-isitors-earth">
            <div class="col-md-2 footer-grids">
              <h3>Global Visitors</h3>

              {/* <iframe
                frameborder="0"
                width="220"
                height="220"
                src="//clustrmaps.com/globe.js?d=2DaF3ZuwSQhqcx9WiKqlwasugiumeXxw_ZxfOm1Stpw"
              ></iframe> */}
              <GlobeEmbed />
            </div>
          </div>
          <div className="context-us-footer">
            <div className="context-us-footer-about">
              <b>ABOUT</b>:This website provides easy to comprehensive, accurate
              and efficient prediction of drug reposintioning. The prediction is
              fuonded on multi-type and high-quality data reasource. This
              website is free and open to all users and there is no login
              requirement
            </div>
          </div>
        </div>
        <div className="footer-home-inner-contact">
          xzeng@hnu.edu.cn ｜ weileyi@sdu.edu.cn{" "}
        </div>
      </Footer>
    </Layout>
  );
}

export default inject("store")(observer(Home));
