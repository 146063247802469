import { message } from "antd";
import Axios from "axios";
// import { JOBINFO, JOBLIST, JOBSUB } from '../constants/url';

export const getModelPriduct = async (
  link,
  key,
  RequestType = "default",
  DataType = "default"
) => {
  let result = {
    resultType: false,
    data: [],
    RequestType: "default",
    DataType: "default",
  };
  await Axios.post(link, { key: key })
    .then((res) => res.data)
    .then((res) => {
      if (res.status === 200 && res.data !== "0") {
        console.log(res, "这里是获取到的数据");
        result.resultType = true;
        result.data = res.data;
        result.RequestType = RequestType;
        result.DataType = DataType;
      } else {
        message.info("There is something in error");
        result.DataType = "default";
      }
      // console.log(res);
    })
    .catch((err) => {
      message.info("There is something in error");
      console.log(err);
      result.resultType = false;
    });
  return new Promise((res, rej) => {
    res(result);
  });
};
