import { inject, observer } from "mobx-react";
import { useEffect } from "react";

import "./homePage.css";

// import homeImg from "../../constants/img/homepage_new.png";
import { Button } from "antd";

function HomePage(store) {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="homepage-body">
      <div className="fixbackground bg-pan-br header-con">
        <div className="title-home-con-home-uper">
          {" "}
          <div className="home-uper">
            {/* <div className="home-uper-home-con"> */}
            <div className="home-uper-home-title">Welcome to the DeepDR</div>
            <div className="home-uper-home-title-context">
            Drug repositioning refers to the process of identifying new therapeutic uses for existing drugs. Using existing knowledge for in silico drug repositioning is crucial for accelerating drug development and enhancing efficiency. <b>DeepDR</b>, a web server employing <b>Deep</b> learning for <b>D</b>rug <b>R</b>epositioning,  recommends potential drugs with treatment effects for specific diseases or target proteins. In brief, DeepDR builds numerous heterogeneous networks and a comprehensive knowledge graph containing extensive data, and utilizes six advanced deep learning models for drug repositioning relevant to diseases and target proteins. With these comprehensive drug repositioning models, this web server provides a rapid online service for suggesting effective drug candidates for particular diseases or target proteins.
            </div>

            {/* </div> */}
          </div>
          <div className="home-uper-down">
            <div className="home-uper-down-img-size">
              <div className="home-uper-down-img-size-position"></div>
              <img
                src={
                  "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/homepage_new.png"
                }
                className="home-uper-down-img"
              />
            </div>
          </div>
          {/* <div className="home-uper-down-button">
          <Button
              onClick={() => {
                store.store.servers.changeHomeStatue(13);
              }}
            >
              Tutorial
            </Button> 
          </div> */}
        </div>
      </div>
      <div className="home-quick-enter">
        <div className="right-other-enter">
          <div className="right-other-enter-con-button-context">
            <div className="right-other-enter-con">
              <div className="right-other-enter-con-title">
                {" "}
                Disease-Centric <b>DeepDR</b>
              </div>
              The "Disease-Centric <b>DeepDR</b>" service provides users with three model options: a model based on heterogeneous networks, a model that integrates heterogeneous networks and text mining, and a model grounded in knowledge graphs. Users can choose an appropriate model and input a disease name or ID to receive a ranked list of potential drug recommendations.
            </div>

            <Button
              onClick={() => {
                store.store.servers.changeHomeStatue(5);
              }}
            >
              Try
            </Button>
          </div>
        </div>
        <div className="right-other-enter">
          <div className="right-other-enter-con-button-context">
            <div className="right-other-enter-con">
              <div className="right-other-enter-con-title">
                Target-Centric <b>DeepDR</b>
              </div>
              The "Target-Centric <b>DeepDR</b>" service provides users with four model options: two based on heterogeneous networks, one grounded in knowledge graphs, and a synergistic model combining knowledge graphs with molecular graphs. Users can choose an appropriate model and input the name or ID of a target protein to receive a ranked list of potential drug recommendations.
            </div>
            <Button
              onClick={() => {
                store.store.servers.changeHomeStatue(6);
              }}
            >
              Try
            </Button>
          </div>
        </div>
      </div>
      <div className="home-display-position"></div>
      {/* <div className="home-display-part">
        <div className="home-details-title">NEW DEVELOPMENTS</div>
        <div className="home-details-departs">
          <div className="home-details-departs-text">
            <div className="home-details-departs-text-con">
            DeepDR: this model is a network-based deep-learning method for drug
            repurposing by integrating 10 networks: one drug disease, one
            drug-side-effect, one drug target and seven drug drug networks.
            Specifically, deepDR learns high-level features of drugs from the
            heterogeneous networks by a multi-modal deep autoencoder. Then the
            learned low-dimensional representation of drugs together with
            clinically reported drug disease pairs are encoded and decoded
            collectively via a variational autoencoder to infer candidates for
            approved drugs for which they were not originally approved.
            (https://github.com/ChengF-Lab/deepDR)
            </div>
            
          </div>
          <div className="home-details-departs-graph">
            <img src={Tutorial_2_Disease_Centric_DeepDR_1}/>
          </div>
        </div>
  
      </div> */}
    </div>
  );
}

export default inject("store")(observer(HomePage));
