import DDRresult from "./DeepDRresult/DDRresult";
import DetailsResult from "./DetailsResult/DetailsResult";
import jobList from "./jobList/jobList";
import result from "./result/result";
import server from "./server/server";
import MetricsResult from "./MetricsResult/MetricsResult";

const jobLists = new jobList();
const results = new result();
const servers = new server();
const dDRresult = new DDRresult();
const detailsResult = new DetailsResult();
const metrics = new MetricsResult();

const stores = {
  jobLists,
  results,
  servers,
  dDRresult,
  detailsResult,
  metrics,
};

export default stores;
