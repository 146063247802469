import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import React from "react";
import "./resources.css";
import { Tree } from "antd";

const { DirectoryTree } = Tree;

const treeData_1 = [
  {
    title: "",
    key: "0-0",
    children: [
      { title: "disease_dict", key: "DeepDR_HeTDR/disease_dict", isLeaf: true },
      {
        title: "drugDisease.txt",
        key: "DeepDR_HeTDR/drugDisease.txt",
        isLeaf: true,
      },
      {
        title: "drugProtein.txt",
        key: "DeepDR_HeTDR/drugProtein.txt",
        isLeaf: true,
      },
      { title: "drug_dict", key: "DeepDR_HeTDR/drug_dict", isLeaf: true },
      { title: "drugdrug.txt", key: "DeepDR_HeTDR/drugdrug.txt", isLeaf: true },
      {
        title: "drugsideEffect.txt",
        key: "DeepDR_HeTDR/drugsideEffect.txt",
        isLeaf: true,
      },
      {
        title: "drugsimBPnet.txt",
        key: "DeepDR_HeTDR/drugsimBPnet.txt",
        isLeaf: true,
      },
      {
        title: "drugsimCCnet.txt",
        key: "DeepDR_HeTDR/drugsimCCnet.txt",
        isLeaf: true,
      },
      {
        title: "drugsimChemicalnet.txt",
        key: "DeepDR_HeTDR/drugsimChemicalnet.txt",
        isLeaf: true,
      },
      {
        title: "drugsimMFnet.txt",
        key: "DeepDR_HeTDR/drugsimMFnet.txt",
        isLeaf: true,
      },
      {
        title: "drugsimMetanet.txt",
        key: "DeepDR_HeTDR/drugsimMetanet.txt",
        isLeaf: true,
      },
      {
        title: "drugsimTherapeuticnet.txt",
        key: "DeepDR_HeTDR/drugsimTherapeuticnet.txt",
        isLeaf: true,
      },
      {
        title: "drugsimWmnet.txt",
        key: "DeepDR_HeTDR/drugsimWmnet.txt",
        isLeaf: true,
      },
      { title: "protein_dict", key: "DeepDR_HeTDR/protein_dict", isLeaf: true },
      {
        title: "sideEffect_dict",
        key: "DeepDR_HeTDR/sideEffect_dict",
        isLeaf: true,
      },
    ],
  },
];
const treeData_2 = [
  {
    title: "",
    key: "0-0",
    children: [
      {
        title: "disease_dict.txt",
        key: "DeepDTnet_AOPEDF/disease_dict.txt",
        isLeaf: true,
      },
      {
        title: "drugDisease.txt",
        key: "DeepDTnet_AOPEDF/drugDisease.txt",
        isLeaf: true,
      },
      {
        title: "drugProtein.txt",
        key: "DeepDTnet_AOPEDF/drugProtein.txt",
        isLeaf: true,
      },
      {
        title: "drug_dict.txt",
        key: "DeepDTnet_AOPEDF/drug_dict.txt",
        isLeaf: true,
      },
      {
        title: "drugdrug.txt",
        key: "DeepDTnet_AOPEDF/drugdrug.txt",
        isLeaf: true,
      },
      {
        title: "drugsideEffect.txt",
        key: "DeepDTnet_AOPEDF/drugsideEffect.txt",
        isLeaf: true,
      },
      {
        title: "drugsim1network.txt",
        key: "DeepDTnet_AOPEDF/drugsim1network.txt",
        isLeaf: true,
      },
      {
        title: "drugsim2network.txt",
        key: "DeepDTnet_AOPEDF/drugsim2network.txt",
        isLeaf: true,
      },
      {
        title: "drugsim3network.txt",
        key: "DeepDTnet_AOPEDF/drugsim3network.txt",
        isLeaf: true,
      },
      {
        title: "drugsim4network.txt",
        key: "DeepDTnet_AOPEDF/drugsim4network.txt",
        isLeaf: true,
      },
      {
        title: "drugsim5network.txt",
        key: "DeepDTnet_AOPEDF/drugsim5network.txt",
        isLeaf: true,
      },
      {
        title: "drugsim6network.txt",
        key: "DeepDTnet_AOPEDF/drugsim6network.txt",
        isLeaf: true,
      },
      {
        title: "proteinDisease.txt",
        key: "DeepDTnet_AOPEDF/proteinDisease.txt",
        isLeaf: true,
      },
      {
        title: "protein_dict.txt",
        key: "DeepDTnet_AOPEDF/protein_dict.txt",
        isLeaf: true,
      },
      {
        title: "proteinprotein.txt",
        key: "DeepDTnet_AOPEDF/proteinprotein.txt",
        isLeaf: true,
      },
      {
        title: "proteinsim1network.txt",
        key: "DeepDTnet_AOPEDF/proteinsim1network.txt",
        isLeaf: true,
      },
      {
        title: "proteinsim2network.txt",
        key: "DeepDTnet_AOPEDF/proteinsim2network.txt",
        isLeaf: true,
      },
      {
        title: "proteinsim3network.txt",
        key: "DeepDTnet_AOPEDF/proteinsim3network.txt",
        isLeaf: true,
      },
      {
        title: "proteinsim4network.txt",
        key: "DeepDTnet_AOPEDF/proteinsim4network.txt",
        isLeaf: true,
      },
      {
        title: "sideEffect_dict.txt",
        key: "DeepDTnet_AOPEDF/sideEffect_dict.txt",
        isLeaf: true,
      },
    ],
  },
];
const treeData_3 = [
  {
    title: "",
    key: "0-0",
    children: [
      {
        title: "drkg.tsv.zip",
        key: "DisKGE_TarKGE/drkg.tsv.zip",
        isLeaf: true,
      },
      {
        title: "entity2src.tsv",
        key: "DisKGE_TarKGE/entity2src.tsv",
        isLeaf: true,
      },
      {
        title: "relation_glossary.tsv",
        key: "DisKGE_TarKGE/relation_glossary.tsv",
        isLeaf: true,
      },
    ],
  },
];
const treeData_4 = [
  {
    title: "",
    key: "0-0",
    children: [
      {
        title: "dataset.zip",
        key: "KG_MTL/dataset.zip",
        isLeaf: true,
      },
    ],
  },
];

function Resources(store) {
  useEffect(() => {
    let current = [];
    for (let i = 0; i < 100; i++) {
      current.push(70 + Math.floor(Math.random() * 10) + 1);
    }
    console.log(current);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  const onSelect = (keys, info) => {
    console.log("Trigger Select", keys, info);
    if (keys[0] !== "0-0")
      window.location.href = "https://server.wei-group.net/resource/" + keys[0];
  };

  const onExpand = () => {
    console.log("Trigger Expand");
  };

  return (
    <div className="Resources-body">
      <div className="Resources-depart">
        <div className="Resources-departs">
          <div className="Resources-departs-title">DeepDR/HeTDR</div>

          <DirectoryTree
            multiple
            defaultExpandAll
            onSelect={onSelect}
            onExpand={onExpand}
            treeData={treeData_1}
          />
        </div>
        <div className="Resources-departs">
          <div className="Resources-departs-title">DeepDTnet/AOPEDF </div>{" "}
          <DirectoryTree
            multiple
            defaultExpandAll
            onSelect={onSelect}
            onExpand={onExpand}
            treeData={treeData_2}
          />
        </div>
        <div className="Resources-departs">
          <div className="Resources-departs-title">DisKGE/TarKGE</div>

          <DirectoryTree
            multiple
            defaultExpandAll
            onSelect={onSelect}
            onExpand={onExpand}
            treeData={treeData_3}
          />
        </div>
        <div className="Resources-departs">
          <div className="Resources-departs-title">KG-MTL</div>

          <DirectoryTree
            multiple
            defaultExpandAll
            onSelect={onSelect}
            onExpand={onExpand}
            treeData={treeData_4}
          />
        </div>
      </div>
    </div>
  );
}

export default inject("store")(observer(Resources));
